import React, { useContext } from 'react'
import PrintIcon from '@material-ui/icons/Print'
import DownloadIcon from '@material-ui/icons/PictureAsPdf'
import DeclineIcon from '@material-ui/icons/Cancel'
import MoreIcon from '@material-ui/icons/ExpandMore'

import { Button, PopupMenu, PopupMenuItem, useClientOptions } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'

import { usePresentationNav } from '../PresentationNavContext'

import { QuoteContext } from '../../../context/QuoteContext'

export interface PresentationNavMoreProps {}

export default function PresentationNavMore({ ..._props }: PresentationNavMoreProps) {
  const { options } = useClientOptions()

  const navContext = usePresentationNav()
  const { onMoreClick, view } = navContext

  const { quote } = useContext(QuoteContext)

  const allowResponse = navContext.allowResponse && view !== 'work-order' && !quote?.is_invoice

  const objectLabels = getObjectLabels({ options, quote })

  const allowDecline = allowResponse && options?.options?.allowDecline && view !== 'product-order-form'

  const declineLabel = getDeclineLabel({ quote, objectLabels })
  const printLabel = getPrintLabel()
  const downloadLabel = getDownloadLabel()

  return (
    <PopupMenu
      component={
        <Button icon={MoreIcon} variant={'text'}>
          More
        </Button>
      }
    >
      <PopupMenuItem
        icon={PrintIcon}
        onClick={(ev) => {
          onMoreClick(ev, 'print')
        }}
      >
        {printLabel}
      </PopupMenuItem>
      <PopupMenuItem
        icon={DownloadIcon}
        onClick={(ev) => {
          onMoreClick(ev, 'download')
        }}
      >
        {downloadLabel}
      </PopupMenuItem>
      {allowDecline && (
        <PopupMenuItem
          icon={DeclineIcon}
          onClick={(ev) => {
            onMoreClick(ev, 'decline')
          }}
        >
          {declineLabel}
        </PopupMenuItem>
      )}
    </PopupMenu>
  )

  function getPrintLabel() {
    if (view === 'work-order') {
      return `Print ${objectLabels.workOrder.value}`
    } else if (quote?.is_invoice) {
      return `Print Invoice`
    } else if (view === 'product-order-form') {
      return `Print Product Order`
    } else {
      return `Print ${objectLabels.quote.value}`
    }
  }

  function getDownloadLabel() {
    if (view === 'work-order') {
      return `Download ${objectLabels.workOrder.value}`
    } else if (quote?.is_invoice) {
      return `Download Invoice`
    } else if (view === 'product-order-form') {
      return `Download Product Order`
    } else {
      // return `Download Presentation`
      return `Download ${objectLabels.quote.value}`
    }
  }

  function getDeclineLabel({ quote, objectLabels }) {
    if (objectLabels.declineButton) {
      return objectLabels.declineButton.value
    } else {
      const item =
        quote?.is_invoice && quote?.totals?.pending ? objectLabels.additionalWork.value : objectLabels.quote.value
      return `Decline ${item}`
    }
  }
}

import { getQuoteTypeOptions } from '@paintscout/util/builder'
import React from 'react'
import { useClientOptions } from '../../ClientOptionsProvider'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityFooter from '../ActivityFooter'
import ActivityOwner from '../ActivityOwner'
import ActivityTitle from '../ActivityTitle'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import QuoteLabel from '../QuoteLabel'

export default function QuoteTypeChangedActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props
  const { options } = useClientOptions()

  const typeOptions = getQuoteTypeOptions({ options, hideAll: false }).map((type) => ({
    value: type.key,
    label: type.label
  }))

  const type =
    typeOptions.find((opt) => opt.value === activity.details.quote?.int_ext?.value)?.label ??
    activity.details.quote?.int_ext?.label

  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            <QuoteLabel activity={activity} /> type changed to <Highlight>{type}</Highlight>
          </>
        ) : (
          <>
            Type changed to <Highlight>{type}</Highlight>
          </>
        )}
        <ActivityOwner activity={activity} />
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

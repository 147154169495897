import axios from 'axios'
// Lightly check if pdf exists at url already
// Refactored to a promise that tries it's best to return
// something in all error scenarios.
export async function urlExists(url) {
  return new Promise(async function (resolve, _reject) {
    axios
      .head(url)
      .then((res) => {
        if (res && res.status === 200) {
          resolve(url)
        } else {
          resolve(false)
        }
      })
      .catch(() => {
        resolve(false)
      })
  })
}

export default urlExists

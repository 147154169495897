import { Box, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Grid, Typography } from '@ui/paintscout'
import React from 'react'
import type { PresentationSectionProps } from '../../../..'
import ColorRowPreview from '../_components/ColorRowPreview'
import type { ColorTileOption } from '../_components/ColorRowPreview/ColorRowPreview'
import ColorTile from '../_components/ColorTile'
import ContentFeature from '../_components/ContentFeature'
import PageHeader from '../_components/PageHeader'
import SWLink from '../_components/SWLink'

const useStyles = makeStyles<Theme, PopularColorsProps>(
  (theme) => ({
    root: {
      background: theme.palette.common.white,
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
      overflow: 'hidden'
    },
    colorOfTheYear: {
      background: '#ECF1F3',
      padding: '70px 40px',
      display: 'flex',
      flexDirection: 'column',
      gap: '80px',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gap: '50px'
      }
    },
    colorOfTheYearHeader: {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
      }
    },
    colorOfTheYearContent: {
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center'
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '20px'
      }
    },
    colorOfTheMonth: {
      padding: '60px 40px'
    },
    exploreExteriorPaint: {
      position: 'relative'
    },
    exploreExteriorGridWrapper: {
      position: 'relative',
      zIndex: 1,
      textAlign: 'center'
    },
    exploreExteriorPaintTitle: {
      margin: '28px 0',
      fontFamily: 'CustomFont-SW',
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '-1.5px',
      [theme.breakpoints.down('md')]: {
        padding: '0 15px'
      }
    },
    exploreExteriorPaintLink: {
      color: '#000',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    exploreExteriorPaintLinkText: {
      textAlign: 'left',
      margin: '9px 0',
      fontFamily: 'CustomFont-SW',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center'
      }
    },
    exploreExteriorPaintDescription: {
      marginBottom: '20px',
      textAlign: 'left',
      fontFamily: 'CustomFont-SW',
      fontSize: '19.5px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center'
      }
    },
    exploreExteriorPaintBackground: {
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '350px',
      background: '#F6F0E8',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    subTitle: {
      fontFamily: 'CustomFont-SW',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase'
    },
    title: {
      fontFamily: 'CustomFont-SW',
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '-1.5px'
    },
    description: {
      fontFamily: 'CustomFont-SW',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.5px'
    },
    pairsWellTitle: {
      fontFamily: 'CustomFont-SW',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '-0.5px'
    },
    socialContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '80px 40px',
      [theme.breakpoints.down('md')]: {
        padding: '80px 10px'
      }
    },
    socialTitle: {
      textAlign: 'center',
      fontFamily: 'CustomFont-SW',
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '-1.5px'
    },
    socialDescription: {
      marginTop: '10px',
      textAlign: 'center',
      color: '#2F2F30',
      fontFamily: 'CustomFont-SW',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '-0.5px'
    },
    socialCard: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      marginTop: '32px'
    },
    socialDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      paddingLeft: '5px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '273px'
      }
    },
    socialHandle: {
      color: '#2F2F30',
      fontFamily: 'CustomFont-SW',
      fontSize: '14.361px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '21.541px',
      letterSpacing: '-0.224px'
    }
  }),
  { name: 'PopularColors' }
)

export interface PopularColorsProps {
  classes?: StyleClasses<typeof useStyles>
}

function PopularColors(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { quote } = props

  return (
    <div className={classes.root}>
      <PageHeader
        title={
          <>
            Most Popular
            <br />
            Colors
          </>
        }
        ctaLink="https://www.sherwin-williams.com/en-us/color/color-collections/top-50-colors"
        ctaLabel="View Colors"
        backgroundImage={
          'https://res.cloudinary.com/taptapquote/image/upload/v1709849379/paintscout/partners/sw/images/hero-header-background-image.png'
        }
        headerBackgroundColor="#324658"
      />

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors?.colorOfTheYear && (
        <div className={classes.colorOfTheYear}>
          <Grid container md={12} className={classes.colorOfTheYearHeader}>
            <Grid item lg={6} md={7} sm={9}>
              <div style={{ position: 'relative', width: '358px', height: '239px' }}>
                <img
                  src={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1709849373/paintscout/partners/sw/images/color-of-the-year-image.png'
                  }
                />
                <div style={{ position: 'absolute', right: '-15px', bottom: '-20px' }}>
                  <ColorTile color={'#BFC9D0'} colorCode={6239} colorName="Upward" darkText tall />
                </div>
              </div>
            </Grid>
            <Grid
              item
              container
              lg={6}
              md={5}
              sm={8}
              direction="column"
              spacing={1}
              className={classes.colorOfTheYearContent}
            >
              <Grid item>
                <Typography variant="h6" className={classes.subTitle}>
                  Introducing
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2" className={classes.title}>
                  2024 Color of the Year
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.description}>
                  Our relaxed and carefree Color of the Year. Take a deep breath and ascend into this blissful blue.
                </Typography>
              </Grid>
              <Grid item>
                <SWLink
                  label="Order Free Chips"
                  href="https://samples.sherwin-williams.com/view/top-50-colors?_gl=1*ipru9e*_gcl_aw*R0NMLjE3MDk3Mzg0MzAuQ2p3S0NBaUFyZmF1QmhBcEVpd0Flb0I3cUFQSkozVXp1SjF4NlpDNFk1XzBTekJKMTNXcnl4Yy1PM3lMcGE2UXhOSXBmbG1JRl9YRHl4b0M5YXdRQXZEX0J3RQ..*_gcl_au*MTg3MzYxNTQwNy4xNzA1NTEwMjA2*_ga*MTkxMDU0MDU2MS4xNzAxNzIwOTM1*_ga_BFTFS1H2E0*MTcwOTgzMDQ2Ni45NC4xLjE3MDk4MzA1NDEuNTMuMC4w&_ga=2.44348844.941519321.1709664242-1910540561.1701720935&_gac=1.223506281.1709738716.CjwKCAiArfauBhApEiwAeoB7qAPJJ3UzuJ1x6ZC4Y5_0SzBJ13Wryxc-O3yLpa6QxNIpflmIF_XDyxoC9awQAvD_BwE"
                  theme="dark"
                />
              </Grid>
            </Grid>
          </Grid>
          <ColorRowPreview
            title={<Typography className={classes.pairsWellTitle}>Pairs Well With</Typography>}
            colorTileOptions={pairsWellWithColorTiles}
          />
        </div>
      )}

      {/* {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
        ?.colorsOfTheMonth && (
        <ColorRowPreview
          title="Our Colors of the Month"
          colorTileOptions={colorsOfTheMonthColorTiles}
          // links={[
          //   {
          //     label: 'View All Colors',
          //     href: 'https://www.google.ca',
          //     theme: 'dark'
          //   }
          // ]}
          style={{ padding: '70px 40px 40px' }}
        />
      )} */}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors?.topColors && (
        <ContentFeature
          title="Our Top Colors"
          description={
            <>
              An incredibly versatile range <br />
              of whites and neutrals from <br />
              our experts to suit any style.
            </>
          }
          cta={
            <Grid item>
              <SWLink
                href="https://www.sherwin-williams.com/en-us/color/color-collections/top-50-colors"
                label="Explore Our Collection"
                theme="dark"
              />
            </Grid>
          }
          colorTileA={{
            color: '#C4B9A3',
            colorCode: '9965',
            colorName: 'Peace of Mind',
            darkText: true
          }}
          colorTileB={{
            color: '#F0ECE2',
            colorCode: '7551',
            colorName: 'Greak Villa',
            darkText: true
          }}
          image={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849381/paintscout/partners/sw/images/our-top-colors.png'
          }
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
        ?.finestWhitesNeutrals && (
        <ContentFeature
          title={
            <>
              Our Finest Whites <br />& Neutrals
            </>
          }
          description={
            <>
              An incredibly versatile range <br />
              of whites and neutrals from <br />
              our experts to suit any style.
            </>
          }
          cta={
            <>
              <Grid item lg={6} md={4} xs={6}>
                <SWLink
                  href="https://www.sherwin-williams.com/en-us/color/color-family/white-paint-colors"
                  label="Whites"
                  theme="dark"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item lg={6} md={4} xs={6}>
                <SWLink
                  href="https://www.sherwin-williams.com/en-us/color/color-collections/finest-whites-neutrals"
                  label="Grays"
                  theme="dark"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item lg={12} md={4} xs={12}>
                <SWLink
                  href="https://www.sherwin-williams.com/en-us/color/color-family/neutral-paint-colors"
                  label="Neutrals"
                  theme="dark"
                  style={{ width: '100%' }}
                />
              </Grid>
            </>
          }
          colorTileA={{
            color: '#CCCCC5',
            colorCode: '9548',
            colorName: 'Sweater Weather',
            darkText: true
          }}
          colorTileB={{
            color: '#EEEFEA',
            colorCode: '7006',
            colorName: 'Extra White',
            darkText: true
          }}
          image={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849380/paintscout/partners/sw/images/our-finest-whites-and-neutrals.png'
          }
          reverseOrder
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
        ?.expertCollections && (
        <ContentFeature
          title={
            <>
              Our Expert <br />
              Collections
            </>
          }
          description={
            <>
              An incredibly versatile range <br />
              of whites and neutrals from <br />
              our experts to suit any style.
            </>
          }
          cta={
            <Grid item>
              <SWLink
                href="https://www.sherwin-williams.com/en-us/color/color-collections/emerald-designer-edition"
                label="Explore Our Collection"
                theme="dark"
              />
            </Grid>
          }
          colorTileA={{
            color: '#7A5F5A',
            colorCode: '6033',
            colorName: 'Bateau Brown',
            darkText: false
          }}
          colorTileB={{
            color: '#E2E0D6',
            colorCode: '6033',
            colorName: 'Nuance',
            darkText: true
          }}
          image={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849377/paintscout/partners/sw/images/our-expert-collections.png'
          }
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
        ?.exploreExteriorPaint && (
        <div className={classes.exploreExteriorPaint}>
          <Grid container className={classes.exploreExteriorGridWrapper}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.exploreExteriorPaintTitle}>
                Explore Exterior Paint Colors
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              {exploreExteriorPaintItems.map((item) => (
                <Box key={item.href} padding={1.25}>
                  <Grid item>
                    <img src={item.image} style={{ maxWidth: '100%' }} />
                  </Grid>
                  <Grid item>
                    <a href={item.href} className={classes.exploreExteriorPaintLink} target="_blank" rel="noreferrer">
                      <Typography className={classes.exploreExteriorPaintLinkText}>{item.title}</Typography>
                    </a>
                  </Grid>
                  <Grid>
                    <Typography variant="body1" className={classes.exploreExteriorPaintDescription}>
                      {item.description}
                    </Typography>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
          <div className={classes.exploreExteriorPaintBackground} />
        </div>
      )}

      {/* <div className={classes.socialContainer}>
        <Typography variant="h2" className={classes.socialTitle}>
          Shared From Real Homes
        </Typography>
        <Typography variant="body1" className={classes.socialDescription}>
          Upload your own or share it with us on social media by using <b>#SWColorLove.</b>
        </Typography>
        <Grid container spacing={3}>
          {socialCards.map((socialCard, index) => {
            const { socialHandle, colorTile, image } = socialCard
            return (
              <Grid
                item
                container
                direction="column"
                md={4}
                alignItems="center"
                className={classes.socialCard}
                key={socialHandle + index}
              >
                <img src={image} style={{ maxWidth: '100%' }} />
                <div className={classes.socialDetails}>
                  <ColorTile icon {...colorTile} />
                  <Typography variant="body1" className={classes.socialHandle}>
                    {socialHandle}
                  </Typography>
                </div>
              </Grid>
            )
          })}
        </Grid>
        <SWLink
          label="See All #SWColorLove"
          theme="social"
          href="https://www.sherwin-williams.com/homeowners/inspiration/swcolorlovegallery"
          style={{ marginTop: '40px' }}
        />
      </div> */}
    </div>
  )
}

export default PopularColors

type ExploreExteriorPaintItem = {
  image: string
  title: React.ReactNode
  href: string
  description: React.ReactNode
}

// type SocialCard = {
//   image: string
//   colorTile: ColorTileProps
//   socialHandle: string
// }

const exploreExteriorPaintItems: ExploreExteriorPaintItem[] = [
  {
    image:
      'https://res.cloudinary.com/taptapquote/image/upload/v1709849374/paintscout/partners/sw/images/explore-exterior-img-a.png',
    title: <>Top Exterior Colors &gt;</>,
    href: 'https://www.sherwin-williams.com/en-us/color/exterior-paint-colors/exterior-house-colors',
    description: (
      <>
        Find the ideal exterior paint <br />
        colors from Sherwin-Williams to <br />
        delight visitors and highlight <br />
        your style.
      </>
    )
  },
  {
    image:
      'https://res.cloudinary.com/taptapquote/image/upload/v1709849375/paintscout/partners/sw/images/explore-exterior-img-b.png',
    title: <>Front Door Colors &gt;</>,
    href: 'https://www.sherwin-williams.com/en-us/color/exterior-paint-colors/front-door-paint-colors',
    description: (
      <>
        By choosing a new front door <br />
        paint color, you can quickly <br />
        upgrade your exterior while <br />
        infusing it with a splash of <br />
        personal style.
      </>
    )
  },
  {
    image:
      'https://res.cloudinary.com/taptapquote/image/upload/v1709849376/paintscout/partners/sw/images/explore-exterior-img-c.png',
    title: <>Garage Door Colors &gt;</>,
    href: 'https://www.sherwin-williams.com/en-us/color/exterior-paint-colors/garage-door-colors',
    description: (
      <>
        Stand out with a contrasting hue <br />
        choice or harmonize by <br />
        coordinating with your home's <br />
        exterior colors.
      </>
    )
  }
]

// const colorsOfTheMonthColorTiles: ColorTileOption[] = [
//   {
//     colorTile: {
//       color: '#273E3E',
//       colorCode: 7623,
//       colorName: 'Cascades',
//       tall: true
//     },
//     label: 'September'
//   },
//   {
//     colorTile: {
//       color: '#8B534E',
//       colorCode: '0006',
//       colorName: 'Toile Red',
//       tall: true
//     },
//     label: 'September'
//   },
//   {
//     colorTile: {
//       color: '#323132',
//       colorCode: 6991,
//       colorName: 'Black Magic',
//       tall: true
//     },
//     label: 'September'
//   },
//   {
//     colorTile: {
//       color: '#B85444',
//       colorCode: 6615,
//       colorName: 'Peppery',
//       tall: true
//     },
//     label: 'September'
//   },
//   {
//     colorTile: {
//       color: '#BFC9D0',
//       colorCode: 6239,
//       colorName: 'Upward',
//       tall: true,
//       darkText: true
//     },
//     label: 'September'
//   },
//   {
//     colorTile: {
//       color: '#DCD8D0',
//       colorCode: 9166,
//       colorName: 'Drift of Mist',
//       tall: true,
//       darkText: true
//     },
//     label: 'September'
//   }
// ]

const pairsWellWithColorTiles: ColorTileOption[] = [
  {
    colorTile: {
      color: '#DCD8D0',
      colorCode: '9166',
      colorName: 'Drift Mist',
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#35454E',
      colorCode: '7605',
      colorName: 'Gale Force'
    }
  },
  {
    colorTile: {
      color: '#2F2F30',
      colorCode: '6258',
      colorName: 'Tricon Black'
    }
  },
  {
    colorTile: {
      color: '#DBDDBD',
      colorCode: '6428',
      colorName: 'Honeydew',
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#635936',
      colorCode: '7735',
      colorName: 'Palm Leaf'
    }
  },
  {
    colorTile: {
      color: '#946644',
      colorCode: '0045',
      colorName: 'Antiquarian Brown'
    }
  }
]

// const socialCards: SocialCard[] = [
//   {
//     image:
//       'https://res.cloudinary.com/taptapquote/image/upload/v1710259787/paintscout/partners/sw/images/swcolorlove-.png',
//     colorTile: {
//       color: '#C2DAE0',
//       colorName: 'Color Name',
//       colorCode: '9999'
//     },
//     socialHandle: '@socialhandle'
//   },
//   {
//     image:
//       'https://res.cloudinary.com/taptapquote/image/upload/v1710259779/paintscout/partners/sw/images/swcolorlove-_1.png',
//     colorTile: {
//       color: '#C2DAE0',
//       colorName: 'Color Name',
//       colorCode: '9999'
//     },
//     socialHandle: '@socialhandle'
//   },
//   {
//     image:
//       'https://res.cloudinary.com/taptapquote/image/upload/v1710259767/paintscout/partners/sw/images/swcolorlove-_2.png',
//     colorTile: {
//       color: '#C2DAE0',
//       colorName: 'Color Name',
//       colorCode: '9999'
//     },
//     socialHandle: '@socialhandle'
//   }
// ]

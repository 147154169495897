import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { Typography, Button, FilePreview, Grid, ImagePlaceholder, HtmlContent } from '@ui/paintscout'
import { padArray } from '@paintscout/util/util'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {},
    image: {
      '& img': {
        borderRadius: theme.spacing(0.5)
      }
    },
    button: {
      textDecoration: 'none !important' as any,
      opacity: 0.7,
      transition: '0.5s all',
      '&:hover': {
        opacity: 1
      }
    }
  }
})

export default function Documents(props: PresentationSectionProps) {
  const { section, WrapperComponent, isPreview, showHelp } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 6

  const classes = useStyles(props)
  const { fields, title } = section

  let rows = []
  if (fields?.rows) {
    rows = fields.rows
  }

  const showPlaceholders = isPreview && showHelp

  if (showPlaceholders) {
    rows = padArray(rows, 3, { title: 'Document Title', content: 'This is the description of this document.' })
  }

  function DocumentRow({ row, index }) {
    return (
      <Grid container spacing={3}>
        {(row.image || showPlaceholders) && (
          <Grid item md={3}>
            {row.image && (
              <a href={row.image.src} target={`paintscout-document-${index}`} onClick={handleButtonClick}>
                <FilePreview file={row.image} classes={{ root: classes.image }} selectedPages={[0]} />
              </a>
            )}
            {!row.image && showPlaceholders && <ImagePlaceholder title={'Document'} width={'100%'} height={250} />}
          </Grid>
        )}

        <Grid item md={9}>
          <Grid container spacing={3} alignItems="center">
            {row.title && (
              <Grid item md={12}>
                <Typography variant={'h3'}>{row.title}</Typography>
              </Grid>
            )}
            {row?.content && (
              <Grid item md={12}>
                <HtmlContent content={row.content} classes={{ root: classes.content }} />
              </Grid>
            )}
            {(row?.image?.src || showPlaceholders) && (
              <Grid item md={12}>
                <a
                  href={row?.image?.src ?? ''}
                  className={classes.button}
                  target={`paintscout-document-${index}`}
                  onClick={handleButtonClick}
                >
                  <Button>View Document</Button>
                </a>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )

    function handleButtonClick(ev: React.MouseEvent) {
      ev.stopPropagation()
    }
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={responsiveSpacing}>
        {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        {rows.map((row, index) => (
          <Grid item md={12} key={index}>
            <DocumentRow row={row} key={index} index={index} />
          </Grid>
        ))}
      </Grid>
    </WrapperComponent>
  )
}

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import type { UserPreferences } from 'paintscout'
import type { Theme } from '@material-ui/core'
import KeypadInputField from '../KeypadInputField'
import type { InputFieldProps } from '../InputField'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import Typography from '../Typography'

import type { OverridableValue } from '@paintscout/util/builder'

import ResetAdornment from './ResetAdornment'

const useStyles = makeStyles<Theme, OverridableInputFieldProps>((theme) => ({
  root: {
    '& p': {
      marginTop: '0 !important'
    }
  },
  inputFocused: {
    '& $adornment': {
      color: theme.palette.primary.main
    }
  },
  icon: {},
  adornment: {
    marginLeft: theme.typography.pxToRem(7),
    marginRight: theme.typography.pxToRem(4),
    color: theme.palette.grey[500]
  },
  inputWrapper: {},
  helperText: {},
  helperTextContainer: {
    // position: 'absolute',
    marginTop: 3
  },
  inputInput: {}
}))

export interface OverridableInputFieldProps extends Omit<InputFieldProps, 'value' | 'onChange' | 'classes'> {
  classes?: StyleClasses<typeof useStyles>
  value?: OverridableValue
  helpPrefix?: string
  helpText?: string
  hideHelp?: boolean
  resetBlank?: boolean
  flipX?: boolean
  preferences?: UserPreferences
  onChange?: (value: OverridableValue) => void
  showKeypad?: 'always' | 'touch' | 'never'
}

const OverridableInputField = forwardRef((props: OverridableInputFieldProps, ref) => {
  const classes = useStyles(props)
  const {
    value,
    resetBlank,
    helpPrefix = 'Default Value:',
    hideHelp,
    helpText,
    format,
    onChange,
    flipX,

    ...baseInputProps
  } = props

  function getValue(value: OverridableValue): string {
    return (Math.round((value.useCustom ? value.custom : value.default) * 100) / 100).toString()
  }

  const disabled = !!baseInputProps?.disabled
  const [rawValue, setRawValue] = useState(getValue(value))
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setRawValue(getValue(value))
    setCurrentValue(value)
  }, [value])

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return currentValue
    },
    setValue: (value: OverridableValue) => {
      setCurrentValue(value)
    }
  }))

  let helperText = null
  if (!hideHelp && helpText) {
    helperText = <>{helpText}</>
  } else if (!hideHelp && currentValue.useCustom && (currentValue.default || currentValue.default === 0)) {
    helperText = (
      <div className={classes.helperTextContainer}>
        {helpPrefix}{' '}
        <Typography
          display="inline"
          variant={'subtitle2'}
          component={'span'}
          format={format}
          classes={{ root: classes.helperText }}
          value={currentValue.default === 0 ? '0' : currentValue.default}
        />
      </div>
    )
  }

  return (
    <>
      <KeypadInputField
        {...baseInputProps}
        classes={{
          root: classes.root,
          adornment: classes.adornment,
          icon: classes.icon,
          inputWrapper: classes.inputWrapper,
          inputFocused: classes.inputFocused,
          inputInput: classes.inputInput
        }}
        value={!rawValue ? '' : rawValue}
        format={format}
        helperText={helperText}
        onChange={handleChange}
        onBlur={handleBlur}
        flipX={flipX}
        endAdornment={currentValue.useCustom && !disabled ? <ResetAdornment onResetClick={handleResetValue} /> : null}
      />
    </>
  )

  function handleResetValue() {
    const updatedValue = {
      ...value,
      useCustom: false
    }

    setRawValue(value.default)
    onChange?.(updatedValue)
    setCurrentValue(updatedValue)
  }

  function handleBlur() {
    if (resetBlank && rawValue === '') {
      const updatedValue = { ...value, useCustom: false }
      setRawValue(value.default)
      onChange?.(updatedValue)
      setCurrentValue(updatedValue)
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const previousValue = value.useCustom ? value.custom : value.default

    const newRawValue = event.target.value
    setRawValue(newRawValue)

    if ((newRawValue.slice(-1) === '.' && newRawValue.length === 1) || newRawValue === '') {
      return null
    }

    const newValue = parseFloat(newRawValue)
    const defaultValue = typeof value.default === 'string' ? parseFloat(value.default) : value.default

    if (value.useCustom && newValue === Number(Number(defaultValue).toFixed(2))) {
      const updatedValue = { ...value, useCustom: false }
      onChange?.(updatedValue)
      setCurrentValue(updatedValue)
    } else if (previousValue === newValue) {
      return null
    } else if (((value as any) !== '' && resetBlank) || (value as any) === '') {
      const updatedValue = { ...value, useCustom: true, custom: newValue }
      onChange?.(updatedValue)
      setCurrentValue(updatedValue)
    }
  }
})
OverridableInputField.displayName = 'OverridableInputField'

export default OverridableInputField

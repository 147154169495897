import React from 'react'
import { ActivityOwner as CoreActivityOwner } from '@ui/core'
import type { ActivityOwnerProps as CoreActivityOwnerProps } from '@ui/core'

export interface ActivityOwnerProps extends Omit<CoreActivityOwnerProps, 'name'> {}

/**
 * Renders "by firstName lastName" if there is an owner, otherwise renders nothing
 */
export default function ActivityOwner(props: CoreActivityOwnerProps) {
  return <CoreActivityOwner {...props} />
}

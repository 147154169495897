import type { OptionsDocument } from 'paintscout'
import { createTheme } from '@ui/paintscout'

export default function getTheme(options?: OptionsDocument) {
  return createTheme({
    palette: {
      primary: options ? { main: options.options.primaryColor.value as string } : { main: '#396E90' },
      secondary: options ? { main: options.options.secondaryColor.value as string } : { main: '#BDE2EA' }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1024, // lg: 1280,
        xl: 1175 // was:1280.
      }
    }
  })
}

/*
  I've changed the xl breakpoint, as that's the only breakpoint that gets the "sidebar" nav on customer-view... So we may as well make it visible as long as possible, even though the breakpoint doesn't line up with the app.
*/

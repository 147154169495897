import React, { Suspense } from 'react'

import WebfontLoader from '@dr-kobros/react-webfont-loader'
// const WebfontLoader = React.lazy(() => import('@dr-kobros/react-webfont-loader'))

export interface FontLoadProps {
  children?: React.ReactNode
  fonts?: string[]
}

export function FontLoad({ children, ...props }: FontLoadProps) {
  const families = (props.fonts ?? []).filter((item) => item)
  const config = {
    google: {
      families
    }
  }

  return families.length ? (
    <Suspense fallback={null}>
      <WebfontLoader config={config}>{children}</WebfontLoader>
    </Suspense>
  ) : (
    <>{children}</>
  )
}

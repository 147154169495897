export const RESIZED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif'
  // 'image/webp'
]
export interface S3UrlComponents {
  key: string
  rawUrl: string
  bucket: string
}

/**
 * Retrieve file dimensions for image, default for pdf
 */
const getFileDimensions = async (
  file: File,
  urlComponents: S3UrlComponents
): Promise<{
  height: number
  width: number
}> => {
  return new Promise(async (resolve, _reject) => {
    if (RESIZED_FILE_TYPES.includes(file.type)) {
      const img = new Image()
      img.onload = () => {
        const height = img.height
        const width = img.width
        resolve({ height, width })
      }
      img.src = urlComponents.rawUrl
    } else if (file.type.includes('pdf')) {
      resolve({ height: 792, width: 612 })
    } else {
      resolve({ height: undefined, width: undefined })
    }
  })
}

export default getFileDimensions

import type { QuoteItemSection, QuoteItemView, QuoteTemplateOption } from '@paintscout/util/builder'
import type { QuoteDocument, PresentationEstimator } from 'paintscout'
import React from 'react'
import type { WisetackPromoResponse } from '../Wisetack'

/**
 * The value of the quote context received by consumers
 *
 */
export interface QuoteContextValue {
  quote?: QuoteDocument
  cleanQuote?: QuoteDocument
  tableView?: 'area' | 'substrate'
  presentationPage?: string
  sidebarPage?: string
  estimator?: PresentationEstimator
  isDirty?: boolean
  isEditable?: boolean
  isNoteEditable?: boolean
  isCustomerView?: boolean
  isPresentation?: boolean
  hasActiveAccount?: boolean
  canAddAdditionalWork?: boolean
  isSaving?: boolean

  editingTemplate?: QuoteTemplateOption

  wisetackPromo?: WisetackPromoResponse

  save?: (quote?: QuoteDocument) => Promise<QuoteDocument>
  reset?: () => any

  // contact
  onSelectContact?: () => any
  onEditContact?: () => any
  onCreateContact?: () => any
  onEditSecondaryContact?: () => any
  onCreateSecondaryContact?: () => any
  onSelectSecondaryContact?: () => any
  onRemoveSecondaryContact?: () => any

  // quote info
  onEditTerms?: () => any
  onEditDetails?: () => any
  onEditNote?: () => any
  onEditJobAddress?: () => any
  onEditInfo?: () => any // EditQuoteInfoDialog

  onAccept?: (pending?: boolean, quote?: QuoteDocument) => any
  refetchQuote?: () => any // Customer view

  // quote items
  onEditItem?: (key: string) => any
  onEditItemFiles?: (args: { itemKey: string; index: number }) => any
  onItemAction?: (action: string, section: QuoteItemSection, keys: string[]) => any
  onReorder?: (args: { items: any[]; section: QuoteItemSection; view: QuoteItemView; addIndex?: number }) => void
  onAddItem?: (section?: QuoteItemSection, insertAfterKey?: string, type?: string) => any

  onTableViewChange?: (view: string) => any
  onSidebarPageChange?: (sidebarPage: string) => any
  onPresentationPageChange?: (presentationPage: string) => any

  toggleEditable?: (editable?: boolean) => any
  updateQuote?: (args: { quote: QuoteDocument; autosave?: boolean; blockDirty?: boolean }) => any
}

export const QuoteContextInitValue: QuoteContextValue = {
  tableView: 'area' as const,
  presentationPage: '',
  sidebarPage: 'index',
  estimator: null,
  isDirty: false,
  editingTemplate: null,
  isEditable: false,
  isNoteEditable: false,
  isPresentation: false,
  hasActiveAccount: false,
  canAddAdditionalWork: false,
  isSaving: false,
  wisetackPromo: null,
  save: () => Promise.resolve(null),
  reset: () => {},
  onSelectContact: () => {},
  onEditContact: () => {},
  onCreateContact: () => {},
  onEditSecondaryContact: () => {},
  onCreateSecondaryContact: () => {},
  onSelectSecondaryContact: () => {},
  onRemoveSecondaryContact: () => {},
  onEditTerms: () => {},
  onEditDetails: () => {},
  onEditNote: () => {},
  onEditJobAddress: () => {},
  onEditItem: () => {},
  onEditItemFiles: () => {},
  onAccept: () => {},
  onItemAction: () => {},
  onReorder: () => {},
  onAddItem: () => {},
  onTableViewChange: () => {},
  onSidebarPageChange: () => {},
  onEditInfo: () => {},
  onPresentationPageChange: () => {},
  toggleEditable: () => {},
  updateQuote: () => {}
}

export const QuoteContext = React.createContext<QuoteContextValue>(QuoteContextInitValue)

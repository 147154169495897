import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core'
import { createStyles, withStyles, Typography } from '@material-ui/core'
import type { FilesGridProps } from '../../FilesGrid'
import FilesGrid from '../../FilesGrid'

export interface FileSectionProps extends WithStyles<typeof styles>, FilesGridProps {
  title: string
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      margin: '0'
    }
  })

function FileSection(props: FileSectionProps) {
  const { classes, title, ...filesGridProps } = props

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom={true}>
        {title}
      </Typography>
      <FilesGrid {...filesGridProps} />
    </div>
  )
}

export default withStyles(styles)(FileSection)

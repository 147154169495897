import React from 'react'
import type { FieldProps } from 'formik'
import type { SwitchProps } from '../Switch'
import Switch from '../Switch'

export interface FormikSwitchProps extends FieldProps<any>, Omit<SwitchProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikSwitch = ({ field, ...props }: FormikSwitchProps) => {
  const { form: _, ...switchProps } = props
  return <Switch {...field} {...switchProps} checked={field.value} />
}

export default FormikSwitch

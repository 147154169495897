/**
 * @module builder
 */

import type { QuoteFile } from 'paintscout'
import { uuid } from '../../../util'

export function createFile(args: { type: string }): QuoteFile {
  const { type } = args
  return {
    key: uuid(),
    type,
    visibility: 'visible'
  }
}

import type { QuoteDocument, OptionsDocument, PresentationOption, PresentationPage } from 'paintscout'
import { getFeature } from '../../../options'

import { getDefaultPresentation } from '../../../options/presentation/get-default-presentation'
import type { QuoteView } from '../../../../../../../@ui/react-quote/src/Quote/Quote'

export function getQuotePresentation(args: {
  options: OptionsDocument
  quote: QuoteDocument
  inactive?: boolean // return inactive presentations
  view?: QuoteView
  current?: boolean // return current presentations from options(sb feature)
}): PresentationOption {
  const { quote, options, view, inactive, current } = args
  const presentationsFeature = getFeature({ options, path: 'presentation.enabled' })
  const currentPresentation = options.options?.presentationOptions?.values?.[quote.presentationTemplate?.key]

  if (view === 'work-order' || view === 'product-order-form') {
    // down the road - we will probably beef-up the work-order
    // to have some presentation capabilities
    // ie: maps, etc
    return getDefaultPresentation({ options, view })
  } else if (quote?.is_invoice || !presentationsFeature) {
    // down the road - we will probably add some capabilities
    // to have an invoice presentation
    // ie: payment instructions, etc.
    return getDefaultPresentation({ options, invoice: quote.is_invoice, view })
  } else if (quote?.presentationTemplate && inactive) {
    return quote.presentationTemplate
  } else if (current && currentPresentation?.key) {
    return currentPresentation
  } else if (quote?.presentationTemplate?.active) {
    const partnerContent = getFeature({ options: options, path: 'partnerContent' })
    const sherwinWilliamsPages: PresentationPage[] = [
      {
        key: 'sherwin-williams-popular-colors',
        type: 'sherwin-williams',
        title: 'Sherwin Williams',
        label: 'Sherwin',
        sections: [
          {
            key: 'sherwin-williams-popular-colors',
            type: 'sherwin-williams-popular-colors',
            label: 'Popular Colors',
            title: 'Popular Colors'
          }
        ]
      },
      {
        key: 'sherwin-williams-color-samples',
        type: 'sherwin-williams',
        title: 'Sherwin Williams',
        label: 'Sherwin',
        sections: [
          {
            key: 'sherwin-williams-color-samples',
            type: 'sherwin-williams-color-samples',
            label: 'Color Samples',
            title: 'Color Samples'
          }
        ]
      },
      {
        key: 'sherwin-williams-color-help',
        type: 'sherwin-williams',
        title: 'Sherwin Williams',
        label: 'Sherwin',
        sections: [
          {
            key: 'sherwin-williams-color-help',
            type: 'sherwin-williams-color-help',
            label: 'Color Help',
            title: 'Color Help'
          }
        ]
      }
    ]

    return {
      ...quote.presentationTemplate,
      pages: [
        ...(quote.presentationTemplate?.pages ?? []),
        ...(partnerContent?.sherwinWilliams?.presentationPages?.enabled ? sherwinWilliamsPages : [])
      ]
    }
  } else {
    return getDefaultPresentation({ options })
  }
}

/**
 * @module builder
 */
import type { QuoteDocument, QuoteOptions } from 'paintscout'

import { getQuoteOptions } from '../../quote/util'

export function setQuoteOptions(args: { quote?: QuoteDocument; quoteOptions?: QuoteOptions }): QuoteDocument {
  const { quoteOptions, quote } = args

  const defaults = getQuoteOptions({})
  return {
    ...quote,
    options: {
      ...defaults,
      ...quoteOptions
    }
  }
}

import React, { useContext } from 'react'
import { CloudinaryContext as LegacyCloudinaryContext } from 'cloudinary-react'

export function CloudinaryProvider({
  children,
  ...props
}: {
  cloudName: string
  secure?: boolean
  uploadPreset?: string
  children: React.ReactNode
}) {
  return (
    <LegacyCloudinaryContext {...props}>
      <CloudinaryContext.Provider value={props}>{children}</CloudinaryContext.Provider>
    </LegacyCloudinaryContext>
  )
}

export interface CloudinaryContextValue {
  cloudName: string
  uploadPreset?: string
}

export const CloudinaryContext = React.createContext<CloudinaryContextValue>({
  cloudName: null,
  uploadPreset: null
})

export const useCloudinary = () => useContext(CloudinaryContext)

export interface WithCloudinary {
  cloudinaryContext: CloudinaryContextValue
}

export const withCloudinary = <T extends WithCloudinary>(Component: React.ComponentType<T>) =>
  function WithCloudinary(props: Omit<T, keyof WithCloudinary>) {
    return (
      <CloudinaryContext.Consumer>
        {(context: CloudinaryContextValue) => <Component {...(props as any)} cloudinaryContext={context} />}
      </CloudinaryContext.Consumer>
    )
  }

export default CloudinaryProvider

import React from 'react'
import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import { Button } from '@ui/paintscout'

const styles = createStyles({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white'
  }
})

export interface RetryOverlayProps extends WithStyles<typeof styles> {
  onRetry?: () => any
}

function RetryOverlay(props: RetryOverlayProps) {
  return (
    <div className={props.classes.root}>
      <Button variant="outlined" onClick={props.onRetry}>
        Retry
      </Button>
    </div>
  )
}

export default withStyles(styles)(RetryOverlay)

import moment from 'moment-timezone'
import React from 'react'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityFooter from '../ActivityFooter'
import ActivityTitle from '../ActivityTitle'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import QuoteLabel from '../QuoteLabel'

export default function QuoteViewedActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props

  const diff = activity.details.updated - activity.details.timestamp

  const utc = moment.utc(diff)

  const minutes = Math.trunc(diff / 1000 / 60)

  const email = activity.details?.events?.length === 1 && activity.details.events[0]

  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            {activity.details.quote?.status === 'accepted' && <Highlight>Accepted </Highlight>}
            <QuoteLabel activity={activity} /> was <Highlight>viewed</Highlight>
          </>
        ) : (
          <>
            <Highlight>Viewed</Highlight>
          </>
        )}{' '}
        for {diff > 10000 ? `${minutes}m ${utc.format('s')}s` : `less than 10s`}
        {/* We've had some reports of the word 'undefined' showing up in this email field */}
        <>
          {email && email !== 'undefined' && (
            <>
              {' '}
              by <Highlight>{email}</Highlight>
            </>
          )}
        </>
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

const pdfBucketName = process.env.PS_PDF_CACHE_BUCKET ?? 'privpstestimage'

interface GetPdfBucketUrlParams {
  companyId: string
  quoteRev: string
  url: string
  quoteId: string
  view: string
  filename: string
}

export function getPdfBucketUrl({ companyId, quoteId, url, quoteRev, view, filename }: GetPdfBucketUrlParams): string {
  const pdfUrl = `https://${pdfBucketName}.s3.amazonaws.com/${companyId}/${quoteId}/${url}/${quoteRev}/${view}/${filename}`

  return pdfUrl
}

export default getPdfBucketUrl

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FastField } from 'formik'

import { Grid, FormikEmbedMedia, FormikInputField, FormikHtmlEditor } from '@ui/paintscout'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dropzone: {},
  label: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing()
    }
  },
  editor: {
    minHeight: '115px'
  }
}))

const IntroductionForm = ({ ...props }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const classes = useStyles(props)

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field label={'title'} name={'section.title'} fullWidth={true} component={FormikInputField} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={FormikEmbedMedia}
            name="section.fields.image"
            buttonHeight={150}
            maxWidth={800}
            maxHeight={1600}
            quality={1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FastField
            classes={{ label: classes.label, editor: classes.editor }}
            component={FormikHtmlEditor}
            label={'Content'}
            fullWidth={true}
            name="section.fields.content"
            debounce={true}
            toolbar={{
              headings: true,
              align: true
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(IntroductionForm)

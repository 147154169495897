import type { WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import React, { useContext, useCallback, useRef } from 'react'

import type { QuoteHeaderCardProps } from '../QuoteHeaderCard'
import QuoteHeaderCard from '../QuoteHeaderCard'
import { QuoteContext } from '../../../context/QuoteContext'
import { useUser, useClientOptions, Button, DialogStackContext } from '@ui/paintscout'
import { formatPhoneNumber, setEstimator } from '@paintscout/util/builder'
import { ChangeEstimatorDialog } from '../../../dialogs'
import type { Auth0User } from 'paintscout'
import type { User } from '@paintscout/api'

const styles = createStyles({
  root: {},
  contents: {},
  errorMessage: {
    color: 'red'
  },
  assignEstimatorButton: {
    whiteSpace: 'break-spaces'
  }
})

const EstimatorCard = (props: QuoteHeaderCardProps & WithStyles<typeof styles>) => {
  const { quote, isEditable, updateQuote } = useContext(QuoteContext)
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const { options } = useClientOptions()
  const { isAdmin } = useUser()
  const currentEstimatorId = useRef<string>(quote?.owner?.id ?? '')
  const { classes } = props

  const onSelectEstimator = useCallback(async () => {
    return openDialog(ChangeEstimatorDialog, {
      currentEstimatorId: currentEstimatorId.current,
      onConfirm: (user: User) => {
        const estimatorProps = user
          ? { quote, user: user as Auth0User, signature: user.signature }
          : { quote, user: null }
        currentEstimatorId.current = user?.user_id ?? ''
        updateQuote({ quote: setEstimator(estimatorProps) })
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }, [dismissDialog, openDialog, quote])

  if (!quote.owner && !isEditable) {
    return null
  } else if (!quote.owner) {
    return (
      <Button
        classes={{ buttonSpan: classes.assignEstimatorButton }}
        variant="outlined"
        fullWidth={true}
        onClick={onSelectEstimator}
      >
        Assign Estimator
      </Button>
    )
  }

  const { firstName, lastName, phoneNumber, phoneExtension, email, title } = quote.owner

  let text = ''

  if (firstName || lastName) {
    text += [firstName, lastName].filter((item) => item).join(' ') + '\n'
  }

  if (title) {
    text += title + '\n'
  }

  if (phoneNumber) {
    text += formatPhoneNumber({ options, phoneNumber, extension: phoneExtension }) + '\n'
  }

  if (email) {
    text += email + '\n'
  }

  return (
    <QuoteHeaderCard
      classes={classes}
      text={text}
      // subtitle={
      //   hasOwner ? null : (
      //     <Typography variant={'subtitle2'} className={classes.errorMessage}>
      //       Estimator not found
      //     </Typography>
      //   )
      // }
      clickable={isAdmin && isEditable}
      onClick={onSelectEstimator}
      {...props}
    />
  )
}

export default withStyles(styles)(EstimatorCard)

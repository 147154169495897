/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'

export function getJobIdentifier(args: { quote: QuoteDocument }) {
  const { quote } = args

  return quote.job_identifier
}

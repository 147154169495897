import type { QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'

export function calculateAfterTax(quote: QuoteDocument) {
  const price = parseNumber(quote.totals.after_discount)
  const totalTax = parseNumber(quote.totals.total_tax)
  const surcharge = Math.round(quote.totals.surcharge * 100) / 100 || 0

  return price + totalTax + surcharge
}

import type { OptionsDocument, QuoteDocument } from 'paintscout'
import clone from 'lodash/clone'
import isPlainObject from 'lodash/isPlainObject'

import { areaDimensions, areaTotals } from './areas'
import { areaSubstrates } from './area-substrates'
import { calculateLineItem } from './line-items'
import { substrateTotals } from './substrates'
import { quoteTotals } from './totals/quote-totals'
import { getGroupDetails } from '../builder/quote/groups/get-group-details/get-group-details'
import uniq from 'lodash/uniq'

export function calculate(quote: QuoteDocument, options: OptionsDocument): QuoteDocument {
  const updatedQuote = clone(quote)

  if (updatedQuote.areas) {
    Object.keys(updatedQuote.areas).forEach((areaKey) => {
      let updatedArea = clone(updatedQuote.areas[areaKey])

      if (!isPlainObject(updatedArea)) {
        return
      }

      updatedArea.dimensions = areaDimensions(updatedArea)

      updatedArea.substrates = areaSubstrates(updatedArea, updatedQuote, options)
      updatedArea = areaTotals(updatedArea, updatedQuote)
      updatedQuote.areas[areaKey] = updatedArea
    })
  }

  if (updatedQuote.lineItems) {
    Object.keys(updatedQuote.lineItems).forEach((lineItemKey) => {
      let updatedLineItem = clone(updatedQuote.lineItems[lineItemKey])
      updatedLineItem = calculateLineItem(updatedQuote, updatedLineItem, options)
      updatedQuote.lineItems[lineItemKey] = updatedLineItem
    })
  }

  if (updatedQuote.groups) {
    Object.keys(updatedQuote.groups).forEach((groupKey) => {
      const group = updatedQuote.groups[groupKey]
      const { price, description } = getGroupDetails({
        quote: updatedQuote,
        options,
        keys: uniq(group.children),
        section: group.section,
        groupKey
      })
      updatedQuote.groups[groupKey] = {
        ...updatedQuote.groups[groupKey],
        description: {
          ...updatedQuote.groups[groupKey].description,
          default: description
        },
        price: {
          ...updatedQuote.groups[groupKey].price,
          default: price
        }
      }
    })
  }

  updatedQuote.substrates = substrateTotals(updatedQuote)

  updatedQuote.totals = quoteTotals(updatedQuote, options)

  return updatedQuote
}

import type { OptionsDocument, QuoteArea, QuoteDocument } from 'paintscout'
import { formatCurrency, getObjectLabels } from '../../../../../util'
import get from 'lodash/get'
import type { RenderableItemSubstrate } from '../../../../area-substrates'
import { parseNumber } from '../../../../../../calculator'
import { getQuoteOptions } from '../../../../util'

// Generate the Dimensions string for the areaSubstrate to be displayed to crew
export default function getDimensionString({
  areaSubstrate,
  area,
  quote,
  options,
  priceValue
}: {
  areaSubstrate: RenderableItemSubstrate
  area: QuoteArea
  quote: QuoteDocument
  options: OptionsDocument
  priceValue?: number
}): string {
  let dimension = ''
  let price = ''
  const areaType = get(area, 'area_type.value', 'room')
  const { unit } = getObjectLabels({ options, quote })
  const { rateType } = areaSubstrate

  const { showWorkOrderItemTotals } = getQuoteOptions({ quote, options })

  if (rateType === 'quantity' && !areaSubstrate.hideQuantityOnWorkOrder) {
    dimension = areaSubstrate.quantity.toString()
  } else {
    if (areaType === 'surface') {
      const length = areaSubstrate.length?.useCustom ? areaSubstrate.length.custom : areaSubstrate.length?.default
      const height = areaSubstrate.height?.useCustom ? areaSubstrate.height.custom : areaSubstrate.height?.default
      const customSubstrateDimensions = areaSubstrate.length?.useCustom || areaSubstrate.height?.useCustom

      const areaLnft = area.dimensions.override_lnft ? area.dimensions.lnft : area.dimensions.calculated_lnft
      const areaSqft = area.dimensions.override_sqft ? area.dimensions.sqft : area.dimensions.calculated_sqft

      const surfaceLnft = decimalPrecision(customSubstrateDimensions ? length : areaLnft)
      const surfaceSqft = decimalPrecision(customSubstrateDimensions ? length * height : areaSqft)

      if (areaSubstrate.dimensionOverride) {
        dimension = `${areaSubstrate.dimensionOverride}${unit.abbreviation.square}`
      } else if (areaSubstrate.rateType === 'lnft') {
        dimension = `${surfaceLnft}${unit.abbreviation.linear}`
      } else {
        dimension = `${surfaceSqft}${unit.abbreviation.square}`
      }
    } else {
      const length = areaSubstrate.length?.useCustom ? areaSubstrate.length.custom : areaSubstrate.length?.default
      const width = areaSubstrate.width?.useCustom ? areaSubstrate.width.custom : areaSubstrate.width?.default
      const height = areaSubstrate.height?.useCustom ? areaSubstrate.height.custom : areaSubstrate.height?.default
      const customSubstrateDimensions =
        areaSubstrate.length?.useCustom || areaSubstrate.width?.useCustom || areaSubstrate.height?.useCustom

      const areaLnft = area.dimensions?.override_lnft ? area.dimensions.lnft : area.dimensions?.calculated_lnft
      const areaSqftWalls = area.dimensions?.override_sqft_walls
        ? area.dimensions.sqft_walls
        : area.dimensions?.calculated_sqft_walls
      const areaSqftCeilings = area.dimensions?.override_sqft_ceiling
        ? area.dimensions.sqft_ceiling
        : area.dimensions?.calculated_sqft_ceiling
      const areaSqftFloor = area.dimensions?.override_sqft_floor
        ? area.dimensions.sqft_floor
        : area.dimensions?.calculated_sqft_floor

      const surfaceLnft = areaSubstrate.dimensionOverride
        ? decimalPrecision(areaSubstrate.dimensionOverride)
        : decimalPrecision(customSubstrateDimensions ? 2 * (length + width) : areaLnft)
      const surfaceSqftWalls = areaSubstrate.dimensionOverride
        ? decimalPrecision(areaSubstrate.dimensionOverride)
        : decimalPrecision(customSubstrateDimensions ? 2 * (length + width) * height : areaSqftWalls)
      const surfaceSqftCeilings = areaSubstrate.dimensionOverride
        ? decimalPrecision(areaSubstrate.dimensionOverride)
        : decimalPrecision(customSubstrateDimensions ? length * width : areaSqftCeilings)
      const surfaceSqftFloor = areaSubstrate.dimensionOverride
        ? decimalPrecision(areaSubstrate.dimensionOverride)
        : decimalPrecision(customSubstrateDimensions ? length * width : areaSqftFloor)

      if (areaSubstrate.dimensionOverride) {
        dimension = `${areaSubstrate.dimensionOverride}${areaSubstrate.rateType === 'lnft' ? unit.abbreviation.linear : unit.abbreviation.square}`
      } else if (areaSubstrate.rateType === 'lnft') {
        dimension = `${surfaceLnft}${unit.abbreviation.linear}`
      } else if (areaSubstrate.rateType === 'sqftWalls') {
        dimension = `${surfaceSqftWalls}${unit.abbreviation.square}`
      } else if (areaSubstrate.rateType === 'sqftCeiling') {
        dimension = `${surfaceSqftCeilings}${unit.abbreviation.square}`
      } else if (areaSubstrate.rateType === 'sqftFloor') {
        dimension = `${surfaceSqftFloor}${unit.abbreviation.square}`
      }
    }

    if (areaSubstrate.calculationType === 'price_per_value' && showWorkOrderItemTotals) {
      if (priceValue) {
        price = formatCurrency({ value: priceValue, options })
      }
    }
  }
  return [dimension, price].filter((str) => str).join(', ')
}

const decimalPrecision = (num: string | number) => {
  return Math.round(parseNumber(num) * 100) / 100
}

import React, { useContext } from 'react'

import type { PresentationNavContextValue } from './PresentationNavContext'
import PresentationNavContext from './PresentationNavContext'

import { QuoteContext } from '../../../context/QuoteContext'

import type { QuoteDocument } from 'paintscout'
import { getItems, getQuotePresentation } from '@paintscout/util/builder'
import { useClientOptions } from '@ui/paintscout'

export interface PresentationNavContextProviderProps extends PresentationNavContextValue {}

export default function PresentationNavContextProvider({ children, ...props }) {
  const childrenFn = children as (context: PresentationNavContextValue) => React.ReactNode
  const { options } = useClientOptions()

  const { quote } = useContext(QuoteContext)

  const presentation = getQuotePresentation({ quote, options })

  // Need to manually check the pending table to see if additional work with no cost needs to be accepted
  const hasPendingItems =
    getItems({
      quote,
      section: 'pending',
      view: 'area',
      consumer: 'customer',
      options,
      overrideOptions: { showPrices: true }
    }).length > 0

  const hasPending =
    (quote.is_invoice || quote.status.value === 'accepted') &&
    (!!((quote?.totals?.pending?.materials ?? 0) + (quote?.totals?.pending?.price ?? 0)) || hasPendingItems)

  const value = {
    ...props,
    allowResponse: getAllowResponse({ quote, hasPending }),
    hasPending,
    isSinglePage: presentation.pages.length <= 1
  }

  return (
    <PresentationNavContext.Provider value={value}>
      {typeof children === 'function' ? childrenFn(value) : children}
    </PresentationNavContext.Provider>
  )
}

function getAllowResponse({ quote, hasPending }: { quote: QuoteDocument; hasPending: boolean }) {
  if (quote?.status?.value === 'declined') {
    return false
  } else if (quote?.status?.value === 'accepted' && !hasPending) {
    return false
  } else if (quote?.is_invoice && !hasPending) {
    return false
  }

  return true
}

import getPageCount from './getPageCount'
import getFileDimensions from './getFileDimensions'
import getUrlComponents from './getUrlComponents'
import type { S3FileUploadData } from '.'

/**
 * Sanitize upload info to return
 */
const getUploadData = async (file: File, url: string): Promise<S3FileUploadData> => {
  const urlComponents = getUrlComponents(url)
  const size = file.size
  const pages = await getPageCount(file)
  const { height, width } = await getFileDimensions(file, urlComponents)

  return {
    src: urlComponents.rawUrl,
    s3PublicKey: urlComponents.key,
    bucket: urlComponents.bucket,
    type: 'upload',
    format: file.type.split('/')[1],
    size,
    pages,
    width: Number(width),
    height: Number(height)
  }
}

export default getUploadData

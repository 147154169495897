import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ estimator }: PresentationIsEmptyProps) {
  const fields = getFields()

  const hasContent = fields?.description && fields.description.replace('<p></p>', '').trim()

  if (!fields.image && !hasContent) {
    return true
  }

  return false

  function getFields() {
    return {
      name: estimator?.name ?? '',
      title: estimator?.title ?? '',
      description: estimator?.description ?? '',
      image: estimator?.image ?? null
    }
  }
}

import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getQuoteTypeOption } from '../get-quote-type-options'
import { getQuoteType } from '../../quote'

export function isSignatureRequired({
  options,
  quote,
  path
}: {
  options: OptionsDocument
  quote: QuoteDocument
  path: 'requireSignatureSent' | 'requireSignatureAdditionalWork' | 'requireSignatureOnSpot'
}) {
  const quoteTypeOption = getQuoteTypeOption({ options, quoteType: getQuoteType({ quote }) })
  let required = options.options.quotes?.[path]
  if (typeof quoteTypeOption?.quoteOptions?.[path] !== 'undefined') {
    required = quoteTypeOption.quoteOptions[path]
  }
  return required
}

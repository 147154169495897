/**
 * @module builder
 */
import type { QuoteDocument, AreaSubstrate, AreaSubstrates } from 'paintscout'

import { uuid } from '../../util'

export function keyAreaSubstrates(args: {
  quote: QuoteDocument
  areaKey: string
  forceOrder?: boolean
}): QuoteDocument {
  const { quote, areaKey, forceOrder } = args

  const substrateOrder = quote.areas[areaKey]?.substrateOrder ? [...(quote.areas[areaKey]?.substrateOrder ?? [])] : []
  // const substrates = { ...quote.areas[areaKey].substrates }

  const substrates: AreaSubstrates = Object.keys(quote.areas[areaKey].substrates).reduce(
    (acc: AreaSubstrates, sectionName: string) => {
      acc[sectionName] = quote.areas[areaKey].substrates[sectionName].map((substrate: AreaSubstrate) => {
        if (substrate.key) {
          if (forceOrder) {
            if (!substrateOrder.find((orderItem) => orderItem.key === substrate.key)) {
              substrateOrder.push({
                type: 'areaSubstrate',
                key: substrate.key
              })
            }
          }
          return { ...substrate }
        }

        const key = uuid()

        substrateOrder.push({
          type: 'areaSubstrate',
          key
        })

        return {
          ...substrate,
          key
        }
      })

      return acc
    },
    {}
  )

  return {
    ...quote,
    areas: {
      ...quote.areas,
      [areaKey]: {
        ...quote.areas[areaKey],
        substrates,
        substrateOrder
      }
    }
  }
}

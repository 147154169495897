import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import React from 'react'
import type { User } from '@paintscout/api'
import Grid from '../Grid'
import Tile from '../Tile'
import Typography from '../Typography'
import UserTile from '../UserTile'

export interface UserTilesProps extends WithStyles<typeof styles> {
  users?: User[]
  disabled?: boolean
  allowNone?: boolean
  selectedUserIds?: string[]
  disabledUserIds?: string[]
  onUserSelect: (user: User) => any
}

const styles = () =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%'
    }
  })

function UserTiles({
  classes,
  users = [],
  selectedUserIds = [],
  disabledUserIds = [],
  onUserSelect,
  allowNone,
  disabled
}: UserTilesProps) {
  return (
    <Grid container spacing={2} justifyContent="space-between">
      {users.map((user) => (
        <Grid key={user.user_id} item xs={12} sm={6}>
          <UserTile
            classes={{ root: classes.root }}
            user={user}
            selected={selectedUserIds.indexOf(user.user_id) > -1}
            disabled={disabled || disabledUserIds.indexOf(user.user_id) > -1}
            onClick={() => onUserSelect(user)}
          />
        </Grid>
      ))}
      {allowNone && (
        <Grid key={'none'} item xs={12} sm={6}>
          <Tile
            classes={{ root: classes.root }}
            onClick={() => {
              onUserSelect(null)
            }}
            selected={selectedUserIds.indexOf(null) > -1 || selectedUserIds.length === 0}
            disabled={disabled}
          >
            <div>{'None'}</div>
            <Typography variant="subtitle1">{'No User'}</Typography>
          </Tile>
        </Grid>
      )}
    </Grid>
  )
}

export default withStyles(styles)(UserTiles)

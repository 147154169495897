/**
 * @module builder
 */
import type { MoveableItem, QuoteItemSection } from '../../index'

export function getItemSection(item: MoveableItem): QuoteItemSection {
  if (!item) {
    return 'archived'
  }
  if (item.additionalWork) {
    return 'additional'
  } else if (item.pending) {
    return 'pending'
  } else if (item.addedOption) {
    return 'added-options'
  } else if (item.show && item.use_total) {
    return 'bid'
  } else if (item.show && !item.use_total) {
    return 'options'
  } else {
    return 'archived'
  }
}

/**
 * Get page count of pdf File
 */
const getPageCount = async (file: File): Promise<number> => {
  return new Promise(async (resolve, _reject) => {
    if (file.type.includes('pdf')) {
      let count: number
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onloadend = async () => {
        count = String(reader.result).match(/\/Type[\s]*\/Page[^s]/g)?.length ?? undefined
        resolve(count)
      }
    } else {
      resolve(undefined)
    }
  })
}

export default getPageCount

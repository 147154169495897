import type { OptionsDocument, QuoteDocument, QuoteFile } from 'paintscout'
import type { QuoteItemSection, QuoteItemConsumer } from '../../index'
import { getFilesForItem } from '../get-files-for-item'
import { getItems } from '../../items'

export function hasFiles(args: {
  quote: QuoteDocument
  sections: QuoteItemSection[]
  options: OptionsDocument
  consumer: QuoteItemConsumer
}) {
  const { quote, sections, options, consumer } = args
  const view = 'area'
  const allItems = sections.reduce((items, section) => [...items, ...getItems({ quote, consumer, section, view })], [])

  const filesByItem = allItems.reduce((files, item) => {
    const itemFiles = getFilesForItem({ quote, itemKey: item.key, options }).filter(({ visibility }) => {
      if (visibility === 'visible') {
        return true
      }

      if (visibility === 'hidden') {
        return false
      }

      return visibility === consumer
    })

    if (itemFiles.length > 0) {
      return {
        ...files,
        [item.key]: itemFiles
      }
    }

    return files
  }, {} as Record<string, QuoteFile[]>)

  return Object.keys(filesByItem).length > 0
}

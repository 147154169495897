import Table from './Table'

import TableHead, { TableHeadContext } from './TableHead'
import type { TableCellProps } from './TableCell'
import TableCell from './TableCell'
import SortableTableCell from './SortableTableCell'
import type { TableRowProps } from './TableRow'
import TableRow from './TableRow'
import TableBody from './TableBody'
import TableFooter from './TableFooter'

export { TableHead, TableHeadContext, TableCell, SortableTableCell, TableRow, TableBody, TableFooter }
export type { TableCellProps, TableRowProps }

export * from './Table'
export default Table

// only exists for legacy imports
import TableActionButton from './TableActionButton'
export { TableActionButton }

/**
 * Get the e2e injection options from local storage
 * Returns all options in local storage that begin with 'e2e.'
 */
export default function getE2eInjection() {
  if (
    typeof window === 'undefined' ||
    !(process.env.DEPLOYMENT_STAGE === 'local' || process.env.DEPLOYMENT_STAGE?.startsWith('dev'))
  ) {
    return {}
  }

  // Declining cookies in safari will break local storage
  try {
    return Object.entries({ ...localStorage })
      .filter(([key]) => key.startsWith('e2e.'))
      .map(([key, value]) => ({
        [key.replace('e2e.', '')]: value === 'false' ? false : value // localStorage.setItem('e2e.abc', false) will set the value to 'false' instead of false
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {})
  } catch (e) {
    return {}
  }
}

/**
 * @module builder
 */

import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getQuoteTypeOption } from '../../options/get-quote-type-options'
import { getQuoteType } from '../../quote/util/get-quote-type'
import get from 'lodash/get'
import endsWith from 'lodash/endsWith'
import getE2eInjection from '../../quote/util/get-options/get-e2e-injection'

export interface UnitAbbreviation {
  basic: string
  square: string
  linear: string
  cubic: string
}

export interface LabelsOption {
  app: { value: string; plural: string }
  quote: { value: string; plural: string }
  option: { value: string; plural: string }
  workOrder: { value: string; plural: string }
  application: { value: string; plural: string }
  additional: { value: string; plural: string }
  additionalWork: { value: string; plural: string }
  labour: { value: string; plural: string }
  prep: { value: string; plural: string }
  volume: { value: string; plural: string }
  substrate: { value: string; plural: string }
  productionRate: { value: string; plural: string }
  acceptButton: { value: string; plural: string }
  declineButton: { value: string; plural: string }
  currency: { value: string; plural: string; symbol: string }
  unit: { value: string; plural: string; abbreviation: UnitAbbreviation }
}

export function getObjectLabels(
  args: { options?: OptionsDocument; quote?: QuoteDocument; invoice?: boolean } = {}
): LabelsOption {
  const { invoice } = args

  const options = args.options && args.options.options ? args.options.options : args.options
  const quote = args.quote

  const quoteType = getQuoteType({ quote })
  const quoteTypeOption = getQuoteTypeOption({ options: args.options, quoteType })

  const labels = {
    quote: 'Quote',
    option: 'Option',
    workOrder: 'Work Order',
    application: 'Coat',
    additional: 'Additional Item',
    additionalWork: 'Additional Work',
    labour: 'Painting',
    prep: 'Prep',
    color: 'Color',
    volume: 'Volume',
    currency: 'Dollar',
    unit: 'Foot',
    substrate: 'Substrate',
    productionRate: 'Production Rate',
    ...get(options, 'labels', {}),
    ...(quoteTypeOption?.objectLabels ?? {}),
    ...getE2eInjection()
  }

  if (invoice) {
    labels.quote = 'Invoice'
  }

  const r = Object.keys(labels).reduce((acc: any, key: string) => {
    acc[key] = {
      value: labels[key],
      plural: pluralize(labels[key]),
      symbol: ['Dollar', 'Euro', 'Pound'].includes(labels[key]) ? getCurrencySymbol(labels[key]) : undefined,
      abbreviation: ['Foot', 'Metre'].includes(labels[key]) ? getUnitAbbreviation(labels[key]) : undefined
    }
    return acc
  }, {})

  return {
    app: {
      value: 'PaintScout'
    },
    ...r
  }
}

function pluralize(input: string): string {
  if (input === 'Additional Work') {
    return 'Additional Work'
  }
  if (input === 'Foot') {
    return 'Feet'
  }
  if (endsWith(input, 's')) {
    return `${input}es`
  }
  return `${input}s`
}

function getCurrencySymbol(input: 'Dollar' | 'Euro' | 'Pound'): string {
  switch (input) {
    case 'Euro':
      return '€'
    case 'Pound':
      return '£'
    default:
      return '$'
  }
}

function getUnitAbbreviation(input: 'Feet' | 'Metre'): UnitAbbreviation {
  if (input === 'Metre') {
    return {
      basic: 'm',
      square: 'm²',
      linear: 'm',
      cubic: 'm³'
    }
  }
  return {
    basic: 'ft',
    square: 'sqft',
    linear: 'lnft',
    cubic: 'cuft'
  }
}

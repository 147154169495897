import striptags from 'striptags'

export function isEmpty(input: string): boolean {
  if (input === '') {
    return true
  }
  if (striptags(input).trim() === '') {
    return true
  }

  return false
}

import type { QuoteDocument } from 'paintscout'
import { add } from '../util/add'
import { zero } from '../util/zero'
import { parseNumber } from '../util/parse-number'

import clone from 'lodash/clone'
import find from 'lodash/find'
import { getAreaSubstrateKey } from '../../builder/quote/area-substrates/get-area-substrate-key'
import { getItemSection } from '../../builder/quote/util/get-item-section'
import { isInSubstrateGroup } from '../../builder/quote/area-substrates/is-in-substrate-group'

export function substrateTotals(quote: QuoteDocument) {
  let substrates = quote.substrates ? clone(quote.substrates) : {}

  if (!quote.order) {
    return substrates
  }

  Object.keys(substrates).forEach((substrateKey) => {
    const substrate = clone(substrates[substrateKey])

    substrate.totals = zero(substrate.totals)
    substrate.grouped_totals = zero(substrate.grouped_totals)
    substrate.hidden_totals = zero(substrate.hidden_totals)
    substrates[substrateKey] = substrate
  })

  quote.order.area.forEach((areaItem) => {
    if (areaItem._deleted) {
      return
    }
    if (areaItem.type !== 'area') {
      return
    }

    const isInAreaGroup = areaItem.parent

    const area = quote.areas?.[areaItem.key]
    if (!area) {
      return
    }

    if (area._deleted) {
      return
    }

    if (!area.substrates) {
      return
    }

    const areaSection = getItemSection(area)

    Object.keys(area.substrates).forEach((section) => {
      area.substrates[section].forEach((substrate) => {
        if (substrate._deleted) {
          return
        }

        let areaSubstrateKey = getAreaSubstrateKey(quote, substrate) as any
        const areaSubstrateSection = getItemSection(substrate)
        const inSubstrateGroup = !!isInSubstrateGroup({
          quote,
          areaSubstrateKey,
          rateKey: substrate.key,
          section: areaSubstrateSection
        })

        if (!substrates[areaSubstrateKey]) {
          substrates = {
            ...substrates,
            [areaSubstrateKey]: {
              totals: {},
              grouped_totals: {},
              hidden_totals: {}
            }
          }
        }

        if (!substrates[areaSubstrateKey].totals) {
          zero(substrates[areaSubstrateKey].totals)
        }
        if (!substrates[areaSubstrateKey].grouped_totals) {
          zero(substrates[areaSubstrateKey].grouped_totals)
        }
        if (!substrates[areaSubstrateKey].hidden_totals) {
          zero(substrates[areaSubstrateKey].hidden_totals)
        }

        let substratePrice = substrate.price
        if (substrate.override_price && typeof substrate.override_price_value !== 'undefined') {
          substratePrice = substrate.override_price_value
        } else if (typeof substrate.default_price !== 'undefined') {
          substratePrice = substrate.default_price
        }
        const price = parseNumber(substratePrice)

        let materials = 0
        if (substrate.product) {
          if (
            substrate.product.totalPrice_override &&
            typeof substrate.product.totalPrice_override_value !== 'undefined' &&
            substrate.product.totalPrice_override_value !== null
          ) {
            materials = substrate.product.totalPrice_override_value ? substrate.product.totalPrice_override_value : 0
          } else {
            materials = substrate.product.totalPrice
          }
        }
        const totalPrice = price + materials

        let substrateHours = substrate.hours
        if (substrate.override_hours && typeof substrate.override_hours_value !== 'undefined') {
          substrateHours = substrate.override_hours_value
        } else if (typeof substrate.default_hours !== 'undefined') {
          substrateHours = substrate.default_hours
        }

        const hours = parseNumber(substrateHours)

        //int_ext
        let int_ext = 'all'
        if (!substrates[areaSubstrateKey]?.int_ext?.value) {
          substrates[areaSubstrateKey].int_ext = {
            value: 'all'
          }
        }
        if (area.int_ext && area.int_ext.value) {
          int_ext = area.int_ext.value
        } else if (quote.int_ext && quote.int_ext.value) {
          int_ext = quote.int_ext.value
        }
        substrates[areaSubstrateKey].int_ext.value = int_ext

        // if (substrate.use_total && (isInAreaGroup || inSubstrateGroup)) {
        if (isInAreaGroup || inSubstrateGroup) {
          substrates[areaSubstrateKey].grouped_totals = add(
            substrates[areaSubstrateKey].grouped_totals,
            {
              hours: substrate.show_crew === false ? 0 : hours,
              prep: substrate.show_crew === false ? 0 : substrate.prep,
              price: totalPrice
            },
            quote
          )
        }

        if (quote && substrates[areaSubstrateKey]) {
          const substrateOrderItem = find(quote.order.substrate, { key: areaSubstrateKey })
          if (substrateOrderItem && substrateOrderItem.parent) {
            return
          }
        }

        if (substrates[areaSubstrateKey].parent) {
          areaSubstrateKey = substrates[areaSubstrateKey].parent
        }

        // const totalable =
        //   ((areaSection === 'bid' || areaSection === 'additional') && areaSubstrateSection === 'bid') ||
        //   ((areaSection === 'bid' || areaSection === 'additional') && areaSubstrateSection === 'additional')

        const totalable = areaSection === 'bid' && areaSubstrateSection === 'bid'

        // console.log({
        //   area: area.area_label.value,
        //   areaSubstrateKey,
        //   totalPrice,
        //   price,
        //   materials,
        //   hours,
        //   substrateHours,

        //   show_crew: substrate.show_crew,
        //   prep: substrate.prep,
        //   areaUseTotal: area.use_total,
        //   substrateUseTotal: substrate.use_total,
        //   isInAreaGroup,
        //   areaSection,
        //   areaSubstrateSection,
        //   totalable
        // })

        if (totalable && !isInAreaGroup && !inSubstrateGroup) {
          substrates[areaSubstrateKey].totals = add(
            substrates[areaSubstrateKey].totals,
            {
              hours: substrate.show_crew === false ? 0 : hours,
              prep: substrate.show_crew === false ? 0 : substrate.prep,
              price: totalPrice
            },
            quote
          )
        } else if (areaSubstrateSection !== 'archived' && areaSection !== 'archived') {
          // this !== archived check handles the edge case of creating a substrate group in the options table
          // that includes a substrate that also exists in the archived table
          substrates[areaSubstrateKey].hidden_totals = add(
            substrates[areaSubstrateKey].hidden_totals,
            {
              hours: substrate.show_crew === false ? 0 : hours,
              prep: substrate.show_crew === false ? 0 : substrate.prep,
              price: totalPrice
            },
            quote
          )
        }
      })
    })
  })

  // console.log({ substrates })

  return substrates
}

import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'

import { Grid, Typography, FilePreview, ImagePlaceholder } from '@ui/paintscout'
import type { QuoteFile } from 'paintscout'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {},
  image: {
    maxWidth: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& img': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))

export default function Image(props: PresentationSectionProps) {
  const { section, WrapperComponent, showHelp } = props

  const classes = useStyles(props)
  const { fields, title } = section

  const { image, link } = fields

  return (
    <WrapperComponent {...props}>
      {title && (
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
          {image && (
            <Grid item md={12} sm={12} xs={12}>
              <ImageComponent link={link} image={image} classes={classes} />
            </Grid>
          )}
          {!image && showHelp && (
            <Grid item md={12} sm={12} xs={12}>
              <ImagePlaceholder title={'Image'} width={'100%'} height={150} />
            </Grid>
          )}
        </Grid>
      )}
      {!title && image && <ImageComponent link={link} image={image} classes={classes} />}
      {!title && !image && showHelp && <ImagePlaceholder title={'Image'} width={'100%'} height={150} />}
    </WrapperComponent>
  )
}

const ImageComponent = React.memo(function ImageComponent({
  link,
  image,
  classes
}: {
  link: string
  image: QuoteFile
  classes: Record<string, string>
}) {
  if (link) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <FilePreview classes={{ image: classes.image }} file={image} />
      </a>
    )
  } else {
    return <FilePreview classes={{ image: classes.image }} file={image} />
  }
})

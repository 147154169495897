import React from 'react'
import { SizeMe } from 'react-sizeme'
import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'

export interface ScalerProps extends WithStyles<typeof styles> {
  /**
   * Width of the child component in px
   */
  fixedWidth: number
  maxScale?: number
  minScale?: number
  children: React.ReactNode

  noHeight?: boolean

  /**
   * Set to true if using server-side rendering, otherwise there might be an error on render
   */
  SSR?: boolean
}

export const styles = () =>
  createStyles({
    root: {},
    wrapper: {
      margin: '0 auto'
    }
  })

function Scaler(props: ScalerProps) {
  const childRef = React.useRef<HTMLDivElement>(null)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0)

  React.useEffect(() => {
    if (childRef.current) {
      forceUpdate()
    }
  }, [childRef.current])

  return (
    <SizeMe noPlaceholder={!props.SSR}>
      {({ size }) => {
        const { fixedWidth, minScale = 0, maxScale = 1, noHeight, children, classes } = props

        const { width = fixedWidth } = size
        const scaleRatio = clamp(minScale, width / fixedWidth, maxScale)
        const height = childRef.current ? childRef.current.clientHeight * scaleRatio : null

        return (
          <div className={classes.root} ref={wrapperRef}>
            <div
              className={classes.wrapper}
              style={{
                width: Math.min(width, fixedWidth * maxScale),
                height: noHeight ? 'auto' : height
              }}
            >
              <div
                ref={childRef}
                style={{
                  width: fixedWidth,
                  transformOrigin: 'top left',
                  transform: `scale(${scaleRatio}, ${scaleRatio}) perspective(1px)`,
                  backfaceVisibility: 'hidden'
                }}
              >
                {children}
              </div>
            </div>
          </div>
        )
      }}
    </SizeMe>
  )
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

export default withStyles(styles)(Scaler)

import React, { useMemo } from 'react'
import { QuoteContext } from '../../context/QuoteContext'
import { ClientOptionsContext, Collapse } from '@ui/paintscout'
import type { QuoteFile } from 'paintscout'
import type { QuoteItemSection, QuoteItemConsumer } from '@paintscout/util/builder'
import { trimGroup } from '@paintscout/util/builder'
import { getItem, getItems, getFilesForItem, updateFileOrderForItem, getQuoteOptions } from '@paintscout/util/builder'
import map from 'lodash/map'
import { Grid } from '@material-ui/core'
import FileSection from './FileSection'
import { DialogStackContext } from '@ui/paintscout'
import FilesLightboxDialog from '../../dialogs/FilesLightboxDialog/FilesLightboxDialog'

export interface FilesByItemProps {
  /**
   * Renders if there are any files or if the quote is being edited
   */
  FormSectionTitle: React.ReactNode

  sections: QuoteItemSection[]
  consumer: QuoteItemConsumer

  WrapperComponent?: React.FunctionComponent
}

/**
 * Shows the files for section grouped by item if there are any
 */
export default function FilesByItem(props: FilesByItemProps) {
  const { quote, isEditable, updateQuote, onEditItemFiles } = React.useContext(QuoteContext)
  const { options } = React.useContext(ClientOptionsContext)
  const { openDialog, dismissDialog } = React.useContext(DialogStackContext)
  const { consumer, sections } = props

  const quoteOptions = getQuoteOptions({
    quote,
    options
  })

  function handleReorder(files: QuoteFile[], itemKey: string) {
    updateQuote({
      quote: updateFileOrderForItem({ quote, options, itemKey, order: files.map((file) => file.key) }),
      autosave: true
    })
  }

  function handleFileClick(itemKey: string, index: number) {
    const files = getFilesForItem({ quote, options, itemKey }).filter(filterVisibleFiles)
    const file = files[index] as QuoteFile

    if (isEditable) {
      onEditItemFiles({ itemKey, index })
    } else {
      if ((file.type === 'application/pdf' || file.format === 'pdf') && file.src) {
        window.open(file.src)
        return
      }

      openDialog(FilesLightboxDialog, {
        files,
        index,
        onClose: dismissDialog
      })
    }
  }

  function filterVisibleFiles(item) {
    const { visibility } = item

    if (visibility === 'visible') {
      return true
    }

    if (visibility === 'hidden') {
      return false
    }

    return visibility === consumer
  }

  const filesByItem = useMemo(() => {
    const view = 'area' // we're always going to show the area-view of files for now...
    const groups = Object.values(quote.groups ?? {})
    const allItems = sections
      .reduce((items, section) => {
        return [
          ...items,
          ...getItems({
            quote,
            consumer,
            section,
            view,
            options,
            // We'll include areas with no substrates in case they have files
            // and show hidden items if we're on the estimate page to account for line items .hideOnWorkOrder
            overrideOptions: { showHiddenItems: consumer === 'customer', includeAreasWithNoSubstrates: true }
          })
        ]
      }, [])
      .filter((item) => {
        for (const group in groups) {
          if (groups[group]?.children?.includes(item.key)) {
            return false
          }
        }

        return true
      })

    const trimmedGroups = groups
      .filter((group) => sections.includes(group.section))
      .map((group) => trimGroup(group, options, quote))
    const itemFiles = [...allItems, ...trimmedGroups].reduce(
      (files, item) => {
        const itemFiles = getFilesForItem({ quote, options, itemKey: item.key }).filter(filterVisibleFiles)

        if (itemFiles.length > 0) {
          return {
            ...files,
            [item.key]: itemFiles
          }
        }

        return files
      },
      {} as Record<string, QuoteFile[]>
    )
    return itemFiles
  }, [quote])

  const hasFiles = Object.keys(filesByItem).length > 0
  const showFiles = consumer === 'customer' ? quoteOptions.showQuoteFiles : quoteOptions.showWorkOrderFiles
  const showTitle = isEditable || (!isEditable && showFiles)

  const MemoizedFiles = useMemo(() => {
    if (!hasFiles) return <></>

    return (
      <>
        {map(filesByItem, (files, key) => {
          const item = getItem({ quote, options, key })

          return (
            <Grid item key={key} xs={12}>
              <FileSection
                justify="flex-start"
                files={files}
                title={item.label as string}
                editable={isEditable}
                onReorder={(files) => handleReorder(files, key)}
                onFileClick={(index) => handleFileClick(item.key, index)}
              />
            </Grid>
          )
        })}
      </>
    )
  }, [filesByItem, isEditable])

  if (!hasFiles) {
    return null
  }

  const content = (
    <>
      {showTitle && props.FormSectionTitle}
      <Collapse show={showFiles}>
        <Grid container spacing={4}>
          {MemoizedFiles}
        </Grid>
      </Collapse>
    </>
  )

  return content
}

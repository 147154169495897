import type { Theme } from '@material-ui/core'

export default function responsiveFontSizes(theme: Theme) {
  return {
    ...theme,
    typography: {
      ...theme.typography,
      h1: {
        ...theme.typography.h1,
        [theme.breakpoints.down('lg')]: { fontSize: '2.0rem' },
        [theme.breakpoints.down('md')]: { fontSize: '2.0rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.7rem' }
      },
      h2: {
        ...theme.typography.h2,
        [theme.breakpoints.down('lg')]: { fontSize: '1.8rem' },
        [theme.breakpoints.down('md')]: { fontSize: '1.8rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.6rem' }
      },
      h3: {
        ...theme.typography.h3,
        [theme.breakpoints.down('lg')]: { fontSize: '1.4rem' },
        [theme.breakpoints.down('md')]: { fontSize: '1.4rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.3rem' }
      },
      h4: {
        ...theme.typography.h4,
        [theme.breakpoints.down('lg')]: { fontSize: '1.1rem' },
        [theme.breakpoints.down('md')]: { fontSize: '1.1rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.0rem' }
      },
      h5: {
        ...theme.typography.h5,
        [theme.breakpoints.down('lg')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('md')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '1.0rem' }
      },
      h6: {
        ...theme.typography.h6,
        [theme.breakpoints.down('lg')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('md')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.7rem' }
      },
      subtitle1: {
        ...theme.typography.subtitle1,
        [theme.breakpoints.down('lg')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('md')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.8rem' }
      },
      subtitle2: {
        ...theme.typography.subtitle2,
        [theme.breakpoints.down('lg')]: { fontSize: '0.9rem' },
        [theme.breakpoints.down('md')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.7rem' }
      },
      body1: {
        ...theme.typography.body1,
        [theme.breakpoints.down('lg')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('md')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.9rem' }
      },
      body2: {
        ...theme.typography.body2,
        [theme.breakpoints.down('lg')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('md')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.7rem' }
      },
      button: {
        ...theme.typography.button,
        [theme.breakpoints.down('lg')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('md')]: { fontSize: '1.0rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.8rem' }
      },
      caption: {
        ...theme.typography.caption,
        [theme.breakpoints.down('lg')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('md')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.7rem' }
      },

      overline: {
        ...theme.typography.overline,
        [theme.breakpoints.down('lg')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('md')]: { fontSize: '0.8rem' },
        [theme.breakpoints.down('sm')]: { fontSize: '0.7rem' }
      }
    }
  }
}

import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useView } from '../../components/ViewProvider'
import { useQuote } from '@ui/react-quote'
import { useInterval } from '@ui/core'

export default function QuoteHeartBeat() {
  const { url } = useView()
  const quoteContext = useQuote()
  const { quote } = quoteContext

  const [sendHeartbeat] = useMutation(gql`
    mutation viewQuoteHeartbeat($url: String!, $viewedActivityId: String!) {
      viewQuoteHeartbeat(url: $url, viewedActivityId: $viewedActivityId)
    }
  `)

  useInterval(() => {
    if (quote?.lastViewedActivity) {
      sendHeartbeat({
        variables: {
          url,
          viewedActivityId: quote.lastViewedActivity
        }
      }).catch((e) => {
        console.error('Heartbeat failed:', e)
      })
    }
  }, 5000)

  return null
}

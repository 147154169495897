import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { useClientOptions, Grid, getPresentationContentStyles } from '@ui/paintscout'
import { getTerms } from '@paintscout/util/builder'

import QuoteSignCard from '../QuoteHeader/QuoteSignCard'

import { useQuote } from '../../context'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.primary
  },
  termsContent: {
    ...getPresentationContentStyles(theme, { noMargins: ['li'] })
  }
}))

export interface QuoteTermsProps {
  isPreview?: boolean
  isCustomerView?: boolean
  isQuoteView?: boolean
}

export default function QuoteTerms(props: QuoteTermsProps) {
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const { isCustomerView, isPreview, isQuoteView } = props
  const { quote, isEditable } = useQuote()

  const status = quote?.status?.value

  const { content } = getTerms({ quote, options })

  const quoteTypeOption = options.options.intExtOptions?.values?.[quote?.int_ext?.value] ?? ({} as any)

  // We, unfortunately, need to make sure to also check options.intExtOptions.values.
  // quoteOptions in case this client has a quoteType override for signatures.
  // It's not something we really advertise, but we have a client with a weird workflow
  // where they require a signature only on certain quote types.

  return (
    <div className={classes.root} id={'terms'}>
      <div dangerouslySetInnerHTML={{ __html: content }} className={classes.termsContent} />
      {(['invoiced', 'accepted'].includes(status) || !isEditable) &&
        ((isQuoteView && quote?.signatures?.customer) ||
          (options.options.quotes.requireSignatureOnSpot && isPreview) ||
          (options.options.quotes.requireSignatureSent && isCustomerView) ||
          (quoteTypeOption?.quoteOptions?.requireSignatureSent && isCustomerView) ||
          (quoteTypeOption?.quoteOptions?.quoteOptions?.requireSignatureOnSpot && isPreview)) && (
          <div id="signatures">
            <Grid container>
              <Grid item sm={12} md={6}>
                <QuoteSignCard />
              </Grid>
            </Grid>
          </div>
        )}
    </div>
  )
}

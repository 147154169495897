import type { QuoteDocument, QuoteArea, AreaSubstrates, OptionsDocument } from 'paintscout'
import type { QuoteItemSection } from '../..'
import { areaTotals } from '../../../../calculator'
import { getItemSection, generateAreaDescription } from '../../util'
import { setAreaSection } from './set-area-section'

export function trimAreaBySection(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  section: QuoteItemSection
}): QuoteArea {
  const { quote, options, areaKey, section } = args
  const area = quote.areas[areaKey]
  const substrateKeys = Object.keys(area?.substrates ?? {})
  const filteredSubstrates: AreaSubstrates = {}
  substrateKeys.forEach((key) => {
    filteredSubstrates[key] = area.substrates[key]
      .filter((substrate) => getItemSection(substrate) === section)
      .map((substrate) => {
        return {
          ...substrate,
          use_total: section === 'bid'
        }
      })
  })
  const filteredKeys = Object.values(filteredSubstrates)
    .flat()
    .map((x) => x.key)
  const substrateOrder = area.substrateOrder?.filter((orderItem) => filteredKeys.includes(orderItem.key))
  const withSectionParams = setAreaSection(
    {
      ...area,
      substrateOrder,
      substrates: filteredSubstrates
    },
    section
  )

  const description = generateAreaDescription({
    quote,
    options,
    areaKey,
    filterBySection: section
  })

  const filteredArea = {
    ...withSectionParams,
    substrate_string: {
      ...withSectionParams.substrate_string,
      default: description
    }
  }

  const { totals } = areaTotals(filteredArea, quote)
  if (section === 'additional') {
    return {
      ...filteredArea,
      totals: {
        ...totals.additionalWork
      }
    }
  }

  if (section === 'pending') {
    return {
      ...filteredArea,
      totals: {
        ...totals.pending
      }
    }
  }

  if (section === 'added-options') {
    return {
      ...filteredArea,
      totals: {
        ...totals.addedOptions
      }
    }
  }
  return {
    ...filteredArea,
    totals
  }
}

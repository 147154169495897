import type { QuoteDocument, OptionsDocument } from 'paintscout'
import { expiryEnabled } from '../expiry-enabled/expiry-enabled'
import type { QuoteView } from '@ui/react-quote/src/Quote/Quote'

/*
 * Checks if given quote should be treated as expired
 */
export function isExpired(args: { quote: QuoteDocument; options: OptionsDocument; view: QuoteView }): boolean {
  const { quote, options, view } = args
  if (quote.is_invoice) {
    return false
  }
  const isExpiryEnabled = expiryEnabled({ quote, options })
  const expiryInterval = quote.options?.customerViewExpiry?.interval

  const isExpired = (() => {
    if (!isExpiryEnabled || ['accepted', 'invoiced'].includes(quote?.status?.value) || view === 'work-order') {
      return false
    }
    return quote.dates?.expiryDate
      ? quote?.dates?.expiryDate < Date.now()
      : quote.dates?.client?.sent + expiryInterval < Date.now()
  })()

  return isExpired
}

import React from 'react'
import type { FieldProps } from 'formik'
import type { EditorProps } from '../Editor'
import { Editor } from '../Editor'

export interface FormikHtmlEditorProps extends FieldProps<any>, Omit<EditorProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikHtmlEditor = ({ form, field, debounce = null, ...props }: FormikHtmlEditorProps) => {
  return (
    <Editor
      {...field}
      {...props}
      content={field?.value ?? ''}
      onChange={(value) => form.setFieldValue(field.name, value)}
      debounce={debounce}
    />
  )
}

export default FormikHtmlEditor

import React, { useState, useEffect } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles, Popover } from '@material-ui/core'
import { SketchPicker } from 'react-color'
import type { InputFieldProps } from '../InputField'
import type { BaseColorPickerProps } from './index'
import type { StyleClasses } from '../styles'

import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles<Theme, BaseColorPickerProps>(
  (theme) => ({
    root: {},
    tooltipRoot: {
      marginLeft: 'auto',
      marginRight: theme.spacing(2)
    },
    tooltipBadge: {
      cursor: 'pointer',
      top: 0,
      right: 0,
      '& svg': {
        fontSize: '1rem'
      }
    },
    input: {},
    popover: {},
    swatch: {
      height: 28,
      width: 28,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    popoverPaper: {}
  }),
  { name: 'CoreInlineColorPicker' }
)

export interface InlineColorPickerProps
  extends BaseColorPickerProps,
    Omit<InputFieldProps, 'onChange' | 'classes' | 'value' | 'label' | 'inputComponent' | 'Typography'> {
  InputField: React.ComponentType<any>
  Tooltip: React.ComponentType<any>
  swatchOnly?: boolean
  swatchIcon?: React.ReactNode
  labelAsValue?: boolean
  fullWidth?: boolean
  useResponsiveSwatchBorder?: boolean
  classes?: StyleClasses<typeof useStyles>
  onClose?: (hex?: string) => void
  presetColors?: string[]
  // clear props
  useClearOption?: boolean
  clearColor?: string
  defaultColor?: string
  helpTextForClear?: string
}

function InlineColorPicker(props: InlineColorPickerProps) {
  const {
    onChange,
    value,
    InputField,
    Tooltip,
    swatchOnly = false,
    swatchIcon,
    labelAsValue,
    label,
    disabled,
    useResponsiveSwatchBorder,
    onClose,
    presetColors,
    useClearOption,
    clearColor,
    defaultColor,
    helpTextForClear,
    ...otherProps
  } = props
  const classes = useStyles(props)
  const [color, setColor] = useState(value)
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 })
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setColor(value)
  }, [value])

  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose(color)
    }
  }
  return (
    <div className={classes.root}>
      {swatchIcon && <div onClick={handleSwatchClick}>{swatchIcon}</div>}
      {!swatchIcon && (
        <>
          {!swatchOnly && (
            <InputField
              {...otherProps}
              value={labelAsValue ? label : value}
              disabled={disabled || labelAsValue ? true : false}
              label={labelAsValue ? '' : label}
              classes={{ root: classes.input, adornment: classes.inputAdornment }}
              onChange={handleInputChange}
              endAdornment={
                <>
                  {useClearOption && value !== defaultColor && (
                    <div className={classes.tooltipRoot} onClick={handleClear}>
                      <Tooltip
                        placement={'left'}
                        content={helpTextForClear}
                        icon={<CloseIcon />}
                        classes={{ badge: classes.tooltipBadge }}
                      />
                    </div>
                  )}
                  <Swatch
                    useResponsiveSwatchBorder={useResponsiveSwatchBorder}
                    className={classes.swatch}
                    hex={value}
                    onClick={handleSwatchClick}
                  />
                </>
              }
            />
          )}
          {swatchOnly && (
            <Swatch
              useResponsiveSwatchBorder={useResponsiveSwatchBorder}
              className={classes.swatch}
              hex={value}
              onClick={handleSwatchClick}
            />
          )}
        </>
      )}

      <Popover
        open={open}
        anchorPosition={anchorPosition}
        anchorReference={'anchorPosition'}
        onClose={handleClose}
        PaperProps={{ className: classes.popoverPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <SketchPicker
          color={color}
          onChange={({ hex }) => {
            setColor(hex)
            if (onChange) onChange(hex)
          }}
          disableAlpha={true}
          presetColors={presetColors}
        />
      </Popover>
    </div>
  )

  function handleClear() {
    setColor(clearColor)
    if (onChange) onChange(clearColor)
  }

  function handleSwatchClick(ev: any) {
    const rect = ev.currentTarget.parentElement.parentElement.getBoundingClientRect()
    const { bottom, right } = rect
    if (!disabled) {
      setOpen(true)
      setAnchorPosition({ top: bottom, left: right })
    }
  }

  function handleInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const hex = ev.target.value
    setColor(hex)
    if (onChange) {
      onChange(hex)
    }
  }

  function Swatch({ hex, useResponsiveSwatchBorder: _useResponsiveSwatchBorder, onClick }: any) {
    return (
      <div
        onClick={onClick}
        className={classes.swatch}
        style={{ backgroundColor: hex ?? '#000', outline: '1px solid #7a7a7a' }}
      />
    )
  }
}

export default InlineColorPicker

import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Switch, Hidden, useClientOptions } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'

const useStyles = makeStyles(
  {
    switch: {
      margin: 0,
      '@media print': {
        display: 'none'
      }
    }
  },
  { name: 'AmountHeadColumn' }
)
export default function AmountHeadColumn({
  consumer,
  disabled,
  onChange,
  showAmount,
  isEditable,
  hideLabel
}: {
  consumer: string
  isEditable?: boolean
  showAmount?: boolean
  disabled?: boolean
  hideLabel?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}) {
  const classes = useStyles({})
  const { options } = useClientOptions()
  const { currency } = getObjectLabels({ options })
  const showSwitch = isEditable

  if (consumer === 'crew') {
    if (showSwitch) {
      return (
        <Switch
          className={classes.switch}
          data-testid="amount-column-switch"
          color={'default'}
          overlay={currency.symbol}
          disabled={disabled}
          onChange={onChange}
          checked={showAmount}
        />
      )
    } else {
      return (
        <Hidden smDown implementation="css">
          {hideLabel ? '' : 'Hours'}
        </Hidden>
      )
    }
  }

  if (consumer === 'customer') {
    if (showSwitch) {
      return (
        <Switch
          className={classes.switch}
          data-testid="amount-column-switch"
          color={'default'}
          overlay={currency.symbol}
          disabled={disabled}
          onChange={onChange}
          checked={showAmount}
        />
      )
    } else {
      return (
        <Hidden smDown implementation="css">
          {hideLabel ? '' : currency.symbol}
        </Hidden>
      )
    }
  }

  return null
}

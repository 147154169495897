import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Typography from '../Typography'
import { ActivityContent as CoreActivityContent } from '@ui/core'
import type { ActivityContentProps as CoreActivityContentProps } from '@ui/core'

export interface ActivityContentProps extends CoreActivityContentProps {}

const useStyles = makeStyles<Theme, ActivityContentProps>({
  root: {},
  title: {
    marginBottom: 2
  },
  bubble: {}
})

export default function ActivityContent(props: ActivityContentProps) {
  const classes = useStyles(props)
  const { title, ...coreProps } = props
  return (
    <CoreActivityContent
      {...coreProps}
      title={
        title && (
          <Typography variant="overline" component="div" className={classes.title}>
            {title}
          </Typography>
        )
      }
    />
  )
}

import type { QuoteDocument, QuoteArea, OrderItem, QuoteOptions, OptionsDocument, AreaSubstrate } from 'paintscout'
import type { RenderableSubItem } from '../..'
import type { QuoteItemSection, QuoteItemConsumer, QuoteItemView } from '../../..'
import { getAreaSubstrate, getAreaSubstrateKey, isInSection, isInSubstrateGroup } from '../../../area-substrates'
import { getArea } from '../../../areas'
import { getItemSection } from '../../../util'
import { formatSubstrateArea } from './format-substrate-areas'

// Filter and format substrate order item
export const filterSubstrateAreas = ({
  quote,
  options,
  area,
  clientOptions,
  areaSubstrateOrderItem,
  key,
  substrateKey,
  section,
  showValues,
  consumer,
  view,
  showGroup = false,
  groupKey = ''
}: {
  quote: QuoteDocument
  area: QuoteArea
  areaSubstrateOrderItem: OrderItem
  key: string
  substrateKey: string
  options: QuoteOptions
  clientOptions: OptionsDocument
  section: QuoteItemSection
  showValues: boolean
  consumer?: QuoteItemConsumer
  view?: QuoteItemView
  showGroup?: boolean
  groupKey?: string
}): RenderableSubItem => {
  const areaSection = getItemSection(area)
  const areaItem = getArea({ quote, options: clientOptions, key })
  const areaSubstrate = getAreaSubstrate({
    quote,
    options: clientOptions,
    areaKey: key,
    key: areaSubstrateOrderItem.key,
    full: true
  }) as AreaSubstrate

  if (!areaSubstrate) {
    return
  }

  const areaSubstrateKey = getAreaSubstrateKey(quote, areaSubstrate)
  const areaSubstrateSection = getItemSection(areaSubstrate)

  // Need to check if any groups exist with this substrateKey AND are in this section
  // If so, we need to filter out this areaSubstrate, unless we want to show grouped substrates(group dialog)
  const groupedSubstrate = !!isInSubstrateGroup({
    quote,
    areaSubstrateKey,
    rateKey: areaSubstrate.key,
    section: areaSubstrateSection,
    groupKey
  })

  // If we dont want to show group and its grouped, hide it
  // If we want to show group and its not grouped, hide it
  // work order/crew doesnt show groups, so just want to show all
  if (
    consumer !== ('crew' as QuoteItemConsumer) &&
    ((groupedSubstrate && !showGroup) || (!groupedSubstrate && showGroup))
  ) {
    return null
  }

  // Filter to make sure we want to include areaSubstrate in
  // this substrate area
  if (!isInSection({ areaSubstrateSection, areaSection, section })) {
    return null
  }

  if (areaSubstrateKey !== substrateKey) {
    return
  }

  // Format/clean areaSubstrate based on consumer/view we are returning to
  const substrateArea = formatSubstrateArea({
    areaSubstrate,
    quote,
    areaItem,
    quoteOptions: options,
    area,
    clientOptions,
    areaSubstrateOrderItem,
    key,
    section,
    showValues,
    consumer,
    view
  })

  // Add substrateArea if valid
  if (substrateArea) {
    return substrateArea
  } else {
    return null
  }
}

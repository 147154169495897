import type { Theme } from '@material-ui/core'
import { useMediaQuery, useTheme } from '@material-ui/core'

/**
 * Returns true if screen size is sm or smaller
 *
 * you can pass in { xs: true } if you only care about xs size
 */
export function useIsMobile({ xs, custom }: { xs?: boolean; custom?: number } = {}) {
  const theme = useTheme<Theme>()
  const val = custom ? custom : xs ? 'xs' : 'sm'
  return useMediaQuery(theme.breakpoints.down(val), { noSsr: true })
}

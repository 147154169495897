import get from 'lodash/get'
import type { QuoteDocument, AreaSubstrate } from 'paintscout'

// Get the key of this area substrate based on its labels
// will be the key it is associated to in quote.substrates
export function getAreaSubstrateKey(quote: QuoteDocument, substrate: AreaSubstrate): string {
  let areaSubstrateKey: string
  if (quote && quote.substratesGroupBy === 'client_label') {
    areaSubstrateKey = get(substrate, 'rate.client_label')
    if (!areaSubstrateKey) {
      areaSubstrateKey = get(substrate, 'rate.label')
    }
    if (!areaSubstrateKey) {
      areaSubstrateKey = 'Custom'
    }
    areaSubstrateKey = areaSubstrateKey.trim().toLowerCase()
  } else {
    areaSubstrateKey = substrate.rate?.name === 'custom' ? substrate.key : substrate.rate.name
  }
  return areaSubstrateKey
}

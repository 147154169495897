import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import ArchiveIcon from '@material-ui/icons/Archive'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import ListIcon from '@material-ui/icons/List'
import get from 'lodash/get'
import React, { useContext, useMemo } from 'react'
import { useClientOptions, useUser, Collapse } from '@ui/paintscout'
import { FormSectionTitle, Hidden } from '@ui/paintscout'
import type { RenderableItem } from '@paintscout/util/builder'
import { getFeatures, getItems, getObjectLabels, getQuoteOptions } from '@paintscout/util/builder'
import ConvertIcon from '@material-ui/icons/SwapHoriz'
import classnames from 'classnames'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { ItemColumn, ItemTableCell } from '../../../../ItemTable'
import ActionColumn from '../../../../ItemTable/columns/ActionColumn'

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    tableHead: {
      background: theme.palette.grey[200]
    },
    headerCell: {
      color: theme.palette.grey[800]
    },
    hidePrint: {
      '@media print': {
        display: 'none'
      }
    }
  })
}

export interface OptionsTableProps extends WithStyles<typeof styles> {
  collapsed?: boolean
  collapsedIfEmpty?: boolean
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  onItemAction: ItemTableProps['onItemAction']
  consumer?: 'crew' | 'customer'
  showValues?: boolean
  leftToggle?: boolean
  onToggle: (ev: React.MouseEvent) => any
  inPresentation?: boolean
  showAddButton?: boolean
  rightContent?: React.ReactNode
}

function OptionsTable({
  classes,
  collapsed,
  leftToggle,
  onItemSelection,
  onToggle,
  onItemAction,
  consumer: consumerProp,
  showValues: showValuesProp,
  rightContent,
  showAddButton,
  collapsedIfEmpty,
  inPresentation,
  ...itemTableProps
}: OptionsTableProps) {
  const { isAdmin, isCrew, isViewer } = useUser()
  const { quote, tableView, onReorder, onAddItem, isEditable, canAddAdditionalWork } = useContext(QuoteContext) // isEditable comes from here
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
  const features = getFeatures({ options })
  const hasAdditionalWork = get(features, 'quotes.additionalWork')
  const canConvertToLineItem = get(features, 'quotes.convertToLineItem')
  const hasOptionsApproval = options?.options?.optionsApproval?.enabled

  const consumer = consumerProp ? consumerProp : 'customer'
  const showValues = typeof showValuesProp !== 'undefined' ? showValuesProp : true

  const { addIndex, hideAllPrices } = getQuoteOptions({
    quote,
    options
  })

  const { allowQuoteAdditionalWork } = getQuoteOptions({ options })

  const items: RenderableItem[] = useMemo(
    () =>
      getItems({
        quote,
        section: 'options',
        view: tableView,
        consumer: 'customer',
        options,
        overrideOptions: { showPrices: true }
      }),
    [quote, tableView, options]
  )

  if (items.length === 0 && !canAddAdditionalWork) {
    return null
  }

  if (collapsed && !canAddAdditionalWork) {
    return null
  }

  function handleReorder({ items, addIndex }) {
    onReorder({ items, section: 'options', view: tableView, addIndex })
  }
  const hidePrint = items.length === 0 || collapsed // Also works to check hideOnEmpty for show
  const show = !collapsed && !(collapsedIfEmpty && hidePrint)
  const canShowAddButton = canAddAdditionalWork && showAddButton
  const showOptionsApproval = hasOptionsApproval && inPresentation

  return (
    <div className={classnames({ [classes.root]: true, [classes.hidePrint]: hidePrint })}>
      <FormSectionTitle
        title={objectLabels.option.plural}
        subTitle={
          'These items are optional additions and are not included in the total.' +
          (showOptionsApproval
            ? ` To add items to your ${objectLabels.quote.value.toLowerCase()}, just click +Add Option.`
            : '')
        }
        showToggle={canAddAdditionalWork}
        leftToggle={leftToggle}
        toggleValue={!collapsed}
        onToggle={onToggle}
        rightContent={rightContent}
      />
      <Collapse show={show}>
        <ItemTable
          draggable={!!isEditable}
          classes={{
            tableHead: classes.tableHead
          }}
          items={items}
          checkboxes={canAddAdditionalWork && onItemSelection ? true : false}
          addIndex={addIndex.options}
          addButtonLabel={'Add Area or Line Item'}
          showAddButton={canShowAddButton}
          onItemSelection={onItemSelection}
          onItemClick={
            (!quote.is_invoice || isAdmin) && canAddAdditionalWork
              ? (ev, item) => onItemAction('edit', 'options', item as RenderableItem)
              : null
          }
          onAddClick={(ev, insertAfterKey, type) => onAddItem('options', insertAfterKey, type)}
          onReorder={handleReorder}
          renderHeadColumns={() => (
            <>
              <ItemTableCell className={classes.headerCell}>Item</ItemTableCell>
              <ItemTableCell className={classes.headerCell} align="right">
                {showValues && !hideAllPrices && (
                  <Hidden smDown implementation="css">
                    {consumer === 'crew' ? 'hr' : objectLabels.currency.symbol}
                  </Hidden>
                )}
              </ItemTableCell>

              {/* action cell */}
              {canAddAdditionalWork && (
                <ItemTableCell isControl={true} className={classnames(classes.headerCell, classes.hidePrint)} />
              )}
            </>
          )}
          renderRowColumns={(item: RenderableItem) => {
            const actions = []
            actions.push({
              key: 'edit',
              label: 'Edit',
              icon: EditIcon
            })
            if ((!isCrew && !isViewer && !quote.is_invoice) || !hasAdditionalWork) {
              actions.push({
                key: 'move-to-bid',
                label: `Move to ${objectLabels.quote.value}`,
                icon: AddBoxIcon
              })
            }
            if (!quote.is_invoice && hasAdditionalWork && allowQuoteAdditionalWork) {
              actions.push({ key: 'move-to-pending', label: `Move to Additional Work`, icon: AddBoxIcon })
            }
            if (quote.is_invoice && hasAdditionalWork) {
              actions.push({
                key: 'move-to-pending',
                label: `Move to ${objectLabels.additionalWork.value}`,
                icon: AddBoxIcon
              })
            }
            if (!quote.is_invoice && hasOptionsApproval) {
              actions.push({
                key: 'move-to-added-options',
                label: `Move to Added Options`,
                icon: AddBoxIcon
              })
            }
            actions.push({
              key: 'archive',
              label: 'Archive',
              icon: ArchiveIcon
            })

            if (tableView === 'area' && item.type !== 'group') {
              actions.push({
                key: 'copy',
                label: 'Copy',
                icon: CopyIcon
              })
            }

            if (canConvertToLineItem && item.type !== 'line_item') {
              actions.push({
                key: 'convert',
                label: 'Convert to Line Item',
                icon: ConvertIcon
              })
            }

            if (item.type === 'group') {
              actions.push({
                key: 'ungroup',
                label: 'Ungroup',
                icon: ListIcon
              })
            }
            return (
              <>
                <ItemTableCell colSpan={2}>
                  <ItemColumn
                    item={item}
                    isEditable={canAddAdditionalWork}
                    showAmount={showValues && !hideAllPrices}
                    consumer={consumer}
                    section={'options'}
                  />
                </ItemTableCell>
                {canAddAdditionalWork && (
                  <ItemTableCell cancelDrag isControl classes={{ root: classes.hidePrint }}>
                    <ActionColumn
                      onActionClick={(ev, action) => onItemAction(action, 'options', item)}
                      actions={actions}
                    />
                  </ItemTableCell>
                )}
              </>
            )
          }}
          {...itemTableProps}
        />
      </Collapse>
    </div>
  )
}

export default withStyles(styles)(OptionsTable)

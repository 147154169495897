import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { InputField, Tooltip } from '../'
import { InlineColorPicker as CoreInlineColorPicker } from '@ui/core/ColorPicker'
import type { InlineColorPickerProps as CoreInlineColorPickerProps } from '@ui/core/ColorPicker'

export interface InlineColorPickerProps extends Omit<CoreInlineColorPickerProps, 'InputField' | 'Tooltip'> {}

const useStyles = makeStyles<Theme, InlineColorPickerProps>((theme) => ({
  root: {},
  input: {},
  popover: {
    position: 'absolute',
    zIndex: 2
  },
  inlineSwatch: {
    width: 28,
    height: 28,
    cursor: 'pointer',
    borderWidth: 1,
    borderStyle: 'solid'
  },
  popoverPaper: {
    marginTop: theme.typography.pxToRem(8)
  }
}))

function InlineColorPicker(props: InlineColorPickerProps) {
  const classes = useStyles(props)

  return <CoreInlineColorPicker InputField={InputField} Tooltip={Tooltip} {...props} classes={classes} />
}

export default InlineColorPicker

/**
 * @module builder
 */

export function roundPrice(n: number): string {
  let sanitized

  if (typeof n === 'string') {
    sanitized = parseFloat(n)
  } else if (typeof n !== 'number') {
    sanitized = 0
  } else {
    sanitized = n
  }

  return (Math.round(sanitized * 100) / 100).toFixed(2)
}

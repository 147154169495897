import type { S3UrlComponents } from './index'

export default function getUrlComponents(url: string): S3UrlComponents {
  // Convert this url into the S3UrlComponents type

  const regex = /amazonaws\.com\/(.*?)\?/
  const key = url.match(regex)[1]
  const regex2 = /(.*?)\?/
  const rawUrl = url.match(regex2)[1]
  const regex3 = /https:\/\/(.*?)\.s3/
  const bucket = url.match(regex3)[1]

  const urlComponents: S3UrlComponents = {
    key,
    rawUrl,
    bucket
  }

  return urlComponents
}

/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'

// Get the expiryDate from quote, or 0 if none set
export function getExpiryDate(args: { quote: QuoteDocument }): number {
  const { quote } = args

  const expiryDate = quote?.dates?.expiryDate || 0

  return expiryDate
}

import type { Theme } from '@material-ui/core'
import { Chip, makeStyles } from '@material-ui/core'
import type { QuoteTag } from '@paintscout/util/builder'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { Typography } from '..'

const useStyles = makeStyles<Theme, QuoteTagProps>({
  root: ({ tag, handleClick }) => {
    return {
      border: `1px solid ${tag.color}`,
      color: tag.color,
      backgroundColor: `${tag.color}25`,
      height: 'fit-content',
      maxWidth: 'inherit',
      cursor: handleClick ? 'pointer' : 'inherit'
    }
  },
  label: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'clip'
  },
  icon: ({ tag }) => {
    return {
      color: tag.color,
      '&:hover': {
        color: `${tag.color}90`
      }
    }
  }
})

interface QuoteTagProps {
  tag: QuoteTag
  handleDelete?: (tag: QuoteTag) => void
  handleClick?: (tag: QuoteTag) => void
}

const QuoteTagChip = (props: QuoteTagProps) => {
  const classes = useStyles(props)
  const { tag, handleDelete, handleClick } = props

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: QuoteTag) => {
    e.stopPropagation()
    handleClick(tag)
  }

  return (
    <Chip
      classes={{
        root: classes.root,
        label: classes.label
      }}
      label={
        <Typography variant="caption">
          <strong>{tag.label}</strong>
        </Typography>
      }
      onDelete={handleDelete ? () => handleDelete(tag) : undefined}
      onClick={handleClick ? (e) => onClick(e, tag) : undefined}
      deleteIcon={<CloseIcon fontSize="small" className={classes.icon} />}
      variant="outlined"
    />
  )
}

export default QuoteTagChip

import type { QuoteItemSection } from '..'

export function isInSection({
  areaSubstrateSection,
  areaSection,
  section
}: {
  areaSubstrateSection: QuoteItemSection
  areaSection: QuoteItemSection
  section: QuoteItemSection
}) {
  if (
    (areaSubstrateSection === 'bid' && areaSection === section) ||
    (areaSubstrateSection !== 'bid' && areaSubstrateSection === section)
  ) {
    return true
  } else {
    return false
  }
}

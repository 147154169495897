import type { QuoteDocument } from 'paintscout'
import type { QuoteItemConsumer, RenderableDiscount } from '../../..'

export default function convertDiscounts(quote: QuoteDocument, consumer: QuoteItemConsumer): RenderableDiscount[] {
  const discounts: RenderableDiscount[] = []

  if (quote.discount) {
    const descriptionComponents = []

    descriptionComponents.push(quote.discount_description ? quote.discount_description : 'Discount')
    if (quote.discount_type.value === 'percentage') {
      descriptionComponents.push(`(${Math.round(quote.discount * 100) / 100}%)`)
    }
    if (consumer === 'customer' && quote.discount !== 0) {
      discounts.push({
        key: '_global',
        description: descriptionComponents.join(' '),
        negative: quote.totals.total_discount < 0,
        amount: quote.totals.total_discount * -1
      })
    }
  }

  if (quote.discounts) {
    quote.discounts
      .filter((discount) => {
        if (discount._deleted) {
          return false
        }
        if (!discount.visible) {
          return false
        }
        if (consumer === 'crew' && !discount.hours) {
          return false
        }
        if (consumer === 'customer' && !discount.price && !discount.calculated_price) {
          return false
        }
        return true
      })
      .map((discount) => {
        const { key, label, price, type, calculated_price } = discount
        const amount = type.value === 'percentage' ? Math.round(calculated_price * 100) / 100 : Number(price)

        const descriptionComponents = []
        descriptionComponents.push(label ? label : 'Discount')
        if (type.value === 'percentage') {
          const priceDescription = Math.abs(Number(price))
          descriptionComponents.push(`(${priceDescription}%)`)
        }

        return {
          key,
          description: descriptionComponents.join(' '),
          negative: amount < 0,
          amount: amount * -1
        }
      })
      .forEach((discount) => {
        discounts.push(discount)
      })
  }

  return discounts
}

import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { Fade } from '@material-ui/core'
import Grid from '../../Grid'
import Spinner from '../../Spinner'

const styles = (theme: Theme) => {
  return createStyles({
    root: (props: DialogTitlePropsWithoutStyles) => {
      const r = {
        ...theme.typography.h3,
        position: 'relative' as const,
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px #DDDDDD solid',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.down('sm')]: {
          ...theme.typography.h2,
          padding: theme.spacing(0, 2.75, 1, 2.75),
          paddingTop: `calc(${theme.spacing(2)}px + env(safe-area-inset-top))`,
          borderBottom: 'none'
        }
      }
      if (props.color && props.color !== 'default') {
        r.color = theme.palette[props.color].main
      }
      return r
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    loading: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 30
    }
  })
}

export interface DialogTitlePropsWithoutStyles {
  children?: React.ReactNode
  rightContent?: React.ReactNode
  loading?: boolean
  icon?: React.ReactNode
  color?: 'warning' | 'success' | 'error' | 'default'
  fade?: boolean
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
}

export type DialogTitleProps = WithStyles<typeof styles> & DialogTitlePropsWithoutStyles

const DialogTitle = (props: DialogTitleProps) => {
  const { rightContent, fade = true } = props

  return (
    <Grid container alignItems={'center'} className={props.classes.root}>
      <Grid container item xs>
        {props.icon && (
          <Grid item className={props.classes.icon}>
            {props.icon}
          </Grid>
        )}
        <Grid item>{props.children}</Grid>
      </Grid>

      {rightContent && fade && (
        <Fade
          in={!props.loading}
          style={{
            transitionDelay: props.loading ? '800ms' : '0ms',
            visibility: !props.loading ? 'visible' : 'hidden'
          }}
          aria-hidden={props.loading}
        >
          <div>{rightContent}</div>
        </Fade>
      )}
      {rightContent && !fade && <div>{rightContent}</div>}
      <div className={props.classes.loading}>
        <Fade
          in={props.loading}
          style={{
            transitionDelay: props.loading ? '800ms' : '0ms'
          }}
          unmountOnExit
        >
          <Spinner size={15} fullHeight fullWidth />
        </Fade>
      </div>
    </Grid>
  )
}

export default withStyles(styles)(DialogTitle)

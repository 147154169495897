import type { QuoteArea } from 'paintscout'
import type { RenderableSubItem } from '../../../index'

export function getClientNote(args?: { area: QuoteArea }): RenderableSubItem[] {
  if (!args) {
    return []
  }

  const { area } = args

  if (!area || !area.client_note || typeof area.client_note !== 'string') {
    return []
  }

  return [
    {
      name: '',
      description: area.client_note as string,
      value: null
    }
  ]
}

import React from 'react'
import type { QuoteFile } from 'paintscout'
import type { FieldProps } from 'formik'
import type { UploadImageProps } from '../UploadImage'
import UploadImage from '../UploadImage'

export interface FormikUploadImageProps extends FieldProps<any>, Omit<UploadImageProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikUploadImage = ({
  field: {
    // remove field's onChange because it causes an error

    ...field
  },
  form,
  ...props
}: FormikUploadImageProps) => {
  const onUpload = (file: Omit<QuoteFile, 'visibility'>) => {
    form.handleChange({ target: { name: field.name, value: file } })
  }
  const onClear = () => {
    form.handleChange({ target: { name: field.name, value: null } })
  }

  return (
    <UploadImage
      {...field.value}
      {...props}
      cloudinaryPublicId={field.value?.cloudinaryPublicId}
      s3PublicKey={field.value?.s3PublicKey}
      src={field.value?.src}
      format={field.value?.format}
      onUpload={onUpload}
      onClear={onClear}
    />
  )
}

export default FormikUploadImage

import type { AreaSubstrate } from 'paintscout'

export default function convertPrep(areaSubstrate: AreaSubstrate): number {
  let prep = 0
  if (typeof areaSubstrate.prep === 'string') {
    prep = parseFloat(areaSubstrate.prep)
  } else {
    prep = Number(areaSubstrate.prep)
  }
  if (isNaN(prep)) {
    prep = 0
  }

  return prep
}

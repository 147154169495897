import type { QuoteItemSection } from '../../builder'

/**
 * Translate a section into its quote.totals[x] name
 */
export function sectionTranslate(groupSection: QuoteItemSection): string {
  return groupSection === 'additional'
    ? 'additionalWork'
    : groupSection === 'added-options'
      ? 'addedOptions'
      : groupSection // 'pending'
}

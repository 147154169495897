import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields

  const hasLeftContent =
    (fields?.leftText && fields.leftText.replace('<p></p>', '').trim() && fields?.leftType === 'text') ||
    (fields?.leftType === 'image' && fields?.leftImage)
  const hasRightContent =
    (fields?.rightText && fields.rightText.replace('<p></p>', '').trim() && fields?.rightType === 'text') ||
    (fields?.rightType === 'image' && fields?.rightImage)

  if (!hasLeftContent && !hasRightContent && !fields?.title) {
    return true
  }

  return false
}

import { gql } from 'apollo-boost'

export const VIEW_QUOTE = gql`
  query viewQuote($silent: Boolean, $pdf: Boolean, $email: String) {
    viewQuote(silent: $silent, pdf: $pdf, email: $email) {
      quote
      options
      estimator
      view
      tableView
    }
  }
`

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FieldArray } from 'formik'
import {
  Button,
  Grid,
  FieldGroup,
  FormikInputField,
  FormikLayoutSelect,
  FormikUploadImage,
  FormikDropdownSelect
} from '@ui/paintscout'
import { MoveRemoveButtons } from '../../util'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {},
  uploadImage: {
    maxWidth: '65%'
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const AwardsForm = ({ formik, ...props }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const classes = useStyles(props)
  const { section } = formik.values

  return (
    <Grid container spacing={3} alignItems={'flex-start'}>
      <Grid item md={4} xs={12}>
        <Field label={'Title'} name={'section.title'} fullWidth={true} component={FormikInputField} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Field component={FormikLayoutSelect} label={'Alignment'} name="section.fields.alignment" vertical={true} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Field
          component={FormikDropdownSelect}
          label={'Columns'}
          name="section.fields.columns"
          variant="outlined"
          fullWidth={true}
          options={[
            { label: 'Auto', value: 'auto' },
            { label: '2', value: '2' },
            { label: '3', value: '3' }
          ]}
        />
      </Grid>

      <Grid item md={12}>
        <FieldGroup label={'Awards Images/Logo'}>
          <FieldArray
            name="section.fields.rows"
            render={(arrayHelpers) => (
              <Grid container spacing={3} alignItems={'center'} justifyContent={'space-around'}>
                {(section?.fields?.rows ?? []).map((row, index) => (
                  <AwardsRow
                    key={index}
                    arrayHelpers={arrayHelpers}
                    section={section}
                    classes={classes}
                    index={index}
                  />
                ))}
                <Grid item xs={12}>
                  <Button
                    fullWidth={true}
                    variant={'outlined'}
                    onClick={() => {
                      arrayHelpers.push({ image: null })
                    }}
                  >
                    Add Award
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        </FieldGroup>
      </Grid>
    </Grid>
  )
}

function AwardsRow({ section, classes, index, arrayHelpers }) {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={11} sm={3} md={5}>
          <div className={classes.imageWrapper}>
            <Field
              component={FormikUploadImage}
              name={`section.fields.rows.${index}.image`}
              maxWidth={500}
              maxHeight={500}
              buttonHeight={100}
              quality={1}
              classes={{ root: classes.uploadImage }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Field component={FormikInputField} name={`section.fields.rows.${index}.link`} fullWidth label="Link" />
        </Grid>
        {section?.fields?.rows.length > 1 && (
          <MoveRemoveButtons arrayHelpers={arrayHelpers} index={index} section={section} />
        )}
      </Grid>
    </Grid>
  )
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(AwardsForm)

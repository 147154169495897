/**
 * @module builder
 */
import type { Payment, QuoteDocument, QuoteTotals } from 'paintscout'
import { getPayments } from '../get-payments'

/**
 * @param quote
 * @param previewPayment payment currently being requested
 * @returns The payment requests to display in the PaymentRequestDialog's preview component
 */
export default function getRequestedPaymentsForPreview(args: {
  quote: QuoteDocument
  previewPayment?: Payment
}): Payment[] {
  const { quote, previewPayment } = args
  const totals = quote?.totals
  const payments = getPayments({ quote })

  const requests = payments ? [...payments].filter((payment) => isValidRequest(payment, totals)) : []

  if (!!previewPayment && isValidRequest(previewPayment, totals)) {
    return requests.find((p) => p.id === previewPayment.id) ? requests : [...requests, previewPayment]
  }

  return requests
}

const isValidRequest = (payment: Payment, totals: QuoteTotals) =>
  payment.status === 'pending' && payment.amount <= totals?.balance_due

/**
 * @module builder
 */
import type { QuoteDocument, AreaSubstrate, OrderItem, OptionsDocument } from 'paintscout'
import type { QuoteItemSection } from '../../index'
import { getItemSection } from '../../index'
import { moveAreaSubstrate } from '../../index'
import { calculateQuote, moveTo } from '../../util'
import get from 'lodash/get'

// Moving a substrate moves the associated areaSubstrates(substrateKey === areaSubstrateKey)
// that are ALSO in the prevSection, to the new section. (provided they are NOT in a group atm)
// Note: For any area we move a(n) areaSubstrate(s) in, we need to regenerate the
// areas substrate_string and totals as we do when moving an area.
// Substrates do not track sections any longer, they are just rebuilt after
// all related areaSubstrates are moved (in calculateQuote)
export function moveSubstrate(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  section: QuoteItemSection
  previousSection: QuoteItemSection
  calculate?: boolean
  force?: boolean
}): QuoteDocument {
  const { key: substrateKey, section, previousSection, quote, options, calculate } = args

  // Return if the substrate doesn't exist
  if (!quote.substrates[substrateKey]) {
    return { ...quote }
  }

  let updatedQuote = {
    ...quote,
    substrates: {
      ...quote.substrates,
      // Substrates base substrate view customization off of this, so keep in sync
      [substrateKey]: moveTo(quote.substrates[substrateKey], section)
    }
  }

  // Check each areas areaSubstrates, add area to updatedQuote,
  // update area before adding if we end up changing an areaSubstrate
  updatedQuote.order.area.forEach((orderItem: OrderItem) => {
    if (orderItem.type !== 'area') {
      return
    }
    if (orderItem.parent) {
      return
    }
    if (orderItem._deleted) {
      return
    }

    const areaKey = orderItem.key
    const area = updatedQuote.areas[areaKey]
    const areaSection = getItemSection(area)

    Object.keys(area.substrates).forEach((rateSection: string) => {
      area.substrates[rateSection].forEach((substrate: AreaSubstrate) => {
        const substrateSection = getItemSection(substrate)

        let areaSubstrateKey: string
        if (quote && quote.substratesGroupBy === 'client_label') {
          areaSubstrateKey = get(substrate, 'rate.client_label')
          if (!areaSubstrateKey) {
            areaSubstrateKey = get(substrate, 'rate.label')
          }
          if (!areaSubstrateKey) {
            areaSubstrateKey = 'Custom'
          }

          areaSubstrateKey = areaSubstrateKey.trim().toLowerCase()
        } else {
          areaSubstrateKey = substrate.rate.name === 'custom' ? substrate.key : substrate.rate.name
        }

        const isMoveCandidate =
          (substrateSection === 'bid' && areaSection === previousSection) || substrateSection === previousSection

        let isInGroup = false
        if (updatedQuote.groups && quote.version >= 5) {
          for (const groupKey in quote.groups || []) {
            if (quote?.groups[groupKey]?.substrateChildren?.includes(substrate.key)) {
              isInGroup = true
            }
          }
        }
        if (isInGroup) {
          return
        }

        if (isMoveCandidate && areaSubstrateKey === substrateKey) {
          // This is the substrate in question - it needs to go to the specified section.

          updatedQuote = moveAreaSubstrate({ quote: updatedQuote, options, areaKey, key: substrate.key, section })
        } else if (substrateSection === 'bid') {
          // This is a different substrate that was in the "bid"
          // We need to move it to the previous section, as we're doing this flip-flop
          // between the area section & the substrate section
          updatedQuote = moveAreaSubstrate({
            quote: updatedQuote,
            options,
            areaKey,
            key: substrate.key,
            section: areaSection
          })
        }
      })
    })
  })

  // Substrates will be rebuilt in calculateQuote
  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}

/**
 * @module builder
 */
import type { QuoteDocument, QuoteJobAddress } from 'paintscout'
import type { QuoteIndexDocument } from '../../../../database/elasticsearch'
const addressFormat = require('address-format')

export function getJobAddress(args: {
  quote: QuoteDocument | QuoteIndexDocument
  addressType?: 'shippingAddress' | 'billingAddress'
}) {
  const { quote, addressType } = args
  if (addressType) {
    return quote[addressType] && Object.keys(quote[addressType]).length > 0 ? quote[addressType] : null
  } else {
    return quote.jobAddress && Object.keys(quote.jobAddress).length > 0 ? quote.jobAddress : null
  }
}

export function getFormattedJobAddress(args: { quote: QuoteDocument }) {
  const { quote } = args

  if (quote.jobAddress) {
    let text = ''
    if (quote.job_identifier) {
      text += quote.job_identifier + '\n'
    }

    const fullAddress = addressFormat({
      address: quote.jobAddress.address,
      city: quote.jobAddress.city,
      postalCode: quote.jobAddress.postal,
      subdivision: quote.jobAddress.province,
      countryCode: 'US'
    })

    if (fullAddress && fullAddress.length > 0) {
      text += fullAddress.join('\n')
    }

    return text
  }

  return null
}

export function formatJobAddress(args: { jobAddress?: QuoteJobAddress; jobIdentifier?: string }) {
  const { jobAddress, jobIdentifier } = args
  if (jobAddress) {
    let text = ''
    if (jobIdentifier) {
      text += jobIdentifier + '\n'
    }

    const fullAddress = addressFormat({
      address: jobAddress.address,
      city: jobAddress.city,
      postalCode: jobAddress.postal,
      subdivision: jobAddress.province,
      countryCode: 'US'
    })

    if (fullAddress && fullAddress.length > 0) {
      text += fullAddress.join('\n')
    }

    return text
  }

  return null
}

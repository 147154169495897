import React from 'react'
import type { AnimatePresenceProps as FMAnimatePresenceProps } from 'framer-motion'
import { AnimatePresence as FMAnimatePresence } from 'framer-motion'

export interface AnimatePresenceProps extends FMAnimatePresenceProps {
  children?: React.ReactNode
}

// TODO: Fix this props:any ... It's dumb, and sort of seems like
// framer-motion needs upgrading, but that causes different problems.
const AnimatePresence = function AnimatePresence(props: any) {
  return <FMAnimatePresence {...props} />
}

export { AnimatePresence }

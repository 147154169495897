import type { OptionsDocument, QuoteDocument, QuoteSubstrate } from 'paintscout'
import { generateSubstrateDescription } from '../../util/generate-substrate-description/generate-substrate-description'

interface UpdatedSubstrates {
  [key: string]: QuoteSubstrate
}

// Update descriptions/area_strings for each quote.substrate on QuoteDocument
export function updateSubstrateDescriptions(args: {
  quote: QuoteDocument
  options: OptionsDocument
}): UpdatedSubstrates {
  const { quote, options } = args
  const updatedQuote = {
    ...quote
  }
  // Update descriptions/area_strings of all substrates
  Object.keys(updatedQuote.substrates).forEach((substrateKey) => {
    if (updatedQuote.substrates[substrateKey]) {
      const defaultValue = generateSubstrateDescription({
        quote: updatedQuote,
        clientOptions: options,
        substrateKey: substrateKey.toString()
      })
      const areaString = updatedQuote.substrates[substrateKey].area_string
        ? { ...updatedQuote.substrates[substrateKey].area_string, default: defaultValue }
        : { use_custom: false, default: defaultValue }
      updatedQuote.substrates[substrateKey].area_string = areaString
    }
  })

  const { substrates: updatedSubstrates } = updatedQuote

  return updatedSubstrates
}

import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { Grid, FilePreview, ImagePlaceholder, Typography } from '@ui/paintscout'
import { padArray } from '@paintscout/util/util'

const useStyles = makeStyles((_theme: Theme) => {
  return {
    root: {},
    content: {},
    imageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      '& a': {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    imageRoot: {
      maxWidth: '80%',

      '& img': {
        maxHeight: 'none'
      }
    },
    title: {
      whiteSpace: 'pre-wrap'
    }
  }
})

export default function Awards(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { section, isPreview, showHelp, WrapperComponent } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 6

  const { fields, title } = section
  let rows = []
  if (fields?.rows) {
    rows = (fields?.rows ?? []).filter((row) => row.image)
  }
  if (isPreview && showHelp) {
    rows = padArray(rows, 3)
  }

  const columns = getColumns(rows.length, fields?.columns ?? 'auto')

  const getAlignment = (alignment) => {
    switch (alignment) {
      case 'top':
        return 'flex-start'
      case 'bottom':
        return 'flex-end'
      default:
        return 'center'
    }
  }

  return (
    <WrapperComponent {...props}>
      <Grid
        container
        spacing={responsiveSpacing}
        justifyContent={'space-between'}
        alignItems={getAlignment(fields?.alignment)}
      >
        {title && (
          <Grid item md={3}>
            <Typography variant={'h2'} classes={{ root: classes.title }}>
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item md={9}>
          <Grid container spacing={3} justifyContent={'space-evenly'} alignItems={'center'}>
            {rows.map((row, index) => {
              if (!row.image && !showHelp) {
                return null
              }
              return (
                <Grid item {...columns} key={index}>
                  <div className={classes.imageWrapper}>
                    {row.image && (
                      <>
                        {row.link && (
                          <a href={row.link} target="_blank" rel="noreferrer">
                            <FilePreview file={row.image} classes={{ root: classes.imageRoot }} />
                          </a>
                        )}
                        {!row.link && <FilePreview file={row.image} classes={{ root: classes.imageRoot }} />}
                      </>
                    )}

                    {!row.image && isPreview && showHelp && <ImagePlaceholder height={150} width={150} title="Award" />}
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </WrapperComponent>
  )
}

function getColumns(argRows, columns) {
  let rows = argRows
  if (columns === '2') {
    rows = 2
  } else if (columns === '3') {
    rows = 3
  }

  switch (rows) {
    case 1:
      return {
        xs: 12 as const,
        sm: 12 as const,
        md: 12 as const
      }
    case 2:
      return {
        xs: 6 as const,
        sm: 6 as const,
        md: 6 as const
      }
    case 3:
      return {
        xs: 6 as const,
        sm: 4 as const,
        md: 4 as const
      }
    default:
      return {
        xs: 6 as const,
        sm: 3 as const,
        md: 3 as const
      }
  }
}

/**
 * @module builder
 */
import type { QuoteAreaTypes, QuoteArea, OrderItem } from 'paintscout'
import type { UpdateableArea } from '../../index'
import { convertDimensions } from './convert-area-dimensions'

import { getCheckboxes } from '../../util'
import get from 'lodash/get'

// Create a quote area
export function createQuoteArea(area: UpdateableArea): QuoteArea {
  const areaType = get(area, 'areaType', 'room')
  const quoteType = get(area, 'quoteType', 'int')

  const checkboxes = getCheckboxes(area)

  return {
    key: area.key,
    substrates: {},
    substrateOrder: [] as OrderItem[],

    show: true,
    show_price: true,
    use_total: true,
    show_crew: true,

    area_label: {
      use_custom: true,
      value: area.label
    },

    area_type: {
      value: areaType as QuoteAreaTypes
    },

    int_ext: {
      value: quoteType
    },

    dimensions: convertDimensions(area.dimensions),

    client_note: '',
    internal_note: '',

    product_string: {},

    substrate_string: {},

    files: [],
    quantity: 1,

    totals: {},

    ...checkboxes
  }
}

import React from 'react'
import type { DialogStackContextValue } from './DialogStackContext'
import DialogStackContext from './DialogStackContext'
import DialogStackRenderer from './DialogStackRenderer'

export interface DialogStackProviderProps {
  children?: React.ReactNode
  innerRef?: React.RefObject<DialogStackProvider>
}

export interface DialogStackProviderState {
  isFirstRender: boolean
  value: DialogStackContextValue
}

// if this is ever refactored to a function component, you will run into issues where class component consumers
// of DialogStack get stale functions :| so... basically don't convert it to a function component
export class DialogStackProvider extends React.PureComponent<DialogStackProviderProps, DialogStackProviderState> {
  constructor(props: DialogStackProviderProps) {
    super(props)

    this.state = {
      isFirstRender: true,
      value: {
        stack: [],
        renderPortal: false,
        openDialog: this.openDialog,
        dismissDialog: this.dismissDialog,
        dismissDialogs: this.dismissDialogs,
        dismissAllDialogs: this.dismissAllDialogs,
        setRenderPortal: this.handleSetRenderPortal
      }
    }
  }

  public componentDidUpdate() {
    if (this.state.isFirstRender) {
      this.setState({ isFirstRender: false })
    }
  }

  public render() {
    const { children } = this.props
    const { renderPortal } = this.state.value

    return (
      <DialogStackContext.Provider value={this.state.value}>
        {children}
        {/* 
          skip the first render incase a renderPortal exists further down the tree ...  
          it's gross but otherwise it'd render a dialog twice on the first render - once here and once in a <DialogPortal />
        */}
        {!renderPortal && !this.state.isFirstRender && <DialogStackRenderer />}
      </DialogStackContext.Provider>
    )
  }

  public openDialog = (component: React.ComponentType<any>, props: any = {}, options: { replace?: boolean } = {}) => {
    let stack = [...this.state.value.stack]

    if (options.replace) {
      stack = stack.slice(0, stack.length - 1)
    }

    this.setState({
      value: {
        ...this.state.value,

        stack: [...stack, { component, props }]
      }
    })
  }

  public popDialog = (amount = 1) => {
    // using a function in setState will ensure we always have the correct stack if popDialog is called multiple times before next render
    return this.setState((prevState) => ({
      value: {
        ...prevState.value,
        stack: prevState.value.stack.slice(0, prevState.value.stack.length - amount),
        isDismissingLast: false
      }
    }))
  }

  public dismissDialog = () => {
    this.dismissDialogs(1)
  }

  public dismissDialogs = (amount?: number) => {
    if (this.state.value.stack.length === 1) {
      this.dismissAllDialogs()
    } else {
      this.popDialog(amount)
    }
  }

  public dismissAllDialogs = () => {
    this.setState((prevState) => ({
      value: { ...prevState.value, stack: [] }
    }))
  }

  public handleSetRenderPortal = (ref: any) => {
    this.setState((prevState) => ({
      value: { ...prevState.value, renderPortal: !!ref }
    }))
  }
}

export default DialogStackProvider

import { useClientOptions, Scaler } from '@ui/paintscout'
import { getQuotePresentation } from '@paintscout/util/builder'
import React, { useState } from 'react'
import type { QuoteProps } from '../Quote'
import { useQuote, QuoteContextProvider } from '../context'
import { PresentationNavContextProvider, PresentationNavMobile, Presentation } from '../presentation'
import isEqual from 'lodash/isEqual'
import PaymentRequests from '../PaymentRequests'

// prevents re-render on every keystroke by using isEqual to compare props
const ScaledQuote = React.memo(function ScaledQuote(props: QuoteProps & { classes: any }) {
  const { quote, classes, view } = props
  const [currentPage, setCurrentPage] = useState(null)
  const [navOpen, setNavOpen] = useState(false)
  const { options } = useClientOptions()
  const { wisetackPromo, estimator } = useQuote()
  const presentation = getQuotePresentation({ options, quote, view })

  return (
    <Scaler fixedWidth={980}>
      <QuoteContextProvider quote={quote} estimator={estimator} contextValue={{ wisetackPromo }}>
        <PresentationNavContextProvider
          open={navOpen}
          currentPage={currentPage}
          view={view}
          popupMenu={true}
          onAcceptClick={() => {}}
          onPageClick={handleNavClick}
          onOpen={() => setNavOpen(true)}
          onClose={() => setNavOpen(false)}
        >
          {presentation.key !== 'default-product-order' && (
            <PresentationNavMobile classes={{ toolbar: classes.previewToolbar }} />
          )}
          <div className={presentation.key === 'default-product-order' ? null : classes.presentationWrapper}>
            <PaymentRequests />
            <Presentation presentation={presentation} classes={{}} page={currentPage} />
          </div>
        </PresentationNavContextProvider>
      </QuoteContextProvider>
    </Scaler>
  )

  function handleNavClick(ev: React.MouseEvent, value: string) {
    setCurrentPage(value)
    setNavOpen(false)
  }
}, isEqual)

export default ScaledQuote

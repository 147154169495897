import React from 'react'

import { useMediaQuery } from '@ui/paintscout'

import QuoteHeaderMobile from './QuoteHeaderMobile'
import QuoteHeaderFull from './QuoteHeaderFull'

export interface QuoteHeaderProps {
  isEditable?: boolean
  variant?: 'default' | 'work-order' | 'product-order-form'
}

function QuoteHeader(props: QuoteHeaderProps) {
  const smDown = useMediaQuery('sm')

  if (smDown) {
    return <QuoteHeaderMobile {...props} />
  } else {
    return <QuoteHeaderFull {...props} />
  }
}

export default QuoteHeader

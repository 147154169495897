import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { Grid, FilePreview, Typography, HtmlContent } from '@ui/paintscout'
import Star from '@material-ui/icons/Star'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import classnames from 'classnames'

const imageHeight = '4em'
const starHeight = '2em'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {},

    singleTestimonial: {
      display: 'flex'
    },
    image: {
      padding: 0,
      textAlign: 'left',
      ...theme.typography.body1,
      height: imageHeight,

      '& img': {
        height: imageHeight,
        width: 'auto'
      }
    },
    roundImage: {
      '& img': {
        borderRadius: '50%',
        width: imageHeight
      }
    },
    imagePlaceholder: {
      ...theme.typography.body1,
      height: imageHeight,
      '& svg': {
        ...theme.typography.body1,
        fill: theme.palette.primary.main,
        width: imageHeight,
        height: imageHeight
      }
    },

    bottomSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    stars: {
      ...theme.typography.body1,
      height: starHeight,
      '& svg': {
        ...theme.typography.body1,
        width: starHeight,
        height: starHeight,
        fill: theme.palette.primary.main
      }
    },
    credit: {
      marginLeft: theme.spacing(0.5)
    }
  }
})

export default function Testimonials(props: PresentationSectionProps) {
  const { section, WrapperComponent } = props

  const classes = useStyles(props)
  const { fields, title } = section
  const { rows } = fields

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 6

  function TestimonialsRow({ row }) {
    const hasContent = !!(row?.content && row.content.replace('<p></p>', '').trim())
    if (!hasContent) {
      return null
    }

    const isSquareImage = row?.image?.width && row?.image?.height && row.image.width === row.image.height

    return (
      <Grid item md={4} classes={{ root: classes.singleTestimonial }}>
        <Grid container spacing={3} justifyContent={'flex-start'} direction="column" wrap="nowrap">
          <Grid item md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {row.image ? (
                  <FilePreview
                    file={row.image}
                    classes={{ root: classnames(classes.image, { [classes.roundImage]: isSquareImage }) }}
                  />
                ) : (
                  <div className={classes.imagePlaceholder}>
                    <AccountCircleIcon />
                  </div>
                )}
              </Grid>
              {hasContent && (
                <Grid item xs={12}>
                  <HtmlContent content={row.content} classes={{ root: classes.content }} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={12} classes={{ root: classes.bottomSection }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stars />
              </Grid>
              {row.credit && (
                <Grid item xs={12}>
                  <Typography variant={'subtitle1'} className={classes.credit}>
                    {row.credit}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3}>
        {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <Grid container spacing={responsiveSpacing}>
            {rows.map((row, index) => (
              <TestimonialsRow key={index} row={row} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </WrapperComponent>
  )

  function Stars() {
    return (
      <div className={classes.stars}>
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </div>
    )
  }
}

import React from 'react'

import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TileList, useDialogs } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'

export interface TileListDialogProps extends DialogProps {
  title: string
  items: {
    key: string
    label: string
    subLabel?: string
  }[]
  onItemSelect: (key: string) => void
}

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 0
  },
  tileList: {
    paddingTop: theme.spacing(1)
  }
}))

export default function TileListDialog({ title, items, onItemSelect, ...otherProps }: TileListDialogProps) {
  const classes = useStyles()
  const { dismissDialog } = useDialogs()
  return (
    <Dialog {...otherProps} maxWidth={'md'} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.tileList}>
          <TileList
            items={items}
            onSelect={(_, key) => {
              onItemSelect(key as string)
            }}
            subLabelField="subLabel"
          />
        </div>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button onClick={dismissDialog} variant="text">
            Cancel
          </Button>
        }
      />
    </Dialog>
  )
}

import { QuotePdfDialog } from '@ui/react-quote'
import { useClientOptions, useDialogs } from '@ui/paintscout'
import type { QuoteDocument } from 'paintscout'
import { useView } from '../components/ViewProvider'

export function useDownloadQuote() {
  const { view, revision, url, companyId, email } = useView()
  const { options } = useClientOptions()

  const dialogContext = useDialogs()

  const downloadQuote = async (quote: QuoteDocument) => {
    return new Promise((resolve) => {
      const { openDialog, dismissDialog } = dialogContext
      openDialog(QuotePdfDialog, {
        quote,
        options,
        view,
        revision,
        companyId,
        custViewUrl: url + (email ? `:${email}` : ''),
        onConfirm: () => {
          dismissDialog()
          resolve(null)
        }
      })
    })
  }

  return {
    downloadQuote
  }
}

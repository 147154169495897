import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import classnames from 'classnames'
import type { QuoteFile } from 'paintscout'

const useStyles = makeStyles<Theme, ResponsiveImageProps>(
  (_theme) => ({
    root: {
      color: 'blue',
      objectFit: 'contain'
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '100%',
      objectFit: 'contain'
    }
  }),
  { name: 'ResponsiveImage' }
)

export interface ResponsiveImageProps {
  classes?: StyleClasses<typeof useStyles>
  file: QuoteFile
  size?: number
  page?: number
  onClick: (ev: any) => any
}

function ResponsiveImage(props: ResponsiveImageProps) {
  const classes = useStyles(props)
  const { file, onClick, size } = props
  const imageRef = useRef(null)

  // Need to organize or src url here for image specific sizing and type demands
  if (file.type.includes('svg')) {
    const generatedSrc = generateSrc({ file, svg: true })
    return (
      <>
        <img
          ref={imageRef}
          className={classnames(classes.fileRoot, classes.image, classes.root)}
          src={generatedSrc}
          onClick={onClick}
          width={'auto'}
        />
      </>
    )
  }

  if (size) {
    // Maybe not?
    const generatedSrc = generateSrc({ file, size })
    return (
      <>
        <img
          ref={imageRef}
          className={classnames(classes.fileRoot, classes.image)}
          src={generatedSrc}
          onClick={onClick}
        />
      </>
    )
  }

  const generatedSrc = generateSrc({ file })
  return (
    <>
      <img
        ref={imageRef}
        srcSet={generatedSrc}
        sizes="
            (max-width: 400px) 300px,
            (max-width: 600px) 500px,
            (max-width: 800px) 700px,
            (max-width: 1000px) 900px,
            1200px"
        className={classnames(classes.fileRoot, classes.image)}
        onClick={onClick}
      />
    </>
  )
}

function generateSrc({ file, svg, size }: { file: QuoteFile; svg?: boolean; size?: number }): string {
  const regex = /(.*amazonaws\.com)/
  const base = file.src.match(regex)[1]
  const fileIndex = file.s3PublicKey.lastIndexOf('/')
  const fileName = file.s3PublicKey.slice(fileIndex)
  const id = fileName.split('.')[0]
  const idIndex = file.s3PublicKey.indexOf(id)
  const dist = file.s3PublicKey.slice(0, idIndex)

  // Svg url
  if (svg) {
    return base + `/${dist}` + id + '/svg' + fileName
  }

  // Singular size url
  if (size) {
    return base + `/${dist}` + id + `/${size}` + fileName
  }

  // Keep in sync w/ apps/api/src/services/task/image-resize/image-resize.ts
  const resizeWidths = [50, 300, 500, 700, 900, 1200]
  // Resized srcSet
  const lines = resizeWidths.map((width) => {
    return base + `/${dist}` + `${id}` + `/${width}` + `${fileName}` + ` ${width}w`
  })
  return lines.join(', ')
}

export default ResponsiveImage

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Typography, useClientOptions } from '@ui/paintscout'
import type { QuoteItemSection } from '@paintscout/util/builder'
import { formatCurrency, getArea } from '@paintscout/util/builder'
import React from 'react'
import { useQuote } from '../../context'

const useStyles = makeStyles((theme: Theme) => ({
  splitValue: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    },
    lineHeight: theme.typography.pxToRem(7),
    textAlignLast: 'justify',
    fontWeight: theme.typography.fontWeightRegular,
    '& span': {
      fontWeight: theme.typography.fontWeightMedium
    },
    height: 0
  }
}))

interface SplitAreaPriceProps {
  areaKey: string
  section: QuoteItemSection
}

export default function SplitAreaPrice({ areaKey, section }: SplitAreaPriceProps) {
  const classes = useStyles()
  const { quote } = useQuote()
  const { options } = useClientOptions()

  const area = getArea({ quote, options, key: areaKey, trimBySubstrateSection: section })
  const laborPrice = area.totals?.price?.useCustom ? area.totals?.price?.custom : area.totals?.price?.default
  const materialPrice = area.totals?.materials?.useCustom
    ? area.totals?.materials?.custom
    : area.totals?.materials?.default

  return (
    <Typography variant="caption" classes={{ root: classes.splitValue }}>
      <div>{`Materials: ${formatCurrency({ options, value: materialPrice || 0 })}`}</div>
      <br />
      <div>{`Labor: ${formatCurrency({ options, value: laborPrice || 0 })}`}</div>
      <br />
      {/* this is a hack to keep the text right justified with textAlignLast: 'justify'
      it uses the U+2800 unicode character, which is invisible */}
      <span>{`⠀ ${formatCurrency({ options, value: area.totals?.totalPrice || 0 })}`}</span>
    </Typography>
  )
}

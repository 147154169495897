import type { QuoteDocument } from 'paintscout'
import { newAdd } from './new-add'
import { oldAdd } from './old-add'

// Correctly add based on quote version
export function add(dst: any, src: any, quote: QuoteDocument) {
  const quoteVersion = quote?.version
  let result

  // < V3 use old add
  if (quoteVersion <= 2) {
    result = oldAdd(dst, src)
  } else {
    result = newAdd(dst, src)
  }
  return result
}

import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import { Grid, useClientOptions } from '@ui/paintscout'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import PresentationAcceptButton from '../PresentationAcceptButton'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      '@media print': {
        display: 'none'
      }
    },
    appBar: {
      background: theme.palette.common.white,
      color: theme.palette.grey[700],

      '& $left': {
        display: 'flex',
        alignItems: 'center',
        '& img': {
          maxHeight: theme.spacing(6),
          width: 'auto',
          marginRight: theme.spacing(3)
        },
        '& $pageButton': {
          color: theme.palette.primary.main,
          minWidth: 0,
          minHeight: 64,
          borderRadius: 0,
          paddingLeft: 0,
          paddingRight: 0,
          borderTop: `2px solid transparent`,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          '&:hover': {
            background: 'none'
          }
        }
      }
    },

    toolbar: {
      width: 1150,
      maxWidth: '100%',
      margin: `0 auto`,
      boxSizing: 'border-box',
      padding: `0 ${theme.spacing(1)}px`,
      minHeight: theme.paintscout.header.height.md
    },

    button: {},
    left: {},
    pageButton: {},
    popupButton: {
      '&:hover': {
        background: 'transparent'
      }
    }
  }
})

export interface PresentationNavFullProps {}

export default function PresentationNavFull({ ...props }: PresentationNavFullProps) {
  const classes = useStyles(props)

  const { options } = useClientOptions()

  const logo = options?.options?.logo?.value

  return (
    <div className={classes.root}>
      <AppBar position="fixed" classes={{ root: classes.appBar }}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <div className={classes.left}>
                <img src={logo} />
              </div>
            </Grid>
            <Grid item>
              <PresentationAcceptButton showPrice={!options.options.quotes.hideTotalOnPresentation} showMore={true} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

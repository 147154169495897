import React from 'react'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityFooter from '../ActivityFooter'
import ActivityTitle from '../ActivityTitle'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import ActivityContent from '../ActivityContent'
import ActivityEvents from '../ActivityEvents'
import QuoteLabel from '../QuoteLabel'

export default function QuoteErrorActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props
  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            <QuoteLabel activity={activity} /> <Highlight color="error">error</Highlight>
          </>
        ) : (
          <>
            <Highlight color="error">Error</Highlight>
          </>
        )}
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {activity.details.events?.length > 0 && (
        <ActivityContent>
          <ActivityEvents events={activity.details.events} />
        </ActivityContent>
      )}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

import type { OptionsDocument } from 'paintscout'
import type { Context } from '../template'

export interface OptionsContext extends Context {
  companyName: string
  companyAddress: string
  companyCity: string
  companyCountry: string
  companyPhone: string
  companyAdditional: string
  companyPostal: string
  companyProv: string
  primaryColor: string
  secondaryColor: string
}

export function createOptionsContext(args: {
  options?: OptionsDocument
  override?: Partial<OptionsContext>
}): OptionsContext {
  const { options, override = {} } = args

  return {
    companyName: options.options.companyName.value as string,
    companyAddress: options.options.companyAddress.value as string,
    companyCity: options.options.companyCity.value as string,
    companyCountry: options.options.companyCountry.value as string,
    companyPhone: options.options.companyPhone.value as string,
    companyAdditional: (options.options.companyAdditional?.value ?? '') as string,
    companyPostal: options.options.companyPostal.value as string,
    companyProv: options.options.companyProv.value as string,
    primaryColor: options.options.primaryColor?.value as string,
    secondaryColor: options.options.secondaryColor?.value as string,
    ...override
  }
}

import uniqBy from 'lodash/uniqBy'
import type { OrderItem, QuoteDocument } from 'paintscout'
import type { QuoteItemSection, QuoteItemView } from '../../../index'

// Get order of items on quote for given view, and section.
// Maintain specific section order if present.
// If item is in sectionOrder nad itemOrder, sectionOrder takes precedence *unless* itemOrder has a parent(item is grouped).
export const getItemOrder = (view: QuoteItemView, section: QuoteItemSection, quote: QuoteDocument): OrderItem[] => {
  const itemOrder = quote.order?.[`${view}`] ?? []

  // Maintain order of options or archived sections
  let sectionOrder: OrderItem[]
  if (section === 'options') {
    sectionOrder = quote.order?.[`${view}Options`] ? quote.order?.[`${view}Options`] : []
  } else if (section === 'archived') {
    sectionOrder = quote.order?.[`${view}Archived`] ? quote.order?.[`${view}Archived`] : []
  } else {
    sectionOrder = []
  }

  // Before dupe check, need to do a dupe w/ parent check.
  if (itemOrder.length && sectionOrder.length) {
    sectionOrder = sectionOrder.filter((orderItem) => {
      // If itemOrder has same key that also has a parent key, dont include in sectionOrder
      // Keep on the quote so if ungrouped it retains its order.
      const hasParent = itemOrder.find((substrateOrderItem) => {
        return substrateOrderItem.key === orderItem.key && substrateOrderItem.parent
      })
      return !hasParent
    })
  }

  // Remove duplicates before returning
  return uniqBy([...sectionOrder, ...itemOrder], 'key')
}

import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core'

import FormatSize from '@material-ui/icons/FormatSize'

import type { InputFieldProps } from '../InputField'
import InputField from '../InputField'
import Select from '../Select'
import MenuItem from '../MenuItem'
import { InlineColorPicker } from '../ColorPicker'
import Grid from '../Grid'
import FontSelect from '../FontSelect'
import Typography from '../Typography'

import classnames from 'classnames'

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    input: {},
    inputOpen: {
      $inputRoot: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    toggleButton: {
      cursor: 'pointer',
      color: theme.palette.grey[800]
    },
    toggleButtonActive: {
      color: theme.palette.primary.main
    },
    formattingBoxWrapper: {
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.common.black}`,
      borderTop: 'none',
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    },
    formattingBox: {
      padding: theme.spacing(2)

      // borderWidth: 1,
      // borderStyle: 'solid',
      // borderColor: theme.palette.grey[300],
      // background: theme.palette.grey[100]
    }
  })
}

export interface TextFormatInputFieldProps extends Omit<InputFieldProps, 'classes'>, WithStyles<typeof styles> {
  styleValue?: any
  onStyleChange?: (styles: any) => void
  open?: boolean
  onOpenToggle?: (ev: React.MouseEvent) => void
}

const sizeOptions = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' }
]

const shadowOptions = [
  { label: 'None', value: 'none' },
  { label: 'Small', value: 'small' },
  { label: 'Large', value: 'large' }
]

const transformOptions = [
  { label: 'None', value: 'none' },
  { label: 'Lowercase', value: 'lowercase' },
  { label: 'Uppercase', value: 'uppercase' }
]

function TextFormatInputField({
  classes,
  styleValue,
  onStyleChange,
  open,
  onOpenToggle,
  ...baseInputProps
}: TextFormatInputFieldProps) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <InputField
          {...baseInputProps}
          fullWidth={true}
          classes={{ root: classnames({ [classes.input]: true, [classes.inputOpen]: open }) }}
          endAdornment={
            <FormatSize
              onClick={onOpenToggle}
              classes={{ root: classnames({ [classes.toggleButton]: true, [classes.toggleButtonActive]: open }) }}
            />
          }
        />
      </Grid>
      {open && (
        <Grid item xs={12}>
          <div className={classes.formattingBoxWrapper}>
            <div className={classes.formattingBox}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <FontSelect label={'Font'} fullWidth={true} onChange={handleFontChange} value={styleValue?.font} />
                </Grid>
                <Grid item md={6}>
                  <InlineColorPicker
                    label={'Color'}
                    fullWidth={true}
                    onChange={handleColorChange}
                    value={styleValue?.color}
                  />
                </Grid>
                <Grid item md={6}>
                  <Select
                    label={'Size'}
                    variant={'outlined'}
                    fullWidth={true}
                    onChange={handleSelectChange('size')}
                    value={styleValue?.size}
                  >
                    {sizeOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <Typography>{item.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <Select
                    label={'Shadow'}
                    variant={'outlined'}
                    fullWidth={true}
                    onChange={handleSelectChange('shadow')}
                    value={styleValue?.shadow}
                  >
                    {shadowOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <Typography>{item.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <Select
                    label="Transform"
                    variant="outlined"
                    fullWidth={true}
                    onChange={handleSelectChange('transform')}
                    value={styleValue?.transform}
                  >
                    {transformOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <Typography>{item.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  )

  function handleSelectChange(name: string) {
    return (ev: React.ChangeEvent<HTMLSelectElement>) => {
      const value = ev.target.value

      onStyleChange({
        ...styleValue,
        [name]: value
      })
    }
  }

  function handleColorChange(color: string) {
    onStyleChange({
      ...styleValue,
      color
    })
  }

  function handleFontChange(font: string) {
    onStyleChange({
      ...styleValue,
      font
    })
  }
}

export default withStyles(styles)(TextFormatInputField)

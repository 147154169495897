import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { Typography, FilePreview, ImagePlaceholder, Grid, HtmlContent } from '@ui/paintscout'

import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {},
    image: {
      '& img': {
        borderRadius: theme.spacing(0.5)
      }
    },
    hasTitle: {
      padding: `0.7em 0`
    }
  }
})

export default function Services(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { section, WrapperComponent, showHelp } = props
  const { fields, title } = section

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 6

  const { rows } = fields

  const getWidth = (width) => {
    switch (width) {
      case 'small':
        return { imageWidth: 2, contentWidth: 10, mobileImageWidth: 3, mobileContentWidth: 9 }
      case 'medium':
        return { imageWidth: 4, contentWidth: 8, mobileImageWidth: 4, mobileContentWidth: 8 }
      case 'large':
        return { imageWidth: 6, contentWidth: 6, mobileImageWidth: 4, mobileContentWidth: 8 }
      default:
        return { imageWidth: 3, contentWidth: 9, mobileImageWidth: 3, mobileContentWidth: 9 }
    }
  }
  const { imageWidth, contentWidth, mobileImageWidth, mobileContentWidth } = getWidth(fields?.width ?? 2)

  const getAlignment = (alignment) => {
    switch (alignment) {
      case 'top':
        return 'flex-start'
      case 'bottom':
        return 'flex-end'
      default:
        return 'center'
    }
  }
  const verticalAlignment = getAlignment(fields?.alignment ?? 'center')

  function ServiceRow({ row }) {
    return (
      <Grid container spacing={responsiveSpacing} alignItems={verticalAlignment}>
        {(row.image || showHelp) && (
          <Grid item xs={mobileImageWidth as any} md={imageWidth as any}>
            <div className={classnames({ [classes.hasTitle]: row.title })}>
              <Grid container spacing={3}>
                {/* {row.title && (
                <Grid item md={12}>
                  <Typography variant={'h3'}>&nbsp;</Typography>
                </Grid>
              )} */}
                {row.image && (
                  <Grid item md={12}>
                    <FilePreview file={row.image} classes={{ root: classes.image }} />
                  </Grid>
                )}
                {!row.image && showHelp && (
                  <Grid item md={12}>
                    <ImagePlaceholder title={'Image'} width={'100%'} height={200} />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        )}

        <Grid item xs={mobileContentWidth as any} md={contentWidth as any}>
          <Grid container spacing={3}>
            {row.title && (
              <Grid item md={12}>
                <Typography variant={'h3'}>{row.title}</Typography>
              </Grid>
            )}
            {row.content && (
              <Grid item md={12}>
                <HtmlContent content={row.content} classes={{ root: classes.content }} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3}>
        {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        {rows.map((row, i) => (
          <Grid key={i} item md={12}>
            <ServiceRow row={row} />
          </Grid>
        ))}
      </Grid>
    </WrapperComponent>
  )
}

import React from 'react'
import type { PresentationSectionProps } from '../../'

import QuoteComponent from '../../../Quote/Quote'

export default function Quote(props: PresentationSectionProps) {
  const { WrapperComponent } = props

  return (
    <WrapperComponent {...props}>
      <QuoteComponent inPresentation={true} />
    </WrapperComponent>
  )
}

import React from 'react'
import type { PresentationHelpProps } from '../../'

import { Typography, Alert } from '@ui/paintscout'

export default function Help({ ..._props }: PresentationHelpProps) {
  return (
    <Alert severity="info">
      <Typography>Add links to PDF documents for your customers to access.</Typography>
      <Typography>We'll show a thumbnail of your document - along with a link to view the full PDF.</Typography>
    </Alert>
  )
}

import type { QuoteDocument, QuoteTotals } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import get from 'lodash/get'
import clone from 'lodash/clone'
import { add } from '../util/add'
import cloneDeep from 'lodash/cloneDeep'

/**
 *  Calculate totals for all line items on quote
 */
export function lineItems(quote: QuoteDocument): QuoteTotals {
  if (!quote.order || !quote.lineItems) {
    return quote.totals
  }

  const totals = cloneDeep(quote.totals)

  // Add line item totals to the existing totals
  const lineItemTotals = quote.order.area.reduce((total, item) => {
    if (item._deleted || item.type !== 'line_item') {
      return total
    }

    const lineItem = quote.lineItems[item.key]
    if (!lineItem || lineItem._deleted) {
      return total
    }

    const hours = lineItem.show_crew ? parseNumber(lineItem.hours) : 0
    const price = parseNumber(lineItem.price)
    const materials = get(lineItem, 'totals.materials') || 0

    const updatedTotal = clone(total)
    let targetProperty = null

    if (lineItem.pending) {
      targetProperty = 'pending'
    } else if (lineItem.additionalWork) {
      targetProperty = 'additionalWork'
    } else if (lineItem.addedOption) {
      targetProperty = 'addedOptions'
    } else if (lineItem.use_total) {
      targetProperty = 'line_items'
    }

    if (targetProperty) {
      updatedTotal[targetProperty] = add(
        updatedTotal[targetProperty] || { hours: 0, prep: 0, price: 0, materials: 0 },
        {
          hours,
          prep: 0,
          price: item.parent ? 0 : price,
          materials: item.parent ? 0 : materials
        },
        quote
      )

      // If line item in bid section, we want to include it in the type totals group too
      if (targetProperty === 'line_items') {
        const group = lineItem.quoteType ? lineItem.quoteType : 'all'
        updatedTotal.groups[group] = add(
          updatedTotal.groups[group] || { hours: 0, prep: 0, price: 0, materials: 0 },
          {
            hours,
            prep: 0,
            price: item.parent ? 0 : price,
            materials: item.parent ? 0 : materials
          },
          quote
        )
      }
    }

    return updatedTotal
  }, totals)

  return lineItemTotals
}

import type { QuoteDocument } from 'paintscout'

export function hasCustomTotal(areas: QuoteDocument['areas'], includeArchived?: boolean): boolean {
  if (!areas) {
    return false
  }
  return Object.values(areas)
    .filter((area) => (includeArchived ? area : area.show))
    .map((area) => area.totals)
    .some((total) => total.override_hours || total.override_price || total.override_materials)
}

/**
 * @module builder
 */
import type { MoveableItem } from '../../index'

export function moveToAdditional(item: MoveableItem): any {
  const r = {
    ...item,
    additionalWork: true,
    show: false,
    use_total: false,
    pending: false,
    addedOption: false
  }

  return r
}

import type { Theme } from '@material-ui/core'

export interface GetPresentationContentStylesOptions {
  noMargins?: string[]
}

export function getPresentationContentStyles(theme: Theme, options?: GetPresentationContentStylesOptions): any {
  const noMargins = options?.noMargins ?? []

  return {
    '& h1': {
      // fontSize: theme.typography.pxToRem(60),
      // letterSpacing: 1,
      ...theme.typography.h1,
      margin: `${theme.spacing(0.5)}px 0`,
      color: 'inherit'
    },
    '& h2': {
      // fontSize: theme.typography.pxToRem(36),
      // letterSpacing: 1,
      // lineHeight: 1.1,
      // fontWeight: 'normal',
      ...theme.typography.h2,
      margin: 0,
      color: 'inherit'
    },
    '& h3': {
      // fontSize: theme.typography.pxToRem(36),
      // letterSpacing: 1,
      // lineHeight: 1.3,
      // fontWeight: 'normal',
      ...theme.typography.h3,
      // fontWeight: theme.typography.fontWeightMedium,
      margin: 0,
      color: 'inherit'
    },
    '& h4': {
      // fontSize: theme.typography.pxToRem(26),
      // letterSpacing: 1,
      // lineHeight: 1.3,
      // fontWeight: 'normal',
      ...theme.typography.h4,
      // fontWeight: theme.typography.fontWeightMedium,
      margin: 0,
      color: 'inherit'
    },
    '& p, & ul, & ol': {
      // fontSize: theme.typography.pxToRem(16),
      // lineHeight: 1.75,
      ...theme.typography.body1,
      fontWeight: 'auto',
      margin: 0,
      color: 'inherit'
    },
    '& p': {
      whiteSpace: 'normal'
    },
    '& ul li, & ol li': {
      marginBottom: noMargins.includes('li') ? 0 : `${(theme.typography.body1.lineHeight as number) / 2}rem`
    },
    // This is a bit of a hack to make empty tags still show up as a line-break.
    // We're doing this so that people can "control" margins just by adding line breaks
    '& p:after, & h1:after, & h2:after, & h3:after, & h4:after, & h5:after, & h6:after': {
      content: "''",
      display: 'inline-block',
      width: 0
    },
    '& a': {
      textDecoration: 'underline',
      color: 'inherit'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '& hr': {
      borderTop: '1px solid #ddd',
      borderBottom: 'none'
    },
    '@media print': {
      '& h1': {
        // fontSize: theme.typography.pxToRem(60)
      },
      '& h2': {
        // fontSize: theme.typography.pxToRem(36)
      },
      '& h3': {
        // fontSize: theme.typography.pxToRem(36)
      },
      '& h4': {
        // fontSize: theme.typography.pxToRem(26)
      },
      '& p, & ul, & ol': {
        // fontSize: theme.typography.pxToRem(16)
      }
    },
    [theme.breakpoints.down('md')]: {
      '& h1': {
        // fontSize: theme.typography.pxToRem(50)
      },
      '& h2': {
        // fontSize: theme.typography.pxToRem(30)
      },
      '& h3': {
        // fontSize: theme.typography.pxToRem(30)
      },
      '& h4': {
        // fontSize: theme.typography.pxToRem(20)
      },
      '& p, & ul, & ol': {
        // fontSize: theme.typography.pxToRem(14)
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& h1': {
        // fontSize: theme.typography.pxToRem(30)
      },
      '& h2': {
        // fontSize: theme.typography.pxToRem(26)
      },
      '& h3': {
        // fontSize: theme.typography.pxToRem(22)
      },
      '& h4': {
        // fontSize: theme.typography.pxToRem(22)
      },
      '& p, & ul, & ol': {
        // fontSize: theme.typography.pxToRem(14)
      }
    }
  }
}

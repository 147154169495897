import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import * as React from 'react'
import type { QuoteTypeOption } from '@paintscout/util/builder'
import { getQuoteTypeOptions } from '@paintscout/util/builder'
import type { QuoteHeaderCardProps } from '../QuoteHeaderCard'
import { QuoteContext } from '../../../context/QuoteContext'
import { useClientOptions } from '@ui/paintscout'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    image: {
      display: 'flex',
      alignItems: 'start',

      '& img': {
        marginRight: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        maxHeight: theme.typography.pxToRem(80),
        maxWidth: theme.typography.pxToRem(240),

        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
          marginBottom: 0,
          maxHeight: theme.typography.pxToRem(40),
          maxWidth: theme.typography.pxToRem(120)
        },
        '@media print': {
          marginRight: theme.spacing(1.5),
          marginBottom: theme.spacing(1.5),
          maxHeight: theme.typography.pxToRem(80),
          maxWidth: theme.typography.pxToRem(240)
        }
      }
    }
  })

export interface QuoteHeaderLogoProps extends Omit<QuoteHeaderCardProps, 'classes'>, WithStyles<typeof styles> {}

function QuoteHeaderLogo(props: QuoteHeaderLogoProps) {
  const { classes } = props
  const { options } = useClientOptions()
  const { quote } = React.useContext(QuoteContext)

  const quoteTypeOptions = getQuoteTypeOptions({ options })
  const quoteType = find(quoteTypeOptions, { key: quote.int_ext.value as any }) as QuoteTypeOption

  const companyOptions = options.options

  let logo = companyOptions.logo.value

  if (quoteType && quoteType.overrideCompanyOptions) {
    logo = get(quoteType, 'companyOptions.logo.value', '')
  }

  return (
    <div className={classes.image}>
      <img src={logo} />
    </div>
  )
}

export default withStyles(styles)(QuoteHeaderLogo)

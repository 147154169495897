import type { QuoteDocument } from 'paintscout'
import { createQuoteActivity } from './create-quote-activity'
import type { Owner, ActivityMessageFrom, MessageActivity } from '@paintscout/api'

export const createQuoteMessageActivity = (args: {
  quote: QuoteDocument
  owner?: Owner
  message: string
  from: ActivityMessageFrom
  timestamp?: number
  smsTo?: string
}) => {
  const { quote, from, message, owner, smsTo, timestamp = Date.now() } = args

  return createQuoteActivity<MessageActivity>({
    quote,
    owner,
    activityType: 'message',
    timestamp,
    message: {
      from,
      text: message,
      readAt: null,
      smsTo
    }
  })
}

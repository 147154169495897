import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { FormControlLabelProps as CoreFormControlLabelProps } from '@ui/core/FormControlLabel'
import CoreFormControlLabel from '@ui/core/FormControlLabel'

const useStyles = makeStyles<Theme, FormControlLabelProps>((theme) => ({
  root: {
    ...theme.typography.body1
  }
}))

export interface FormControlLabelProps extends CoreFormControlLabelProps {
  classes?: CoreFormControlLabelProps['classes'] & StyleClasses<typeof useStyles>
}

const FormControlLabel = React.forwardRef(function FormControlLabel(props: FormControlLabelProps, ref) {
  const classes = useStyles(props)
  return <CoreFormControlLabel {...props} classes={classes} ref={ref} />
})

export default FormControlLabel

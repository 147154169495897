/**
 * @module builder
 */
import type { AreaSubstrate, OptionsDocument, QuoteDocument, QuoteOptions } from 'paintscout'
import type { QuoteItemConsumer, QuoteItemSection } from '../../index'
import { filterItem, getItemSection, getQuoteOptions } from '../util'
import type { RenderableItemSubstrate } from './index'

import { isInSection } from './is-in-section'

import { getArea } from '../areas/get-area'
import { getAreaSubstrate } from './get-area-substrate'
import { keyAreaSubstrates } from './key-area-substrates'
import { toRenderableItemSubstrate } from './util'
/**
 * Get all areaSubstrates for the given section,
 * substrates filtered for crew or customers
 */
export function getAreaSubstrates(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  section?: QuoteItemSection
  consumer?: QuoteItemConsumer
  overrideOptions?: { [key: string]: any }
  showGroups?: boolean
}): RenderableItemSubstrate[] {
  const { options, overrideOptions, areaKey, section, showGroups } = args
  const consumer = args.consumer === 'crew' ? 'crew' : 'customer'

  if (!args.quote.areas[areaKey]) {
    return [] as RenderableItemSubstrate[]
  }

  const quote = args.quote.areas[areaKey].substrateOrder
    ? args.quote
    : keyAreaSubstrates({ quote: args.quote, areaKey })

  const area = getArea({ quote, options, key: areaKey })

  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const substrateOrder = quote.areas[areaKey].substrateOrder

  const areaSection = getItemSection(quote.areas[areaKey])
  return substrateOrder
    .map((orderItem) => {
      const areaSubstrate = getAreaSubstrate({
        quote,
        options,
        areaKey,
        key: orderItem.key,
        full: true
      }) as AreaSubstrate

      if (!areaSubstrate) {
        return null
      }

      const renderableItem = toRenderableItemSubstrate({ areaSubstrate, options: quoteOptions, quote, area })

      // Filter substrates for customer or crew
      if (consumer === 'customer') {
        if (renderableItem.inSubstrateGroup) {
          const parentGroupKey = Object.keys(quote.groups ?? []).find((groupKey) => {
            const group = quote?.groups[groupKey]
            return group && group?.substrateChildren?.includes(areaSubstrate.key)
          })
          if (parentGroupKey && section && quote.groups[parentGroupKey].section !== section) {
            return null
          }
        } else if (section && !filterItem(areaSubstrate, section)) {
          return null
        }
        if (!showGroups && (renderableItem.inAreaGroup || renderableItem.inSubstrateGroup)) {
          return null
        }
        if (!showGroups && (renderableItem.inAreaGroup || renderableItem.inSubstrateGroup)) {
          return null
        }
      } else if (consumer === 'crew') {
        const areaSubstrateSection = getItemSection(areaSubstrate)

        if (section && !isInSection({ section, areaSection, areaSubstrateSection })) {
          return null
        }

        // if (areaSection !== section && areaSubstrateSection === 'bid') {
        //   return null
        // }
        // if (areaSection && areaSubstrateSection !== section) {
        //   return null
        // } else if (areaSection && areaSubstrateSection !== areaSection && areaSubstrateSection !== 'bid') {
        //   return null
        // }
      }

      return renderableItem
    })
    .filter((item) => item)
}

import type { OptionsDocument, QuoteDocument, QuoteTotals } from 'paintscout'
import cloneDeep from 'lodash/clone'
import { calculateAfterTax } from './calculate-after-tax'
import { calculateAmountPaid } from './calculate-amount-paid'
import { calculateBalanceDue } from './calculate-balance-due'
import { lineItems } from '../line-items'
import { groups } from '../groups'
import { subTotal } from './sub-total'
import { totalAdjustment } from './total-adjustment'
import { discount, discounts } from '../discounts'
import { roundPrice } from '../util/'
import { tax } from '../tax'
import { add } from '../util/add'
import { DEFAULT_TOTALS } from './index'
import { getDetails } from '../../builder/quote/details/get-details/get-details'

/**
 * Calculate quote totals
 */
export function quoteTotals(quote: QuoteDocument, options: OptionsDocument): QuoteTotals {
  if (!quote) {
    return null
  }

  // Reset totals
  const updatedQuote = cloneDeep(quote)
  updatedQuote.totals = {
    ...updatedQuote.totals,
    ...DEFAULT_TOTALS,
    show_total: updatedQuote?.totals?.show_total ?? true // Carry from original quote.totals if exists
  }

  // Combine item totals
  updatedQuote.totals = lineItems(updatedQuote)
  updatedQuote.totals = groups(updatedQuote, options)
  updatedQuote.totals = subTotal(updatedQuote)

  // We 0 out base totals in subTotal, so these must be added after
  updatedQuote.totals = add(updatedQuote.totals, updatedQuote.totals.line_items, quote)
  updatedQuote.totals = add(updatedQuote.totals, updatedQuote.totals.groupedItems, quote)

  // Add materials to price
  updatedQuote.totals.price += updatedQuote.totals.materials

  // Price adjustments and service fees
  updatedQuote.totals = totalAdjustment(updatedQuote, options)

  // Discounts
  updatedQuote.totals = discount(updatedQuote)
  updatedQuote.totals = discounts(updatedQuote)

  // Taxes and payment calculations
  updatedQuote.totals.total_tax = tax(updatedQuote)
  updatedQuote.totals.after_tax = calculateAfterTax(updatedQuote)
  updatedQuote.totals.amount_paid = calculateAmountPaid(updatedQuote)
  updatedQuote.totals.balance_due = calculateBalanceDue(updatedQuote.totals)

  // Round totals
  const roundedTotals = {
    ...updatedQuote.totals,
    price: roundPrice(updatedQuote.totals.price),
    after_adjustment: roundPrice(updatedQuote.totals.after_adjustment),
    after_discount: roundPrice(updatedQuote.totals.after_discount),
    after_tax: roundPrice(updatedQuote.totals.after_tax),
    amount_paid: roundPrice(updatedQuote.totals.amount_paid),
    balance_due: roundPrice(updatedQuote.totals.balance_due),
    total_discount: roundPrice(updatedQuote.totals.total_discount),
    total_tax: roundPrice(updatedQuote.totals.total_tax),
    materials: roundPrice(updatedQuote.totals.materials),
    service_fee_hidden: roundPrice(updatedQuote.totals.service_fee_hidden)
  }

  // Get sales rates with updatedQuote and totals
  const quoteDetails = getDetails({
    quote: {
      ...updatedQuote,
      totals: roundedTotals
    }
  })
  const {
    hours,
    addedOptionsMaterials: addedOptionsMaterialsPrice,
    addedOptions: addedOptionsLabour,
    additionalMaterials: additionalWorkMaterials,
    additionalWork: additionalWorkLabour,
    afterAdjustment,
    afterDiscount
  } = quoteDetails.totals
  const combinedTotal =
    Math.round(
      (afterAdjustment +
        addedOptionsLabour +
        addedOptionsMaterialsPrice +
        additionalWorkMaterials +
        additionalWorkLabour) *
        100
    ) / 100

  const hoursValue = hours.useCustom ? hours.custom : hours.default
  const parsedHours = typeof hoursValue === 'string' ? parseFloat(hoursValue) : hoursValue

  let sales_rate_after_discount = Math.round((afterDiscount / parsedHours) * 100) / 100
  let sales_rate = Math.round((combinedTotal / parsedHours) * 100) / 100

  if (!isFinite(sales_rate_after_discount) || isNaN(sales_rate_after_discount)) {
    sales_rate_after_discount = 0
  }
  if (!isFinite(sales_rate) || isNaN(sales_rate)) {
    sales_rate = 0
  }

  return {
    ...roundedTotals,
    sales_rate,
    sales_rate_after_discount
  }
}

import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { Grid, Typography, useClientOptions, useMediaQuery } from '@ui/paintscout'

import StatusStamp from '../StatusStamp'
import QuoteHeaderLogo from '../QuoteHeaderLogo'
import { useQuote } from '../../../context/useQuote'

import { getObjectLabels } from '@paintscout/util/builder'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'row-reverse',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row'
      },
      ['@media print']: {
        flexDirection: 'row-reverse'
      }
    },
    titleContainer: {
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start'
      },
      ['@media print']: {
        alignItems: 'flex-end'
      }
    }
  })

export interface QuoteStatusCardProps extends WithStyles<typeof styles> {
  variant?: 'default' | 'work-order' | 'product-order-form'
}

function QuoteStatusCard({ classes, variant, ..._props }: QuoteStatusCardProps) {
  const { quote } = useQuote()
  const { options } = useClientOptions()

  const status = quote?.status?.value ?? 'draft'
  const hasStamp = ['accepted', 'declined', 'paid', 'partial', 'invoiced'].includes(status)

  const smDown = useMediaQuery('sm')
  const showLogo = !!options.options.logo.value

  const title = getTitle()
  return (
    <Grid container justifyContent="space-between" alignItems="center" classes={{ container: classes.root }}>
      <Grid item>
        <Grid container direction="column" justifyContent="center" classes={{ container: classes.titleContainer }}>
          <Grid item>
            <Typography variant="h2">{title}</Typography>
          </Grid>
          <Grid item>{hasStamp && (!smDown || (smDown && showLogo)) && <StatusStamp status={status} />}</Grid>
        </Grid>
      </Grid>

      {showLogo && smDown && (
        <Grid item>
          <QuoteHeaderLogo />
        </Grid>
      )}

      {hasStamp && smDown && !showLogo && (
        <Grid item>
          <StatusStamp status={status} />
        </Grid>
      )}
    </Grid>
  )

  function getTitle() {
    const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
    if (variant === 'work-order') {
      return objectLabels.workOrder.value
    } else if (variant === 'product-order-form') {
      return 'Product Order Form'
    } else {
      return objectLabels.quote.value
    }
  }
}

export default withStyles(styles)(QuoteStatusCard)

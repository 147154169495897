import React from 'react'
import Grid from '../Grid'
import Button from '../Button'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import BackIcon from '@material-ui/icons/ArrowBackIos'

const useStyles = makeStyles<Theme, { hideDivider?: boolean }>((theme) => ({
  root: {
    padding: theme.spacing(1.5, 2),
    // borderTop:  `1px solid ${theme.palette.divider}`
    borderTop: ({ hideDivider }) => (hideDivider ? 'none' : `1px solid ${theme.palette.divider}`)
  },
  button: {
    transform: 'scale(0.9)'
  },
  cancelButton: {
    marginLeft: -theme.spacing(1)
  }
}))

export interface DropdownSelectFooterProps {
  onApply: () => void
  onClear: () => void
  clearLabel?: string
  hideDivider?: boolean
}

export default function DropdownSelectFooter({ onApply, onClear, clearLabel, hideDivider }: DropdownSelectFooterProps) {
  const classes = useStyles({ hideDivider })
  return (
    <Grid container className={classes.root} justifyContent="space-between">
      <Button
        icon={clearLabel === 'Back' ? BackIcon : null}
        className={classnames(classes.button, classes.cancelButton)}
        onClick={onClear}
        variant="text"
      >
        {clearLabel || 'clear'}
      </Button>
      <Button className={classes.button} onClick={onApply}>
        done
      </Button>
    </Grid>
  )
}

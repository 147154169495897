import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import ArchiveIcon from '@material-ui/icons/Archive'
import EditIcon from '@material-ui/icons/Edit'
import React, { useContext, useMemo } from 'react'
import { useClientOptions, Hidden } from '@ui/paintscout'
import { FormSectionTitle } from '@ui/paintscout'
import type { RenderableItem } from '@paintscout/util/builder'
import { getItems, getObjectLabels, getQuoteOptions } from '@paintscout/util/builder'
import classnames from 'classnames'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { ItemColumn, ItemTableCell } from '../../../../ItemTable'
import ActionColumn from '../../../../ItemTable/columns/ActionColumn'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    tableHead: {
      background: theme.palette.grey[200]
    },
    headerCell: {
      color: theme.palette.grey[800]
    },
    hidePrint: {
      '@media print': {
        display: 'none'
      }
    }
  })
}

export interface OptionsAddedTableProps extends WithStyles<typeof styles> {
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  onItemAction: ItemTableProps['onItemAction']
}

function OptionsAddedTable({ classes, onItemAction, onItemSelection, ...itemTableProps }: OptionsAddedTableProps) {
  const { quote, tableView, onReorder, isEditable, isNoteEditable } = useContext(QuoteContext)
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })

  const { hideAllPrices } = getQuoteOptions({
    quote,
    options
  })

  const items: RenderableItem[] = useMemo(
    () =>
      getItems({
        quote,
        section: 'added-options',
        view: tableView,
        consumer: 'customer',
        options,
        overrideOptions: { showPrices: true }
      }),
    [quote, tableView, options]
  )

  if (items.length === 0) {
    return null
  }

  function handleReorder({ items, addIndex }) {
    onReorder({ items, section: 'added-options', view: tableView, addIndex })
  }

  return (
    <div className={classnames({ [classes.root]: true, [classes.hidePrint]: items.length === 0 })}>
      <FormSectionTitle
        title={`${objectLabels.option.plural} ${
          quote.status.value === 'accepted' || quote.is_invoice ? 'Accepted' : 'Added'
        }`}
      />
      <ItemTable
        draggable={!!isEditable}
        classes={{
          tableHead: classes.tableHead
        }}
        checkboxes={isEditable && !!onItemSelection}
        items={items}
        onItemClick={
          isEditable || isNoteEditable
            ? (ev, item) => onItemAction('edit', 'added-options', item as RenderableItem)
            : null
        }
        onReorder={handleReorder}
        onItemSelection={onItemSelection}
        renderHeadColumns={() => (
          <>
            <ItemTableCell className={classes.headerCell}>Item</ItemTableCell>
            <ItemTableCell className={classes.headerCell} align="right">
              {!hideAllPrices && (
                <Hidden smDown implementation="css">
                  {objectLabels.currency.symbol}
                </Hidden>
              )}
            </ItemTableCell>

            {/* action cell */}
            {isEditable && (
              <ItemTableCell isControl={true} className={classnames(classes.headerCell, classes.hidePrint)} />
            )}
          </>
        )}
        renderRowColumns={(item: RenderableItem) => {
          const actions = [
            {
              key: 'edit',
              label: 'Edit',
              icon: EditIcon
            },
            {
              key: 'make-option',
              label: `Make ${objectLabels.option.value}`,
              icon: AddBoxIcon
            },
            {
              key: 'archive',
              label: 'Archive',
              icon: ArchiveIcon
            }
          ]

          return (
            <>
              <ItemTableCell colSpan={2}>
                <ItemColumn
                  item={item}
                  isEditable={isEditable || isNoteEditable}
                  showAmount={!hideAllPrices}
                  consumer={'customer'}
                  section={'added-options'}
                />
              </ItemTableCell>
              {isEditable && (
                <ItemTableCell cancelDrag isControl classes={{ root: classes.hidePrint }}>
                  <ActionColumn
                    onActionClick={(ev, action) => onItemAction(action, 'added-options', item)}
                    actions={actions}
                  />
                </ItemTableCell>
              )}
            </>
          )
        }}
        {...itemTableProps}
      />
    </div>
  )
}

export default withStyles(styles)(OptionsAddedTable)

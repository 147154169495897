import type { Theme } from '@material-ui/core'
import { useMediaQuery, useTheme } from '@material-ui/core'

/**
 * Checks if the breakpoint is active
 *
 * ex: const isSmallScreen = useBreakpoint(breakpoints => breakpoints.down('sm))
 */
export function useBreakpoint(fn: (breakpoints: Theme['breakpoints']) => string) {
  const theme = useTheme<Theme>()

  return useMediaQuery(fn(theme.breakpoints))
}

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { ActionButtonProps } from '@ui/paintscout'
import { ActionButton } from '@ui/paintscout'
import React from 'react'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    marginTop: -16
  }
}))

/**
 * A styled ActionButton to be aligned properly with the table cell
 */
export default function ActionColumn(props: ActionButtonProps) {
  const classes = useStyles(props)

  return (
    <ActionButton
      {...props}
      className={classes.root}
      onActionClick={(ev, action, payload) => {
        props.onActionClick(ev, action, payload)
      }}
    />
  )
}

import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getItem, updateItem } from '../../items'

export function updateFileOrderForItem(args: {
  quote: QuoteDocument
  options: OptionsDocument
  itemKey: string
  order: string[]
}): QuoteDocument {
  const { quote, options, itemKey, order } = args

  const item = getItem({ quote, options, key: itemKey })

  const updatedItem = {
    ...item,
    // @ts-ignore - remove legacy images order
    images: [],
    files: order
  }

  return updateItem({ quote, options, item: updatedItem })
}

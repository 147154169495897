export default function scaleVariant(inputVariant: any, scale: number) {
  try {
    const variant = { ...inputVariant }
    variant.fontSize = scaleRem(variant.fontSize, scale)
    Object.keys(variant)
      .filter((key) => key.startsWith('@media'))
      .forEach((key) => {
        if (variant?.[key]?.fontSize) {
          variant[key] = {
            ...variant[key],
            fontSize: scaleRem(variant[key].fontSize, scale)
          }
        }
      })
    return variant
  } catch (err) {
    return inputVariant
  }
}

export function scaleRem(inputValue: string, scale: number) {
  const numericValue = parseFloat(inputValue.substring(0, inputValue.length - 3))
  return `${numericValue * scale}rem`
}

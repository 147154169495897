import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import Typography from '@ui/paintscout/src/Typography'

const useStyles = makeStyles<Theme, ColorTileProps>(
  () => ({
    root: {
      width: '128px',
      height: '128px',
      flexShrink: 0,
      boxShadow: '0px 4px 16px -4px rgba(0, 0, 0, 0.08), 0px 8px 32px -4px rgba(0, 0, 0, 0.02)',
      borderRadius: '2px'
    },
    typographyWrapper: {
      padding: '6px 8px'
    },
    colorCode: {
      color: '#FFFFFF',
      fontFamily: 'CustomFont-SW',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '-0.25px'
    },
    colorName: {
      color: '#FFFFFF',
      fontFamily: 'CustomFont-SW',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '-0.25px'
    },
    darkText: {
      color: '#000000'
    },
    tall: {
      height: '192px'
    },
    colorChip: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px'
    },
    colorChipIcon: {
      width: '20px',
      height: '20px',
      border: '1.5px solid rgba(0, 0, 0, .25)',
      borderRadius: '4px'
    },
    colorChipColorCode: {
      color: '#2F2F30',
      fontFamily: 'CustomFont-SW',
      fontSize: '10.77px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '14.361px',
      marginTop: '3px'
    }
  }),
  { name: 'ColorTile' }
)

export interface ColorTileProps {
  color?: string
  colorCode?: number | string
  colorName?: string
  darkText?: boolean
  tall?: boolean
  style?: React.CSSProperties
  icon?: boolean
}

function ColorTile(props: ColorTileProps) {
  const { color, colorCode, colorName, darkText, tall, style, icon } = props
  const classes = useStyles(props)

  const rootClass = `${classes.root} ${tall ? classes.tall : ''}`
  const colorCodeClass = `${classes.colorCode} ${darkText ? classes.darkText : ''}`
  const colorNameClass = `${classes.colorName} ${darkText ? classes.darkText : ''}`

  if (!icon)
    return (
      <div className={rootClass} style={{ background: color, ...style }}>
        <div className={classes.typographyWrapper}>
          <Typography className={colorCodeClass} variant="body1">{`SW ${colorCode}`}</Typography>
          <Typography className={colorNameClass} variant="body1">
            {colorName}
          </Typography>
        </div>
      </div>
    )

  return (
    <div className={classes.colorChip}>
      <div className={classes.colorChipIcon} style={{ background: color }} />
      <Typography variant="body1" className={classes.colorChipColorCode}>
        <b>{colorName}</b> {colorCode}
      </Typography>
    </div>
  )
}

export default ColorTile

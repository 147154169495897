import React from 'react'
import type { TypographyProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'

export interface ActivityTitleProps extends Partial<TypographyProps> {
  rightContent?: React.ReactNode
  titleContent: React.ReactNode
}

const useStyles = makeStyles(
  (_theme) => ({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    }
  }),
  {
    name: 'ActivityTitle'
  }
)

export default function ActivityTitle({ rightContent, titleContent, ...props }: ActivityTitleProps) {
  const classes = useStyles(props)

  return (
    <div className={classnames(classes.root, props.className)}>
      {titleContent}
      {rightContent}
    </div>
  )
}

import type { Theme } from '@material-ui/core'
import { Typography, makeStyles } from '@material-ui/core'
import { Grid, useClientOptions } from '@ui/paintscout'
import { formatCurrency, getObjectLabels } from '@paintscout/util/builder'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import type { Payment } from 'paintscout'
import React from 'react'
import { useQuote } from '../context'
import classnames from 'classnames'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2, 0)
  },
  img: {
    objectFit: 'contain',
    maxHeight: '90px',
    marginBottom: theme.spacing(2)
  },
  border: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  stamp: {
    color: theme.palette.quote.status.accepted,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  },
  checkIcon: {
    color: theme.palette.quote.status.accepted,
    fontSize: '3rem',
    marginLeft: theme.spacing(1)
  },
  spacer: {
    height: theme.spacing(3.75)
  }
}))

export default function PaymentReceipt({ payment }: { payment: Payment }) {
  const classes = useStyles()
  const { options } = useClientOptions()
  const { logo, companyName, companyPhone, dateFormat } = options.options
  const { quote } = useQuote()
  const { quote: quoteLabel } = getObjectLabels({ options, quote, invoice: quote.is_invoice })

  const formattedAmount = formatCurrency({
    options,
    value: payment.amount
  })

  const formattedSubtotal = formatCurrency({
    options,
    value: payment.amount - payment.surcharge
  })

  const formattedSurcharge = formatCurrency({
    options,
    value: payment.surcharge
  })

  return (
    <>
      <Grid className={classnames(classes.container, classes.border)} container justifyContent="space-between">
        <Grid item>
          <Typography variant="h3">Payment Receipt</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3">{formattedAmount}</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.container} justifyContent="space-between" container spacing={3}>
        <Grid item>
          <img src={logo.value} className={classes.img} />
          <Typography>{`${quote.contact?.first_name || ''} ${quote.contact?.last_name ?? ''}`}</Typography>
          {quote.contact?.email && <Typography>{quote.contact?.email}</Typography>}
          {quote.contact?.phone_number && <Typography>{quote.contact?.phone_number}</Typography>}
        </Grid>
        <Grid item>
          <Typography>{`${quoteLabel.value} #${quote.number}`}</Typography>
          <Typography>{companyName.value}</Typography>
          {quote.owner?.email && <Typography>{quote.owner?.email}</Typography>}
          {companyPhone?.value && <Typography>{companyPhone.value}</Typography>}
          <div className={classes.spacer} />
          {!!payment.surcharge && (
            <>
              <Typography>{`Subtotal: ${formattedSubtotal}`}</Typography>
              <Typography>{`Surcharge: ${formattedSurcharge}`}</Typography>
            </>
          )}
          <Typography>{`Total: ${formattedAmount}`}</Typography>
          {!!payment.surcharge && <div className={classes.spacer} />}
          <Typography>{`Payment Date: ${moment(payment.date).format(dateFormat.momentValue)}`}</Typography>
          {payment.type && <Typography>{`Method: ${payment.type}`}</Typography>}
        </Grid>
        <Grid item>
          {payment.status === 'paid' && (
            <>
              <Typography className={classes.stamp}>
                Paid <CheckIcon className={classes.checkIcon} />
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <div className={classes.border} />
    </>
  )
}

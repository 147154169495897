import React, { useContext, useMemo } from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { QuoteContext } from '../../../context/QuoteContext'

import { FilePreview, Grid, ImagePlaceholder, Typography, HtmlContent, useClientOptions } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'

import isEmpty from './IntroductionIsEmpty'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {},
    placeholder: {
      border: `2px dashed ${theme.palette.grey.A100}`,
      minHeight: theme.typography.pxToRem(100),
      textAlign: 'center',
      padding: theme.spacing(2),
      color: theme.palette.grey[700],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }
})

export default function Introduction(props: PresentationSectionProps) {
  const classes = useStyles(props)

  const { section, isPreview, showHelp, WrapperComponent } = props
  const { fields } = section

  const { options } = useClientOptions()
  const { quote } = useContext(QuoteContext)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 6

  const { image, content, title, objectLabels } = useMemo(() => {
    return {
      title: section.title ?? '',
      image: fields?.image ?? quote?.presentation?.featuredMedia,
      content: fields?.content ?? quote?.presentation?.coverLetter,
      objectLabels: getObjectLabels({ options })
    }
  }, [fields])

  const empty = isEmpty({ section })

  if (empty && !isPreview) {
    return null
  } else if (empty) {
    return (
      <WrapperComponent {...props}>
        <IntroductionPresentationSectionPlaceholder />
      </WrapperComponent>
    )
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={responsiveSpacing} alignItems={'center'}>
        {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        {(image || showHelp) && (
          <Grid item md={6}>
            {image && <FilePreview file={image} />}
            {!image && showHelp && <ImagePlaceholder title={'Image'} width={'100%'} height={200} />}
          </Grid>
        )}
        {hasContent() && (
          <Grid item md={image || showHelp ? 6 : 12}>
            <HtmlContent content={content} classes={{ root: classes.content }} />
          </Grid>
        )}
      </Grid>
    </WrapperComponent>
  )

  function IntroductionPresentationSectionPlaceholder() {
    return (
      <Grid container spacing={responsiveSpacing} alignItems={'center'} justifyContent={'center'}>
        <Grid item md={12}>
          <div className={classes.placeholder}>
            <Typography variant={'overline'}>Introduction</Typography>
            <Typography
              variant={'subtitle2'}
            >{`This section will not be shown unless it has been edited on individual ${objectLabels.quote.plural}`}</Typography>
            <Typography
              variant={'subtitle2'}
            >{`If you update this section here, and it will still be possible to edit on ${objectLabels.quote.plural}.`}</Typography>
          </div>
        </Grid>
      </Grid>
    )
  }

  function hasContent() {
    return !!fields?.content && fields.content.replace('<p></p>', '').trim()
  }
}

import type { QuoteDocument, QuoteTotals } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import get from 'lodash/get'

export function discount(quote: QuoteDocument): QuoteTotals {
  const newTotal = { ...quote.totals }

  const discount = typeof quote.discount !== 'undefined' && quote.discount !== null ? parseNumber(quote.discount) : 0
  const discountType = (quote.discount_type && quote.discount_type.value) || 'percentage'

  const price = parseNumber(
    quote.totals && quote.totals.after_adjustment ? quote.totals.after_adjustment : quote.totals.price
  )

  const additionalWorkPrice = parseNumber(get(quote, 'totals.additionalWork.price', 0))
  const additionalWorkMaterials = parseNumber(get(quote, 'totals.additionalWork.materials', 0))

  const addedOptionsPrice = parseNumber(get(quote, 'totals.addedOptions.price', 0))
  const addedOptionsMaterials = parseNumber(get(quote, 'totals.addedOptions.materials', 0))

  const totalPrice = price + additionalWorkPrice + additionalWorkMaterials + addedOptionsPrice + addedOptionsMaterials

  if (discountType === 'percentage') {
    newTotal.total_discount = Math.ceil(totalPrice * discount * 0.01 * 100) / 100
  } else {
    newTotal.total_discount = discount
  }

  newTotal.after_discount = totalPrice - newTotal.total_discount

  return newTotal
}

import type { UploadOptions } from '../s3'

export interface CloudinaryUploadResponse {
  public_id?: string
  version?: number
  signature?: string
  width?: number
  height?: number
  format?: string
  resource_type?: string
  created_at?: string
  tags?: string[]
  bytes?: number
  pages?: number
  type?: string
  etag?: string
  placeholder?: boolean
  url?: string
  secure_url?: string
  overwritten?: boolean
  original_filename?: string
  original_extension?: string
}

const CLOUD_NAME = process.env.CLOUDINARY_CLOUD_NAME
const UPLOAD_PRESET = process.env.CLOUDINARY_UPLOAD_PRESET

export async function cloudinaryUploadFile(file: File, opts: UploadOptions): Promise<CloudinaryUploadResponse> {
  const formData = new FormData()
  const { companyId, docId, tags = [] } = opts

  formData.append('file', file)
  formData.append('upload_preset', UPLOAD_PRESET)
  formData.append('folder', companyId)

  formData.append(
    'tags',
    [companyId && `company-${companyId}`, docId && `doc-${docId}`, ...tags].filter(Boolean).join(',')
  )

  const data = await fetch(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/auto/upload`, {
    method: 'POST',
    body: formData
  }).then((res) => res.json())

  return data
}

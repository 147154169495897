import React from 'react'
import type { FieldProps } from 'formik'
import type { DropdownSelectOption, SingleSelectProps } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'

export interface FormikDropdownSelectProps
  extends FieldProps<any>,
    Omit<SingleSelectProps, 'form' | 'classes' | 'variant'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikDropdownSelect = ({ field, ...props }: FormikDropdownSelectProps) => {
  const value =
    (props.options as DropdownSelectOption[]).find((option: DropdownSelectOption) => option.value === field.value) ||
    props.options[0]

  const onChange = (option: DropdownSelectOption) => {
    return field.onChange({ target: { name: field.name, value: option.value } })
  }

  return (
    <DropdownSelect variant="single" {...field} {...props} value={value as DropdownSelectOption} onChange={onChange} />
  )
}

export default FormikDropdownSelect

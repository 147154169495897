import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { getPresentationContentStyles } from '@ui/paintscout'
import type { OptionsDocument, PresentationEstimator, PresentationSection, QuoteDocument, QuoteFiles } from 'paintscout'
import { useQuote } from '../../context'
import sectionComponents from '../sections'
import { Grid, useInView } from '@ui/core'
import type { PresentationSectionProps } from '..'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {
      ...getPresentationContentStyles(theme, { noMargins: ['li'] })
    },
    sectionWrapper: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      position: 'relative',
      borderCollapse: 'separate'
    },
    pageBreaks: {
      '&:after': {
        ['@media print']: {
          display: 'block',
          content: '""',
          pageBreakAfter: 'always',
          pageBreakInside: 'avoid'
        }
      }
    },
    padding: {
      padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
      },
      '@media print': {
        padding: 0
      }
    },
    firstPaper: {},
    extraPadding: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
      }
    },
    backgroundWrapper: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    },
    containedBackground: {
      boxShadow: theme.shadows[1],
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      },
      '@media print': {
        boxShadow: 'none'
      },
      position: 'relative'
    },
    widths: {
      marginLeft: 'auto',
      marginRight: 'auto',
      boxSizing: 'border-box',
      width: 954,
      maxWidth: '100%',
      ['@media print']: {
        width: 'auto'
      }
    },
    widthsResponsive: {
      [theme.breakpoints.down('md')]: {
        width: 718
      },
      [theme.breakpoints.down('sm')]: {
        width: '96%'
      }
    },
    border: {
      border: `2px dashed ${theme.palette.grey[400]}`
    }
  }
})
export interface PresentationPagesProps {
  classes?: StyleClasses<typeof useStyles>
  pages: PresentationSection[][]
  quote: QuoteDocument
  options: OptionsDocument
  estimator: PresentationEstimator
  isPrint?: boolean
  isPreview?: boolean
  isCustomerView?: boolean
  isSettings?: boolean
  editFromDialog?: boolean
  showHelp?: boolean
  onPresentationClick?: (key: string) => void
  setViewportSections: React.Dispatch<React.SetStateAction<string[]>>
}

function PresentationPages({
  pages,
  quote,
  options,
  estimator,
  isPrint,
  isPreview,
  isCustomerView,
  isSettings,
  editFromDialog,
  showHelp,
  onPresentationClick,
  setViewportSections
}: PresentationPagesProps) {
  return (
    <>
      {pages.map((sections) => {
        return (
          <div id={sections?.[0]?.pageId} key={sections?.[0]?.pageId}>
            {sections
              .filter((section) => {
                if (!(section.active ?? true)) {
                  return false
                }

                // Find a better way to check static components so we don't need to use isEmpty all the time
                const isEmpty = sectionComponents?.[section.type]?.isEmpty
                if ((section.type != 'about-estimator' && section.type !== 'product-order-form') || showHelp) {
                  if (!isEmpty || isEmpty({ quote, options, section, isPrint, isSettings, estimator })) {
                    return false
                  }
                }

                return true
              })

              .map((section, index) => {
                const SectionComponent = sectionComponents?.[section.type]?.Section
                return (
                  <SectionComponent
                    section={section}
                    WrapperComponent={SectionWrapper}
                    key={section.key}
                    index={index}
                    onClick={handleSectionClick(section.key)}
                    isPreview={isPreview}
                    isCustomerView={isCustomerView}
                    isSettings={isSettings}
                    editFromDialog={editFromDialog}
                    showHelp={showHelp}
                    setViewportSections={setViewportSections}
                    quote={quote}
                  />
                )
              })}
          </div>
        )
      })}
    </>
  )

  function handleSectionClick(key: string) {
    return function (_ev: React.MouseEvent) {
      if (onPresentationClick) {
        onPresentationClick(key)
      }
    }
  }
}

export interface SectionWrapperProps extends PresentationSectionProps {
  children: React.ReactElement
  setViewportSections: React.Dispatch<React.SetStateAction<string[]>>
}

const SectionWrapper = function ({
  children,
  onClick,
  key: _key,
  index,
  section,
  showHelp,
  isPreview: _isPreview,
  isSettings,
  setViewportSections,
  ...props
}: SectionWrapperProps) {
  const classes = useStyles(props)
  const { quote } = useQuote()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (!inView) {
      setViewportSections((sections) => sections.filter((viewportSection) => viewportSection !== section.key))
    } else {
      setViewportSections((sections) => [...sections, section.key])
    }
  }, [inView])

  const sectionDetails = useMemo(() => getSectionDetails({ section, quote, isSettings }), [section, quote, isSettings])

  if (!sectionDetails) return null

  const { forcePageBreaks, extraPadding, backgroundStyle, sectionStyles, hasPadding, backgroundImage } = sectionDetails

  return (
    <div
      ref={ref}
      id={section.key}
      onClick={onClick}
      className={classnames({
        [classes.sectionWrapper]: true,
        [classes.pageBreaks]: forcePageBreaks,
        [classes.border]: showHelp,
        [classes.content]: section.type !== 'work-order'
      })}
    >
      <div
        className={classnames({
          [classes.containedBackground]: backgroundStyle === 'contained',
          [classes.widths]: backgroundStyle === 'contained',
          [classes.widthsResponsive]: backgroundStyle === 'contained',
          [classes.firstPaper]: backgroundStyle === 'contained' && index === 0
        })}
        style={sectionStyles}
      >
        <div
          className={classnames({
            [classes.padding]: hasPadding,
            [classes.extraPadding]: extraPadding,
            [classes.backgroundWrapper]: backgroundImage
          })}
        >
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12} style={{ height: '100%' }}>
              {children}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
function getSectionDetails({
  section,
  quote,
  isSettings
}: {
  section: PresentationSection
  quote: QuoteDocument
  isSettings?: boolean
}): {
  forcePageBreaks: boolean
  extraPadding: boolean
  backgroundStyle: string
  sectionStyles: any
  hasPadding: boolean
  backgroundImage: any
} {
  if (!section) return null
  const fields = section?.fields ?? {}

  // For CoverSection background option
  const quoteImageFiles = quote?.files ?? null
  const firstQuoteImageFile = quoteImageFiles ? getFirstQuoteImage(quoteImageFiles, quote) : null
  const getBackgroundFromQuote = fields?.useFirstQuoteImage ?? false

  const background = section?.background ?? {}
  const backgroundStyle = background?.style ?? 'full'
  const sectionStyles: any = {}
  if (background.color) {
    sectionStyles.backgroundColor = background?.color ?? '#fefefe'
  }
  if (background.textColor) {
    sectionStyles.color = background.textColor
  }

  const backgroundImage =
    getBackgroundFromQuote && firstQuoteImageFile && !isSettings
      ? {
          height: 1,
          width: 1.5,
          ...firstQuoteImageFile,
          format: firstQuoteImageFile.type.split('/')[1]
        }
      : fields?.backgroundImage

  if (backgroundImage) {
    sectionStyles.backgroundImage = `url(${backgroundImage?.src})`
    sectionStyles.height = 0
    sectionStyles.backgroundPosition = 'center'
    sectionStyles.backgroundSize = 'contain'
    sectionStyles.backgroundRepeat = 'no-repeat'
    if (backgroundImage.width >= backgroundImage.height) {
      sectionStyles.paddingTop = `${(backgroundImage.height / backgroundImage.width) * 100}%`
    } else {
      sectionStyles.paddingTop = `${(backgroundImage.width / backgroundImage.height) * 1.25 * 100}%`
    }
  }

  // These elements need to pad themselves
  const hasPadding = !['quote', 'work-order'].includes(section.type) && !section?.background?.noPadding
  const forcePageBreaks = ['quote', 'terms', 'work-order'].includes(section.type)
  const extraPadding = hasPadding && backgroundStyle !== 'contained' && section.type !== 'cover'

  return { forcePageBreaks, extraPadding, backgroundStyle, sectionStyles, hasPadding, backgroundImage }
}

// Gets first active image on quote
function getFirstQuoteImage(quoteImageFiles: QuoteFiles, quote: QuoteDocument) {
  const fileKeys = Object.keys(quoteImageFiles)
  const activeFiles = fileKeys
    .map((key) => {
      if (quoteImageFiles[key].visibility === 'visible') {
        return quoteImageFiles[key]
      } else {
        return null
      }
    })
    .filter((item) => Boolean(item))
  const orderedAreas = quote.order.area
    .map(
      (orderItem) =>
        quote.areas[orderItem.key] ?? quote.lineItems[orderItem.key] ?? (quote?.groups ?? {})[orderItem.key]
    )
    .filter((item) => item?.files?.length > 0)
  const firstImageKey = orderedAreas[0]?.files[0]
  return activeFiles.find((file) => file.key === firstImageKey) ?? null
}

export default PresentationPages

import React from 'react'
import type { UserValue } from './UserContext'
import UserContext from './UserContext'

export interface WithUser {
  user: UserValue
}

const withUser = <T extends WithUser>(Component: React.ComponentType<T>) =>
  function WithUser(props: Omit<T, keyof WithUser>) {
    return (
      <UserContext.Consumer>
        {(context: UserValue) => <Component {...(props as any)} user={context} />}
      </UserContext.Consumer>
    )
  }

export default withUser

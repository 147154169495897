import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { GridProps as MuiGridProps } from '@material-ui/core/Grid'
import { default as MuiGrid } from '@material-ui/core/Grid'
import type { StyleClasses } from '../theme'

const useStyles = makeStyles({
  root: {},
  container: {},
  grid2: {
    '@media print': {
      width: '16.666666667%',
      flexBasis: 'unset'
    }
  },
  grid3: {
    '@media print': {
      width: '25%',
      flexBasis: 'unset'
    }
  },
  grid4: {
    '@media print': {
      width: '33.333333333%',
      flexBasis: 'unset'
    }
  },
  grid5: {
    '@media print': {
      width: '41.6666667%',
      flexBasis: 'unset'
    }
  },
  grid6: {
    '@media print': {
      width: '50%',
      flexBasis: 'unset'
    }
  },
  grid7: {
    '@media print': {
      width: '58.333333333%',
      flexBasis: 'unset'
    }
  },
  grid8: {
    '@media print': {
      width: '66.666666667%',
      flexBasis: 'unset'
    }
  },
  grid9: {
    '@media print': {
      width: '75%',
      flexBasis: 'unset'
    }
  },
  grid10: {
    '@media print': {
      width: '83.333333333%',
      flexBasis: 'unset'
    }
  },
  grid11: {
    '@media print': {
      width: '91.666666667%',
      flexBasis: 'unset'
    }
  },
  grid12: {
    '@media print': {
      width: '100%',
      flexBasis: 'unset'
    }
  }
})

export interface GridProps extends Omit<MuiGridProps, 'classes'> {
  classes?: StyleClasses<typeof useStyles>
}

const Grid = React.forwardRef(function Grid(props: GridProps, ref: MuiGridProps['ref']) {
  const classes = useStyles(props)
  const { md, ...baseGridProps } = props
  const gridItemClass = [classes.root]

  if (md === 2) {
    gridItemClass.push(classes.grid2)
  }
  if (md === 3) {
    gridItemClass.push(classes.grid3)
  }
  if (md === 4) {
    gridItemClass.push(classes.grid4)
  }
  if (md === 5) {
    gridItemClass.push(classes.grid5)
  }
  if (md === 6) {
    gridItemClass.push(classes.grid6)
  }
  if (md === 7) {
    gridItemClass.push(classes.grid7)
  }
  if (md === 8) {
    gridItemClass.push(classes.grid8)
  }
  if (md === 9) {
    gridItemClass.push(classes.grid9)
  }
  if (md === 10) {
    gridItemClass.push(classes.grid10)
  }
  if (md === 11) {
    gridItemClass.push(classes.grid11)
  }
  if (md === 12) {
    gridItemClass.push(classes.grid12)
  }

  return (
    <MuiGrid
      {...baseGridProps}
      ref={ref}
      md={md}
      classes={{ container: classes.container, item: gridItemClass.join(' ') }}
    />
  )
})

Grid.defaultProps = {
  color: 'primary'
}

export default Grid

import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const { fields } = section

  if (!fields) {
    return true
  }

  if (!fields || !fields.rows || fields.rows.length === 0) {
    return true
  }

  return fields.rows.reduce((acc, row) => {
    const hasContent = row?.content && row.content.replace('<p></p>', '').trim()
    const emptyRow = !hasContent && !row?.credit && !row?.image

    return acc && emptyRow
  }, false)
}

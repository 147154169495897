import React, { useContext } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import { Grid, Typography, useMediaQuery, useClientOptions, scaleVariant } from '@ui/paintscout'
import { getFeature, getQuoteOptions, getObjectLabels, getDetails } from '@paintscout/util/builder'

import { QuoteContext } from '../../../context/QuoteContext'
import { usePresentationNav } from '../PresentationNavContext'

import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => {
  return {
    totalText: {
      padding: `0 ${theme.spacing(1)}px`,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('lg')]: {
        fontSize: `0.9em`
      },
      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(0.5)}px`
      }
    },
    valueText: {
      padding: `0 ${theme.spacing(1)}px`,
      [theme.breakpoints.down('lg')]: {
        fontSize: `0.9em`
      },
      [theme.breakpoints.down('sm')]: {
        // padding: `0 ${theme.spacing(0.5)}px`
        padding: 0
      }
    },
    sizeSmall: {
      ...scaleVariant(theme.typography.h3, 0.8)
    },
    sizeLarge: {
      ...scaleVariant(theme.typography.h3, 1.3)
    }
  }
})

export interface PresentationNavTotalProps {
  type?: string
  size?: 'small' | 'medium' | 'large'
}

export default function PresentationNavTotal({ type: _type, size, ...props }: PresentationNavTotalProps) {
  const classes = useStyles(props)

  const smDown = useMediaQuery('sm')
  const { view } = usePresentationNav()

  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const toggleWorkOrderTotalHours = getFeature({ options, path: 'quotes.workOrderToggleTotalHours' })

  const { quote } = useContext(QuoteContext)
  const quoteOptions = getQuoteOptions({ options, quote })
  const showWorkOrderAreaValues = quoteOptions?.['showWorkOrderAreaValues'] ?? false

  const { format, value, totalLabel, showUnits } = getValues()

  const totalComponent = 'span'

  if (toggleWorkOrderTotalHours && !showWorkOrderAreaValues) {
    return null
  }

  if (value === 0) {
    return null
  }

  return (
    <Grid item className={'presentation-nav-total'}>
      <Grid container spacing={0} direction={'row'} alignItems={'center'} wrap={'nowrap'}>
        <Grid item>
          <Typography
            variant={'h3'}
            className={classnames({
              [classes.totalText]: true,
              [classes.sizeSmall]: size === 'small',
              [classes.sizeLarge]: size === 'large'
            })}
            component={totalComponent}
          >
            {totalLabel}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant={'h3'}
            className={classnames({
              [classes.valueText]: true,
              [classes.sizeSmall]: size === 'small',
              [classes.sizeLarge]: size === 'large'
            })}
            component={totalComponent}
            showUnits={showUnits}
            showZeros={true}
            format={format}
            value={value}
          />
        </Grid>
      </Grid>
    </Grid>
  )

  function getValues() {
    const details = getDetails({ quote, options })
    const totalHours = details.totals?.hours?.useCustom
      ? details.totals.hours.custom
      : details.totals?.hours?.default ?? 0

    const balanceDue = quote?.totals?.balance_due ?? 0
    const pending = (quote?.totals?.pending?.price ?? 0) + (quote?.totals?.pending?.materials ?? 0)

    const isAdditional =
      (quote.is_invoice || quote.status.value === 'accepted') &&
      !!((quote?.totals?.pending?.materials ?? 0) + (quote?.totals?.pending?.price ?? 0))

    if (view === 'work-order') {
      return {
        value: Number(totalHours),
        totalLabel: 'Hours',
        showUnits: smDown ? true : false,
        format: 'hours'
      }
    } else if (isAdditional) {
      return {
        value: pending,
        totalLabel: 'Additional Items',
        showUnits: true,
        format: 'price'
      }
    } else {
      return {
        value: balanceDue,
        totalLabel:
          quote.is_invoice && !!quote.totals.balance_due ? 'Balance Due' : `${objectLabels.quote.value} Total`,
        showUnits: true,
        format: 'price'
      }
    }
  }
}

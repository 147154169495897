import type { Theme } from '@material-ui/core/styles'
import { makeStyles, styled } from '@material-ui/core/styles'
import { HtmlContent, Table, TableBody, TableCell, TableRow, Typography, useClientOptions } from '@ui/paintscout'
import type { QuoteItemSection, RenderableItem } from '@paintscout/util/builder'
import { getObjectLabels, getQuoteOptions } from '@paintscout/util/builder'
import Tooltip from '@material-ui/core/Tooltip'
import CancelIcon from '@material-ui/icons/Cancel'
import classnames from 'classnames'
import type { QuoteDocument } from 'paintscout'
import React from 'react'
import { isHtmlEmpty } from '../../../..'
import QuoteHeaderCard from '../../../QuoteHeader/QuoteHeaderCard'
import striptags from 'striptags'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '@media print': {
      /*
        Reverted this, as it was realized after the fact that
        it causes the columns to "split" (ie: if left side fits
          and right side does not, it looks wonky because
          the right size will force page break).
        That behaviour is exactly what would be intended with 
        'avoid'... My feeling was just that the trade-off is bad,
        and more people will be happy with the line-breaking to 
        save space on the page.
      */
      // breakInside: 'avoid'
    },
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    background: 'white'
  },
  itemTableCell: {
    width: 555,
    boxSizing: 'border-box',
    '& p, & li': {
      whiteSpace: 'pre-wrap'
    }
  },
  preWrap: {
    whiteSpace: 'pre-wrap'
  },
  itemName: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(2)
  },
  valueTableCell: {
    width: theme.typography.pxToRem(50)
  },
  value: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2)
  },

  crewNoteContent: {
    '& p': {
      whiteSpace: 'pre-wrap'
    },
    marginBottom: theme.spacing(1)
  },
  noteTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },

  header: {
    background: theme.palette.grey[300],
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    // whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(2)
    }
  },
  hrLabel: {
    color: theme.palette.grey[500]
  },
  content: {
    flex: 1,
    border: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: theme.spacing(2)
  },
  innerPostItems: {
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    display: 'grid',
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    gridTemplateColumns: '555px auto',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto'
    }
  },
  innerPostItemsSingle: {
    gridTemplateColumns: '1fr'
  },
  notesCard: {
    minHeight: 0
  },
  notesCardContents: {
    minHeight: 0,
    paddingRight: 0,
    paddingBottom: '0 !important'
  },
  totalCard: {
    textAlign: 'right',
    minHeight: 0
  },
  totalCardTitle: {
    justifyContent: 'flex-end'
  },
  totalCardContents: {
    minHeight: 0,
    paddingRight: 0,
    paddingBottom: '0 !important',
    '& p, & span': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  dimensions: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1)
  },
  divHr: {
    color: 'transparent',
    border: 'none'
  },
  headerSpan: {
    display: 'flex',
    alignItems: 'center'
  },
  cancelIcon: {
    fontSize: '1.0em'
  },
  containerDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  itemDiv: {
    marginRight: '5px'
  }
}))

export interface WorkOrderCardProps {
  item: RenderableItem
  quote: QuoteDocument
  showHours?: boolean
  showPrices?: boolean
  section?: QuoteItemSection
  onClick?: (ev: React.MouseEvent<Element, MouseEvent>) => void
}

export interface WorkOrderCardState {
  collapsed: boolean
}

function WorkOrderCard(props: WorkOrderCardProps) {
  const classes = useStyles(props)
  const { item, showHours, quote, section } = props
  const { options } = useClientOptions()

  const crewNote = item.additionalFields && item.additionalFields.crewNote

  const { showWorkOrderClientNotes } = getQuoteOptions({ quote, options })
  const clientNote = showWorkOrderClientNotes && item.additionalFields ? item.additionalFields.clientNote : ''

  const hasCrewNote = !!striptags(crewNote).trim()
  const hasClientNote = !!striptags(clientNote).trim()

  function allSubstratesAreHidden(key: string) {
    const area = quote.areas[key]

    if (!area?.substrates) {
      return false
    }

    const substrates = Object.keys(area.substrates)
      .map((key) => {
        return area.substrates[key]
      })
      .flat()

    const hasClientVisibleClientVisibleSubstrates = substrates.some((substrate) => !substrate.workOrderOnly)
    const priceOverridden = !!area.totals.override_price
    const descriptionOverridden = !!area.substrate_string.use_custom

    return !hasClientVisibleClientVisibleSubstrates && !priceOverridden && !descriptionOverridden
  }

  let name
  if (!item.name) {
    name = ''
  } else if (typeof item.name === 'string') {
    name = item.name
  } else {
    name = item.name.useCustom ? item.name.custom : item.name.default
  }

  let showTotal = false
  if ((item?.subItems?.length ?? 0) > 1) {
    showTotal = true
  }
  if (
    item?.value !== 0 &&
    item?.subItemTotal &&
    Number(item?.value).toFixed(2) !== Number(item?.subItemTotal).toFixed(2)
  ) {
    showTotal = true
  }

  return (
    <div className={classes.root} onClick={props.onClick}>
      <Typography className={classes.header} variant="h4">
        <span className={classes.headerSpan}>
          {name}
          {item.subtitle ? <span className={classes.dimensions}>({item.subtitle})</span> : null}
          {allSubstratesAreHidden(item.key) && (
            <Tooltip title={'Hidden from customer - Only shows on work order'}>
              <CancelIcon className={classes.cancelIcon} />
            </Tooltip>
          )}
        </span>
        <span className={classes.hrLabel}>hr</span>
      </Typography>

      <div className={classes.content}>
        <Table>
          <TableBody>
            <ItemRows quote={quote} section={section} item={item} showHours={showHours} />
            {/* {item.type !== 'line_item' && showSubstrateOrAreaDescription && (
              <>
                <hr className={classes.divHr} />
                <StyledTableRow>
                  <TableCell padding="none" className={classes.itemTableCell}>
                    <Typography variant={'body2'} component={'div'}>
                      <HtmlContent
                        classes={{ root: 'crew-note-highlight' }}
                        content={item?.description?.default ?? ''}
                      />
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              </>
            )} */}
          </TableBody>
        </Table>
        {(hasCrewNote || hasClientNote || showTotal) && (
          <div className={classnames(classes.innerPostItems, { [classes.innerPostItemsSingle]: !showHours })}>
            {(hasClientNote || hasCrewNote) && (
              <QuoteHeaderCard title="Notes" classes={{ contents: classes.notesCardContents, root: classes.notesCard }}>
                {hasCrewNote && (
                  <div className={classes.crewNoteContent}>
                    <Typography classes={{ root: classes.noteTitle }} variant={'body2'}>
                      Crew
                    </Typography>

                    <Typography classes={{ root: 'crew-note-highlight' }} variant={'body2'}>
                      <HtmlContent content={crewNote} />
                    </Typography>
                  </div>
                )}
                {hasClientNote && showWorkOrderClientNotes && (
                  <div className={classes.crewNoteContent}>
                    <Typography classes={{ root: classes.noteTitle }} variant={'body2'}>
                      Client
                    </Typography>

                    <Typography variant={'body2'} classes={{ root: 'client-note-highlight' }}>
                      <HtmlContent content={clientNote} />
                    </Typography>
                  </div>
                )}
              </QuoteHeaderCard>
            )}

            {showTotal && showHours && (
              <QuoteHeaderCard
                title="Total"
                classes={{
                  root: classes.totalCard,
                  contents: classes.totalCardContents,
                  title: classes.totalCardTitle
                }}
              >
                <TotalAreaHours item={item} />
              </QuoteHeaderCard>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

function TotalAreaHours({ item }) {
  const classes = useStyles()
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const additional = []
  if (item.additionalFields?.prep) {
    additional.push(
      <div className={classes.itemDiv} key={'prep'}>
        <Typography variant="body1" component="span">
          Prep:
        </Typography>{' '}
        <Typography variant={'body1'} format={'hours'} component="span" value={Number(item.additionalFields.prep)} />
      </div>
    )
  }

  if (item.additionalFields?.prep && item.additionalFields?.painting) {
    additional.push(
      <div className={classes.itemDiv} key={'add'}>
        {' '}
        <Typography variant="body1" component="span">
          +
        </Typography>{' '}
      </div>
    )
  }

  if (item.additionalFields?.painting) {
    additional.push(
      <div className={classes.itemDiv} key={'additional'}>
        <Typography variant="body1" component="span">
          {objectLabels.labour.value}:
        </Typography>{' '}
        <Typography
          variant={'body1'}
          format={'hours'}
          component="span"
          value={Number(item.additionalFields.painting)}
        />
      </div>
    )
  }

  if (additional.length) {
    additional.push(
      <div className={classes.itemDiv} key={'equals'}>
        {' '}
        <Typography variant="body1" component="span">
          =
        </Typography>{' '}
      </div>
    )
  }

  additional.push(
    <Typography
      variant={'body1'}
      format={'hours'}
      component="span"
      value={Number(item.value)}
      key={'lastly'}
      className={classes.itemDiv}
    />
  )

  return <div className={classes.containerDiv}>{additional}</div>
}

function ItemRows({
  item,
  showHours
}: {
  quote?: QuoteDocument
  section?: QuoteItemSection
  item: RenderableItem
  showHours?: boolean
}) {
  const classes = useStyles({})
  const { options } = useClientOptions()
  const showCoats = options.options.quotes.showWorkOrderCoats
  const showSubstrateOrAreaDescriptions = options?.options?.quotes?.showWorkOrderViewDescriptions ?? false

  let name
  if (!item.name) {
    name = ''
  } else if (typeof item.name === 'string') {
    name = item.name
  } else {
    name = item.name.useCustom ? item.name.custom : item.name.default
  }

  if (item.subItems && item.subItems.length > 0) {
    return (
      <>
        {item.subItems.map((subItem, index) => {
          const coats = subItem.additionalFields?.coats ? subItem.additionalFields.coats.toString() : ''
          const hasReportText = !isHtmlEmpty(subItem.reportText)
          const hasCrewNote = !!striptags(subItem.crewNote).trim()

          return (
            <StyledTableRow key={index}>
              <TableCell padding="none" className={classes.itemTableCell}>
                <Typography variant={'body1'} className={classes.itemName}>
                  {subItem.name}
                </Typography>
                <Typography variant={'body2'} component={'div'}>
                  <HtmlContent content={subItem.description} />
                </Typography>
                {hasCrewNote && (
                  <Typography variant={'body2'} component={'div'}>
                    <HtmlContent classes={{ root: 'crew-note-highlight' }} content={subItem.crewNote} />
                  </Typography>
                )}
                {showCoats && !!coats && (
                  <Typography variant={'body2'} component={'div'}>
                    <HtmlContent classes={{ root: 'crew-note-content' }} content={`Coats: ${coats}`} />
                  </Typography>
                )}
                {hasReportText && showSubstrateOrAreaDescriptions && (
                  <>
                    <Typography variant={'body2'} component={'div'}>
                      <HtmlContent classes={{ root: 'crew-note-content' }} content={subItem.reportText} />
                    </Typography>
                  </>
                )}
              </TableCell>
              {showHours && (
                <TableCell padding="none" align="right" className={classes.valueTableCell}>
                  <Typography
                    className={classes.value}
                    variant={'body1'}
                    value={typeof subItem.value === 'string' ? parseFloat(subItem.value) : subItem.value}
                    format={'hours'}
                  />
                </TableCell>
              )}
            </StyledTableRow>
          )
        })}
      </>
    )
  }

  const description = item.description.useCustom ? item.description.custom : item.description.default
  const isHtml = description.includes('<p>')

  return (
    <StyledTableRow>
      <TableCell padding="none" className={classnames({ [classes.itemTableCell]: true, [classes.preWrap]: !isHtml })}>
        <Typography variant={'body1'} className={classes.itemName}>
          {name}
        </Typography>
        <Typography variant={'body2'} component={'div'}>
          <HtmlContent content={description} />
        </Typography>
      </TableCell>
      {showHours && (
        <TableCell align="right" padding="none" className={classes.valueTableCell}>
          <Typography className={classes.value} format={'hours'} variant={'body1'} value={Number(item.value)} />
        </TableCell>
      )}
    </StyledTableRow>
  )
}

const StyledTableRow = styled((props) => <TableRow noBorder {...props} />)({
  verticalAlign: 'top'
})

export default WorkOrderCard

import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import { Backdrop } from '../Dialog'
import React, { useContext } from 'react'
import type { DialogProps } from '../Dialog'
import DialogStackContext from './DialogStackContext'
import type { WithDialogStackContext } from './withDialogStackContext'
import withDialogStackContext from './withDialogStackContext'
import { AlertDialog } from '../dialogs'
import ErrorIcon from '@material-ui/icons/Error'
import * as Sentry from '@sentry/core'
import { usePrevious } from '@ui/core'

const styles = (theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal
    }
  })

export interface DialogStackRendererProps extends WithStyles<typeof styles> {}

function DialogStackRenderer({ classes }: DialogStackRendererProps, ref?: React.Ref<HTMLDivElement>) {
  const { stack } = useContext(DialogStackContext)

  const prevStackLength = usePrevious(stack.length)
  const currentDialog = stack[stack.length - 1]

  return (
    <DialogErrorBoundary>
      <div ref={ref} data-testid="dialog-stack">
        <Backdrop className={classes.backdrop} open={stack.length > 0} transitionDuration={225} />
        {stack.map((dialog, index) => {
          const DialogComponent = dialog.component as React.ComponentType<DialogProps>

          return (
            <DialogComponent
              key={index}
              open={dialog === currentDialog}
              keepMounted={true}
              transitionDuration={prevStackLength === 0 ? 225 : 0}
              {...dialog.props}
              hideBackdrop
            >
              {dialog.props.children}
            </DialogComponent>
          )
        })}
      </div>
    </DialogErrorBoundary>
  )
}

const DialogErrorBoundary = withDialogStackContext(
  class DialogErrorBoundary extends React.Component<WithDialogStackContext> {
    // there will be a warning about using getDerivedStateFromError and showing an error message instead of the children
    // but we just want to dismiss the dialogs
    public componentDidCatch(error: any, info: any) {
      console.log(info)
      console.log(error)
      const currentDialog = this.props.dialogStackContext.stack[this.props.dialogStackContext.stack.length - 1]
      console.log({ currentDialog, component: currentDialog.component })

      Sentry.captureException(error, {
        tags: {
          crash: 'dialog'
        }
      })
      this.props.dialogStackContext.dismissAllDialogs()
      setTimeout(() =>
        this.props.dialogStackContext.openDialog(AlertDialog, {
          icon: <ErrorIcon />,
          title: 'An unexpected error occurred',
          message: `We are sorry for the inconvenience`,
          onConfirm: this.props.dialogStackContext.dismissDialog
        })
      )
    }

    public render() {
      return this.props.children
    }
  }
)

export default withStyles(styles)(React.forwardRef(DialogStackRenderer))

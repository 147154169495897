import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field } from 'formik'
import { Grid, FormikInputField, Tooltip, InputLabel } from '@ui/paintscout'

const CustomForm = (_props: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field label={'Title'} name={'section.title'} fullWidth={true} component={FormikInputField} />
      </Grid>
      <Grid item xs={12}>
        <Tooltip title={'HTML Content'} content={<p>This section will show a block of Custom on the presentation.</p>}>
          <InputLabel>Content</InputLabel>
        </Tooltip>
        <Field component={FormikInputField} name={'section.fields.html'} fullWidth={true} multiline={true} />
      </Grid>
      <Grid item xs={12}>
        <Tooltip title={'CSS Styles'} content={<p>Customize the html above with CSS.</p>}>
          <InputLabel>Styles</InputLabel>
        </Tooltip>
        <Field component={FormikInputField} name={'section.fields.css'} fullWidth={true} multiline={true} />
      </Grid>
    </Grid>
  )
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(CustomForm)

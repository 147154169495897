import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect } from 'formik'
import { Typography, useClientOptions, Grid } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'

const QuoteForm = (_: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={'body1'}>
          This section will show the {objectLabels.quote.value} as it appears on the {objectLabels.quote.value} page.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'}>
          It will always show in the customer's header Navigation, with a title of "{objectLabels.quote.value}"
        </Typography>
      </Grid>
    </Grid>
  )
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(QuoteForm)

import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, Grid, withStyles } from '@material-ui/core'
import type { DropdownSelectOption } from '@ui/paintscout'
import {
  Button,
  ClientOptionsContext,
  ConfirmationDialog,
  DialogStackContext,
  DropdownSelect,
  Dropzone,
  FilePreview,
  InputField,
  Typography
} from '@ui/paintscout'

import { getObjectLabels } from '@paintscout/util/builder'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'
import type { QuoteFile, QuoteFileVisibility } from 'paintscout'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useConnection } from 'react-detect-offline'

import { PERMITTED_FILE_TYPES } from '@paintscout/util/s3'
import getErrorInfo from './getErrorInfo'
import RetryOverlay from './RetryOverlay'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    rootGrid: {
      width: '100%',

      // Grid container "spacing" prop applies margin to itself as well, but we don't want that
      margin: 0
    },
    dropzoneWrapper: {
      display: 'flex',
      justifyContent: 'center'
    },
    dropzone: {
      position: 'relative',
      width: '100%',
      height: '40vh',
      maxHeight: 500, // keeps portrait photos from getting too wonky on 12.9inch ipad portrait
      '& :not($disabled)': {
        cursor: 'pointer'
      },
      [theme.breakpoints.down('sm')]: {
        width: 320,
        height: 180
      }
    },
    disabled: {},
    uploadButton: {
      height: '100%'
    },
    filePreview: {
      width: '100%',
      height: '100%'
    },
    deleteButton: {
      color: theme.palette.error.main
    },
    image: {
      objectFit: 'contain',
      background: 'transparent'
    }
  })

export interface EnhancedQuoteFile extends QuoteFile {
  error?: string
  uploading?: boolean
  failedFile?: File
}

export interface DropzoneError {
  error: string
  key?: string
  file?: File
}

export interface FileViewProps extends WithStyles<typeof styles> {
  isNew?: boolean
  file?: EnhancedQuoteFile
  disabled?: boolean
  disabledMsg?: string
  onChange?: (file: EnhancedQuoteFile) => any
  onDelete?: (key: string) => any
  onUpload?: (files: File[]) => any
  onReplace?: (file: File) => any
  onRetry?: () => any
  onDropzoneError?: (files: DropzoneError[]) => any
}

function FileView({
  file,
  classes,
  onChange,
  onDelete,
  onUpload,
  onReplace,
  onDropzoneError,
  onRetry,
  disabledMsg,
  ...props
}: FileViewProps) {
  const { options } = useContext(ClientOptionsContext)
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const dropzoneRef = useRef(null)
  const labels = getObjectLabels({ options })
  const { online } = useConnection()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [heicError, setHeicError] = useState(false)
  const [wasOnline, _setWasOnline] = useState(online)

  useEffect(() => {
    if (heicError) {
      setHeicError(false)
      setTimeout(() => {
        closeSnackbar()
      }, 9000)
    }
  }, [heicError])

  const visibilityOptions: DropdownSelectOption[] = [
    {
      label: `${labels.quote.value}`,
      value: 'quote'
    },
    {
      label: 'Work Order',
      value: 'work-order'
    }
  ]

  function handleInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange({
        ...file,
        [ev.target.name]: ev.target.value
      })
    }
  }

  function handleVisiblityChange(options: DropdownSelectOption[]) {
    if (onChange) {
      const quoteSelected = options.find(({ value }) => value === 'quote')
      const workOrderSelected = options.find(({ value }) => value === 'work-order')
      let visibility: QuoteFileVisibility = 'hidden'

      if (quoteSelected && workOrderSelected) {
        visibility = 'visible'
      } else if (quoteSelected) {
        visibility = 'customer'
      } else if (workOrderSelected) {
        visibility = 'crew'
      } else {
        visibility = 'hidden'
      }

      onChange({
        ...file,
        visibility
      })
    }
  }

  function getVisibilityValue() {
    switch (file?.visibility) {
      case 'visible':
        return visibilityOptions
      case 'customer':
        return [visibilityOptions[0]]
      case 'crew':
        return [visibilityOptions[1]]
      case 'hidden':
        return []
      default:
        return []
    }
  }

  function handleDelete() {
    if (onDelete) {
      openDialog(ConfirmationDialog, {
        title: 'Delete Media Item',
        message: 'Are you sure you want to delete this Media item?',
        onConfirm: () => {
          onDelete(file.key)
          dismissDialog()
        },
        onCancel: dismissDialog
      })
    }
  }

  function handleError(files: File[], errors: any[]) {
    if (onDropzoneError) {
      if (!files[0].type.indexOf('heif')) {
        onDropzoneError(
          files.map((erroredFile, index) => {
            if (index === 0 && file) {
              return {
                key: file.key,
                file: erroredFile,
                error: errors[index]
              }
            } else {
              return {
                file: erroredFile,
                error: errors[index]
              }
            }
          })
        )
      }
    }
  }

  const errorInfo = file && file.error && getErrorInfo(file.error)
  const retryable = errorInfo && errorInfo.retryable
  const disabled = !wasOnline || props.disabled

  return (
    <div className={classnames(classes.root, { [classes.disabled]: !!disabled })} data-testid="file-view">
      <Grid
        container
        className={classes.rootGrid}
        direction="column"
        alignItems="stretch"
        justifyContent="space-between"
        spacing={3}
        wrap="nowrap"
      >
        {errorInfo && errorInfo.msg && (
          <Typography color="error" align="center" data-testid="file-view-error-message">
            {errorInfo.msg}
          </Typography>
        )}
        <Grid item xs={12} className={classes.dropzoneWrapper}>
          <Dropzone
            dropzoneRef={dropzoneRef}
            accept={PERMITTED_FILE_TYPES.join(',')}
            classes={{
              root: classes.dropzone,
              uploadButton: classes.uploadButton
            }}
            onDropAccepted={(accepted) => {
              if (file) {
                if (onReplace) {
                  onReplace(accepted[0])
                }
              } else {
                onUpload(accepted)
              }
            }}
            onDropRejected={(rejected) => {
              if (rejected[0].type.indexOf('heif') > -1) {
                enqueueSnackbar(
                  'Heic images are not supported. See: Settings > Camera > Formats > "Most Compatible" -OR- Settings > Photos > Transfer to Mac or PC > "Automatic" to improve compatibility.',
                  { variant: 'error', persist: true }
                )
                setHeicError(true)
              } else {
                enqueueSnackbar('Unable to upload files of this format.', { variant: 'error' })
              }
            }}
            disabled={disabled}
            onError={handleError}
            noClick={!!file}
            multiple={!file}
            shouldRenderPreview={!!file}
            renderPreview={() => (
              <>
                <FilePreview
                  classes={{
                    root: classes.filePreview,
                    image: classes.image
                  }}
                  file={file}
                  uploading={file.uploading}
                />
                {retryable && <RetryOverlay onRetry={onRetry} />}
              </>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" align="left">
            Max file size 100MB
          </Typography>
        </Grid>
        {!file && disabled && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="center">
              {!online ? 'You must be online to upload' : disabledMsg}
            </Typography>
          </Grid>
        )}
        {file && (
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item>
              <Button
                variant="text"
                fullWidth={true}
                disabled={file.uploading}
                onClick={() => {
                  dropzoneRef.current.open()
                }}
              >
                Change
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                className={classes.deleteButton}
                fullWidth={true}
                disabled={file.uploading}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <InputField
                fullWidth={true}
                name="title"
                label="Title"
                sublabel="Shown under thumbnail"
                value={(file && file.title) || ''}
                disabled={!file}
                onChange={handleInputChange}
                margin="dense"
                data-testid="file-view-title"
              />
            </Grid>
            <Grid item>
              <InputField
                fullWidth={true}
                name="caption"
                label="Caption"
                sublabel="Shown under thumbnail"
                value={(file && file.caption) || ''}
                disabled={!file}
                onChange={handleInputChange}
                multiline={true}
                margin="dense"
                data-testid="file-view-caption"
              />
            </Grid>
            <Grid item>
              <DropdownSelect
                fullWidth
                variant="multi"
                name="visibility"
                label="Show on"
                placeholder={{
                  selectPlaceholder: 'Hidden'
                }}
                popperPlacement="top-start"
                sublabel="Select where it is shown"
                options={visibilityOptions}
                value={getVisibilityValue()}
                onChange={handleVisiblityChange}
                disabled={!file}
                margin="dense"
                data-testid="file-view-visibility"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(FileView)

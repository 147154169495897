import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect } from 'formik'
import { Typography, Grid } from '@ui/paintscout'

const DividerForm = ({ ..._props }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={'body1'}>This section will show a horizontal rule / divider.</Typography>
        <Typography variant={'body1'}>
          It's color or padding can be changed by changing the text color on the style tab.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(DividerForm)

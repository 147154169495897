// Convert calculation type to be updateable
export default function convertCalculationType(calculationType: string): string {
  switch (calculationType) {
    case 'value_per_hour':
      return 'amountPerHour'
    case 'price_per_value':
      return 'pricePerAmount'
    case 'hours_per_value':
      return 'hoursPerAmount'
    case 'value_per_price':
      return 'amountPerPrice'
    default:
      return calculationType
  }
}

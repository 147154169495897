import type { QuoteArea, AreaSubstrates, AreaSubstrate, QuoteDocument, OptionsDocument } from 'paintscout'
import { singleSubstrate } from './single-substrate'

export function areaSubstrates(area: QuoteArea, quote: QuoteDocument, options: OptionsDocument): AreaSubstrates {
  if (!area.substrates) {
    return {}
  }

  const updatedSubstrates: AreaSubstrates = {}

  Object.keys(area.substrates).forEach((section: string) => {
    updatedSubstrates[section] = []
    area.substrates[section].forEach((substrate: AreaSubstrate) => {
      updatedSubstrates[section].push(singleSubstrate(substrate, area, quote, options))
    })
  })

  return updatedSubstrates
}

import React from 'react'
import Button from '../Button'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: `${theme.palette.primary.contrastText} !important`,
      padding: `0 ${theme.spacing(2)}px`,
      margin: theme.spacing(),
      whiteSpace: 'nowrap'
    }
  })

export interface BulkActionButtonProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  icon?: React.ComponentType<SvgIconProps>
  style?: React.CSSProperties
  onClick?: (selectedItems: any[]) => void
}

const BulkActionButton = (props: BulkActionButtonProps) => (
  <Button
    style={props.style}
    className={classnames(props.classes.root, props.className)}
    variant={'text'}
    onClick={props.onClick as any}
    icon={props.icon}
    disabled={props.disabled}
  >
    {props.children}
  </Button>
)

export default withStyles(styles)(BulkActionButton)

import type { QuoteArea } from 'paintscout'
import { parseNumber } from '../util/parse-number'
export function calculateSurfaceSqft(area: QuoteArea) {
  /*
    There's not really any reason to not just calculate
    the sqft no matter what, then it's available when 
    changing between Room/Surface easily.
  */

  const length = parseNumber(area.dimensions && area.dimensions.length ? area.dimensions.length : 0)
  const height = parseNumber(area.dimensions && area.dimensions.height ? area.dimensions.height : 0)

  return length * height
}

import { Badge, Dialog, Fab, IconButton, makeStyles, Popover, useMediaQuery, useTheme } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/ChatBubble'
import CloseIcon from '@material-ui/icons/Close'
import type { MessagesQuery, MessageActivity } from '@paintscout/api'
import { useReadMessagesMutation, MessagesDocument } from '@paintscout/api'
import { useQuote } from '@ui/react-quote'
import { Hidden } from '@ui/paintscout'
import { AnimatePresence, motion } from '@ui/core'
import React, { useEffect, useState } from 'react'
import Chat from './Chat'
import UnreadMessagePreview from './UnreadMessagePreview'

const useStyles = makeStyles((theme) => ({
  root: {
    '@media print': {
      display: 'none'
    },
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  popover: {
    marginTop: -theme.spacing(2)
  },
  popoverPaper: {
    height: 500,
    width: 375
  },
  dialog: {
    paddingBottom: `env(safe-area-inset-bottom)`
  },
  fab: {
    position: 'relative',
    [theme.breakpoints.up('xl')]: {
      minWidth: '8em'
    }
  },
  fabText: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  icon: {
    position: 'absolute',
    top: 12,
    display: 'flex'
  },
  mobileCloseButton: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing()
  },
  mobileChatHeader: {
    justifyContent: 'center',
    flexDirection: 'row-reverse'
  },
  messagePreview: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(3)
  }
}))

const animationVariants = {
  open: {
    scale: 1,
    opacity: 1
  },
  closed: {
    scale: 0,
    opacity: 0
  }
}

export default function ChatButton() {
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isOpen = !!anchorEl
  const { quote, refetchQuote } = useQuote()
  const [unread, setUnread] = useState<MessageActivity[]>([])
  const [unreadPreview, setUnreadPreview] = useState<MessageActivity>(null)

  const [readMessages] = useReadMessagesMutation({
    update: (cache, result) => {
      if (result?.data?.readMessages?.length > 0) {
        const prev = cache.readQuery<MessagesQuery>({
          query: MessagesDocument,
          variables: {
            quoteId: quote._id,
            limit: 20
          }
        })

        cache.writeQuery<MessagesQuery>({
          query: MessagesDocument,
          variables: {
            quoteId: quote._id,
            limit: 20
          },
          data: {
            ...prev,
            messages: {
              ...prev.messages,
              messages: prev.messages.messages.map((message) => {
                // the __typename check keeps typescript happy for knowing which type of activity this is
                if (
                  message.__typename === 'MessageActivity' &&
                  result.data.readMessages.find((m) => m._id === message._id)
                ) {
                  return {
                    ...message,
                    details: {
                      ...message.details,
                      message: {
                        ...message.details.message,
                        readAt: Date.now()
                      }
                    }
                  }
                }

                return message
              })
            }
          }
        })
      }
    }
  })

  // mark unread messages as read
  useEffect(() => {
    if (isOpen && unread.length > 0) {
      setUnread([])
      readMessages({
        variables: {
          quoteId: quote._id
        }
      })
    }
    // eslint-disable-next-line
  }, [isOpen, unread])

  // get the first unread message for preview
  useEffect(() => {
    if (unread.length > 0 && !unreadPreview) {
      setUnreadPreview(unread[0])
    } else if (unread.length === 0) {
      setUnreadPreview(null)
    }
    // eslint-disable-next-line
  }, [unread])

  function handleClose() {
    setAnchorEl(null)
  }

  const chat = (
    <Chat
      key="chat"
      classes={{
        header: isMobile && classes.mobileChatHeader
      }}
      onUnread={(activites) => {
        if (activites.length > unread.length) {
          setUnread(activites)
          refetchQuote()
        }
      }}
    />
  )

  return (
    <div className={classes.root}>
      <Badge
        badgeContent={!isOpen && unread.length > 0 ? `${unread.length > 10 ? '10+' : unread.length}` : null}
        color="primary"
      >
        <Fab
          data-testid="chat-button"
          className={classes.fab}
          color="primary"
          variant="extended"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <AnimatePresence>
            <motion.div
              className={classes.icon}
              key={isOpen.toString()}
              variants={animationVariants}
              animate="open"
              initial="closed"
              exit="closed"
            >
              {isOpen ? (
                <>
                  <span className={classes.fabText}>Close</span>
                  <CloseIcon />
                </>
              ) : (
                <>
                  <span className={classes.fabText}>Chat</span>
                  <ChatIcon />
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </Fab>
      </Badge>
      <Hidden mdDown>
        <AnimatePresence>
          {!isOpen && unreadPreview && (
            <UnreadMessagePreview className={classes.messagePreview} message={unreadPreview} />
          )}
        </AnimatePresence>
      </Hidden>
      {isMobile ? (
        <Dialog
          classes={{
            paper: classes.dialog
          }}
          open={isOpen}
          fullScreen
          keepMounted
        >
          {chat}
          <IconButton className={classes.mobileCloseButton} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Dialog>
      ) : (
        <Popover
          className={classes.popover}
          classes={{
            paper: classes.popoverPaper
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          anchorEl={anchorEl}
          open={isOpen}
          disableScrollLock
          keepMounted
          onClose={() => handleClose()}
        >
          {chat}
        </Popover>
      )}
    </div>
  )
}

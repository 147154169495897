import identity from 'lodash/identity'
import type { OptionsDocument, QuoteTag } from 'paintscout'
import { getQuoteTags } from '../../options/get-quote-tags'

export const getTags = (args: { options: OptionsDocument; keys: string[] }): QuoteTag[] => {
  const { options, keys } = args
  const tags = getQuoteTags({ options })

  if (!keys || !tags) {
    return []
  }

  const foundTags = keys.map((key) => {
    return tags.find((tag) => tag.name === key)
  })

  return foundTags.filter(identity)
}

import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const { fields } = section

  if (!fields.files) {
    return true
  }

  if (!fields.files.length) {
    return true
  }

  return false
}

import type { OfflineQuotesQuery, QuoteQuery, SearchQuotesQuery } from '../generated/types'
import { SearchQuotesDocument } from '../generated/types'
import { OfflineQuotesDocument, QuoteDocument as QuoteGQLDocument, SearchedQuoteFragmentDoc } from '../generated/types'
import type ApolloCache from '@wora/apollo-cache'
import { defaultDataIdFromObject } from 'apollo-cache-inmemory'
import type { QuoteDocument } from 'paintscout'
import type { CacheProxy } from '.'
import { updateQueries } from './util'

// const RE_QUERY_VARIABLES = /({.*})/g

/**
 * Updates any cached quotes in the apollo cache
 */
export function updateCachedQuote(proxy: CacheProxy, quote: QuoteDocument) {
  const cache = proxy as ApolloCache

  // if QuoteGQLDocument queries haven't been made yet we'll make it
  // todo: use cache redirects instead: https://www.apollographql.com/docs/react/caching/cache-interaction/#cache-redirects-with-cacheredirects
  cache.writeQuery<QuoteQuery>({
    query: QuoteGQLDocument,
    variables: { id: quote._id },
    data: { quote: { ...quote, __typename: 'JSON' } }
  })

  // update searchQuote results
  cache.writeFragment({
    id: defaultDataIdFromObject({ id: quote._id, __typename: 'Quote' }),
    fragment: SearchedQuoteFragmentDoc,
    data: {
      _deleted: null,
      tags: null,
      parent_quote: null,
      parent_quote_number: null,
      child_invoices: null,
      child_invoice_numbers: null,
      trashed: null,
      trashedDate: null,
      trashedBy: null,
      deleteDate: null,
      lastMessage: null,
      number: null,
      jobAddress: null,
      job_identifier: null,
      archived: null,
      owner: null,
      quote_date: null,
      totals: null,
      error: null,
      int_ext: null,
      lead_source: null,
      demoTitle: null,
      payments: null,
      acceptedVia: null,
      addedOptionsTotal: null,
      additionalWorkTotal: null,
      totalHours: null,
      amountPaid: null,
      balanceDue: null,
      ...quote,
      contact: quote.contact
        ? {
            ...quote.contact,
            __typename: 'QuoteContact'
          }
        : null,
      secondaryContact: quote.secondaryContact
        ? {
            ...quote.secondaryContact,
            __typename: 'QuoteContact'
          }
        : null,
      __typename: 'Quote'
    }
  })

  // update all searchQuote queries to remove the quote
  if (quote._deleted) {
    updateQueries<SearchQuotesQuery>(cache, {
      query: SearchQuotesDocument,
      update: (data) => {
        const filteredQuotes = data.searchQuotes.quotes.filter((q) => q._id !== quote._id)

        return {
          ...data,
          searchQuotes: {
            ...data.searchQuotes,
            quotes: filteredQuotes,
            total_rows:
              filteredQuotes.length !== data.searchQuotes.quotes.length
                ? data.searchQuotes.total_rows - 1
                : data.searchQuotes.total_rows
          }
        }
      }
    })
  }

  // update OfflineQuotes
  if (quote.type !== 'quoteTemplate') {
    let offlineQuotesData: OfflineQuotesQuery

    try {
      offlineQuotesData = cache.readQuery<OfflineQuotesQuery>({
        query: OfflineQuotesDocument
      })
    } catch (e) {
      offlineQuotesData = {
        offlineQuotes: {
          __typename: 'OfflineQuotesResponse',
          quotes: [],
          invoices: [],
          contacts: [],
          templates: []
        }
      }
    }

    const updatedOfflineQuotes = !quote.is_invoice
      ? updateQuoteArray({ ...quote, __typename: 'JSON' }, offlineQuotesData.offlineQuotes.quotes)
          // also filter out archived since it's offline quotes
          .filter((q) => !q.archived && !q.trashed)
      : offlineQuotesData.offlineQuotes.quotes

    const updatedOfflineInvoices = quote.is_invoice
      ? updateQuoteArray({ ...quote, __typename: 'JSON' }, offlineQuotesData.offlineQuotes.invoices).filter(
          (q) => !q.archived && !q.trashed
        )
      : offlineQuotesData.offlineQuotes.invoices

    cache.writeQuery<OfflineQuotesQuery>({
      query: OfflineQuotesDocument,
      data: {
        ...offlineQuotesData,
        offlineQuotes: {
          ...offlineQuotesData.offlineQuotes,
          quotes: updatedOfflineQuotes,
          invoices: updatedOfflineInvoices
        }
      }
    })
  }
}

function updateQuoteArray(quote: any, quotes: any[]) {
  if (quote._deleted) {
    return quotes.filter((q) => q._id !== quote._id)
  }

  const index = quotes.findIndex((q) => q._id === quote._id)
  if (index !== -1) {
    return [...quotes.slice(0, index), quote, ...quotes.slice(index + 1)]
  } else {
    return [quote, ...quotes]
  }
}

export function updateCachedQuotePartially(cache: ApolloCache, quoteId: string, values: Partial<QuoteDocument>) {
  try {
    const quote = cache.readQuery<QuoteQuery>({
      query: QuoteGQLDocument,
      variables: { id: quoteId }
    }).quote

    updateCachedQuote(cache, {
      ...quote,
      ...values
    })
  } catch (e) {
    console.warn('Unable to find quote for id ' + quoteId)
  }
}

/**
 * @module builder
 */

import type { OptionsDocument, PaymentMethodOption } from 'paintscout'

export function getPaymentMethodOptions(options: OptionsDocument): PaymentMethodOption[] {
  return (options.options.paymentMethods?.order ?? [])
    .map((orderItem) => {
      const item: PaymentMethodOption = options.options.paymentMethods?.values[orderItem]
      if (!item) {
        return null
      }

      return {
        key: orderItem,
        label: item.label
      }
    })
    .filter(Boolean)
}

export default function getErrorInfo(error: string) {
  let msg = 'Failed to upload file'
  let retryable = true
  switch (error) {
    case 'LIMIT_FILE_SIZE':
      msg = `File is too big, please make sure it is 100mb or smaller`
      retryable = false
      break
    case 'Network Error':
      msg = 'Failed to upload file due to network error'
      break
  }

  return {
    msg,
    retryable
  }
}

import type { QuoteDocument, OptionsDocument } from 'paintscout'
import { getQuoteTypeOptions } from '../../builder'

export const getQuoteTypeSubTotals = (args: { quote: QuoteDocument; options: OptionsDocument }) => {
  const { quote, options } = args
  let subTotals = {
    int: 0,
    ext: 0,
    all: 0
  }

  if (!quote) {
    return subTotals
  }

  const lineItems = Object.keys(quote.lineItems ?? {})
  const areas = Object.keys(quote.areas ?? {})
  const groups = Object.keys(quote.groups ?? {})
  const quoteTypeOptions = getQuoteTypeOptions({ options })

  if (lineItems.length > 0) {
    lineItems.forEach((lineItemKey) => {
      const lineItem = quote.lineItems[lineItemKey]
      if (lineItem) {
        const quoteType =
          lineItem?.quoteType && quoteTypeOptions.find((option) => option.value === lineItem?.quoteType)
            ? lineItem.quoteType
            : 'all'
        const price = lineItem?.totals?.afterMaterials ?? 0
        if (lineItem.use_total) {
          subTotals = {
            ...subTotals,
            [quoteType]: subTotals[quoteType] ? subTotals[quoteType] + price : price
          }
        }
      }
    })
  }

  if (areas.length > 0) {
    areas.forEach((areaKey) => {
      const area = quote.areas[areaKey]

      const { totals, int_ext } = area
      const { price, override_price_value, override_price, materials, override_materials, override_materials_value } =
        totals

      const materialsPrice = override_materials ? override_materials_value : materials
      const adjustedPrice = override_price ? override_price_value : price
      const totalPrice = adjustedPrice + materialsPrice

      const quoteType =
        int_ext?.value && quoteTypeOptions.find((option) => option.value === int_ext?.value) ? int_ext?.value : 'all'

      if (area && area.use_total) {
        subTotals = {
          ...subTotals,
          [quoteType]: subTotals[quoteType] ? subTotals[quoteType] + totalPrice : totalPrice
        }
      }
    })
  }

  if (groups.length > 0) {
    groups.forEach((groupKey) => {
      const group = quote.groups[groupKey]

      group.children.forEach((child) => {
        if (quote?.substrates[child]) {
          // If substrate, add totals to group
          const substrate = quote?.substrates[child]

          let quoteType
          if (substrate.int_ext && substrate.int_ext.value) {
            quoteType = substrate.int_ext.value
          }

          quoteType = quoteType && quoteTypeOptions.find((option) => option.value === quoteType) ? quoteType : 'all'

          const price = substrate?.grouped_totals?.price ?? 0

          subTotals = {
            ...subTotals,
            [quoteType]: subTotals[quoteType] ? subTotals[quoteType] + price : price
          }
        }
      })
    })
  }

  return subTotals
}

export const getQuoteTypeHourTotals = (args: { quote: QuoteDocument; options: OptionsDocument }) => {
  const { quote, options } = args
  let hourTotals = {
    int: 0,
    ext: 0,
    all: 0
  }

  if (!quote) {
    return hourTotals
  }

  const lineItems = Object.keys(quote.lineItems ?? {})
  const areas = Object.keys(quote.areas ?? {})
  const groups = Object.keys(quote.groups ?? {})
  const quoteTypeOptions = getQuoteTypeOptions({ options })

  if (lineItems.length > 0) {
    lineItems.forEach((lineItemKey) => {
      const lineItem = quote.lineItems[lineItemKey]
      const quoteType =
        lineItem?.quoteType && quoteTypeOptions.find((option) => option.value === lineItem?.quoteType)
          ? lineItem.quoteType
          : 'all'
      hourTotals = {
        ...hourTotals,
        [quoteType]: hourTotals[quoteType] ? hourTotals[quoteType] + lineItem.hours : lineItem.hours
      }
    })
  }

  if (areas.length > 0) {
    areas.forEach((areaKey) => {
      const area = quote.areas[areaKey]
      const { totals, int_ext } = area
      const { hours } = totals
      const quoteType =
        int_ext?.value && quoteTypeOptions.find((option) => option.value === int_ext?.value) ? int_ext?.value : 'all'
      hourTotals = {
        ...hourTotals,
        [quoteType]: hourTotals[quoteType] ? hourTotals[quoteType] + hours : hours
      }
    })
  }

  if (groups.length > 0) {
    groups.forEach((groupKey) => {
      const group = quote.groups[groupKey]

      group.children.forEach((child) => {
        if (quote?.substrates[child]) {
          const substrate = quote?.substrates[child]

          let quoteType
          if (substrate.int_ext && substrate.int_ext.value) {
            quoteType = substrate.int_ext.value
          }
          quoteType = quoteType && quoteTypeOptions.find((option) => option.value === quoteType) ? quoteType : 'all'

          const hours = substrate?.grouped_totals?.hours ?? 0

          hourTotals = {
            ...hourTotals,
            [quoteType]: hourTotals[quoteType] ? hourTotals[quoteType] + hours : hours
          }
        }
      })
    })
  }

  return hourTotals
}

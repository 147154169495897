import type { AreaSubstrate, QuoteProduct } from 'paintscout'
import type { UpdateableProduct } from '../..'
import get from 'lodash/get'

// Converts an areaSubstrates QuoteProducts to an UpdateableProduct to go with UpdateableAreaSubstrate
// empty array if no products found on areaSubstrate
export default function convertAreaSubstrateProducts(areaSubstrate: AreaSubstrate): UpdateableProduct[] {
  const legacyProduct = legacyAreaSubstrateProduct(areaSubstrate)
  const defaultProducts = defaultAreaSubstrateProducts(areaSubstrate)
  const res = [...(legacyProduct ? [legacyProduct] : []), ...defaultProducts]

  return res
}

function legacyAreaSubstrateProduct(areaSubstrate: AreaSubstrate): UpdateableProduct {
  return areaSubstrate.product && (areaSubstrate.product.custom || get(areaSubstrate, 'product.product.label'))
    ? convertProduct(areaSubstrate.product)
    : null
}

function defaultAreaSubstrateProducts(areaSubstrate: AreaSubstrate): UpdateableProduct[] {
  return areaSubstrate.products ? areaSubstrate.products.map((product) => convertProduct(product)) : []
}

function convertProduct(product: QuoteProduct): UpdateableProduct {
  return {
    key: get(product, 'product.name'),
    label: get(product, 'product.label'),
    calculate: get(product, 'calculate'),
    color: get(product, 'product.color'),
    unitLabel: get(product, 'product.unitLabel'),
    quantity: get(product, 'volume_override') ? get(product, 'volume_override_value') : get(product, 'volume'),
    coverage: {
      default: get(product, 'coverage_rate_value'),
      useCustom: get(product, 'coverage_rate_override'),
      custom: get(product, 'coverage_rate_override_value')
    },
    lnftCoverage: {
      default: get(product, 'lnft_coverage_rate_value'),
      useCustom: get(product, 'lnft_coverage_rate_override'),
      custom: get(product, 'lnft_coverage_rate_override_value')
    },
    quantityCoverage: {
      default: get(product, 'quantity_coverage_rate_value'),
      useCustom: get(product, 'quantity_coverage_rate_override'),
      custom: get(product, 'quantity_coverage_rate_override_value')
    },
    volume: {
      default: get(product, 'volume'),
      useCustom: get(product, 'volume_override'),
      custom: get(product, 'volume_override_value')
    },
    price: {
      default: get(product, 'price'),
      useCustom: get(product, 'price_override'),
      custom: get(product, 'price_override_value')
    },
    totalPrice: {
      default: get(product, 'totalPrice'),
      useCustom: get(product, 'totalPrice_override'),
      custom: get(product, 'totalPrice_override_value')
    },
    quoteTypes: get(product, 'quoteTypes'),
    custom: get(product, 'custom', false)
  }
}

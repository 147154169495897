import React from 'react'
import { Grid, useClientOptions } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'
import { getFeatures, getPayments } from '@paintscout/util/builder'
import { useQuote } from '../context'
import PaymentRequest from '../PaymentRequest'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(6)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    },
    '& > *:last-child': {
      marginBottom: '0 !important'
    }
  }
}))

export default function PaymentRequests() {
  const classes = useStyles()
  const { quote } = useQuote()
  const { options } = useClientOptions()
  const { paymentRequests: paymentRequestsFeature } = getFeatures({ options })
  const paymentRequests = getPayments({ quote })?.filter(
    (payment) => payment.status === 'pending' && payment.amount <= quote.totals.balance_due
  )

  if (!paymentRequestsFeature || !paymentRequests?.length) {
    return null
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {paymentRequests.map((payment) => {
        return <PaymentRequest key={payment.id} paymentRequest={payment} />
      })}
    </Grid>
  )
}

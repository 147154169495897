import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields
  if (!fields) {
    return true
  }

  if (!fields.rows || fields.rows.length === 0) {
    return true
  }

  return fields.rows.reduce((acc, row) => {
    const emptyRow = !row?.image

    return acc && emptyRow
  }, true)

  return false
}

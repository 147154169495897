/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import type { QuoteItemSection } from '../../index'

import { moveItem } from './index'

import { calculateQuote } from '../../util'

export function moveItems(args: {
  quote: QuoteDocument
  options: OptionsDocument
  keys: string[]
  section: QuoteItemSection
  previousSection?: QuoteItemSection
}): QuoteDocument {
  const { quote, options, keys, section, previousSection } = args

  const finalQuote = keys.reduce(
    (updatedQuote: QuoteDocument, key: string) => {
      return moveItem({ quote: updatedQuote, options, key, section, previousSection, calculate: false })
    },
    { ...quote }
  )

  if (section === 'additional') {
    // check if there are remaining additional items
    // if not, we'll blast the "approval" information
  }

  return calculateQuote({ quote: finalQuote, options })
}

import React from 'react'
import type { IconButtonProps as CoreIconButtonProps } from '@ui/core/IconButton'
import { default as CoreIconButton } from '@ui/core/IconButton'

export interface IconButtonProps extends CoreIconButtonProps {}

export function IconButton(props: IconButtonProps) {
  return <CoreIconButton {...props} />
}

export default IconButton

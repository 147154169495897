import { useApolloClient } from '@apollo/react-hooks'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import Done from '@material-ui/icons/Done'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { TRIAL_QUOTE_FILES_LIMIT } from '@paintscout/util'
import { createFile, getFeature, getFiles, getObjectLabels } from '@paintscout/util/builder'
// import { uploadFile } from '@paintscout/util/cloudinary'
import { compressFile, uploadFile } from '@paintscout/util/s3'
import type { DialogProps, WithClientOptions, WithCloudinary, WithDialogStackContext, WithUser } from '@ui/paintscout'
import {
  Button,
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withClientOptions,
  withCloudinary,
  withDialogStackContext,
  withUser
} from '@ui/paintscout'
import type { ApolloClient } from 'apollo-client'
import type { WithSnackbarProps } from 'notistack'
import { withSnackbar } from 'notistack'
import type { QuoteFile } from 'paintscout'
import React from 'react'
import type { WithQuoteContext } from '../../context/withQuoteContext'
import { withQuoteContext } from '../../context/withQuoteContext'
import type { DropzoneError, EnhancedQuoteFile } from './FileView'
import FileView from './FileView'
import ItemFilesStepper from './ItemFilesStepper'
import getErrorInfo from './getErrorInfo'

export interface EditItemFilesProps {
  /**
   * QuoteFiles to include in dialog
   */
  files?: QuoteFile[]

  /**
   * Automatically starts uploading these files when dialog mounts
   */
  uploadFiles?: File[]

  /**
   * QuoteFiles index to open at
   */
  index?: number

  onConfirm?: (files: QuoteFile[], deletedFiles?: QuoteFile[]) => void
  onDelete?: (key: string) => void
  onCancel?: () => void
}

type EditItemFilesPropsWithInjections = EditItemFilesProps &
  WithStyles<typeof styles> &
  WithSnackbarProps &
  WithDialogStackContext &
  WithUser &
  WithQuoteContext &
  WithClientOptions &
  WithCloudinary & {
    client: ApolloClient<any>
  }

export interface EditItemFilesState {
  index: number
  files: EnhancedQuoteFile[]
  isDirty: boolean

  /**
   * The total number of quote files excluding this item (so we can calculate how many files a trial user can add)
   */
  numQuoteFiles?: number
}

export const styles = (_theme: Theme) => {
  return createStyles({
    root: {},
    fullHeight: {
      height: '100%'
    },
    content: {
      padding: 16,
      height: '100%'
    },
    stepper: {
      background: 'none'
    },
    paginationActions: {
      padding: 0,
      height: 'initial'
    },
    paginationActionsRightButton: {
      display: 'block'
    }
  })
}

class EditItemFiles extends React.Component<EditItemFilesPropsWithInjections, EditItemFilesState> {
  static defaultProps: Partial<EditItemFilesPropsWithInjections> = {
    files: [],
    index: 0
  }

  constructor(props: EditItemFilesPropsWithInjections) {
    super(props)

    const { quote } = props.quoteContext

    const { index, files = [] } = props

    this.state = {
      index: index || 0,
      files,
      numQuoteFiles: quote ? getFiles({ quote, attached: true }).length - files.length : 0,
      isDirty: false
    }
  }

  public componentDidMount() {
    if (this.props.uploadFiles) {
      this.uploadFiles({ files: this.props.uploadFiles })
    }
  }

  public render() {
    const { classes, onConfirm: _onConfirm, onCancel, user, quoteContext: _quoteContext, clientOptions } = this.props
    const { index, files, numQuoteFiles, isDirty } = this.state
    const currentFile = files[index]
    const uploading = files.some((file) => file.uploading)

    const canAddFiles = !user?.isTrial || numQuoteFiles + files.length < TRIAL_QUOTE_FILES_LIMIT

    const objectLabels = getObjectLabels({ options: clientOptions.options })

    return (
      <>
        <DialogTitle>{files[index] ? 'Edit Media' : 'Add Media'}</DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <FileView
            isNew={!currentFile}
            file={currentFile}
            disabled={!canAddFiles}
            disabledMsg={`You must upgrade your plan to add more files to this ${objectLabels.quote.value}`}
            onChange={this.handleFileChange}
            onDelete={this.handleFileDelete}
            onUpload={this.handleNewFiles}
            onReplace={(file) => this.handleReplaceFile(file, this.state.index)}
            onDropzoneError={this.handleDropzoneError}
            onRetry={this.handleRetry}
          />
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.paginationActions,
            rightButton: classes.paginationActionsRightButton
          }}
        >
          <ItemFilesStepper
            activeStep={index}
            position="static"
            files={files}
            nextButton={
              <Button
                data-testid="files-next-button"
                variant="text"
                color="primary"
                disabled={index === files.length}
                onClick={() => this.setState({ index: index + 1 })}
              >
                Next
                <KeyboardArrowRightIcon />
              </Button>
            }
            backButton={
              <Button
                data-testid="files-back-button"
                variant="text"
                color="primary"
                disabled={index === 0}
                onClick={() => this.setState({ index: index - 1 })}
              >
                <KeyboardArrowLeftIcon />
                Back
              </Button>
            }
          />
        </DialogActions>
        <DialogActions
          leftButton={
            <Button
              onClick={() => {
                if (isDirty) {
                  this.props.dialogStackContext.openDialog(ConfirmationDialog, {
                    message: 'Newly added images will be lost',
                    onConfirm: () => {
                      this.props.dialogStackContext.dismissDialog()
                      onCancel()
                    },
                    onCancel: () => {
                      this.props.dialogStackContext.dismissDialog()
                    }
                  })
                } else {
                  onCancel()
                }
              }}
              variant={'text'}
            >
              Cancel
            </Button>
          }
        >
          <Button
            type="submit"
            variant={'contained'}
            onClick={this.handleDialogConfirm}
            disabled={files.filter((file) => file.uploading).length > 0}
            icon={Done}
            loading={uploading}
          >
            Confirm
          </Button>
        </DialogActions>
      </>
    )
  }

  public handleFileChange = (file: EnhancedQuoteFile) => {
    const { files } = this.state
    const fileIndex = files.findIndex(({ key }) => key === file.key)

    if (fileIndex !== -1) {
      const [cleanFile] = cleanEnhancedFiles([file])

      this.setState({ files: [...files.slice(0, fileIndex), cleanFile, ...files.slice(fileIndex + 1)] })
    }
  }

  public handleFileDelete = (key: string) => {
    const { onDelete } = this.props
    onDelete(key) // If no files left this can dismissDialog, else we want to account for dialog still being open by updating files
    const { files } = this.state
    const fileIndex = files.findIndex((file) => file.key === key)
    if (fileIndex !== -1) {
      const newFiles = [...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]
      this.setState({ files: newFiles })
    }
  }

  public handleDialogConfirm = (_event: any) => {
    const { onConfirm, clientOptions } = this.props
    const { options, save } = clientOptions

    const filesWithErrors = this.state.files.filter((file) => !!file.error)

    const files = this.state.files
    const deletedFiles = this.props.files.filter(
      (file) => !this.state.files.find((stateFile) => stateFile.key === file.key)
    )
    if (files.length > 0 && !options.options.onboardingTasks?.uploadedMediaToQuote) {
      save({
        options: {
          ...options,
          options: {
            ...options.options,
            onboardingTasks: {
              ...options.options.onboardingTasks,
              uploadedMediaToQuote: true
            }
          }
        }
      })
    }
    if (filesWithErrors.length > 0) {
      this.props.dialogStackContext.openDialog(ConfirmationDialog, {
        message: 'Some Media items have errors. Are you sure you want to save?',
        onConfirm: () => {
          if (onConfirm) {
            const cleanFiles = cleanEnhancedFiles(files)
            onConfirm(cleanFiles, deletedFiles)
          }
          this.props.dialogStackContext.dismissDialog()
        },
        onCancel: this.props.dialogStackContext.dismissDialog
      })
    } else {
      if (onConfirm) {
        onConfirm(files, deletedFiles)
      }
    }
  }

  public handleNewFiles = async (files: File[]) => {
    if (this.props.user?.isTrial) {
      const filesLeft = TRIAL_QUOTE_FILES_LIMIT - (this.state.numQuoteFiles + this.state.files.length)

      this.uploadFiles({
        files: files.slice(0, filesLeft)
      })
    } else {
      this.uploadFiles({ files })
    }
  }

  public handleReplaceFile = async (file: File, index: number) => {
    const quoteFile = this.state.files[index]
    this.uploadFiles({ files: [file], quoteFiles: [quoteFile] })
  }

  public uploadFile = async (file: File) => {
    let fileToUpload = file
    const { clientOptions } = this.props
    const { options } = clientOptions
    const s3UploadFeatureEnabled = getFeature({ options, path: 's3ImageUpload.enabled' })

    // compress if image
    if (file.type.startsWith('image') && !file.type.includes('svg')) {
      fileToUpload = await compressFile({
        file
      })

      console.log(`Resize image: ${file.size}->${fileToUpload.size}`)
    }

    const data = await uploadFile({
      file: fileToUpload,
      apolloClient: this.props.client,
      uploadOptions: {
        companyId: this.props.user?.user?.app_metadata?.companyId,
        docId: this.props.quoteContext?.quote?._id,
        s3UploadFeatureEnabled
      }
    })

    return data
  }

  /**
   * Retries all retryable failed files
   */
  public handleRetry = async () => {
    const failedQuoteFiles = this.state.files.filter((file) => !!file.failedFile && getErrorInfo(file.error).retryable)

    await this.uploadFiles({
      quoteFiles: failedQuoteFiles,
      files: failedQuoteFiles.map((quoteFile) => quoteFile.failedFile)
    })
  }

  /**
   * files are the raw files from the upload
   *
   * if quoteFiles is provided, it'll update the quoteFiles with the uploaded file (in order of array, 1 file to quoteFile)
   *
   * if it's not provided, it'll create a new quoteFile with the uploaded file and add it to the state
   */
  public uploadFiles = async (args: { files: File[]; quoteFiles?: QuoteFile[] }) => {
    // if the upload fails we'll want to set the src back to what it was before
    const originalSourceValues = args.quoteFiles
      ? args.quoteFiles.reduce(
          (srcs, quoteFile) => ({
            ...srcs,
            [quoteFile.key]: { src: quoteFile.src, cloudinaryPublicId: quoteFile.cloudinaryPublicId }
          }),
          {}
        )
      : {}

    // create new quote files or update existing ones
    const editingQuoteFiles = args.files.map((file, i) => {
      const quoteFile = args.quoteFiles && args.quoteFiles[i] ? args.quoteFiles[i] : createFile({ type: file.type })

      return {
        ...quoteFile,
        type: file.type,
        uploading: true,
        src: null,
        cloudinaryPublicId: null,
        error: null,
        failedFile: null
      }
    })

    // determine which ones are new
    const newQuoteFiles = editingQuoteFiles.filter(({ key }) => !this.state.files.find((f) => f.key === key))

    // update the files in state
    this.setState({
      files: [
        ...this.state.files.map((quoteFile) => {
          const edited = editingQuoteFiles.find((af) => af.key === quoteFile.key)

          return edited ? edited : quoteFile
        }),
        ...newQuoteFiles
      ],
      isDirty: true
    })

    const funcs = editingQuoteFiles.map((quoteFile, index) => async () => {
      try {
        const uploadRes = await this.uploadFile(args.files[index])

        // update src after successful upload
        this.setState((prevState) => ({
          files: prevState.files.map((prevQuoteFile) => {
            if (prevQuoteFile.key === quoteFile.key) {
              return {
                ...prevQuoteFile,
                height: uploadRes.height,
                width: uploadRes.width,
                uploading: false,
                src: uploadRes.src,
                cloudinaryPublicId: uploadRes?.cloudinaryPublicId ?? '',
                s3PublicKey: uploadRes?.s3PublicKey ?? ''
              }
            }

            return prevQuoteFile
          })
        }))
        return {
          success: true,
          key: quoteFile.key
        }
      } catch (error) {
        this.setState((prevState) => ({
          files: prevState.files.map((prevQuoteFile) => {
            if (prevQuoteFile.key === quoteFile.key) {
              const originalSource = originalSourceValues[prevQuoteFile.key] || {
                src: null,
                cloudinaryPublicId: null,
                s3PublicKey: null
              }

              return {
                ...prevQuoteFile,
                ...originalSource,
                failedFile: args.files[index]
              }
            }

            return prevQuoteFile
          })
        }))

        console.log({ error })
        return {
          error: error,
          key: quoteFile.key
        }
      }
    })

    const results = await limitConcurrency(funcs, 10)
    const errors = results.filter((res) => !!res?.error)

    if (errors.length > 0) {
      if (errors.length === 1) {
        this.props.enqueueSnackbar('Unable to upload file', { variant: 'error' })
      } else {
        this.props.enqueueSnackbar(`Unable to upload ${errors.length} files`, { variant: 'error' })
      }
    }

    // update files uploading status and include any errors
    this.setState((prevState) => {
      const errorsByKey = errors.reduce(
        (total, res) => {
          if (res.error) {
            // api error response
            if (res.error.response && res.error.response.data) {
              return {
                ...total,
                [res.key]: res.error.response.data.name
              }
            }
            // axios error
            else if (res.error.message) {
              return {
                ...total,
                [res.key]: res.error.message
              }
            }
          }

          return total
        },
        {} as Record<string, string>
      )

      return {
        files: prevState.files.map((file) => {
          const error = errorsByKey[file.key] || null

          return {
            ...file,
            uploading: false,
            error: error ? error : file.error
          }
        })
      }
    })
  }

  public handleDropzoneError = (errors: DropzoneError[]) => {
    const errorsWithoutKey = errors.filter((file) => !file.key)
    const errorsWithKey = errors.filter((file) => !!file.key)

    this.setState({
      files: [
        ...this.state.files.map((file) => {
          const errorFile = errorsWithKey.find((f) => f.key === file.key)

          if (errorFile) {
            return {
              ...file,
              error: errorFile.error
            }
          }

          return file
        }),
        ...errorsWithoutKey.map((error) => ({
          ...createFile({ type: error.file.type }),
          error: error.error
        }))
      ]
    })
  }
}

const EditItemFilesWithHOCs = withStyles(styles)(
  withSnackbar(withDialogStackContext(withUser(withQuoteContext(withClientOptions(withCloudinary(EditItemFiles))))))
)

function EditItemFilesDialog(props: DialogProps & EditItemFilesProps) {
  const { files, index, uploadFiles, onConfirm, onDelete, onCancel, ...baseDialogProps } = props

  // easiest way to get apolloClient into our class component
  // but we probably should refactor EditItemFiles into a functional component
  // if we do any more work on it
  const apolloClient = useApolloClient()

  return (
    <Dialog {...baseDialogProps} maxWidth="md" fullWidth={true}>
      <EditItemFilesWithHOCs
        client={apolloClient}
        files={files}
        uploadFiles={uploadFiles}
        index={index}
        onConfirm={onConfirm}
        onDelete={onDelete}
        onCancel={onCancel}
      />
    </Dialog>
  )
}

export default EditItemFilesDialog

// removes extra values from EnhancedQuoteFile
function cleanEnhancedFiles(files: EnhancedQuoteFile[]): QuoteFile[] {
  return files.map(({ uploading: _uploading, error: _error, failedFile: _failedFile, ...cleanFile }) => {
    return cleanFile
  })
}

async function limitConcurrency(funcs, limit) {
  let active = 0
  const results = []
  const funcsCopy = [...funcs] // Working copy

  const runner = async () => {
    while (funcsCopy.length > 0 && active < limit) {
      const func = funcsCopy.shift() // Get next funct
      active++
      func().then((result) => {
        results.push(result)
        active--
        runner() // Start a new promise if there are any left
      })
    }
  }

  const initialRunners = Array.from({ length: Math.min(limit, funcsCopy.length) }, runner)
  await Promise.all(initialRunners)

  // Wait for all promises to finish
  while (active > 0) {
    await new Promise((resolve) => setTimeout(resolve, 100))
  }

  return results
}

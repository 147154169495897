import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'

import { Divider } from '@material-ui/core'

const useStyles = makeStyles<Theme, FormSectionProps>((theme) => ({
  root: () => ({
    padding: `${theme.spacing(2)}px 0`
  }),
  divider: {}
}))

type DivProps = JSX.IntrinsicElements['div']
export interface FormSectionProps extends DivProps {
  classes?: StyleClasses<typeof useStyles>
  hideDivider?: boolean
}

/**
 * Renders a div with a Divider above it
 */
const FormSection = (props: FormSectionProps) => {
  const classes = useStyles(props)
  const { hideDivider, ...otherProps } = props
  return (
    <React.Fragment>
      {!hideDivider && <Divider classes={{ root: classes.divider }} />}
      <div className={classes.root} {...otherProps}>
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default FormSection

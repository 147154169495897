import Menu from '@material-ui/core/Menu'

import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button, MenuItem, useClientOptions, useUser } from '@ui/paintscout'
import { useQuote } from '../../../context/useQuote'
import React, { useState, useEffect } from 'react'
import QuoteHeaderCard from '../QuoteHeaderCard'
import { formatPhoneNumber, isUserAssigned, isUserOwner } from '@paintscout/util/builder'
import classnames from 'classnames'

import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import MapIcon from '@material-ui/icons/Map'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import PeopleIcon from '@material-ui/icons/People'
import { useContactQuery } from '@paintscout/api'

const addressFormat = require('address-format')

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing()
    },
    contents: {},
    placeholderContent: {
      paddingLeft: 0
    },
    hoverMenu: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    button: {
      flexGrow: 1,
      marginLeft: 5,
      marginRight: 5
    },
    cardLink: {
      textDecoration: 'none !important',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    },
    menuItemDivider: {
      padding: 0,
      height: 0,
      minHeight: 0,
      paddingBottom: theme.spacing(),
      marginBottom: theme.spacing(),
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    quoteHeaderAddress: {
      whiteSpace: 'pre-wrap'
    }
  })
)

export interface ContactCardProps {}

export function ContactCard(props: ContactCardProps) {
  const classes = useStyles(props)
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    onSelectContact,
    onCreateContact,
    onEditContact,
    onCreateSecondaryContact,
    onSelectSecondaryContact,
    isEditable,
    quote
  } = useQuote()
  const { options } = useClientOptions()
  const { preferences, user, hasPermissions } = useUser()
  const [deletedContact, setDeletedContact] = useState(false)

  const { contact } = quote
  const res = useContactQuery({
    skip: !user || !contact?.id || !contact?.rev || contact.email === 'bob.johnson@example.com',
    variables: {
      id: contact?.id
    }
  })
  const { data: contactData } = res

  useEffect(() => {
    if ((res.error?.message ?? '').includes('Document not found')) {
      setDeletedContact(true)
    }
  }, [contactData])

  const isAssigned = user ? (contact ? isUserAssigned(contact, user.user_id) : true) : false
  const isOwner = user ? (contact ? isUserOwner(contact, user.user_id) : true) : false
  const canEdit = user
    ? isOwner || (isAssigned && hasPermissions(['write:assigned-contacts'])) || hasPermissions(['write:all-contacts'])
    : false

  const phoneNumber = formatPhoneNumber({ options, phoneNumber: contact?.phone_number, extension: contact?.phone_ext })
  const alternatePhoneNumber = formatPhoneNumber({
    options,
    phoneNumber: contact?.alternate_phone,
    extension: contact?.alternate_phone_ext
  })
  const contactInfo = getContactInfo({ phoneNumber, alternatePhoneNumber })

  const showPlaceholder = !contact && isEditable

  const handleAddSecondaryContact =
    preferences?.addContact === 'addFirst' ? handleCreateSecondaryContact : handleSelectSecondaryContact

  const handleClick = contact
    ? (event) => {
        setAnchorEl(event.currentTarget)
      }
    : preferences?.addContact === 'addFirst'
      ? handleCreateContact
      : handleSelectContact

  const placeholderContent = (
    <div className={classes.hoverMenu}>
      <Button classes={{ root: classes.button }} variant="outlined" fullWidth={true} onClick={handleClick}>
        Add Contact
      </Button>
    </div>
  )

  return (
    <>
      <QuoteHeaderCard
        classes={{
          root: classes.root,
          contents: classes.contents,
          placeholderContent: classes.placeholderContent
        }}
        onClick={handleClick}
        title="Contact"
        subtitle={<div>{contactInfo.name}</div>}
        showPlaceholder={showPlaceholder}
        clickable={isEditable}
        placeholderContent={placeholderContent}
        aria-owns={anchorEl ? 'contact-menu' : undefined}
        aria-haspopup="true"
        data-testid="quote-contact-card"
      >
        <div>{contactInfo.details}</div>
      </QuoteHeaderCard>
      <Menu id="contact-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {canEdit && !deletedContact && (
          <MenuItem icon={EditIcon} onClick={handleEditContact}>
            Edit Contact
          </MenuItem>
        )}
        <MenuItem icon={PeopleIcon} onClick={handleSelectContact}>
          Select Existing Contact
        </MenuItem>
        {(user ? hasPermissions(['create:contacts']) : false) && (
          <MenuItem icon={AddIcon} onClick={handleCreateContact}>
            Create & Use New Contact
          </MenuItem>
        )}
        {!quote?.secondaryContact && options.options.features.quotes?.secondaryContact?.enabled && (
          <div>
            <MenuItem disabled classes={{ root: classes.menuItemDivider }} />
            <MenuItem icon={PeopleIcon} onClick={handleAddSecondaryContact}>
              Add Secondary Contact
            </MenuItem>
          </div>
        )}
        {(!!phoneNumber || !!contact?.email || !!contact?.address) && (
          <MenuItem disabled classes={{ root: classes.menuItemDivider }} />
        )}
        {phoneNumber && (
          <MenuItem onClick={handleOpenCall} icon={PhoneIcon}>
            {phoneNumber}
          </MenuItem>
        )}
        {contact?.email && (
          <MenuItem onClick={handleOpenEmail} icon={EmailIcon}>
            {contact.email}
          </MenuItem>
        )}
        {contact?.address && (
          <MenuItem onClick={handleOpenMap} icon={MapIcon}>
            Open in Google Maps
          </MenuItem>
        )}
      </Menu>
    </>
  )

  function getContactInfo({ phoneNumber, alternatePhoneNumber }) {
    const { contact } = quote

    const details = []
    let name = null

    if (contact) {
      const {
        first_name,
        last_name,
        secondary_first_name,
        secondary_last_name,
        email,
        alternate_email,

        address,
        city,
        province,
        postal,
        company
      } = contact

      if (company) {
        details.push(
          <div key="company" className="quote-header-company">
            {company}
          </div>
        )
      }

      const fullAddress = addressFormat({ address, city, subdivision: province, postalCode: postal, countryCode: 'US' })
      if (fullAddress && fullAddress.length > 0) {
        details.push(
          <div key="address" className={classnames('quote-header-address', classes.quoteHeaderAddress)}>
            {fullAddress.join('\n')}
          </div>
        )
      }

      if (email) {
        details.push(
          <div key="email" className="quote-header-email">
            {email}
          </div>
        )
      }

      if (alternate_email) {
        details.push(
          <div key="alt-email" className="quote-header-alternate-email">
            {alternate_email}
          </div>
        )
      }

      if (phoneNumber) {
        details.push(
          <div key="phone" className="quote-header-phone-number">
            {phoneNumber}
          </div>
        )
      }

      if (alternatePhoneNumber) {
        details.push(
          <div key="alt-phone" className="quote-header-alternate-phone">
            {alternatePhoneNumber}
          </div>
        )
      }

      name = (
        <React.Fragment key="name">
          <div>
            {first_name && <span className="quote-header-first-name">{first_name}</span>}
            {first_name && last_name && <span> </span>}
            {last_name && <span className="quote-header-last-name">{last_name}</span>}
          </div>
          <div>
            {secondary_first_name && <span className="quote-header-first-name">{secondary_first_name}</span>}
            {secondary_first_name && secondary_last_name && <span> </span>}
            {secondary_last_name && <span className="quote-header-last-name">{secondary_last_name}</span>}
          </div>
        </React.Fragment>
      )
    }

    return { name, details }
  }

  function handleOpenCall() {
    document.location.href = `tel:${contact.phone_number}`
  }
  function handleOpenEmail() {
    window.open(`mailto:${contact.email}`, '_blank')
  }
  function handleOpenMap() {
    window.open(`http://maps.google.com/?q=${contact.address}`, '_blank')
  }

  function handleCreateSecondaryContact() {
    onCreateSecondaryContact()
    setAnchorEl(null)
  }

  function handleSelectSecondaryContact() {
    onSelectSecondaryContact()
    setAnchorEl(null)
  }

  function handleSelectContact() {
    onSelectContact()
    setAnchorEl(null)
  }

  function handleCreateContact() {
    onCreateContact()
    setAnchorEl(null)
  }

  function handleEditContact() {
    onEditContact()
    setAnchorEl(null)
  }

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setAnchorEl(null)
  }
}

export default ContactCard

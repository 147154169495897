import React from 'react'
// import { compose as reduxCompose } from 'redux'
import classnames from 'classnames'

export function cloneElementWithClassName(child: React.ReactElement<any>, className: string) {
  return React.cloneElement(child, {
    className: classnames(child.props.className, className)
  })
}

export function cloneChildrenWithClassName(children: React.ReactNode, className: string) {
  return React.Children.map(children, (child) => {
    return React.isValidElement(child) && cloneElementWithClassName(child, className)
  })
}

/**
 * Finds the closest scrollable parent
 */
export function getScrollParent(node: any): HTMLElement {
  if (!node) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}

import React from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '../Typography'
import Card from '@material-ui/core/Card'
import moment from 'moment-timezone'
import { ActivityFooter as CoreActivityFooter, useIsMobile } from '@ui/core'
import type { ActivityFooterProps as CoreActivityFooterProps } from '@ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'
import type { MessageActivity } from '@paintscout/api'
import { useUser } from '../UserProvider'

export interface ActivityFooterProps extends Omit<CoreActivityFooterProps, 'time'> {
  activity?: MessageActivity
  timestamp: number
}

const useStyles = makeStyles((theme) => ({
  root: {},
  extendedTimeDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  checkCircle: {
    fontSize: '1rem !important',
    marginLeft: theme.spacing(0.5)
  },
  popover: {
    padding: '0px 2px 0px 2px',
    position: 'absolute',
    left: '25px',
    top: '-20px',
    border: '1px solid #bbb',
    width: '65px',
    backgroundColor: '#fff'
  },
  checkHolder: {
    position: 'relative'
  },
  sentTime: {
    [theme.breakpoints.down('xs')]: {
      fontWeight: 600
    }
  }
}))

export default function ActivityFooter({ activity, timestamp, ...props }: ActivityFooterProps) {
  const [isHovered, setIsHovered] = React.useState<boolean>(false)
  const classes = useStyles(props)
  const { user } = useUser()
  const sentTime = moment(timestamp).format('hh:mm A')
  const hasBeenRead = activity?.details?.message.readAt ?? false
  const readTime = hasBeenRead ? moment(hasBeenRead).format('MMM D hh:mm A') : 'Not read'
  const mobile = useIsMobile()

  const ExtendedTime = () => {
    return (
      <div
        onMouseLeave={() => {
          setIsHovered(false)
        }}
        className={classes.extendedTimeDiv}
      >
        <Typography className={classes.sentTime} variant={mobile ? 'body1' : 'overline'}>
          {sentTime}
        </Typography>
        {hasBeenRead && user && (
          <div className={classes.checkHolder}>
            <CheckCircle
              onMouseEnter={() => {
                setIsHovered(true)
              }}
              onMouseLeave={() => {
                setIsHovered(false)
              }}
              className={classes.checkCircle}
            />
            <Card className={classes.popover} hidden={!isHovered}>
              <Typography variant="body2">Read:</Typography>
              <Typography variant="body2">{readTime}</Typography>
            </Card>
          </div>
        )}
      </div>
    )
  }

  return <CoreActivityFooter {...props} classes={classes} time={<ExtendedTime />} />
}

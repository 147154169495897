import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'
import { parseNumber } from '../../calculator'

const getDepositAmount = ({
  quote,
  options,
  wisetack
}: {
  quote: QuoteDocument
  options: OptionsDocument
  wisetack?: boolean
}): number => {
  const quoteOptions = getQuoteOptions({ quote, options })

  const depositAmount = wisetack ? quoteOptions.wisetackDepositAmount : quoteOptions.depositAmount
  const depositAmountBeforeTax = quoteOptions.depositAmountBeforeTax

  if (depositAmount.type === 'amount') {
    return parseNumber(depositAmount.value)
  }

  const total = depositAmountBeforeTax ? quote.totals.after_tax - quote.totals.total_tax : quote.totals.after_tax
  return parseNumber((depositAmount.value / 100) * total)
}

export default getDepositAmount

import * as React from 'react'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import type { ClientOptionsValue } from './ClientOptionsContext'
import ClientOptions from './ClientOptionsContext'

export interface ClientOptionsProviderProps extends Pick<ClientOptionsValue, 'options' | 'rates'> {
  onSave?: (args: {
    options: OptionsDocument
    rates?: RatesDocument
  }) => Promise<{ options: OptionsDocument; rates?: RatesDocument }>
  clientId?: string
  isAdmin?: boolean

  onPartialOptionsSave?: (args: { input: string; key: string }) => Promise<{ output: string }>

  onSync?: (args: {
    optionsRev: string
    ratesRev: string
  }) => Promise<{ options?: OptionsDocument; rates?: RatesDocument }>

  children: React.ReactNode | ((context: ClientOptionsValue) => React.ReactNode)
}

// state will be used for modification of the ClientOptions by components - but right now that's not supported
// so the state is just passing along the ClientOptions prop for now
export interface ClientOptionsProviderState {
  value: ClientOptionsValue
}

class ClientOptionsProvider extends React.Component<ClientOptionsProviderProps, ClientOptionsProviderState> {
  public static getDerivedStateFromProps(props: ClientOptionsProviderProps, state: ClientOptionsProviderState) {
    const shouldUpdateOptions = !state.value.isDirty
    const shouldUpdateRates = !state.value.isDirty

    return {
      value: {
        ...state.value,
        clientId: props.clientId ?? '',
        isAdmin: props.isAdmin ?? false,
        options: shouldUpdateOptions ? props.options : state.value.options,
        rates: shouldUpdateRates ? props.rates : state.value.rates
      }
    }
  }

  constructor(props: ClientOptionsProviderProps) {
    super(props)
    this.state = {
      value: {
        options: props.options,
        rates: props.rates,
        isDirty: false,
        updateOptions: this.updateOptions,
        updateRates: this.updateRates,
        savePartialOptions: this.savePartialOptions,
        optionsSync: this.optionsSync,
        save: this.save,
        reset: this.reset,
        isAdmin: props?.isAdmin ?? false,
        clientId: props?.clientId ?? ''
      }
    }
  }

  public render() {
    const { children } = this.props
    const { value } = this.state
    const childrenFn = children as (context: ClientOptionsValue) => React.ReactNode

    return (
      <ClientOptions.Provider value={this.state.value}>
        {typeof children === 'function' ? childrenFn(value) : children}
      </ClientOptions.Provider>
    )
  }

  public updateOptions = (args: { options: OptionsDocument; isDirty?: boolean }) => {
    const { options, isDirty = true } = args
    this.setState({ value: { ...this.state.value, options, isDirty } })
  }

  public updateRates = (args: { options: OptionsDocument; rates: RatesDocument; isDirty?: boolean }) => {
    const { rates, options, isDirty = true } = args
    this.setState({ value: { ...this.state.value, options, rates, isDirty } })
  }

  public save = async (args: { options?: OptionsDocument; rates?: RatesDocument } = {}) => {
    const { options = this.state.value.options, rates = this.state.value.rates } = args
    const res = await this.props?.onSave({
      options,
      rates: this.props.rates !== rates ? rates : null
    })

    this.setState({ value: { ...this.state.value, isDirty: false, options: res.options, rates: res?.rates ?? rates } })

    return res
  }

  public optionsSync = async (args: { optionsRev: string; ratesRev: string }) => {
    const { optionsRev, ratesRev } = args
    const res = await this.props?.onSync({ optionsRev, ratesRev })

    const updatedOptions = res?.options ?? this.state.value.options
    const updatedRates = res?.rates ?? this.state.value.rates
    this.setState({ value: { ...this.state.value, isDirty: false, options: updatedOptions, rates: updatedRates } })

    return res
  }

  public savePartialOptions = async (args: { input: string; key: string }) => {
    const { input, key } = args
    const res = await this.props?.onPartialOptionsSave({
      input,
      key
    })
    this.setState({ value: { ...this.state.value, isDirty: false } })

    return res
  }

  public reset = () => {
    this.setState({
      value: { ...this.state.value, isDirty: false, options: this.props.options, rates: this.props.rates }
    })
  }
}

export default ClientOptionsProvider

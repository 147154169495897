import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { Grid, Typography } from '@ui/paintscout'
import SherwinWilliamsLogo from '../SherwinWilliamsLogo'
import SWLink from '../SWLink'

const useStyles = makeStyles<Theme, PageHeaderProps>(
  (theme) => ({
    root: {
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      height: '356px',
      [theme.breakpoints.down('xs')]: {
        height: '320px'
      }
    },
    backgroundImg: {
      width: '100%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 0,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    heroHeader: {
      color: 'white',
      padding: '30px 40px 10px 40px',
      background: '#324658',
      zIndex: 1,
      width: '466px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: '4.5%',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0px',
        width: '100%',
        padding: '10px'
      }
    },
    heroTitle: {
      fontFamily: 'CustomFont-SW',
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '-1.5px',
      color: '#fff',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    presentedBy: {
      fontFamily: 'CustomFont-SW',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    logoAndCta: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    cta: {
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2)
      }
    }
  }),
  { name: 'PageHeader' }
)

export interface PageHeaderProps {
  title?: React.ReactNode
  ctaLink?: string
  ctaLabel?: string
  backgroundImage?: string
  headerBackgroundColor?: string
}

function PageHeader(props: PageHeaderProps) {
  const classes = useStyles(props)

  const { title, ctaLink, ctaLabel, backgroundImage, headerBackgroundColor } = props

  return (
    <div className={classes.root}>
      <Grid className={classes.heroHeader} style={{ background: headerBackgroundColor }} container md spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.heroTitle}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.presentedBy}>Presented By</Typography>
        </Grid>
        <Grid item container className={classes.logoAndCta}>
          <Grid item sm={5}>
            <SherwinWilliamsLogo />
          </Grid>
          <Grid item sm={7} className={classes.cta}>
            <SWLink label={ctaLabel} href={ctaLink} theme="light" />
          </Grid>
        </Grid>
      </Grid>
      <img src={backgroundImage} className={classes.backgroundImg} />
    </div>
  )
}

export default PageHeader

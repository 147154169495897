import React from 'react'
import type { HiddenProps as CoreHiddenProps } from '@ui/core/Hidden'
import { default as CoreHidden } from '@ui/core/Hidden'

export interface HiddenProps extends CoreHiddenProps {}

const Hidden = function Hidden(props: HiddenProps) {
  return <CoreHidden {...props} />
}

export default Hidden

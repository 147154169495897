import { useEffect, useRef } from 'react'

/**
 * useEffect but the callback arguments contain the previous values of the dependencies
 *
 * usage:
 *
 * ```jsx
 * useEffectPrevious((prevNumber, prevString) => {
 *   ...
 * }, [someNumber, someString] as const)
 * ```
 */
export function useEffectPrevious<T extends readonly any[]>(
  callback: (...args: readonly [...T]) => any,
  dependencies: T
) {
  const prev = useRef(dependencies)

  useEffect(() => {
    const returnValue = callback(...prev.current)

    // update prev values
    dependencies.forEach((dep, i) => {
      if (prev.current[i] !== dep) {
        // @ts-ignore
        prev.current[i] = dep
      }
    })

    return returnValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

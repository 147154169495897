import type { LineItem, OptionsDocument, OrderItem, QuoteArea, QuoteDocument, QuoteOptions } from 'paintscout'

import get from 'lodash/get'

import { parseNumber } from '../../../../calculator'
import { getObjectLabels } from '../../../util/get-object-labels'
import type { QuoteItemSection } from '../../index'
import { getQuoteOptions } from '../../util/get-options/get-quote-options'
import { filterItem } from '../filter-item'
import { roundPrice } from '../round-price'

export interface SubstrateProducts {
  [key: string]: { quantity: number; totalPrice: number; unitLabel: string; unitPrice: number }
}

export function generateProductDescription(args?: {
  quote: QuoteDocument
  options: OptionsDocument
  section?: QuoteItemSection
}): string {
  const { quote, options } = args
  const section = args.section ? args.section : 'bid'

  const quoteOptions = getQuoteOptions({ quote, options })
  const { hideWorkOrderVolumes, roundProducts, hideWorkOrderMaterialCost } = quoteOptions ?? {}

  const { currency } = getObjectLabels({ options })

  const order = quote.order?.area ?? []
  let substrateProducts: SubstrateProducts = {}
  order.forEach((orderItem: OrderItem) => {
    if (orderItem._deleted) {
      return null
    }

    if (orderItem.type === 'area') {
      const area = quote.areas[orderItem.key]

      if (quote.version === 2 && !filterItem(area, section)) {
        // Only filter v2 areas, v3 filters via substrate section
        return null
      } else if (quote.version > 2 && filterItem(area, 'archived')) {
        // This shouldn't happen with v3+ quotes, as only the substrates
        // are moved around. But if we do find an area that's archived, it
        // should probably be tossed entirely here.
        // Also solveable by moving the area from/to archived.
        return null
      }

      if (area) {
        substrateProducts = getAreaProducts(area, section, substrateProducts, quoteOptions)
      }
    } else if (orderItem.type === 'line_item') {
      const lineItem = quote.lineItems[orderItem.key]

      if (
        section &&
        !filterItem(lineItem, section) &&
        // If we're in the bid section, and the item is an added option, we want to include it
        !(section === 'bid' && filterItem(lineItem, 'added-options'))
      ) {
        return
      }

      substrateProducts = getLineItemProducts(lineItem, section, substrateProducts, quoteOptions)
    }
  })

  return Object.keys(substrateProducts)
    .map((item) => {
      const { quantity, totalPrice, unitLabel, unitPrice } = substrateProducts[item]

      let cleanQuantity: string | number = ''
      let cleanPrice = ''

      if (quantity && !hideWorkOrderVolumes) {
        if (roundProducts === 'up') {
          cleanQuantity = Math.ceil(quantity)
          const adjustedPrice = cleanQuantity * unitPrice
          cleanPrice =
            !hideWorkOrderMaterialCost && totalPrice ? ` - ${currency.symbol}${roundPrice(adjustedPrice)}` : ''
        } else if (roundProducts === 'round') {
          cleanQuantity = Math.round(quantity)
          const adjustedPrice = cleanQuantity * unitPrice
          cleanPrice =
            !hideWorkOrderMaterialCost && totalPrice ? ` - ${currency.symbol}${roundPrice(adjustedPrice)}` : ''
        } else {
          cleanQuantity = roundPrice(quantity)
          cleanPrice = !hideWorkOrderMaterialCost && totalPrice ? ` - ${currency.symbol}${roundPrice(totalPrice)}` : ''
        }
        return `<p>${item} (Estimated: ${cleanQuantity} ${unitLabel}${cleanPrice})</p>`
      }
      return item
    })
    .filter((item) => item)
    .join('')
}

export function getAreaProducts(
  area: QuoteArea,
  section: QuoteItemSection,
  substrateProducts: SubstrateProducts,
  quoteOptions?: QuoteOptions
) {
  Object.keys(area?.substrates ?? {}).forEach((substrateSection) => {
    const substrates = area.substrates[substrateSection]
    substrates.forEach((substrate) => {
      if (
        section &&
        !filterItem(substrate, section) &&
        // If we're in the bid section, and the item is an added option, we want to include it
        !(section === 'bid' && filterItem(substrate, 'added-options'))
      ) {
        return
      }

      const { product, products } = substrate
      const areaSubstrateProducts = [...(product ? [product] : []), ...(products ?? [])]
      for (const product of areaSubstrateProducts) {
        const label = [get(product, 'product.label', ''), get(product, 'product.color', '')]
          .filter((item) => item)
          .join(' - ')

        const quantity = product.volume_override ? product.volume_override_value : product.volume
        const totalPrice = product.totalPrice_override ? product.totalPrice_override_value : product.totalPrice
        const unitLabel = product.product?.unitLabel ?? 'gal'
        const unitPrice = product.price_override ? product.price_override_value : product.price

        if (substrateProducts[label]) {
          substrateProducts[label].quantity += roundQuantity(quantity, quoteOptions)
          substrateProducts[label].totalPrice += totalPrice ? totalPrice : 0
        } else {
          substrateProducts[label] = {
            quantity,
            totalPrice: totalPrice ? totalPrice : 0,
            unitLabel,
            unitPrice
          }
        }
      }
    })
  })

  return substrateProducts
}

export function getLineItemProducts(
  lineItem: LineItem,
  section: QuoteItemSection,
  substrateProducts: SubstrateProducts,
  quoteOptions?: QuoteOptions
) {
  ;(lineItem.materials ?? []).forEach((material) => {
    const label = [material?.label, material?.color].filter((item) => item).join(' - ')
    if (
      section &&
      !filterItem(lineItem, section) &&
      // If we're in the bid section, and the item is an added option, we want to include it
      !(section === 'bid' && filterItem(lineItem, 'added-options'))
    ) {
      return
    }
    const quantity = material?.quantity
    const totalPrice = material?.totalPrice?.useCustom ? material.totalPrice.custom : material.totalPrice.default ?? 0
    const unitLabel = material?.unitLabel ?? 'gal'
    const unitPrice = material?.price?.useCustom ? material.price.custom : material.price.default ?? 0

    if (substrateProducts[label]) {
      substrateProducts[label].quantity += roundQuantity(parseNumber(quantity), quoteOptions)
      substrateProducts[label].totalPrice += totalPrice ? totalPrice : 0
    } else {
      substrateProducts[label] = {
        quantity: parseNumber(quantity),
        totalPrice: totalPrice ? totalPrice : 0,
        unitLabel,
        unitPrice
      }
    }
  })

  return substrateProducts
}

function roundQuantity(quantity: number, quoteOptions: QuoteOptions): number {
  const roundProducts = quoteOptions?.roundProducts || false
  const individualProductRounding = quoteOptions?.individualProductRounding || false

  if (individualProductRounding) {
    if (roundProducts === 'up') {
      return Math.ceil(quantity)
    } else if (roundProducts === 'round') {
      return Math.round(quantity)
    }
  }

  return quantity
}

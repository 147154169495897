const omitTypename = (key: any, value: any) => (key === '__typename' ? undefined : value)

/**
 * Removes any __typename keys that may exist if the input is reusing the query data
 *
 * workaround for https://github.com/apollographql/apollo-feature-requests/issues/6
 */
export function removeTypenames<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj), omitTypename) as T
}

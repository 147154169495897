import type { OptionsDocument, UserPreferences } from 'paintscout'
import { getFeatures } from './get-features'
import get from 'lodash/get'

export function getFeature(args: { options: OptionsDocument; preferences?: UserPreferences; path: string }): any {
  const { options, preferences, path } = args

  const features = getFeatures({ options, preferences })

  return get(features, path, false)
}

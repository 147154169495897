/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'
import { getQuoteOptions } from '../../util'

// Toggles correct expiry for quote or invoice
export function toggleExpiry(args: { quote: QuoteDocument; enabled: boolean }): QuoteDocument {
  const { quote, enabled } = args
  const optionsField = quote?.is_invoice ? 'customerInvoiceExpiry' : 'customerViewExpiry'
  const quoteOptions = getQuoteOptions({ quote })
  return {
    ...quote,
    options: {
      ...quoteOptions,
      [optionsField]: {
        ...quoteOptions[optionsField],
        enabled
      }
    }
  }
}

import React from 'react'

import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { HtmlContent } from '@ui/paintscout'
import { Grid, Typography } from '@ui/paintscout'

const useStyles = makeStyles({
  root: {},
  content: {}
})

export default function Text(props: PresentationSectionProps) {
  const { section, WrapperComponent } = props

  const classes = useStyles(props)

  const { title, fields } = section

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3}>
        {title && (
          <Grid item xs={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <HtmlContent content={fields.content} classes={{ root: classes.content }} />
        </Grid>
      </Grid>
    </WrapperComponent>
  )
}

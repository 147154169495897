import React from 'react'
import type { ImageProps as CloudinaryImageProps } from 'cloudinary-react'
import { Image as CloudinaryImage } from 'cloudinary-react'
import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import classnames from 'classnames'

const styles = createStyles({
  root: {}
})

export interface ImageProps extends CloudinaryImageProps, WithStyles<typeof styles> {
  loading?: 'lazy' | 'eager'
}

class Image extends React.Component<ImageProps> {
  public render() {
    const { classes, ...otherProps } = this.props

    return (
      <CloudinaryImage
        key={otherProps.publicId}
        data-testid={`cloudinary-image:${otherProps.publicId}`}
        defaultImage="placeholders:image"
        quality="auto"
        flags="progressive:semi"
        {...otherProps}
        width={'auto'}
        className={classnames(classes.root, otherProps.className)}
      />
    )
  }
}

export default withStyles(styles)(Image)

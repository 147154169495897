import type { OptionsDocument } from 'paintscout'
import type { QuoteTypeOption } from '..'

export function getQuoteTypeOption({ options, quoteType }: { options: OptionsDocument; quoteType: string }) {
  const intExtOptions = options?.options?.intExtOptions?.values
  if (!intExtOptions || !intExtOptions[quoteType]) {
    return null
  }

  const item = intExtOptions[quoteType]

  const option: QuoteTypeOption = {
    key: quoteType,
    value: quoteType,
    label: item.label,
    hourlyRate: item.hourlyRate,
    defaultAreaType: item.defaultAreaType,
    defaultTerms: item.defaultTerms,
    defaultPresentation: item.defaultPresentation,
    overrideCompanyOptions: item.overrideCompanyOptions,
    active: typeof item.active === 'undefined' ? true : !!item.active,
    objectLabels: item.objectLabels,
    quoteOptions: item.quoteOptions,
    companyOptions: item.companyOptions
      ? item.companyOptions
      : {
          name: '',
          city: '',
          province: '',
          address: '',
          postal: '',
          phoneNumber: '',
          logo: null
        }
  }

  return option
}

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FieldArray, FastField } from 'formik'
import { Button, Grid, FieldGroup, FormikHtmlEditor, FormikInputField, FormikUploadImage } from '@ui/paintscout'
import { MoveRemoveButtons } from '../../util'

const TestimonialsForm = ({ formik }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Field component={FormikInputField} fullWidth={true} name={'section.title'} label={'Title'} />
      </Grid>

      <Grid item md={12}>
        <FieldGroup label={'Testimonials'}>
          <FieldArray
            name="section.fields.rows"
            render={(arrayHelpers) => (
              <Grid container spacing={3}>
                {(section?.fields?.rows ?? []).map((row, index) => (
                  <Grid key={index} item md={12}>
                    <TestimonialsFormRow section={section} arrayHelpers={arrayHelpers} index={index} />
                  </Grid>
                ))}

                <Grid item md={12}>
                  <Button
                    onClick={() => {
                      arrayHelpers.push({ image: null, title: '', content: '' })
                    }}
                    variant={'outlined'}
                    fullWidth={true}
                  >
                    Add Testimonial
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        </FieldGroup>
      </Grid>
    </Grid>
  )
}

function TestimonialsFormRow({ index, section, arrayHelpers }) {
  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <Field
          component={FormikUploadImage}
          label={'Image'}
          name={`section.fields.rows.${index}.image`}
          maxWidth={360}
          maxHeight={360}
          buttonHeight={100}
          quality={1}
        />
      </Grid>
      <Grid item md={8}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <FastField
              component={FormikHtmlEditor}
              label={'Description'}
              name={`section.fields.rows.${index}.content`}
              fullWidth={true}
              debounce={true}
              toolbar={{
                headings: true,
                align: true
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Field
              component={FormikInputField}
              label={'Credit'}
              name={`section.fields.rows.${index}.credit`}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Grid>
      {section?.fields?.rows.length > 1 && (
        <MoveRemoveButtons arrayHelpers={arrayHelpers} index={index} section={section} />
      )}
    </Grid>
  )
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(TestimonialsForm)

import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'

import { ImagePlaceholder, FilePreview, Grid, HtmlContent, Typography } from '@ui/paintscout'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    title: {},
    content: {
      textAlign: 'center'
    },
    image: {
      '& img': {
        borderRadius: theme.spacing(1)
      }
    }
  }
})

export default function Mission(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { section, showHelp, WrapperComponent } = props

  const { fields, title } = section

  const hasContent = fields?.content && fields.content.replace('<p></p>', '').trim()

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3} direction={'column'} justifyContent={'center'} alignItems={'center'}>
        {title && (
          <Grid item sm={12} md={8}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        {hasContent && (
          <Grid item sm={12} md={8}>
            <HtmlContent content={fields?.content} classes={{ root: classes.content }} />
          </Grid>
        )}
        {(fields?.image || showHelp) && (
          <Grid item sm={12} md={8}>
            {fields.image ? (
              <FilePreview className={classes.image} file={fields?.image} />
            ) : (
              <ImagePlaceholder title={'Add Image'} width={200} height={200} />
            )}
          </Grid>
        )}
      </Grid>
    </WrapperComponent>
  )
}

import type { HTMLProps } from 'react'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles({
  root: {
    width: 252,
    height: 304,
    fill: '#004BB4',
    '&:hover': {}
  },
  wrapper: {}
})

export interface LogoProps extends HTMLProps<HTMLDivElement> {
  scale?: number
  classes?: StyleClasses<typeof useStyles>
}
function Logo({ ...props }: LogoProps) {
  const classes = useStyles(props)

  return (
    <div className={classes.wrapper} {...props}>
      <svg className={classes.root} viewBox="0 0 252 304">
        <g id="outline">
          <path d="M126,0 C195.587878,0 252,56.6417166 252,126.512815 C252,196.383913 188.776842,259.100179 126,304 C65.2768861,260.506573 0,196.383913 0,126.512815 C0,56.6417166 56.4121215,0 126,0 Z M126.906475,18.2032827 C66.8306085,18.2032827 18.1294964,67.1026064 18.1294964,127.422979 C18.1294964,187.743352 66.8306085,236.642676 126.906475,236.642676 C186.982341,236.642676 235.683453,187.743352 235.683453,127.422979 C235.683453,67.1026064 186.982341,18.2032827 126.906475,18.2032827 Z" />
        </g>
        <g id="brush">
          <path d="M162,126 L160.202732,183.981237 L115.738259,193.96706 C115.852952,187.317616 115.852952,181.325691 115.738259,175.991284 C113.619447,187.01584 109.524111,197.685517 91,200 L91.0006008,199.46629 C91.026969,190.514757 91.8645582,170.083787 93.5133683,138.173379 L162,126 Z M128,41 C133.259745,41 139.728457,42.3190831 139.728457,48.6145281 L139.728457,48.6145281 L139.728457,82.2007145 C139.728457,91.160524 145.341045,96.2115511 151.155274,96.2115511 L151.155274,96.2115511 L162,96.2115511 L162,115.597874 L94.1059585,129 C93.9646805,122.078737 93.9646805,116.368546 94.1059585,111.86943 C103.315996,107.832276 117.779003,98.2103571 117.779003,85 L117.779003,85 L117.779003,52.1172373 C117.779003,45.4419172 122.740255,41 128,41 Z" />
        </g>
      </svg>
    </div>
  )
}
export default Logo

/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { QuoteItemSection, UpdateableSubstrate } from '../../index'
import { convertSubstrate } from '../convert-substrate'

export function getSubstrate(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  key: string
  showHiddenTotals?: boolean
  section?: QuoteItemSection
  groupKey?: string
}): UpdateableSubstrate {
  const { quote, options, key, showHiddenTotals, section, groupKey } = args
  const sanitizedKey = key.trim().toLowerCase()
  const fullSubstrate = quote.substrates[sanitizedKey]

  const convertedSubstrate = convertSubstrate({
    key,
    quote,
    options,
    substrate: fullSubstrate,
    showHiddenTotals,
    sectionOverride: section,
    groupKey
  })

  return convertedSubstrate
}

import type { AreaSubstrate, OverridableValue } from 'paintscout'

export default function convertPrice(areaSubstrate: AreaSubstrate): OverridableValue {
  const price = {
    useCustom: !!areaSubstrate.override_price,
    custom: Number(
      areaSubstrate.override_price && typeof areaSubstrate.override_price_value !== 'undefined'
        ? areaSubstrate.override_price_value
        : areaSubstrate?.price ?? 0
    ),
    default: Number(
      typeof areaSubstrate.default_price !== 'undefined' ? areaSubstrate.default_price : areaSubstrate?.price ?? 0
    )
  }

  return price
}

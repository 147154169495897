import type { OptionsDocument, OrderItem, QuoteDocument, QuoteOptions, QuoteSubstrate } from 'paintscout'
import type { QuoteItemConsumer, QuoteItemSection, RenderableItem } from '../../../index'
import { generateSubstrateDescription } from '../../util/generate-substrate-description/generate-substrate-description'
import type { RenderableSubItem } from '../../items'
import { getItemSection } from '../../util/get-item-section'
import striptags from 'striptags'

interface OrderedSubstrate extends OrderItem, QuoteSubstrate {}

interface trimSubstrateInput {
  quote: QuoteDocument
  clientOptions: OptionsDocument
  item: OrderedSubstrate
  subItems: RenderableSubItem[]
  options: QuoteOptions
  consumer: QuoteItemConsumer
  section: QuoteItemSection
  noReportText?: boolean
  showGroup?: boolean
  groupKey?: string
}

// Convert a quoteSubstrate retrieved from quote.substrates to a Renderable Item to be edited/viewed.
export function trimSubstrate({
  quote,
  item,
  subItems,
  clientOptions,
  options,
  consumer,
  section,
  noReportText,
  showGroup,
  groupKey
}: trimSubstrateInput): RenderableItem {
  const substrateSection = getItemSection(item)

  const label =
    substrateSection === section
      ? striptags(item?.client_label ? item.client_label : item?.label ?? '')
      : striptags(String(item.clientLabel?.default))
  const substrateKey = item.key

  const defaultDescription = generateSubstrateDescription({
    quote,
    substrateKey,
    options,
    clientOptions,
    section,
    noReportText,
    showGroup,
    groupKey
  })

  const description =
    consumer === 'crew'
      ? { useCustom: false, default: '', custom: '' }
      : {
          useCustom: substrateSection === section ? !!item.area_string.use_custom : false,
          custom: item.area_string.custom,
          default: defaultDescription
        }

  let totals = item.totals
  if (consumer === 'customer') {
    if (item.grouped_totals?.price) {
      totals = item.grouped_totals
    } else if (item.hidden_totals?.price && section !== 'bid') {
      totals = item.hidden_totals
    }
  }

  const { hours, price, prep } = totals

  const { label: dimensionLabel, value: dimensionValue } = subItems.reduce(
    (acc, subItem) => {
      if (subItem.dimension) {
        return {
          label: subItem.dimension.label,
          value: acc.value + subItem.dimension.value
        }
      }

      return acc
    },
    {
      label: '',
      value: 0
    }
  )
  const name = dimensionValue
    ? `${label} (${
        Math.floor(dimensionValue * 100) === dimensionValue * 100 ? dimensionValue : dimensionValue.toFixed(2)
      }${dimensionLabel})`
    : label

  const r: any = {
    key: item.key,
    type: item.type,
    name,
    description,
    files: item?.files ?? [],
    images: item?.images ?? [],
    value: consumer === 'crew' ? hours + prep : price
  }

  if (consumer === 'crew') {
    r.additionalFields = {
      prep: prep,
      painting: hours,
      price
    }
  } else {
    r.additionalFields = {
      painting: hours,
      prep: prep,
      price
    }
  }
  return r
}

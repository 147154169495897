import type { ReactNode } from 'react'
import type { PopperProps as MuiPopperProps } from '@material-ui/core/Popper'
import React from 'react'
import type { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'
import Dialog, { DialogActions, DialogContent } from '../Dialog'
import Popper from '@material-ui/core/Popper'
import type { Theme } from '@material-ui/core'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles<Theme, ResponsivePopperProps>(() => ({
  root: {},
  dialogRoot: {},
  dialogContent: {}
}))

export interface ResponsivePopperProps {
  classes?: ReturnType<typeof useStyles>
  open: boolean
  children: ReactNode
  footer?: ReactNode
  PopperProps?: Partial<MuiPopperProps>
  modalBreakpoint?: keyof BreakpointValues
}

export default function ReponsivePopper(props: ResponsivePopperProps) {
  const { open, children, footer, PopperProps = {}, modalBreakpoint = 'xs' } = props
  const classes = useStyles(props)
  const theme = useTheme()
  const isDialog = useMediaQuery(theme.breakpoints.down(modalBreakpoint))

  if (!isDialog) {
    return (
      <Popper className={classes.root} open={open} {...PopperProps}>
        {children}
        {footer && <div>{footer}</div>}
      </Popper>
    )
  } else {
    return (
      <Dialog classes={{ paper: classes.dialogRoot }} open={open}>
        <DialogContent className={classes.dialogContent}>{children}</DialogContent>
        {footer && <DialogActions>{footer}</DialogActions>}
      </Dialog>
    )
  }
}

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Typography, useClientOptions } from '@ui/paintscout'
import React from 'react'
import { WisetackDisclaimerContent } from '../WisetackPromoDisclaimer'
import { getObjectLabels } from '@paintscout/util/builder'
import type { WisetackPromoResponse } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  textWrapper: {
    textAlign: 'left'
  },
  textSpacing: {
    marginBottom: theme.spacing(2)
  },
  disclaimer: {
    fontStyle: 'italic'
  }
}))

export interface WisetackLearnMoreProps {
  isCustomerView?: boolean
  wisetackPromo?: WisetackPromoResponse
}

export default function WisetackLearnMore({ isCustomerView, wisetackPromo }: WisetackLearnMoreProps) {
  const classes = useStyles()
  const content = isCustomerView ? <CustomerViewContent wisetackPromo={wisetackPromo} /> : <MerchantContent />
  return (
    <>
      {content}
      <div className={classes.disclaimer}>
        <WisetackDisclaimerContent wisetackPromo={wisetackPromo} />
      </div>
    </>
  )
}

const MerchantContent = () => {
  const classes = useStyles()
  const { options } = useClientOptions()
  const { quote: quoteLabel } = getObjectLabels({ options, invoice: false })
  return (
    <div className={classes.textWrapper}>
      <Typography variant="h6" className={classes.textSpacing}>
        How-to
      </Typography>
      <Typography className={classes.textSpacing}>
        Financing Application Process in 3 Easy Steps:
        <ol>
          <li>
            Send a financing application (or a qualifying {quoteLabel.value.toLowerCase()}) to your customer (no fee to
            you or impact to their credit score)
          </li>
          <li>
            Once your customer is approved and they’ve locked in an offer, complete the job and get paid from Wisetack!
          </li>
        </ol>
      </Typography>
      <Typography variant="h6" className={classes.textSpacing}>
        Steps for your Customer
      </Typography>
      <Typography className={classes.textSpacing}>
        Apply in less than 60 seconds and get approved for up to $25,000, with no credit score impact! Select the
        monthly payment that works best within the budget. Accept the terms and mark the work as complete.
      </Typography>
    </div>
  )
}

const CustomerViewContent = ({ wisetackPromo }: { wisetackPromo: WisetackPromoResponse }) => {
  const classes = useStyles()
  return (
    <div className={classes.textWrapper}>
      <Typography variant="h6" className={classes.textSpacing}>
        {wisetackPromo.headline}
      </Typography>
      <Typography className={classes.textSpacing}>
        {wisetackPromo.tagline}
        <ul>
          <li>{wisetackPromo.valueProp1}</li>
          <li>{wisetackPromo.valueProp2}</li>
          <li>{wisetackPromo.valueProp3}</li>
        </ul>
      </Typography>
      <Typography className={classes.textSpacing}>
        Consumer-friendly financing makes it easy to afford the services you care about. Now you can invest in what
        matters most without surprises or unexpected late fees. Don’t worry – checking your options will not affect your
        credit score. Learn even more{' '}
        <a target="_blank" href="https://www.wisetack.com/consumers" rel="noreferrer">
          here
        </a>
        !
      </Typography>
    </div>
  )
}

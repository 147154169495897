import React from 'react'
import Highlight from '../Highlight'
import type { Activity } from '@paintscout/api'

export interface ActivityOwnerProps {
  name?: string
  activity: Activity
  /**
   * Defaults to ' by '
   */
  prefix?: string
}

/**
 * Renders "by firstName lastName" if there is an owner, otherwise renders nothing
 */
export default function ActivityOwner(props: { activity: Activity; prefix?: string }) {
  const { activity, prefix = ' by ' } = props

  const owner = activity.details.owner
  const name = `${owner?.firstName ?? ''} ${owner?.lastName ?? ''}`.trim()

  if (!name) {
    return null
  }

  return (
    <>
      {prefix}
      <Highlight>{name}</Highlight>
    </>
  )
}

import SpinnerLogo from './SpinnerLogo'
export * from './SpinnerLogo'

export * from './FlipperLogo'

// import PopperLogo from './PopperLogo'
// export * from './PopperLogo'

export interface AnimatedLogoProps {
  scale?: number
}

// function DefaultLogo(props) {
//   const logos = [SpinnerLogo, FlipperLogo, PopperLogo]
//   const RandomLogo = logos[Math.floor(Math.random() * logos.length)]
//   return <RandomLogo {...props} />
// }
// export default DefaultLogo
export default SpinnerLogo

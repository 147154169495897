import type { PresentationIsEmptyProps } from '../../'
export default function isEmpty({ section, isPrint }: PresentationIsEmptyProps) {
  const fields = section?.fields
  if (!fields) {
    return true
  }

  if (!fields.image) {
    return true
  }

  if (isPrint && fields.image.format === 'youtube') {
    return true
  }

  return false
}

import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'

import { getJobAddress, getJobIdentifier, getQuoteOptions } from '@paintscout/util/builder'

import ContactCard from '../ContactCard'
import SecondaryContactCard from '../SecondaryContactCard'
import JobAddressCard from '../JobAddressCard'
import QuoteDateCard from '../QuoteDateCard/QuoteDateCard'
import QuoteStatusCard from '../QuoteStatusCard'
import QuoteHeaderCard from '../QuoteHeaderCard'

import { useClientOptions, Grid } from '@ui/paintscout'
import EstimatorCard from '../EstimatorCard'
import CompanyCard from '../CompanyCard'

import QuoteIDCard from '../QuoteIDCard'

import { useQuote } from '../../../context'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4)
    }
  })

export interface QuoteHeaderProps extends WithStyles<typeof styles> {
  isEditable?: boolean
  variant?: 'default' | 'work-order' | 'product-order-form'
}

function QuoteHeaderMobile({ classes, variant, ...props }: QuoteHeaderProps) {
  const quoteContext = useQuote()
  const { options } = useClientOptions()
  const { quote } = quoteContext
  const isEditable = quoteContext.isEditable || props.isEditable

  const isTemplate = quote.type === 'quoteTemplate'

  const jobAddress = getJobAddress({ quote })
  const jobIdentifier = getJobIdentifier({ quote })
  const { hideWorkOrderClientContactInfo } = getQuoteOptions({ options, quote })
  const showJobAddress = isEditable || jobAddress || jobIdentifier
  const showContactDetails = !(hideWorkOrderClientContactInfo && variant === 'work-order')

  return (
    <div className={classes.root} id="quote-header-mobile">
      <Grid container={true} spacing={3} alignItems="flex-start">
        <Grid item={true} xs={12} id={'quote-header-status-card'}>
          <QuoteStatusCard variant={variant} />
        </Grid>

        {!isTemplate && (
          <>
            <Grid item={true} xs={12} id={'quote-header-contact-card'}>
              {showContactDetails && (
                <>
                  <ContactCard />
                  {quote?.secondaryContact && <SecondaryContactCard />}
                </>
              )}
            </Grid>
            {showJobAddress && (
              <Grid item={true} xs={12} id={'quote-header-job-address-card'}>
                <JobAddressCard />
              </Grid>
            )}

            <Grid item xs={6} id={'quote-header-date-card'}>
              <QuoteDateCard />
            </Grid>
            <Grid item xs={6} id={'quote-header-number-card'}>
              <QuoteIDCard />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <QuoteHeaderCard showBorder={true}>
            <Grid container spacing={3}>
              <Grid item xs={6} id={'quote-header-company-card'}>
                <CompanyCard />
              </Grid>
              <Grid item xs={6} id={'quote-header-estimator-card'}>
                <EstimatorCard />
              </Grid>
            </Grid>
          </QuoteHeaderCard>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(QuoteHeaderMobile)

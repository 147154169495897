import clone from 'lodash/clone'
export function zero<T extends { hours: number | string; prep: number | string; price: number | string }>(obj: T): T {
  // return {
  //   ...obj,
  //   hours: 0,
  //   price: 0,
  //   prep: 0
  // }
  if (!obj) {
    return {
      hours: 0,
      price: 0,
      prep: 0
    } as T
  }
  const objClone = clone(obj)
  for (const key in objClone) {
    if (objClone[key]) {
      delete objClone[key]
    }
  }
  objClone.hours = 0
  objClone.price = 0
  objClone.prep = 0

  return objClone as T
}

import type { MessageActivity, MessagesQuery } from '@paintscout/api'
import {
  FullMessageActivityFragmentDoc,
  MessagesDocument,
  sendMessageOptimisticResponse,
  useDeleteMessageMutation,
  useMessagesQuery,
  useNewMessagesQuery,
  useSendMessageMutation
} from '@paintscout/api'
import { useQuote } from '@ui/react-quote'
import type { ChatBoxProps, ChatBoxRef } from '@ui/paintscout'
import { ChatBox, useClientOptions } from '@ui/paintscout'
import { defaultDataIdFromObject } from 'apollo-boost'
import uniqBy from 'lodash/uniqBy'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useView } from '../ViewProvider'
import type { QuoteDocument } from 'paintscout'
import { useEffectPrevious, useInterval } from '@ui/core'
import { getFeature } from '@paintscout/util/builder'
import { createQuoteMessageActivity } from '@paintscout/util/activity'
import { removeTypenames } from '@paintscout/util/util'

export interface ChatProps {
  classes?: ChatBoxProps['classes']
  onUnread?: (activities: MessageActivity[]) => any
}

const MESSAGES_LIMIT = 20
const ACTIVE_TIME = 1000 * 60 * 3 // estimator is considered active if they replied less than this time ago

export default function Chat({ onUnread, ...props }: ChatProps) {
  const { quote, updateQuote } = useQuote()
  const { estimator } = useView()
  const { enqueueSnackbar } = useSnackbar()
  const { options } = useClientOptions()
  const hasSettingsCommunications = getFeature({ options, path: 'settingsCommunications.enabled' })
  const chatRef = useRef<ChatBoxRef>(null)
  const estimatorName = `${estimator.name}`
  const [isEstimatorActive, setEstimatorActive] = useState(false)
  const alwaysShowStatusMessage = hasSettingsCommunications
    ? options.options?.chat?.alwaysShowStatusMessage
    : estimator.alwaysShowStatusMessage
  const statusMessage = hasSettingsCommunications ? options.options?.chat?.statusMessage : estimator.statusMessage
  const chatStatus = isEstimatorActive && !alwaysShowStatusMessage ? 'Active Now' : statusMessage
  const [pollTimestamp, setPollTimestamp] = useState(Date.now())

  const { data, loading, updateQuery, fetchMore } = useMessagesQuery({
    variables: {
      quoteId: quote._id,
      limit: MESSAGES_LIMIT
    },
    notifyOnNetworkStatusChange: true
  })

  const messages = useMemo(() => data?.messages?.messages ?? [], [data])
  const hasMore = messages.length < data?.messages?.total_rows
  const lastMessage = useMemo(
    () =>
      messages
        .sort((a, b) => b.details.timestamp - a.details.timestamp)
        .find((message) => (message as MessageActivity).details.activityType === 'message') as MessageActivity,
    [messages]
  )

  function checkIfEstimatorActive() {
    const lastEstimatorMessage = getLastEstimatorMessage(messages as MessageActivity[])
    if (lastEstimatorMessage) {
      setEstimatorActive(lastEstimatorMessage.details.timestamp > Date.now() - ACTIVE_TIME)
    }
  }

  useEffect(() => {
    checkIfEstimatorActive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  useInterval(() => {
    checkIfEstimatorActive()
  }, 5000)

  // scroll to bottom when `messages` is updated with a new message
  useEffectPrevious(
    (prevMessages) => {
      const prevNewestMessage = prevMessages.sort((a, b) => (a.details.timestamp > b.details.timestamp ? -1 : 1))[0]
      const currentNewestMessage = messages.sort((a, b) => (a.details.timestamp > b.details.timestamp ? -1 : 1))[0]

      if (prevNewestMessage !== currentNewestMessage) {
        chatRef.current?.scrollToBottom()
      }
    },
    [messages] as const
  )

  useNewMessagesQuery({
    pollInterval: 15000,
    fetchPolicy: 'no-cache',
    variables: useMemo(() => {
      return {
        quoteId: quote._id,
        since: pollTimestamp
      }
      // eslint-disable-next-line
    }, [pollTimestamp]),
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.newMessages?.length > 0) {
        setPollTimestamp(Date.now())
        updateQuery((prev) => {
          const newActivities = data.newMessages.filter(
            (activity) => !prev.messages.messages.find(({ _id }) => _id === activity._id)
          )
          const updatedActivities = data.newMessages.filter(
            (activity) => !!prev.messages.messages.find(({ _id }) => _id === activity._id)
          )

          return {
            ...prev,
            messages: {
              ...prev.messages,
              total_rows: prev.messages.total_rows + data.newMessages.length,
              messages: [
                ...newActivities,
                ...prev.messages.messages.map((activity) => {
                  const updatedActivity = updatedActivities.find(({ _id }) => _id === activity._id)
                  return updatedActivity || activity
                })
              ]
            }
          }
        })
      }
    }
  })

  const [deleteMessage] = useDeleteMessageMutation({
    onError: useCallback((e) => {
      console.error('Failed to delete message', e)
      enqueueSnackbar('Unable to delete message', { variant: 'error' })
      // eslint-disable-next-line
    }, [])
  })

  useEffect(() => {
    if (onUnread) {
      onUnread(
        messages.slice(0, MESSAGES_LIMIT).filter((message) => {
          if (message.__typename === 'MessageActivity') {
            const isCustomer = message.details.message.from.isCustomer
            const isRead = !!message.details.message.readAt
            const isDeleted = !!message.details.message.deleted

            return !isCustomer && !isRead && !isDeleted
          }

          return false
        }) as MessageActivity[]
      )
    }
    // eslint-disable-next-line
  }, [data])

  async function handleLoadMore() {
    await fetchMore({
      variables: {
        quoteId: quote._id,
        bookmark: data.messages.bookmark,
        limit: MESSAGES_LIMIT
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!prev) {
          return fetchMoreResult
        }

        if (!fetchMoreResult.messages) {
          return prev
        }

        return {
          ...prev,
          ...fetchMoreResult,
          messages: {
            ...prev.messages,
            ...fetchMoreResult.messages,

            // duplicates will come in if newMessahes has returned any new docs, so we need to filter them out
            messages: uniqBy([...prev.messages.messages, ...fetchMoreResult.messages.messages], '_id')
          }
        }
      }
    })
  }

  function handleDelete(activity: MessageActivity) {
    deleteMessage({
      variables: {
        id: activity._id
      },
      optimisticResponse: {
        deleteMessage: true
      },
      update: (cache) => {
        const id = defaultDataIdFromObject({ ...activity, __typename: 'MessageActivity' })
        const fragment = cache.readFragment<MessageActivity>({
          id,
          fragmentName: 'FullMessageActivity',
          fragment: FullMessageActivityFragmentDoc
        })

        cache.writeFragment({
          id,
          fragmentName: 'FullMessageActivity',
          fragment: FullMessageActivityFragmentDoc,
          data: {
            ...fragment,
            details: {
              ...fragment.details,
              message: {
                ...fragment.details.message,
                deleted: true
              }
            }
          }
        })
      }
    })
  }

  const [sendMessage] = useSendMessageMutation({
    update: (
      cache,
      {
        data: {
          sendMessage: { activity, quote }
        }
      }
    ) => {
      const cacheData = cache.readQuery<MessagesQuery>({
        query: MessagesDocument,
        variables: {
          quoteId: quote._id,
          limit: MESSAGES_LIMIT
        }
      })

      cache.writeQuery<MessagesQuery>({
        query: MessagesDocument,
        variables: {
          quoteId: quote._id,
          limit: MESSAGES_LIMIT
        },
        data: {
          ...cacheData,
          messages: {
            ...cacheData.messages,
            total_rows: cacheData.messages.total_rows + 1,
            // sometimes sendMessage mutation response happens after the "new message" listener picks it up,
            // so we need to uniq it
            messages: uniqBy([activity, ...cacheData.messages.messages], '_id')
          }
        }
      })
    }
  })

  async function handleSend(message) {
    try {
      const timestamp = Date.now()
      const optimisticResponse = {
        sendMessage: sendMessageOptimisticResponse(
          createQuoteMessageActivity({
            quote,
            from: {
              userId: null,
              name: `${quote.contact.first_name} ${quote.contact.last_name}`,
              email: quote.contact.email ?? null,
              isCustomer: true
            },
            message,
            timestamp
          }),
          quote
        )
      }

      // send notification if the last message was not from customer, or was longer than 5 minutes ago
      const sendNotification =
        !lastMessage?.details.message.from.isCustomer ||
        (lastMessage?.details?.timestamp ?? 0) < Date.now() - 1000 * 60 * 2

      const { data } = await sendMessage({
        context: {
          onlineOnly: true
        },
        optimisticResponse,
        variables: {
          message,
          quoteId: quote._id,
          timestamp,
          sendNotification
        }
      })
      const updatedQuote = (data?.sendMessage?.quote as any as QuoteDocument) ?? null
      if (updatedQuote) {
        updateQuote({ quote: removeTypenames(updatedQuote) })
      }
    } catch (e) {
      enqueueSnackbar('Unable to send message', { variant: 'error' })
      throw e
    }
  }

  return (
    <ChatBox
      ref={chatRef}
      loading={loading}
      hasMore={hasMore}
      messages={messages as MessageActivity[]}
      placeholder={`Send a message to ${quote.owner.firstName}`}
      name={estimatorName}
      status={chatStatus}
      onDelete={handleDelete}
      onUnread={onUnread}
      onSendMessage={handleSend}
      onLoadMore={handleLoadMore}
      {...props}
    />
  )
}

function getLastEstimatorMessage(messages: MessageActivity[]) {
  return messages
    .sort((a, b) => (a.details.timestamp > b.details.timestamp ? -1 : 1))
    .filter((message) => message?.details?.message?.from?.isCustomer === false)[0]
}

import type { OptionsDocument, QuoteOptions } from 'paintscout'
import type { UpdateableProduct } from '../../../area-substrates'
import getProductFields from './get-product-fields'
import type { ProductFields } from './get-product-fields'

/**
 * @description Crew description and materialValues to be displayed
 * @returns crewDescription and productFields
 */
export const getCrewDescription = ({
  products,
  prep,
  hours,
  itemName,
  clientLabel,
  showValues,
  quoteOptions,
  substrate,
  options
}: {
  products?: UpdateableProduct[]
  quoteOptions: QuoteOptions
  prep: number
  hours: number
  itemName: string
  clientLabel: string
  showValues?: boolean
  substrate?: boolean
  options: OptionsDocument
}): { crewDescription: string; productFields: ProductFields[] } => {
  const descriptionComponents = []
  /* "secondLine" is the line that has the client label and/or the prep/hours */
  const secondLine = []
  const hoursComponents = []

  if (substrate) {
    // toRenderableCrewSubstrateArea filter
    if (clientLabel || (clientLabel == '' && clientLabel.trim().toLowerCase() !== itemName.trim().toLowerCase())) {
      secondLine.push(itemName)
    }
  } else {
    // toRenderableCrewAreaSubstrate filter
    if (clientLabel && clientLabel !== itemName) {
      secondLine.push(itemName)
    }
  }
  if (prep && showValues) {
    hoursComponents.push(`Prep: ${Math.round(prep * 100) / 100}`)
    hoursComponents.push(`Labor: ${Math.round(hours * 100) / 100}`)
  }

  const labourHours = hoursComponents.join(' + ')
  if (labourHours) {
    secondLine.push(labourHours)
  }

  if (secondLine.filter(Boolean).length) {
    descriptionComponents.push(secondLine.join(' - '))
  }

  // Generate product related fields and description string
  const productFields: ProductFields[] = []
  for (const product of products ?? []) {
    const { productFields: generatedFields, descriptionString } = getProductFields({
      product,
      quoteOptions,
      clientOptions: options
    })

    descriptionComponents.push(descriptionString)
    productFields.push(generatedFields)
  }

  // Clean and join our description components
  // the if statement above is sometimes pushing an empty string into descriptionComponents, the following removes them
  const noEmptyDescriptionComponents = descriptionComponents.filter((elem) => elem.length > 0)
  const crewDescription: string = noEmptyDescriptionComponents.join('\n')

  return { crewDescription, productFields }
}

import React, { useState, useEffect } from 'react'
import type { DialogProps } from '@ui/paintscout'
import { useDialogs, useProcessEvent } from '@ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import {
  Spinner,
  Typography,
  Grid,
  Button,
  AlertDialog,
  useClientOptions,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@ui/paintscout'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core/styles'

import { Elements as StripeElements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import type { QuoteEventType, QuoteDocument, OptionsDocument } from 'paintscout'

import { useSnackbar } from 'notistack'

import {
  formatCurrency,
  getEmailTemplates,
  getFeatures,
  getObjectLabels,
  getQuoteOptions
} from '@paintscout/util/builder'
import * as Sentry from '@sentry/core'

import Done from '@material-ui/icons/Done'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { usePaymentProcessedQuery } from '@paintscout/api'
import PaymentDialogRightContent from '../../PaymentDialogRightContent'
import { isEmailValid } from '@paintscout/util/util'
import TotalTable from '../../TotalTable'

export interface CollectPaymentDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  quote: QuoteDocument
  options: OptionsDocument
  amount: number
  note?: string
  paymentRequestId?: string
  source?: 'collect-payment' | 'customer-view' | 'preview' | 'payment-request'
  checkForMismatch?: boolean
  isDeposit?: boolean
  showSurcharge?: boolean
  backOnCancel?: boolean
  onCancel: () => void
  onConfirm?: (updatedQuote?: QuoteDocument) => void | Promise<void>
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    slate: {
      minHeight: '10em'
    },
    dialogContent: {
      overflow: 'hidden',
      padding: theme.spacing(1, 2)
    },
    successCheckmark: {
      color: theme.palette.success.main,
      width: '3em',
      height: '3em'
    },
    checkContainer: {
      height: '100%'
    }
  })
}

function CollectPaymentDialog(props: CollectPaymentDialogProps) {
  const { onCancel, options, quote, isDeposit, source, amount, note, paymentRequestId, checkForMismatch } = props
  const { processEvent } = useProcessEvent()
  const objectLabels = getObjectLabels({ options, quote, invoice: quote.is_invoice })

  const [stripeOptions, setStripeOptions] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const { dismissAllDialogs, openDialog } = useDialogs()
  const [loading, setLoading] = useState(true)

  const { surcharge } = getQuoteOptions({ options, quote })
  const surchargeFeature = getFeatures({ options })?.surcharge
  const showSurcharge =
    surchargeFeature &&
    !!surcharge?.enabled &&
    surcharge?.value > 0 &&
    (!isDeposit || (isDeposit && surcharge?.applyToDeposit))
  const surchargeAmount = showSurcharge ? (amount * surcharge.value) / 100 : 0

  useEffect(() => {
    async function process() {
      const res = await processEvent({
        type: 'get-payment-intent' as QuoteEventType,
        provider: 'stripe',
        params: {
          quoteId: quote._id,
          source,
          deposit: isDeposit,
          paymentAmount: amount + surchargeAmount,
          note: note?.length > 100 ? `${note.substring(0, 100)}...` : note,
          paymentRequestId,
          rev: checkForMismatch ? quote._rev : undefined,
          surcharge: surchargeAmount,
          customerViewURL: source === 'customer-view' ? window.location.toString() : undefined
        }
      })

      if (res.error || res.result?.error) {
        const error = res.error || res.result?.error
        if (error?.includes('Rev Mismatch')) {
          openDialog(AlertDialog, {
            title: `${objectLabels.quote.value} Has Been Updated`,
            icon: <ErrorIcon />,
            color: 'warning',
            message: `The ${objectLabels.quote.value} was updated by the estimator while you were viewing it. It may have been for internal purposes, but we suggest you look over it once more first.`,
            onConfirm: () => {
              dismissAllDialogs()
              window.location.reload()
            }
          })
        } else {
          enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' })
          onCancel()
        }
      } else if (res.result) {
        setStripeOptions({
          clientSecret: res.result.result.clientSecret,
          account: res.result.result.account
        })
        setStripePromise(loadStripe(res.result.result.publishableKey))
      }
    }
    if (!paymentRequestId || isEmailValid(quote.contact?.email)) {
      process()
    } else {
      dismissAllDialogs()
      if (source === 'customer-view') {
        enqueueSnackbar('Something went wrong. Please contact your estimator', { variant: 'error' })
      } else {
        openDialog(AlertDialog, {
          title: 'Invalid Email',
          message: 'You must add a valid email address to your contact before you can request a payment',
          onConfirm: dismissAllDialogs
        })
        return
      }
    }
  }, [])

  return (
    <StripeElements stripe={stripePromise} options={stripeOptions}>
      <CheckoutForm loading={loading} setLoading={setLoading} showSurcharge={showSurcharge} {...props} />
    </StripeElements>
  )
}

function CheckoutForm({
  onCancel,
  onConfirm,
  quote,
  classes,
  loading,
  setLoading,
  amount,
  isDeposit,
  showSurcharge,
  backOnCancel,
  ...otherProps
}: CollectPaymentDialogProps & { loading: boolean; setLoading: React.Dispatch<React.SetStateAction<boolean>> }) {
  const stripe = useStripe()
  const elements = useElements()
  const options = useClientOptions()?.options || otherProps.options
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog } = useDialogs()
  const { surcharge } = getQuoteOptions({ options, quote })

  const surchargeAmount = showSurcharge ? (amount * surcharge.value) / 100 : 0

  const leftButton = (
    <Button disabled={loading} onClick={onCancel} variant={'text'}>
      {backOnCancel ? '< Back' : 'Cancel'}
    </Button>
  )

  const paymentAmountBeforeSurcharge = formatCurrency({ options, value: amount })
  const paymentAmountAfterSurcharge = formatCurrency({ options, value: amount + surchargeAmount })

  const Title = () => {
    return (
      <DialogTitle
        fade={false}
        rightContent={
          <PaymentDialogRightContent
            quote={quote}
            options={options}
            includeSurcharge={showSurcharge}
            paymentAmount={paymentAmountBeforeSurcharge}
          />
        }
      >{`Pay ${isDeposit ? 'Deposit ' : ''}`}</DialogTitle>
    )
  }

  return (
    <Dialog onClose={onCancel} fullWidth={true} maxWidth={'md'} {...otherProps}>
      <form onSubmit={handleSubmit}>
        <Title />
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PaymentElement
                onReady={() => {
                  setLoading(false)
                }}
              />
            </Grid>
            {showSurcharge && !loading && (
              <Grid item xs={12}>
                <TotalTable
                  propQuote={quote}
                  propOptions={options}
                  propTotals={{
                    subTotal: amount,
                    balanceDue: amount + (amount * surcharge.value) / 100,
                    showBalanceDue: true,
                    serviceFees: {
                      surcharge: showSurcharge ? (amount * surcharge.value) / 100 : 0
                    }
                  }}
                  showPaid={false}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions leftButton={!loading && leftButton}>
          <Button loading={loading} type="submit" variant={'contained'} icon={Done}>
            Pay {paymentAmountAfterSurcharge}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )

  async function handleSubmit(event) {
    try {
      event.preventDefault()

      setLoading(true)

      if (!stripe || !elements) {
        onCancel()
        enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' })
        return null
      }

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.toString()
        },
        redirect: 'if_required'
      })

      setLoading(false)

      if (result.error) {
        handleError(result.error)
      } else {
        handleSuccessfulPayment()
      }
    } catch (err) {
      setLoading(false)
      handleError(err)
    }
  }

  function handleError(error: any) {
    console.log('Error processing payment:')
    console.log(error.message ?? error)

    // If the stripe form is missing data the form will be re-rendered with the error message,
    // so we don't need to show a snackbar.
    if (!error?.message.includes('is incomplete')) {
      Sentry.captureException(error)
      if (error?.message.includes('declined')) {
        enqueueSnackbar(' Card declined, unable to process payment', { variant: 'error' })
      }
      enqueueSnackbar('Unable to process payment.', { variant: 'error' })
    }
  }

  function handleSuccessfulPayment() {
    openDialog(PaymentProcessingDialog, {
      Title: Title(),
      amount: paymentAmountAfterSurcharge,
      classes,
      quote,
      balanceDue: quote.totals.balance_due,
      onConfirm
    })
  }
}

function PaymentProcessingDialog(
  props: DialogProps & {
    amount: string
    classes: any
    quote: QuoteDocument
    balanceDue: number
    onConfirm: CollectPaymentDialogProps['onConfirm']
    Title: JSX.Element
  }
) {
  const { amount: _amount, classes, quote, balanceDue, onConfirm, Title, ...otherProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const { options } = useClientOptions()

  const [processing, setProcessing] = useState(true)
  const [confirming, setConfirming] = useState(false)

  const { dismissAllDialogs } = useDialogs()

  const paymentReceivedTemplate = getEmailTemplates({ options })?.['payment-received']

  const { data } = usePaymentProcessedQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: quote._id,
      balanceDue
    },
    onError: (error) => {
      console.log('Error processing payment:')
      console.log(error.message)
      dismissAllDialogs()
      enqueueSnackbar('Your payment has been received, but we were unable to process it.', { variant: 'error' })
    },
    onCompleted: () => {
      if (data?.paymentProcessed?.updatedQuote) {
        setProcessing(false)
      }
    }
  })

  async function handleConfirm() {
    if (onConfirm) {
      setConfirming(true)
      await onConfirm({ ...quote, ...data?.paymentProcessed?.updatedQuote })
      setConfirming(false)
    }
  }

  return (
    <Dialog {...otherProps}>
      {Title}
      <DialogContent>
        <Grid
          className={classes.checkContainer}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={1}
        >
          {processing ? (
            <>
              <Grid item>
                <Spinner className={classes.successCheckmark} />
              </Grid>
              <Grid item>
                <Typography align="center" gutterBottom variant="h3">
                  Payment Received! Processing...
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <CheckCircle className={classes.successCheckmark} />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h2">
                  Payment Succeeded
                </Typography>
              </Grid>
              {paymentReceivedTemplate?.enabled && (
                <Grid item>
                  <Typography variant="caption">Please check your email for a receipt.</Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!processing && (
          <Button loading={confirming} onClick={handleConfirm} type="submit" variant={'contained'} icon={Done}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(CollectPaymentDialog)

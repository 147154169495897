import React from 'react'
import type { QuoteFile } from 'paintscout'
import type { ImageProps, VideoProps, CloudinaryComponentProps } from 'cloudinary-react'
import Image from './Image'
import Video from './Video'
import type { Theme } from '@material-ui/core'
import { makeStyles, Avatar } from '@material-ui/core'
import classnames from 'classnames'
import Typography from '../Typography'
import Paper from '../Paper'
import PlayArrow from '@material-ui/icons/PlayArrow'
import AttachmentIcon from '@material-ui/icons/Attachment'
import Spinner from '../Spinner'
import type { StyleClasses } from '@ui/core/theme'
import ReactYouTube from 'react-youtube'
import ResponsiveImage from './ResponsiveImage'
import ResponsiveVideo from './ResponsiveVideo'

type CommonCloudinaryProps = Omit<ImageProps & VideoProps, keyof CloudinaryComponentProps>
export interface FilePreviewProps extends CommonCloudinaryProps {
  className?: string
  classes?: StyleClasses<typeof useStyles>
  file: QuoteFile
  uploading?: boolean

  isEditable?: boolean
  inPresentation?: boolean
  inGallery?: boolean

  selectedPages?: number[]

  /**
   * Shows an overlay of the title/caption for file types that support it (just images for now)
   */
  showOverlay?: boolean

  /**
   * if true, the video will only render an image thumbnail rather than a video player
   */
  videoThumbnail?: boolean
}

const useStyles = makeStyles<Theme, FilePreviewProps>(
  (theme) => ({
    root: {
      padding: 2,
      maxWidth: '100%',
      minHeight: '100px',
      minWidth: '100px',
      // aspectRatio: '4/3',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      borderRadius: 0,
      textAlign: 'center',
      overflow: 'hidden',
      '&$uploading $image': {
        opacity: 0.3
      }
    },
    placeholder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.typography.h1
    },
    fileRoot: {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      width: 'auto',
      height: 'auto',
      // fixes a small gap below  causing it not to fill parent div - https://stackoverflow.com/a/10266919
      verticalAlign: 'top',
      backgroundColor: 'transparent'
    },
    image: {},
    imageRoot: {},
    video: {},
    clickable: {
      cursor: 'pointer'
    },
    uploadProgress: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: 0
    },
    pageIcon: (_props) => ({
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
      color: 'grey',
      outline: '0px solid rgba(0,0,0,0.34)',
      pointerEvents: 'none'
    }),
    descriptionIcon: (props) => ({
      zIndex: 12,
      lineHeight: 1,
      position: 'absolute',
      top: props.inGallery ? '65%' : '62%',
      left: props.inGallery ? '50%' : '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      padding: '0.1em',
      fontSize: props.inGallery ? '0.9rem' : '0.8rem',
      outline: '0px solid rgba(0,0,0,0.36)',
      color: 'black',
      opacity: props.isEditable || props.inGallery ? 0.9 : `${0.9} !important`,
      fontWeight: 'bold',
      pointerEvents: 'none'
    }),
    textOverlay: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      padding: '1em',
      background: 'rgba(0,0,0,0.4)',
      color: 'white',
      pointerEvents: 'none'
    },
    videoOverlay: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      opacity: 0.8,
      pointerEvents: 'none'
    },
    title: {
      color: 'white'
    },
    caption: {
      color: 'white'
    },
    uploading: {},
    youtubeWrapper: {
      width: '100%',
      position: 'relative',
      // paddingTop: '56.25%',
      '& iframe': {
        width: '100%',
        // height: '100%',
        height: 'auto',
        aspectRatio: '16/9'
        // position: 'absolute',
        // top: 0,
        // left: 0
      }
    },
    pdfPaper: {
      position: 'relative',
      '& svg': {
        position: 'absolute',
        top: 0,
        right: 0,
        background: '#fff',
        borderRadius: '50%'
      }
    }
  }),
  { name: 'FilePreview' }
)

function FilePreview({
  className,
  file,
  selectedPages,
  uploading,
  showOverlay,
  videoThumbnail,
  ...otherProps
}: FilePreviewProps) {
  let content: React.ReactNode
  const classes = useStyles({ file, ...otherProps })
  const { cloudinaryPublicId, title } = file

  const isVideo = (file?.type ?? '').startsWith('video') || ['mp4', 'mov'].includes(file.format)
  const isPdf = file?.format === 'pdf' || file?.type === 'application/pdf'

  if (file.youtubeId && file.format === 'youtube') {
    const youtubeOpts = {}
    content = (
      <div className={classes.youtubeWrapper}>
        <ReactYouTube videoId={file?.youtubeId} opts={youtubeOpts} />
      </div>
    )
  } else if ((!file.cloudinaryPublicId && !file.s3PublicKey && !file.src) || uploading) {
    content = (
      <div className={classnames(classes.fileRoot, classes.placeholder)} onClick={otherProps.onClick}>
        <AttachmentIcon color="inherit" fontSize={'inherit'} />
      </div>
    )
  } else {
    if (file.type && isVideo) {
      if (file?.src && file?.s3PublicKey) {
        content = (
          <ResponsiveVideo
            file={file}
            classes={{ root: classnames(classes.fileRoot, classes.video) }}
            onClick={otherProps.onClick}
          />
        )
      } else if (videoThumbnail) {
        // workaround for https://github.com/cloudinary/cloudinary-react/issues/85
        content = (
          <Image
            {...otherProps}
            classes={{
              root: classnames(classes.fileRoot, classes.image)
            }}
            publicId={`${cloudinaryPublicId}.jpg`}
            resourceType="video"
          />
        )
      } else {
        content = (
          <Video
            {...otherProps}
            classes={{
              root: classes.fileRoot
            }}
            publicId={cloudinaryPublicId}
            title={title}
          />
        )
      }
    } else if (isPdf) {
      let pages = []
      if (selectedPages) {
        pages = selectedPages
      } else if (file.selectedPages) {
        pages = file.selectedPages
      } else if (file.pages) {
        pages = Array.from({ length: file.pages }).map((item, index) => index)
      } else {
        pages = [0]
      }

      content = (
        <>
          {pages.map((page) => (
            <Paper key={page} className={classes.pdfPaper}>
              {/* <PdfIcon /> */}
              <Image
                {...otherProps}
                page={page + 1}
                classes={{
                  root: classnames(classes.fileRoot, classes.image)
                }}
                publicId={`${cloudinaryPublicId}.png`}
                resourceType="image"
              />
            </Paper>
          ))}
        </>
      )
    } else if (file?.src && file.s3PublicKey) {
      content = (
        <ResponsiveImage
          file={file}
          classes={{ root: classnames(classes.fileRoot, classes.image, classes.imageRoot) }}
          onClick={otherProps.onClick}
        />
      )
    } else if (cloudinaryPublicId) {
      // if (file.type.startsWith('image')) {
      content = (
        <Image
          {...otherProps}
          // loading={'lazy'}
          publicId={cloudinaryPublicId}
          title={title}
          classes={{
            root: classnames(classes.fileRoot, classes.image)
          }}
        />
      )
    } else if (file.src) {
      content = (
        <img
          src={file.src}
          // loading={'lazy'}
          className={classnames(classes.fileRoot, classes.image)}
          onClick={otherProps.onClick}
        />
      )
    }
  }

  if (content) {
    return (
      <div
        className={classnames({
          [classes.root]: true,
          [classes.clickable]: !!otherProps.onClick,
          [className]: !!className,
          [classes.uploading]: !!uploading
        })}
        data-testid="file-preview"
      >
        {content}
        {showOverlay && <Overlay classes={classes} title={file.title} caption={file.caption} fileType={file.type} />}
        {videoThumbnail && !uploading && (file.type ?? 'image').startsWith('video') && (
          <VideoOverlay classes={classes} />
        )}
        {uploading && (
          <Spinner
            className={classes.uploadProgress}
            fullWidth={true}
            fullHeight={true}
            data-testid="file-preview-upload-spinner"
          />
        )}
      </div>
    )
  }

  return null
}

function VideoOverlay(props: { classes: any }) {
  const { classes } = props

  return (
    <div className={classes.videoOverlay}>
      <Avatar>
        <PlayArrow color="inherit" />
      </Avatar>
    </div>
  )
}

function Overlay(props: { title?: string; caption?: string; fileType: string; classes: any }) {
  const { classes, title, caption, fileType } = props

  if (fileType.startsWith('image') && (title || caption)) {
    return (
      <div className={classes.textOverlay}>
        {title && (
          <Typography classes={{ root: classes.title }} variant="h5">
            {title}
          </Typography>
        )}
        {caption && (
          <Typography classes={{ root: classes.caption }} variant="subtitle1">
            {caption}
          </Typography>
        )}
      </div>
    )
  }

  return null
}

export default FilePreview

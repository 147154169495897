import type ApolloCache from '@wora/apollo-cache'
import type { CacheProxy } from '.'
import { getOperationName } from 'apollo-link'
import type { DocumentNode } from 'graphql'

const RE_QUERY_VARIABLES = /({.*})/g

/**
 * Helper function for updating all queries in the cache with any variables. The update function
 * will run for each found query and write it with cache.writeQuery
 **/
export function updateQueries<T>(
  proxy: CacheProxy,
  {
    query,
    update
  }: {
    query: DocumentNode
    update: (data: T) => T
  }
) {
  const cache = proxy as ApolloCache

  // get all queries from cache, pull out the ones that match
  // this will look something like ["$ROOT_QUERY.searchQuotes({\"limit\": 10})"]
  const queryKeys = cache.cache.getAllKeys().filter((key) => key.startsWith(`$ROOT_QUERY.${getOperationName(query)}`))

  // process every matching query
  queryKeys.forEach((key) => {
    // pull out the variables from the query key
    const [vars] = key.match(RE_QUERY_VARIABLES) ?? []

    if (vars) {
      const variables = JSON.parse(vars)
      const data = cache.readQuery<T>({ query, variables })
      cache.writeQuery<T>({
        query,
        variables,
        data: update(data)
      })
    }
  })
}

import type { LineItem, OptionsDocument, OrderItem, QuoteOptions } from 'paintscout'
import type { RenderableItem, QuoteItemConsumer, UpdateableProduct } from '../../index'
import get from 'lodash/get'
import { getProductLabel, roundPrice } from '../../util'
import { getObjectLabels } from '../../../util'

export function trimLineItem(
  item: LineItem & OrderItem,
  options: QuoteOptions,
  consumer: QuoteItemConsumer,
  clientOptions: OptionsDocument
): RenderableItem {
  const additionalFields: { [key: string]: any } = { materials: [] }
  const { currency } = getObjectLabels({ options: clientOptions })
  if (consumer === 'crew') {
    additionalFields.crewNote = item.crew_note
    additionalFields.clientNote = item.client_note
  } else if (consumer === 'customer' && item.client_note) {
    additionalFields.clientNote = item.client_note
  }

  const price = get(item, 'totals.afterMaterials', item.price)
  const hours = item?.hours ?? 0

  additionalFields.painting = hours
  additionalFields.price = Number(roundPrice(item?.price ?? 0))

  const descriptionComponents = [item.description]

  if (consumer === 'crew' && item.materials) {
    item.materials.forEach((material: UpdateableProduct) => {
      const priceValue = material.totalPrice.useCustom ? material.totalPrice.custom : material.totalPrice.default

      const m = [
        material.label,
        material.quantity,
        priceValue && !options?.hideWorkOrderMaterialCost ? `${currency.symbol}${priceValue.toFixed(2)}` : ''
      ]
        .filter((i) => i)
        .join(' - ')

      const unitLabel = material?.unitLabel ?? 'gal'

      descriptionComponents.push('<p>' + m + '</p>')

      additionalFields.materials.push({
        key: material.key,
        label: getProductLabel(material),
        volume: material.quantity,
        unitPrice: material.price?.useCustom ? material.price?.custom : material.price.default,
        unitLabel,
        totalPrice: priceValue
      })
    })
  }

  if (consumer === 'customer' && item.client_note) {
    descriptionComponents.push(item.client_note)
  }

  const description = descriptionComponents.filter((i) => i).join('\n')
  let name = item.name
  const displayHours =
    options.showWorkOrderAreaValues && options.showWorkOrderSubstrateValues && item.hoursPerUnit
      ? ` @ ${item.hoursPerUnit}hr`
      : ''

  const quantity = item.quantity !== undefined ? parseFloat(item.quantity as any) : 0

  // add quant to quote if not hidden, greater than 0, and not equal to 1
  if (
    consumer === 'customer' &&
    item.calculateBy === 'quantity' &&
    !item.hideQuantity &&
    quantity > 0 &&
    quantity !== 1
  ) {
    name = `${item.name} x ${item.quantity}`
  }

  // always add quant to work order as hours makes no sense without it
  if (consumer === 'crew') {
    name =
      item.calculateBy === 'quantity' && quantity > 0 ? `${item.name} x ${item.quantity}${displayHours}` : item.name
  }

  return {
    key: item.key,
    type: item.type,
    quoteType: item.quoteType,
    name: name,
    files: item.files,
    images: item.images,
    description: { useCustom: false, default: description, custom: null },
    value: consumer === 'crew' ? hours : price,
    additionalFields,
    products: item?.materials ?? [],
    source: item?.source ?? { provider: '', id: '', value: '', label: '' }
  }
}

import React from 'react'
import type { RouteProps } from 'react-router-dom'
import { Route } from 'react-router-dom'
import ViewProvider from './ViewProvider'

export default function ClientRoute({ children, ...props }: Omit<RouteProps, 'component'>) {
  return (
    <Route {...props}>
      <ViewProvider>{children}</ViewProvider>
    </Route>
  )
}

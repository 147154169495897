import React from 'react'
import type { ButtonProps } from '../Button'
import Button from '../Button'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles<Theme, LearnMoreLinkProps>((theme: Theme) => ({
  root: {
    paddingTop: '0.1em',
    paddingBottom: '0.1em',
    paddingLeft: 0,
    paddingRight: 0
  },
  link: {
    textDecoration: 'none !important',
    color: (props) => props.linkColor ?? theme.palette.primary.main
  }
}))

export interface LearnMoreLinkProps extends ButtonProps {
  link?: string
  label?: string
  linkColor?: string
}

export function LearnMoreLink(props: LearnMoreLinkProps) {
  const { link, label, linkColor: _linkColor, ...buttonProps } = props
  const classes = useStyles(props)
  return (
    <Button
      {...buttonProps}
      className={classes.root}
      variant="text"
      size="small"
      onClick={buttonProps.onClick ? buttonProps.onClick : null}
    >
      {link ? (
        <a className={classes.link} href={link} target="_blank" rel="noreferrer">
          {label ? label : 'Learn More'}
        </a>
      ) : (
        <>{label ? label : 'Learn More'}</>
      )}
    </Button>
  )
}

/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'

// Set the correct expiry date for quote or invoice
export function setExpiryDate(args: { quote: QuoteDocument; expiryDate: number }): QuoteDocument {
  const { quote, expiryDate } = args

  const res = {
    ...quote,
    dates: {
      ...quote.dates,
      expiryDate
    }
  }

  return res
}

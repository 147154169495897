import React from 'react'
import type { FieldProps } from 'formik'
import type { SelectProps } from '../Select'
import Select from '../Select'

export interface FormikSelectProps extends FieldProps<any>, Omit<SelectProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikSelect = ({ field, ...props }: FormikSelectProps) => {
  return <Select {...field} {...props} />
}

export default FormikSelect

import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fill: {
    fill: theme.palette.primary.main
  },
  paper: {
    fill: '#fff',
    stroke: theme.palette.primary.main,
    strokeWidth: 5
  }
}))

export default function PicturesThumbnail({ ...props }) {
  const classes = useStyles(props)

  return (
    <svg viewBox="0 0 150 200">
      <rect x={0} y={0} width={150} height={200} className={classes.paper} />

      <rect x={15} y={20} width={45} height={20} className={classes.fill} />
      <rect x={15} y={50} width={25} height={25} className={classes.fill} />
      <rect x={45} y={50} width={25} height={25} className={classes.fill} />
      <rect x={75} y={50} width={25} height={25} className={classes.fill} />
      <rect x={105} y={50} width={25} height={25} className={classes.fill} />

      <rect x={15} y={100} width={45} height={20} className={classes.fill} />
      <rect x={15} y={130} width={25} height={25} className={classes.fill} />
      <rect x={45} y={130} width={25} height={25} className={classes.fill} />
      <rect x={75} y={130} width={25} height={25} className={classes.fill} />

      <rect x={15} y={160} width={25} height={25} className={classes.fill} />
      <rect x={45} y={160} width={25} height={25} className={classes.fill} />
    </svg>
  )
}

import type { Group, OptionsDocument, QuoteDocument } from 'paintscout'
import type { RenderableItem } from '../../items'
import { getItem } from '../../items/get-items'

export function trimGroup(group: Group, options: OptionsDocument, quote: QuoteDocument): RenderableItem {
  // get combined file keys from children
  const combinedFiles = (group.children as string[])
    .map((key: string) => {
      const groupItem = getItem({ quote, options, key })
      return groupItem
    })
    .reduce((childFiles: string[], child) => {
      if (child && child.files) {
        return [...childFiles, ...(child.files ?? [])]
      }

      return childFiles
    }, [])

  return {
    key: group.key,
    type: group.type,
    name: group.label,
    files: [...combinedFiles, ...(group.files ?? [])],
    description: {
      ...group.description,
      default: group.description?.default?.toString(),
      custom: group.description?.custom?.toString()
    },
    value: group.price.useCustom ? group.price.custom : group.price.default
  }
}

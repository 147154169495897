import React, { useState } from 'react'
import type { DialogProps } from '@ui/paintscout'
import { InputLabel, SingleDatePicker, useUser } from '@ui/paintscout'
import { KeypadInputField } from '@ui/paintscout'
import { useClientOptions } from '@ui/paintscout'
import { InputField, PhoneNumberInput } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@ui/paintscout'
import type { Moment } from 'moment'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { parseNumber } from '@paintscout/util/calculator'
import { useQuote } from '../../context'
import { formatCurrency, getObjectLabels } from '@paintscout/util/builder'
import { makeStyles } from '@material-ui/core'
import MockTextMessage from '../../MockTextMessage'
import classnames from 'classnames'
import { useWisetack } from '../useWisetack'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: theme.spacing(0, 3)
    }
  },
  previewWrapper: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0)
    }
  },
  link: {
    color: theme.palette.info.main
  },
  overflowVisible: {
    overflow: 'visible'
  }
}))

export interface SendWistackLinkDialogProps extends DialogProps {
  onConfirm: (
    phoneNumber: string,
    transactionAmount?: number,
    serviceCompletedOn?: number,
    purchaseId?: string
  ) => Promise<void> | void
  onCancel?: () => void
  phoneNumber?: string
  serviceDate?: number
  transactionAmount?: number
  link?: string
}

const SendWisetackLinkDialog = (props: SendWistackLinkDialogProps) => {
  const { onCancel, onConfirm, link } = props
  const classes = useStyles()
  const { quote, save: saveQuote, isDirty } = useQuote()
  const { options } = useClientOptions()
  const { user, preferences } = useUser()
  const { currency } = getObjectLabels({ options, quote })
  const { enqueueSnackbar } = useSnackbar()
  const { companyName } = options.options

  const { maximumLoanAmount } = useWisetack({
    user,
    options,
    preferences
  })

  const contactName = `${quote?.contact?.first_name ?? ''} ${quote?.contact?.last_name ?? ''}` ?? ''

  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber ?? '')
  const [transactionAmount, setTransactionAmount] = useState(
    props.transactionAmount && props.transactionAmount > maximumLoanAmount
      ? maximumLoanAmount.toString()
      : props.transactionAmount?.toString() ?? ''
  )
  const [serviceDate, setServiceDate] = useState<Moment>(
    props.serviceDate ? moment(props.serviceDate) : moment().add(quote.is_invoice ? 0 : 1, 'days')
  )

  const moreThan60DaysAway = serviceDate.diff(moment(), 'days') > 60

  const [loading, setLoading] = useState(false)

  const previewContent = `Access your application to finance your ${formatCurrency({
    options,
    value: transactionAmount
  })} purchase with ${companyName.value} here:`

  async function onSend() {
    const parsedAmount = parseNumber(transactionAmount)
    if (!transactionAmount || parsedAmount < 500) {
      enqueueSnackbar(`Transaction amount must be at least ${formatCurrency({ options, value: 500 })}`, {
        variant: 'error'
      })
      return
    }

    if (!serviceDate || moreThan60DaysAway) {
      enqueueSnackbar('Service date must be within 60 days', { variant: 'error' })
      return
    }

    setLoading(true)
    if (!!quote && !!saveQuote) {
      if (serviceDate !== moment(quote.dates.completion)) {
        await saveQuote({
          ...quote,
          dates: {
            ...quote.dates,
            completion: serviceDate.unix()
          }
        })
      } else if (isDirty) {
        await saveQuote(quote)
      }
    }

    await onConfirm(phoneNumber, parseNumber(transactionAmount), serviceDate.unix())
    setLoading(false)
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = parseNumber(e.target.value)
    if (parsed >= maximumLoanAmount) {
      setTransactionAmount(maximumLoanAmount.toString())
    } else {
      setTransactionAmount(e.target.value)
    }
  }

  const disableButton = !phoneNumber || !transactionAmount

  return (
    <Dialog fullWidth maxWidth={'md'} {...props} classes={{ paper: classes.overflowVisible }}>
      <DialogTitle>
        <Typography variant="h3">Text Financing Application</Typography>
      </DialogTitle>
      <DialogContent className={classnames(classes.content, classes.overflowVisible)}>
        <Grid container spacing={1} alignContent="flex-start">
          <Grid item xs={12}>
            <InputField disabled fullWidth label={'Contact'} value={contactName} />
          </Grid>
          <Grid item xs={12}>
            <InputField
              disabled={loading}
              placeholder="012.345.6789"
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
              inputComponent={PhoneNumberInput}
              inputProps={{
                separator: options?.options?.phoneNumbers?.separator
              }}
              id="phone_number"
              label="phone number"
              value={phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <KeypadInputField
              toolTip={
                parseNumber(transactionAmount) > maximumLoanAmount
                  ? `Wisetack transactions cannot exceed ${formatCurrency({ options, value: maximumLoanAmount })}. The remaining balance will need to be paid with another payment method.`
                  : undefined
              }
              label={`amount (${currency.symbol})`}
              value={transactionAmount}
              placeholder="Estimated Service Amount..."
              name={'transaction-amount'}
              onChange={handleAmountChange}
              fullWidth={true}
              format={'price'}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SingleDatePicker
              date={serviceDate}
              label="Estimated Service Completion Date"
              disabled={loading}
              onChange={(date: Moment) => {
                setServiceDate(moment(date.unix() * 1000))
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.previewWrapper} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <InputLabel>Text Preview</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <MockTextMessage>
              <Typography variant="body2">
                {previewContent} <span className={classes.link}>{link ?? '[link generated by Wisetack]'}</span>
              </Typography>
            </MockTextMessage>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        showBorder={true}
        leftButton={
          <Button onClick={onCancel} variant={'text'}>
            Cancel
          </Button>
        }
      >
        <Button disabled={disableButton} loading={loading} variant={'contained'} onClick={onSend}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendWisetackLinkDialog

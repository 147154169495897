import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import Highlight from '../../Highlight'
import Typography from '../../Typography'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityContent from '../ActivityContent'
import ActivityFooter from '../ActivityFooter'
import ActivityOwner from '../ActivityOwner'
import ActivityTitle from '../ActivityTitle'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import QuoteLabel from '../QuoteLabel'
import HtmlContent from '../../HtmlContent'
import type { SentActivityDetails } from '@paintscout/api'

const useStyles = makeStyles<Theme, ActivityTypeComponentProps<SentActivityDetails>>((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  email: {
    maxHeight: 150,
    overflowY: 'auto'
  },
  emailTo: {},
  emailSubject: {
    fontWeight: theme.typography.fontWeightMedium
  },
  emailContent: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    whiteSpace: 'pre-line',
    wordBreak: 'break-all',

    marginTop: theme.spacing(0.5)
  }
}))

export default function QuoteSentActivity(props: ActivityTypeComponentProps<SentActivityDetails>) {
  const { activity, showDocTypeDetails, showContent } = props
  const classes = useStyles(props)
  const { email, isReminder, isWorkOrder } = activity.details
  const to = email?.to ?? []
  const subject = email?.subject ?? ''
  const content = email?.content ?? ''

  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            {isWorkOrder ? <Highlight>Work Order</Highlight> : <QuoteLabel activity={activity} />}
            {isReminder && ' Reminder'} was <Highlight>sent</Highlight>
          </>
        ) : (
          <>
            <Highlight>
              {isWorkOrder && 'Work Order '}
              {isReminder && 'Reminder '}Sent
            </Highlight>
          </>
        )}
        <ActivityOwner activity={activity} />
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {showContent && (
        <ActivityContent className={classes.content}>
          <div className={classes.email}>
            <Typography variant="body1" component="h5" className={classes.emailSubject}>
              {subject}
            </Typography>
            <Typography variant="subtitle2" component="h4" className={classes.emailTo}>
              to {to.join(', ')}
            </Typography>
            <HtmlContent content={content} classes={{ root: classes.emailContent }} />
          </div>
        </ActivityContent>
      )}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import ArchiveIcon from '@material-ui/icons/Archive'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopy'
import GroupIcon from '@material-ui/icons/FormatIndentIncrease'
import ConvertIcon from '@material-ui/icons/SwapHoriz'
import { useUser, BulkActionBar, BulkActionButton } from '@ui/paintscout'
import type { QuoteItemSection, RenderableItem } from '@paintscout/util/builder'
import { getFeatures, getObjectLabels, isSplitItem, getQuoteOptions } from '@paintscout/util/builder'
import get from 'lodash/get'

import type { OptionsDocument, QuoteDocument } from 'paintscout'
import React from 'react'

export interface QuoteBulkActionsProps {
  options: OptionsDocument
  quote: QuoteDocument
  tableView: string
  getSelectedItems: (args?: { section?: QuoteItemSection }) => RenderableItem[]
  getSelectedItemsByType: (type: string) => RenderableItem[]
  onBulkActionClick: (action: string, previousSection?: string) => (selectedItems: RenderableItem[]) => void
  onDeselectAll: () => void
}

export default function BulkActions({
  options,
  quote,
  tableView,
  getSelectedItems,
  getSelectedItemsByType,
  onBulkActionClick,
  onDeselectAll
}: QuoteBulkActionsProps) {
  const { isViewer, isCrew } = useUser()
  if (getSelectedItems().length === 0) {
    return null
  }

  const features = getFeatures({ options })
  const { allowQuoteAdditionalWork } = getQuoteOptions({ options })

  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })

  const selectedGroups = getSelectedItemsByType('group')
  const selectedAreas = getSelectedItemsByType('area')
  const selectedSubstrates = getSelectedItemsByType('substrate')
  const selectedLineItems = getSelectedItemsByType('line_item')
  const selectedNonGroups = [...selectedAreas, ...selectedSubstrates, ...selectedLineItems]

  const selectedBidItems = getSelectedItems({ section: 'bid' })
  const selectedAdditionalItems = getSelectedItems({ section: 'additional' })
  const selectedPendingItems = getSelectedItems({ section: 'pending' })
  const selectedOptionItems = getSelectedItems({ section: 'options' })
  const selectedArchivedItems = getSelectedItems({ section: 'archived' })
  const selectedAddedOptionItems = getSelectedItems({ section: 'added-options' })

  const hasSelectedBid = selectedBidItems.length > 0
  const hasSelectedAdditional = selectedAdditionalItems.length > 0
  const hasSelectedPending = selectedPendingItems.length > 0
  const hasSelectedOptions = selectedOptionItems.length > 0
  const hasSelectedArchived = selectedArchivedItems.length > 0
  const hasSelectedAddedOptions = selectedAddedOptionItems.length > 0

  const groupsFeature = get(features, 'quotes.groups.enabled')
  const additionalWorkFeature = get(features, 'quotes.additionalWork')
  const convertToLineItemFeature = get(features, 'quotes.convertToLineItem')
  const bulkMaterialsEditingFeature = get(features, 'quotes.bulkMaterialsEditing')
  const hasOptionsApproval = options?.options?.optionsApproval?.enabled

  const hasAdditionalWork = (quote.is_invoice || allowQuoteAdditionalWork) && additionalWorkFeature

  const selectedSections = [
    hasSelectedBid && 'bid',
    hasSelectedAdditional && 'additional',
    hasSelectedPending && 'pending',
    hasSelectedOptions && 'options',
    hasSelectedArchived && 'archived',
    hasSelectedAddedOptions && 'added-options'
  ].filter((item) => item)

  const selectedSectionCount = selectedSections.length

  return (
    <BulkActionBar selectedItems={getSelectedItems()} itemName={'Item'} onDeselectAll={onDeselectAll}>
      {(allowCopy() || allowUngroup()) && (
        <BulkActionButton icon={CopyIcon} onClick={onBulkActionClick('copy')}>
          Copy
        </BulkActionButton>
      )}
      {allowMoveToBid() && (
        <BulkActionButton icon={AddIcon} onClick={onBulkActionClick('move-to-bid', selectedSections?.[0])}>
          Move to {objectLabels.quote.value}
        </BulkActionButton>
      )}
      {allowMoveToPending() && (
        <BulkActionButton icon={AddIcon} onClick={onBulkActionClick('move-to-pending', selectedSections?.[0])}>
          Move to {objectLabels.additionalWork.value}
        </BulkActionButton>
      )}
      {allowMakeOption() && (
        <BulkActionButton icon={AddIcon} onClick={onBulkActionClick('make-option', selectedSections?.[0])}>
          Make {objectLabels.option.value}
        </BulkActionButton>
      )}
      {allowMoveToAddedOptions() && (
        <BulkActionButton icon={AddIcon} onClick={onBulkActionClick('move-to-added-options', selectedSections?.[0])}>
          Move to Added {objectLabels.option.plural}
        </BulkActionButton>
      )}
      {allowGroups() && (
        <BulkActionButton icon={GroupIcon} onClick={onBulkActionClick('group', selectedSections?.[0])}>
          Group
        </BulkActionButton>
      )}
      {allowAddToGroup() && (
        <BulkActionButton icon={GroupIcon} onClick={onBulkActionClick('addToGroup')}>
          Add to Group
        </BulkActionButton>
      )}
      {allowUngroup() && (
        <BulkActionButton icon={GroupIcon} onClick={onBulkActionClick('ungroup')}>
          Ungroup
        </BulkActionButton>
      )}
      {allowEdit() && bulkMaterialsEditingFeature && (
        <BulkActionButton icon={EditIcon} onClick={onBulkActionClick('edit-bulk')}>
          Edit Materials
        </BulkActionButton>
      )}
      {allowArchive() && (
        <BulkActionButton icon={ArchiveIcon} onClick={onBulkActionClick('archive', selectedSections?.[0])}>
          Archive
        </BulkActionButton>
      )}
      {allowDelete() && (
        <BulkActionButton icon={DeleteIcon} onClick={onBulkActionClick('delete')}>
          Delete
        </BulkActionButton>
      )}
      {allowConvertToLineItem() && (
        <BulkActionButton icon={ConvertIcon} onClick={onBulkActionClick('convert', selectedSections?.[0])}>
          Convert to Line Item
        </BulkActionButton>
      )}
    </BulkActionBar>
  )

  function allowMoveToBid() {
    if (isViewer || isCrew) {
      return false
    }
    if (hasAdditionalWork && quote.is_invoice) {
      return false
    }
    if (!hasSelectedOptions && !hasSelectedArchived) {
      return false
    }
    if (selectedSectionCount > 1) {
      return false
    }
    return true
  }

  function allowMakeOption() {
    return (
      (hasSelectedBid ||
        hasSelectedAdditional ||
        hasSelectedPending ||
        hasSelectedArchived ||
        hasSelectedAddedOptions) &&
      selectedSectionCount === 1
    )
  }

  function allowMoveToAddedOptions() {
    return hasSelectedOptions && selectedSectionCount === 1 && hasOptionsApproval
  }

  function allowMoveToPending() {
    return hasAdditionalWork && hasSelectedOptions && !hasSelectedBid && selectedSectionCount === 1
  }

  function allowConvertToLineItem() {
    const selectedItems = getSelectedItems()
    return (
      !(selectedItems.length === 1 && selectedItems[0].type === 'line_item') &&
      !(selectedSectionCount > 1) &&
      convertToLineItemFeature
    )
  }

  function allowCopy() {
    if (tableView !== 'area') {
      return false
    }

    if (selectedGroups.length > 0) {
      return false
    }

    if (hasAdditionalWork) {
      if (hasSelectedBid) {
        return false
      }
    }

    return true
  }

  function allowGroups() {
    if (!groupsFeature) {
      return false
    }
    if (selectedSectionCount > 1) {
      // Don't allow grouping from multiple sections (for now)
      return false
    }
    if (
      selectedSections?.[0] === 'pending' ||
      selectedSections?.[0] === 'additional' ||
      selectedSections?.[0] === 'archived' ||
      selectedSections?.[0] === 'added-options'
    ) {
      // Don't allow additional work or archived items to be grouped
      return false
    }
    if (selectedGroups.length > 0) {
      // Don't allow re-grouping groups
      return false
    }
    if (
      (hasSelectedBid || hasSelectedAdditional) &&
      hasAdditionalWork &&
      ['invoiced', 'partial', 'paid'].includes(quote?.status?.value)
    ) {
      return false
    }
    if (selectedAreas.length > 0 && selectedSubstrates.length > 0) {
      // Don't allow cross-view grouping
      return false
    }

    // Don't allow grouping of "split" areas (ie: just some substrates are options,
    // but not the whole area)
    if (selectedAreas.length > 0) {
      return !isSplitItem({ quote, options, keys: selectedAreas.map((item) => item.key) })
    }

    return true
  }

  function allowAddToGroup() {
    const groups = selectedGroups?.length ?? 0
    const nonGroups = selectedNonGroups?.length ?? 0
    const selectedTotal = groups + nonGroups
    if (selectedTotal <= 1) {
      return false
    }
    if (selectedGroups.length !== 1) {
      return false
    }
    const selectedGroup = quote.groups[selectedGroups[0].key]
    const allLineItemGroup = selectedGroup.children.filter((key) => !quote.lineItems[key]).length === 0
    const allLineItemsSelected = selectedNonGroups.filter((item) => item.type === 'line_item').length === nonGroups
    if (selectedGroup?.view && selectedGroup.view !== quote.defaultView && !allLineItemGroup && !allLineItemsSelected) {
      return false
    }
    if (selectedNonGroups.length === 0) {
      return false
    }
    if (selectedSectionCount !== 1) {
      return false
    }
    return true
  }

  function allowUngroup() {
    if (selectedSectionCount !== 1) {
      return false
    }
    if (selectedGroups.length !== 1) {
      return false
    }
    if (selectedNonGroups.length !== 0) {
      return false
    }
    return true
  }

  function allowEdit() {
    return true
  }

  function allowArchive() {
    if (hasSelectedArchived) {
      return false
    }
    if (selectedSectionCount > 1) {
      return false
    }
    return true
  }

  function allowDelete() {
    if (!hasSelectedArchived) {
      return false
    }
    if (selectedSectionCount !== 1) {
      return false
    }
    if (tableView !== 'area') {
      return false
    }
    if (selectedGroups.length > 0) {
      return false
    }
    return true
  }
}

import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { withStyles, createStyles, FormControl } from '@material-ui/core'
import type { SelectProps as MuiSelectProps } from '@material-ui/core/Select'
import { default as MuiSelect } from '@material-ui/core/Select'
import InputLabel from '../InputLabel'
// import FormControlLabel from '../FormControlLabel'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classnames from 'classnames'

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    flat: {},
    hasLabel: {},
    disabled: {},
    select: {
      paddingLeft: '0.5em'
    },
    selectRoot: {},
    icon: {
      width: theme.typography.pxToRem(16),
      color: theme.palette.grey[500]
    },
    outlined: {
      '&$select': {
        ...theme.typography.body1,
        borderRadius: 2,
        border: `1px solid ${theme.palette.text.primary}`,
        textTransform: 'none',
        padding: `0.75em 3em 0.75em 0.75em`,
        letterSpacing: 'initial',
        transition: 'border 0.1s, box-shadow 0.1s',
        '&:focus-within, &:active': {
          borderColor: theme.palette.primary.main,
          boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
        }
      },
      '& $icon': {
        right: '0.5em',
        top: 'calc(50% - 10px)',
        width: '0.75em',
        color: theme.typography.body1.color
      },
      '& $hasLabel': {
        marginTop: theme.spacing(-1)
      },
      '& $disabled': {
        borderColor: theme.palette.grey[500],
        '& $icon': {
          color: theme.palette.grey[500]
        },
        '& $select': {
          color: theme.palette.grey[500]
        }
      }
    }
  })
}

export interface SelectProps extends WithStyles<typeof styles>, Omit<MuiSelectProps, 'classes'> {
  label?: string
  shrinkLabel?: boolean
  /**
   * Add margin above Select as if it had a label
   */
  useLabelMargin?: boolean
}

class Select extends React.PureComponent<SelectProps> {
  public static defaultProps: Partial<SelectProps> = {
    color: 'primary',
    variant: 'standard'
  }

  public render() {
    const { variant, label, margin, required, fullWidth, useLabelMargin, classes, shrinkLabel, ...baseSelectProps } =
      this.props

    const variantClass = classes[variant]
    const disabledClass = this.props.disabled && classes.disabled
    const hasLabelClass = (label || useLabelMargin) && classes.hasLabel

    return (
      <FormControl
        classes={{ root: classnames(classes.root, variantClass) }}
        margin={margin}
        fullWidth={fullWidth}
        required={required}
      >
        {label && (
          <InputLabel
            disabled={baseSelectProps.disabled}
            shrink={
              shrinkLabel ||
              (this.props.multiple ? (this.props.value as string[]).length !== 0 : (this.props.value as string) !== '')
            }
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          {...baseSelectProps}
          // variant={variant}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          classes={{
            root: classnames(classes.selectRoot, variantClass, disabledClass, hasLabelClass),
            disabled: classes.disabled,
            select: classes.select,
            icon: classes.icon
          }}
          disableUnderline={true}
          IconComponent={KeyboardArrowDownIcon}
        >
          {this.props.children}
        </MuiSelect>
      </FormControl>
    )
  }
}

export default withStyles(styles)(Select)

/**
 * @module builder
 */

// Helper function to reformat string for special chacters
// that are encoded incorrectly
export function reformatSpecialChars(subject: string) {
  const characters = [
    ['&amp;', '&'],
    ['&#39;', "'"],
    ['&quot;', '"'],
    ['&nbsp;', ' ']
  ]
  characters.forEach((char) => {
    while (subject.indexOf(char[0]) !== -1) {
      subject = subject.replace(char[0], char[1])
    }
  })
  return subject
}

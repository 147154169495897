import isPlainObject from 'lodash/isPlainObject'
import type { AreaDimensions, AreaSubstrate, QuoteArea, QuoteDocument, QuoteTotals } from 'paintscout'
import { isInAreaGroup } from '../../builder/quote/area-substrates/is-in-area-group'
import { getItemSection } from '../../builder/quote/util/get-item-section/get-item-section'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'
import { add } from '../util/add'
import { parseNumber } from '../util/parse-number'
import { addDimensions } from './add-dimensions'

export function subTotal(quote: QuoteDocument): QuoteTotals {
  let updatedTotals = { ...quote.totals, prep: 0, hours: 0, price: 0 }

  if (!quote.areas || !quote.order) {
    return updatedTotals
  }

  const { useAllAreasForTotalDimensions } = getQuoteOptions({ quote })

  quote.order.area.forEach((item) => {
    if (item._deleted || item.type !== 'area') {
      return
    }

    const area = quote.areas[item.key]

    if (!area || !isPlainObject(area) || area._deleted || isInAreaGroup({ quote, areaKey: area.key })) {
      return
    }

    // Update totals sections w/ area section totals
    if (area.totals.pending) {
      updatedTotals.pending = add(updatedTotals.pending, area.totals.pending, quote)
    }
    if (area.totals.additionalWork) {
      updatedTotals.additionalWork = add(updatedTotals.additionalWork, area.totals.additionalWork, quote)
    }
    if (area.totals.addedOptions && !item.parent) {
      updatedTotals.addedOptions = add(updatedTotals.addedOptions, area.totals.addedOptions, quote)
    }

    // Add area values to totals in correct section based on area section
    const { hours, prep, price, materials } = getAreaValues(area)
    let targetProperty = null
    if (area.pending && !(quote?.version >= 3)) {
      targetProperty = 'pending'
    } else if (area.additionalWork && !(quote?.version >= 3)) {
      targetProperty = 'additionalWork'
    } else if (area.addedOption) {
      targetProperty = 'addedOptions'
    }

    // If area is in bid section:
    if (area.use_total) {
      // Update quote.totals w/ area values
      updatedTotals = add(
        updatedTotals,
        {
          hours,
          prep,
          price: item.parent ? 0 : price
        },
        quote
      )

      // Add to materials
      if (!updatedTotals.materials) {
        updatedTotals.materials = item.parent ? 0 : materials
      } else {
        updatedTotals.materials += item.parent ? 0 : materials
      }

      // Add area totals to quote type group section
      const group = getAreaGroupSection({ quote, area })
      updatedTotals.groups[group] = add(
        updatedTotals.groups[group] || { hours: 0, prep: 0, price: 0 },
        {
          hours,
          prep,
          price: item.parent ? 0 : price
        },
        quote
      )

      // Add area or substrate dimensions to total
      if (useAllAreasForTotalDimensions) {
        const areaDimensions = getAreaDimensions(area)

        updatedTotals.dimensions = addDimensions({ total: updatedTotals.dimensions, area: areaDimensions })
      } else {
        const areaSubstrateDimensions = getAreaSubstrateDimensions(area)

        updatedTotals.dimensions = addDimensions({ total: updatedTotals.dimensions, area: areaSubstrateDimensions })
      }
    } else if (targetProperty) {
      // Just add to totals section if not in bid section
      updatedTotals[targetProperty] = add(
        updatedTotals[targetProperty],
        {
          hours,
          prep,
          price: item.parent ? 0 : price,
          materials
        },
        quote
      )
    }
  })

  return updatedTotals
}

export function getAreaDimensions(area: QuoteArea): Omit<AreaDimensions, 'length' | 'width' | 'height'> {
  const areaDimensions =
    area.area_type?.value === 'surface'
      ? {
          sqft: area.dimensions?.sqft ?? 0,
          lnft: area.dimensions?.lnft ?? 0,
          sqft_walls: 0,
          sqft_ceiling: 0,
          sqft_floor: 0
        }
      : {
          sqft_walls: area.dimensions?.sqft_walls ?? 0,
          sqft_ceiling: area.dimensions?.sqft_ceiling ?? 0,
          sqft_floor: area.dimensions?.sqft_floor ?? 0,
          lnft: area.dimensions?.lnft ?? 0,
          sqft: 0
        }

  return areaDimensions
}

export function getAreaSubstrateDimensions(area: QuoteArea): Omit<AreaDimensions, 'length' | 'width' | 'height'> {
  const tempSubs = []
  Object.keys(area.substrates).forEach((key) => {
    tempSubs.push(area.substrates[key])
  })
  const substrates = tempSubs.flat() as AreaSubstrate[]
  const workedAreaDimensions = {
    sqft: [],
    lnft: [],
    sqft_walls: [],
    sqft_ceiling: [],
    sqft_floor: []
  }

  const getDimensions = (sub: AreaSubstrate, area: QuoteArea) => {
    const rateType = sub.rate.rate_type
    let activeDimension = 'sqft'
    if (area.area_type.value === 'room') {
      activeDimension = rateType
    } else if (rateType === 'lnft') {
      activeDimension = 'lnft'
    }
    if (workedAreaDimensions[rateType] && ['bid', 'additional'].includes(getItemSection(sub))) {
      workedAreaDimensions[rateType].push(
        sub.override_dimensions ? sub.override_dimensions_value : area.dimensions?.[activeDimension]
      )
    }
  }

  substrates.forEach((sub) => {
    getDimensions(sub, area)
  })

  const getMax = (arr: number[]) => {
    if (!arr.length) return 0
    else return arr.reduce((a, b) => Math.max(a, b))
  }

  const largestWorkedDimensions = {}
  Object.keys(workedAreaDimensions).forEach((sub) => (largestWorkedDimensions[sub] = getMax(workedAreaDimensions[sub])))

  return largestWorkedDimensions
}

function getAreaValues(area: QuoteArea): { hours: number; prep: number; price: number; materials: number } {
  let hours = 0
  if (area.totals.override_hours) {
    hours = parseNumber(area.totals.override_hours_value)
  } else {
    hours = parseNumber(area.totals.hours)
  }

  let prep = 0
  if (area.totals.override_prep) {
    prep = parseNumber(area.totals.override_prep_value)
  } else {
    prep = parseNumber(area.totals.prep)
  }

  let price = 0
  if (area.totals.override_price) {
    price = parseNumber(area.totals.override_price_value)
  } else {
    price = parseNumber(area.totals.price)
  }

  let materials = 0
  if (area.totals.override_materials) {
    materials = parseNumber(area.totals.override_materials_value)
  } else {
    materials = parseNumber(area.totals.materials)
  }

  return { hours, prep, price, materials }
}

function getAreaGroupSection({ quote, area }: { quote: QuoteDocument; area: QuoteArea }): string {
  let group = null
  if (area.int_ext && area.int_ext.value) {
    group = area.int_ext.value
  } else if (quote.int_ext && quote.int_ext.value) {
    group = quote.int_ext.value
  }

  if (!group || group === 'all') {
    group = 'int'
  }
  return group
}

import { useInView as useInViewLib } from 'react-intersection-observer'
import { useEffect, useRef, useState } from 'react'
import type { InViewHookResponse, IntersectionOptions } from 'react-intersection-observer'

const loadIntersectionObserver = () => import('intersection-observer')
const hasNativeIntersectionObserver = typeof IntersectionObserver !== 'undefined'
let isIntersectionObserverAvailable = hasNativeIntersectionObserver

/**
 * React Hook to monitor the inView state of your components.
 * Call the useInView hook with the (optional) options you need.
 * It will return an array containing a ref, the inView status and the current entry.
 * Assign the ref to the DOM element you want to monitor, and the hook will report the status.
 *
 * With lazy polyfill for Safari < 12.
 */
export const useInView: typeof useInViewLib = hasNativeIntersectionObserver
  ? useInViewLib
  : (args?: IntersectionOptions) => {
      const noopRef = useRef(null)
      const result = useInViewLib(args)
      const [isIntersectionObserverReady, setIsIntersectionObserverReady] = useState<boolean>(
        isIntersectionObserverAvailable
      )
      useEffect(() => {
        if (isIntersectionObserverAvailable) {
          return
        }
        let mounted = true
        loadIntersectionObserver().then(() => {
          isIntersectionObserverAvailable = true
          if (mounted) {
            setIsIntersectionObserverReady(true)
          }
        })
        return () => {
          mounted = false
        }
      }, [])
      return isIntersectionObserverReady
        ? result
        : ({
            ...result,
            ref: noopRef as any
          } as InViewHookResponse)
    }

import type { TableCellProps } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles, styled } from '@material-ui/core/styles'
import { CustomBadge, Typography, useClientOptions, Table, TableBody, TableCell, TableRow } from '@ui/paintscout'
import type { QuoteDetails, RenderableTotals } from '@paintscout/util/builder'
import { getQuoteTypeOptions, getDetails, getFeatures } from '@paintscout/util/builder'
import { getTotals, getObjectLabels, getQuoteOptions } from '@paintscout/util/builder'
import { getQuoteTypeSubTotals } from '@paintscout/util/util/getQuoteTypeTotals/getQuoteTypeTotals'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import React, { useContext } from 'react'
import { QuoteContext } from '../context/QuoteContext'

const useStyles = makeStyles<Theme, { invoicesDialog?: boolean; isEditable?: boolean }>(
  (theme) => ({
    root: {
      display: 'grid',
      justifyContent: 'end'
    },
    tableWrapper: {
      background: theme.palette.grey['100'],
      cursor: ({ isEditable, invoicesDialog }) => (isEditable && !invoicesDialog ? 'pointer' : 'auto'),
      marginTop: ({ invoicesDialog }) => (invoicesDialog ? 0 : theme.spacing(2)),
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    },
    childWrapper: {
      background: theme.palette.grey['100'],
      width: '100%'
    },

    table: {
      overflowX: 'auto',
      borderRadius: theme.typography.pxToRem(2),
      lineHeight: '1.7',
      borderTop: 'none'
    },
    tableRow: {
      background: 'transparent',
      height: theme.typography.pxToRem(32)
    },
    tableCell: {
      textAlign: 'right',
      borderBottomWidth: '0px',
      padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(12)}`,
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    value: {
      fontWeight: theme.typography.fontWeightMedium,
      '& span': {
        fontWeight: theme.typography.fontWeightMedium
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.typography.pxToRem(8)
      }
    },
    typeGroups: {
      alignItems: 'flex-end'
    },
    wordBreak: {
      wordBreak: 'break-word'
    }
  }),
  {
    name: 'TotalTable'
  }
)

export interface TotalTableProps {
  propQuote?: QuoteDocument
  propTotals?: Partial<RenderableTotals>
  propOptions?: OptionsDocument
  showPaid?: boolean
  hideTypeGroups?: boolean
  children?: JSX.Element
}

function TotalTable({ propQuote, propTotals, propOptions, showPaid, hideTypeGroups, children }: TotalTableProps) {
  const { quote: contextQuote, isEditable, onEditDetails } = useContext(QuoteContext)
  const quote = propQuote ?? contextQuote
  const { options: clientOptions } = useClientOptions()
  const options = propOptions ?? clientOptions
  const { allowQuoteAdditionalWork, splitSubtotal, surcharge } = getQuoteOptions({ quote, options })
  const classes = useStyles({ invoicesDialog: !!propTotals, isEditable })
  const surchargeFeature = getFeatures({ options })?.surcharge

  const totals = propTotals ? (propTotals as RenderableTotals) : getTotals({ quote, consumer: 'customer', options })
  const hasAdditionalWork = quote.is_invoice || allowQuoteAdditionalWork
  const showSurcharge = surchargeFeature && !!totals.serviceFees?.surcharge
  const showAmountPaid = (!!totals.amountPaid || quote.is_invoice || showPaid) && showPaid !== false
  const showTypeGroups = !hideTypeGroups && quote?.int_ext?.value === 'all' && options?.options?.quotes?.groupHours

  if (!totals || Object.keys(totals).length === 0) {
    return null
  }

  const details = getDetails({ quote, options })
  return (
    <div className={classes.root}>
      <div onClick={isEditable && !propTotals ? onEditDetails : null} className={classes.tableWrapper}>
        {showTypeGroups && (
          <>
            <Table>
              <TableBody>
                <TypeGroups quote={quote} options={options} />
              </TableBody>
            </Table>
            <hr />
          </>
        )}
        <Table className={classes.table}>
          <TableBody>
            <GroupRows totals={totals} />
            {splitSubtotal && !totals.customSubTotal ? (
              <SplitSubtotalRow details={details} />
            ) : (
              <SubTotalRow totals={totals} isEditable={isEditable} />
            )}
            <AddedOptionsRow totals={totals} />
            {hasAdditionalWork && <AdditionalWorkRow totals={totals} />}
            <DiscountRows totals={totals} />
            <TaxRows totals={totals} />
            {showSurcharge && <SurchargeRow totals={totals} surchargePercent={surcharge.value} />}
            <TotalRow totals={totals} />
            {showAmountPaid && <AmountPaidRow totals={totals} />}
            {totals.showBalanceDue && (showAmountPaid || showSurcharge) && <BalanceDueRow totals={totals} />}
          </TableBody>
        </Table>
      </div>
      {children && <div className={classes.childWrapper}>{children}</div>}
    </div>
  )
}

const StyledTableRow = styled((props) => <TableRow {...props} noBorder />)({
  background: 'transparent',
  '@media print': {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

const StyledTableCell = styled((props: TableCellProps) => <TableCell {...props} align={props.align ?? 'right'} />)({
  padding: `0.4em 1em`,
  '@media (max-width: 599px)': {
    padding: `0.4em 0em`
  },
  '@media print': {
    margin: '0px 8px'
  }
})

function TypeGroups({ quote, options }: { quote: QuoteDocument; options: OptionsDocument }) {
  const classes = useStyles({})
  const typeTotals = getQuoteTypeSubTotals({ quote, options })

  return (
    <>
      {Object.keys(typeTotals).map((type) =>
        typeTotals[type] === 0 ? null : (
          <StyledTableRow key={type} noBorder className={classes.tableRow}>
            <StyledTableCell align="left" className={classes.groupsTile}>
              <QuoteTypeTitle type={type} options={options} />
            </StyledTableCell>
            <StyledTableCell className={classes.groupsTile}>
              <Typography
                className={classes.value}
                value={typeTotals[type]}
                format={'price'}
                showUnits
                variant={'body1'}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      )}
    </>
  )
}

function QuoteTypeTitle({ type, options }: { type: string; options: OptionsDocument }) {
  const classes = useStyles({})
  const quoteTypeOptions = getQuoteTypeOptions({ options })
  const quoteType = quoteTypeOptions.find((option) => option.value === type)
  const title = quoteType?.label ?? type.charAt(0).toUpperCase() + type.slice(1)

  return (
    <Typography className={classes.value} variant={'body1'}>
      {title}
    </Typography>
  )
}

function GroupRows({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { groups } = totals
  if (!groups) {
    return null
  }
  return (
    <>
      {groups.map((groupsItem) => (
        <StyledTableRow key={groupsItem.key} noBorder className={classes.tableRow}>
          <StyledTableCell align="left">{groupsItem.description}</StyledTableCell>
          <StyledTableCell>
            <Typography
              classes={{ root: classes.value }}
              value={groupsItem.value}
              format={'price'}
              showUnits={true}
              variant={'body1'}
            />
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  )
}

function SplitSubtotalRow({ details }: { details: QuoteDetails }) {
  const classes = useStyles({})
  const { labour, materials } = details.totals
  return (
    <>
      {!!labour && (
        <StyledTableRow>
          <StyledTableCell align="left">Labor</StyledTableCell>
          <StyledTableCell>
            <Typography
              classes={{ root: classes.value }}
              value={labour}
              format={'price'}
              showUnits={true}
              variant={'body1'}
            />
          </StyledTableCell>
        </StyledTableRow>
      )}
      {!!materials && (
        <StyledTableRow>
          <StyledTableCell align="left">Materials</StyledTableCell>
          <StyledTableCell>
            <Typography
              classes={{ root: classes.value }}
              value={materials}
              format={'price'}
              showUnits={true}
              variant={'body1'}
            />
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  )
}

function SubTotalRow({ totals, isEditable }: { totals: RenderableTotals; isEditable: boolean }) {
  const classes = useStyles({})
  const { subTotal, customSubTotal, afterTax } = totals
  if (!subTotal || subTotal === afterTax) {
    return null
  }

  const subTotalContent = (
    <Typography
      classes={{ root: classes.value }}
      value={subTotal}
      format={'price'}
      showUnits={true}
      variant={'body1'}
    />
  )
  return (
    <StyledTableRow>
      <StyledTableCell align="left">Sub Total</StyledTableCell>
      <StyledTableCell data-testid="quote-sub-total">
        {customSubTotal && isEditable ? <CustomBadge>{subTotalContent}</CustomBadge> : subTotalContent}
      </StyledTableCell>
    </StyledTableRow>
  )
}

function AddedOptionsRow({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { quote } = useContext(QuoteContext)
  const { options } = useClientOptions()
  const { addedOptions } = totals

  const objectLabels = getObjectLabels({ options })

  if (!addedOptions) {
    return null
  }

  return (
    <StyledTableRow noBorder>
      <StyledTableCell align="left">{`${objectLabels.option.plural} ${
        quote.status.value === 'accepted' || quote.is_invoice ? 'Accepted' : 'Added'
      }`}</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          value={addedOptions}
          format={'price'}
          showUnits={true}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function AdditionalWorkRow({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { additionalWork } = totals
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  if (!additionalWork) {
    return null
  }

  return (
    <StyledTableRow noBorder>
      <StyledTableCell align="left">{objectLabels.additionalWork.value}</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          value={additionalWork}
          format={'price'}
          showUnits={true}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function DiscountRows({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { discounts } = totals

  if (!discounts || discounts.length === 0) {
    return null
  }

  return (
    <>
      {discounts.map((discountItem) => {
        const discountLabel = discountItem.negative ? 'Additional: ' : 'Discount: '
        if (discountItem.amount === 0) {
          return null
        }
        return (
          <StyledTableRow key={discountItem.key}>
            <StyledTableCell className={classes.wordBreak} align="left">
              {discountItem.description ? '' : discountLabel}
              {discountItem.description}
            </StyledTableCell>
            <StyledTableCell>
              <Typography
                classes={{ root: classes.value }}
                value={discountItem.amount}
                format={'price'}
                showUnits={true}
                variant={'body1'}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </>
  )
}

function TaxRows({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { taxes: tax = [], discounts, afterDiscount, afterTax } = totals

  if (!tax) {
    return null
  }

  return (
    <>
      {discounts && discounts.length !== 0 && afterDiscount !== afterTax && (
        <StyledTableRow>
          <StyledTableCell align="left">After Discount</StyledTableCell>
          <StyledTableCell>
            <Typography
              classes={{ root: classes.value }}
              format={'price'}
              showUnits={true}
              showZeros={true}
              value={afterDiscount}
              variant={'body1'}
            />
          </StyledTableCell>
        </StyledTableRow>
      )}
      {tax.map((taxItem, index) => {
        return (
          <StyledTableRow key={`tax-${index}`}>
            <StyledTableCell className={classes.wordBreak} align="left">
              {taxItem.description}
            </StyledTableCell>
            <StyledTableCell>
              <Typography
                classes={{ root: classes.value }}
                format={'price'}
                showUnits={true}
                showZeros={true}
                value={taxItem.amount}
                variant={'body1'}
              />
            </StyledTableCell>
          </StyledTableRow>
        )
      })}
    </>
  )
}

function TotalRow({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { afterTax } = totals
  if (typeof afterTax === 'undefined') {
    return null
  }

  return (
    <StyledTableRow>
      <StyledTableCell align="left">Total</StyledTableCell>
      <StyledTableCell data-testid="quote-total">
        <Typography
          classes={{ root: classes.value }}
          format={'price'}
          showUnits={true}
          value={afterTax}
          showZeros={true}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function SurchargeRow({ totals, surchargePercent }: { totals: RenderableTotals; surchargePercent: number }) {
  const classes = useStyles({})
  const surchargeAmount = Math.round((totals.serviceFees?.surcharge ?? 0) * 100) / 100

  return (
    <StyledTableRow>
      <StyledTableCell align="left">{`Credit Card Surcharge (${surchargePercent}%)`}</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          format={'price'}
          showUnits={true}
          showZeros={true}
          value={surchargeAmount || 0}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function AmountPaidRow({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { amountPaid } = totals

  return (
    <StyledTableRow>
      <StyledTableCell align="left">Amount Paid</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          format={'price'}
          showUnits={true}
          showZeros={true}
          value={amountPaid || 0}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

function BalanceDueRow({ totals }: { totals: RenderableTotals }) {
  const classes = useStyles({})
  const { balanceDue } = totals

  return (
    <StyledTableRow>
      <StyledTableCell align="left">Balance Due</StyledTableCell>
      <StyledTableCell>
        <Typography
          classes={{ root: classes.value }}
          format={'price'}
          showUnits={true}
          showZeros={true}
          value={balanceDue || 0}
          variant={'body1'}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default TotalTable

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import Typography from '../Typography'
import type { TipTapToolbarProps } from './TipTapToolbar'
const useStyles = makeStyles<Theme>((_theme) => {
  return {
    resetWrapper: {
      display: 'inline-block',
      position: 'absolute',
      right: 0,
      top: -5,
      padding: '5px',
      zIndex: 10
    }
  }
})

export function TipTapResetToolbar(props: TipTapToolbarProps) {
  const { onReset } = props
  const classes = useStyles(props)

  return (
    <>
      {!!onReset && (
        <div className={classes.resetWrapper}>
          <button
            type="reset"
            onMouseDown={(ev) => {
              ev.preventDefault()
              onReset()
            }}
          >
            <Tooltip title={'Reset to default (generated) value'}>
              <Typography variant="overline">{'(Reset)'}</Typography>
            </Tooltip>
          </button>
        </div>
      )}
    </>
  )
}

import React, { useState } from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'

import find from 'lodash/find'

import Scrollspy from 'react-scrollspy'

import { Grid, Button, IconButton, useClientOptions, PopupMenu, PopupMenuItem } from '@ui/paintscout'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import TocIcon from '@material-ui/icons/Toc'
import HamburgerIcon from '@material-ui/icons/Menu'
import { useQuote } from '../../../context/useQuote'
import PresentationNavText from '../PresentationNavText'
import PresentationAcceptButton from '../PresentationAcceptButton'
import { usePresentationNav } from '../PresentationNavContext'
import { getQuotePresentation, filterActivePresentationPages } from '@paintscout/util/builder'

const padSpacing = 2

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    appBar: {
      background: theme.palette.common.white,
      color: theme.palette.grey[700],
      '@media print': {
        display: 'none'
      }
    },

    toolbar: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      margin: '0 auto',
      width: 1150,
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },

    rightSide: {},
    leftColumn: {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      '& img': {
        maxWidth: 100,
        height: 'auto'
      },

      [theme.breakpoints.down('md')]: {
        '& img': {
          maxWidth: theme.spacing(7)
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& img': {
          maxWidth: theme.spacing(6)
        }
      }
    },

    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },

    logoWrapper: {
      maxWidth: 75,
      maxHeight: 75,
      display: 'flex',
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    },

    closedContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    left: {
      width: '55%',
      paddingRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      '& img': {
        maxHeight: theme.spacing(6),
        width: 'auto',
        marginRight: theme.spacing()
      },
      [theme.breakpoints.down('sm')]: {
        '& img': {
          marginRight: theme.spacing(0.5),
          maxHeight: 'none',
          maxWidth: theme.spacing(5)
        }
      }
    },
    right: {
      width: '45%',
      paddingLeft: theme.spacing(1)
    },

    menuButton: {
      border: `1px solid #000`,
      borderRadius: 0,
      justifyContent: 'flex-start',
      '& span': {
        width: '100%'
      },
      '& svg': {
        color: theme.palette.common.black
      }
    },
    buttonPageTitle: {
      color: theme.palette.grey[700],
      textAlign: 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
})

export interface PresentationNavMobileProps extends WithStyles<any> {}

export default function PresentationNavMobile({ ...props }: PresentationNavMobileProps) {
  const classes = useStyles(props)

  const { options } = useClientOptions()
  const { quote } = useQuote()
  const logo = options?.options?.logo?.value

  const [currentPageKey, setCurrentPageKey] = useState()

  const {
    open,
    onOpen,
    onClose,
    isSinglePage,
    view,
    onPageClick,
    // currentPage: currentPageKey,
    popupMenu
  } = usePresentationNav()

  const presentation = getQuotePresentation({ quote, options, view })

  const currentPage = find(presentation.pages, { key: currentPageKey })

  let activeItems = []

  if (isSinglePage) {
    activeItems = presentation.pages[0].sections.filter((section) => section.active)
  } else {
    activeItems = presentation.pages.filter(filterActivePresentationPages)
  }

  return (
    <>
      <AppBar position="fixed" classes={{ root: classes.appBar }}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.closedContainer}>
            <div className={classes.left}>
              <>
                {!isSinglePage && (
                  <>
                    {popupMenu ? (
                      <PopupNav presentation={presentation} onPageClick={onPageClick} />
                    ) : (
                      <MenuButton
                        presentation={presentation}
                        activeItems={activeItems}
                        currentPage={currentPage}
                        onChange={(id) => {
                          setCurrentPageKey(id)
                        }}
                        onOpen={onOpen}
                      />
                    )}
                  </>
                )}
                {isSinglePage && !popupMenu && (
                  <MenuButton
                    presentation={presentation}
                    activeItems={activeItems}
                    currentPage={currentPage}
                    onChange={(id) => {
                      setCurrentPageKey(id)
                    }}
                    onOpen={onOpen}
                  />
                )}
              </>
            </div>

            <div className={classes.right}>
              <PresentationAcceptButton showPrice={!options.options.quotes.hideTotalOnPresentation} type={'closed'} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={onClose} anchor={'top'}>
        <div className={classes.root}>
          <Grid container spacing={padSpacing}>
            <Grid item xs={6} classes={{ root: classes.leftColumn }}>
              <Grid container spacing={3} wrap={'nowrap'}>
                <Grid item>
                  <PresentationNavText />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} classes={{ root: classes.rightColumn }}>
              <PresentationAcceptButton showPrice={!options.options.quotes.hideTotalOnPresentation} type={'open'} />
              <div className={classes.logoWrapper}>
                <img src={logo} />
              </div>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </>
  )
}

function MenuButton({ presentation, currentPage, activeItems, onChange, onOpen, ...props }) {
  const classes = useStyles(props)
  return (
    <Scrollspy
      componentTag={'div'}
      style={{ width: '100%' }}
      items={activeItems.map((item) => item.key)}
      offset={-150}
      onUpdate={(element) => {
        onChange(element?.id)
      }}
    >
      {presentation?.advanced?.infiniteScroll ? (
        <IconButton onClick={onOpen}>
          <HamburgerIcon />
        </IconButton>
      ) : (
        <Button
          fullWidth={true}
          flipIconSide={false}
          className={classes.menuButton}
          variant={'outlined'}
          icon={TocIcon}
          onClick={onOpen}
        >
          <span className={classes.buttonPageTitle}>{currentPage?.title ?? 'Menu'}</span>
        </Button>
      )}
    </Scrollspy>
  )
}

function PopupNav({ presentation, onPageClick, ..._props }) {
  const activePages = presentation.pages.filter(filterActivePresentationPages)

  return (
    <PopupMenu
      component={
        <Button flipIconSide={true} variant={'text'} icon={TocIcon}>
          Menu
        </Button>
      }
    >
      {activePages.map((page) => (
        <PopupMenuItem key={page.key} onClick={(ev) => onPageClick(ev, page.key)}>
          {page.title}
        </PopupMenuItem>
      ))}
    </PopupMenu>
  )
}

/*
  Other stuff we're not using but I wouldn't be surprised if it makes it's way back into things when people are looking at it:

  Hamburger button (instead of "Menu"):
  We thought this was probably unclear (fear of hamburgers)

    <IconButton onClick={onOpen}>
      <HamburgerIcon />
    </IconButton> 
*/

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import ActivityDot from './ActivityDot'
import QuoteViewedActivity from './variants/QuoteViewedActivity'
import QuoteInvoicedActivity from './variants/QuoteInvoicedActivity'
import QuoteSentActivity from './variants/QuoteSentActivity'
import QuoteDeclinedActivity from './variants/QuoteDeclinedActivity'
import QuoteAcceptedActivity from './variants/QuoteAcceptedActivity'
import QuoteErrorActivity from './variants/QuoteErrorActivity'
import QuotePaymentActivity from './variants/QuotePaymentActivity'
import QuoteAssignmentActivity from './variants/QuoteAssignmentActivity'
import QuotePaidActivity from './variants/QuotePaidActivity'
import QuoteWarningActivity from './variants/QuoteWarningActivity'
import QuoteOwnerChangedActivity from './variants/QuoteOwnerChangedActivity'
import BasicQuoteActivity from './variants/BasicQuoteActivity'
import QuoteStatusChangedActivity from './variants/QuoteStatusChangedActivity'
import QuoteStatusUpdatedActivity from './variants/QuoteStatusUpdatedActivity'
import MessageActivity from './variants/MessageActivity'
import type { Activity as ActivityInterface, ActivityDetails } from '@paintscout/api'
import type { ActivityType } from 'paintscout'
import QuoteTypeChangedActivity from './variants/QuoteTypeChangedActivity'
import { Activity as CoreActivity } from '@ui/core'
import type { ActivityProps as CoreActivityProps } from '@ui/core'
import QuoteThankYouActivity from './variants/QuoteThankYouActivity'
import QuoteThankYouDeclinedActivity from './variants/QuoteThankYouDeclinedActivity'
import QuotePaymentRequestedActivity from './variants/QuotePaymentRequestedActivity'

export interface ActivityProps extends Omit<CoreActivityProps, 'activityComponent' | 'activityDotComponent'> {
  activity: ActivityInterface
  isQuote?: boolean
}

export interface ActivityTypeComponentProps<T extends ActivityDetails = ActivityDetails>
  extends Omit<ActivityProps, 'activityComponent' | 'activityDotComponent'> {
  activity: ActivityInterface & { details: T }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dot: {
    position: 'relative',
    zIndex: 2,
    marginTop: 5,
    marginRight: theme.spacing(1),
    width: 11,
    height: 11
  }
}))

export default function Activity(props: ActivityProps) {
  const { activity, dotRef, showContent, showDocTypeDetails, actions, onAction, isQuote, ...coreProps } = props
  const classes = useStyles(props)

  const ActivityTypeComponent = getComponentForType(activity.details.activityType as ActivityType)

  if (!ActivityTypeComponent) {
    console.warn(`No component for activity type "${activity.details.activityType}"`)
    return null
  }

  return (
    <CoreActivity
      className={classes.root}
      {...coreProps}
      activityComponent={
        <ActivityTypeComponent
          isQuote={isQuote}
          activity={activity}
          showContent={showContent}
          showDocTypeDetails={showDocTypeDetails}
          actions={actions}
          onAction={onAction}
        />
      }
      activityDotComponent={<ActivityDot activity={activity} className={classes.dot} ref={dotRef} />}
    />
  )
}

function getComponentForType(type: ActivityType): React.ComponentType<ActivityTypeComponentProps<any>> {
  switch (type) {
    case 'created':
    case 'copied':
    case 'deleted':
    case 'disputed':

    case 'archived':
    case 'unarchived':
    case 'downloaded':
    case 'restored':
    case 'edited':
      return BasicQuoteActivity
    case 'message':
      return MessageActivity //
    case 'error':
      return QuoteErrorActivity //
    case 'accepted':
      return QuoteAcceptedActivity
    case 'declined':
      return QuoteDeclinedActivity
    case 'invoiced':
      return QuoteInvoicedActivity
    case 'status-changed':
      return QuoteStatusChangedActivity
    case 'status-updated':
      return QuoteStatusUpdatedActivity
    case 'sent':
      return QuoteSentActivity
    case 'thank-you-sent':
      return QuoteThankYouActivity
    case 'declined-sent':
      return QuoteThankYouDeclinedActivity
    case 'payment':
      return QuotePaymentActivity
    case 'assignment':
      return QuoteAssignmentActivity
    case 'paid':
      return QuotePaidActivity
    case 'owner-changed':
      return QuoteOwnerChangedActivity
    case 'type-changed':
      return QuoteTypeChangedActivity
    case 'viewed':
      return QuoteViewedActivity
    case 'warning':
      return QuoteWarningActivity
    case 'payment-requested':
      return QuotePaymentRequestedActivity
  }

  return null
}

import cloneDeep from 'lodash/cloneDeep'
import type { Group, OptionsDocument, QuoteDocument, QuoteTotals } from 'paintscout'
import { getItem } from '../../builder/quote/items/get-items/get-item/get-item'
import { add, parseNumber, sectionTranslate } from '../util'
import { addChildTotals } from './add-child-totals'
import groupedSubstrateMaterials from './grouped-substrate-materials'

/**
 * Sum the hours, prep, and materials of all children of a group
 * Also *will soon be* adding grouped substrate and area details to the totals
 * Adds all these values to the given totals and returns it
 */
export function sumGroupChildren({
  group,
  quote,
  options,
  currentTotals
}: {
  group: Group
  quote: QuoteDocument
  options: OptionsDocument
  currentTotals: QuoteTotals
}): QuoteTotals {
  const groupSection = group.section
  let updatedTotal = cloneDeep(currentTotals)

  const { hours, prep, materials } = group.children.reduce(
    (sum, childKey) => {
      // Update totals w/ quote type group and dimensions info from child
      updatedTotal = addChildTotals({ quote, key: childKey, currentTotals: updatedTotal })

      // Get child details to find materials, prep and hours to add to totals
      const item = getItem({ quote, options, key: childKey, sectionOverride: groupSection })
      const isSubstrateItem = !(item as any)?.totals?.materials && item?.type === 'substrate'
      let materialCost = 0
      let itemHours = 0
      let itemPrep = 0

      if (isSubstrateItem) {
        // Substrates dont have materials associated, we need to check the associated areaSubstrates in the same section as the group
        // And add those materials in
        const substrateMaterials = groupedSubstrateMaterials({
          quote,
          options,
          substrateKey: childKey,
          groupSection
        })

        materialCost += substrateMaterials
      }

      if (item && 'totals' in item && item.totals) {
        if ('materials' in item.totals && item.totals.materials) {
          materialCost =
            typeof item.totals.materials === 'number'
              ? item.totals.materials
              : item.totals.materials.useCustom
                ? item.totals.materials.custom
                : item.totals.materials.default
        }
        if ('hours' in item.totals && item.totals.hours) {
          itemHours =
            typeof item.totals.hours === 'number'
              ? item.totals.hours
              : item.totals.hours.useCustom
                ? item.totals.hours.custom
                : item.totals.hours.default
        }
        if ('prep' in item.totals && item.totals.prep) {
          itemPrep +=
            typeof item.totals.prep === 'number'
              ? item.totals.prep
              : item.totals.prep.useCustom
                ? item.totals.prep.custom
                : item.totals.prep.default
        }
      }

      return {
        hours: sum.hours + parseNumber(itemHours),
        prep: sum.prep + parseNumber(itemPrep),
        materials: sum.materials + parseNumber(materialCost)
      }
    },
    { hours: 0, prep: 0, materials: 0 }
  )

  // Update the totals with our gathered hours, prep and materials totals
  const price = group.price.useCustom ? group.price.custom : group.price.default
  const priceValue = parseNumber(price) - parseNumber(materials)

  // Add the group totals to the correct total based on section
  if (group.section === 'bid') {
    updatedTotal.groupedItems = add(
      updatedTotal?.groupedItems || { hours: 0, prep: 0, price: 0, materials: 0 },
      { price: priceValue, materials, hours, prep },
      quote
    )
  } else {
    // Will belong to 'additional', 'pending' or 'added-option
    const sectionTotalName = sectionTranslate(group.section)
    updatedTotal[sectionTotalName] = add(
      updatedTotal[sectionTotalName],
      { hours, prep, materials, price: priceValue },
      quote
    )
  }

  return updatedTotal
}

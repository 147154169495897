/**
 * @module builder
 */
import type { QuoteDocument, QuoteStatus } from 'paintscout'
import moment from 'moment'

export function setStatus(args: {
  quote: QuoteDocument
  status: QuoteStatus
  note?: string
  customerNote?: string
  acceptedVia?: 'onTheSpot' | 'editQuoteInfo' | 'customer' | ''
  updateDates?: boolean // If status note only portion updated, only update its date
}): QuoteDocument {
  const { quote, status, note, customerNote, updateDates = true } = args
  const acceptedOrInvoiced = ['accepted', 'invoiced'].includes(status)
  const acceptedVia = acceptedOrInvoiced && args.acceptedVia ? args.acceptedVia : ''

  const updated = Date.now()

  const signatures = quote?.signatures ?? {
    estimator: '',
    customer: ''
  }

  return {
    ...quote,
    status: {
      ...quote.status,
      value: status,
      updated: updateDates ? updated : quote.status.updated,
      customerNote:
        typeof customerNote !== 'undefined' && customerNote !== null
          ? { value: customerNote, updated }
          : quote.status.customerNote,
      note:
        typeof note !== 'undefined' && note !== null
          ? {
              value: note,
              updated
            }
          : quote.status.note,
      history: {
        ...(quote.status.history ?? {}),
        // set previous status
        [quote.status.value]: {
          day:
            moment(quote.status.updated ?? quote.dates.created)
              .startOf('day')
              .unix() * 1000,
          timestamp: quote.status.updated ?? quote.dates.created,
          firstTimestamp:
            quote.status.history?.[quote.status.value]?.firstTimestamp ?? quote.status.updated ?? quote.dates.created
        },
        // put in updated status(if we update status value). it'll be the same as status.updated,
        // but for followUp querying purposes, we'll also stick it here
        [status]: updateDates
          ? {
              day: moment(updated).startOf('day').unix() * 1000,
              timestamp: updated,
              firstTimestamp: quote.status?.history?.[status]?.firstTimestamp || updated
            }
          : quote.status?.history[status] && quote.status?.history[status]?.timestamp
            ? {
                ...quote.status.history[status]
              }
            : {}
      }
    },
    signatures: {
      ...quote.signatures,
      customer: acceptedOrInvoiced ? signatures.customer : ''
    },
    dates: {
      ...quote.dates,
      client: {
        ...quote?.dates?.client,
        ...(quote.dates?.client?.accepted && acceptedOrInvoiced
          ? { accepted: acceptedOrInvoiced ? quote.dates?.client?.accepted : null }
          : {})
      },
      lastStatusUpdate: updateDates ? updated : quote.dates.lastStatusUpdate
    },
    meta: {
      ...quote.meta,
      status: {
        ...quote.meta?.status,
        acceptedVia
      }
    }
  }
}

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FastField } from 'formik'

import clone from 'lodash/clone'

import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

import {
  Grid,
  Button,
  FormikInputField,
  FormikEmbedMedia,
  FormikHtmlEditor,
  FormikLayoutSelect,
  FormikSelect,
  MenuItem
} from '@ui/paintscout'

const TwoColumnForm = ({ formik }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values
  const { fields } = section

  const leftType = fields?.leftType ?? 'image'
  const rightType = fields?.rightType ?? 'text'

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container alignItems="flex-end" justifyContent="space-between" spacing={3}>
          <Grid item md={6}>
            <Field component={FormikInputField} name="section.title" label={'Title'} fullWidth={true} />
          </Grid>
          <Grid item md={6}>
            <Field
              component={FormikLayoutSelect}
              label={'Alignment'}
              name="section.fields.verticalAlign"
              vertical={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Field component={FormikSelect} name={'section.fields.leftType'} fullWidth={true} variant={'outlined'}>
              <MenuItem value="image">Image/Video</MenuItem>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button onClick={handleSwapClick} variant={'text'}>
              <SwapHorizIcon />
            </Button>
          </Grid>
          {leftType === 'text' && (
            <Grid item xs={12}>
              <FastField
                component={FormikHtmlEditor}
                name="section.fields.leftText"
                label={'Content'}
                fullWidth={true}
                debounce={true}
                toolbar={{
                  headings: true,
                  align: true
                }}
              />
            </Grid>
          )}
          {leftType === 'image' && (
            <Grid item xs={12}>
              <Field
                component={FormikEmbedMedia}
                name="section.fields.leftImage"
                maxWidth={800}
                maxHeight={1600}
                quality={1}
              />
            </Grid>
          )}
          {leftType === 'custom' && (
            <Grid item xs={12}>
              <Field
                component={FormikInputField}
                name="section.fields.leftCustom"
                fullWidth={true}
                label="Content"
                multiline={true}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Field component={FormikSelect} name="section.fields.rightType" fullWidth={true} variant={'outlined'}>
              <MenuItem value="image">Image/Video</MenuItem>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Field>
          </Grid>
          {rightType === 'text' && (
            <Grid item xs={12}>
              <FastField
                component={FormikHtmlEditor}
                name="section.fields.rightText"
                label={'Content'}
                fullWidth={true}
                debounce={true}
                toolbar={{
                  headings: true,
                  align: true
                }}
              />
            </Grid>
          )}
          {rightType === 'image' && (
            <Grid item xs={12}>
              <Field
                component={FormikEmbedMedia}
                name="section.fields.rightImage"
                maxWidth={800}
                maxHeight={1600}
                quality={1}
              />
            </Grid>
          )}
          {rightType === 'custom' && (
            <Grid item xs={12}>
              <Field
                component={FormikInputField}
                name="section.fields.rightCustom"
                fullWidth={true}
                label="Content"
                multiline={true}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )

  function handleSwapClick() {
    const newSection = clone(section)

    const leftType = section.fields.leftType
    const leftImage = section.fields.leftImage
    const leftText = section.fields.leftText
    const leftCustom = section.fields.leftCustom

    newSection.fields.leftType = section.fields.rightType
    newSection.fields.leftImage = section.fields.rightImage
    newSection.fields.leftText = section.fields.rightText
    newSection.fields.leftCustom = section.fields.leftCustom

    newSection.fields.rightType = leftType
    newSection.fields.rightImage = leftImage
    newSection.fields.rightText = leftText
    newSection.fields.rightCustom = leftCustom

    formik.setValues({ section: newSection })
  }
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(TwoColumnForm)

import React from 'react'
import { default as MuiRadio } from '@material-ui/core/Radio'
import type { RadioProps as MuiRadioProps } from '@material-ui/core/Radio'

export interface RadioProps extends MuiRadioProps {}

function Radio(props: RadioProps) {
  return <MuiRadio {...props} />
}

Radio.defaultProps = {
  color: 'primary'
}

export default Radio

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect } from 'formik'

import { Grid, Typography, useClientOptions } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'

const PicturesForm = (_: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={'body1'}>
          This section will show the pictures attached to this {objectLabels.quote.value}.
        </Typography>
      </Grid>
    </Grid>
  )
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(PicturesForm)

import type { Theme, WithStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { getQuotePresentation } from '@paintscout/util/builder'
import { useClientOptions, useMediaQuery } from '@ui/paintscout'
import findIndex from 'lodash/findIndex'
import type { PresentationOption, PresentationSection } from 'paintscout'
import React, { useEffect, useMemo } from 'react'
import { usePresentationNav } from '../'
import type { QuoteView } from '../../Quote'
import { useQuote } from '../../context'
import PresentationPages from './PresentationPages'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      '@media print': {
        display: 'block'
      },
      ...theme.typography.body1
    }
  }
})

export interface PresentationProps extends WithStyles {
  presentation?: PresentationOption
  page?: string
  isPreview?: boolean
  isCustomerView?: boolean
  isSettings?: boolean
  editFromDialog?: boolean

  onPresentationClick?: (key: string) => void
  showHelp?: boolean
  stacked?: boolean
  filterTypes?: string[]
  view?: QuoteView
}

export default function Presentation({
  onPresentationClick,
  isPreview,
  showHelp,
  stacked,
  page,
  view,
  isSettings,
  isCustomerView,
  editFromDialog,
  ...props
}: PresentationProps) {
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const filterTypes = props?.filterTypes ?? null
  const { quote, estimator } = useQuote()
  const presentation = useMemo(
    () => props.presentation ?? getQuotePresentation({ options, quote, view }),
    [props.presentation, options, quote, view]
  )
  const { onScroll, isSinglePage } = usePresentationNav()
  const isPrint = useMediaQuery('print')
  const [viewportSections, setViewportSections] = React.useState<string[]>([])

  useEffect(() => {
    if (isSinglePage) {
      onScroll?.(viewportSections[viewportSections.length - 1])
    }
  }, [viewportSections, isSinglePage])

  const pageIndex = getPageIndex(page)
  const pages = useMemo(
    () => getPages({ presentation, pageIndex, stacked, filterAllowedTypes }),
    [presentation, pageIndex]
  )

  const MemoizedPresPages = useMemo(
    () =>
      PresentationPages({
        pages,
        quote,
        options,
        estimator,
        isPrint,
        isPreview,
        showHelp,
        isSettings,
        isCustomerView,
        editFromDialog,
        onPresentationClick,
        setViewportSections
      }),
    [pages]
  )
  return <div className={classes.root}>{MemoizedPresPages}</div>

  function filterAllowedTypes(section: PresentationSection): boolean {
    return filterTypes === null || filterTypes.includes(section?.type)
  }

  function getPageIndex(page: string): number {
    if (!page) {
      return 0
    }

    const i = findIndex(presentation.pages, { key: page })
    return i >= 0 ? i : 0
  }
}

function getPages({
  presentation,
  pageIndex,
  stacked,
  filterAllowedTypes
}: {
  presentation: PresentationOption
  pageIndex: number
  stacked: boolean
  filterAllowedTypes: (section: PresentationSection) => boolean
}): PresentationSection[][] {
  const pages: PresentationSection[][] = []

  // If we're using the stacked layout, we want ALL the sections from ALL
  // the pages. Otherwise, just the sections from the active page.
  if (stacked) {
    presentation.pages.forEach((page) => {
      pages.push(
        page.sections.filter(filterAllowedTypes).map(function (section) {
          return {
            ...section,
            pageId: page.key
          }
        })
      )
    })
  } else {
    pages.push(presentation.pages[pageIndex].sections.filter(filterAllowedTypes))
  }
  return pages
}

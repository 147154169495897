import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Logo, Typography } from '@ui/paintscout'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    '@media print': {
      display: 'none'
    },
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.grey[500],
      transition: '0.5s all',
      '& svg': {
        width: 32,
        height: 'auto',
        display: 'inline-block',
        fill: theme.palette.grey[500],
        marginBottom: theme.spacing(),
        transition: '0.5s all'
      }
    },
    '& a:hover': {
      color: '#004BB4',
      '& svg': {
        fill: '#004BB4'
      }
    }
  }
}))

function Footer() {
  const classes = useStyles({})
  return (
    <footer className={classes.footer}>
      <div>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://www.paintscout.com" target="_blank">
          <Logo />
          <Typography variant={'body1'}>Amazing On-Site Estimation</Typography>
        </a>
      </div>
    </footer>
  )
}

export default Footer

import type { QuoteArea } from 'paintscout'
import { parseNumber } from '../util/parse-number'
export function calculateWallsSqft(area: QuoteArea) {
  if (area.area_type && area.area_type.value === 'surface') {
    return null
  } else {
    const width = parseNumber(area.dimensions && area.dimensions.width ? area.dimensions.width : 0)
    const length = parseNumber(area.dimensions && area.dimensions.length ? area.dimensions.length : 0)
    const height = parseNumber(area.dimensions && area.dimensions.height ? area.dimensions.height : 0)
    return 2 * (length + width) * height
  }
}

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FieldArray, FastField } from 'formik'
import {
  Button,
  Grid,
  FieldGroup,
  FormikInputField,
  FormikHtmlEditor,
  FormikEmbedMedia,
  FormikDropdownSelect,
  FormikLayoutSelect
} from '@ui/paintscout'
import { MoveRemoveButtons } from '../../util'

const ServicesForm = ({ formik }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Field component={FormikInputField} fullWidth={true} name={'section.title'} label={'Title'} />
        </Grid>
        <Grid item md={4}>
          <Field
            component={FormikDropdownSelect}
            fullWidth={true}
            name={'section.fields.width'}
            label="Width"
            options={[
              { label: 'Small', value: 'small' },
              { label: 'Medium', value: 'medium' },
              { label: 'Large', value: 'large' }
            ]}
          />
        </Grid>
        <Grid item md={4}>
          <Field component={FormikLayoutSelect} label={'Alignment'} name="section.fields.alignment" vertical={true} />
        </Grid>

        <Grid item md={12}>
          <FieldGroup label={'Services'}>
            <Grid container spacing={3}>
              <FieldArray
                name="section.fields.rows"
                render={(arrayHelpers) => (
                  <>
                    {(section?.fields?.rows ?? []).map((row, index) => (
                      <Grid item md={12} key={index}>
                        <ServicesRow section={section} arrayHelpers={arrayHelpers} index={index} />
                      </Grid>
                    ))}
                    <Grid item md={12}>
                      <Button
                        onClick={() => {
                          arrayHelpers.push({ image: null, title: '', content: '' })
                        }}
                        variant={'outlined'}
                        fullWidth={true}
                      >
                        Add Row
                      </Button>
                    </Grid>
                  </>
                )}
              />
            </Grid>
          </FieldGroup>
        </Grid>
      </Grid>
    </>
  )
}

function ServicesRow({ index, section, arrayHelpers }) {
  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <Field
          component={FormikEmbedMedia}
          name={`section.fields.rows.${index}.image`}
          maxWidth={600}
          maxHeight={600}
          buttonHeight={100}
          quality={1}
        />
      </Grid>
      <Grid item md={8}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Field
              component={FormikInputField}
              label={'Title'}
              fullWidth={true}
              name={`section.fields.rows.${index}.title`}
            />
          </Grid>
          <Grid item md={12}>
            <FastField
              component={FormikHtmlEditor}
              label={'Description'}
              name={`section.fields.rows.${index}.content`}
              fullWidth={true}
              debounce={true}
              toolbar={{
                headings: true,
                align: true
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {section?.fields?.rows.length > 1 && (
        <MoveRemoveButtons arrayHelpers={arrayHelpers} index={index} section={section} />
      )}
    </Grid>
  )
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(ServicesForm)

import React from 'react'
import type { FieldProps } from 'formik'
import type { CheckboxProps } from '../Checkbox'
import type { ConfirmationCheckboxProps } from '../ConfirmationCheckbox'
import Checkbox from '../Checkbox'
import { Field } from 'formik'
import Typography from '../Typography'
import ConfirmationCheckbox from '../ConfirmationCheckbox'
import FormControlLabel from '../FormControlLabel'
import type { FormControlLabelProps } from '../FormControlLabel'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

export interface FormikCheckboxProps
  extends FieldProps<CheckboxProps>,
    Omit<FormControlLabelProps, 'form' | 'classes'> {
  classes?: StyleClasses<typeof useStyles>
  checkboxProps?: CheckboxProps
  confirmationCheckboxProps?: ConfirmationCheckboxProps
  confirmationCheck?: boolean
}

const useStyles = makeStyles<Theme>(
  {
    root: {},
    confirmationCheckboxDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  { name: 'FormikCheckbox' }
)

/**
 * A Checkbox configured to use with Formik. It is wrapped in a FormControlLabel, so any props
 * are passed to that. If you want to pass props to the checkbox, you can use the `checkboxProps` prop
 * for ConfirmationCheckbox props including overriding ones set here use 'confirmationCheckboxProps' prop
 */
const FormikCheckbox = ({ field, confirmationCheck, ...props }: FormikCheckboxProps) => {
  const classes = useStyles(props)
  const { checkboxProps, confirmationCheckboxProps, form: _, ...labelProps } = props

  if (confirmationCheck) {
    return (
      <Field name={field.name}>
        {({ field, form }) => {
          return (
            <div className={classes.confirmationCheckboxDiv}>
              <ConfirmationCheckbox
                customOnChange={() => form.setFieldValue(field.name, !field.value)}
                checked={!!field.value}
                dialogMessage={`Would you like to toggle ${labelProps.label} to ${
                  !field.value ? 'Checked' : 'Un-Checked'
                }`}
                {...confirmationCheckboxProps}
              />
              <Typography variant={'body1'}>{labelProps.label}</Typography>
            </div>
          )
        }}
      </Field>
    )
  } else {
    return (
      <FormControlLabel {...labelProps} control={<Checkbox checked={!!field.value} {...field} {...checkboxProps} />} />
    )
  }
}

export default FormikCheckbox

/**
 * @module builder
 */
import { getQuoteOptions } from '../../quote'
import type { OptionsDocument } from 'paintscout'
import numeral from 'numeral'
import { getObjectLabels } from '../get-object-labels'

export function formatCurrency(args?: {
  options?: OptionsDocument
  value: string | number
  abbreviate?: boolean
  forceCurrency?: '$' | '€' | '£'
}) {
  const { value, options, abbreviate, forceCurrency } = args

  const { separateThousands } = getQuoteOptions({ options })
  const { currency } = getObjectLabels({ options })
  const symbol = forceCurrency ?? currency?.symbol

  // replace any non-numeric characters
  const sanitizedValue = typeof value === 'string' ? value.replace(/[^0-9.]/g, '') : value

  let format: string
  if (abbreviate)
    format = parseFloat(sanitizedValue as string) > 10000 ? '$0.00a' : separateThousands ? '$0,0.00' : '$0.00'
  if (!abbreviate) format = separateThousands ? '$0,0.00' : '$0.00'

  const formattedString = numeral(sanitizedValue).format(format)

  // Numeral doesn't like directly passing in different currency symbols
  return symbol ? formattedString.replace('$', symbol) : formattedString
}

import isEmpty from 'lodash/isEmpty'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { roundPrice } from '../../calculator'
import { filterArchived, trimArea } from '../../builder'

// import { filterArchived } from '../../builder/quote/util'
// import { trimArea } from '../../builder/quote/areas'
import type { SubstrateProducts } from '../../builder/quote/util/generate-product-description'
import { getAreaProducts, getLineItemProducts } from '../../builder/quote/util/generate-product-description'

export interface ItemProducts {
  label: string
  dimensions?: string
  products: {
    label: string
    color: string
    unitLabel: string
    volume: number
  }[]
}

export interface ProductTotal {
  label: string
  quantity: number
  unitLabel: string
}

export const getProductTotals = (quote: QuoteDocument): ProductTotal[] => {
  let products: ProductTotal[] = []

  const addProducts = (newProducts: SubstrateProducts) => {
    const keys = Object.keys(newProducts)
    keys.forEach((k) => {
      if (k && newProducts[k]) {
        const values = newProducts[k]
        const existingProduct = products.some((p) => p.label === k)
        if (existingProduct) {
          products = products.map((p) => {
            if (p.label === k) {
              return {
                ...p,
                quantity: p.quantity + values.quantity
              }
            } else {
              return p
            }
          })
        } else {
          products = [
            ...products,
            {
              label: k,
              quantity: values.quantity,
              unitLabel: values.unitLabel
            }
          ]
        }
      }
    })
  }

  Object.values(quote.areas).flatMap((area) => {
    if (!filterArchived(area)) {
      const areaProducts = getAreaProducts(area, undefined, {})
      addProducts(
        // Object.keys(areaProducts).map((key) => {
        //   return { ...areaProducts[key], label: key }
        // })
        areaProducts
      )
    }
  })

  Object.values(quote.lineItems).flatMap((lineItem) => {
    if (!filterArchived(lineItem)) {
      const lineItemProducts = getLineItemProducts(lineItem, undefined, {})
      // addProducts(
      //   Object.keys(lineItemProducts).map((key) => {
      //     return { ...lineItemProducts[key], label: key }
      //   })
      // )
      addProducts(lineItemProducts)
    }
  })

  const roundedProducts = products.map((product) => {
    return {
      ...product,
      quantity: roundPrice(product.quantity ?? 0)
    }
  })

  return roundedProducts
}

export const getItemProducts = (quote: QuoteDocument, options: OptionsDocument): ItemProducts[] => {
  const lineItemProducts = getProductsByLineItem(quote)
  const areaProducts = getProductsByArea(quote, options)

  const allProducts = [...areaProducts, ...lineItemProducts]

  const roundedProducts = allProducts.map((product) => {
    return {
      ...product,
      products: product.products.map((p) => {
        return {
          ...p,
          volume: roundPrice(p.volume)
        }
      })
    }
  })

  return roundedProducts
}

const getProductsByLineItem = (quote: QuoteDocument): ItemProducts[] => {
  return Object.values(quote.lineItems)
    .flatMap((lineItem) => {
      if (!filterArchived(lineItem)) {
        return {
          label: lineItem.name,
          products: lineItem.materials?.map((product) => {
            return {
              label: product.label,
              color: product.color ?? '',
              unitLabel: product.unitLabel ?? '',
              volume: product.quantity
            }
          })
        }
      }
    })
    .filter((item) => item?.products?.length)
}

const getProductsByArea = (quote: QuoteDocument, options: OptionsDocument): ItemProducts[] => {
  return Object.values(quote.areas)
    .flatMap((area) => {
      if (!filterArchived(area)) {
        const { subtitle } = trimArea({ item: area, quote, options, consumer: 'crew' })
        return {
          label: area.area_label.value,
          dimensions: subtitle,
          products: Object.values(area.substrates).flatMap((substrates) =>
            substrates
              .flatMap((substrate) => {
                return {
                  label: substrate.product?.product?.label,
                  color: (substrate.product?.product as any)?.color ?? '',
                  unitLabel: substrate.product?.product?.unitLabel ?? 'gal',
                  volume: substrate.product?.volume_override
                    ? substrate.product?.volume_override_value
                    : substrate.product?.volume
                }
              })
              .filter((product) => !isEmpty(product.label) && product.volume !== 0)
          )
        }
      }
    })
    .filter((product) => product?.products.length)
}

import React from 'react'
import { default as MuiCheckbox } from '@material-ui/core/Checkbox'
import type { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox'

export interface CheckboxProps extends MuiCheckboxProps {}

function Checkbox(props: CheckboxProps) {
  return <MuiCheckbox {...props} />
}

Checkbox.defaultProps = {
  color: 'primary'
}

export default Checkbox

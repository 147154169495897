import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import type { MenuItemProps as CoreMenuItemProps } from '@ui/core/MenuItem'
import CoreMenuItem from '@ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles<Theme, MenuItemProps>((theme: Theme) => ({
  root: {
    '& img': {
      fontSize: '1.5em',
      width: '1em',
      height: '1em',
      marginRight: '1rem'
    }
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: theme.typography.pxToRem(16)
  },
  iconAfter: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: theme.typography.pxToRem(16)
  }
}))

export interface MenuItemProps extends CoreMenuItemProps {
  children?: React.ReactNode
}

const MenuItem = React.forwardRef(function MenuItem({ children, ...props }: MenuItemProps, ref) {
  const classes = useStyles(props)

  return (
    <CoreMenuItem {...props} ref={ref as any} classes={classes}>
      {children}
    </CoreMenuItem>
  )
})

MenuItem.defaultProps = {
  disabled: false,
  iconAfter: false
}

export default MenuItem

import clone from 'lodash/clone'
import { parseNumber } from './parse-number'

// New add using Math.round. V3 changed to this
export function newAdd(dst: any, src: any) {
  const result = clone(dst)

  for (const key in src) {
    if (typeof src[key] === 'object' && src[key] !== null) {
      result[key] = clone(src[key])
    } else {
      const srcVal = parseNumber(src[key])

      if (!isNaN(srcVal)) {
        const dstVal = parseNumber(result[key])
        const sum = Math.round(Number(((dstVal + srcVal) * 100).toFixed(2))) / 100
        if (!isNaN(sum)) {
          result[key] = sum
        }
      }
    }
  }

  return result
}

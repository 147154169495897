import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { useClientOptions } from '@ui/paintscout'
import { Typography } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'
import { QuoteContext } from '../../../context/QuoteContext'
import QuoteHeaderCard from '../QuoteHeaderCard'

const styles = (_theme: Theme) =>
  createStyles({
    root: {},
    contents: {},
    hoverMenu: {
      display: 'flex'
    }
  })

export interface QuoteIDCardProps extends WithStyles<typeof styles> {}

function QuoteIDCard(props: QuoteIDCardProps) {
  const { classes } = props
  const { quote } = useContext(QuoteContext)

  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })

  return (
    <QuoteHeaderCard
      classes={{
        root: classes.root,
        contents: classes.contents
      }}
      title={`${objectLabels.quote.value} ID`}
      text={
        quote.number && quote.number !== -1 ? (
          quote.number.toString()
        ) : (
          <Typography variant="subtitle1">No ID</Typography>
        )
      }
    />
  )
}

export default withStyles(styles)(QuoteIDCard)

// Modification of Material-UI's MobileStepper https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/MobileStepper/MobileStepper.js

import React from 'react'
import type { MobileStepperProps } from '@material-ui/core/MobileStepper'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import { Paper } from '@ui/paintscout'
import classnames from 'classnames'
import type { EnhancedQuoteFile } from './FileView'

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.default,
      padding: 8,
      width: '100%'
    },
    dots: {
      display: 'flex',
      flexDirection: 'row'
    },
    dot: {
      backgroundColor: theme.palette.action.disabled,
      borderRadius: '50%',
      width: 6,
      height: 6,
      marginTop: 1,
      marginLeft: 2,
      marginRight: 2,
      '&:last-child': {
        background: `
      linear-gradient(to bottom, transparent 35%, 
        ${theme.palette.grey[400]} 35%, 
        ${theme.palette.grey[400]} 65%,  
        transparent 65%),

      linear-gradient(to right, transparent 35%, 
        ${theme.palette.grey[400]} 35%, 
        ${theme.palette.grey[400]} 65%,  
        transparent 65%)
      `
      }
    },
    dotActive: {
      width: 8,
      height: 8,
      marginTop: 0,
      backgroundColor: theme.palette.primary.main,
      '&:last-child': {
        background: `
      linear-gradient(to bottom, transparent 35%, 
        ${theme.palette.primary.main} 35%, 
        ${theme.palette.primary.main} 65%,  
        transparent 65%),

      linear-gradient(to right, transparent 35%, 
        ${theme.palette.primary.main} 35%, 
        ${theme.palette.primary.main} 65%,  
        transparent 65%)
      `
      }
    },
    dotError: {
      backgroundColor: theme.palette.error.light,
      '&$dotActive': {
        backgroundColor: theme.palette.error.dark
      }
    },
    dotUploading: {
      '&:not($dotActive)': {
        animation: '2s infinite pulse'
      }
    },

    '@keyframes pulse': {
      '50%': {
        // transform: 'scale(1.1)',
        backgroundColor: theme.palette.primary.light
      }
    }
  })

export interface ItemFilesStepperProps
  extends Omit<MobileStepperProps, 'classes' | 'steps'>,
    WithStyles<typeof styles> {
  files?: EnhancedQuoteFile[]
}

function ItemFilesStepper(props: ItemFilesStepperProps) {
  const { activeStep, backButton, classes, className, nextButton, files } = props

  const steps = (files ? files.length : 0) + 1
  return (
    <Paper square elevation={0} className={classnames(classes.root, className)}>
      {backButton}
      <div className={classes.dots}>
        {Array.from({ length: steps }).map((_, index) => {
          return (
            <div
              key={index}
              className={classnames(classes.dot, {
                [classes.dotActive]: index === activeStep,
                [classes.dotError]: files[index] && !!files[index].error,
                [classes.dotUploading]: files[index] && files[index].uploading
              })}
            />
          )
        })}
      </div>
      {nextButton}
    </Paper>
  )
}

export default withStyles(styles)(ItemFilesStepper)

import { gql } from 'apollo-boost'
import type { ApolloClient } from 'apollo-client'
import * as Sentry from '@sentry/core'

async function getUploadUrl({
  apolloClient,
  fileKey
}: {
  apolloClient: ApolloClient<any>
  fileKey: string
}): Promise<string> {
  let signedUploadUrl = ''
  try {
    const { data } = await apolloClient.query({
      query: gql`
        query getSignedUploadUrl($fileKey: String!) {
          getSignedUploadUrl(fileKey: $fileKey) {
            url
          }
        }
      `,
      variables: {
        fileKey
      }
    })

    signedUploadUrl = data.getSignedUploadUrl.url
  } catch (error) {
    console.log('error geting signed upload url', { error, fileKey })
    Sentry.captureException(error)
  }

  return signedUploadUrl
}

export default getUploadUrl

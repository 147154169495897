import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import LensIcon from '@material-ui/icons/Lens'
import ErrorIcon from '@material-ui/icons/Error'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import type { Activity } from '@paintscout/api'
import type { StyleClasses } from '../theme'

const useStyles = makeStyles<Theme, ActivityDotProps>(
  (_theme) => ({
    root: {
      width: 16,
      height: 16
    }
  }),
  { name: 'CoreActivityDot' }
)

export interface ActivityDotProps extends Omit<SvgIconProps, 'color'> {
  classes?: StyleClasses<typeof useStyles>
  activity: Activity
  color?: string
}

const ActivityDot = React.forwardRef<SVGElement, ActivityDotProps>(function ActivityDot(props, ref) {
  const { activity, color: _color, ...svgProps } = props
  const classes = useStyles(props)
  const error = activity.details.quote?.error || activity.details.invoice?.error

  const IconComponent = error ? ErrorIcon : LensIcon

  return <IconComponent {...svgProps} classes={classes} ref={ref as any} />
})

export default ActivityDot

import type { UploadOptions } from '..'
import { uuid } from '../../builder'
import getInitialImageWidth from './getInitialImageWidth'

// Get key to store file under in image bucket
// Images, Mp4, Pdf go to uploads to be transformed,
// All else go right to company file
// If docId present, nest under that too
// Return clean fileType too
const getFileKey = (
  file: File,
  opts: UploadOptions
): { fileKey: string; fileType: File['type']; initialImageWidth: number } => {
  let fileType = file.type.split('/')[1]
  const id = uuid().slice(0, 8)
  const initialImageWidth = getInitialImageWidth()

  let base: string
  let subType: string
  let docId: string

  if (opts?.docId) {
    docId = `/doc-${opts.docId}`
  } else {
    docId = ''
  }

  switch (fileType) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'gif':
      subType = 'images'
      base = `${opts.companyId}${docId}/${subType}/${id}/${initialImageWidth}`
      break

    case 'svg+xml':
      subType = 'images'
      if (fileType === 'svg+xml') {
        fileType = 'svg'
      }
      base = `${opts.companyId}${docId}/${subType}/${id}/svg`
      break

    case 'pdf':
      subType = 'pdf'
      // base = `uploads/${opts.companyId}${docId}/${subType}/${id}`
      base = `${opts.companyId}${docId}/${subType}/${id}` // Try going right to instead, will generate pages on other end
      break

    case 'mp4':
      subType = 'mp4'
      base = `${opts.companyId}${docId}/${subType}/${id}`
      break

    default:
      base = `${opts.companyId}${docId}/${id}`
      break
  }
  const fileKey = base + `/${id}.${fileType}`

  return { fileKey, fileType, initialImageWidth }
}

export default getFileKey

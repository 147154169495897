import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields
  if (!fields) {
    return true
  }

  const hasContent = fields?.content && fields.content.replace('<p></p>', '').trim()
  if (!hasContent && !fields?.image) {
    return true
  }

  return false
}

import type { PresentationSection } from 'paintscout'

export function filterActivePresentationSections(section: PresentationSection) {
  if (!(section?.active ?? true)) {
    return false
  }
  if (!section?.advanced?.useForNav) {
    return false
  }

  return true
}

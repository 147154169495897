import type { BoxProps } from '@material-ui/core'
import { Box } from '@material-ui/core'
import React from 'react'

type FlexDirection = 'row' | 'column'
type FlexJustify =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'initial'
  | 'inherit'
  | 'stretch'
type FlexAlign = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' | 'initial' | 'inherit'
type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse' | 'initial' | 'inherit'

export interface FlexProps extends BoxProps {
  /**
   * flexDirection
   */
  direction?: FlexDirection | FlexDirection[]

  /**
   * justifyContent
   */
  justify?: FlexJustify | FlexJustify[]

  /**
   * alignItems
   */
  align?: FlexAlign | FlexAlign[]

  /**
   * flexWrap
   */
  wrap?: FlexWrap | FlexWrap[]

  /**
   * Shorthand for justify and align = center
   */
  center?: boolean
}

/**
 * Creates a flex element
 */
const Flex = React.forwardRef<HTMLElement, FlexProps>(function Flex(
  {
    center = false,
    direction = `row`,
    justify = center ? `center` : `flex-start`,
    align = center ? `center` : `flex-start`,
    wrap = `wrap`,
    ...props
  },
  ref
) {
  return (
    <Box
      // @ts-ignore - Box is missing ref type, but it exists
      ref={ref as any}
      display="flex"
      flexDirection={direction}
      justifyContent={justify}
      alignItems={align}
      flexWrap={wrap}
      {...props}
    />
  )
})

export default Flex

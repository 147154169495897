import type { OptionsDocument } from 'paintscout'
import { getObjectLabels } from '../../builder/util/get-object-labels'

export function getTemplateTags(args: { options: OptionsDocument; invoice?: boolean }) {
  const { options, invoice } = args
  const { quote } = getObjectLabels({ options, invoice })

  return [
    {
      key: 'companyName',
      label: 'Company Name',
      hideValue: true
    },
    {
      key: 'clientName',
      label: 'Client Name',
      hideValue: true
    },
    {
      key: 'clientFirstName',
      label: 'Client First Name',
      hideValue: true
    },
    {
      key: 'clientLastName',
      label: 'Client Last Name',
      hideValue: true
    },
    {
      key: 'clientSecondaryFirstName',
      label: 'Client Secondary First Name',
      hideValue: true
    },
    {
      key: 'clientSecondaryLastName',
      label: 'Client Secondary Last Name',
      hideValue: true
    },
    { key: 'address', label: 'Client/Job Address', hideValue: true },
    { key: 'city', label: 'Client/Job City', hideValue: true },
    { key: 'state', label: 'Client/Job State', hideValue: true },
    { key: 'zip', label: 'Client/Job Zip Code', hideValue: true },
    { key: 'jobIdentifier', label: 'Job Identifier', hideValue: true },
    {
      key: 'quoteDate',
      label: `${quote.value} Date`,
      hideValue: true
    },
    {
      key: 'quoteNumber',
      label: `${quote.value} Number`,
      hideValue: true
    },
    {
      key: 'intExt',
      label: `${quote.value} Type`,
      hideValue: true
    },
    {
      key: 'estimatorInfo',
      label: `Estimator Info`,
      hideValue: true
    },
    {
      key: 'estimatorName',
      label: `Estimator Name`,
      hideValue: true
    },
    {
      key: 'estimatorFirstName',
      label: `Estimator First Name`,
      hideValue: true
    },
    {
      key: 'estimatorEmail',
      label: `Estimator Email`,
      hideValue: true
    },
    {
      key: 'estimatorPhone',
      label: `Estimator Phone`,
      hideValue: true
    }
  ]
}

export function getTemplateContext(editorPreference?: string) {
  return {
    companyName: '{{companyName}}',
    clientName: '{{clientName}}',
    clientFirstName: '{{clientFirstName}}',
    clientLastName: '{{clientLastName}}',
    clientSecondaryFirstName: '{{clientSecondaryFirstName}}',
    clientSecondaryLastName: '{{clientSecondaryLastName}}',
    address: '{{address}}',
    city: '{{city}}',
    state: '{{state}}',
    zip: '{{zip}}',
    jobIdentifier: '{{jobIdentifier}}',
    quoteDate: '{{quoteDate}}',
    quoteNumber: '{{quoteNumber}}',
    fileType: '{{fileType}}',
    intExt: '{{quoteType}}',
    estimatorInfo:
      editorPreference !== 'always'
        ? '{{estimatorName}}<br/>{{estimatorEmail}}<br/>{{estimatorPhone}}'
        : '{{estimatorName}}\n{{estimatorEmail}}\n{{estimatorPhone}}',
    estimatorName: '{{estimatorName}}',
    estimatorFirstName: '{{estimatorFirstName}}',
    estimatorEmail: '{{estimatorEmail}}',
    estimatorPhone: '{{estimatorPhone}}'
  }
}

/**
 * Get area totals
 * @param  {GetAreaDescription}    args
 * @return {string}
 */

import type { OptionsDocument, QuoteDocument } from 'paintscout'

import { generateAreaDescription } from '../../util'

import type { OverridableValue, QuoteItemSection } from '../../index'

export function getAreaDescription(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  plainText?: boolean
  section?: QuoteItemSection
  inGroup?: boolean
}): OverridableValue {
  const { quote, areaKey, options, plainText = true, section, inGroup } = args

  const substrateString = quote.areas[areaKey].substrate_string || {}
  const r = {
    default: generateAreaDescription({ quote, options, areaKey, plainText, section, inGroup }),
    useCustom: !!substrateString.use_custom,
    custom: substrateString.custom
  }

  return r
}

import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import CoreInputField from '@ui/core/InputField'
import type { Theme } from '@material-ui/core'
import type { InputProps } from '../Input'
import Input from '../Input'
import FormattedInput from '@ui/core/FormattedInput'
import classnames from 'classnames'
import { default as MuiInputAdornment } from '@material-ui/core/InputAdornment'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import Typography from '../Typography'

const useStyles = makeStyles<Theme, InputFieldProps>((theme) => ({
  root: {},
  inputRoot: {},
  inputInput: {},
  adornment: {
    marginLeft: theme.typography.pxToRem(7),
    marginRight: theme.typography.pxToRem(7),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.typography.pxToRem(12),
      marginRight: theme.typography.pxToRem(12)
    }
  },
  inputWrapper: {},
  inputFocused: {
    '& $adornment': {
      color: theme.palette.primary.main
    }
  },
  clickableIcon: {
    cursor: 'pointer'
  },
  input: {},
  icon: {},
  hasLabel: {},
  hasSublabel: {}
}))

export type InputFieldFormat = 'price' | 'quantity' | 'hours' | 'dimension' | 'rate' | 'taxRate'

export interface InputFieldProps extends InputProps {
  classes?: InputProps['classes'] & StyleClasses<typeof useStyles>
  autoSelect?: boolean
  icon?: React.ComponentType<SvgIconProps>

  toolTip?: string | React.ReactNode
  format?: InputFieldFormat
  suffix?: string
  prefix?: string
  pattern?: { expression: string; title: string }
  onIconClick?: (ev: React.MouseEvent) => void
}

function InputField(props: InputFieldProps) {
  const classes = useStyles(props)

  const {
    icon: Icon,
    label: labelText,
    sublabel: sublabelText,
    inlineLabel,

    required,
    pattern,

    startAdornment,
    endAdornment,
    inputRef,
    ...baseInputProps
  } = props

  return (
    <CoreInputField
      classes={classes}
      Typography={Typography}
      {...baseInputProps}
      inputComponent={
        <Input
          ref={inputRef}
          label={labelText}
          inputProps={pattern ? { pattern: pattern.expression, title: pattern.title } : {}}
          inlineLabel={inlineLabel}
          sublabel={sublabelText}
          toolTip={props.toolTip}
          startAdornment={
            (Icon || startAdornment) && (
              <MuiInputAdornment position="start" classes={{ root: classes.adornment }}>
                {Icon && (
                  <Icon
                    className={classnames(classes.icon, { [classes.clickableIcon]: props.onIconClick })}
                    onClick={props.onIconClick}
                  />
                )}
                {startAdornment}
              </MuiInputAdornment>
            )
          }
          endAdornment={
            endAdornment && (
              <MuiInputAdornment position="end" classes={{ root: classes.adornment }}>
                {endAdornment}
              </MuiInputAdornment>
            )
          }
          classes={{
            focused: classes.inputFocused
          }}
          required={required}
          {...getFormatProps(props)}
          {...baseInputProps}
        />
      }
    />
  )
}

const getFormatProps = (props) => {
  const { format, name, prefix, suffix } = props
  if (format) {
    return {
      inputComponent: FormattedInput,
      inputProps: { format, name, prefix, suffix }
    } as any
  }
  return {}
}

export default InputField

import React from 'react'
import type { StyleClasses } from '../theme'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel'
import MuiInputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles<Theme, InputLabelProps>((_theme) => ({
  root: {},
  formControl: {
    position: 'relative'
  },
  sublabel: {},
  labelFocused: {},
  sublabelFocused: {},
  focused: {},
  disabled: {},
  required: {},
  error: {}
}))

export interface InputLabelProps extends Omit<MuiInputLabelProps, 'variant'> {
  classes?: MuiInputLabelProps['classes'] & StyleClasses<typeof useStyles>
  variant?: 'label' | 'sublabel'
}

function InputLabel(props: InputLabelProps) {
  const classes = useStyles(props)
  const { variant: _variant, ...baseInputLabelProps } = props
  const {
    labelFocused: _labelFocused,
    sublabelFocused: _sublabelFocused,
    sublabel: _sublabel,
    ...baseClasses
  } = classes

  return <MuiInputLabel {...baseInputLabelProps} classes={baseClasses} shrink={true} />
}

InputLabel.defaultProps = {
  variant: 'label'
}

export default InputLabel

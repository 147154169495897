import Compressor from 'compressorjs'

export interface CompressFileOptions {
  file: File
  maxWidth?: number
  maxHeight?: number
  quality?: number
  width?: number
}

// Compress images, return compressed image or skipped file
export default async function compressFile({ file, maxHeight = null, maxWidth = null }: CompressFileOptions) {
  if (!file.type || !file.type.startsWith('image') || file.type === 'image/gif') {
    return file
  }

  const newFile = await new Promise<File>((res, rej) => {
    new Compressor(file, {
      // https://www.npmjs.com/package/compressorjs#quality
      maxHeight: maxHeight ?? 1200,
      maxWidth: maxWidth ?? 1200,
      success(result) {
        res(result as File)
      },
      error: rej
    })
  })

  return newFile
}

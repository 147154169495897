import type { Group, QuoteDocument } from 'paintscout'

/**
 * Check if an area is in a group, return group if found
 * @returns Group if found, null if not
 */
export function isInAreaGroup({ quote, areaKey }: { quote: QuoteDocument; areaKey: string }): Group {
  let inAreaGroup = null
  Object.keys(quote.groups ?? []).forEach((groupKey) => {
    const group = quote.groups[groupKey]
    if (group.children.includes(areaKey)) {
      inAreaGroup = group
    }
  })
  return inAreaGroup
}

import React from 'react'
import type { InputFieldProps } from '../InputField'
import InputField from '../InputField'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useIsMobile } from '@ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2, 2, 1, 2)
  },
  input: {
    paddingLeft: 0
  },
  icon: {
    color: theme.palette.grey[400]
  }
}))

export interface DropdownSelectSearchBarProps extends Omit<InputFieldProps, 'onChange'> {
  onChange: (value: string) => void
  placeholder?: string
}

export default function DropdownSelectSearchBar({
  placeholder,
  onChange,
  ...inputProps
}: DropdownSelectSearchBarProps) {
  const classes = useStyles()
  const mobile = useIsMobile()

  return (
    <div className={classes.container}>
      <InputField
        {...inputProps}
        classes={{
          input: classes.input
        }}
        fullWidth
        startAdornment={<SearchIcon className={classes.icon} />}
        placeholder={placeholder ?? 'Search'}
        onChange={(e) => onChange(e.target.value)}
        autoFocus={!mobile}
      />
    </div>
  )
}

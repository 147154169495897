import React from 'react'
import { Typography } from '@material-ui/core'
import { ActivityTitle as CoreActivityTitle } from '@ui/core'
import type { ActivityTitleProps as CoreActivityTitleProps } from '@ui/core'

export interface ActivityTitleProps extends Omit<CoreActivityTitleProps, 'titleContent'> {}

export default function ActivityTitle(props: ActivityTitleProps) {
  return <CoreActivityTitle {...props} titleContent={<Typography variant="body1" {...props} />} />
}

import React from 'react'
import { useQuote } from '../../context'
import { Alert, Button, Grid, Link, useClientOptions } from '@ui/paintscout'
import { formatCurrency, getObjectLabels } from '@paintscout/util/builder'
import type { Theme } from '@material-ui/core'
import { Typography, makeStyles } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles<Theme, { isPreview: boolean }>((theme) => ({
  root: ({ isPreview }) => {
    return {
      margin: theme.spacing(1, 0, 3, 0),
      pointerEvents: isPreview ? 'none' : 'auto'
    }
  },
  content: {
    width: 'fit-content'
  },
  button: {
    padding: 0
  }
}))

export interface InvoiceAlertProps {
  inPresentation?: boolean
}

export default function InvoiceAlert({ inPresentation }: InvoiceAlertProps) {
  const { quote, isCustomerView } = useQuote()
  const { options } = useClientOptions()

  const classes = useStyles({ isPreview: inPresentation && !isCustomerView })
  const { depositInfo, child_invoice } = quote
  const status = quote.status.value

  const showDeposit = depositInfo && !quote.is_invoice
  const showInvoiceAlert =
    status === 'accepted' && child_invoice && !quote.is_invoice && !isCustomerView && !inPresentation

  if (!showDeposit && !showInvoiceAlert) {
    return null
  }

  const { quote: quoteLabel } = getObjectLabels({ options })
  const { quote: invoiceLabel } = getObjectLabels({ options, quote, invoice: true })
  const dateFormat = options.options.dateFormat?.momentValue ?? 'MM/DD/YYYY'
  const invoiceLink = isCustomerView ? depositInfo.invoiceLink : `/invoices/edit/${quote.child_invoice}`

  const handleRedirect = () => {
    window.open(invoiceLink, '_self')
  }

  const DepositInfoContent = () => {
    return (
      <>
        <Typography className={classes.content}>
          A deposit of <strong>{formatCurrency({ options, value: depositInfo.amount })}</strong> was paid on{' '}
          {moment(depositInfo.date).format(dateFormat)}.
        </Typography>
        {!!invoiceLink &&
          (isCustomerView ? (
            <Button id="allow-propagation" className={classes.button} variant="underline" onClick={handleRedirect}>
              view {invoiceLabel.value}
            </Button>
          ) : (
            <Link to={invoiceLink}>
              <Button id="allow-propagation" className={classes.button} variant="underline">
                view {invoiceLabel.value}
              </Button>
            </Link>
          ))}
      </>
    )
  }

  const InvoiceAlertContent = () => {
    return (
      <>
        <Typography className={classes.content}>
          This is an accepted {quoteLabel.value.toLowerCase()}. We recommend updating or adding to the{' '}
          {invoiceLabel.value.toLowerCase()}.
        </Typography>
        {!!invoiceLink && (
          <Link to={invoiceLink}>
            <Button id="allow-propagation" className={classes.button} variant="underline">
              view {invoiceLabel.value}
            </Button>
          </Link>
        )}
      </>
    )
  }

  return (
    <Alert severity="info" hideIcon className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        {showDeposit ? <DepositInfoContent /> : <InvoiceAlertContent />}
      </Grid>
    </Alert>
  )
}

import { makeStyles } from '@material-ui/core'
import { Typography } from '@ui/paintscout'
import React from 'react'
import { formatCurrency, getFeatures, getQuoteOptions } from '@paintscout/util/builder'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { parseNumber } from '@paintscout/util/calculator'

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.h3,
    whiteSpace: 'nowrap',
    '& span': {
      color: theme.palette.text.disabled
    }
  }
}))

export interface PaymentDialogRightContentProps {
  quote: QuoteDocument
  options: OptionsDocument
  paymentAmount: number
  includeSurcharge?: boolean
}

export default function PaymentDialogRightContent({ quote, options, paymentAmount, includeSurcharge }) {
  const classes = useStyles()
  const { surcharge } = getQuoteOptions({ options, quote })
  const surchargeFeature = getFeatures({ options })?.surcharge
  const parsedAmount = parseNumber(paymentAmount, 0)
  const balanceDue = quote?.totals?.balance_due

  const surchargeAmount =
    includeSurcharge && !!surchargeFeature && !!surcharge?.enabled && surcharge?.value > 0
      ? (parsedAmount * surcharge.value) / 100
      : 0

  const formattedAmount = formatCurrency({ options, value: parsedAmount + surchargeAmount })
  const formattedBalanceDue = balanceDue ? formatCurrency({ options, value: balanceDue + surchargeAmount }) : null
  return (
    <Typography className={classes.root} align={'center'}>
      {formattedAmount} {!!formattedBalanceDue && <span>of {formattedBalanceDue}</span>}
    </Typography>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import type { WithUseStyles } from '../theme'

type DivProps = JSX.IntrinsicElements['div']

export interface ActivityFooterProps extends DivProps {
  classes?: WithUseStyles<typeof useStyles>['classes']
  time: React.ReactNode
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: 2,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      color: theme.palette.grey[500],
      paddingRight: theme.spacing()
    }
  }),
  {
    name: 'ActivityFooter'
  }
)

export default function ActivityFooter({ children, time, ...props }: ActivityFooterProps) {
  const classes = useStyles(props)

  return (
    <div {...props} className={classnames(classes.root, props.className)}>
      {time}
      {children}
    </div>
  )
}

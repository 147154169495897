import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ConvertIcon from '@material-ui/icons/SwapHoriz'
import ListIcon from '@material-ui/icons/List'
import React, { useContext, useMemo } from 'react'
import { useClientOptions, useUser } from '@ui/paintscout'
import { Typography } from '@ui/paintscout'
import type { RenderableItem } from '@paintscout/util/builder'
import { getFeatures } from '@paintscout/util/builder'
import get from 'lodash/get'
import { getItems, getObjectLabels } from '@paintscout/util/builder'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { ItemColumn, ItemTableCell } from '../../../../ItemTable'
import ActionColumn from '../../../../ItemTable/columns/ActionColumn'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    noResults: {
      textAlign: 'center',
      margin: theme.spacing(4)
    }
  })

export interface ArchivedTableProps {
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  onItemAction?: ItemTableProps['onItemAction']
  disableSubstrateView?: boolean
}

type ArchivedTablePropsWithInjections = ArchivedTableProps & WithStyles<typeof styles>

function ArchivedTable({
  classes,
  disableSubstrateView,
  onItemAction,
  ...itemTableProps
}: ArchivedTablePropsWithInjections) {
  const { quote, isEditable, tableView } = useContext(QuoteContext)
  const { options } = useClientOptions()
  const { isViewer, isCrew } = useUser()

  const features = getFeatures({ options })
  const canConvertToLineItem = get(features, 'quotes.convertToLineItem')

  const objectLabels = getObjectLabels({ options })
  const view = disableSubstrateView ? 'area' : tableView
  const items = useMemo(
    () =>
      getItems({
        quote,
        section: 'archived',
        view,
        consumer: 'customer',
        options,
        overrideOptions: { showPrices: true }
      }).map((item) => {
        if (!item.description && !item.value && item.subItems && item.subItems.length > 0) {
          const firstSubItem = item.subItems.shift()
          return {
            ...item,
            description: { useCustom: false, default: firstSubItem.description, custom: '' },
            value: firstSubItem.value
          }
        }

        return item
      }),
    [quote, tableView]
  )

  return (
    <div>
      {items && items.length > 0 ? (
        <ItemTable
          items={items}
          checkboxes={isEditable}
          draggable={false}
          showAddButton={false}
          onItemClick={isEditable ? (ev, item) => onItemAction('edit', 'archived', item as RenderableItem) : null}
          renderHeadColumns={() => (
            <>
              <ItemTableCell>Item</ItemTableCell>
              <ItemTableCell align="right">{objectLabels.currency.symbol}</ItemTableCell>

              {/* action column */}
              {(isEditable || isViewer || isCrew) && <ItemTableCell />}
            </>
          )}
          renderRowColumns={(item: RenderableItem) => {
            const actions = []
            actions.push({
              key: 'edit',
              label: 'Edit',
              icon: EditIcon
            })
            if (!isViewer && !isCrew) {
              actions.push({
                key: 'move-to-bid',
                label: `Move to ${objectLabels.quote.value}`,
                icon: AddBoxIcon
              })
            }

            actions.push({
              key: 'make-option',
              label: `Make ${objectLabels.option.value}`,
              icon: AddBoxIcon
            })

            if (item.type !== 'group') {
              if (view === 'area') {
                actions.push({
                  key: 'delete',
                  label: 'Delete',
                  icon: DeleteIcon
                })
              }
            }

            if (item.type === 'group') {
              actions.push({
                key: 'ungroup',
                label: 'Ungroup',
                icon: ListIcon
              })
            }

            if (canConvertToLineItem && item.type !== 'line_item') {
              actions.push({
                key: 'convert',
                label: 'Convert to Line Item',
                icon: ConvertIcon
              })
            }
            return (
              <>
                <ItemTableCell colSpan={2}>
                  <ItemColumn
                    item={item}
                    consumer={'customer'}
                    isEditable={isEditable || isViewer || isCrew}
                    showAmount
                    section={'archived'}
                  />
                </ItemTableCell>
                {(isEditable || isViewer || isCrew) && (
                  <ItemTableCell cancelDrag isControl>
                    <ActionColumn
                      onActionClick={(ev, action) => onItemAction(action, 'archived', item as RenderableItem)}
                      actions={actions}
                    />
                  </ItemTableCell>
                )}
              </>
            )
          }}
          {...itemTableProps}
        />
      ) : (
        <div className={classes.noResults}>
          <Typography variant="h4">
            There are no archived items on this {objectLabels.quote.value.toLowerCase()}.
          </Typography>
          <Typography variant="subtitle1">
            Items can be archived by selecting "Archive" from the Item Action menu.
          </Typography>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(ArchivedTable)

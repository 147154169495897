/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'

import { getEntityTypeOptions } from './index'

export function getEntityType(args: { quote: QuoteDocument; key: string }): string {
  const entityTypes = getEntityTypeOptions()
  const quote: { [key: string]: any } = args.quote
  const { key } = args
  const found = entityTypes.filter((entityType: string) => quote[entityType] && quote[entityType][key])
  return found.length ? found[0] : null
}

import React from 'react'
import type { DialogProps } from '../Dialog'

export type OpenDialogProps<T> = Omit<T, 'open' | 'ref'> & {
  'data-testid'?: string
}

export interface DialogStackContextValue {
  stack: Array<{
    component: React.ComponentType<DialogProps | any>
    props: any
  }>
  renderPortal: boolean

  setRenderPortal?: (ref: any) => any

  /**
   * Opens a dialog using the provided component and props
   */
  openDialog?: <T>(
    component: React.ComponentType<T>,
    props?: OpenDialogProps<T>,
    options?: { replace?: boolean; skip?: boolean }
  ) => any

  /**
   * Dismisses the active dialog
   */
  dismissDialog?: () => void
  dismissDialogs?: (amount?: number) => void
  dismissAllDialogs?: () => void
}

export default React.createContext<DialogStackContextValue>({
  stack: [],
  renderPortal: false,
  setRenderPortal: () => {},
  openDialog: () => {},
  dismissAllDialogs: () => {},
  dismissDialog: () => {}
})

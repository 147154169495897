import type { OwnerChangedActivityDetails } from '@paintscout/api'

import React from 'react'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityFooter from '../ActivityFooter'
import ActivityTitle from '../ActivityTitle'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'

export default function QuoteOwnerChangedActivity(props: ActivityTypeComponentProps<OwnerChangedActivityDetails>) {
  const { activity, showDocTypeDetails } = props

  const newOwner = [activity.details.newOwner?.firstName ?? '', activity.details.newOwner?.lastName ?? '']
    .filter((item) => item)
    .join(' ')

  return (
    <>
      <ActivityTitle>
        <Highlight>Estimator</Highlight> changed to <Highlight>{newOwner ? newOwner : 'No User'}</Highlight>
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

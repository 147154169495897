import { Box, Paper, Typography } from '@material-ui/core'
import type { MessageActivity } from '@paintscout/api'
import type { HTMLMotionProps } from 'framer-motion'
import { motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { Highlight } from '@ui/paintscout'
import { reformatSpecialChars, replaceBlockTags } from '@paintscout/util/builder'

export interface UnreadMessagePreviewProps {
  message: MessageActivity
}

const MAX_WORD_LENGTH = 40

export default function UnreadMessagePreview({
  message,
  ...props
}: UnreadMessagePreviewProps & HTMLMotionProps<'div'>) {
  // trim long messages with an elipsis
  const text = useMemo(() => {
    const split = message.details.message.text.split(' ')

    if (split.length > MAX_WORD_LENGTH) {
      return reformatSpecialChars(replaceBlockTags(`${split.slice(0, MAX_WORD_LENGTH - 1).join(' ')}...`))
    }

    return reformatSpecialChars(replaceBlockTags(message.details.message.text))
  }, [message])

  return (
    <motion.div
      variants={{
        open: {
          scale: 1,
          opacity: 1,
          transformOrigin: 'bottom right',
          transition: {
            type: 'spring',
            damping: 20,
            stiffness: 200
          }
        },
        closed: {
          scale: 0,
          opacity: 0
        }
      }}
      animate="open"
      initial="closed"
      exit="closed"
      {...props}
    >
      <Box component={Paper} padding={2} width={250}>
        <Typography variant="body1" gutterBottom>
          <Highlight>{message.details.message.from.name}</Highlight>
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </Box>
    </motion.div>
  )
}

import type { OptionsDocument, QuoteArea, QuoteDocument } from 'paintscout'
import type { RenderableSubItem } from '../../..'
import { getItemDetails } from '../../util/get-item-details'
import type { RenderableItemSubstrate } from '../../../../area-substrates'
import { getCrewDescription } from '../../util/get-crew-description'
import getDimensionString from './get-dimension-string'
import { getQuoteOptions } from '../../../../util'

interface toRenderableCrewAreaSubstrateProps {
  key: string
  areaSubstrate: RenderableItemSubstrate
  quote: QuoteDocument
  area: QuoteArea
  options: OptionsDocument
  showValues: boolean
  hideDimensions?: boolean
}

/**
 * Convert AreaSubstrate into a RenderableSubItem
 * to be viewed by crew within an Area on WorkOrders
 */
export default function toRenderableCrewAreaSubstrate({
  key,
  areaSubstrate,
  quote,
  area,
  options,
  showValues = true,
  hideDimensions = false
}: toRenderableCrewAreaSubstrateProps): RenderableSubItem {
  const { prep, hours, price, totalPrice, clientLabel, itemName } = getItemDetails({
    item: areaSubstrate,
    quote
  })

  const { crewDescription, productFields } = getCrewDescription({
    products: areaSubstrate.products,
    prep,
    hours,
    itemName,
    clientLabel,
    showValues,
    quoteOptions: getQuoteOptions({ quote, options }),
    options
  })
  let name = clientLabel ? clientLabel : itemName
  const coats = areaSubstrate.useCoats ? areaSubstrate?.coats ?? 0 : 0

  const dimensionString = getDimensionString({
    areaSubstrate,
    area,
    quote,
    options,
    priceValue: price
  })

  if (dimensionString && !hideDimensions) {
    name = `${name} (${dimensionString})`
  }

  const r = {
    key,
    name,
    description: name === crewDescription ? '' : crewDescription,
    crewNote: areaSubstrate.notes,
    value: areaSubstrate.totalHours,
    reportText: areaSubstrate.areaReportText,
    additionalFields: {
      prep,
      painting: hours,
      price: totalPrice,
      coats,
      materials: productFields
    }
  } as RenderableSubItem

  return r
}

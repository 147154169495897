import type { Theme } from '@material-ui/core'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Collapse, useClientOptions, useUser } from '@ui/paintscout'
import React from 'react'
import { useQuote } from '../../context'
import type { LoanApplicationStatus } from '../types'
import { useWisetack } from '../useWisetack'
import { useConnection } from 'react-detect-offline'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '@media print': {
      filter: 'grayscale(1)'
    },
    padding: theme.spacing(2),
    paddingTop: 0
  },
  logo: {
    width: theme.typography.pxToRem(120),
    marginRight: theme.spacing(1)
  },
  dialogLogo: {
    width: theme.typography.pxToRem(120),
    objectFit: 'contain',
    margin: theme.spacing(1, 0)
  }
}))

export interface WisetackPromoProps {
  inPresentation?: boolean
}

export default function WisetackPromo({ inPresentation }: WisetackPromoProps) {
  const classes = useStyles()
  const { options } = useClientOptions()
  const { user, preferences } = useUser()
  const { online } = useConnection()
  const { wisetackPromo, quote } = useQuote()
  const { hasWisetack, merchantSignupDate, showOnOldDocuments } = useWisetack({ options, user, preferences })
  const contact = quote?.contact
  const companyContact = !!quote?.contact?.company

  const loanStatus = contact?.wisetackLoanApplication?.changedStatus as LoanApplicationStatus | undefined

  const hideBasedOnSignup =
    (inPresentation && !hasWisetack) ||
    (merchantSignupDate && merchantSignupDate.isAfter(quote?.dates?.created) && !showOnOldDocuments)
  const hideBasedOnLoanApp = inPresentation && loanStatus === 'DECLINED'

  const hide = hideBasedOnSignup || hideBasedOnLoanApp || (inPresentation && !contact) || !online || companyContact
  return (
    <Collapse show={!!wisetackPromo && !wisetackPromo.error && !hide}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        className={classes.root}
      >
        <Grid item>
          <Typography align="center">
            Or as low as <strong>${wisetackPromo?.asLowAs?.monthlyPayment}/month*</strong> with{' '}
          </Typography>
        </Grid>
        <Grid container item alignItems="flex-end" justifyContent="center">
          <img
            className={classes.logo}
            alt="Wisetack"
            src={
              'https://res.cloudinary.com/taptapquote/image/upload/v1663601481/paintscout/providers/wisetack-alternate.png'
            }
          />
        </Grid>
      </Grid>
    </Collapse>
  )
}

import React from 'react'
import type { DialogStackContextValue } from './DialogStackContext'
import DialogStackContext from './DialogStackContext'

export interface WithDialogStackContext {
  dialogStackContext: DialogStackContextValue
  children?: React.ReactNode
}

const withDialogStackContext = <T extends WithDialogStackContext>(Component: React.ComponentType<T>) => {
  function WithDialogStackContext(props: Omit<T, 'dialogStackContext'>) {
    return (
      <DialogStackContext.Consumer>
        {(context: DialogStackContextValue) => <Component {...(props as any)} dialogStackContext={context} />}
      </DialogStackContext.Consumer>
    )
  }

  WithDialogStackContext.displayname = `WithDialogStackContext(${Component.displayName ?? 'Component'})`
  return WithDialogStackContext
}

export default withDialogStackContext

import React from 'react'
import type { QuoteFile } from 'paintscout'
import type { FieldProps } from 'formik'
import type { EmbedMediaProps } from '../EmbedMedia'
import EmbedMedia from '../EmbedMedia'

export interface FormikEmbedMediaProps extends FieldProps<any>, Omit<EmbedMediaProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikEmbedMedia = ({ field, form, ...props }: FormikEmbedMediaProps) => {
  const onChange = (file: QuoteFile) => {
    form.handleChange({ target: { name: field.name, value: file } })
  }

  return <EmbedMedia {...field} {...props} onChange={onChange} />
}

export default FormikEmbedMedia

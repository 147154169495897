import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { RenderableTotalGroup } from '../get-totals'

export default function convertGroups(quote: QuoteDocument, options: OptionsDocument): RenderableTotalGroup[] {
  const intExtOptions = options.options.intExtOptions

  if (!quote.totals.groups || Object.keys(quote.totals.groups).length < 2) {
    return []
  }

  const groups = intExtOptions.order
    .filter((key) => {
      if (!quote.totals.groups[key]) {
        return false
      }

      const prep = quote.totals.groups[key].prep
      const hours = quote.totals.groups[key].hours
      return prep + hours > 0
    })
    .map((key) => {
      return {
        key,
        description: intExtOptions.values[key].label,
        value: quote.totals.groups[key].hours
      }
    })

  return groups.length > 1 ? groups : []
}

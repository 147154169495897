import type { Location } from 'history'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import qs from 'qs'
import { getUrlQuery } from './getUrlQuery'
import history from './history'

// @ts-ignore - fixes error when hot reloading
if (!qs._parse) {
  require('qs-numbers')(qs)
}
/**
 * When React hooks ship to production, we can replace this
 */

export function setUrlQuery(args: { location: Location<any>; params: any; replaceParams?: boolean }) {
  let params = { ...args.params }

  if (!args.replaceParams) {
    const previousParams = getUrlQuery({ location: args.location })
    params = omitBy(
      {
        ...(previousParams as any),
        ...args.params
      },
      (val) => isUndefined(val) || isNull(val)
    )
  }

  return history.replace({
    pathname: args.location.pathname,
    search: qs.stringify(params, { skipNulls: true }),
    state: args.location.state
  })
}

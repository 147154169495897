/**
 * @module builder
 */

import type { OptionsDocument, QuoteDocument } from 'paintscout'

import { calculate, buildSubstrates, sanitizeSubstrates } from '../../../../calculator'

import { generateProductDescription } from '../generate-product-description'

export function calculateQuote(args?: { quote: QuoteDocument; options: OptionsDocument }): QuoteDocument {
  const { quote, options } = args ?? {}

  const builtSubstrates = buildSubstrates(quote, options)

  const calculatedQuote = calculate(builtSubstrates, options)

  const sanitizedQuote = sanitizeSubstrates(calculatedQuote)

  sanitizedQuote.productString = {
    ...sanitizedQuote.productString,
    default: generateProductDescription({ quote, options })
  }

  return sanitizedQuote
}

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/ArrowDropDown'
import ErrorIcon from '@material-ui/icons/PriorityHigh'
import classnames from 'classnames'
import type { QuoteStatus } from 'paintscout'
import React from 'react'
import type { WithUseStyles } from '../styles'

export const useStyles = makeStyles<Theme, StatusDotProps>((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'top',
      whiteSpace: 'nowrap'
    },
    label: {
      color: theme.palette.quote.status.draft
    },
    dot: {
      width: (props) => props.size,
      height: (props) => props.size,
      borderRadius: '50%',
      marginRight: theme.spacing(),
      backgroundColor: theme.palette.quote.status.draft,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      fontSize: 'inherit',
      color: '#fff'
    },
    errorIcon: {
      width: (props) => props.size,
      height: (props) => props.size
    },
    archiveIcon: {
      width: (props) => props.size * 1.5,
      height: (props) => props.size * 1.5
    }
  }
})

export interface StatusDotProps {
  classes?: WithUseStyles<typeof useStyles>['classes']
  size?: number
  status: QuoteStatus | 'pending'
  className?: string
  children?: React.ReactNode
  error?: boolean
  archived?: boolean
  dense?: boolean
}

function StatusDot(props: StatusDotProps) {
  const { className, status, error, dense, archived, children } = props
  const theme = useTheme()
  const classes = useStyles(props)

  const errorColor = theme.palette.error.main

  const statusLabel = children ? children : formatStatusLabel(status)

  let Icon = null
  let iconClass = null
  const statusColor = getStatusColor({ status, theme })
  let dotColor = statusColor
  if (archived) {
    Icon = ArchiveIcon
    iconClass = classes.archiveIcon
  } else if (error) {
    Icon = ErrorIcon
    iconClass = classes.errorIcon
    dotColor = errorColor
  }

  return (
    <div
      className={classnames({
        [className]: !!className,
        [classes.root]: true
      })}
    >
      <div data-testid={'quote-status-dot'} className={classes.dot} style={{ backgroundColor: dotColor }}>
        {Icon && <Icon className={classnames(classes.icon, iconClass)} />}
      </div>

      <div style={{ color: statusColor }} className={classes.label}>
        {!dense && statusLabel}
      </div>
    </div>
  )
}

StatusDot.defaultProps = {
  size: 12
}

function formatStatusLabel(status) {
  if (!status) {
    return ''
  }
  return status
    .split('-')
    .map((s) => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

function getStatusColor({ status: _status, theme }) {
  const status = _status.toLowerCase()
  const statusColors = theme.palette.quote.status
  const otherColor = status === 'pending' ? statusColors.sent : statusColors.draft

  switch (status) {
    case 'message':
    case 'thank-you-sent':
    case 'warning':
      return statusColors['sent']

    case 'error':
      return statusColors['declined']

    case 'payment-requested':
    case 'payment':
      return statusColors['paid']

    default:
      return statusColors?.[status] ?? otherColor
  }
}

export default StatusDot

import React, { useState } from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { Fade, createStyles, withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import classnames from 'classnames'
import EditIcon from '@material-ui/icons/Edit'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      background: 'none',
      // minHeight: theme.typography.pxToRem(80),
      borderRadius: 0,
      boxShadow: theme.shadows[0],
      textAlign: 'left',

      '& button': {
        color: theme.palette.grey[500]
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 0
      }
    },
    clickable: {
      cursor: 'pointer',
      '&:hover button': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main
      }
    },

    infoWrapper: {
      width: '100%'
    },
    contents: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: theme.typography.pxToRem(16),
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      '&:last-child': {
        paddingBottom: 0
      },
      ...theme.typography.body1
    },
    innerContents: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        // flexDirection: 'column'
      },
      ['@media print']: {
        flexDirection: 'row'
      }
    },
    title: {
      paddingBottom: theme.typography.pxToRem(10),
      ...theme.typography.overline,
      color: theme.palette.grey.A200,

      display: 'flex',
      flexDirection: 'row',

      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) '
    },
    border: {
      borderTop: `solid ${theme.typography.pxToRem(2)}`,
      borderColor: theme.palette.grey.A100,
      marginBottom: theme.typography.pxToRem(10)
    },
    titleHover: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    hoverIcon: {
      ...theme.typography.overline,
      marginLeft: theme.typography.pxToRem(theme.spacing()),
      marginTop: '1px',
      '@media print': {
        display: 'none'
      }
    },
    subtitle: {
      flexDirection: 'column',
      whiteSpace: 'pre-wrap',
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightMedium
    },
    text: {
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word'
    },

    innerContentsStacked: {
      flexDirection: 'column'
    },
    placeholderContent: {
      marginTop: theme.spacing()
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
}

export interface QuoteHeaderCardProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
  style?: React.CSSProperties
  title?: React.ReactText | JSX.Element
  subtitle?: React.ReactText | JSX.Element
  rightContent?: React.ReactNode
  text?: React.ReactText | JSX.Element
  image?: React.ReactNode
  imageWidth?: number
  imageHeight?: number
  imageOrientation?: 'default' | 'auto' | 'stack'
  clickable?: boolean
  placeholderContent?: JSX.Element
  showPlaceholder?: boolean
  showBorder?: boolean

  onClick?: (event: React.MouseEvent) => void
}

export interface QuoteHeaderCardState {
  showHover?: boolean
}

const fadeTimeout = 300

function QuoteHeaderCard({
  image,
  imageWidth,
  imageHeight,
  imageOrientation,
  title,
  subtitle,
  rightContent,
  text,
  placeholderContent,
  showPlaceholder,
  onClick,

  clickable,
  showBorder = true,
  classes,
  children,
  ...otherProps
}: QuoteHeaderCardProps) {
  const [showHover, setShowHover] = useState<boolean>(false)
  const stackImage = imageOrientation === 'stack' || (imageOrientation === 'auto' && imageWidth / imageHeight > 1.5)

  return (
    <Card
      {...otherProps}
      classes={{
        root: classes.root
      }}
      onClick={clickable ? onClick : undefined}
    >
      <CardContent
        className={classnames(classes.contents, { [classes.clickable]: clickable })}
        onClick={() => {
          setShowHover(true)
        }}
        onMouseEnter={() => {
          setShowHover(true)
        }}
        onMouseLeave={() => {
          setShowHover(false)
        }}
      >
        <div className={classnames(classes.innerContents, { [classes.innerContentsStacked]: stackImage })}>
          {image}

          <div className={classes.infoWrapper}>
            {title && showBorder && <div className={classes.border}></div>}
            {title && <Title />}
            {!placeholderContent && (
              <div>
                {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                {text && <div className={classes.text}>{text}</div>}
                {children}
              </div>
            )}
            {placeholderContent && (
              <Fade in={!showPlaceholder} appear={false} timeout={fadeTimeout} unmountOnExit={false}>
                <div>
                  {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                  {text && <div className={classes.text}>{text}</div>}
                  {children}
                </div>
              </Fade>
            )}
          </div>
        </div>
        {placeholderContent && (
          <Fade in={placeholderContent && showPlaceholder} appear={false} timeout={fadeTimeout} unmountOnExit={true}>
            <div className={classes.placeholderContent}>{placeholderContent}</div>
          </Fade>
        )}
      </CardContent>
    </Card>
  )

  function Title() {
    const hoverIcon = <EditIcon classes={{ root: classes.hoverIcon }} />

    return (
      <div className={classes.titleWrapper}>
        <div className={classnames(classes.title, showHover && clickable ? classes.titleHover : '')}>
          {title}
          {clickable && hoverIcon}
        </div>
        {rightContent}
      </div>
    )
  }
}

export default withStyles(styles)(QuoteHeaderCard)

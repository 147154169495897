import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import history from 'shared/util/routing/history'

import CustomerViewLogRocket from './util/CustomerViewLogRocket'
import './util/customerViewSentry'

import { ApolloProvider } from '@apollo/react-hooks'
import { CssBaseline } from '@material-ui/core'
import { CloudinaryProvider, DialogStackProvider, SnackbarProvider } from '@ui/paintscout'
import ClientRoute from './components/ClientRoute'
import { client } from './graphql/client'
import Index from './pages/Index'
import getTheme from './util/getTheme'

const p = document.createElement('a')
p.href = process.env.PS_VIEW_URL
const rootPath = p.pathname ? p.pathname : '/'

function App() {
  return (
    <ApolloProvider client={client as any}>
      <CloudinaryProvider cloudName={process.env.CLOUDINARY_CLOUD_NAME} secure={true}>
        <ThemeProvider theme={getTheme()}>
          <CssBaseline />
          <Router history={history}>
            <DialogStackProvider>
              <SnackbarProvider>
                <Switch>
                  <ClientRoute path={rootPath} exact>
                    <CustomerViewLogRocket />
                    <Index />
                  </ClientRoute>
                  {/* redirect */}
                  <Route
                    path={rootPath}
                    render={() => {
                      window.location.href = 'https://paintscout.com'

                      return null
                    }}
                  />
                </Switch>
              </SnackbarProvider>
            </DialogStackProvider>
          </Router>
        </ThemeProvider>
      </CloudinaryProvider>
    </ApolloProvider>
  )
}

export default App

import type { QuoteOptions } from 'paintscout'
import type { RenderableItem, RenderableSubItem } from '../../..'

// Get the subItems for area if special display required,
// and the adjusted area/renderableItem
export const getAreaSubItems = ({
  trimmedArea,
  areaSubstrates,
  quoteOptions
}: {
  trimmedArea: RenderableItem
  areaSubstrates: RenderableSubItem[]
  quoteOptions: QuoteOptions
}): {
  cleanArea: RenderableItem
  subItems: RenderableSubItem[]
} => {
  let subItems = [] as RenderableSubItem[]

  if (quoteOptions.showDetailedBreakdown || quoteOptions.stackSubstrates) {
    if (quoteOptions.stackSubstrates) {
      // Show substrates on seperate lines, no values beside them
      subItems = areaSubstrates.map((item) => {
        item.value = ''
        return item
      })
    } else if (quoteOptions.showDetailedBreakdown) {
      // Show substrates on seperate lines, w/ value
      subItems = areaSubstrates
      trimmedArea.value = ''
    }

    // Unless customized, no default description, else we will include
    // under the stacked substrates
    if (!trimmedArea.description.useCustom) {
      trimmedArea.description.default = ''
    }
  }
  return { cleanArea: trimmedArea, subItems }
}

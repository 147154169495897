/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { UpdateableTerms } from '../../index'
import { template, createQuoteContext } from '../../../../templater'

export function getTerms(args: { quote: QuoteDocument; options: OptionsDocument }): UpdateableTerms {
  const { quote, options } = args
  const terms = quote?.terms

  if (!terms) {
    return {
      name: '',
      label: '',
      custom: false,
      content: '',
      disabled: false,
      showToc: true
    }
  }

  return {
    name: terms.name,
    label: terms.label,
    custom: terms.custom,
    disabled: terms.disabled,
    showToc: terms.showToc,
    content: terms.content ? template({ text: terms.content, context: createQuoteContext({ quote, options }) }) : ''
  }
}

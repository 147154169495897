/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'

import type { UpdateableGroup } from '../index'

export function getGroup(args: { quote: QuoteDocument; key: string }): UpdateableGroup {
  const { quote, key } = args
  return { ...quote.groups[key] }
}

import type { OptionsDocument } from 'paintscout'

/*
  Returns the last 10 digits of a phone number with non-number characters removed
*/
export const sanitizePhoneNumber = (phoneNumber?: string, options?: OptionsDocument): string => {
  if (options?.options?.internationalMode) {
    return phoneNumber?.replace(/\D/g, '')?.trim()
  }

  return phoneNumber?.replace(/\D/g, '')?.slice(-10) || ''
}

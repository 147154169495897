import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { QuoteItemSection, RenderableItemSubstrate } from '../../builder'
import { getAreaSubstrates } from '../../builder/quote/area-substrates/get-area-substrates'
import getSubstrateAreas from '../../builder/quote/items/get-items/util/get-substrate-areas'
import { parseNumber } from '../util'

// Get materials for substrates in a group
export default function groupedSubstrateMaterials({
  quote,
  options,
  substrateKey,
  groupSection
}: {
  quote: QuoteDocument
  options: OptionsDocument
  substrateKey: string
  groupSection: QuoteItemSection
}) {
  const substrateAreas = getSubstrateAreas({
    quote,
    options: quote.options,
    substrateKey: substrateKey,
    clientOptions: options,
    section: groupSection,
    showValues: true,
    showGroup: true
  })
  const areaSubstrates: RenderableItemSubstrate[] = substrateAreas
    .reduce((acc, substrateArea) => {
      const areaSubstrates = getAreaSubstrates({
        quote,
        options,
        areaKey: substrateArea.additionalFields.areaKey,
        section: groupSection,
        showGroups: true
      })
      return [...acc, ...areaSubstrates]
    }, [])
    .filter((areaSubstrate: RenderableItemSubstrate) => {
      return areaSubstrate.rateKey === substrateKey
    })

  const areaSubstrateMaterialsCost = areaSubstrates.reduce((sum, areaSubstrate) => {
    const materialCost =
      areaSubstrate?.materials?.useCustom && areaSubstrate?.materials?.custom
        ? areaSubstrate.materials.custom
        : areaSubstrate?.materials?.default ?? 0
    return sum + parseNumber(materialCost)
  }, 0)

  return areaSubstrateMaterialsCost
}

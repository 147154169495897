import React from 'react'

import Grid from '../Grid'
import Typography from '../Typography'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'

export interface ImagePlaceholderProps {
  classes?: StyleClasses<typeof useStyles>
  title?: string
  subtitle?: string
  height?: number
  width?: number | string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  placeholder: {
    border: `2px dashed ${theme.palette.grey.A100}`,
    boxSizing: 'border-box',
    textAlign: 'center',
    padding: theme.spacing(2),
    color: theme.palette.grey[700],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    background: theme.palette.grey.A100,
    padding: theme.spacing(1)
  },
  subtitle: {
    background: theme.palette.grey.A100,
    padding: theme.spacing(1)
  }
}))

export default function ImagePlaceholder({
  title,
  subtitle,
  width = '100%',
  height = 100,
  ...otherProps
}: ImagePlaceholderProps) {
  const classes = useStyles(otherProps)

  const style = {
    height,
    width
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={6} alignItems={'center'} justifyContent={'center'}>
        <Grid item sm={12}>
          <div className={classes.placeholder} style={style}>
            {title && (
              <div>
                <Typography variant={'overline'} classes={{ root: classes.title }}>
                  {title}
                </Typography>
              </div>
            )}
            {subtitle && (
              <div>
                <Typography variant={'subtitle2'} classes={{ root: classes.subtitle }}>
                  {subtitle}
                </Typography>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

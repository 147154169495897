import React from 'react'

import { Style } from 'react-style-tag'
import { useClientOptions } from '../ClientOptionsProvider'

/**
 * Injects the customCss from the $options doc
 */
function CustomCss() {
  const { options } = useClientOptions()

  const css = options?.options?.customCss?.value

  return css ? <Style>{css}</Style> : null
}

export default CustomCss

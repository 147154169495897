import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect } from 'formik'
import { getObjectLabels } from '@paintscout/util/builder'
import { Typography, Grid, useClientOptions } from '@ui/paintscout'

const TermsForm = (_: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>This section will show the Contract Terms of your {objectLabels.quote.value}</Typography>
      </Grid>
    </Grid>
  )
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(TermsForm)

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FastField } from 'formik'
import { Grid, FormikHtmlEditor, FormikInputField, Tooltip, InputLabel } from '@ui/paintscout'

const TextForm = (_: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field component={FormikInputField} label={'Title'} name={'section.title'} fullWidth={true} />
      </Grid>
      <Grid item xs={12}>
        <Tooltip title={'Content'} content={<p>This section will show a block of text on the presentation.</p>}>
          <InputLabel>Content</InputLabel>
        </Tooltip>
        <FastField
          component={FormikHtmlEditor}
          name="section.fields.content"
          fullWidth={true}
          debounce={true}
          toolbar={{
            headings: true,
            align: true
          }}
        />
      </Grid>
    </Grid>
  )
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(TextForm)

import React, { useCallback, useLayoutEffect } from 'react'
import { getScrollParent } from '../util'

/**
 * Listens for the scroll on an element and calls onLoadMore when they've passed
 * the scrollPercentage of its height.
 *
 * It returns a ref to be assigned to a HTML element, or you can pass it a ref if you
 * already have one
 */
export const useInfiniteScroll = <T extends HTMLElement>({
  ref = React.createRef<T>(),
  disabled,
  onLoadMore,
  scrollPercentage = 100,
  reverse
}: {
  disabled?: boolean
  ref?: React.RefObject<T>
  scrollPercentage?: number
  reverse?: boolean
  onLoadMore: () => any
}): [React.RefObject<T>] => {
  const handleScroll = useCallback(
    (el: HTMLElement) => {
      if (el) {
        const scrollBottom = el.scrollTop + el.clientHeight
        const scrollPercentageMultiplier = scrollPercentage / 100

        let shouldLoad = false

        if (el.scrollHeight >= el.clientHeight) {
          shouldLoad = true
        } else {
          shouldLoad = reverse
            ? el.scrollTop <= el.scrollHeight - el.scrollHeight * scrollPercentageMultiplier
            : scrollBottom >= el.scrollHeight * scrollPercentageMultiplier
        }

        if (!disabled && shouldLoad) {
          onLoadMore()
        }
      }
    },
    [disabled, onLoadMore, reverse, scrollPercentage]
  )

  // if disabled changes from true to false, check scroll position
  useLayoutEffect(() => {
    if (!disabled) {
      const el = getScrollParent(ref.current)
      handleScroll(el)
    }
  }, [ref, disabled])

  useLayoutEffect(() => {
    const el = getScrollParent(ref.current)
    if (el) {
      const cb = () => handleScroll(el)
      el.addEventListener('scroll', cb)
      return () => el.removeEventListener('scroll', cb)
    }
  }, [ref])

  return [ref]
}

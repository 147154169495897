import React from 'react'
import type { FormControlLabelProps as MuiFormControlLabelProps } from '@material-ui/core/FormControlLabel'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'

export interface FormControlLabelProps extends MuiFormControlLabelProps {}

const FormControlLabel = React.forwardRef(function FormControlLabel(props: FormControlLabelProps, ref) {
  return <MuiFormControlLabel {...props} ref={ref} />
})

export default FormControlLabel

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FastField } from 'formik'

import { Grid, UploadImage, FormikHtmlEditor, FormikInputField } from '@ui/paintscout'

const MissionForm = ({ formik }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values
  const fields = section.fields ? section.fields : ({} as any)

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field component={FormikInputField} label={'Title'} fullWidth={true} name={'section.title'} />
            </Grid>

            <Grid item xs={12}>
              <FastField
                component={FormikHtmlEditor}
                label={'Content'}
                name={'section.fields.content'}
                fullWidth={true}
                debounce={true}
                toolbar={{
                  headings: true,
                  align: true
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <UploadImage
            label={'Image'}
            cloudinaryPublicId={fields.image?.cloudinaryPublicId ?? ''}
            s3PublicKey={fields.image?.s3PublicKey ?? ''}
            src={fields.image?.src ?? ''}
            onUpload={handleImageUpload}
            maxWidth={800}
            maxHeight={1600}
            buttonHeight={100}
            quality={1}
            onClear={handleImageClear}
          />
        </Grid>
      </Grid>
    </div>
  )

  async function handleImageUpload(image) {
    formik.setFieldValue('section.fields.image' as any, image)
  }

  function handleImageClear() {
    formik.setFieldValue('section.fields.image' as any, null)
  }
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(MissionForm)

import type { PresentationPage } from 'paintscout'

export function filterActivePresentationPages(page: PresentationPage) {
  if (!(page?.active ?? true)) {
    return false
  }

  if (!page?.advanced?.useForNav) {
    return false
  }

  if (page.sections?.length === 0) {
    return false
  }

  return true
}

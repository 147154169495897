import React, { useEffect } from 'react'
import type { WithStyles, Theme } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { DropzoneProps as ReactDropzoneProps, DropzoneState, DropzoneRef } from 'react-dropzone'
import ReactDropzone from 'react-dropzone'
import classnames from 'classnames'

import Button from '../Button'
import AddIcon from '@material-ui/icons/Add'

const styles = (theme: Theme) =>
  createStyles({
    root: { position: 'relative', width: '100%', height: '100%' },
    uploadButton: {},
    buttonFullHeight: {
      height: '100%'
    },
    acceptDrop: {},
    activeDrop: {
      borderColor: `${theme.palette.primary.main}`
    },
    rejectDrop: {
      '& $uploadButton': {
        borderColor: theme.palette.error.main,
        padding: 0
      }
    }
  })

export interface DropzoneProps extends WithStyles<typeof styles>, ReactDropzoneProps {
  style?: React.CSSProperties
  className?: string
  text?: string

  /**
   * Makes the button the full height of the dropzone
   *
   * (you may _not_ want this if you want to use a normal sized 'outlined' variant button and keep the size consistent)
   */
  buttonFullHeight?: boolean

  shouldRenderPreview?: boolean
  dropzoneRef?: React.RefObject<DropzoneRef>
  renderPreview?: (dropzoneArgs: DropzoneState) => any
  onError?: (files: File[], errors: string[]) => any
}

function Dropzone({
  classes,
  className,
  text,
  shouldRenderPreview,
  renderPreview,
  style,
  dropzoneRef,
  buttonFullHeight,
  maxSize,
  onError,
  disabled,
  ...dropzoneProps
}: DropzoneProps) {
  function handleDropRejected(files: File[], event) {
    if (onError) {
      onError(
        files,
        files.map((file) => {
          let error = 'FILE_INVALID'
          if (file.size > maxSize) {
            error = 'LIMIT_FILE_SIZE' // matches multer error response for exceeding file size
          }

          return error
        })
      )
    }

    if (dropzoneProps.onDropRejected) {
      return dropzoneProps.onDropRejected(files, event)
    }
  }

  useEffect(() => {
    // Block browser drag & drop behavior so we arent overridden
    const preventEventDefault = (e) => {
      e.preventDefault()
    }
    window.addEventListener('dragenter', preventEventDefault, false)
    window.addEventListener('dragover', preventEventDefault)
    window.addEventListener('drop', preventEventDefault)

    return () => {
      window.removeEventListener('dragenter', preventEventDefault)
      window.removeEventListener('dragover', preventEventDefault)
      window.removeEventListener('drop', preventEventDefault)
    }
  }, [])

  return (
    <ReactDropzone
      {...dropzoneProps}
      maxSize={maxSize}
      onDropRejected={handleDropRejected}
      ref={dropzoneRef}
      disabled={disabled}
    >
      {(dropzoneArgs) => {
        const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive, draggedFiles } = dropzoneArgs
        // Need to second check this as chrome doesnt actully populate these types and rejects everything
        const rejectDrag = isDragReject && draggedFiles.filter((file) => file.type !== '').length > 0
        const buttonMessage = rejectDrag ? 'Invalid File Type' : text || 'Upload'
        return (
          <div
            {...getRootProps()}
            className={classnames({
              [classes.root]: true,
              [className]: !!className,
              [classes.acceptDrop]: isDragAccept,
              [classes.rejectDrop]: rejectDrag,
              [classes.activeDrop]: isDragActive
            })}
            style={style}
          >
            <input {...getInputProps({})} />
            {shouldRenderPreview && renderPreview ? (
              renderPreview(dropzoneArgs)
            ) : (
              <Button
                className={classnames({
                  [classes.uploadButton]: true,
                  [classes.buttonFullHeight]: buttonFullHeight,
                  [classes.activeDrop]: isDragActive
                })}
                icon={disabled ? null : AddIcon}
                variant="outlined"
                fullWidth={true}
                disabled={disabled}
              >
                {buttonMessage}
              </Button>
            )}
          </div>
        )
      }}
    </ReactDropzone>
  )
}

Dropzone.defaultProps = {
  maxSize: 1024 * 200000 // 100mb
}

export default withStyles(styles)(Dropzone)

import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fill: {
    fill: theme.palette.primary.main
  },
  paper: {
    fill: '#fff',
    stroke: theme.palette.primary.main,
    strokeWidth: 5
  }
}))

export default function IntroductionThumbnail({ ...props }) {
  const classes = useStyles(props)

  return (
    <svg viewBox="0 0 150 200">
      <rect x={0} y={80} width={67.5} height={50} className={classes.fill} />

      <rect x={82.5} y={80} width={67.5} height={20} className={classes.fill} />
      <rect x={82.5} y={110} width={67.5} height={20} className={classes.fill} />
    </svg>
  )
}

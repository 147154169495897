import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import ActivityTitle from '../ActivityTitle'
import ActivityFooter from '../ActivityFooter'
import ActivityContent from '../ActivityContent'
import type { ActivityTypeComponentProps } from '../Activity'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import Typography from '../../Typography'
import ActivityOwner from '../ActivityOwner'
import QuoteLabel from '../QuoteLabel'

const useStyles = makeStyles<Theme, ActivityTypeComponentProps>((theme: Theme) => ({
  reason: {
    color: theme.palette.text.secondary
  }
}))

export default function QuoteStatusUpdatedActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props
  const classes = useStyles(props)

  const reason = activity.details.events?.[0]

  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            <QuoteLabel activity={activity} /> status note updated
          </>
        ) : (
          <>Status note updated</>
        )}
        <ActivityOwner activity={activity} />
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {reason && (
        <ActivityContent>
          <Typography variant="subtitle2" className={classes.reason}>
            {reason}
          </Typography>
        </ActivityContent>
      )}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

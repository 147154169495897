import type { Activity } from '@paintscout/api'
import type { ActivityType, QuoteDocument } from 'paintscout'

export function createQuoteActivity<T extends Activity>(
  args: {
    quote: QuoteDocument
    activityType: ActivityType
  } & Omit<Partial<T['details']>, '_id' | 'quote'>
): T {
  const { quote, timestamp, owner, activityType, events, ...otherDetails } = args
  const { contact } = quote
  const additional = !!(activityType === 'accepted' && (otherDetails as any).additional)
  const total = additional
    ? (quote.totals.additionalWork?.price ?? 0) + (quote.totals.additionalWork?.materials ?? 0)
    : quote.totals.after_tax ?? null

  const commonDetails: T['details'] = {
    timestamp: timestamp || quote.dates.updated || Date.now(),
    updated: timestamp || quote.dates.updated || Date.now(),
    owner,
    docType: 'quote',
    activityType,
    events: events !== undefined ? events : [],
    contact: contact
      ? {
          id: contact.id ?? null,
          email: contact.email ?? null,
          firstName: contact.first_name ?? null,
          lastName: contact.last_name ?? null,
          secondaryFirstName: contact.secondary_first_name ?? null,
          secondaryLastName: contact.secondary_first_name ?? null
        }
      : null,
    [quote.is_invoice ? 'invoice' : 'quote']: {
      id: quote._id ?? null,
      number: quote.number ?? null,
      status: quote.status.value ?? null,
      total,
      int_ext: quote.int_ext,
      error: null
    }
  }

  return {
    type: 'activity',
    docIds: [quote._id, contact && contact.id],
    assignedUsers: quote.assignedUsers,
    details: {
      ...commonDetails,
      ...otherDetails
    }
  } as any
}

import type { QuoteSubstrate } from 'paintscout'
import type { RenderableSubItem } from '../../..'
import type { QuoteItemSection } from '../../../..'

// Filter out crew substrate items we dont want to show
export const filterCrewSubstrateItems = ({
  substrate,
  substrateAreas
}: {
  substrate: QuoteSubstrate
  substrateAreas: RenderableSubItem[]
  section: QuoteItemSection
}): boolean => {
  // Filter if no substrate or associated areas
  if (!substrate || !substrateAreas.length) {
    return true
  }

  // Filter based on totals --- commented out to show 0 total substrates on work order
  // const hasHiddenTotals = (substrate?.hidden_totals?.prep ?? 0) !== 0 || (substrate?.hidden_totals?.hours ?? 0) !== 0
  // const hasGroupedTotals = (substrate?.grouped_totals?.prep ?? 0) !== 0 || (substrate?.grouped_totals?.hours ?? 0) !== 0
  // const hasTotals =
  //   (substrate?.totals?.prep ?? 0) !== 0 ||
  //   (substrate?.totals?.hours ?? 0) !== 0 ||
  //   (substrate?.totals?.price ?? 0) !== 0

  // if (section === 'bid' && !hasHiddenTotals && !hasGroupedTotals && !hasTotals) {
  //   return false
  // }

  return false
}

import React from 'react'

import CoreSignatureCanvas from '@ui/core/SignatureCanvas'
import type { SignatureCanvasProps as CoreSignatureCanvasProps } from '@ui/core/SignatureCanvas'

import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'

import Button from '../Button'

export interface SignatureCanvasProps extends Omit<CoreSignatureCanvasProps, 'Button'> {}

const useStyles = makeStyles<Theme, SignatureCanvasProps>((theme) => ({
  canvas: {
    border: `dashed ${theme.typography.pxToRem(2)} ${theme.palette.grey.A100}`
  },
  captionRow: {
    paddingTop: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(10)
  },
  caption: {
    ...theme.typography.button,
    color: theme.palette.grey.A200
  }
}))

function SignatureCanvas(props: SignatureCanvasProps) {
  const classes = useStyles(props)
  return <CoreSignatureCanvas Button={Button} {...props} classes={classes} />
}

export default SignatureCanvas

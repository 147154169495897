import React from 'react'
import type { QuoteView } from '../../../Quote'

export interface PresentationNavContextValue {
  open?: boolean

  currentPage?: string
  currentSection?: string
  view?: QuoteView
  revision?: boolean

  onScroll?: (key: string) => void

  onOpen?: () => void
  onClose?: () => void

  onPageClick?: (ev: React.MouseEvent, pageKey: string) => void
  onSectionClick?: (ev: React.MouseEvent, pageKey: string) => void
  onAcceptClick?: (ev: React.MouseEvent, pending?: boolean) => void
  onPayClick?: (ev: React.MouseEvent, amount: number) => void
  onMoreClick?: (ev: React.MouseEvent, action: string) => void

  allowResponse?: boolean
  hasPending?: boolean

  /**
   * If customer is accepting/declining
   */
  isResponding?: boolean
  isSinglePage?: boolean
  popupMenu?: boolean
}

export const PresentationNavContextInitValue: PresentationNavContextValue = {
  open: false,

  currentPage: null,
  currentSection: null,
  view: 'quote',

  onScroll: () => {},

  onOpen: () => {},
  onClose: () => {},

  onPageClick: () => {},
  onSectionClick: () => {},
  onAcceptClick: () => {},
  onPayClick: () => {},
  onMoreClick: () => {},

  allowResponse: false,
  hasPending: false,
  isSinglePage: false,
  isResponding: false,
  popupMenu: false
}

const PresentationNavContext = React.createContext<PresentationNavContextValue>(PresentationNavContextInitValue)

export default PresentationNavContext

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Dialog as CoreDialog } from '@ui/core'
import type { DialogProps as CoreDialogProps } from '@ui/core'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles<Theme, DialogProps>({
  root: {},
  paper: {},
  paperFullScreen: {}
})

export interface DialogProps extends CoreDialogProps {
  classes?: CoreDialogProps['classes'] & StyleClasses<typeof useStyles>
  children?: React.ReactNode
}

function Dialog(props: DialogProps) {
  const classes = useStyles(props)

  return (
    <CoreDialog {...props} classes={classes}>
      {props.children}
    </CoreDialog>
  )
}

export default Dialog

import Menu from '@material-ui/core/Menu'

import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { MenuItem, useClientOptions, useUser } from '@ui/paintscout'
import { useQuote } from '../../../context/useQuote'
import React, { useState, useEffect } from 'react'
import QuoteHeaderCard from '../QuoteHeaderCard'
import { formatPhoneNumber, isUserAssigned, isUserOwner } from '@paintscout/util/builder'
import { useContactQuery } from '@paintscout/api'

import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import MapIcon from '@material-ui/icons/Map'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import EditIcon from '@material-ui/icons/Edit'
import PeopleIcon from '@material-ui/icons/People'

const addressFormat = require('address-format')

import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    contents: {},
    placeholderContent: {
      paddingLeft: 0
    },
    hoverMenu: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    button: {
      flexGrow: 1,
      marginLeft: 5,
      marginRight: 5
    },
    cardLink: {
      textDecoration: 'none !important',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    },
    menuItemDivider: {
      padding: 0,
      height: 0,
      minHeight: 0,
      paddingBottom: theme.spacing(),
      marginBottom: theme.spacing(),
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    quoteHeaderAddress: {
      whiteSpace: 'pre-wrap'
    }
  })
)

export interface SecondaryContactCardProps {}

export function SecondaryContactCard(props: SecondaryContactCardProps) {
  const classes = useStyles(props)
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    onSelectSecondaryContact,
    onCreateSecondaryContact,
    onEditSecondaryContact,
    onRemoveSecondaryContact,
    isEditable,
    quote
  } = useQuote()
  const { options } = useClientOptions()
  const { user, hasPermissions } = useUser()
  const { secondaryContact } = quote
  const [deletedSecondaryContact, setDeletedSecondaryContact] = useState(false)

  const { data: contactData } = useContactQuery({
    skip: !user || !secondaryContact?.id,
    variables: {
      id: secondaryContact?.id
    }
  })
  useEffect(() => {
    if (contactData && !contactData.contact && secondaryContact) {
      setDeletedSecondaryContact(true)
    }
  }, [contactData])

  const isAssigned = user ? (secondaryContact ? isUserAssigned(secondaryContact, user.user_id) : true) : false
  const isOwner = user ? (secondaryContact ? isUserOwner(secondaryContact, user.user_id) : true) : false
  const canEdit = user
    ? isOwner || (isAssigned && hasPermissions(['write:assigned-contacts'])) || hasPermissions(['write:all-contacts'])
    : false

  const phoneNumber = formatPhoneNumber({
    options,
    phoneNumber: secondaryContact?.phone_number,
    extension: secondaryContact?.phone_ext
  })
  const alternatePhoneNumber = formatPhoneNumber({
    options,
    phoneNumber: secondaryContact?.alternate_phone,
    extension: secondaryContact?.alternate_phone_ext
  })
  const contactInfo = getContactInfo({ phoneNumber, alternatePhoneNumber })

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  return (
    <>
      <QuoteHeaderCard
        classes={{
          root: classes.root,
          contents: classes.contents
        }}
        onClick={handleClick}
        subtitle={<div>{contactInfo.name}</div>}
        showPlaceholder={false}
        clickable={isEditable}
        aria-owns={anchorEl ? 'secondary-contact-menu' : undefined}
        aria-haspopup="true"
        data-testid="quote-contact-card"
      >
        <div>{contactInfo.details}</div>
      </QuoteHeaderCard>
      <Menu id="secondary-contact-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {canEdit && !deletedSecondaryContact && (
          <MenuItem icon={EditIcon} onClick={handleEditContact}>
            Edit Secondary Contact
          </MenuItem>
        )}
        <MenuItem icon={PeopleIcon} onClick={handleSelectContact}>
          Select Existing Contact
        </MenuItem>
        {(user ? hasPermissions(['create:contacts']) : false) && (
          <MenuItem icon={AddIcon} onClick={handleCreateContact}>
            Create & Use New Contact
          </MenuItem>
        )}
        <MenuItem disabled classes={{ root: classes.menuItemDivider }} />
        <MenuItem icon={RemoveIcon} onClick={handleRemoveContact}>
          Remove Secondary Contact
        </MenuItem>

        {(!!phoneNumber || !!secondaryContact?.email || !!secondaryContact?.address) && (
          <MenuItem disabled classes={{ root: classes.menuItemDivider }} />
        )}
        {phoneNumber && (
          <MenuItem onClick={handleOpenCall} icon={PhoneIcon}>
            {phoneNumber}
          </MenuItem>
        )}
        {secondaryContact?.email && (
          <MenuItem onClick={handleOpenEmail} icon={EmailIcon}>
            {secondaryContact.email}
          </MenuItem>
        )}
        {secondaryContact?.address && (
          <MenuItem onClick={handleOpenMap} icon={MapIcon}>
            Open in Google Maps
          </MenuItem>
        )}
      </Menu>
    </>
  )

  function getContactInfo({ phoneNumber, alternatePhoneNumber }) {
    const { secondaryContact } = quote

    const details = []
    let name = null

    if (secondaryContact) {
      const { first_name, last_name, email, alternate_email, company, address, city, province, postal } =
        secondaryContact

      if (company) {
        details.push(
          <div key="company" className="quote-header-company">
            {company}
          </div>
        )
      }

      const fullAddress = addressFormat({ address, city, subdivision: province, postalCode: postal, countryCode: 'US' })
      if (fullAddress && fullAddress.length > 0) {
        details.push(
          <div key="address" className={classnames('quote-header-address', classes.quoteHeaderAddress)}>
            {fullAddress.join('\n')}
          </div>
        )
      }

      if (email) {
        details.push(
          <div key="email" className="quote-header-email">
            {email}
          </div>
        )
      }

      if (alternate_email && !email) {
        details.push(
          <div key="alt-email" className="quote-header-alternate-email">
            {alternate_email}
          </div>
        )
      }

      if (phoneNumber || alternatePhoneNumber) {
        details.push(
          <div key="phone" className="quote-header-phone-number">
            {phoneNumber ? phoneNumber : alternatePhoneNumber}
          </div>
        )
      }

      name = (
        <div key="name">
          {first_name && <span className="quote-header-first-name">{first_name}</span>}
          {first_name && last_name && <span> </span>}
          {last_name && <span className="quote-header-last-name">{last_name}</span>}
        </div>
      )
    }

    return { name, details }
  }

  function handleOpenCall() {
    window.open(`tel:${secondaryContact.phone_number}`)
  }
  function handleOpenEmail() {
    window.open(`mailto:${secondaryContact.email}`, '_blank')
  }
  function handleOpenMap() {
    window.open(`http://maps.google.com/?q=${secondaryContact.address}`, '_blank')
  }

  function handleSelectContact() {
    onSelectSecondaryContact()
    setAnchorEl(null)
  }

  function handleCreateContact() {
    onCreateSecondaryContact()
    setAnchorEl(null)
  }

  function handleEditContact() {
    onEditSecondaryContact()
    setAnchorEl(null)
  }

  function handleRemoveContact() {
    onRemoveSecondaryContact()
    setAnchorEl(null)
  }

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setAnchorEl(null)
  }
}

export default SecondaryContactCard

/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import _get from 'lodash/get'

export function getOverrideableOption(args: {
  options?: OptionsDocument
  selector: string
  quote?: QuoteDocument
  override?: { [key: string]: any }
  quoteSelector?: string
  overrideSelector?: string
  defaultValue?: any
}): any {
  const { selector, options = {}, quoteSelector, quote = {}, defaultValue, override = {}, overrideSelector } = args

  const optionsVal = _get(options, selector, defaultValue)
  const quoteVal = _get(quote, quoteSelector ? quoteSelector : selector, optionsVal)
  const overrideVal = _get(override, overrideSelector ? overrideSelector : selector, quoteVal)

  return overrideVal
}

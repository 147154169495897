import QuoteHeader from './QuoteHeader'
import QuoteHeaderCard from './QuoteHeaderCard'

export * from './QuoteHeaderCard'
export { QuoteHeaderCard }

import ContactCard from './ContactCard'
import QuoteIDCard from './QuoteIDCard'
import QuoteDateCard from './QuoteDateCard'

export { ContactCard, QuoteIDCard, QuoteDateCard }

export default QuoteHeader

import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { FieldAttributes } from 'formik'
import { Field } from 'formik'
import type { FormikCheckboxProps } from '../formik'
import { FormikCheckbox } from '../formik'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Tooltip from '../Tooltip'

const useCheckboxFieldStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '0.5em',
    display: 'block'
  },
  sublabel: {
    marginLeft: theme.spacing(4),
    fontSize: '1rem'
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    alignSelf: 'self-start'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  checked: {
    color: '#292a2d'
  }
}))

/**
 * A helper component to wrap each checkbox in a FormControl with a subtitle, and use the formik field props
 */
const CheckboxField: React.FC<
  FieldAttributes<any> & FormikCheckboxProps & { sublabel?: string; Help?: JSX.Element }
> = ({ sublabel, Help, ...props }) => {
  const classes = useCheckboxFieldStyles({})
  const ref = useRef(null)

  return (
    <FormControl className={classes.root}>
      <Field
        {...props}
        innerRef={ref}
        component={FormikCheckbox}
        checkboxProps={{
          classes: {
            root: classes.checkbox
          }
        }}
        label={
          <div className={classes.labelContainer}>
            {props.label}
            {Help && <Tooltip content={Help} />}
          </div>
        }
      />
      {sublabel && (
        <Typography className={classes.sublabel} variant="subtitle2">
          {sublabel}
        </Typography>
      )}
    </FormControl>
  )
}

export default CheckboxField

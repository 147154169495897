import type { QuoteDocument } from 'paintscout'
import { keyAreaSubstrates } from '../../../area-substrates/key-area-substrates'

// Convert v1 quotes that don't have an AreaSubstrates Order.
export const convertV1Quote = (inputQuote: QuoteDocument): QuoteDocument => {
  let quote = { ...inputQuote }
  Object.keys(quote?.areas ?? {}).forEach((item) => {
    quote = keyAreaSubstrates({ quote, areaKey: item })
  })
  return quote
}

import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import CorePopupMenu from '@ui/core/PopupMenu'
import { makeStyles } from '@material-ui/core'
import type { WithUseStyles } from '../styles'
import type { PopupMenuProps as CorePopupMenuProps } from '@ui/core/PopupMenu'

export interface PopupMenuProps extends Omit<CorePopupMenuProps, 'classes'> {}

const useStyles = makeStyles<Theme, PopupMenuProps>({
  root: {
    display: 'inline-block'
  },
  divider: {
    backgroundColor: '#DDDDDD'
  }
})

function PopupMenu({ ...props }: PopupMenuProps & WithUseStyles<typeof useStyles>) {
  return <CorePopupMenu {...props} />
}

export default PopupMenu

import type { Context } from '../template'

export interface UserContext extends Context {
  estimatorEmail: string
  estimatorFirstName: string
  estimatorLastName: string
  estimatorName: string
  estimatorPhone: string
  estimatorInfo: string
  company: string
}

export function createUserContext(args: {
  user?: { email?: string; firstName?: string; lastName?: string; phoneNumber?: string; company?: string }
  override?: Partial<UserContext>
}): UserContext {
  const { user, override = {} } = args

  return {
    estimatorEmail: user?.email ?? '',
    estimatorFirstName: user?.firstName ?? '',
    estimatorLastName: user?.lastName ?? '',
    estimatorName: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
    estimatorPhone: user?.phoneNumber ?? '',
    estimatorInfo: `${user?.firstName ?? ''} ${user?.lastName ?? ''}<br/>${user?.email ?? ''}${
      user?.phoneNumber ? '<br/>' + user.phoneNumber : ''
    }`,
    company: user?.company ?? '',
    ...override
  }
}

// Checks all strings in array are the same
export default function allSame(array: string[]): boolean {
  const first = array[0]
  return array.reduce((prev, current) => {
    if (first !== current) {
      return false
    }
    return prev
  }, true)
}

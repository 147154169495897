import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { InputLabelProps as CoreInputLabelProps } from '@ui/core/InputLabel'
import CoreInputLabel from '@ui/core/InputLabel'

const useStyles = makeStyles<Theme, InputLabelProps>(
  (theme) => ({
    label: {
      display: 'block',
      color: theme.palette.grey.A400,
      fontWeight: 500,
      letterSpacing: '0.15em',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      '& span': {
        color: theme.palette.error.main,
        position: 'absolute',
        top: 2.5
      }
    },
    labelFocused: {
      color: theme.palette.primary.main
    },
    sublabel: {
      fontSize: '1.2rem',
      color: theme.palette.grey[500],
      fontWeight: theme.typography.fontWeightRegular,
      '&$sublabelFocused': { color: theme.palette.grey[400] }
    },
    sublabelFocused: {},
    required: {},
    error: {}
  }),
  { name: 'PaintScoutInputLabel' }
)

export interface InputLabelProps extends Omit<CoreInputLabelProps, 'classes' | 'variant'> {
  classes?: CoreInputLabelProps['classes'] & StyleClasses<typeof useStyles>
  variant?: 'label' | 'sublabel'
}

function InputLabel(props: InputLabelProps) {
  const classes = useStyles(props)
  const { variant, ...baseInputLabelProps } = props
  const { label, sublabel, sublabelFocused, ...baseClasses } = classes

  return (
    <CoreInputLabel
      classes={{
        root: variant === 'sublabel' ? sublabel : label,
        focused: variant === 'sublabel' ? sublabelFocused : sublabelFocused,
        ...baseClasses
      }}
      {...baseInputLabelProps}
    />
  )
}

export default InputLabel

import uniq from 'lodash/uniq'
import { formatCurrency } from '../../../util'
import type { OptionsDocument } from 'paintscout'

// Combine areaSubstrate labels to be rendered in the area  or substrate description
export default function mergeLabels(
  options: OptionsDocument,
  areaLabels: string[],
  quantities: any,
  prices: any,
  coats: any,
  prep: any,
  products: any
): string[] {
  const res = uniq(areaLabels).map((label) => {
    const parenthesisItems = []

    if (quantities[label]) {
      parenthesisItems.push(quantities[label])
    }
    if (prices[label]) {
      parenthesisItems.push(formatCurrency({ value: prices[label], options }))
    }
    if (coats[label]) {
      parenthesisItems.push(coats[label])
    }
    if (prep[label]) {
      parenthesisItems.push(prep[label])
    }
    if (products[label]) {
      parenthesisItems.push(products[label].join(', '))
    }

    const filteredItems = parenthesisItems.filter((item) => item)

    return filteredItems.length ? `${label} (${filteredItems.join(', ')})` : label
  })

  return res
}

import type { QuoteArea, QuoteDocument } from 'paintscout'

/**
 * Find and return the QuoteArea for a given AreaSubstrate key, null if not found
 */
export function getAreaSubstrateArea({
  quote,
  substrateKey
}: {
  quote: QuoteDocument
  substrateKey: string
}): QuoteArea {
  for (const areaKey in quote.areas) {
    const area = quote.areas[areaKey]
    if (area.substrateOrder?.find((orderItem) => orderItem.key === substrateKey)) {
      return area
    }
  }
  return null
}

import type { SvgIconProps, Theme } from '@material-ui/core'
import { Box, makeStyles, Typography } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import type { Action } from '../ActionButton'
import Button from '../Button'
import type { TileProps } from '../Tile'
import Hidden from '../Hidden'
import Tile from '../Tile'
import Add from '@material-ui/icons/Add'
const MotionButton = motion(Button)
import Switch from '../Switch'
import Checkbox from '../Checkbox'
import Tooltip from '../Tooltip'
import CheckmarkIcon from '@material-ui/icons/Check'
import { getFeatures } from '@paintscout/util/builder'
import get from 'lodash/get'
import { useClientOptions } from '../ClientOptionsProvider'
import { useIsMobile } from '@ui/core/hooks'
import classnames from 'classnames'

export interface TileItem {
  key: string | number
  label: string
  icon?: React.ComponentType<SvgIconProps>
  sublabel?: string
  Thumbnail?: React.FunctionComponent
  color?: string
  active?: boolean
  disabled?: boolean
  name?: string

  /**
   * This item can only be selected on its own. It will deselect any other
   * items when selected
   */
  individual?: boolean
}

export type ValueType = string | number

type TileListVariants = 'standard' | 'select' | 'multiselect'
export interface TileListProps<
  T extends TileItem,
  Variant extends TileListVariants,
  Value extends Variant extends 'multiselect' ? ValueType[] : ValueType
> {
  classes?: StyleClasses<typeof useStyles>
  /**
   * Title of section of tiles
   */
  title?: string

  /**
   * Subtitle of section of tiles
   */
  subtitle?: string

  /**
   * Tile items
   */
  items?: T[]

  /**
   * Use dense variant of Tile components
   */
  dense?: boolean

  /**
   * Disables all tiles
   */
  disabled?: boolean

  /**
   * Use 3 columns
   */
  threeColumns?: boolean

  /**
   * Variants of the TileList for behaviour
   *
   * standard - show all tiles, clicking on them fires onSelect event
   * select - a tile is selected and the rest are hidden
   * multiselect - multiple tiles are selected and the rest is hidden afterwards
   */
  variant?: 'standard' | 'select' | 'multiselect'

  /**
   * Selected item keys. Needs to be an array if multiSelect is true
   */
  selected?: Value

  /**
   * Field to use in item for the tile sublabel
   */
  subLabelField?: string

  /**
   * Action button items
   */
  actions?: ((tile: TileItem) => Action[]) | Action[]

  /**
   * Enable custom tile
   */
  customTile?:
    | boolean
    | {
        title?: string
        selected?: boolean
      }

  /**
   * Show Create button with the given title
   */
  createButton?: string

  addButtonVariant?: 'outlined' | 'text'

  /**
   * Always show unselected tiles
   */
  showUnselected?: boolean

  reorderMode?: boolean
  bulkEditMode?: boolean
  hideReorderSwitch?: boolean

  forceSubLabel?: boolean

  showActive?: boolean
  noItemTitle?: string
  noItemHelpText?: string

  draggable?: boolean
  dragAxis?: 'x' | 'y' | 'xy'

  rightIcon?: JSX.Element

  /**
   * When a tile is selected
   */
  onSelect?: (event: any, selected: Value) => void

  /**
   * When the custom tile is selected
   */
  onCustom?: (event: any, setSelecting: (selecting: boolean) => any) => any

  /**
   * When the create button is clicked
   */
  onCreate?: (event: any) => any

  /**
   * When an action on a tile is clicked
   */
  onActionClick?: (event: React.MouseEvent<HTMLElement>, actionName: string, rowId: string | number) => void

  /**
   * When a tile is drag and dropped
   */
  onReorder?: (items: T[]) => any

  /**
   * When the reorder mode toggle is pressed
   */
  onToggleReorderMode?: () => void

  /**
   * When selected tiles are chosen for bulk delete
   */
  onDeleteBulkItems?: (event: any, keys: ValueType[]) => any

  /**
   * When selected tiles are chosen for bulk transfer
   */
  onTransferBulkItems?: (event: any, keys: ValueType[]) => any

  /**
   * When selected tiles are chosen for bulk export
   */
  onExportBulkItems?: (event: any, keys: ValueType[]) => any

  /**
   * When selected tiles are chosen for bulk edit
   */
  onEditBulkItems?: (event: any, keys: ValueType[]) => any

  /**
   * When selected tiles are chosen for bulk assign
   */
  onAssignBulkItems?: (event: any, keys: ValueType[]) => any

  /**
   * When the bulk edit mode toggle is pressed
   */
  onToggleBulkEditMode?: () => void
}

interface StyleArgs {
  disabled?: boolean
  reorderMode?: boolean
  hideReorderSwitch?: boolean
  threeColumns?: boolean
}

const useStyles = makeStyles<Theme, StyleArgs>((theme) => ({
  root: {},
  dragging: {
    zIndex: 2147483002
  },
  tiles: {
    position: 'relative',
    display: 'grid',
    gridGap: '0.75em',
    justifyContent: 'space-between',
    gridTemplateColumns: ({ threeColumns }) => `repeat(${threeColumns ? 3 : 2}, 1fr)`,
    paddingBottom: '1.5rem',
    paddingRight: '1.5rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr) !important'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr) !important',
      paddingRight: 0
    }
  },
  selectedRow: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '50% 50%',
    alignItems: 'center'
  },
  changeButton: {
    color: theme.palette.grey[600],
    marginLeft: 20,
    width: '50%',
    minWidth: 200,
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 'auto'
    }
  },
  title: {
    ...theme.typography.overline,
    display: 'block'
  },
  subtitle: {
    marginTop: 5
  },
  tile: ({ disabled }) => ({
    width: '100%',
    maxWidth: 'none',
    minHeight: '100%',
    ...(disabled && { cursor: 'default', opacity: 0.8 })
  }),
  subLabel: {
    ...theme.typography.body2,
    color: theme.palette.grey.A200
  },
  emptySubLabel: {
    ...theme.typography.body1,
    paddingTop: '1.91px'
  },
  noItemTextContainer: {
    marginBottom: theme.spacing(2)
  },
  selectedSubLabel: {
    ...theme.typography.body2,
    color: theme.palette.primary.contrastText
  },
  label: {
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 16,
      height: 16,
      marginLeft: 8
    },
    wordBreak: 'break-word'
  },
  addButton: {
    height: '100%',
    minHeight: theme.typography.pxToRem(60)
  },
  helpText: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.grey[500]
  },
  deleteText: {
    color: 'red'
  },
  bulkEditDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    minWidth: 'fit-content',

    height: '100%'
  },
  multiSelectContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    width: '100%',
    marginBottom: '5px'
  },
  multiControlContainer: {
    height: '52px',
    width: 'fit-content'
  },
  horizontalScroller: {
    height: '42px',
    width: '100%',
    overflowX: 'hidden'
  },
  scrollableScroller: {
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      overflowX: 'scroll',
      border: 'none',
      background: `linear-gradient(to right, #e0e0e0, transparent 2%, transparent 98%, #e0e0e0)`
    },
    '&::-webkit-scrollbar': {
      width: '5px',
      borderRadius: '20px',
      height: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'lightgrey',
      borderRadius: '20px',
      height: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: '20px',
      border: '1px solid grey'
    },
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: 'grey lightgrey'
  }
}))

/**
 * A TileList component that can support multi select.
 *
 * It does not support all features that TileList does. Soon, we should use this
 * as the basis for the TileList rewrite.
 */
export default function TileList<
  T extends TileItem,
  Variant extends TileListVariants,
  Value extends Variant extends 'multiselect' ? ValueType[] : ValueType
>({
  title,
  subtitle,
  actions,
  disabled,
  subLabelField,
  dense,
  draggable,
  dragAxis = 'xy',
  variant = 'standard',
  showUnselected = false,
  forceSubLabel = false,
  threeColumns = false,
  customTile,
  rightIcon,
  createButton,
  showActive,
  noItemTitle,
  noItemHelpText,
  reorderMode = false,
  bulkEditMode = false,
  onTransferBulkItems,
  onExportBulkItems,
  onEditBulkItems,
  onDeleteBulkItems,
  onAssignBulkItems,
  onToggleBulkEditMode,
  hideReorderSwitch = false,
  addButtonVariant = 'outlined',

  onCreate,
  onActionClick,
  onSelect,
  onReorder,
  onCustom,
  ...props
}: TileListProps<T, Variant, Value>) {
  const classes = useStyles({ reorderMode, hideReorderSwitch, disabled, threeColumns, ...props })
  const { options, isAdmin } = useClientOptions()
  const features = getFeatures({ options })
  const isMobile = useIsMobile({ xs: true })
  const [selected, setSelected] = useState<ValueType[]>(getValueType(props.selected))
  const [isSelecting, setSelecting] = useState(selected.length === 0)
  let items = isSelecting || showUnselected ? props.items : props.items.filter((item) => selected.includes(item.key))
  const hasBulkActionFeature = get(features, 'markupMaterials.enabled')
  // const hasBulkActionFeature = false
  const hasAction = !!onEditBulkItems || !!onDeleteBulkItems || !!onTransferBulkItems || !!onAssignBulkItems
  const hasBulkAction = (hasBulkActionFeature || isAdmin) && hasAction
  const isMultiSelect = variant === 'multiselect' && items.length > 0

  if (customTile) {
    const customTileItem = {
      key: '__custom__',
      label: (typeof customTile !== 'boolean' && customTile?.title) || 'Custom'
    } as T

    if (typeof customTile !== 'boolean' && customTile?.selected && !isSelecting) {
      items = [customTileItem]
    } else if (isSelecting) {
      items = [customTileItem, ...items]
    }
  }

  function handleReorder({ oldIndex, newIndex }: any) {
    if (oldIndex === newIndex) {
      return
    }
    if (onReorder) {
      const newOrder = arrayMove([...items], oldIndex, newIndex)
      onReorder(newOrder)
    }
  }

  function toggleSelect(selecting = !isSelecting) {
    if (selecting) {
      if (isMultiSelect) {
        setSelected([])
        onSelect(null, [] as Value)
      } else {
        setSelecting(true)
        onSelect(null, null as Value)
      }
    } else {
      setSelecting(false)
      onSelect(null, isMultiSelect ? (selected as Value) : (selected[0] as Value))
    }
  }

  function handleSelectAll() {
    const keys = items.map((item) => item.key).filter((key) => key !== 'all')
    if (selected.length === keys.length) {
      setSelected([])
      onSelect(null, [] as Value)
    } else {
      setSelected(keys)
      onSelect(null, keys as Value)
    }
  }

  // update internal selected if props.selected changes
  useEffect(() => {
    const selected = getValueType(props.selected)
    setSelected(selected)
    setSelecting(selected.length === 0)
  }, [props.selected])

  const multiSelectActions = [
    onAssignBulkItems ? (
      <Button variant="text" onClick={(event) => onAssignBulkItems(event, selected)} disabled={selected.length === 0}>
        Assign
      </Button>
    ) : null,
    onEditBulkItems ? (
      <Button variant="text" onClick={(event) => onEditBulkItems(event, selected)} disabled={selected.length === 0}>
        Edit
      </Button>
    ) : null,
    onDeleteBulkItems && !selected.find((selectedKey) => String(selectedKey).includes('default')) ? (
      <Button
        classes={{ text: classes.deleteText }}
        variant="text"
        disabled={selected.length === 0}
        onClick={(event) => onDeleteBulkItems(event, selected)}
      >
        Delete
      </Button>
    ) : null,
    onTransferBulkItems ? (
      <Button variant="text" disabled={selected.length === 0} onClick={(event) => onTransferBulkItems(event, selected)}>
        Transfer
      </Button>
    ) : null,
    onExportBulkItems ? (
      <Button variant="text" disabled={selected.length === 0} onClick={(event) => onExportBulkItems(event, selected)}>
        Export
      </Button>
    ) : null
  ].filter(Boolean)

  function shouldCancelStart(event: any) {
    // If the user is clicking on the drag-indicator (or any child of the drag-indicator), don't cancel the start
    return !event?.target?.closest('#drag-indicator') || !draggable
  }

  return (
    <SortableTiles
      axis={dragAxis}
      helperClass={classes.dragging}
      rightIcon={rightIcon}
      pressDelay={150}
      shouldCancelStart={shouldCancelStart}
      onSortEnd={handleReorder}
      className={classes.root}
    >
      {/* title/subtitle */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={title || hasBulkAction ? 2 : 0}
        height={
          (title || hasBulkAction) && !(items.length == 0) ? (isMultiSelect && isMobile ? '68px' : '32px') : '0px'
        }
      >
        <Box
          display="flex"
          alignItems={hasBulkAction ? 'center' : 'end'}
          justifyContent="left"
          width={hasBulkAction ? '100%' : '90%'}
        >
          <Box>
            {title && !isMultiSelect && (
              <Typography className={classes.title} variant={'caption'}>
                {title}
              </Typography>
            )}
            {subtitle && !isMultiSelect && (
              <Typography variant="subtitle2" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </Box>
          {/* {items.length > 1 && onReorder && (
            <Box display="flex" alignItems="center">
            <Hidden smDown>
            <Switch size={'small'} checked={reorderMode} onChange={onToggleReorderMode} />
            <Typography variant={'subtitle2'}>Reorder</Typography>
            </Hidden>
            </Box>
          )} */}
          {!disabled &&
            isMultiSelect &&
            !hasBulkAction &&
            isSelecting &&
            (selected.length === 0 ? (
              <Tooltip title={'Select at least 1 tile'} placement="left">
                <Box marginRight={[0, 0, 3]}>
                  <Button icon={CheckmarkIcon} disabled={selected.length === 0} onClick={() => toggleSelect()}>
                    Done
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Box marginRight={[0, 0, 3]}>
                <Button icon={CheckmarkIcon} disabled={selected.length === 0} onClick={() => toggleSelect()}>
                  Done
                </Button>
              </Box>
            ))}
          {items.length >= 1 && !reorderMode && hasBulkAction && (
            <Box display="flex" alignItems="left" className={classes.multiSelectContainer}>
              <Box display="flex" alignItems="center" className={classes.multiControlContainer}>
                <Switch size={'small'} checked={bulkEditMode} onChange={onToggleBulkEditMode} />
                <Typography variant={'subtitle2'}>Multi-Select</Typography>
                {bulkEditMode && isSelecting && (
                  <>
                    <Checkbox checked={selected.length === items.length} onChange={handleSelectAll} />
                    <Typography onClick={() => handleSelectAll()} variant={'subtitle2'}>
                      Select All
                    </Typography>
                  </>
                )}
              </Box>
              {bulkEditMode && isSelecting && (
                <div
                  className={classnames({
                    [classes.horizontalScroller]: true,
                    [classes.scrollableScroller]: multiSelectActions.length > 2 && isMobile
                  })}
                >
                  <div className={classes.bulkEditDiv}>{multiSelectActions}</div>
                </div>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {/* no items text */}
      {items.length == 0 && (noItemTitle || noItemHelpText) && (
        <div className={classes.noItemTextContainer}>
          <Typography variant="h4">{noItemTitle}</Typography>
          <Typography className={classes.helpText}>{noItemHelpText}</Typography>
        </div>
      )}
      {/* tiles */}
      <div className={classes.tiles}>
        {items.map((item, index) => {
          const isSelected =
            selected.includes(item.key) ||
            // if it's a custom tile, we're not selecting, and customTile.selected is true
            (!isSelecting && item.key === '__custom__' && typeof customTile !== 'boolean' && customTile?.selected)

          return (
            <SortableTile
              id={item.key}
              key={item.key}
              index={index}
              dense={dense}
              draggable={draggable}
              disabled={disabled || item.disabled || item.key === '__custom__'}
              disableTile={disabled || item.disabled || (isMultiSelect && item.key === 'all')}
              active={showActive ? item.active : undefined}
              selected={isSelected}
              Thumbnail={item.Thumbnail}
              color={item.color}
              className={classes.tile}
              actions={bulkEditMode ? null : typeof actions === 'function' ? actions(item) : actions}
              onActionClick={onActionClick}
              rightIcon={rightIcon}
              onClick={(event: any) => {
                const individualSelected = selected.find((key) => items.find((i) => i.key === key)?.individual)

                if (item.key === '__custom__' && onCustom) {
                  if (isSelecting) {
                    setSelecting(false)
                  }
                  onCustom(event, setSelecting)
                } else if (variant === 'select' || isMultiSelect) {
                  // show all items if we weren't already
                  if (!isSelecting) {
                    toggleSelect()
                  }

                  // if the item is an individual item, or we're not multiselect, set the selected
                  // to this item only
                  if ((item.individual || variant === 'select') && !isSelected) {
                    setSelected([item.key])
                  } else {
                    // if we had an individual selected before, remove it from selected
                    if (individualSelected) {
                      setSelected([])
                    }

                    // update selected with this item
                    setSelected((prev) => (isSelected ? prev.filter((i) => i !== item.key) : [...prev, item.key]))
                  }

                  if (variant === 'select' && !isSelected) {
                    // if we selected an item in single select mode, hide the other items
                    setSelecting(false)

                    // fire events
                    onSelect(event, item.key as Value)
                  }
                } else {
                  // tiles are not selectable, so just fire onSelect for that tile
                  onSelect(event, item.key as Value)
                }
              }}
            >
              <label className={classes.label}>
                {item.label}
                {item.icon && <item.icon />}
              </label>
              {item[subLabelField] && items[index] && isSelected ? (
                <label className={classes.selectedSubLabel}>{items[index][subLabelField]}</label>
              ) : (
                <label className={classes.subLabel}>{item[subLabelField]}</label>
              )}
              {!item[subLabelField] &&
                forceSubLabel && ( // Maintains height if no sublabel
                  <label className={classes.emptySubLabel}>
                    <p></p>
                  </label>
                )}
            </SortableTile>
          )
        })}
        {/* create button */}
        {createButton && (!bulkEditMode || items.length == 0) && (
          <Button
            classes={{ root: classes.addButton }}
            variant={addButtonVariant}
            color={'primary'}
            size={'medium'}
            icon={Add}
            fullWidth={true}
            onClick={(event) => {
              if (onCreate) {
                onCreate(event)
              }
            }}
          >
            <Hidden smDown={true}>{createButton}</Hidden>
            <Hidden mdUp={true}>{'Add'}</Hidden>
          </Button>
        )}
        {/* change selection button */}
        {!disabled && !isSelecting && variant !== 'standard' && !showUnselected && (
          <MotionButton
            className={classes.changeButton}
            variant={'text'}
            size={'medium'}
            onClick={() => toggleSelect()}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.1
              }
            }}
          >
            Change Selection
          </MotionButton>
        )}
      </div>
    </SortableTiles>
  )
}

// convert props.selected into an array if a single value was provided
function getValueType(val: ValueType[] | ValueType): ValueType[] {
  return val ? (Array.isArray(val) ? val : [val]) : []
}

const itemVariants = {
  hidden: { opacity: 0, transition: { duration: 0.2 } },
  show: { opacity: 1 }
}

const transition = {
  type: 'spring',
  damping: 100,
  stiffness: 1000
}

const SortableTile: React.ComponentClass<any, any> = SortableElement(function SortableTile({
  disableTile,
  draggable,
  ...props
}: TileProps & { disableTile?: boolean }) {
  // wrap the tile in a div so that the transitions from drag & drop don't
  // conflict with MotionTile
  return (
    <div>
      <motion.div
        style={{ height: '100%' }}
        variants={itemVariants}
        // only animate position change when dragging is disabled (otherwise animations conflict with react-sortable-hoc)
        layout={!draggable ? 'position' : undefined}
        transition={!draggable ? transition : undefined}
      >
        <Tile {...props} draggable={draggable} disabled={disableTile} />
      </motion.div>
    </div>
  )
})

const SortableTiles: React.ComponentClass<any, any> = SortableContainer((props: any) => <div {...props} />)

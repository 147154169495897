import type { Theme } from '@material-ui/core'
import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => {
  return {
    icon: {
      position: 'absolute',
      bottom: -6,
      left: -5,
      zIndex: -1,
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderBottom: `12px solid ${theme.palette.grey[200]}`,
      borderRight: '8px solid transparent',
      transform: 'rotate(-145deg)'
    },
    root: (props: MockTextMessageProps) => {
      return {
        position: 'relative',
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
        padding: theme.spacing(1, 2),
        borderRadius: 12,
        maxWidth: props.maxWidth ? props.maxWidth : 400
      }
    }
  }
})

export interface MockTextMessageProps {
  children: JSX.Element
  maxWidth?: number
}

const MockTextMessage = (props: MockTextMessageProps) => {
  const classes = useStyles(props)
  const { children } = props
  return (
    <>
      <Box className={classes.root}>
        {/* {children} <ChatBubbleIcon fontSize="large" className={classes.icon} /> */}
        {children} <div className={classes.icon} />
      </Box>
    </>
  )
}

export default MockTextMessage

import type { QuoteDocument, LineItem, OptionsDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { roundHours } from '../util/round-hours'
import { roundPriceQuoteOptions } from '../util'
import { getFeature } from '../../builder/options/get-features/get-feature'

export function calculateLineItem(quote: QuoteDocument, lineItem: LineItem, options: OptionsDocument) {
  const updatedLineItem = {
    ...lineItem,
    price: parseNumber(lineItem.price),
    quantity: parseNumber(lineItem?.quantity ?? 0),
    hoursPerUnit: parseNumber(lineItem?.hoursPerUnit ?? 0),
    pricePerUnit: parseNumber(lineItem?.pricePerUnit ?? 0)
  }

  const hoursPerUnitLineItems = getFeature({ options, path: 'quotes.hoursPerUnitLineItems' })

  const hours = updatedLineItem.show_crew ? parseNumber(updatedLineItem.hours) : 0
  const calculateByHourlyRate = updatedLineItem.calculate || updatedLineItem.calculateBy === 'hourlyRate'
  const calculateByQuantity = updatedLineItem.calculateBy === 'quantity'
  const customHourlyRate = updatedLineItem.customHourlyRate
  const hourlyRate = customHourlyRate ? updatedLineItem.hourlyRate : quote.hourly_rate

  if (calculateByHourlyRate) {
    const price = hours * hourlyRate
    updatedLineItem.price = price
  }

  if (
    calculateByQuantity &&
    (updatedLineItem.quantity || updatedLineItem.pricePerUnit || updatedLineItem.hoursPerUnit)
  ) {
    const price = updatedLineItem.quantity * updatedLineItem.pricePerUnit
    const quantityHours = hoursPerUnitLineItems ? updatedLineItem.quantity * updatedLineItem.hoursPerUnit : hours
    updatedLineItem.price = price
    updatedLineItem.hours = roundHours(quantityHours, quote)
  }

  updatedLineItem.price = roundPriceQuoteOptions(updatedLineItem.price, quote)

  updatedLineItem.totals = {
    materials: 0,
    afterMaterials: 0
  }

  /* Materials */

  if (updatedLineItem.materials) {
    updatedLineItem.totals.materials = updatedLineItem.materials.reduce((acc, i) => {
      if (i?.totalPrice?.useCustom) {
        const totalPrice = i.totalPrice.custom ? i.totalPrice.custom : 0
        return (acc += totalPrice)
      }

      if (!i.price) {
        return acc
      }

      const quantity = i.quantity ? parseNumber(i.quantity) : 1
      const price = i.price.useCustom ? i.price.custom : i.price.default

      if (quantity && price) {
        acc += quantity * price
      }

      return acc
    }, 0)
  }

  updatedLineItem.totals.materials = roundPriceQuoteOptions(updatedLineItem.totals.materials, quote)
  updatedLineItem.totals.afterMaterials = updatedLineItem.price + updatedLineItem.totals.materials

  return updatedLineItem
}

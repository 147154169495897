import { Divider, makeStyles, Typography } from '@material-ui/core'
import type { Activity, MessageActivity } from '@paintscout/api'
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ActivityTimeline from '../ActivityTimeline'
import Flex from '../Flex'
import { useInfiniteScroll } from '@ui/core'
import type { MessageInputProps } from '../MessageInput'
import MessageInput from '../MessageInput'
import Spinner from '../Spinner'
import type { WithUseStyles } from '../styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    minHeight: 60,
    padding: theme.spacing(),
    paddingBottom: '5px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(),
    overflow: 'auto'
  },
  input: {
    minHeight: 40,
    flexShrink: 0
  },
  spinner: {
    marginBottom: theme.spacing(2)
  },
  logo: {
    display: 'flex',
    alignItems: 'start',
    '& img': {
      minWidth: 50,
      marginRight: theme.spacing(),
      maxHeight: theme.typography.pxToRem(50),
      maxWidth: theme.typography.pxToRem(200),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.typography.pxToRem(120)
      }
    }
  }
}))

export interface ChatBoxProps extends WithUseStyles<typeof useStyles> {
  loading?: boolean
  hasMore?: boolean
  messages: MessageActivity[]
  placeholder?: string
  name: string
  status?: string
  disabled?: boolean
  isActive?: boolean
  onDelete?: (activity: MessageActivity) => any
  onUnread?: (activities: MessageActivity[]) => any
  onLoadMore?: () => any
  onSendMessage?: (value: string) => Promise<any>
}

export interface ChatBoxRef {
  scrollToBottom: () => any
}

export default React.forwardRef<ChatBoxRef, ChatBoxProps>(function ChatBox(props, ref) {
  const {
    name,
    status,
    placeholder,
    messages,
    loading,
    hasMore,
    disabled,
    onDelete,
    onLoadMore,
    onUnread,
    onSendMessage
  } = props
  const classes = useStyles(props)

  const contentRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    if (contentRef.current) {
      // scrollHeight seems to be a few px short for some reason. not sure why (padding/margin?), but adding 60 is an easy fix
      contentRef.current.scrollTo({ top: contentRef.current.scrollHeight + 60, behavior: 'auto' })
    }
  }, [])

  /**
   * Expose scrollToBottom method to parent via ref
   */
  useImperativeHandle(
    ref,
    () => ({
      scrollToBottom
    }),
    [scrollToBottom]
  )

  useEffect(() => {
    onUnread?.(
      messages.filter((message) => {
        if (message.__typename === 'MessageActivity') {
          const isCustomer = message.details.message.from.isCustomer
          const isRead = !!message.details.message.readAt
          const isDeleted = !!message.details.message.deleted

          return !isCustomer && !isRead && !isDeleted
        }

        return false
      }) as MessageActivity[]
    )

    // eslint-disable-next-line
  }, [messages])

  useInfiniteScroll({
    ref: contentRef,
    scrollPercentage: 90,
    reverse: true,
    disabled: loading || !hasMore,
    onLoadMore: async () => {
      const oldHeight = contentRef.current.scrollHeight
      await onLoadMore()
      const top = contentRef.current.scrollHeight - oldHeight
      contentRef.current.scrollTo({
        top
      })
    }
  })

  function handleAction(action: string, activity: Activity) {
    switch (action) {
      case 'delete':
        onDelete(activity as MessageActivity)
        break
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Flex direction="column">
          <Typography variant="body1">{name}</Typography>
          {status && (
            <Typography color="inherit" variant="subtitle2">
              {status}
            </Typography>
          )}
        </Flex>
        {/* <div className={classes.logo}> */}
        {/* <img src={options.options.logo.value} /> */}
        {/* </div> */}
      </div>
      <Divider />
      <div className={classes.content} ref={contentRef} data-testid="chat-content">
        {loading && <Spinner className={classes.spinner} />}
        <ActivityTimeline ascending activities={messages} showContent actions onAction={handleAction} />
      </div>
      <div className={classes.input}>
        <Divider />
        <ChatInput
          disabled={disabled}
          placeholder={placeholder}
          onSend={async (value) => {
            await onSendMessage(value)
          }}
        />
      </div>
    </div>
  )
})

function ChatInput({
  onSend,
  ...props
}: { onSend: (value: string) => any } & Partial<Omit<MessageInputProps, 'onSend'>>) {
  const [value, setValue] = useState('')

  return (
    <MessageInput
      inputProps={{
        'data-testid': 'chat-input'
      }}
      {...props}
      value={value}
      onChange={(ev) => setValue(ev.currentTarget.value)}
      onSend={async () => {
        const prevValue = value
        if (onSend) {
          setValue('')
          await onSend(prevValue).catch((e) => {
            console.error(e)
            setValue(prevValue)
          })
        }
      }}
    />
  )
}

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import ActivityTitle from '../ActivityTitle'
import ActivityFooter from '../ActivityFooter'
import ActivityContent from '../ActivityContent'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import { useClientOptions } from '../../ClientOptionsProvider'
import { getObjectLabels } from '@paintscout/util/builder'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import QuoteLabel from '../QuoteLabel'
import type { AcceptedActivityDetails } from '@paintscout/api'

const useStyles = makeStyles<Theme, ActivityTypeComponentProps<AcceptedActivityDetails>>((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  reason: {
    color: theme.palette.text.secondary
  },
  signatureWrapper: {
    maxHeight: theme.typography.pxToRem(50),
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  }
}))

export default function QuoteAcceptedctivity(props: ActivityTypeComponentProps<AcceptedActivityDetails>) {
  const { activity, showDocTypeDetails, showContent } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()

  const objectLabels = getObjectLabels({ options })

  const signature = activity?.details?.signature

  const title = activity?.details?.additional ? (
    <ActivityTitle>
      <>
        <Highlight>{objectLabels.additionalWork.value}</Highlight> was <Highlight>Accepted</Highlight>
      </>
    </ActivityTitle>
  ) : (
    <ActivityTitle>
      {showDocTypeDetails ? (
        <>
          <QuoteLabel activity={activity} /> was <Highlight>Accepted</Highlight>
        </>
      ) : (
        <>
          <Highlight>Accepted</Highlight>
        </>
      )}
    </ActivityTitle>
  )

  return (
    <>
      {title}
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {signature && showContent && (
        <ActivityContent className={classes.content}>
          <div className={classes.signatureWrapper}>
            <img alt={'Estimator Signature'} src={signature} />
          </div>
        </ActivityContent>
      )}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'

import { Typography, FilePreview, Grid, HtmlContent, ImagePlaceholder } from '@ui/paintscout'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {},
    image: {
      '& img': {
        borderRadius: theme.spacing(1)
      }
    }
  }
})

export default function TwoColumnPresentationSection(props: PresentationSectionProps) {
  const { section, WrapperComponent, showHelp } = props

  const classes = useStyles(props)
  const fields = section?.fields ?? ({} as any)
  const { title } = section
  const { leftText, leftImage, leftCustom, rightText, rightImage, rightCustom } = fields
  const leftType = fields?.leftType ?? 'image'
  const rightType = fields?.rightType ?? 'text'

  const alignItems = getAlignment(fields?.verticalAlign)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 6

  let direction = 'row'
  if (isMobile && rightType === 'text' && leftType !== 'text') {
    direction = 'row-reverse'
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3}>
        {title && (
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={responsiveSpacing}
            alignItems={alignItems}
            direction={direction as any}
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={6}>
              {leftType === 'text' && <HtmlContent content={leftText} classes={{ root: classes.content }} />}
              {leftType === 'custom' && <HtmlContent content={leftCustom} classes={{ root: classes.content }} />}
              {leftType === 'image' && (
                <>
                  {leftImage && <FilePreview classes={{ root: classes.image }} file={leftImage} />}
                  {!leftImage && showHelp && <ImagePlaceholder width={'100%'} height={150} title={'Image'} />}
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {rightType === 'text' && <HtmlContent content={rightText} classes={{ root: classes.content }} />}
              {rightType === 'custom' && <HtmlContent content={rightCustom} classes={{ root: classes.content }} />}
              {rightType === 'image' && (
                <>
                  {rightImage && <FilePreview classes={{ root: classes.image }} file={rightImage} />}
                  {!rightImage && showHelp && <ImagePlaceholder width={'100%'} height={150} title={'Image'} />}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WrapperComponent>
  )

  function getAlignment(align: string) {
    switch (align) {
      case 'top':
        return 'flex-start'
      case 'middle':
        return 'center'
      case 'bottom':
        return 'flex-end'
      default:
        return 'flex-start'
    }
  }
}

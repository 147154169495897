/**
 * Check if this item is split between bid/options
 * @param  {IsSplitItem}    args
 * @return {string}
 */

import type { QuoteDocument, OptionsDocument } from 'paintscout'
import { getItem } from '../get-items'
import type { QuoteItemSection } from '../../'
import { isSplitArea } from '../../areas/is-split-area'

export function isSplitItem(args: {
  quote: QuoteDocument
  options: OptionsDocument
  keys: string[]
  section?: QuoteItemSection
}): boolean {
  const { quote, options, keys, section: referenceSection } = args

  let isSplit = false
  keys.forEach((key) => {
    const item = getItem({ quote, options, key })
    const { section: itemSection } = item ?? {}

    if (referenceSection && itemSection !== referenceSection) {
      isSplit = true
    } else if (item?.type === 'area') {
      if (!isSplit && isSplitArea({ quote, areaKey: key, options, showGroups: true })) {
        isSplit = true
      }
    }
  })
  return isSplit
}

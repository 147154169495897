import React from 'react'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityFooter from '../ActivityFooter'
import ActivityOwner from '../ActivityOwner'
import ActivityTitle from '../ActivityTitle'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'

export default function QuoteThankYouActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props

  return (
    <>
      <ActivityTitle>
        <Highlight>Thank You Message</Highlight> Sent
        <ActivityOwner activity={activity} />
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

export default function getInitialImageWidth(): number {
  let initialImageWidth
  const currentWidth = window.innerWidth
  if (currentWidth < 400) {
    initialImageWidth = 300
  } else if (currentWidth < 600) {
    initialImageWidth = 500
  } else if (currentWidth < 800) {
    initialImageWidth = 700
  } else {
    initialImageWidth = 1200
  }
  return initialImageWidth
}

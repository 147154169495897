import type { QuoteDocument } from 'paintscout'

export function getSubstrateGroup({ quote, rateKey }: { quote: QuoteDocument; rateKey: string }) {
  let r = null
  Object.keys(quote.groups ?? []).forEach((groupKey) => {
    if (quote.groups[groupKey].children.includes(rateKey)) {
      r = groupKey
    }
  })
  return r
}

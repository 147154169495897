import React from 'react'
import type { HiddenProps as MuiHiddenProps } from '@material-ui/core/Hidden'
import { default as MuiHidden } from '@material-ui/core/Hidden'

export interface HiddenProps extends MuiHiddenProps {
  children?: React.ReactNode
}

const Hidden = function Hidden(props: HiddenProps) {
  return <MuiHidden {...props} />
}

export default Hidden

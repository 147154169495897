import type { HTMLProps } from 'react'
import React, { useMemo } from 'react'

import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'

import classnames from 'classnames'

const striptags = require('striptags')

export interface HtmlContentProps extends WithStyles<typeof styles>, HTMLProps<HTMLDivElement> {
  content: string
  includeTags?: string[]
  preWrap?: boolean
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    preWrap: {
      whiteSpace: 'pre-wrap !important' as any,
      '& p': {
        whiteSpace: 'pre-wrap !important' as any
      }
    },
    paragraphs: {
      '& p': {
        whiteSpace: 'pre-wrap'
      },
      '& p, & li': {
        marginTop: 0,
        marginBottom: 0
      },
      // keeps an empty line for empty p tags
      '& p:after': {
        content: "''",
        display: 'inline-block',
        width: 0
      },
      '& ul, & ol': {
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: theme.spacing(4)
      },
      '& strong, & b': {
        fontWeight: 500
      }
    }
  })

const HtmlContent = React.forwardRef<HTMLInputElement, HtmlContentProps>(function HtmlContent(
  { classes, content, includeTags = [], preWrap, ...props }: HtmlContentProps,
  ref
) {
  const sanitizedContent = useMemo(sanitizeContent, [content])
  const plainContent = useMemo(getPlainContent, [content])

  return (
    <div
      ref={ref}
      className={classnames({
        [classes.root]: true,
        [classes.paragraphs]: true,

        // This is for v1 quotes. If they have no HTML, we need pre-wrap or else
        // they'll just be all smushed together.
        // As soon as they're edited, they'll get <p> wrappers and be fine.
        [classes.preWrap]:
          preWrap ?? (sanitizedContent === plainContent || sanitizedContent === `<p>${plainContent}</p>`)
      })}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      {...props}
    />
  )

  function getPlainContent() {
    const allowedTags = []
    const replacement = ''
    return striptags(content, allowedTags, replacement)
  }

  function sanitizeContent() {
    const allowedTags = [
      ...includeTags,
      'b',
      'u',
      'i',
      'ins',
      'em',
      'br',
      'strong',
      'del',
      'p',
      'ul',
      'ol',
      'li',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'img',
      'div',
      'span',
      'mark',
      '/mark', // Some descriptions have <//mark> instead of </mark>
      'a'
    ]
    const replacement = '\n'

    const sanitized = striptags(
      (content || '').replace(/<(\d+)/g, '&lt;$1').replace(/(^|[^a-zA-Z"])>(\d+)/g, '$1&gt;$2'), // Some descriptions have &gt; and &lt; instead of > and <
      allowedTags,
      replacement
    )

    if (sanitized.trim() && !sanitized.startsWith('<') && !sanitized.endsWith('>')) {
      return `<p>${sanitized}</p>`
    }

    return sanitized
  }
})

export default withStyles(styles)(HtmlContent)

import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import isTouchDevice from 'is-touch-device'
import React, { useRef, useState } from 'react'
import Keypad from '../Keypad'
import type { InputFieldProps } from '../InputField'
import InputField from '../InputField'
import { useUser } from '../UserProvider'

export interface KeypadInputProps extends Omit<InputFieldProps, 'classes'>, WithStyles<typeof styles> {
  showKeypad?: 'always' | 'never' | 'touch'
  allowDecimal?: boolean
  allowNegative?: boolean
  flipX?: boolean
}

const styles = () => {
  return createStyles({
    root: {
      position: 'relative'
    },
    wrapper: {
      position: 'relative',
      display: 'inline-block',
      width: '100%'
    },
    keypad: {
      position: 'absolute'
    },
    inputFocused: {},
    inputInput: {},
    inputWrapper: {},
    icon: {},
    adornment: {}
  })
}

function KeypadInputField({
  showKeypad,
  onBlur,
  autoFocus,
  name,
  allowDecimal,
  allowNegative,
  classes,
  flipX,
  ...inputProps
}: KeypadInputProps) {
  const [open, setOpen] = useState(autoFocus)
  const [inputElement, setInputElement] = useState(null)
  const { preferences } = useUser()
  const keypadEnabled = isEnabled()

  const keypadRef = useRef(null)

  function toggle() {
    setOpen(!open)
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    // This was an extra bit to stop the underlying (non-keypad)
    // input from firing the blur event when the keypad was open.
    if ((keypadEnabled && !open && onBlur) || (!keypadEnabled && onBlur)) {
      onBlur(event)
    }
  }

  function handleFocus(event) {
    // Select the text when the input is focused
    event.target.select()
  }

  setTimeout(() => {
    if (keypadRef && keypadRef.current && keypadRef.current.scrollIntoView) {
      keypadRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  })

  return (
    <div className={classes.wrapper}>
      <InputField
        {...inputProps}
        classes={{
          root: classes.root,
          inputFocused: classes.inputFocused,
          inputWrapper: classes.inputWrapper,
          icon: classes.icon,
          adornment: classes.adornment,
          inputInput: classes.inputInput
        }}
        autoFocus={autoFocus}
        onFocus={handleFocus}
        onBlur={handleInputBlur}
        onClick={toggle}
        name={name}
        inputRef={(element) => {
          setInputElement(element)
        }}
        readOnly={keypadEnabled}
      />
      {keypadEnabled && (
        <Keypad
          value={inputProps.value as number}
          ref={keypadRef}
          allowNegative={allowNegative}
          allowDecimal={allowDecimal}
          classes={{ root: classes.keypad }}
          onClose={toggle}
          onChange={inputProps.onChange}
          open={open}
          name={name}
          flipX={flipX}
          anchor={inputElement}
        />
      )}
    </div>
  )

  function isEnabled() {
    if (inputProps.disabled) {
      return false
    }
    // Use showKeypad if it exists. If not,
    const keypadPreference = preferences?.keypad ?? 'touch'
    const keypad = showKeypad ?? keypadPreference

    return (
      keypad === 'always' ||
      (keypad === 'touch' && isTouchDevice()) ||
      localStorage.getItem('e2e.forceKeypad') === 'true'
    )
  }
}

export default withStyles(styles)(KeypadInputField)

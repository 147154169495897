import React from 'react'
import type { MenuItemProps as MuiMenuItemProps } from '@material-ui/core/MenuItem'
import MuiMenuItem from '@material-ui/core/MenuItem'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '../theme'
import { makeStyles } from '@material-ui/core'

export interface MenuItemProps extends MuiMenuItemProps {
  classes?: MuiMenuItemProps['classes'] & StyleClasses<typeof useStyles>
  icon?: React.ComponentType<SvgIconProps>
  image?: string
  iconAfter?: boolean
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  children?: React.ReactNode
  iconOnly?: boolean
}

const useStyles = makeStyles<Theme, MenuItemProps>((_theme: Theme) => ({
  root: {},
  selected: {},
  gutters: {},
  icon: {},
  iconAfter: {}
}))

const MenuItem = React.forwardRef<HTMLUListElement, MenuItemProps>(function MenuItem(
  { icon, image, children, classes, iconAfter, button: _button, ...props },
  _ref
) {
  const Icon = icon

  return (
    <MuiMenuItem {...props} classes={{ root: classes?.root, gutters: classes?.gutters, selected: classes?.selected }}>
      {icon && !iconAfter && <Icon className={classes?.icon} />}
      {image && !icon && <img src={image} />}
      {children}
      {icon && iconAfter && <Icon className={classes?.iconAfter} />}
    </MuiMenuItem>
  )
})

MenuItem.defaultProps = {
  disabled: false,
  iconAfter: false
}

export default MenuItem

/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { UpdateableItem, UpdateableLineItem, UpdateableArea, QuoteItemSection } from '../../index'
import { updateLineItem } from '../../index'
import { updateArea } from '../../areas'
import type { UpdateableGroup } from '../../groups'
import { updateGroup } from '../../groups'

export function updateItem(args: {
  quote: QuoteDocument
  item: UpdateableItem
  options: OptionsDocument
  calculate?: boolean
  insertAfterKey?: string
  section?: QuoteItemSection
}): QuoteDocument {
  const { quote, options, item, calculate, insertAfterKey, section } = args

  if (item.type === 'lineItem') {
    return updateLineItem({ quote, options, item: item as UpdateableLineItem, calculate, insertAfterKey })
  } else if (item.type === 'area') {
    return updateArea({ quote, options, area: item as UpdateableArea, calculate, insertAfterKey })
  } else if (item.type === 'group') {
    return updateGroup({ quote, options, group: item as UpdateableGroup, calculate, section })
  }
  return quote
}

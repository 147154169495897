import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import PresentationNavText from '../PresentationNavText'

const useStyles = makeStyles((_theme: Theme) => {
  return {
    root: {
      '@media print': {
        display: 'none'
      }
    }
  }
})

export interface PresentationNavSideProps {
  classes?: any
}

export default function PresentationNavSide({ ...props }: PresentationNavSideProps) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <PresentationNavText />
    </div>
  )
}

import React from 'react'
import type { PresentationSectionProps } from '../../'

import QuoteTerms from '../../../Quote/QuoteTerms'

export default function Terms(props: PresentationSectionProps) {
  const { WrapperComponent } = props

  return (
    <WrapperComponent {...props}>
      <QuoteTerms isPreview={props?.isPreview} isCustomerView={props?.isCustomerView} />
    </WrapperComponent>
  )
}

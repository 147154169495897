/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument, QuoteSubstrate } from 'paintscout'
import type { UpdateableSubstrate } from '..'
import type { QuoteItemSection } from '../../..'
import { generateSubstrateDescription } from '../../util/generate-substrate-description'
import { getItemSection } from '../../util/get-item-section'
import { getQuoteOptions } from '../../util/get-options'

// Convert a quoteSubstrate retrieved from quote.substrates
// via key to an UpdateableSubstrate to be edited.
export function convertSubstrate({
  key,
  quote,
  options,
  substrate,
  showHiddenTotals,
  sectionOverride,
  groupKey
}: {
  key: string
  quote: QuoteDocument
  options: OptionsDocument
  substrate: QuoteSubstrate
  showHiddenTotals?: boolean
  sectionOverride?: QuoteItemSection
  groupKey?: string
}): UpdateableSubstrate {
  const type = 'substrate' as const
  const label = substrate.label

  const quoteOptions = getQuoteOptions({ options, quote })
  const substrateSection = getItemSection(substrate)
  const section = sectionOverride ?? substrateSection

  const clientLabel = substrate.clientLabel
    ? {
        ...substrate.clientLabel,
        useCustom: substrateSection === section ? substrate.clientLabel?.useCustom : false
      }
    : {
        useCustom: false,
        default: substrate.client_label,
        custom: ''
      }

  const substrateDescription = {
    useCustom: substrateSection === section ? !!substrate.area_string?.use_custom : false,
    custom: substrate.area_string?.custom ? substrate.area_string.custom : substrate.area_string?.value,
    default: generateSubstrateDescription({
      quote,
      clientOptions: options,
      substrateKey: key,
      options: quoteOptions,
      section,
      showGroup: groupKey ? true : false,
      groupKey
    })
  }

  const prep =
    showHiddenTotals && substrate?.hidden_totals?.prep
      ? {
          useCustom: false,
          default: substrate.hidden_totals.prep,
          custom: substrate.hidden_totals.prep
        }
      : substrate?.grouped_totals?.prep && groupKey
        ? {
            useCustom: false,
            default: substrate.grouped_totals.prep,
            custom: substrate.grouped_totals.prep
          }
        : {
            useCustom: false,
            default: substrate.totals?.prep ?? 0,
            custom: substrate.totals?.prep ?? 0
          }

  const hours =
    showHiddenTotals && substrate.hidden_totals?.hours
      ? {
          useCustom: false,
          default: substrate.hidden_totals.hours,
          custom: substrate.hidden_totals.hours
        }
      : substrate.grouped_totals.hours && groupKey
        ? {
            useCustom: false,
            default: substrate.grouped_totals.hours,
            custom: substrate.grouped_totals.hours
          }
        : {
            useCustom: false,
            default: substrate.totals.hours,
            custom: substrate.totals.hours
          }

  const price =
    showHiddenTotals && substrate.hidden_totals?.price
      ? {
          useCustom: false,
          default: substrate.hidden_totals.price,
          custom: substrate.hidden_totals.price
        }
      : substrate.grouped_totals.price && groupKey
        ? {
            useCustom: false,
            default: substrate.grouped_totals.price,
            custom: substrate.grouped_totals.price
          }
        : {
            useCustom: false,
            default: substrate.totals.price,
            custom: substrate.totals.price
          }

  const totals = {
    prep,
    hours,
    price
  }

  const files = [...(substrate.images ? substrate.images : []), ...(substrate.files ? substrate.files : [])]
  return {
    key,
    type,
    section,
    label,
    clientLabel,
    substrateDescription,
    files,
    totals
  }
}

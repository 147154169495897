import type { QuoteDocument, AreaSubstrate } from 'paintscout'
import type { QuoteItemSection } from '../..'
import { getAreaSubstrateKey } from '../../area-substrates/get-area-substrate-key'
import { isInSubstrateGroup } from '../../area-substrates/is-in-substrate-group'

/**
 * Check if substrate is grouped and we want to filter it out of substrate description
 */
export function filterGroupedSubstrate({
  quote,
  areaSubstrate,
  section,
  showGroup,
  groupKey
}: {
  quote: QuoteDocument
  areaSubstrate: AreaSubstrate
  section: QuoteItemSection
  showGroup?: boolean
  groupKey?: string
}): boolean {
  if (quote.version < 5) return false
  // Check if in a general or specific substrate group.
  const areaSubstrateKey = getAreaSubstrateKey(quote, areaSubstrate)
  const inSubstrateGroup = !!isInSubstrateGroup({
    quote,
    areaSubstrateKey,
    rateKey: areaSubstrate.key,
    section,
    groupKey
  })

  // Filter out group if we are not showing group
  if (inSubstrateGroup && !showGroup && !groupKey) {
    return true
  }
  // Filter out non-gorup if we are showing group
  if (!inSubstrateGroup && showGroup) {
    return true
  }
  // If looking for group items, and not in a group, skip
  // unless first check and group isnt existant yet, then continue as substrate
  // is about to be grouped
  if (!inSubstrateGroup && showGroup && groupKey && quote.groups[groupKey]) {
    return true
  }

  return false
}

import type { QuoteFile } from 'paintscout'
export function isMediaEmpty({ file, allowVideo = true }: { file: QuoteFile; allowVideo?: boolean }): boolean {
  if (!file) {
    return true
  }

  const { format, youtubeId, src } = file
  const imagesFormats = ['png', 'jpg', 'jpeg', 'gif']

  if (allowVideo) {
    if (!youtubeId && !src) {
      return true
    }
  } else if (youtubeId || !imagesFormats.includes(format)) {
    return true
  }

  return false
}

import { getObjectLabels } from '@paintscout/util/builder'
import React from 'react'
import ReactNumberFormat from 'react-number-format'
import { useClientOptions } from '../ClientOptionsProvider'

export default function NumberFormat(props: {
  children?: any
  format?: string
  showUnits?: boolean
  showZeros?: boolean
  value?: number
}) {
  const { children, format, showUnits, showZeros, value = 0 } = props
  const { options } = useClientOptions()
  const { currency } = getObjectLabels({ options })

  if (value || showZeros) {
    const numberFormatProps: any = {}
    numberFormatProps.decimalScale = 2
    numberFormatProps.thousandSeparator = true
    if (format === 'price') {
      if (showUnits) {
        numberFormatProps.prefix = currency.symbol
      }
      numberFormatProps.fixedDecimalScale = true
    }
    if (format === 'hours') {
      if (showUnits) {
        numberFormatProps.suffix = ' hr'
      }
    }
    return <ReactNumberFormat {...numberFormatProps} value={value} displayType="text" />
  } else if (children) {
    return children
  } else {
    return ''
  }
}

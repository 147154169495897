import React from 'react'
import type { FieldProps } from 'formik'
import type { LayoutSelectProps } from '../LayoutSelect'
import LayoutSelect from '../LayoutSelect'

export interface FormikLayoutSelectProps extends FieldProps<any>, Omit<LayoutSelectProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikLayoutSelect = ({ field, form, ...props }: FormikLayoutSelectProps) => {
  const onChange = (value: string) => {
    form.handleChange({ target: { name: field.name, value } })
  }

  return <LayoutSelect {...field} {...props} onChange={onChange} />
}

export default FormikLayoutSelect

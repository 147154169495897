import React from 'react'
import type { StyleClasses } from '../theme'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { default as MuiSwitch } from '@material-ui/core/Switch'
import type { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch'

const useStyles = makeStyles<Theme, SwitchProps>((_) => ({
  root: {
    pointerEvents: ({ readOnly }) => (readOnly ? 'none' : 'auto')
  },
  switchBase: {},
  thumb: {},
  track: {},
  sizeSmall: {},
  checked: {},
  disabled: {}
}))

export interface SwitchProps extends MuiSwitchProps {
  classes?: MuiSwitchProps['classes'] & StyleClasses<typeof useStyles>
}

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(function Switch(props: SwitchProps, ref) {
  const classes = useStyles(props)

  return <MuiSwitch {...props} classes={classes} ref={ref} />
})

export default Switch

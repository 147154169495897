/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { calculateQuote } from '../../util'
import type { UpdateableArea } from '../../index'
import { updateSubstrateDescriptions } from '../../index'
import { uuid } from '../../../util'
import findIndex from 'lodash/findIndex'
import { createQuoteArea } from './create-quote-area'
import { convertArea } from './convert-area'
import { removeFalseValues } from '../../../../util'
// Update or create a new area from given area
export function updateArea(args: {
  quote: QuoteDocument
  options: OptionsDocument
  area: UpdateableArea
  calculate?: boolean
  insertAfterKey?: string
  sanitize?: boolean
}): QuoteDocument {
  const { quote, area, options, calculate = true, insertAfterKey, sanitize = true } = args
  const key = area.key ? area.key : uuid()
  const isNewItem = quote.areas[key] ? false : true

  const convertedArea = isNewItem ? createQuoteArea({ ...area, key }) : convertArea(quote, area)

  const areaOrder = [...quote.order.area]

  // Add to order if new item
  if (isNewItem) {
    const orderItem = {
      key,
      type: 'area'
    }

    if (insertAfterKey) {
      const index = findIndex(areaOrder, { key: insertAfterKey })
      areaOrder.splice(index, 0, orderItem)
    } else {
      areaOrder.push(orderItem)
    }
  }

  const sanitizedArea = sanitize ? removeFalseValues(convertedArea, ['substrates']) : convertedArea

  // Update Quote with updated area and order
  const updatedQuote: QuoteDocument = {
    ...quote,
    areas: {
      ...quote.areas,
      [key as any]: sanitizedArea
    },
    order: {
      ...quote.order,
      area: areaOrder
    }
  }

  // Update descriptions of all quote.substrates
  updatedQuote.substrates = updateSubstrateDescriptions({
    quote: updatedQuote,
    options
  })

  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}

/*
export interface UpdateableArea {
  key: string
  areaType?: 'area' | 'surface'
  label?: string
  dimensions?: {
    width?: number
    length?: number
    height?: number
    lnft?: number
    sqftWalls?: number
    sqftCeiling?: number
    sqftFloor?: number
    override?: boolean
  }
  totals?: {
    hours?: number
    prep?: number
    price?: number
    overrideHours?: boolean
    overridePrice?: boolean
  }
  notes?: {
    value: string
    internal: boolean
  }[]
  substrateString?: {
    useCustom?: boolean
    value?: string
  }
}
*/

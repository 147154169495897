import { getUploadData } from './util'
import axios from 'axios'
import * as Sentry from '@sentry/core'
import type { CsvOwner } from './uploadFile'

export interface UploadOptions {
  /**
   * If provided(better be), folder will be setup accordingly /companyId/x
   */
  companyId: string

  /**
   * Temporary feature flag on s3 uploads
   */
  s3UploadFeatureEnabled: boolean

  /**
   * If provided, a tag of `doc-<id>` will be categorized under /companyId/doc-id/'x'
   * x = /{id} | /images/{id}
   */
  docId?: string

  /**
   * Type of csv upload we are doing
   */
  type?: string

  /**
   * Owner of newly created quote/contacts on csv uplaod
   */
  owner?: CsvOwner

  /**
   * Template used for upload
   */
  provider?: string

  tags?: string[]
}

export interface S3FileUploadData {
  size: number
  src: string
  s3PublicKey: string
  bucket: string
  format: string
  type: string
  pages?: number
  width?: number
  height?: number
}

export interface S3UrlComponents {
  key: string
  rawUrl: string
  bucket: string
}

/**
 * Upload file to correct key in images bucket
 */
export default async function s3UploadFile(file: File, url: string): Promise<S3FileUploadData> {
  const config = {
    method: 'PUT',
    url,
    headers: {
      'Content-Type': file.type,
      ACL: 'public-read'
    },
    data: file
  }

  const res = await axios(config).catch((error) => {
    console.log('error uploading file to s3', { url, file, error })
    Sentry.captureException(error)
  })
  console.log('s3 uploadRes', res)

  const uploadData = await getUploadData(file, url)

  return uploadData
}

import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import type { DialogContentProps as MuiDialogContentProps } from '@material-ui/core/DialogContent'
import MuiDialogContent from '@material-ui/core/DialogContent'

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography

  return createStyles({
    root: {
      padding: pxToRem(30),
      ...theme.typography.body2,

      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light
    },
    grey: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.primary
    },
    error: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main
    }
  })
}

export interface AfterDialogContentProps extends WithStyles<typeof styles>, Omit<MuiDialogContentProps, 'classes'> {
  className?: string
  type?: string
}

class DialogAfterContent extends React.PureComponent<AfterDialogContentProps, any> {
  public static defaultProps: Partial<AfterDialogContentProps> = {
    type: 'root'
  }

  public render() {
    const { classes, children, type } = this.props
    const typeClass = type !== 'root' && classes[type] ? classes[type] : ''

    return <MuiDialogContent classes={{ root: [classes.root, typeClass].join(' ') }}>{children}</MuiDialogContent>
  }
}

export default withStyles(styles)(DialogAfterContent)

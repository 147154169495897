import React from 'react'
import IconButton from '../IconButton'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import type { PopupMenuProps } from '../PopupMenu'
import PopupMenu from '../PopupMenu'
import MenuItem from '../MenuItem'
import { makeStyles } from '@material-ui/core'

export interface ActivityActionsProps extends Omit<PopupMenuProps, 'component' | 'classes'> {}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: -theme.spacing(0.5)
  }
}))
export default function ActivityActions(props: ActivityActionsProps) {
  const classes = useStyles(props)

  return (
    <PopupMenu
      component={
        <IconButton size="small" className={classes.button} data-testid="activity-actions">
          <MoreIcon />
        </IconButton>
      }
      {...props}
    />
  )
}

export const ActivityAction = MenuItem

/**
 * Remove trailing whitespace from html string, both <p></p> and <br> tags
 * @param html html string to clean
 * @returns cleaned html string
 */
export const trimHtmlTrailingWhitespace = (html: string): string => {
  let pTagsTrimmed = html
  // Remove all trailing empty lines <p></p> tags, or <p><br></p> tags with any number of <br> tags inside
  while (/<p>(?:<br>)*<\/p>$/.test(pTagsTrimmed)) {
    pTagsTrimmed = pTagsTrimmed.replace(/<p>(?:<br>)*<\/p>$/, '')
  }
  // Remove all trailing <br> tags making lines if just inside our closing tag at end of string
  const brTagsTrimmed = pTagsTrimmed.replace(/(<br>)+(?=<\/\w+>$)/g, '')

  return brTagsTrimmed
}

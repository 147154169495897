import type { Auth0User, QuoteDocument } from 'paintscout'
import { setOwner } from '../../documents'

export function setEstimator({
  quote,
  user,
  signature
}: {
  quote: QuoteDocument
  user: Auth0User | null
  signature?: string
}): QuoteDocument {
  const withOwner = setOwner({ doc: quote, user })

  return {
    ...withOwner,
    signatures: {
      ...(withOwner?.signatures ?? {}),
      estimator: signature
    }
  }
}

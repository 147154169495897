import { getObjectLabels } from '@paintscout/util/builder'
import React from 'react'
import { useClientOptions } from '../ClientOptionsProvider'
import Highlight from '../Highlight'
import type { Activity } from '@paintscout/api'

export default function QuoteLabel(props: { activity: Activity }) {
  const { activity } = props
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const isInvoice = !!activity.details.invoice

  let label
  if (isInvoice) {
    label = 'Invoice'
  } else {
    label = objectLabels.quote.value
  }

  return <Highlight>{label}</Highlight>
}

import type { QuoteDocument } from 'paintscout'
import type { RenderableTax } from '../../..'
import { parseNumber } from '../../../../../calculator'

export default function convertTaxes(quote: QuoteDocument): RenderableTax[] {
  if (!quote.tax_rate && !quote.tax_description) {
    return []
  }

  // If old quote, use existing tax_description and tax_rate
  if (quote.tax_details === undefined || !quote.tax_details?.[0]) {
    const amount = quote.totals.total_tax
    const taxLabel = quote.tax_description ? quote.tax_description : 'Tax'
    const rateDescription = ` (${quote.tax_rate ? quote.tax_rate : 0}%)`

    return [{ description: `${taxLabel}${rateDescription}`, amount }] as RenderableTax[]
  } else {
    return quote.tax_details
      .map((tax, i) => {
        if (tax.key === 'Notax') {
          return null
        }

        const laborRate = parseNumber(tax.laborRate)
        const materialRate = parseNumber(tax.materialRate)
        if (laborRate || materialRate) {
          const price = parseNumber(quote.totals.after_discount)
          const materials = parseNumber(quote.totals.materials)
          const labor = price - materials

          const materialsTax = materials * materialRate * 0.01
          const laborTax = labor * laborRate * 0.01

          const amount = materialsTax + laborTax

          const taxLabel = tax.description ? tax.description : 'Tax'
          const rateDescription = ''

          return { key: `${i}`, description: `${taxLabel}${rateDescription}`, amount }
        } else if (tax.rate) {
          const amount = parseFloat((tax.rate * 0.01 * quote.totals.after_discount).toFixed(2))
          const taxLabel = tax.description ? tax.description : 'Tax'
          const rateDescription = ` (${tax.rate}%)`
          return { key: `${i}`, description: `${taxLabel}${rateDescription}`, amount }
        }
      })
      .filter((item) => item)
  }
}

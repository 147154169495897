import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { HtmlContent } from '@ui/paintscout'
import { Grid, Typography } from '@ui/paintscout'

const useStyles = makeStyles((_theme: Theme) => {
  return {
    root: {},
    content: {}
  }
})

export default function Custom(props: PresentationSectionProps) {
  const { section, WrapperComponent } = props

  const classes = useStyles(props)
  const { title, fields } = section

  const additionalTags = ['pre', 'blockquote', 'code', 'input', 'button', 'form', 'iframe']

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3}>
        {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <HtmlContent includeTags={additionalTags} content={fields?.html ?? ''} classes={{ root: classes.content }} />
          <HtmlContent includeTags={['style']} content={`<style>${fields?.css ?? ''}</style>`} />
        </Grid>
      </Grid>
    </WrapperComponent>
  )
}

import React from 'react'
import type { QuoteContextValue } from './QuoteContext'
import { QuoteContext } from './QuoteContext'

export interface WithQuoteContext {
  quoteContext: QuoteContextValue
}

/**
 * Provides the component with a quoteContext prop as the value from QuoteContextProvider
 */
export const withQuoteContext = <T extends WithQuoteContext>(Component: React.ComponentType<T>) =>
  function WithQuoteContext(props: Omit<T, keyof WithQuoteContext>) {
    return (
      <QuoteContext.Consumer>
        {(context: QuoteContextValue) => <Component {...(props as any)} quoteContext={context} />}
      </QuoteContext.Consumer>
    )
  }

import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { Grid, Typography } from '@ui/paintscout'
import type { ColorTileProps } from '../ColorTile'
import ColorTile from '../ColorTile'
import type { SWLinkProps } from '../SWLink'
import SWLink from '../SWLink'

export interface ColorTileOption {
  colorTile: ColorTileProps
  label?: string
}

export interface ColorRowPreviewProps {
  title: React.ReactNode
  description?: string
  colorTileOptions: ColorTileOption[]
  links?: SWLinkProps[]
  style?: React.CSSProperties
}

const useStyles = makeStyles<Theme, ColorRowPreviewProps>(
  () => ({
    title: {
      textAlign: 'center',
      fontFamily: 'CustomFont-SW',
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '-1.5px'
    },
    description: {
      textAlign: 'center',
      fontFamily: 'CustomFont-SW',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.5px'
    }
  }),
  { name: 'PopularColors' }
)

function ColorRowPreview(props: ColorRowPreviewProps) {
  const classes = useStyles(props)
  const { title, description, colorTileOptions, links, style } = props

  return (
    <Grid container lg={12} md={12} justifyContent="center" alignItems="center" style={style}>
      <Grid item container style={{ paddingBottom: '20px' }}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container sm={12} spacing={2} justifyContent="center" alignItems="center">
        {colorTileOptions.map((colorTileOption, index) => {
          const { colorTile, label } = colorTileOption
          return (
            <Grid item lg={2} key={index}>
              <ColorTile {...colorTile} />
              {label && <Typography style={{ textAlign: 'center', marginTop: '5px' }}>{label}</Typography>}
            </Grid>
          )
        })}
      </Grid>
      <Grid item container style={{ marginTop: '20px' }} lg={12} justifyContent="center" spacing={2}>
        {links &&
          links.map((link, index) => {
            return (
              <Grid item key={link.label + index}>
                <SWLink {...link} />
              </Grid>
            )
          })}
      </Grid>
    </Grid>
  )
}

export default ColorRowPreview

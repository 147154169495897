/**
 * @module builder
 */

import type { QuoteDocument, AreaSubstrate, OptionsDocument, QuoteOptions } from 'paintscout'
import { getAreaSubstrateKey } from '../index'
import type { QuoteItemSection } from '../index'
import { moveTo, generateAreaDescription, calculateQuote, moveToBid, getQuoteOptions, getItemSection } from '../util'
import { generateSubstrateDescription } from '../util/generate-substrate-description/generate-substrate-description'

import findIndex from 'lodash/findIndex'

import { areaTotals } from '../../../calculator'
import allSame from '../../../calculator/util/all-same'
import { getAreaSubstrateSections } from './get-area-substrate-sections'
import getSubstrateAreas from '../items/get-items/util/get-substrate-areas'

export function moveAreaSubstrate(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  key: string
  section: QuoteItemSection
  calculate?: boolean
}): QuoteDocument {
  const { quote, areaKey, key, section, calculate = true, options } = args

  // If area doesn't exist, return
  if (!quote.areas[areaKey]) {
    return { ...quote }
  }

  let foundRateSection: string
  let sectionSubstrates: AreaSubstrate[]
  let updatedAreaSubstrate: AreaSubstrate
  let previousSection: QuoteItemSection
  Object.keys(quote.areas[areaKey].substrates).forEach((rateSection: string) => {
    const index = findIndex(quote.areas[areaKey].substrates[rateSection], { key })
    if (index !== -1) {
      foundRateSection = rateSection
      previousSection = getItemSection(quote.areas[areaKey].substrates[rateSection][index])
      sectionSubstrates = [...quote.areas[areaKey].substrates[rateSection]]
      updatedAreaSubstrate = moveTo(sectionSubstrates[index], section)
      sectionSubstrates[index] = updatedAreaSubstrate
    }
  })

  if (!foundRateSection) {
    return {
      ...quote
    }
  }

  const newArea = {
    ...quote.areas[areaKey],
    substrates: {
      ...quote.areas[areaKey].substrates,
      [foundRateSection]: sectionSubstrates
    },
    substrate_string: {
      ...quote.areas[areaKey].substrate_string
    }
  }

  // Check area substrate sections and if all the same move to that section,
  // else we default to bid section
  const areaSubstrateSections = getAreaSubstrateSections({
    quote: {
      ...quote,
      areas: {
        ...quote.areas,
        [areaKey]: newArea
      }
    },
    options,
    areaKey
  })
  const movedArea = allSame(areaSubstrateSections) ? moveTo(newArea, areaSubstrateSections[0]) : moveToBid(newArea)

  const updatedArea = areaTotals(movedArea, quote)
  const updatedQuote = {
    ...quote,
    areas: {
      ...quote.areas,
      [areaKey]: updatedArea
    }
  }

  updatedQuote.areas[areaKey].substrate_string.default = generateAreaDescription({
    options,
    quote: updatedQuote,
    areaKey
  })

  /*
    Need to update the substrateDescription of updatedQuote.substrates[areaSubstrate.name]
  */
  if (updatedAreaSubstrate) {
    const substrateKey = getAreaSubstrateKey(updatedQuote, updatedAreaSubstrate)
    if (updatedQuote.substrates[substrateKey]) {
      const defaultValue = generateSubstrateDescription({
        quote: updatedQuote,
        clientOptions: options,
        substrateKey: substrateKey.toString()
      })
      const areaString = updatedQuote.substrates[substrateKey].area_string
        ? { ...updatedQuote.substrates[substrateKey].area_string, default: defaultValue }
        : { use_custom: false, default: defaultValue }

      const quoteOptions: QuoteOptions = getQuoteOptions({
        options,
        quote
      })
      const substrateAreas = getSubstrateAreas({
        quote: updatedQuote,
        options: quoteOptions,
        clientOptions: options,
        substrateKey,
        consumer: 'customer',
        section: previousSection,
        showValues: true,
        showGroup: false
      })

      updatedQuote.substrates[substrateKey] = {
        ...updatedQuote.substrates[substrateKey],
        ...(substrateAreas.length === 0 ? moveTo(updatedQuote.substrates[substrateKey], section) : {}),
        area_string: areaString
      }
    }
  }

  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}

import type { OptionsDocument } from 'paintscout'
import { sanitizePhoneNumber } from '../../../util/sanitizePhoneNumber/sanitizePhoneNumber'

export function formatPhoneNumber({
  options,
  phoneNumber,
  extension
}: {
  options?: OptionsDocument
  phoneNumber?: any
  extension?: string
}): string {
  if (!phoneNumber) {
    return ''
  }

  let formatted: string

  if (options?.options?.internationalMode) {
    formatted = sanitizePhoneNumber(phoneNumber as string, options)
  } else {
    const separator = options?.options?.phoneNumbers?.separator ?? '.'
    const stripped: string = sanitizePhoneNumber(phoneNumber as string)
    formatted = stripped.replace(/([1-9]\d\d)([1-9]\d\d)(\d\d\d\d)/gi, `$1${separator}$2${separator}$3`)
  }

  if (extension) {
    formatted = `${formatted} ext. ${extension.trimEnd()}`
  }

  return `${formatted}`
}

import type { FontOption } from './FontSelect'
export function getFontOptions(): Record<string, FontOption> {
  return {
    'Archivo+Black:400': {
      label: 'Archivo Black',
      value: 'Archivo+Black:400',
      style: { fontFamily: 'Archivo Black, sans-serif', fontWeight: 400 }
    },
    'Hind:700': {
      label: 'Hind',
      value: 'Hind:700',
      style: { fontFamily: 'Hind, sans-serif', fontWeight: 700 }
    },
    'Oswald:700': {
      label: 'Oswald',
      value: 'Oswald:700',
      style: { fontFamily: 'Oswald, sans-serif', fontWeight: 700 }
    },
    'Raleway:900': {
      label: 'Raleway',
      value: 'Raleway:900',
      style: { fontFamily: 'Raleway, sans-serif', fontWeight: 900 }
    },
    'Roboto:500': {
      label: 'Roboto (Default)',
      value: 'Roboto:500',
      style: { fontFamily: 'Roboto, sans-serif', fontWeight: 500 }
    },
    'Roboto:700': {
      label: 'Roboto (Bold)',
      value: 'Roboto:700',
      style: { fontFamily: 'Roboto, sans-serif', fontWeight: 700 }
    },
    'Rubik+Mono+One:400': {
      label: 'Rubik Mono One',
      value: 'Rubik+Mono+One:400',
      style: { fontFamily: 'Rubik Mono One, sans-serif', fontWeight: 400 }
    },
    'Lato:wght@900': {
      label: 'Lato',
      value: 'Lato:wght@900',
      style: { fontFamily: 'Lato, sans-serif', fontWeight: 900 }
    }
  }
}

import Button from '@material-ui/core/Button'
import MobileStepper from '@material-ui/core/MobileStepper'
import type { Theme, WithStyles, WithTheme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import type { DialogProps } from '@ui/paintscout'
import { Dialog, FilePreview, IconButton } from '@ui/paintscout'
import type { QuoteFile } from 'paintscout'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: 'auto'
    },
    scrollPaper: {
      overflow: 'hidden',
      maxHeight: 'none',
      height: '95vh',
      '&$paperFullScreen': {
        height: '100vh'
      }
    },
    paperFullScreen: {},
    content: {
      position: 'relative',
      // background: 'black',
      height: '100%',
      display: 'flex',
      maxWidth: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    swipeContainer: {
      flexGrow: 1,
      display: 'flex',
      '& .react-swipeable-view-container': {
        flexGrow: 1
      }
    },
    hiddenFilePreview: {
      display: 'none'
    },
    currentFilePreview: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '80px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px'
      }
    },
    image: {
      objectFit: 'contain'
    },

    closeDialogButton: {
      position: 'absolute',
      top: 10,
      left: 10,
      zIndex: 1400
    }
  })

export interface FilesLightboxDialogProps extends DialogProps {
  files: QuoteFile[]
  index?: number
}

export type FilesLightboxDialogPropsWithInjections = FilesLightboxDialogProps & WithStyles<typeof styles> & WithTheme

export interface FilesLightboxDialogState {
  index: number
}

function FilesLightboxDialog(props: FilesLightboxDialogPropsWithInjections) {
  const { classes, theme: _theme, files, onClose, ...dialogProps } = props
  const [index, setIndex] = React.useState(props.index || 0)

  const nextButton = (
    <Button
      onClick={() => {
        setIndex(index + 1)
      }}
      disabled={index === files.length - 1}
    >
      Next
      <KeyboardArrowRightIcon />
    </Button>
  )

  const backButton = (
    <Button
      onClick={() => {
        setIndex(index - 1)
      }}
      disabled={index === 0}
    >
      <KeyboardArrowLeftIcon />
      Back
    </Button>
  )

  return (
    <Dialog
      classes={{
        paper: classes.paper,
        paperScrollPaper: classes.scrollPaper,
        paperFullScreen: classes.paperFullScreen
      }}
      maxWidth="lg"
      fullWidth={true}
      {...dialogProps}
    >
      <div className={classes.content}>
        <SwipeableViews
          className={classes.swipeContainer}
          axis={'x'}
          index={index}
          onChangeIndex={(newIndex) => setIndex(newIndex)}
          enableMouseEvents={true}
        >
          {files.map((file, fileIndex) => (
            <FilePreview
              key={file.key}
              file={file}
              showOverlay={true}
              classes={{
                root: index === fileIndex ? classes.currentFilePreview : classes.hiddenFilePreview,
                image: classes.image
              }}
            />
          ))}
        </SwipeableViews>
        <MobileStepper
          steps={files.length}
          activeStep={index}
          position="static"
          nextButton={nextButton}
          backButton={backButton}
        />
        <IconButton className={classes.closeDialogButton} color="inherit" onClick={() => onClose({}, null)}>
          <CloseIcon />
        </IconButton>
      </div>
    </Dialog>
  )
}

export default withStyles(styles, { withTheme: true })(FilesLightboxDialog)

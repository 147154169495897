import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { useQuote } from '../../../context'
const addressFormat = require('address-format')

import classnames from 'classnames'

import { Typography, ImagePlaceholder, FontLoad, getFontOptions, scaleVariant, useClientOptions } from '@ui/paintscout'
import { getFeatures } from '@paintscout/util/builder'

const useStyles = makeStyles((theme: Theme) => {
  const minHeight = 100

  return {
    root: {},
    content: {},
    section: {
      // padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,

      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      boxSizing: 'border-box',

      '& > div': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        minHeight
      }
    },

    hasBackground: {
      '& $alignjustify': {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }
    },
    overlay: {
      display: 'flex',
      flexDirection: 'column'
    },
    overlayText: {
      lineHeight: 1,
      whiteSpace: 'pre-wrap'
    },
    title: {
      margin: `${theme.spacing(1)}px 0`
    },
    subtitle: {
      margin: `${theme.spacing(1)}px 0`,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    imageWrapper: (props: PresentationSectionProps) => {
      const overlayImageSize = props?.section?.fields?.overlayImageSize ?? 'small'
      let width = 125
      if (overlayImageSize === 'large') {
        width = 375
      } else if (overlayImageSize === 'medium') {
        width = 250
      }
      return {
        margin: `${theme.spacing(1)}px 0`,
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: width,
        maxHeight: width,
        [theme.breakpoints.down('md')]: {
          maxWidth: 0.8 * width,
          maxHeight: 0.8 * width
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: 0.5 * width,
          maxHeight: 0.5 * width
        },
        '& img': {
          objectFit: 'contain',
          objectPosition: 'right'
        }
      }
    },
    image: {
      maxWidth: '100%',
      height: 'auto'
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },

    [`vertical-align-top`]: {
      justifyContent: 'flex-start !important' as any
    },
    [`vertical-align-middle`]: {
      justifyContent: 'center !important' as any
    },
    [`vertical-align-bottom`]: {
      justifyContent: 'flex-end !important' as any
    },
    [`align-left`]: {
      alignItems: 'flex-start !important' as any,
      '& $overlay': {
        alignItems: 'flex-start',
        textAlign: 'left'
      },
      '& $imageWrapper': {
        justifyContent: 'flex-start' as any,
        '& img': {
          objectPosition: 'left'
        }
      }
    },
    [`align-center`]: {
      alignItems: 'center !important' as any,
      '& $overlay': {
        alignItems: 'center',
        textAlign: 'center'
      },
      '& $imageWrapper': {
        justifyContent: 'center' as any,
        '& img': {
          objectPosition: 'center'
        }
      }
    },
    [`align-right`]: {
      alignItems: 'flex-end !important' as any,
      '& $overlay': {
        alignItems: 'flex-end',
        textAlign: 'right'
      },
      '& $imageWrapper': {
        justifyContent: 'flex-end' as any,
        '& img': {
          objectPosition: 'right'
        }
      }
    },
    [`align-justify`]: {
      alignItems: 'flex-end !important' as any,
      '& $overlay': {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'left',
        width: '100%'
      },
      '& $imageWrapper': {
        justifyContent: 'flex-end' as any,
        '& img': {
          objectPosition: 'right'
        }
      }
    },

    noSubtitle: {
      '& $overlay': {
        alignItems: 'center'
      },
      '& $imageWrapper': {
        '& img': {
          objectPosition: 'center'
        }
      }
    },

    [`fontSize-small`]: {
      ...scaleVariant(theme.typography.h1, 0.5)
    },
    [`fontSize-medium`]: {},
    [`fontSize-large`]: {
      ...scaleVariant(theme.typography.h1, 1.5)
    }
  }
})

const fontOptions = getFontOptions()

export default function Cover(props: PresentationSectionProps) {
  const { section, WrapperComponent, isPreview, showHelp } = props
  const { quote } = useQuote()
  const { options } = useClientOptions()
  const jobIDInCoverSection = getFeatures({ options })?.presentation?.jobIDInCoverSection

  const classes = useStyles(props)
  const { fields, title } = section

  // Handle subtitle and background image options
  const quoteImageFiles = quote?.files ?? null
  const firstQuoteImageFile = quoteImageFiles ? quoteImageFiles[Object.keys(quoteImageFiles)[0]] : null
  const { subtitle, useQuoteContactAddress, useFirstQuoteImage } = fields
  let quoteSubtitle = ''

  if (quote && quote?._id !== 'preview') {
    if (
      (!quote?.jobAddress ||
        (!quote?.jobAddress.address &&
          !quote?.jobAddress.city &&
          !quote?.jobAddress.postal &&
          !quote?.jobAddress.province)) &&
      quote?.contact
    ) {
      quoteSubtitle = addressFormat({
        address: quote.contact?.address ?? '',
        city: quote.contact?.city ?? '',
        postalCode: quote.contact?.postal ?? '',
        subdivision: quote.contact?.province ?? '',
        countryCode: 'US'
      }).join('\n')
    } else if (Object.keys(quote?.jobAddress ?? {})?.length > 0) {
      quoteSubtitle = addressFormat({
        address: quote.jobAddress?.address ?? '',
        city: quote.jobAddress?.city ?? '',
        postalCode: quote.jobAddress?.postal ?? '',
        subdivision: quote.jobAddress?.province ?? '',
        countryCode: 'US'
      }).join('\n')
    }
  }

  if (jobIDInCoverSection && quote?.job_identifier) {
    quoteSubtitle = `${quote?.job_identifier}\n${quoteSubtitle}`
  }

  const textShadows = {
    none: 'none',
    small: '1px 1px 2px rgba(0,0,0,0.25)',
    large: '4px 4px 8px rgba(0,0,0,0.5)'
  }

  const textTransforms = {
    none: 'none',
    lowercase: 'lowercase',
    uppercase: 'uppercase'
  }

  const fonts = [fields?.titleStyle?.font, fields?.subTitleStyle?.font]

  const titleStyle = fields?.titleStyle
    ? {
        ...(fontOptions?.[fields.titleStyle.font]?.style ?? {}),
        color: fields.titleStyle.color,
        textShadow: textShadows[fields.titleStyle.shadow],
        textTransform: textTransforms[fields.titleStyle.transform]
      }
    : {}

  const subTitleStyle = fields?.subTitleStyle
    ? {
        ...(fontOptions?.[fields.subTitleStyle.font]?.style ?? {}),
        color: fields.subTitleStyle.color,
        textShadow: textShadows[fields.subTitleStyle.shadow],
        textTransform: textTransforms[fields.subTitleStyle.transform]
      }
    : {}

  const color = fields?.background?.textColor ?? '#333'

  const overlayImage = fields?.overlayImage?.src

  const backgroundImage =
    useFirstQuoteImage && firstQuoteImageFile ? firstQuoteImageFile.src : fields?.backgroundImage?.src
  // const imageWidth = (fields?.overlayImage?.width ?? 0) < 125 ? 'auto' : 125

  const alignment = fields?.alignment ?? 'justify'
  const verticalAlignment = fields?.verticalAlign ?? 'middle'

  return (
    <WrapperComponent {...props}>
      <FontLoad fonts={fonts}>
        <div
          className={classnames({
            [classes.section]: true,
            [classes.hasBackground]: backgroundImage
          })}
        >
          <div
            className={classnames({
              [classes[`align-${alignment}`]]: true,
              [classes[`vertical-align-${verticalAlignment}`]]: true,
              [classes.noSubtitle]: !subtitle
            })}
          >
            <div className={classes.overlay}>
              {overlayImage && (
                <div className={classes.imageWrapper}>
                  <img className={classes.image} src={overlayImage} />
                </div>
              )}
              {!overlayImage && isPreview && showHelp && (
                <div className={classes.imageWrapper}>
                  <ImagePlaceholder title="Logo" width={150} height={150} />
                </div>
              )}
              {(title || subtitle) && (
                <div className={classes.textWrapper}>
                  {title && (
                    <Typography
                      variant={'h1'}
                      classes={{
                        root: classnames(classes.title, classes.overlayText)
                      }}
                    >
                      <span
                        className={classnames({
                          [classes[`fontSize-${fields?.titleStyle?.size ?? 'medium'}`]]: true
                        })}
                        style={{ color, ...titleStyle }}
                      >
                        {title}
                      </span>
                    </Typography>
                  )}

                  {(subtitle || useQuoteContactAddress) && (
                    <Typography
                      variant={'h2'}
                      classes={{
                        root: classnames(classes.subtitle, classes.overlayText)
                      }}
                    >
                      <span
                        className={classnames({
                          [classes[`fontSize-${fields?.subTitleStyle?.size ?? 'medium'}`]]: true
                        })}
                        style={{ color, ...subTitleStyle }}
                      >
                        {useQuoteContactAddress ? quoteSubtitle : subtitle}
                      </span>
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </FontLoad>
    </WrapperComponent>
  )
}

import React, { useMemo } from 'react'
import type { QuoteFile } from 'paintscout'
import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles, Tooltip, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'

import type { DropzoneProps } from '@ui/paintscout'
import { Button, FilePreview, Grid, Typography, useUser } from '@ui/paintscout'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import type { SortableContainerProps, SortableElementProps } from 'react-sortable-hoc'
import { Dropzone } from '@ui/paintscout'
import { useConnection } from 'react-detect-offline'
import { TRIAL_QUOTE_FILES_LIMIT } from '@paintscout/util'
import { useQuote } from '../context'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { getFiles } from '@paintscout/util/builder'
import classnames from 'classnames'
import { Spinner } from '@ui/core'

export interface FilesGridProps {
  files: QuoteFile[]
  editable?: boolean
  showUploadDropzone?: boolean
  responsiveTransformation?: boolean
  columns?: number
  showBorder?: boolean
  justify?: 'space-around' | 'space-between' | 'center' | 'flex-start' | 'flex-end'
  isLoading?: boolean

  onReorder?: (files: QuoteFile[]) => any
  onUpload?: (files: File[]) => any
  onFileClick?: (index: number) => any
  onLoadMore?: () => any
}

type FilesGridPropsWithInjections = FilesGridProps & WithStyles<typeof styles>

const styles = createStyles({
  dragging: {
    zIndex: 2147483002
  },
  grid3: {
    '@media print': {
      width: '25%',
      flexBasis: 'unset'
    }
  },
  grid6: {
    '@media print': {
      width: '50%',
      flexBasis: 'unset'
    }
  }
})

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  filePreview: {
    height: '100%',
    width: '100%',
    aspectRatio: '4/3'
  },
  image: {
    objectFit: 'cover'
  },
  uploadButton: {},
  grid3: {
    '@media print': {
      width: '25%',
      flexBasis: 'unset'
    }
  },
  grid4: {
    '@media print': {
      width: '33.333%',
      flexBasis: 'unset'
    }
  },
  grid6: {
    '@media print': {
      width: '50%',
      flexBasis: 'unset'
    }
  },
  grid12: {
    '@media print': {
      width: '100%',
      flexBasis: 'unset'
    }
  },
  itemInner: ({ showBorder }: { showBorder: boolean }) => {
    const baseStyle = {
      padding: 4
    }

    if (showBorder === false) {
      return {
        ...baseStyle
      }
    }

    return {
      // height: '100%',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      border: '1px solid #efefef',
      background: 'white',
      position: 'relative',
      ...baseStyle
    }
  },
  imageWrapper: {
    height: '100%',
    width: '100%'
  },
  selected: {
    background: '#333 !important'
  },
  check: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    zIndex: 10,
    color: '#fff'
  },
  delete: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
    color: '#fff',
    background: 'black'
  },
  imageWrapperItem: {
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    // maxHeight: '100%',
    overflow: 'wrap',
    overflowY: 'hidden'
  },
  item: {
    '& img': {
      aspectRatio: '4/3'
    },
    // aspectRatio: '4/3',
    '@media print': {
      pageBreakInside: 'avoid'
    }
  },
  fileCaption: {
    color: theme.palette.text.secondary
  }
}))

interface SortableFilePreviewProps extends SortableElementProps {
  file?: QuoteFile
  columns?: number
  showBorder: boolean
  classes?: any
  editable?: boolean
  onClick?: () => any
}

const SortableFilePreview: React.ComponentClass<SortableFilePreviewProps, any> = SortableElement(
  ({ file, columns, onClick, ...props }: SortableFilePreviewProps) => {
    const classes = useStyles(props)
    let transformationProps: any = {
      ...getImageWidth(columns),
      crop: 'pad',
      background: '#fff'
    }

    if ((file.type ?? 'image').startsWith('video')) {
      transformationProps = {
        ...transformationProps,
        controls: false
      }
    }

    const { columnWidth, columnClass } = getColumnWidth(columns, classes)

    return (
      <Grid
        item
        key={file.key}
        xs={12}
        sm={columnWidth}
        md={columnWidth}
        className={classnames(columnClass, classes.item)}
      >
        <Grid
          container
          alignItems="center"
          className={classnames({ [classes.itemInner]: true, [classes.selected]: file.selected })}
        >
          {file.selected && <CheckCircleIcon className={classes.check} />}
          {/* {editable && (
              <IconButton className={classes.delete}>
                <DeleteIcon />
              </IconButton>
            )} */}
          <Grid item xs={12} className={classes.imageWrapperItem}>
            <FilePreview
              classes={{
                root: classes.filePreview
              }}
              file={file}
              videoThumbnail={true}
              onClick={onClick}
              {...transformationProps}
            />
          </Grid>
          <Grid item xs={12} className={classes.imageWrapperItem}>
            {file?.title && (
              <Typography
                classes={{ root: classes.title }}
                variant="overline"
                style={{ marginTop: 8 }}
                gutterBottom={true}
              >
                {file.title}
              </Typography>
            )}
            {file?.caption && (
              <Typography
                variant="subtitle2"
                gutterBottom={true}
                className={classnames('file-caption', classes.fileCaption)}
              >
                {file.caption}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
)

interface SortableGridContainerProps extends SortableContainerProps {
  files: QuoteFile[]
  disabled: boolean
  showUploadDropzone?: boolean
  columns: number
  editable: boolean
  showBorder: boolean
  justify: 'space-around' | 'space-between' | 'center' | 'flex-start' | 'flex-end'
  classes?: any
  onUpload?: (files: File[]) => any
  onClick?: (index: number) => any
  onLoadMore?: () => Promise<boolean>
}

const SortableGridContainer: React.ComponentClass<SortableGridContainerProps, any> = SortableContainer(
  ({
    files,
    disabled,
    showUploadDropzone,
    columns,
    showBorder,
    editable,
    justify,
    onUpload,
    onClick,
    onLoadMore,
    classes
  }: SortableGridContainerProps) => {
    const { online } = useConnection()
    const { quote } = useQuote()
    const { isTrial } = useUser()
    const [loadingMore, setLoadingMore] = React.useState(false)
    // calculate initial number of quote files so we know how many files are left without needing
    // to update the quote first
    const numQuoteFiles = useMemo(() => getFiles({ quote, attached: true }).length - files.length, [quote])

    const canAddFiles = !isTrial || numQuoteFiles + files.length < TRIAL_QUOTE_FILES_LIMIT

    return (
      <Grid container spacing={1} alignItems={'stretch'} justifyContent={justify}>
        {files.map((file, index) => (
          <SortableFilePreview
            key={file.key}
            index={index}
            file={file}
            editable={editable}
            showBorder={showBorder}
            columns={columns}
            disabled={disabled}
            onClick={() => {
              if (onClick) {
                onClick(index)
              }
            }}
          />
        ))}
        {onLoadMore && (
          <Grid item key={'loadMore'} xs={12} sm={12} md={12} className={classes.grid6}>
            <Button
              onClick={async () => {
                setLoadingMore(true)
                await onLoadMore()
                setLoadingMore(false)
              }}
              variant="outlined"
              fullWidth
              loading={loadingMore}
              disabled={!online}
            >
              Load More
            </Button>
          </Grid>
        )}
        {showUploadDropzone && (
          <Grid item key={'upload'} xs={12} sm={12} md={12} className={classes.grid6}>
            <Tooltip title={!canAddFiles ? 'You must upgrade your plan to add more media' : ''}>
              <div>
                <UploadDropzone onUpload={onUpload} disabled={!canAddFiles || !online || !editable} />
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    )
  }
)

function UploadDropzone(props: { onUpload?: (files: File[]) => any } & Partial<DropzoneProps>) {
  return (
    <Dropzone
      text="Add Media"
      onDropAccepted={props.onUpload}
      style={{ height: 'initial', marginTop: 20 }}
      {...props}
    />
  )
}

function FilesGrid(props: FilesGridPropsWithInjections) {
  const {
    files,
    editable,
    showUploadDropzone,
    onUpload,
    columns = 4,
    showBorder = true,
    justify = 'flex-start',
    classes,
    onReorder,
    onFileClick,
    isLoading,
    onLoadMore
  } = props

  function handleSortEnd({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
    const reorderedFiles = arrayMove(files, oldIndex, newIndex)

    if (onReorder) {
      onReorder(reorderedFiles)
    }
  }

  if (isLoading) {
    return <Spinner padded />
  }

  return (
    <>
      <SortableGridContainer
        classes={classes}
        helperClass={classes.dragging}
        pressDelay={150}
        files={files}
        editable={editable}
        axis="xy"
        columns={columns}
        showBorder={showBorder}
        justify={justify}
        onSortEnd={handleSortEnd}
        disabled={!editable}
        onClick={onFileClick}
        showUploadDropzone={showUploadDropzone}
        onUpload={onUpload}
        onLoadMore={onLoadMore}
      />
    </>
  )
}

function getImageWidth(columns: number) {
  switch (columns) {
    case 1:
      return { width: 1200 }
    case 2:
      return { width: 1000 }
    case 3:
      return { width: 800 }
    default:
      return { width: 600 }
  }
}
function getColumnWidth(columns: number, classes: any) {
  switch (columns) {
    case 1:
      return { columnWidth: 12 as const, columnClass: classes.grid12 }
    case 2:
      return { columnWidth: 6 as const, columnClass: classes.grid6 }
    case 3:
      return { columnWidth: 4 as const, columnClass: classes.grid4 }
    default:
      return { columnWidth: 3 as const, columnClass: classes.grid3 }
  }
}

export default withStyles(styles)(FilesGrid)

import type { HTMLProps } from 'react'
import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import type { StyleClasses } from '../theme'
import type { Activity as ActivityInterface } from '@paintscout/api'
import { motion } from 'framer-motion'

export interface ActivityProps extends HTMLProps<HTMLDivElement> {
  activityComponent: React.ReactNode
  activityDotComponent: React.ReactNode
  classes?: StyleClasses<typeof useStyles>
  showContent?: boolean
  showDocTypeDetails?: boolean
  animate?: boolean
  actions?: boolean
  onAction?: (action: string, activity: ActivityInterface) => any
  layout?: boolean | 'position'

  /** Ref for the activity dot */
  dotRef?: React.Ref<SVGElement>
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      background: 'none',
      border: 'none',
      width: '100%',
      padding: theme.spacing()
    },
    dot: {},
    content: {
      textAlign: 'left',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    }
  }),
  {
    name: 'Activity'
  }
)

export default function Activity(props: ActivityProps) {
  const { className, animate, layout, activityComponent, activityDotComponent, ...divProps } = props
  const classes = useStyles(props)

  return (
    <motion.div layout={layout} {...(divProps as any)} className={classnames(classes.root, className)}>
      {activityDotComponent}
      <motion.div className={classes.content} initial={animate && { opacity: 0 }} animate={animate && { opacity: 1 }}>
        {activityComponent}
      </motion.div>
    </motion.div>
  )
}

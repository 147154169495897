/**
 * @module builder
 */
import type { AreaDimensions } from 'paintscout'
import type { UpdateableAreaDimensions } from '../../index'

// Convert UpdateableAreaDimensions to AreaDimensions
export function convertDimensions(dimensions: UpdateableAreaDimensions): AreaDimensions {
  return {
    width: dimensions.width,
    length: dimensions.length,
    height: dimensions.height,

    lnft: dimensions.lnft.useCustom ? dimensions.lnft.custom : dimensions.lnft.default,
    override_lnft: dimensions.lnft.useCustom,
    calculated_lnft: dimensions.lnft.default,

    sqft: dimensions.sqft.useCustom ? dimensions.sqft.custom : dimensions.sqft.default,
    override_sqft: dimensions.sqft.useCustom,
    calculated_sqft: dimensions.sqft.default,

    cuft: dimensions.cuft.useCustom ? dimensions.cuft.custom : dimensions.cuft.default,
    override_cuft: dimensions.cuft.useCustom,
    calculated_cuft: dimensions.cuft.default,

    sqft_walls: dimensions.sqftWalls.useCustom ? dimensions.sqftWalls.custom : dimensions.sqftWalls.default,
    override_sqft_walls: dimensions.sqftWalls.useCustom,
    calculated_sqft_walls: dimensions.sqftWalls.default,

    sqft_ceiling: dimensions.sqftCeiling.useCustom ? dimensions.sqftCeiling.custom : dimensions.sqftCeiling.default,
    override_sqft_ceiling: dimensions.sqftCeiling.useCustom,
    calculated_sqft_ceiling: dimensions.sqftCeiling.default,

    sqft_floor: dimensions.sqftFloor.useCustom ? dimensions.sqftFloor.custom : dimensions.sqftFloor.default,
    override_sqft_floor: dimensions.sqftFloor.useCustom,
    calculated_sqft_floor: dimensions.sqftFloor.default
  }
}

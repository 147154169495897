import React from 'react'
import type { DialogProps } from '../Dialog'
import Dialog from '../Dialog'
import { DialogTitle, DialogActions, DialogContent } from '../Dialog'
import type { ButtonProps } from '../Button'
import Button from '../Button'
import { Grid, makeStyles } from '@material-ui/core'
import Typography from '../Typography'

export type AlertDialogActions = Array<
  {
    label: string
    left?: boolean
  } & Partial<ButtonProps>
>

export interface AlertDialogProps extends DialogProps {
  title?: string
  message?: React.ReactNode
  messages?: React.ReactNode[]
  icon?: React.ReactNode
  color?: 'default' | 'warning' | 'success' | 'error'
  contentAlign?: 'left' | 'center' | 'right'
  actions?: AlertDialogActions
  onConfirm?: (event: React.MouseEvent<HTMLElement>) => void
}

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  }
}))

function AlertDialog(props: AlertDialogProps) {
  const {
    loading,
    onConfirm,
    title = 'Alert',
    contentAlign = 'center',
    actions,
    message,
    messages,
    icon,
    color,
    ...otherProps
  } = props

  const classes = useStyles(props)

  const leftActions = actions?.filter((action) => action.left)
  return (
    <Dialog {...otherProps}>
      <DialogTitle loading={loading} icon={icon} color={color}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1" component="div" align={contentAlign}>
          {message}
        </Typography>
        {messages && (
          <Grid className={classes.content} container spacing={1}>
            {messages.map((message) => {
              return (
                <Typography key={message[0]} variant="body1" component="div" align={contentAlign}>
                  {message}
                </Typography>
              )
            })}
          </Grid>
        )}
      </DialogContent>
      <DialogActions
        leftButton={
          leftActions ? (
            <div>
              <Grid container spacing={1}>
                {leftActions.map(({ label, ...btnProps }) => (
                  <Grid key={label} item>
                    <Button disabled={loading} variant={btnProps.variant ?? 'contained'} {...btnProps}>
                      {label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : null
        }
      >
        {actions ? (
          <div>
            <Grid container spacing={1}>
              {actions
                .filter((action) => !action.left)
                .map(({ label, ...btnProps }) => (
                  <Grid key={label} item>
                    <Button disabled={loading} variant={'contained'} {...btnProps}>
                      {label}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </div>
        ) : (
          <Button disabled={loading} onClick={onConfirm} variant={'contained'}>
            ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default AlertDialog

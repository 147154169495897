import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fill: {
    fill: theme.palette.primary.main
  },
  paper: {
    fill: '#fff',
    stroke: theme.palette.primary.main,
    strokeWidth: 5
  }
}))

export default function ThreeColumnThumbnail({ ...props }) {
  const classes = useStyles(props)

  const width = 40
  const height = 20

  return (
    <svg viewBox="0 0 150 200">
      <rect x={0} y={75} width={width} height={height} className={classes.fill} />

      <rect x={55} y={75} width={width} height={height} className={classes.fill} />
      <rect x={55} y={105} width={width} height={height} className={classes.fill} />

      <rect x={110} y={75} width={width} height={height * 2.5} className={classes.fill} />
    </svg>
  )
}

import React, { useState } from 'react'

import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import type { WithQuoteContext } from '../../../../context/withQuoteContext'
import { withQuoteContext } from '../../../../context/withQuoteContext'
import type { WithClientOptions } from '@ui/paintscout'
import { withClientOptions } from '@ui/paintscout'
import moment from 'moment'
import get from 'lodash/get'
import { Button } from '@ui/paintscout'

import CheckCircle from '@material-ui/icons/CheckCircle'

import Popover from '@material-ui/core/Popover'
import { Typography } from '@ui/paintscout'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(),
      borderRadius: 10,
      background: theme.palette.grey[700]
    },
    date: {},
    signature: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(1),
      height: 75,
      width: 'auto'
    },
    note: {},
    icon: {},
    approveButton: {
      color: theme.palette.success.main
    },
    popoverPaper: {
      padding: theme.spacing(2)
    }
  })
}

export interface AdditionalWorkSignatureProps extends WithStyles<typeof styles>, WithClientOptions, WithQuoteContext {}

/*

 */

function AdditionalWorkSignature({ classes, clientOptions, quoteContext }: AdditionalWorkSignatureProps) {
  const { quote } = quoteContext
  const { options } = clientOptions
  const [anchor, setAnchor] = useState(null)
  const dateFormat = get(options, 'dateFormat.momentValue', 'YYYY-MM-DD')

  if (!quote.additionalWork) {
    return null
  }

  const { approvedDate, approvedSignature: signature, approvedNote: note } = quote.additionalWork
  const date = moment(approvedDate).format(dateFormat)

  const open = Boolean(anchor)

  const handlePopoverOpen = (ev: React.MouseEvent) => {
    if (signature !== undefined) {
      setAnchor(ev.currentTarget)
    }
  }

  const handlePopoverClose = () => {
    setAnchor(null)
  }

  /*
  onMouseEnter={handlePopoverOpen}
  onMouseLeave={handlePopoverClose}
  style={{ pointerEvents: 'none' }}
   */

  return (
    <>
      <Button variant={'text'} classes={{ root: classes.approveButton }} icon={CheckCircle} onClick={handlePopoverOpen}>
        {date}
      </Button>
      <Popover
        open={open}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        anchorEl={anchor}
        disableRestoreFocus
      >
        {signature && <img className={classes.signature} src={signature} />}
        {note && <Typography classes={{ root: classes.note }}>Note: {note}</Typography>}
      </Popover>
    </>

    // <Tooltip title={`Approved ${date}`} content={content} icon={<CheckCircle classes={{ root: classes.icon }} />} />
    // <Grid container className={classes.root}>

    // </Grid>
  )
}

export default withStyles(styles)(withQuoteContext(withClientOptions(AdditionalWorkSignature)))

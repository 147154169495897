import type { OrderItem, QuoteDocument, QuoteImage } from 'paintscout'
import map from 'lodash/map'

// legacy function for getting images from quote.images - they're stored under quote.files now
export default function getImages(args: {
  quote?: QuoteDocument
  keys?: string[]
  allImages?: boolean
  attached?: boolean
}): QuoteImage[] {
  const { quote, keys, allImages, attached } = args

  if (!quote) {
    return []
  }

  if (attached) {
    return quote.order.area.reduce((acc: QuoteImage[], orderItem: OrderItem): QuoteImage[] => {
      let item
      if (orderItem._deleted) {
        return acc
      } else if (orderItem.type === 'line_item') {
        item = quote.lineItems[orderItem.key]
      } else if (orderItem.type === 'area') {
        item = quote.areas[orderItem.key]
      } else {
        return acc
      }

      if (!item || !item.images) {
        return acc
      }

      const images = item.images
        .filter((imageKey) => quote.images && quote.images[imageKey])
        .map((imageKey: string) => {
          return { ...quote.images[imageKey], type: 'image' }
        })
      return [...acc, ...images]
    }, [])
  }

  if (allImages) {
    return map(quote.images)
  }

  if (!keys) {
    return []
  }

  return keys
    .filter((key) => quote.images && quote.images[key])
    .reduce((images, key) => {
      if (quote.images[key]) {
        return [...images, { ...quote.images[key], type: 'image' }]
      }

      return images
    }, [])
}

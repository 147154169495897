/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument } from 'paintscout'

import type { QuoteItemSection, QuoteItemConsumer, QuoteItemView, RenderableItem } from '../../../index'
import { getItemsByArea } from '../get-items-by-area'
import { getCrewItemsByArea } from '../get-crew-items-by-area'
import { getItemsBySubstrate } from '../get-items-by-substrate'
import { getCrewItemsBySubstrate } from '../get-crew-items-by-substrate'

/**
 * Get a list of bid items given view, section, & consumer.
 * @param {Object} args
 * @param {QuoteDocument} args.quote
 * @param {OptionsDocument} args.options
 * @param {QuoteItemView} args.view
 * @param {QuoteItemSection} args.section
 * @param {QuoteItemConsumer} args.consumer
 * @return {RenderableItem[]}
 */
export function getItems(args?: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  view: QuoteItemView
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  showGroups?: boolean
  groupKey?: string
  showLineItems?: boolean
  keys?: string[]
  showValues?: boolean
  hideDimensions?: boolean
  trimBySubstrateSection?: QuoteItemSection
}): RenderableItem[] {
  const { view, consumer, groupKey } = args

  if (view === 'substrate' && consumer === 'crew') {
    return getCrewItemsBySubstrate(args)
  } else if (view === 'substrate') {
    return getItemsBySubstrate({ ...args, groupKey })
  } else if (view === 'area' && consumer === 'crew') {
    return getCrewItemsByArea(args)
  } else {
    return getItemsByArea(args)
  }
}

import type { QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'

export function calculateAmountPaid(quote: QuoteDocument) {
  if (!quote.payments) {
    quote.totals.amount_paid = 0
    return null
  }

  return quote.payments.reduce((totalPayments, payment) => {
    const paymentAmount = payment.status === 'pending' ? 0 : parseNumber(payment.amount)
    return totalPayments + paymentAmount
  }, 0)
}

const moment = require('moment')

import _get from 'lodash/get'
import { template } from '../../../../templater'

import { getObjectLabels } from '../../../util/get-object-labels'

export function getTemplatedFilename(args: any = {}) {
  const { report, quote, options, view } = args

  const extension = args.extension ? args.extension : 'pdf'

  let format = _get(args, 'options.pdf.filename.format')
  if (!format) {
    format = '{{fileType}}-{{quoteNumber}}-{{clientFirstName}}-{{clientLastName}}'
  }

  const quoteNumber = quote && quote.number ? quote.number : ''
  const quoteDate = quote && quote.quote_date ? moment(quote.quote_date).format('MM-DD-YY') : ''

  const objectLabels = getObjectLabels({ options })

  let fileType
  if ((report && report.label === 'Work Order') || view === 'work-order') {
    fileType = objectLabels.workOrder.value.toLowerCase().replace(' ', '-')
  } else if (view === 'product-order-form') {
    fileType = 'products'
  } else if (view === 'payment-receipt') {
    fileType = 'payment-receipt'
  } else if (quote && quote.is_invoice) {
    fileType = 'invoice'
  } else {
    fileType = objectLabels.quote.value.toLowerCase().replace(' ', '-')
  }

  const clientFirstName = (quote?.contact?.first_name ?? '').replace(/[^\w\s]/gi, '')
  const clientLastName = (quote?.contact?.last_name ?? '').replace(/[^\w\s]/gi, '')

  const revision = _get(quote, 'revision', '')

  const jobIdentifier = quote?.job_identifier ?? ''

  const filenameScope = {
    clientFirstName,
    clientLastName,
    jobIdentifier,
    fileType,
    revision,
    quoteDate,
    quoteNumber
  }

  let filename = template({
    text: format,
    context: filenameScope
  })

  if (filename[filename.length - 1] === '-') {
    filename = filename.slice(0, filename.length - 1)
  }

  if (!filename.endsWith(`.${extension}`)) {
    filename = `${filename}.${extension}`
  }

  filename = filename.replace(/\s+/gi, '-')
  return filename
}

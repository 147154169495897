import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { ActivityDot as CoreActivityDot } from '@ui/core'
import type { ActivityDotProps as CoreActivityDotProps } from '@ui/core'

export interface ActivityDotProps extends CoreActivityDotProps {}

const useStyles = makeStyles<Theme, ActivityDotProps>(
  (theme) => {
    return {
      root: ({ activity, ...props }) => {
        let color = props.color ?? theme.palette.quote.status.draft
        const quote = activity.details?.quote || activity.details?.invoice

        switch (activity.details.activityType) {
          case 'status-changed':
          case 'status-updated':
            color = theme.palette.quote.status[quote.status]
            break
          default:
            color = theme.palette.activityType[activity.details.activityType] ?? '#AAAAAA'
            break
        }

        return {
          color
        }
      }
    }
  },
  {
    name: 'ActivityDot'
  }
)

const ActivityDot = React.forwardRef<SVGElement, ActivityDotProps>(function ActivityDot(props, ref) {
  const classes = useStyles(props)

  return <CoreActivityDot {...props} classes={classes} ref={ref} />
})

export default ActivityDot

import React from 'react'
import CoreCheckbox from '@ui/core/Checkbox'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { CheckboxProps as CoreCheckboxProps } from '@ui/core/Checkbox'

// Primary = light gray when unchecked, dark gray when checked
// Secondary = dark gray when unchecked, white when checked
const useStyles = makeStyles<Theme, CoreCheckboxProps>((theme) => {
  const { pxToRem } = theme.typography

  const primaryColors = {
    checked: theme.palette.grey[700],
    unchecked: theme.palette.grey.A100,
    disabled: theme.palette.grey[200]
  }

  const secondaryColors = {
    checked: theme.palette.common.white,
    unchecked: theme.palette.grey[400],
    disabled: theme.palette.grey[700]
  }

  return {
    root: {},
    checked: {},
    disabled: {},
    colorPrimary: {
      color: primaryColors.checked,
      '&$checked': {
        color: primaryColors.checked
      },
      '& $uncheckedIcon': {
        background: primaryColors.unchecked
      },
      '&$disabled $uncheckedIcon': {
        background: primaryColors.disabled
      },
      '&$disabled': {
        color: primaryColors.disabled
      }
    },
    colorSecondary: {
      color: secondaryColors.checked,
      '&$checked': {
        color: secondaryColors.checked
      },
      '& $uncheckedIcon': {
        background: secondaryColors.unchecked
      },
      '&$disabled $uncheckedIcon': {
        background: secondaryColors.disabled
      },
      '&$disabled': {
        color: secondaryColors.disabled
      }
    },
    uncheckedIcon: {
      width: pxToRem(18),
      height: pxToRem(18),
      margin: pxToRem(3)
    }
  }
})

export interface CheckboxProps extends CoreCheckboxProps {
  classes?: CoreCheckboxProps['classes'] & StyleClasses<typeof useStyles>
  /**
   * Icon to show when unchecked
   */
  icon?: React.ReactNode
  /**
   * Icon to show when checked
   */
  checkedIcon?: React.ReactNode
}

function Checkbox(props: CheckboxProps) {
  const classes = useStyles(props)
  const { ...otherClasses } = classes

  return <CoreCheckbox {...props} classes={otherClasses} icon={UncheckedIcon(props)} />
}

const UncheckedIcon = ({ ...props }) => {
  const classes = useStyles(props)

  return (
    <div>
      <div className={classes.uncheckedIcon} />
    </div>
  )
}

export default Checkbox

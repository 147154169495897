import { Grid, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { QuoteDetails, QuoteItemSection, QuoteItemView } from '@paintscout/util/builder'
import {
  generateProductDescription,
  getDetails,
  getFeature,
  getObjectLabels,
  getQuoteOptions,
  getTags,
  getTotals,
  setQuoteOptions
} from '@paintscout/util/builder'
import { isEmpty } from '@paintscout/util/util'
import type { StyleClasses } from '@ui/core/theme'
import {
  Button,
  CustomBadge,
  DialogStackContext,
  FormSectionTitle,
  HtmlContent,
  QuoteTagChip,
  Typography,
  useClientOptions
} from '@ui/paintscout'
import classnames from 'classnames'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import React, { useContext, useMemo } from 'react'
import AreaSubstrateView from '../../../AreaSubstrateView'
import { useQuote } from '../../../context'
import EditCrewNoteDialog from '../../../dialogs/EditCrewNoteDialog'
import FilesByItem from '../../FilesByItem'
import QuoteHeader from '../../QuoteHeader'
import QuoteHeaderCard from '../../QuoteHeader/QuoteHeaderCard'
import OptionsTable from '../QuoteView/OptionsTable'
import WorkOrderTable from './WorkOrderTable'

const useStyles = makeStyles<Theme, WorkOrderViewProps>(
  (theme) => ({
    root: {},
    headerWrapper: {},
    note: {
      padding: 0
    },
    noteHover: {
      padding: 0
    },
    hours: {
      padding: 0
    },
    optionsTable: {},
    row: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between'
    },
    table: {
      // marginBottom: theme.spacing(4),
      '& thead': {
        background: theme.palette.grey[200]
      },
      '& thead th': {
        color: theme.palette.common.black
      }
    },
    hidePrint: {
      '@media print': {
        display: 'none'
      }
    },
    spacer: {
      marginTop: theme.spacing(5)
    },
    afterHeaderWrapper: {
      display: 'grid',
      gridTemplateColumns: 'auto 270px',
      gridGap: theme.spacing(2)
    },
    internalNote: {
      whiteSpace: 'pre-wrap'
    },
    headerTotals: {
      marginBottom: theme.spacing(2)
    },
    tags: {
      padding: theme.spacing(1)
    }
  }),
  { name: 'WorkOrderView' }
)

export interface WorkOrderViewProps {
  classes?: StyleClasses<typeof useStyles>
}

function WorkOrderView(props: WorkOrderViewProps) {
  const classes = useStyles(props)
  const { isEditable, isNoteEditable, quote, updateQuote, onReorder, onItemAction, onAddItem } = useQuote()
  const { options } = useClientOptions()
  const { openDialog, dismissDialog } = useContext(DialogStackContext)

  const quoteOptions = getQuoteOptions({ quote, options })
  const {
    showWorkOrderAreas,
    showWorkOrderAreaOptions,
    showWorkOrderSubstrates,
    showWorkOrderAreaValues,
    showWorkOrderSubstrateValues,
    showWorkOrderFiles,
    workOrderOptionsConsumer
  } = quoteOptions

  const details = useMemo(() => getDetails({ quote, options }), [quote, options])
  const objectLabels = getObjectLabels({ options })
  const { internal: internalNote } = details.notes

  const toggleWorkOrderTotalHours = getFeature({ options, path: 'quotes.workOrderToggleTotalHours' })

  const workOrderProductNote = useMemo(() => getProductNote({ quote, options, details }), [quote, options, details])

  const totals = useMemo(() => getTotals({ quote, options, consumer: 'crew' }), [quote, options])

  const hasTotalDimensions =
    totals.dimensions &&
    (totals.dimensions.lnft > 0 ||
      totals.dimensions.sqft > 0 ||
      totals.dimensions.sqftWalls > 0 ||
      totals.dimensions.sqftCeiling > 0 ||
      totals.dimensions.sqftFloor > 0)

  const handleOptionsViewChange = (event: any, view: 'crew' | 'customer') => {
    const quoteOptions = getQuoteOptions({ options, quote })

    const updatedQuote = setQuoteOptions({ quoteOptions: { ...quoteOptions, workOrderOptionsConsumer: view }, quote })
    updateQuote({ quote: updatedQuote })
  }

  const handleToggleAmount = (_ev: any) => {
    const quoteOptions = getQuoteOptions({ options, quote })
    const keys = [
      'showWorkOrderAreaValues',
      'showWorkOrderSubstrateValues',
      'showWorkOrderAreaOptionValues',
      'showWorkOrderSubstrateOptionValues'
    ]
    keys.forEach((key) => {
      quoteOptions[key] = !quoteOptions[key]
    })

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    updateQuote({ quote: updatedQuote })
  }

  const handleEditNote = (_event: React.MouseEvent<HTMLElement>) => {
    openDialog(EditCrewNoteDialog, {
      quote,
      onConfirm: (updatedQuote: QuoteDocument) => {
        updateQuote({ quote: updatedQuote })

        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const handleReorder = (args: { items: any[]; section: QuoteItemSection; view: QuoteItemView }) => {
    if (onReorder) {
      onReorder(args)
    }
  }

  const handleAdd = (_event: React.MouseEvent<HTMLElement>) => {
    if (onAddItem) {
      onAddItem()
    }
  }

  const handleItemActionClick = (ev: any, action: string, section: QuoteItemSection, key: string) => {
    onItemAction(action, section, [key])
  }

  const handleToggle = (event: any, name: string) => {
    const quoteOptions = getQuoteOptions({ options, quote })

    quoteOptions[name] = !quoteOptions[name]

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    updateQuote({ quote: updatedQuote })
  }

  const noteHoverContent = (
    <Button variant={'outlined'} fullWidth={true} classes={{ root: classes.hidePrint }}>
      Edit Crew Note
    </Button>
  )
  const productHoverContent = (
    <Button variant={'outlined'} fullWidth={true} classes={{ root: classes.hidePrint }}>
      Edit Product Description
    </Button>
  )

  const optionsViewSwitcher = isEditable ? (
    <AreaSubstrateView
      hideOnSmall={true}
      view={workOrderOptionsConsumer}
      onChange={handleOptionsViewChange}
      options={[
        { label: 'Customer/Price', value: 'customer' },
        { label: 'Crew/Hours', value: 'crew' }
      ]}
    />
  ) : null

  const hasInternalNote = !isEmpty(internalNote)

  const totalHours = details.totals?.hours?.useCustom ? details.totals.hours.custom : details.totals.hours.default
  const showTotalHours =
    (toggleWorkOrderTotalHours || showWorkOrderAreaValues) && (isEditable || Number(totalHours) > 0)
  const tags = useMemo(() => (quote.tags ? getTags({ options, keys: quote.tags }) : []), [quote.tags, options])

  return (
    <div className={classes.root} id={'work-order'} data-testid="work-order">
      <Grid item container spacing={1} className={classes.tags}>
        {tags.map((tag) => {
          return (
            <Grid item key={tag.name}>
              <QuoteTagChip tag={tag} />
            </Grid>
          )
        })}
      </Grid>
      <div className={classes.headerWrapper}>
        <QuoteHeader isEditable={isEditable} variant="work-order" />
      </div>
      <Grid container={true} spacing={3} classes={{ root: classes.headerTotals }}>
        <Grid item={true} xs={12} sm={8}>
          {(hasInternalNote || isEditable || isNoteEditable) && (
            <QuoteHeaderCard
              title="Crew Note"
              classes={{ contents: classes.note, placeholderContent: classes.noteHover }}
              onClick={handleEditNote}
              clickable={isEditable || isNoteEditable}
              placeholderContent={noteHoverContent}
              showPlaceholder={!hasInternalNote}
            >
              <Typography
                variant={'body1'}
                className={classnames(classes.internalNote, 'crew-note-highlight')}
                component={'div'}
              >
                {hasInternalNote && <HtmlContent content={internalNote} />}
              </Typography>
            </QuoteHeaderCard>
          )}
        </Grid>

        <Grid item={true} xs={12} sm={4} className={'hours-card'}>
          {showTotalHours && (
            <QuoteHeaderCard
              title="Total Hours"
              classes={{ contents: classes.hours }}
              clickable={isEditable}
              onClick={handleEditNote}
            >
              {details.totals.hours.useCustom ? (
                <CustomBadge showBadge={isEditable}>
                  <Typography variant={'h2'} format={'hours'}>
                    {details.totals.hours.custom.toString()}
                  </Typography>
                </CustomBadge>
              ) : (
                <Typography
                  variant={'h2'}
                  value={
                    typeof details.totals.hours.default === 'string'
                      ? parseFloat(details.totals.hours.default)
                      : details.totals.hours.default
                  }
                  format={'hours'}
                />
              )}
            </QuoteHeaderCard>
          )}
        </Grid>

        <Grid item={true} xs={12} sm={8}>
          {(!!workOrderProductNote || isEditable) && (
            <QuoteHeaderCard
              title="Product Description"
              classes={{ contents: classes.note, placeholderContent: classes.noteHover }}
              onClick={handleEditNote}
              clickable={isEditable}
              placeholderContent={productHoverContent}
              showPlaceholder={!workOrderProductNote}
            >
              <Typography variant={'body1'} component={'div'} className={classes.internalNote}>
                <HtmlContent content={workOrderProductNote} />
              </Typography>
            </QuoteHeaderCard>
          )}
        </Grid>
        <Grid item={true} xs={12} sm={4} classes={{ root: 'total-dimensions' }}>
          {hasTotalDimensions && (
            <QuoteHeaderCard title={`Total Dimensions (${objectLabels.unit.abbreviation.square})`} clickable={false}>
              <Grid container={true}>
                {totals.dimensions.sqftWalls > 0 && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="span">Walls: </Typography>
                    <Typography component="span" value={totals.dimensions.sqftWalls} />
                  </Grid>
                )}
                {totals.dimensions.sqftCeiling > 0 && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="span">Ceiling: </Typography>
                    <Typography component="span" value={totals.dimensions.sqftCeiling} />
                  </Grid>
                )}
                {totals.dimensions.sqftFloor > 0 && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="span">Floor: </Typography>
                    <Typography component="span" value={totals.dimensions.sqftFloor} />
                  </Grid>
                )}
                {totals.dimensions.sqft > 0 && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="span">Other: </Typography>
                    <Typography component="span" value={totals.dimensions.sqft} />
                  </Grid>
                )}
                {totals.dimensions.lnft > 0 && (
                  <Grid item xs={12} sm={6}>
                    <Typography component="span">{objectLabels.unit.abbreviation.linear}: </Typography>
                    <Typography component="span" value={totals.dimensions.lnft} />
                  </Grid>
                )}
              </Grid>
            </QuoteHeaderCard>
          )}
        </Grid>
      </Grid>

      <div className={classnames({ [classes.hidePrint]: !showWorkOrderAreas })}>
        <WorkOrderTable
          classes={{ root: classes.table }}
          section={'bid'}
          tableView={'area'}
          title={'Areas'}
          collapsed={!showWorkOrderAreas}
          onReorder={handleReorder}
          onAddClick={handleAdd}
          onItemClick={(ev, key) => handleItemActionClick(ev, 'edit', 'bid', key)}
          showAddButton={false}
          onToggle={(ev: any) => handleToggle(ev, 'showWorkOrderAreas')}
          showHours={showWorkOrderAreaValues}
          onToggleAmount={handleToggleAmount}
          showEmpty={true}
          showLineItems={true}
        />
      </div>

      {showWorkOrderAreas && (
        <div className={classnames({ [classes.hidePrint]: !showWorkOrderAreas })}>
          <WorkOrderTable
            classes={{ root: classes.table }}
            section={'additional'}
            tableView={'area'}
            title={'Additional Work'}
            onReorder={handleReorder}
            onAddClick={handleAdd}
            onItemClick={(ev, key) => handleItemActionClick(ev, 'edit', 'additional', key)}
            showAddButton={false}
            showHours={showWorkOrderAreaValues}
            showEmpty={false}
            showLineItems={true}
          />
        </div>
      )}

      <div className={classnames({ [classes.hidePrint]: !showWorkOrderSubstrates })}>
        <WorkOrderTable
          classes={{ root: classes.table }}
          section={'bid'}
          tableView={'substrate'}
          title={objectLabels.substrate.plural}
          collapsed={!showWorkOrderSubstrates}
          onReorder={handleReorder}
          onAddClick={handleAdd}
          onItemClick={(ev, key) => handleItemActionClick(ev, 'edit', 'bid', key)}
          onToggle={(ev: any) => handleToggle(ev, 'showWorkOrderSubstrates')}
          showHours={showWorkOrderSubstrateValues}
          showLineItems={!showWorkOrderAreas}
          showEmpty={true}
        />
      </div>
      {showWorkOrderSubstrates && (
        <div className={classnames({ [classes.hidePrint]: !showWorkOrderSubstrates })}>
          <WorkOrderTable
            classes={{ root: classes.table }}
            section={'additional'}
            tableView={'substrate'}
            title={'Additional Work'}
            onReorder={handleReorder}
            onAddClick={handleAdd}
            onItemClick={(ev, key) => handleItemActionClick(ev, 'edit', 'additional', key)}
            showAddButton={false}
            showHours={showWorkOrderSubstrateValues}
            showEmpty={false}
            showLineItems={true}
          />
        </div>
      )}

      <div
        className={classnames({
          [classes.optionsTable]: true,
          [classes.hidePrint]: !showWorkOrderAreaOptions
        })}
      >
        {workOrderOptionsConsumer === 'customer' ? (
          <OptionsTable
            collapsed={!showWorkOrderAreaOptions}
            collapsedIfEmpty
            onToggle={(ev: any) => handleToggle(ev, 'showWorkOrderAreaOptions')}
            onItemAction={(action, section, item) => handleItemActionClick(null, action, section, item.key)}
            consumer={'customer'}
            showValues={showWorkOrderAreaValues}
            rightContent={optionsViewSwitcher}
          />
        ) : (
          <WorkOrderTable
            classes={{ root: classes.table }}
            section={'options'}
            tableView={'area'}
            rightContent={optionsViewSwitcher}
            title={objectLabels.option.plural}
            subTitle={'These items are optional additions and are not included in the total'}
            collapsed={!showWorkOrderAreaOptions}
            onReorder={handleReorder}
            onAddClick={handleAdd}
            onItemClick={(ev, key) => handleItemActionClick(ev, 'edit', 'options', key)}
            showAddButton={false}
            onToggle={(ev: any) => handleToggle(ev, 'showWorkOrderAreaOptions')}
            showHours={showWorkOrderAreaValues}
            onToggleAmount={handleToggleAmount}
            showEmpty={true}
            showLineItems={true}
          />
        )}
      </div>

      <div id={'pictures'} className={classnames({ [classes.hidePrint]: !showWorkOrderFiles })}>
        <FilesByItem
          FormSectionTitle={
            <FormSectionTitle
              title={'Media'}
              showToggle={isEditable}
              toggleValue={showWorkOrderFiles}
              style={{ marginBottom: 0 }}
              onToggle={(ev: any) => handleToggle(ev, 'showWorkOrderFiles')}
            />
          }
          sections={['bid', 'additional', 'added-options', 'pending', 'options']}
          consumer={'crew'}
        />
      </div>
    </div>
  )
}

const getProductNote = ({
  quote,
  options,
  details
}: {
  quote: QuoteDocument
  options: OptionsDocument
  details: QuoteDetails
}) => {
  const productNote = details.notes.products.useCustom
    ? (details.notes.products.custom as string)
    : (details.notes.products.default as string)
  // We want additional work and added options products to be in product description too. Lets add them to the end with a note
  const additionalWorkNote = generateProductDescription({ quote, options, section: 'additional' })
  let workOrderProductNote = productNote
  if (additionalWorkNote.length > 0) {
    workOrderProductNote =
      workOrderProductNote +
      `${workOrderProductNote.length ? '\n' : ''}<b>Additional Work Products:</b>` +
      '\n' +
      additionalWorkNote
  }
  return workOrderProductNote
}

export default WorkOrderView

import { makeStyles } from '@material-ui/core'
import { Collapse, Typography } from '@ui/paintscout'
import React from 'react'
import { useQuote } from '../../context'
import type { WisetackPromoResponse } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    '@media print': {
      display: 'none'
    }
  }
}))

export default function WisetackPromoDisclaimer() {
  const { wisetackPromo } = useQuote()

  return (
    <Collapse show={!!wisetackPromo && !wisetackPromo.error}>
      <WisetackDisclaimerContent wisetackPromo={wisetackPromo} />
    </Collapse>
  )
}

export function WisetackDisclaimerContent({ wisetackPromo }: { wisetackPromo: WisetackPromoResponse }) {
  const classes = useStyles()
  function parseText(text: string) {
    if (!text) {
      return null
    }
    const words = text.split(' ')
    return words
      .map((word, i) => {
        const urlPattern = new RegExp('^(http|https)://', 'i')
        if (urlPattern.test(word)) {
          return (
            <a href={word} key={`word-${i}`} target="_blank" rel="noopener noreferrer">
              {word}
            </a>
          )
        } else {
          return word
        }
      })
      .reduce((prev, current, i) => {
        if (i === 0) {
          return [current]
        } else {
          return [...prev, ' ', current]
        }
      }, [] as JSX.Element[])
  }
  return (
    <div className={classes.root}>
      <Typography align="left" variant="caption">
        {parseText(wisetackPromo?.disclosure)}
      </Typography>
    </div>
  )
}

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Typography from '../Typography'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.overline
  },
  container: ({ orientation = 'vertical' }: NoResultsProps) => ({
    display: 'flex',
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }),
  graphic: ({ variant, baseWidth = 133, baseHeight = 159, scale = 1, ratio = 0.5 }: NoResultsProps) => ({
    maxWidth: '100%',
    maxHeight: '100%',
    width: (variant === 'psLogo' ? baseWidth : 175) * scale * ratio,
    height: (variant === 'psLogo' ? baseHeight : 175) * scale * ratio
  }),
  messageTypography: ({ scale = 1, orientation = 'vertical' }: NoResultsProps) => ({
    ...theme.typography.overline,
    color: theme.palette.grey[700],
    fontSize: `${scale}em !important`,
    textAlign: orientation === 'vertical' ? 'center' : 'left'
  }),
  message: ({ variant, scale = 1, orientation = 'vertical' }: NoResultsProps) => {
    const margin = variant === 'psLogo' ? theme.spacing(2 * scale) : theme.spacing(1 * scale)
    return {
      textAlign: orientation === 'vertical' ? 'center' : 'left',
      margin: `${margin}px ${orientation === 'vertical' ? margin : 0}px ${
        orientation === 'vertical' ? 0 : margin
      }px ${margin}px`
    }
  }
}))

export interface NoResultsProps {
  orientation?: 'vertical' | 'horizontal'
  variant?: 'psLogo' | 'warning'
  message?: string | React.ReactNode
  scale?: number
  ratio?: number
  baseWidth?: number
  baseHeight?: number
}

function NoResults({ message, variant = 'psLogo', ...props }: NoResultsProps) {
  const classes = useStyles({
    ...props,
    variant
  })

  return (
    <div className={classes.container}>
      {variant === 'psLogo' ? (
        <svg viewBox="0 0 133 159" className={classes.graphic}>
          <g id="no-results">
            <path
              id="brush"
              d="M49.2997632,71.6918526 L85.50042,65.2654068 L84.55042,95.8742693 L61.0473936,101.145883 C61.1080183,97.6355763 61.1080183,94.4723801 61.0473936,91.6562946 C59.9274321,97.4762655 57.7627202,103.108892 47.9712472,104.330731 C47.9676104,99.7610873 48.4104491,88.8814612 49.2997632,71.6918526 Z"
              fill="#B7D0E9"
            />
            <path
              id="handle"
              d="M49.3561715,66.8455235 L85.50042,59.85 L85.50042,49.7308981 C83.7150545,49.7308981 81.7906123,49.7308981 79.7270934,49.7308981 C76.6318152,49.7308981 73.6438837,47.0944075 73.6438837,42.4176452 C73.6438837,37.7408828 73.6438837,28.1726647 73.6438837,24.8866238 C73.6438837,21.6005829 70.2001833,20.9120596 67.4000916,20.9120596 C64.6,20.9120596 61.9588153,23.2306124 61.9588153,26.714937 C61.9588153,30.1992617 61.9588153,36.9833658 61.9588153,43.8787915 C61.9588153,50.7742172 54.2592509,55.7965815 49.3561715,57.9038594 C49.2809605,60.2522688 49.2809605,63.2328234 49.3561715,66.8455235 Z"
              fill="#B7D0E9"
            />
            <path
              id="outline"
              d="M66.025,1.42108547e-14 C102.489601,1.42108547e-14 132.05,29.5603994 132.05,66.025 C132.05,102.489601 98.9205633,135.220209 66.025,158.652703 C34.2056064,135.954184 0,102.489601 0,66.025 C0,29.5603994 29.5603994,1.42108547e-14 66.025,1.42108547e-14 Z M111.305472,31.2616282 L31.2616282,111.305472 C40.9596013,118.943228 53.197655,123.5 66.5,123.5 C97.9802307,123.5 123.5,97.9802307 123.5,66.5 C123.5,53.197655 118.943228,40.9596013 111.305472,31.2616282 Z M66.5,9.5 C35.0197693,9.5 9.5,35.0197693 9.5,66.5 C9.5,81.2560559 15.1071415,94.7024938 24.3070016,104.824891 L104.824891,24.3070016 C94.7024938,15.1071415 81.2560559,9.5 66.5,9.5 Z"
              fill="#EEEEEE"
            />
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" className={classes.graphic} viewBox="0 -960 960 960">
          <path
            fill="#767676"
            d="M157.261-160q-8.108 0-14.459-3.913-6.351-3.914-9.879-10.318-3.526-5.523-3.879-12.992-.352-7.469 3.754-14.723L456.58-759.072q3.958-6.236 10.074-9.736 6.115-3.5 13.346-3.5t13.346 3.5q6.116 3.5 10.074 9.736l323.782 557.126q4.106 7.254 3.754 14.723-.353 7.469-3.879 12.992-3.528 6.404-9.879 10.318Q810.847-160 802.739-160H157.261Zm5.2-30.769h635.078L480-738.462 162.461-190.769Zm319.42-63.154q8.581 0 14.196-5.804 5.615-5.805 5.615-14.385t-5.804-14.196q-5.804-5.615-14.385-5.615-8.58 0-14.195 5.804-5.616 5.805-5.616 14.385t5.805 14.196q5.804 5.615 14.384 5.615Zm.027-84.846q6.631 0 10.9-4.423t4.269-10.962v-180.923q0-6.538-4.485-10.962-4.485-4.423-11.116-4.423-6.63 0-10.899 4.423-4.269 4.424-4.269 10.962v180.923q0 6.539 4.485 10.962t11.115 4.423ZM480-464.615Z"
          />
        </svg>
      )}

      {message && typeof message === 'string' && (
        <Typography className={classnames(classes.message, classes.messageTypography)}>{message}</Typography>
      )}
      {message && typeof message !== 'string' && <div className={classes.message}>{message}</div>}
    </div>
  )
}

export default NoResults

import React from 'react'
import type { WithStyles } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'

const styles = createStyles({
  root: {}
})

export interface CustomBadgeProps extends WithStyles<typeof styles> {
  showBadge?: boolean
  message?: string
  children: React.ReactNode
}

function CustomBadge({ classes, children, showBadge, message: messageProp }: CustomBadgeProps) {
  const message = messageProp ?? 'This value has been customized'

  if (showBadge) {
    return (
      <Tooltip title={message}>
        <Badge variant="dot" color="primary" classes={{ badge: classes.root }}>
          {children}
        </Badge>
      </Tooltip>
    )
  }

  return <>{children}</>
}

export default withStyles(styles)(CustomBadge)

import React from 'react'
import { Grid, useClientOptions } from '@ui/paintscout'
import { Editor } from '@ui/paintscout'
import { FormSectionTitle } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { useQuote } from '../../../context'

const useStyles = makeStyles<Theme, NotesViewProps>(
  (theme) => ({
    root: {},
    editor: {
      '& #menu-bar': {
        top: 83,
        [theme.breakpoints.down('sm')]: {
          top: 68
        }
      }
    }
  }),
  { name: 'NotesView' }
)

export interface NotesViewProps {
  classes?: StyleClasses<typeof useStyles>
}

function NotesView(props: NotesViewProps) {
  const classes = useStyles(props)
  const { quote, isEditable, isNoteEditable, updateQuote } = useQuote()
  const { options } = useClientOptions()
  const estimatorNotes = quote?.estimatorNotes
  const objectLabels = getObjectLabels({ options })

  const handleChange = (value: any) => {
    if (quote.estimatorNotes === (value as string)) {
      return
    }

    const updatedQuote = {
      ...quote,
      estimatorNotes: value as string
    }

    updateQuote({ quote: updatedQuote })
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <FormSectionTitle
          title={'Estimator Notes'}
          variant="h2"
          subTitle={`These notes are a "scratch pad" for estimators and won't appear anywhere else on the ${objectLabels.quote.value.toLowerCase()}`}
        />
      </Grid>
      <Grid item xs={12}>
        <Editor
          classes={{ root: classes.editor }}
          content={estimatorNotes}
          onChange={handleChange}
          fullWidth={true}
          disabled={!isEditable && !isNoteEditable}
          /* this is nasty, but the slate editor was 0% interested
          in becoming not-disabled when changed to editable */
          key={`notesView-editor-${isEditable}`}
          debounce
        />
      </Grid>
    </Grid>
  )
}

export default NotesView

import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import React from 'react'
import type { PresentationSectionProps } from '../../../..'
import ColorRowPreview from '../_components/ColorRowPreview'
import type { ColorTileOption } from '../_components/ColorRowPreview/ColorRowPreview'
import ContentBlock from '../_components/ContentBlock'
import PageHeader from '../_components/PageHeader'

const useStyles = makeStyles<Theme, ColorSamplesProps>(
  (theme) => ({
    root: {
      background: theme.palette.common.white,
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
      overflow: 'hidden'
    },
    freePaintChips: {
      padding: '70px 40px',
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '50px'
    },
    peelAndStick: {
      padding: '70px 40px',
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '50px',
      background: '#EDEAE5'
    }
  }),
  { name: 'ColorSamples' }
)

export interface ColorSamplesProps {
  classes?: StyleClasses<typeof useStyles>
}

function ColorSamples(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { quote } = props

  return (
    <div className={classes.root}>
      <PageHeader
        title={
          <>
            Get Free
            <br />
            Color Samples
          </>
        }
        ctaLabel="Order Samples"
        ctaLink="https://samples.sherwin-williams.com/view/top-50-colors?_gl=1*ipru9e*_gcl_aw*R0NMLjE3MDk3Mzg0MzAuQ2p3S0NBaUFyZmF1QmhBcEVpd0Flb0I3cUFQSkozVXp1SjF4NlpDNFk1XzBTekJKMTNXcnl4Yy1PM3lMcGE2UXhOSXBmbG1JRl9YRHl4b0M5YXdRQXZEX0J3RQ..*_gcl_au*MTg3MzYxNTQwNy4xNzA1NTEwMjA2*_ga*MTkxMDU0MDU2MS4xNzAxNzIwOTM1*_ga_BFTFS1H2E0*MTcwOTgzMDQ2Ni45NC4xLjE3MDk4MzA1NDEuNTMuMC4w&_ga=2.44348844.941519321.1709664242-1910540561.1701720935&_gac=1.223506281.1709738716.CjwKCAiArfauBhApEiwAeoB7qAPJJ3UzuJ1x6ZC4Y5_0SzBJ13Wryxc-O3yLpa6QxNIpflmIF_XDyxoC9awQAvD_BwE"
        backgroundImage={
          'https://res.cloudinary.com/taptapquote/image/upload/v1709849402/paintscout/partners/sw/images/color-sample-header-background.png'
        }
        headerBackgroundColor="#507C88"
      />

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples?.rightToYourDoor && (
        <ContentBlock
          img={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849404/paintscout/partners/sw/images/color-to-go-on-ladder.png'
          }
          preTitle="GET FREE PAINT CHIPS DELIVERED"
          title="Right to Your Door!"
          description={
            <>
              See how your top color picks compare. Get a
              <br />
              feel for how they’ll look in your space using
              <br />
              color chips.
            </>
          }
          linkHref="https://samples.sherwin-williams.com/view/top-50-colors?_gl=1*ipru9e*_gcl_aw*R0NMLjE3MDk3Mzg0MzAuQ2p3S0NBaUFyZmF1QmhBcEVpd0Flb0I3cUFQSkozVXp1SjF4NlpDNFk1XzBTekJKMTNXcnl4Yy1PM3lMcGE2UXhOSXBmbG1JRl9YRHl4b0M5YXdRQXZEX0J3RQ..*_gcl_au*MTg3MzYxNTQwNy4xNzA1NTEwMjA2*_ga*MTkxMDU0MDU2MS4xNzAxNzIwOTM1*_ga_BFTFS1H2E0*MTcwOTgzMDQ2Ni45NC4xLjE3MDk4MzA1NDEuNTMuMC4w&_ga=2.44348844.941519321.1709664242-1910540561.1701720935&_gac=1.223506281.1709738716.CjwKCAiArfauBhApEiwAeoB7qAPJJ3UzuJ1x6ZC4Y5_0SzBJ13Wryxc-O3yLpa6QxNIpflmIF_XDyxoC9awQAvD_BwE"
          linkLabel="Order Free Chips"
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples?.topHottestColors && (
        <ColorRowPreview
          title="Our Top Hottest Colors"
          description="Getting paint samples was never this easy."
          colorTileOptions={topHottestColors}
          links={[
            {
              href: 'https://samples.sherwin-williams.com/view/top-50-colors?_gl=1*ipru9e*_gcl_aw*R0NMLjE3MDk3Mzg0MzAuQ2p3S0NBaUFyZmF1QmhBcEVpd0Flb0I3cUFQSkozVXp1SjF4NlpDNFk1XzBTekJKMTNXcnl4Yy1PM3lMcGE2UXhOSXBmbG1JRl9YRHl4b0M5YXdRQXZEX0J3RQ..*_gcl_au*MTg3MzYxNTQwNy4xNzA1NTEwMjA2*_ga*MTkxMDU0MDU2MS4xNzAxNzIwOTM1*_ga_BFTFS1H2E0*MTcwOTgzMDQ2Ni45NC4xLjE3MDk4MzA1NDEuNTMuMC4w&_ga=2.44348844.941519321.1709664242-1910540561.1701720935&_gac=1.223506281.1709738716.CjwKCAiArfauBhApEiwAeoB7qAPJJ3UzuJ1x6ZC4Y5_0SzBJ13Wryxc-O3yLpa6QxNIpflmIF_XDyxoC9awQAvD_BwE',
              label: 'Order Free Chips',
              theme: 'dark'
            },
            {
              href: 'https://www.sherwin-williams.com/en-us/color/color-collections/top-50-colors',
              label: 'View All Color Chips',
              theme: 'dark'
            }
          ]}
          style={{ padding: '40px' }}
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples?.finestWhites && (
        <ColorRowPreview
          title="Our Finest Whites"
          description="Clean and bright, white paints can add lightness to any space."
          colorTileOptions={finestWhites}
          links={[
            {
              href: 'https://samples.sherwin-williams.com/view/top-50-colors?_gl=1*ipru9e*_gcl_aw*R0NMLjE3MDk3Mzg0MzAuQ2p3S0NBaUFyZmF1QmhBcEVpd0Flb0I3cUFQSkozVXp1SjF4NlpDNFk1XzBTekJKMTNXcnl4Yy1PM3lMcGE2UXhOSXBmbG1JRl9YRHl4b0M5YXdRQXZEX0J3RQ..*_gcl_au*MTg3MzYxNTQwNy4xNzA1NTEwMjA2*_ga*MTkxMDU0MDU2MS4xNzAxNzIwOTM1*_ga_BFTFS1H2E0*MTcwOTgzMDQ2Ni45NC4xLjE3MDk4MzA1NDEuNTMuMC4w&_ga=2.44348844.941519321.1709664242-1910540561.1701720935&_gac=1.223506281.1709738716.CjwKCAiArfauBhApEiwAeoB7qAPJJ3UzuJ1x6ZC4Y5_0SzBJ13Wryxc-O3yLpa6QxNIpflmIF_XDyxoC9awQAvD_BwE',
              label: 'Order Free Chips',
              theme: 'dark'
            },
            {
              href: 'https://www.sherwin-williams.com/en-us/color/color-family/white-paint-colors',
              label: 'View All Whites',
              theme: 'dark'
            }
          ]}
          style={{ padding: '40px 40px 80px' }}
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples?.peelAndStick && (
        <ContentBlock
          imgFirst
          img={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849405/paintscout/partners/sw/images/peel-and-stick-image.png'
          }
          preTitle="NEW"
          title={<>Peel & Stick</>}
          description={'No mess, no dry time. Try 8” x 8” samples.'}
          linkHref="https://samples.sherwin-williams.com/view/top-50-colors?_gl=1*ipru9e*_gcl_aw*R0NMLjE3MDk3Mzg0MzAuQ2p3S0NBaUFyZmF1QmhBcEVpd0Flb0I3cUFQSkozVXp1SjF4NlpDNFk1XzBTekJKMTNXcnl4Yy1PM3lMcGE2UXhOSXBmbG1JRl9YRHl4b0M5YXdRQXZEX0J3RQ..*_gcl_au*MTg3MzYxNTQwNy4xNzA1NTEwMjA2*_ga*MTkxMDU0MDU2MS4xNzAxNzIwOTM1*_ga_BFTFS1H2E0*MTcwOTgzMDQ2Ni45NC4xLjE3MDk4MzA1NDEuNTMuMC4w&_ga=2.44348844.941519321.1709664242-1910540561.1701720935&_gac=1.223506281.1709738716.CjwKCAiArfauBhApEiwAeoB7qAPJJ3UzuJ1x6ZC4Y5_0SzBJ13Wryxc-O3yLpa6QxNIpflmIF_XDyxoC9awQAvD_BwE"
          linkLabel="Order Now"
          backgroundColor="#EDEAE5"
        />
      )}
    </div>
  )
}

export default ColorSamples

const topHottestColors: ColorTileOption[] = [
  {
    colorTile: {
      color: '#C2C0BB',
      colorCode: '0055',
      colorName: 'Light French Gray',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#EDEAE0',
      colorCode: '7008',
      colorName: 'Alabaster',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#EDECE6',
      colorCode: '7005',
      colorName: 'Pure White',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#D1C7B8',
      colorCode: '7036',
      colorName: 'Accessible Beige',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#CDD2CA',
      colorCode: '6204',
      colorName: 'Sea Salt',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#EDEAE5',
      colorCode: '7004',
      colorName: 'Snowbound',
      tall: true,
      darkText: true
    }
  }
]

const finestWhites: ColorTileOption[] = [
  {
    colorTile: {
      color: '#EDEAE0',
      colorCode: '7008',
      colorName: 'Alabaster',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#EDECE6',
      colorCode: '7005',
      colorName: 'Pure White',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#EDEAE5',
      colorCode: '7004',
      colorName: 'Snowbound',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#EEEFEA',
      colorCode: '7006',
      colorName: 'Extra White',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#F0EADC',
      colorCode: '6385',
      colorName: 'Dover White',
      tall: true,
      darkText: true
    }
  },
  {
    colorTile: {
      color: '#E3E4E1',
      colorCode: '6252',
      colorName: 'Ice Cubde',
      tall: true,
      darkText: true
    }
  }
]

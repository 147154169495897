import { ClickAwayListener, FormControl } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { DayPickerSingleDateController } from 'react-dates'
import type { Moment } from 'moment'
import moment from 'moment'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import type { PopperProps as MuiPopperProps } from '@material-ui/core/Popper'
import IconButton from '../IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import classnames from 'classnames'
import Paper from '../Paper'
import DatePickerMonthDropdown from './DatePickerMonthDropdown'
import ResponsivePopper from '../ResponsivePopper'
import { useDatePickerStyles } from './useDatePickerStyles'
import InputField from '../InputField'
import Button from '../Button'
import type { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'
import { useBreakpoint } from '@ui/core'

export interface SingleDatePickerProps {
  label?: string
  sublabel?: string
  date?: Moment
  disabled?: boolean
  PopperProps?: Partial<MuiPopperProps>
  modalBreakpoint?: keyof BreakpointValues
  clearable?: boolean
  onChange: (date: Moment) => void
  InputComponent?: React.ComponentType<any>
}

export function SingleDatePicker(props: SingleDatePickerProps) {
  const {
    label,
    sublabel,
    disabled,
    clearable,
    PopperProps,
    modalBreakpoint = 'sm',
    onChange,
    InputComponent = InputField
  } = props
  const mobile = useBreakpoint((breakpoints) => breakpoints.down(modalBreakpoint))
  const dateFormat = 'MMM D, YYYY'

  const [tableHeight, setTableHeight] = useState(0)

  const anchorRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [date, setDate] = useState<Moment>(props.date)

  useEffect(() => {
    recalculateTableHeight()
  }, [anchorEl])

  const recalculateTableHeight = () => {
    const elements = document.getElementsByClassName('CalendarMonthGrid_month__horizontal')
    if (elements.length > 0) {
      const height = (elements[0] as any).offsetHeight
      setTableHeight(height)
    }
  }

  const classes = useDatePickerStyles({ isSingle: true, tableHeight })

  const handleClickAway = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (e.target instanceof HTMLElement && e.target.className.includes('top-navigation')) {
      // This means the click was in a nested dropdown, so we don't want to close the date picker
      return
    }
    setAnchorEl(null)
  }

  const handleChange = (date: Moment) => {
    setDate(date)
    setAnchorEl(null)
    onChange(date)
  }

  const handleClear = (e) => {
    e.stopPropagation()
    setDate(null)
    onChange(null)
    setAnchorEl(null)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl
        fullWidth
        onClick={() => {
          if (props.InputComponent) {
            setAnchorEl(anchorEl ? null : anchorRef.current)
          }
        }}
      >
        <InputComponent
          className={classes.input}
          placeholder="Select Date"
          inputRef={anchorRef}
          readOnly
          fullWidth
          value={date ? date.format(dateFormat) : ''}
          label={label}
          sublabel={sublabel}
          onClick={() => {
            setAnchorEl(anchorEl ? null : anchorRef.current)
          }}
          disabled={disabled}
          endAdornment={
            clearable &&
            date && (
              <IconButton size="small" onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            )
          }
        />
        <ResponsivePopper
          classes={{ root: classes.popper, dialogContent: classes.dialogContent }}
          open={!!anchorEl}
          PopperProps={{
            anchorEl,
            placement: 'bottom-start',
            modifiers: {
              flip: {
                enabled: false
              }
            },
            disablePortal: true,
            ...PopperProps
          }}
          modalBreakpoint={modalBreakpoint}
          footer={
            mobile && (
              <div className={classes.mobileFooter}>
                <Button variant="text" onClick={() => setAnchorEl(null)}>
                  cancel
                </Button>
              </div>
            )
          }
          // footer={<DropdownSelectFooter hideDivider onApply={handleApply} onClear={handleClear} />}
        >
          <Paper className={classes.paper}>
            <DayPickerSingleDateController
              transitionDuration={0}
              onFocusChange={(_) => {}}
              date={date}
              onDateChange={handleChange}
              daySize={45}
              noBorder
              focused
              initialVisibleMonth={() => date || moment()}
              navPrev={
                <IconButton className={classes.navButton} size="small">
                  <ChevronLeftIcon fontSize={mobile ? 'default' : 'large'} />
                </IconButton>
              }
              navNext={
                <IconButton className={classnames(classes.navButton, classes.rightNavButton)} size="small">
                  <ChevronRightIcon fontSize={mobile ? 'default' : 'large'} />
                </IconButton>
              }
              onPrevMonthClick={() => {
                recalculateTableHeight()
              }}
              onNextMonthClick={() => {
                recalculateTableHeight()
              }}
              renderMonthElement={(args) => <DatePickerMonthDropdown {...args} />}
            />
          </Paper>
        </ResponsivePopper>
      </FormControl>
    </ClickAwayListener>
  )
}

import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields
  if (!fields) {
    return true
  }

  const hasHtmlContent = fields?.html && fields.html.replace('<p></p>', '').trim()

  if (!hasHtmlContent && !fields.css) {
    return true
  }

  return false
}

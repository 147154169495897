import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { NoResults, Typography } from '@ui/paintscout'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '33vh'
  },
  title: {
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  description: {
    marginTop: 0
  }
}))

export interface ErrorMessageProps {
  title?: string
  description?: string
  variant?: 'psLogo' | 'warning'
}

function ErrorMessage({ title, description, variant }: ErrorMessageProps) {
  const classes = useStyles({})

  return (
    <div className={classes.root}>
      <NoResults
        scale={2}
        message={getMessage()}
        orientation={variant === 'warning' ? 'vertical' : 'horizontal'}
        variant={variant}
      />
    </div>
  )

  function getMessage() {
    return (
      <div>
        {title && (
          <Typography className={classes.title} variant="h1">
            {title}
          </Typography>
        )}
        {description && (
          <Typography className={classes.description} variant="h3">
            {description}
          </Typography>
        )}
      </div>
    )
  }
}

export default ErrorMessage

import type { QuoteDocument } from 'paintscout'

export function buildSubstrateOrder(quote: QuoteDocument) {
  const substrateOrder = [...quote.order.substrate]
  Object.keys(quote.substrates).forEach((substrateKey) => {
    for (const orderItem of substrateOrder) {
      if (orderItem.type === 'substrate' && orderItem.key === substrateKey) {
        return
      }
    }
    substrateOrder.push({
      type: 'substrate',
      key: substrateKey
    })
  })

  return substrateOrder
}

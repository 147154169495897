import get from 'lodash/get'
import type { OptionsDocument, QuoteDocument, QuoteTotals } from 'paintscout'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'
import { parseNumber } from '../util/parse-number'

export function totalAdjustment(quote: QuoteDocument, options: OptionsDocument): QuoteTotals {
  const newTotal = { ...quote.totals }
  const { serviceFees } = getQuoteOptions({ quote, options })

  const totalAdjustment = get(quote, 'totalAdjustment') || {}

  const adjustmentValue =
    typeof totalAdjustment.rate !== 'undefined' && totalAdjustment.rate !== null ? parseNumber(totalAdjustment.rate) : 0
  const adjustmentType = totalAdjustment.type ? totalAdjustment.type : 'percentage'

  const price = parseNumber(quote.totals.price)

  if (adjustmentType === 'percentage') {
    newTotal.total_adjustment = price * adjustmentValue * 0.01
  } else {
    newTotal.total_adjustment = adjustmentValue
  }

  const afterAdjustmentTotal = price + newTotal.total_adjustment

  // Internal service fees on quote
  const hiddenFeeRate = serviceFees?.hiddenValue ? Number(serviceFees?.hiddenValue / 100) : 0
  const hiddenFeeValue = Number((afterAdjustmentTotal * hiddenFeeRate).toFixed(2))

  newTotal.after_adjustment = afterAdjustmentTotal + hiddenFeeValue
  newTotal.service_fee_hidden = hiddenFeeValue

  return newTotal
}

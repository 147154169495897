import type { LoanApplicationStatus } from '@ui/react-quote/src/Wisetack'
import { formatCurrency } from '../../builder'
import type { OptionsDocument } from 'paintscout'
import startCase from 'lodash/startCase'

export default function wisetackStatusMapping({
  options,
  status,
  approvedLoanAmount
}: {
  options: OptionsDocument
  status: LoanApplicationStatus
  approvedLoanAmount?: number
}) {
  if (!status) {
    return ''
  }
  switch (status) {
    case 'INITIATED':
      return 'In Progress'
    case 'ACTIONS REQUIRED':
      return 'In Progress; Action Required'
    case 'AUTHORIZED':
      return 'In Progress; Approved'
    case 'LOAN TERMS ACCEPTED':
      return approvedLoanAmount
        ? `Offer Locked for ${formatCurrency({ value: approvedLoanAmount, options })}`
        : 'Offer Locked'
    case 'LOAN CONFIRMED':
    case 'SETTLED':
      return approvedLoanAmount ? `Paid for ${formatCurrency({ value: approvedLoanAmount, options })}` : 'Paid'
    case 'DECLINED':
      return 'Declined (can send app again)'
    default:
      return startCase(status.toLowerCase())
  }
}

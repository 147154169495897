import type { UpdateableItem } from '../../'
import type { RenderableItem } from '../../items'

/**
 * Gets the array of image keys from an item merging both files & images keys.
 */
export function getFileKeysForItem(args: { item: UpdateableItem | RenderableItem }): string[] {
  const { item } = args

  const keys = []

  // previous UpdateableItem interface had an 'images' property - it no longer exists, but we'll want to support old quotes
  if ((item as any).images) {
    keys.push(...(item as any).images)
  }

  if (item.files) {
    keys.push(...item.files)
  }

  return keys
}

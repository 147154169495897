import type { QuoteArea } from 'paintscout'
import { calculateSurfaceSqft } from './calculate-surface-sqft'
import { calculateLnft } from './calculate-lnft'
import { calculateCuft } from './calculate-cuft'
import { calculateWallsSqft } from './calculate-walls-sqft'
import { calculateCeilingSqft } from './calculate-ceiling-sqft'
import { calculateFloorSqft } from './calculate-floor-sqft'

export function areaDimensions(area: QuoteArea) {
  const sqft = calculateSurfaceSqft(area)
  const lnft = calculateLnft(area)
  const cuft = calculateCuft(area)
  const sqftWalls = calculateWallsSqft(area)
  const sqftCeiling = calculateCeilingSqft(area)
  const sqftFloor = calculateFloorSqft(area)

  // const sqftOverridden = sqft !== area.dimensions.sqft
  // const lnftOverridden = lnft !== area.dimensions.lnft
  // const sqftWallsOverridden = sqftWalls !== area.dimensions.sqft_walls
  // const sqftCeilingOverridden = sqftCeiling !== area.dimensions.sqft_ceiling
  // const sqftFloorOverridden = sqftFloor !== area.dimensions.sqft_floor
  //
  const overrideDimensions = !!(area.dimensions && area.dimensions.override_dimensions)

  const updatedDimensions = {
    ...area.dimensions,
    override_dimensions: overrideDimensions,
    lnft: area.dimensions && area.dimensions.lnft ? area.dimensions.lnft : lnft,
    calculated_lnft: lnft,
    cuft: area.dimensions && area.dimensions.cuft ? area.dimensions.cuft : cuft,
    calculated_cuft: cuft,
    sqft: area.dimensions && area.dimensions.sqft ? area.dimensions.sqft : sqft,
    calculated_sqft: sqft,
    sqft_walls: area.dimensions && area.dimensions.sqft_walls ? area.dimensions.sqft_walls : sqftWalls,
    calculated_sqft_walls: sqftWalls,
    sqft_ceiling: area.dimensions && area.dimensions.sqft_ceiling ? area.dimensions.sqft_ceiling : sqftCeiling,
    calculated_sqft_ceiling: sqftCeiling,
    sqft_floor: area.dimensions && area.dimensions.sqft_floor ? area.dimensions.sqft_floor : sqftFloor,
    calculated_sqft_floor: sqftFloor
  }

  return updatedDimensions
}

import React from 'react'
import type { PresentationSectionProps } from '../../'
import { FormSectionTitle } from '@ui/paintscout'
import type { QuoteItemSection } from '@paintscout/util/builder'
import FilesByItem from '../../../Quote/FilesByItem'

export default function Pictures(props: PresentationSectionProps) {
  const { WrapperComponent } = props
  const consumer = 'customer'
  const sections = ['bid', 'additional', 'added-options', 'pending', 'options'] as QuoteItemSection[]

  return (
    <WrapperComponent {...props}>
      <FilesByItem
        FormSectionTitle={<FormSectionTitle title={'Media'} style={{ marginBottom: 0 }} />}
        sections={sections}
        consumer={consumer}
        WrapperComponent={WrapperComponent}
      />
    </WrapperComponent>
  )
}

import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import ArchiveIcon from '@material-ui/icons/Archive'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import ListIcon from '@material-ui/icons/List'
import ConvertIcon from '@material-ui/icons/SwapHoriz'
import React, { useContext, useMemo } from 'react'
import { useClientOptions, useUser } from '@ui/paintscout'
import { FormSectionTitle } from '@ui/paintscout'
import type { RenderableItem } from '@paintscout/util/builder'
import {
  getItems,
  getObjectLabels,
  getQuoteOptions,
  getTotals,
  getFeatures,
  setQuoteOptions
} from '@paintscout/util/builder'
import get from 'lodash/get'
import AreaSubstrateView from '../../../../AreaSubstrateView'
import { QuoteContext } from '../../../../context/QuoteContext'
import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { AmountHeadColumn, ItemColumn, ItemTableCell } from '../../../../ItemTable'
import ActionColumn from '../../../../ItemTable/columns/ActionColumn'
import { hasCustomTotal } from '@paintscout/util/'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    clientNote: {
      fontStyle: 'italic'
    },
    rightContent: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: theme.spacing(2)
    },
    actionColumn: {
      '@media print': {
        display: 'none'
      }
    }
  })

export interface BidTableProps {
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  onDeselectItem?: ItemTableProps['onDeselectItem']
  onItemAction?: ItemTableProps['onItemAction']
  onClearItemSelection?: () => any
}

type BidTablePropsWithInjections = BidTableProps & WithStyles<typeof styles>

function BidTable({ classes, onItemAction, onDeselectItem, ...itemTableProps }: BidTablePropsWithInjections) {
  const { quote, tableView, isEditable, isNoteEditable, onReorder, onTableViewChange, onAddItem, updateQuote } =
    useContext(QuoteContext)
  const { options } = useClientOptions()
  const { isAdmin, isCrew } = useUser()
  const isInvoice = !!quote.is_invoice

  const features = getFeatures({ options })
  const hasAdditionalWork = get(features, 'quotes.additionalWork')
  const canConvertToLineItem = get(features, 'quotes.convertToLineItem')
  const canFullyEdit = !quote.is_invoice || isAdmin || !hasAdditionalWork
  const { showPrices, showDetailedBreakdown, allowShowCustomizedTotalPrice, addIndex, hideAllPrices, serviceFees } =
    getQuoteOptions({
      quote,
      options
    })

  const objectLabels = getObjectLabels({ options, invoice: isInvoice })
  const disableSubstrateView = hasCustomTotal(quote.areas)
  const totals = getTotals({ quote, consumer: 'customer', options })
  const items: RenderableItem[] = useMemo(
    () =>
      getItems({
        quote,
        section: 'bid',
        view: tableView,
        consumer: 'customer',
        options
      }).map((item) => {
        if (showDetailedBreakdown && !item.description && !item.value && item.subItems && item.subItems.length > 0) {
          const firstSubItem = item.subItems.shift()
          return {
            ...item,
            description: { useCustom: false, default: firstSubItem.description, custom: '' },
            value: firstSubItem.value
          }
        }

        return item
      }),
    [quote, tableView]
  )

  if (items.length === 0 && !isEditable) {
    return null
  }

  const allowAmountToggle =
    !quote.totalAdjustment ||
    allowShowCustomizedTotalPrice ||
    (quote.totalAdjustment && quote.totalAdjustment.rate === 0)
  const activeServiceFee = serviceFees?.hiddenValue && serviceFees.hiddenValue > 0

  function handleReorder({ items, addIndex }) {
    onReorder({ items, section: 'bid', view: tableView, addIndex })
  }

  function handleTableViewChange(event: any, view: string) {
    onTableViewChange(view)
    if (onDeselectItem) {
      onDeselectItem({ section: 'all' })
    }
  }

  function handleToggleAmount(_ev: any) {
    const quoteOptions = getQuoteOptions({ options, quote })
    quoteOptions.showPrices = !quoteOptions.showPrices

    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    updateQuote({ quote: updatedQuote })
  }

  return (
    <div className={classes.root}>
      <FormSectionTitle
        title={''}
        rightContent={
          isEditable ? (
            <div className={classes.rightContent}>
              <AreaSubstrateView view={tableView} onChange={handleTableViewChange} disabled={disableSubstrateView} />
            </div>
          ) : null
        }
      />
      <ItemTable
        draggable={!!isEditable}
        checkboxes={isEditable}
        showAddButton={isEditable}
        items={items}
        totals={totals}
        addIndex={addIndex.bid}
        addButtonLabel={'Add Area or Line Item'}
        onReorder={handleReorder}
        onItemClick={
          isEditable || isNoteEditable ? (ev, item) => onItemAction('edit', 'bid', item as RenderableItem) : null
        }
        onAddClick={(ev, insertAfterKey, type) => onAddItem('bid', insertAfterKey, type)}
        renderHeadColumns={() => (
          <>
            <ItemTableCell>Item</ItemTableCell>
            <ItemTableCell align="right">
              {items.length > 0 && (
                <AmountHeadColumn
                  isEditable={isEditable && !hideAllPrices}
                  consumer="customer"
                  disabled={!allowAmountToggle || activeServiceFee}
                  onChange={handleToggleAmount}
                  showAmount={showPrices && !hideAllPrices}
                  hideLabel={hideAllPrices}
                />
              )}
            </ItemTableCell>

            {/* action column */}
            {isEditable && <ItemTableCell classes={{ root: classes.actionColumn }} />}
          </>
        )}
        renderRowColumns={(item: RenderableItem) => {
          const actions = []

          actions.push({
            key: 'edit',
            label: 'Edit',
            icon: EditIcon
          })

          if (canFullyEdit) {
            actions.push({
              key: 'make-option',
              label: `Make ${objectLabels.option.value}`,
              icon: AddBoxIcon
            })

            actions.push({
              key: 'archive',
              label: 'Archive',
              icon: ArchiveIcon
            })

            if (tableView === 'area') {
              actions.push({
                key: 'copy',
                label: 'Copy',
                icon: CopyIcon
              })
            }

            if (item.type === 'group') {
              actions.push({
                key: 'ungroup',
                label: 'Ungroup',
                icon: ListIcon
              })
            }

            if (canConvertToLineItem && item.type !== 'line_item') {
              actions.push({
                key: 'convert',
                label: 'Convert to Line Item',
                icon: ConvertIcon
              })
            }
          }

          return (
            <>
              <ItemTableCell colSpan={2}>
                <ItemColumn
                  item={item}
                  consumer={'customer'}
                  isEditable={isEditable || isNoteEditable}
                  showAmount={showPrices}
                  section={'bid'}
                />
              </ItemTableCell>
              {isEditable && !isCrew && (
                <ItemTableCell cancelDrag isControl classes={{ root: classes.actionColumn }}>
                  <ActionColumn onActionClick={(ev, action) => onItemAction(action, 'bid', item)} actions={actions} />
                </ItemTableCell>
              )}
            </>
          )
        }}
        {...itemTableProps}
      />
    </div>
  )
}

export default withStyles(styles)(BidTable)

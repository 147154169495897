import React from 'react'
import { makeStyles } from '@material-ui/core'
import { IconButton, Grid } from '@ui/paintscout'
import ClearIcon from '@material-ui/icons/Clear'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles({
  root: {},
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    minWidth: 43 // as close to 48 (24 for button and 12 padding) while still displaying inline when needed
  },
  moveRemoveButton: {
    padding: 0,
    height: 24,
    width: 24
  },
  removeButton: {
    margin: '8px 0'
  }
})

interface MoveRemoveButtonProps {
  arrayHelpers: any
  index: number
  section: any
}

export function MoveRemoveButtons({ arrayHelpers, index, section, ...props }: MoveRemoveButtonProps) {
  const classes = useStyles(props)

  return (
    <Grid item xs={1} className={classes.buttonWrapper}>
      <IconButton
        onClick={() => {
          arrayHelpers.move(index, index - 1)
        }}
        classes={{ root: classes.moveRemoveButton }}
        disabled={index === 0}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          arrayHelpers.remove(index)
        }}
        classes={{ root: `${classes.moveRemoveButton} ${classes.removeButton}` }}
      >
        <ClearIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          arrayHelpers.move(index, index + 1)
        }}
        classes={{ root: classes.moveRemoveButton }}
        disabled={index === section?.fields?.rows.length - 1}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
    </Grid>
  )
}

/**
 * Performs a loose equality check on two objects, treating falsey values as equal
 * @example isLooselyEqual({ a: '' }, { a: null }) // true
 */
export function isLooselyEqual(first: any, second: any) {
  return compare(first, second) && compare(second, first)
}

export function compare(first: any, second: any, diff: any = {}) {
  for (const key in first) {
    if (first[key] !== second[key]) {
      if (typeof first[key] === 'object' && typeof second[key] === 'object') {
        compare(first[key] as any, second[key] as any, diff)
      } else if (!(isFalsey(first[key]) && isFalsey(second[key]))) {
        diff[key] = second[key]
      }
    }
  }
  return Object.keys(diff).length === 0
}

function isFalsey(value: any) {
  return [undefined, null, false, '', '<p></p>', 0, -1, '0', '-1'].includes(value)
}

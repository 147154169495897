import type { OptionsDocument, QuoteOptions } from 'paintscout'
import type { UpdateableProduct } from '../../../area-substrates'
import { getProductLabel, roundPrice as round } from '../../../util'
import { getObjectLabels } from '../../../../util'

// Product related fields returned by function alongside product descripton string
export interface ProductFields {
  key: string
  label: string
  volume: number
  unitPrice: number
  unitLabel: string
}

/**
 * @description Generate Product related fields returned by function alongside product descripton string
 * to be added to description for areaSubstrate
 */
export default function getProductFields({
  product,
  quoteOptions,
  clientOptions
}: {
  product: UpdateableProduct
  quoteOptions: QuoteOptions
  clientOptions: OptionsDocument
}): {
  productFields: ProductFields
  descriptionString: string
} {
  const { currency } = getObjectLabels({ options: clientOptions })
  const showVolume = !(quoteOptions?.hideWorkOrderVolumes ?? false)
  const showMaterialCost = !(quoteOptions?.hideWorkOrderMaterialCost ?? false)
  const volume = product.volume && (product.volume.useCustom ? product.volume.custom : product.volume.default)
  const productPrice =
    ((product.totalPrice && (product.totalPrice.useCustom ? product.totalPrice.custom : product.totalPrice.default)) *
      100) /
    100
  const unitPrice = product?.price?.useCustom ? product?.price?.custom : product?.price.default

  const unitLabel = product?.unitLabel ?? 'gal'
  const volumeText = volume && showVolume ? `${round(volume)} ${unitLabel}` : ''
  const totalPriceText = showMaterialCost && productPrice ? `${currency.symbol}${round(productPrice)}` : null
  const productLabel = getProductLabel(product)

  const descriptionString = [productLabel, volumeText, totalPriceText].filter((i) => i).join(' - ')

  const productFields = {
    key: product?.key ?? '',
    label: productLabel,
    volume,
    unitPrice,
    unitLabel,
    totalPrice: productPrice
  }

  return { productFields, descriptionString }
}

import type { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog'
import MuiDialog from '@material-ui/core/Dialog'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, withStyles, useTheme } from '@material-ui/core/styles'
import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import type { StyleClasses } from '../theme'

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography

  // stub names are to keep typescript happy about existing MuiDialog class names
  return createStyles({
    root: {},
    scrollPaper: {
      // move up dialog location when on > md screens
      [theme.breakpoints.up('md')]: {
        alignItems: 'start'
      }
    },
    scrollBody: {},
    container: {},
    paper: {
      // fix safari not showing cursor on inner InputFields on nextjs
      zIndex: theme.zIndex.modal,

      maxHeight: '100vh',
      '@media (min-width: 728px)': {
        minWidth: pxToRem(728),
        maxHeight: 'calc(100vh - 96px)'
      },
      '@media (max-width: 727px)': {
        width: '100%'
      }
    },
    paperScrollPaper: {},
    paperScrollBody: {},
    paperWidthXs: {},
    paperWidthSm: {},
    paperWidthMd: {},
    paperWidthLg: {},
    paperFullWidth: {},
    paperFullScreen: {
      borderRadius: '0 !important',
      maxHeight: '100vh !important'
    }
  })
}

export interface DialogProps extends Omit<MuiDialogProps, 'title' | 'classes' | 'ref'> {
  classes?: StyleClasses<typeof styles>
  children?: React.ReactNode
  forceFullScreen?: boolean
  loading?: boolean
}

/**
 * Extends material-ui's dialog to be fullscreen on 'xs' breakpoint and minimum 500px width otherwise
 */
const Dialog = (props: DialogProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { forceFullScreen, children, ...otherProps } = props

  return (
    <MuiDialog
      fullScreen={forceFullScreen || fullScreen}
      scroll="paper"
      {...(otherProps as MuiDialogProps)}
      onClose={(ev, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return false
        }

        props.onClose?.(ev, reason)
      }}
    >
      {children}
    </MuiDialog>
  )
}

export default withStyles(styles)(Dialog)

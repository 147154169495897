import type { QuoteDocument, OptionsDocument } from 'paintscout'

/*
 * Check if quote expiry is enabled for quote or invoice.
 * Checks quote and options settings
 */
export function expiryEnabled(args: { quote: QuoteDocument; options: OptionsDocument }): boolean {
  const { quote, options } = args
  const expiryField = quote.is_invoice ? 'customerInvoiceExpiry' : 'customerViewExpiry'
  const settingsEnabledQuoteExpiry = options.options?.quotes?.[expiryField]?.enabled || false
  const quoteEnabledExpiry = (quote?.options?.[expiryField]?.enabled && settingsEnabledQuoteExpiry) || false

  return quoteEnabledExpiry
}

import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core'

import IconButton from '../IconButton'
import InputLabel from '../InputLabel'

import FormatAlignLeft from '@material-ui/icons/FormatAlignLeft'
import FormatAlignCenter from '@material-ui/icons/FormatAlignCenter'
import FormatAlignRight from '@material-ui/icons/FormatAlignRight'
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify'
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom'
import VerticalAlignCenter from '@material-ui/icons/VerticalAlignCenter'
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop'

import classnames from 'classnames'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderRadius: 2
    },
    label: {
      marginBottom: theme.typography.pxToRem(5)
    },
    button: {
      borderRadius: 0,
      padding: `${theme.typography.pxToRem(10)} ${theme.spacing(2)}px`,
      '&:hover': {
        color: theme.palette.grey[700]
      }
    },
    selected: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    fill: {
      fill: theme.palette.primary.main
    }
  })
}

export interface LayoutSelectProps extends WithStyles<typeof styles> {
  value?: string
  label?: string
  vertical?: boolean
  disabled?: boolean
  onChange?: (value: string) => void
}

function RateTypeSelect({ classes, value, onChange, disabled, label, ...otherProps }: LayoutSelectProps) {
  const vertical = otherProps?.vertical ?? false

  const horizontalOptions = [
    { label: 'Left', icon: <FormatAlignLeft />, value: 'left' },
    { label: 'Center', icon: <FormatAlignCenter />, value: 'center' },
    { label: 'Right', icon: <FormatAlignRight />, value: 'right' },
    { label: 'Justify', icon: <FormatAlignJustify />, value: 'justify' }
  ]
  const verticalOptions = [
    { label: 'Top', icon: <VerticalAlignTop />, value: 'top' },
    { label: 'Middle', icon: <VerticalAlignCenter />, value: 'middle' },
    { label: 'Bottom', icon: <VerticalAlignBottom />, value: 'bottom' }
  ]

  const options = vertical ? verticalOptions : horizontalOptions

  return (
    <div className={classes.root}>
      {label && (
        <InputLabel className={classes.label} shrink={true}>
          {label}
        </InputLabel>
      )}
      <div className={classes.buttonWrapper}>
        {options.map((item, index) => {
          const selected = value === item.value
          return (
            <IconButton
              key={index}
              disabled={disabled}
              onClick={handleButtonClick(item.value)}
              classes={{ root: classnames({ [classes.button]: true, [classes.selected]: selected }) }}
            >
              {item.icon}
            </IconButton>
          )
        })}
      </div>
    </div>
  )

  function handleButtonClick(value: string) {
    return () => {
      onChange(value)
    }
  }
}

export default withStyles(styles)(RateTypeSelect)

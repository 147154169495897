import type { QuoteArea } from 'paintscout'
import type { QuoteItemSection } from '../..'

export function setAreaSection(item: QuoteArea, section: QuoteItemSection): QuoteArea {
  if (section === 'additional') {
    return {
      ...item,
      additionalWork: true,
      use_total: false
    }
  } else if (section === 'pending') {
    return {
      ...item,
      pending: true,
      use_total: false
    }
  } else if (section === 'bid') {
    return {
      ...item,
      show: true,
      use_total: true
    }
  } else if (section === 'options') {
    return {
      ...item,
      show: true,
      use_total: false
    }
  } else if (section === 'added-options') {
    return {
      ...item,
      show: true,
      use_total: false,
      addedOption: true
    }
  } else {
    // archived
    return {
      ...item,
      show: false,
      use_total: false
    }
  }
}

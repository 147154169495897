import type { AreaSubstrate } from 'paintscout'
import type { UpdateableArea } from '../../..'

interface convertedDimensions {
  length: {
    useCustom: boolean
    custom: number
    default: number
  }
  width: {
    useCustom: boolean
    custom: number
    default: number
  }
  height: {
    useCustom: boolean
    custom: number
    default: number
  }
}

export default function convertDimensions({
  areaSubstrate,
  area
}: {
  areaSubstrate: AreaSubstrate
  area: UpdateableArea
}): convertedDimensions {
  const areaLength = area.dimensions.length * (areaSubstrate.useInches ? 12 : 1)
  const areaWidth = area.dimensions.width * (areaSubstrate.useInches ? 12 : 1)
  const areaHeight = area.dimensions.height * (areaSubstrate.useInches ? 12 : 1)

  let lengthUseCustom
  let heightUseCustom
  let widthUseCustom
  if (area.areaType === 'room') {
    if (
      typeof areaSubstrate.override_length === 'undefined' ||
      typeof areaSubstrate.override_width === 'undefined' ||
      typeof areaSubstrate.override_height === 'undefined'
    ) {
      // this means it's an "old" room - before we allowed overriding
      lengthUseCustom = false
      heightUseCustom = false
      widthUseCustom = false
    } else {
      lengthUseCustom = areaSubstrate.override_length === true
      heightUseCustom = areaSubstrate.override_height === true
      widthUseCustom = areaSubstrate.override_width === true
    }
  } else {
    lengthUseCustom =
      typeof areaSubstrate.override_length !== 'undefined'
        ? areaSubstrate.override_length
        : typeof areaSubstrate.length === 'number' && areaSubstrate.length !== areaLength

    heightUseCustom =
      typeof areaSubstrate.override_height !== 'undefined'
        ? areaSubstrate.override_height
        : typeof areaSubstrate.height === 'number' && areaSubstrate.height !== areaHeight

    widthUseCustom =
      typeof areaSubstrate.override_width !== 'undefined'
        ? areaSubstrate.override_width
        : typeof areaSubstrate.width === 'number' && areaSubstrate.width !== areaWidth
  }

  const length = {
    useCustom: lengthUseCustom,
    custom: typeof areaSubstrate.length === 'number' ? areaSubstrate.length : areaLength,
    default: areaLength
  }
  const width = {
    useCustom: widthUseCustom,
    custom: typeof areaSubstrate.width === 'number' ? areaSubstrate.width : areaWidth,
    default: areaWidth
  }
  const height = {
    useCustom: heightUseCustom,
    custom: typeof areaSubstrate.height === 'number' ? areaSubstrate.height : areaHeight,
    default: areaHeight
  }

  return { length, width, height }
}

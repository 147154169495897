import React, { useContext } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import type { QuoteStatus } from 'paintscout'
import { Grid, Typography } from '@ui/paintscout'
import classnames from 'classnames'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import { QuoteContext } from '../../../context'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles<Theme, StatusStampProps>((theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
      '& $editIcon': {
        color: theme.palette.primary.main
      }
    }
  },
  color: {
    color: (props) => {
      return theme.palette.quote.status[props.status]
    }
  },
  checkIcon: {
    marginLeft: theme.spacing(1)
  },
  editIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[400],
    fontSize: '0.7rem',
    '@media print': {
      display: 'none'
    }
  }
}))
export interface StatusStampProps {
  status: QuoteStatus
}

function StatusStamp(props: StatusStampProps) {
  const { status } = props
  const classes = useStyles(props)
  const { isEditable, onEditInfo } = useContext(QuoteContext)

  const showCheck = status === 'accepted' || status === 'paid'
  return (
    <Grid className={classes.root} container alignItems="center" onClick={onEditInfo}>
      {isEditable && <EditIcon className={classes.editIcon} />}
      <Typography variant="overline" data-testid="quote-status" className={classes.color}>
        {status}
      </Typography>
      {showCheck && <CheckIcon className={classnames(classes.color, classes.checkIcon)} />}
    </Grid>
  )
}

export default StatusStamp

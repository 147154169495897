import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { Moment } from 'moment'

export interface StylesArgs {
  startDate?: Moment
  endDate?: Moment
  isSingle?: boolean
  tableHeight?: number
}

export const useDatePickerStyles = makeStyles<Theme, StylesArgs>((theme: Theme) => ({
  popper: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 1400,
    boxShadow: theme.shadows[4],
    marginTop: theme.spacing(0.25),
    maxWidth: 'min-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%'
    },
    '& .CalendarMonth_table': {
      borderCollapse: 'separate',
      borderSpacing: theme.spacing(0, 2)
    },
    '& .CalendarMonth_caption': {
      padding: theme.spacing(1.5, 5, 1, 5),
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '314px',
      '& > *': {
        margin: theme.spacing(0.5),
        marginBottom: ({ isSingle }) => (isSingle ? theme.spacing(2) : undefined)
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5, 3, 1, 3)
      }
    },
    '& .DayPicker': {},
    '& .DayPicker_weekHeader': {
      color: theme.palette.text.primary,
      '& ul': {
        ...theme.typography.h4,
        fontWeight: 500
      },
      paddingTop: ({ isSingle }) => (isSingle ? '8px !important' : undefined)
    },
    '& .DayPicker_transitionContainer': {
      height: ({ tableHeight }) => (tableHeight ? `${tableHeight}px !important` : '390px !important'),
      position: 'revert',
      overflow: 'hidden'
    },
    '& .CalendarMonthGrid_month__hidden': {
      height: '0px !important'
    },
    '& .CalendarMonthGrid__horizontal': {
      width: 'initial !important'
    },
    '& .CalendarDay': {
      color: theme.palette.text.secondary,
      borderRadius: '50%',
      border: 'none',
      fontWeight: 500
    },
    '& .CalendarDay__today': {
      color: theme.palette.primary.main
    },
    '& .CalendarDay__selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    '& .CalendarDay__selected_start, & .CalendarDay__selected_end': {
      borderRadius: '50%'
    },
    '& .CalendarDay__selected_start:not(:last-child)': {
      position: 'relative',
      borderRadius: ({ endDate }) => (!endDate ? '50%' : '50% 0 0 50%'),
      backgroundColor: ({ startDate, endDate }) =>
        startDate?.day() === endDate?.day() ? theme.palette.background.paper : theme.palette.grey[200],
      '&::before': {
        content: ({ startDate }) => `'${startDate ? startDate.format('D') : ''}'`,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    '& .CalendarDay__selected_end:not(:first-child)': {
      position: 'relative',
      borderRadius: ({ startDate }) => (!startDate ? '50%' : '0 50% 50% 0'),
      backgroundColor: ({ startDate, endDate }) =>
        startDate?.day() === endDate?.day() ? theme.palette.background.paper : theme.palette.grey[200],
      '&::before': {
        content: ({ endDate }) => `'${endDate ? endDate.format('D') : ''}'`,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    '& .CalendarDay__selected_span': {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 0,
      '&:first-child': {
        borderRadius: '50% 0 0 50%'
      },
      '&:last-child': {
        borderRadius: '0 50% 50% 0'
      }
    },
    '& .CalendarDay__hovered_span': {
      background: 'none',
      borderRadius: 0,
      '&:first-child': {
        borderRadius: '50% 0 0 50%'
      },
      '&:last-child': {
        borderRadius: '0 50% 50% 0'
      },
      '&:hover': {
        borderRadius: '50%',
        background: theme.palette.grey[200]
      }
    },
    '& .CalendarDay__highlighted_calendar:not(.CalendarDay__selected):not(.CalendarDay__selected_span)': {
      color: theme.palette.primary.main,
      background: 'none',
      '&:hover': {
        background: theme.palette.grey[200]
      }
    },
    '& .CalendarDay__highlighted_calendar.CalendarDay__selected_span': {
      color: theme.palette.primary.main
    },
    '& .DayPickerKeyboardShortcuts_buttonReset': {
      display: 'none'
    }
  },
  dialogMargin: {
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  paper: {
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: ({ isSingle }) => (isSingle ? theme.spacing(2) : 0),
    paddingLeft: ({ isSingle }) => (isSingle ? theme.spacing(2) : 0),
    paddingRight: ({ isSingle }) => (isSingle ? theme.spacing(2) : 0),
    border: 'none',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  navButton: {
    position: 'absolute',
    top: '19px',
    left: ({ isSingle }) => (isSingle ? '24px' : '50px'),
    right: 'auto',
    [theme.breakpoints.down('md')]: {
      top: ({ isSingle }) => (isSingle ? undefined : '24px'),
      left: ({ isSingle }) => (isSingle ? '14px' : '43px')
    },
    [theme.breakpoints.down('sm')]: {
      top: ({ isSingle }) => (isSingle ? '24px' : undefined),
      left: ({ isSingle }) => (isSingle ? '26px' : '55px')
    }
  },
  rightNavButton: {
    right: ({ isSingle }) => (isSingle ? '25px' : '52px') + '!important',
    left: 'auto' + '!important',
    [theme.breakpoints.down('md')]: {
      right: ({ isSingle }) => (isSingle ? '14px !important' : '43px')
    },
    [theme.breakpoints.down('sm')]: {
      right: ({ isSingle }) => (isSingle ? '29px !important' : '56px !important')
    }
  },
  topContent: {
    padding: theme.spacing(3, 3, 2, 3)
  },
  monthTitles: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    top: '26px',
    left: 'calc(25% - 20px)',
    right: 'calc(25% - 5px)',
    [theme.breakpoints.down('md')]: {
      left: 'auto',
      right: 'auto',
      width: '445px'
    },
    zIndex: 2,
    '& > p': {
      ...theme.typography.h6,
      textTransform: 'uppercase',
      fontSize: '0.9rem',
      fontWeight: 500
    }
  },
  dialogContent: {
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    '@media screen and (max-height: 930px)': {
      overflowY: 'scroll'
    }
  },
  mobileInputs: {
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  input: {
    ...theme.typography.body1,
    cursor: 'pointer !important',
    '& input': {
      cursor: 'pointer !important'
    },
    '& svg': {
      fontSize: '1rem'
    }
  },
  headerCardInput: {
    border: 'none !important',
    '& input': {
      marginBottom: theme.spacing(2),
      padding: 0
    }
  },
  selectedInput: {
    borderColor: theme.palette.primary.main,
    '& input': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main} !important`,
      '-webkit-box-shadow': `inset 0 0 0 2px ${theme.palette.primary.main}!important`,
      '-webkit-border-radius': '0px',
      '-webkit-appearance': 'none'
    },
    '&:hover': {
      backgroundColor: 'inherit',
      boxShadow: 'none'
    }
  },
  mobileFooter: {
    width: '100%'
  }
}))

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import ActivityTitle from '../ActivityTitle'
import ActivityFooter from '../ActivityFooter'
import ActivityContent from '../ActivityContent'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import Typography from '../../Typography'

import type { WarningActivityDetails } from '@paintscout/api'

const useStyles = makeStyles<Theme, ActivityTypeComponentProps<WarningActivityDetails>>((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  message: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap'
  }
}))

export default function QuoteWarningActivty(props: ActivityTypeComponentProps<WarningActivityDetails>) {
  const { activity, showDocTypeDetails } = props
  const classes = useStyles(props)

  const warningMessage = activity.details.warningMessage
  const warningTitle = activity.details?.warningTitle ?? 'Warning'

  return (
    <>
      <ActivityTitle>
        <Highlight>Warning:</Highlight> {warningTitle}
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {warningMessage && (
        <ActivityContent className={classes.content}>
          <Typography variant="subtitle2" className={classes.message}>
            {warningMessage}
          </Typography>
        </ActivityContent>
      )}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

import type { QuoteDocument } from 'paintscout'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'

export function roundHours(value: number, quote: QuoteDocument, rateType?: string) {
  switch (getRoundType(quote, rateType)) {
    case 'none':
      return value
    case 'newNone':
      return Math.round(value * 100) / 100
    case 'hour':
      return Math.ceil(value)
    case 'quarter':
      return Math.ceil(value * 4) / 4
    default:
      // half
      return Math.ceil(value * 2) / 2
  }
}

function getRoundType(quote: QuoteDocument, rateType?: string): string {
  const { roundHours, roundQuantityHours, legacyRoundQuantityHours } = getQuoteOptions({ quote }) ?? {}
  const isV4RoundAdjusted = quote.version >= 4 && roundHours === 'none'

  // I discovered some quotes that were previously calculated with roundQuantityHours=false, and were version=2, and roundHours=none. But, they were *now* calculating with the quantity hours rounding. I've added this typeof check so that I could add roundHours & roundQuantityHours to these quotes and have it calculate them as they should have

  if (!quote.version || quote.version === 1) {
    return rateType !== 'quantity' || roundQuantityHours ? 'half' : 'none'
  }

  if (rateType === 'quantity' && legacyRoundQuantityHours === true) {
    return roundHours
  } else if (rateType === 'quantity' && legacyRoundQuantityHours === false) {
    return quote.version >= 4 ? 'newNone' : 'none'
  }

  if (isV4RoundAdjusted) {
    return 'newNone'
  }

  return roundHours
}

import type { QuoteDocument, QuoteImage, OrderItem, QuoteFile } from 'paintscout'
import getImages from './get-images'

export interface GetFilesArgs {
  quote: QuoteDocument
  keys?: string[]

  /**
   * only return files that are attached to an area or line item (that are not deleted)
   */
  attached?: boolean
}

export function getFiles(args: { quote?: QuoteDocument; keys?: string[]; attached?: boolean }): QuoteFile[] {
  const { quote, keys, attached } = args

  if (!quote?.files && !quote?.images) {
    return []
  }

  // support old quotes that have image files stored under 'images'
  const imageFiles = getImages(args)

  if (attached) {
    return [
      ...imageFiles,
      ...quote.order.area.reduce((acc: QuoteImage[], orderItem: OrderItem): QuoteImage[] => {
        let item
        if (orderItem._deleted) {
          return acc
        } else if (orderItem.type === 'line_item') {
          item = quote.lineItems[orderItem.key]
        } else if (orderItem.type === 'area') {
          item = quote.areas[orderItem.key]
        } else if (orderItem.type === 'group') {
          item = quote.groups[orderItem.key]
        } else {
          return acc
        }

        if (!item || !item.files) {
          return acc
        }

        const files = item.files
          .filter((fileKey) => quote.files && quote.files[fileKey])
          .map((fileKey: string) => {
            return quote.files[fileKey]
          })
        return [...acc, ...files]
      }, [])
    ]
  }

  if (!keys) {
    return []
  }

  return [
    ...imageFiles,
    ...keys
      .filter((key) => quote.files && quote.files[key])
      .reduce((files, key) => {
        if (quote.files[key]) {
          return [...files, quote.files[key]]
        }

        return files
      }, [])
  ]
}

import type { QuoteDocument, OptionsDocument, AreaSubstrate } from 'paintscout'
import { getItemSection } from '../util'
import { getAreaSubstrate } from './get-area-substrate'
import type { QuoteItemSection } from '..'

// Get all area substrate sections for given area
export function getAreaSubstrateSections({
  quote,
  areaKey,
  options
}: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
}): QuoteItemSection[] {
  const area = quote.areas[areaKey]
  if (!area) {
    return []
  }

  const areaSubstrates = area.substrateOrder
    .map((orderItem) => {
      const areaSubstrate = getAreaSubstrate({
        quote,
        options,
        areaKey,
        key: orderItem.key,
        full: true
      }) as AreaSubstrate

      if (!areaSubstrate) {
        return null
      }

      const itemSection = getItemSection(areaSubstrate)

      return itemSection
    })
    .filter(Boolean)

  return areaSubstrates
}

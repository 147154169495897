import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import classNames from 'classnames'
import type { QuoteFile } from 'paintscout'

const useStyles = makeStyles<Theme, ResponsiveVideoProps>(
  (_theme) => ({
    root: {},
    video: {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '100%'
    }
  }),
  { name: 'ResponsiveVideo' }
)

export interface ResponsiveVideoProps {
  classes?: StyleClasses<typeof useStyles>
  file: QuoteFile
  onClick: (ev: any) => any
}

function ResponsiveVideo(props: ResponsiveVideoProps) {
  const classes = useStyles(props)
  const { onClick, file } = props
  const thumbnailSrc = generateThumbnailSrc({ file })

  return (
    <>
      <video
        controls
        className={classNames(classes.fileRoot, classes.video, classes.image)}
        src={file.src + '/' + file.s3PublicKey}
        poster={thumbnailSrc}
        onClick={onClick}
      />
    </>
  )
}

function generateThumbnailSrc({ file }: { file: QuoteFile }): string {
  const base = file.src
  const cleanKey = file.s3PublicKey.replace('uploads/', '')
  const idIndex = cleanKey.lastIndexOf('/')
  const dist = cleanKey.slice(0, idIndex)
  const id = cleanKey.slice(idIndex)

  // Singular size url
  return base + `/${dist}` + `/thumbnail` + id.split('.')[0] + '.jpeg'
}

export default ResponsiveVideo

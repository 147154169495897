import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Grid, Tooltip, Typography, useClientOptions } from '@ui/paintscout'
import React from 'react'
import startCase from 'lodash/startCase'
import type { LoanApplicationStatus, PrequalStatus, SignupStatus } from '../types'
import { formatCurrency, getObjectLabels } from '@paintscout/util/builder'
import wisetackStatusMapping from '@paintscout/util/util/wisetackStatusMapping/wisetackStatusMapping'

const useStyles = makeStyles<Theme, WisetackStatusProps>((theme: Theme) => ({
  logo: {
    width: theme.typography.pxToRem(26),
    padding: (props) => `${props.stacked ? '2' : '4'}px 4px 0px 4px`,
    marginRight: 6
  },
  label: {
    color: theme.palette.text.primary
  },
  status: {
    color: (props) => {
      if (props.stacked) {
        return theme.palette.quote.status.draft + '!important'
      }
      switch (props.status) {
        case 'SETTLED':
        case 'PREQUALIFIED':
        case 'LOAN CONFIRMED':
        case 'APPLICATION_APPROVED':
        case 'LOAN TERMS ACCEPTED':
          return theme.palette.quote.status.accepted + '!important'
        case 'DECLINED':
        case 'ACTIONS REQUIRED':
        case 'APPLICATION_DECLINED':
          return theme.palette.quote.status.declined + '!important'
        case 'EXPIRED':
        case 'CANCELED':
          return theme.palette.quote.status.onHold + '!important'
        case 'REFUNDED':
          return theme.palette.quote.status.partial + '!important'
        default:
          return theme.palette.quote.status.viewed + '!important'
      }
    }
  }
}))

export interface WisetackStatusProps {
  status: PrequalStatus | LoanApplicationStatus | SignupStatus
  variant: 'prequal' | 'loanApplication' | 'signup'
  amount?: number
  hideIcon?: boolean
  centerAlign?: boolean
  stacked?: boolean
  showTooltip?: boolean
}

const WisetackStatus = (props: WisetackStatusProps) => {
  const { hideIcon, stacked, status, amount, variant, centerAlign, showTooltip } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()

  if (!status) {
    return null
  }

  const getLabel = () => {
    switch (variant) {
      case 'prequal':
        return 'Prequalification'
      case 'loanApplication':
        return 'Financing Application'
      case 'signup':
      default:
        return 'Signup Status'
    }
  }

  const getStatusLabel = () => {
    if (variant === 'loanApplication') {
      return wisetackStatusMapping({ options, status: status as LoanApplicationStatus, approvedLoanAmount: amount })
    }
    switch (status as PrequalStatus | SignupStatus) {
      case 'INITIATED':
      case 'PENDING':
      case 'INITIATOR_ADDED':
      case 'BUSINESS_ADDED':
      case 'OWNERS_ADDED':
      case 'EXECUTIVE_ADDED':
        return 'In Progress'
      case 'PREQUALIFIED':
        return amount ? `Prequalified for ${formatCurrency({ options, value: amount })}` : 'Prequalified'
      case 'DECLINED':
        return 'Declined, (can send app again)'
      case 'APPLICATION_SUBMITTED':
        return 'Submitted and Under Review'
      default:
        return startCase(status.replace('_', ' ').toLowerCase())
    }
  }

  const Content = () => {
    return (
      <>
        <span className={classes.label}>{getLabel()}: </span>
        {getStatusLabel()}
      </>
    )
  }

  const StackedContent = () => {
    return (
      <>
        {showTooltip ? (
          <Tooltip content={TooltipContent(variant === 'loanApplication')} classes={{ badge: classes.tooltipBadge }}>
            <span className={classes.label}>{getLabel()}: </span>
          </Tooltip>
        ) : (
          <span className={classes.label}>{getLabel()}: </span>
        )}
        <br />
        {getStatusLabel()}
      </>
    )
  }

  return (
    <Grid className={classes.root} container alignItems={stacked ? 'flex-start' : 'center'}>
      {!hideIcon && (
        <Grid item>
          <img
            src={
              'https://res.cloudinary.com/taptapquote/image/upload/v1663604185/paintscout/providers/wisetack-icon.png'
            }
            alt={'wisetack-icon'}
            className={classes.logo}
          />
        </Grid>
      )}
      <Grid item>
        <Typography align={centerAlign ? 'center' : 'left'} variant={'body1'} className={classes.status}>
          {showTooltip && !stacked ? (
            <Tooltip content={TooltipContent(variant === 'loanApplication')} classes={{ badge: classes.tooltipBadge }}>
              <Content />
            </Tooltip>
          ) : stacked ? (
            <StackedContent />
          ) : (
            <Content />
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}

const TooltipContent = (isLoanApplication?: boolean) => {
  const { options } = useClientOptions()
  const { quote: quoteLabel } = getObjectLabels({ options, invoice: false })
  const { quote: invoiceLabel } = getObjectLabels({ options, invoice: true })
  return (
    <Typography variant="body1">
      {isLoanApplication ? (
        <>
          <strong>Financing</strong>
          <br />
          Allow customers to apply for funding to pay for services in installments. Go to the customer's{' '}
          {quoteLabel.value.toLowerCase()} or {invoiceLabel.value.toLowerCase()} and send a link to apply for financing.
        </>
      ) : (
        <>
          <strong>Prequalification</strong>
          <br />
          Offer a prequalification process for customers to determine financing eligibility without impacting credit
          scores. Your unique prequalification link can be found in the integration settings.
        </>
      )}
    </Typography>
  )
}

export default WisetackStatus

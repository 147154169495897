import type { ClientOptionsProviderProps } from '@ui/core/ClientOptionsProvider'
import ClientOptionsProvider from '@ui/core/ClientOptionsProvider'

import type { WithClientOptions } from '@ui/core/ClientOptionsProvider'
import { withClientOptions } from '@ui/core/ClientOptionsProvider'
import { useClientOptions } from '@ui/core/ClientOptionsProvider'
import type { ClientOptionsValue } from '@ui/core/ClientOptionsProvider'
import { ClientOptionsContext } from '@ui/core/ClientOptionsProvider'

export { ClientOptionsContext, withClientOptions, useClientOptions }
export type { ClientOptionsProviderProps, ClientOptionsValue, WithClientOptions }
export default ClientOptionsProvider

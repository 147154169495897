import type { QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import moment from 'moment'

export function tax(quote: QuoteDocument) {
  const { materialRate, laborRate } = (quote.tax_details ?? []).reduce(
    (acc, item) => {
      const m = parseNumber(item.materialRate)
      const l = parseNumber(item.laborRate)
      return {
        materialRate: acc.materialRate + m,
        laborRate: acc.laborRate + l
      }
    },
    { materialRate: 0, laborRate: 0 }
  )

  if (materialRate || laborRate) {
    const price = parseNumber(quote.totals.after_discount)
    const materials = parseNumber(quote.totals.materials)
    const labor = price - materials

    const materialsTax = materials * materialRate * 0.01
    const laborTax = labor * laborRate * 0.01

    const totalTax = materialsTax + laborTax

    return parseNumber(totalTax.toFixed(2))
  }

  const taxRate = parseNumber(quote.tax_rate)
  const price = parseNumber(quote.totals.after_discount)

  // Most states require taxes to be rounded up, but we don't want this to effect old quotes
  // see https://github.com/PaintScout/PaintScout/issues/9356
  if (moment(quote.dates?.created).isBefore('2023-08-02')) {
    // Just truncate to 2 decimal places
    return parseNumber((price * taxRate * 0.01).toFixed(2))
  } else {
    // Round up if the third decimal place is greater than or equal to 5
    const totalTax = Math.round(price * taxRate * 0.01 * 100) / 100
    return parseNumber(totalTax.toFixed(2))
  }
}

import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { Button, useClientOptions } from '@ui/paintscout'
import QuoteHeaderCard from '../QuoteHeaderCard'
import { QuoteContext } from '../../../context/QuoteContext'
import { getJobAddress, getJobIdentifier, getQuoteOptions } from '@paintscout/util/builder'
const addressFormat = require('address-format')

const styles = (_theme: Theme) =>
  createStyles({
    root: {},
    contents: {},
    hoverMenu: {
      display: 'flex'
    }
  })

export interface JobAddressCardProps extends WithStyles<typeof styles> {}

function JobAddressCard(props: JobAddressCardProps) {
  const { classes } = props
  const { options } = useClientOptions()
  const { quote, isEditable, onEditJobAddress } = React.useContext(QuoteContext)

  const quoteOptions = getQuoteOptions({ options, quote })
  const { alwaysShowJobAddress } = quoteOptions

  const jobAddress = getJobAddress({ quote })
  const jobIdentifier = getJobIdentifier({ quote })

  let text = ''

  if (jobIdentifier) {
    text += jobIdentifier + '\n'
  }

  if (
    (!jobAddress || (!jobAddress.address && !jobAddress.city && !jobAddress.postal && !jobAddress.province)) &&
    quote.contact &&
    alwaysShowJobAddress
  ) {
    const fullAddress = addressFormat({
      address: quote.contact?.address ?? '',
      city: quote.contact?.city ?? '',
      postalCode: quote.contact?.postal ?? '',
      subdivision: quote.contact?.province ?? '',
      countryCode: 'US'
    })
    if (fullAddress && fullAddress.length > 0) {
      text += fullAddress.join('\n')
    }
  } else if (jobAddress) {
    const fullAddress = addressFormat({
      address: jobAddress.address,
      city: jobAddress.city,
      postalCode: jobAddress.postal,
      subdivision: jobAddress.province,
      countryCode: 'US'
    })
    if (fullAddress && fullAddress.length > 0) {
      text += fullAddress.join('\n')
    }
  }

  const showPlaceholder = !text && isEditable

  const placeholderContent = (
    <Button variant="outlined" fullWidth={true}>
      Edit
    </Button>
  )

  return (
    <QuoteHeaderCard
      classes={{
        root: classes.root,
        contents: classes.contents
      }}
      onClick={onEditJobAddress}
      title="Job Address"
      text={text}
      clickable={isEditable}
      placeholderContent={placeholderContent}
      showPlaceholder={showPlaceholder}
    />
  )
}

export default withStyles(styles)(JobAddressCard)

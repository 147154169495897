import type { OptionsDocument, UserPreferencesDocument } from 'paintscout'

export function hasIntegrationInterface({
  preferences,
  options,
  integration
}: {
  preferences?: UserPreferencesDocument
  options: OptionsDocument
  integration: string
}): boolean {
  if (!!options.options.features?.settingsIntegrations?.enabled === true) {
    return !!options.options.integrations?.[integration]?.enabled
  } else {
    if (preferences?.integrations?.[integration]?.enabled) {
      return true
    }
    return !!options.options.integrations?.[integration]?.enabled
  }
}

import React from 'react'
import { Paper } from '@ui/paintscout'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import QuoteContextProvider from '../context/QuoteContextProvider'
import ArchivedItemsView from './views/ArchivedItemsView'
import NotesView from './views/NotesView'
import QuoteView from './views/QuoteView'
import WorkOrderView from './views/WorkOrderView'
import type { QuoteDocument } from 'paintscout'
import ProductOrderForm from './views/ProductOrderForm'
import type { WisetackPromoResponse } from '../Wisetack'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: 20
    },
    scalerWrapper: {},
    paper: {
      borderRadius: 2,
      // boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.25)',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 954,
      [theme.breakpoints.down('md')]: {
        width: 718
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    paperWrapper: {},
    content: {
      // padding: `${pxToRem(50)} ${pxToRem(72)}`
      padding: '30px 30px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 20px'
      },
      '@media print': {
        padding: '0'
      }
    },
    headerWrapper: {},
    bidTableWrapper: {
      marginTop: theme.spacing(2)
    },
    optionsTableWrapper: {
      marginTop: theme.spacing(5)
    },
    optionsTableSubhead: {
      ...theme.typography.subtitle2,

      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(2)
    },
    termsWrapper: {
      marginTop: theme.spacing(5)
    }
  })
}

export type QuoteView =
  | 'quote'
  | 'work-order'
  | 'archived-items'
  | 'activity'
  | 'details'
  | 'terms'
  | 'notes'
  | 'presentation'
  | 'product-order-form'
  | 'payment-receipt'

export interface QuoteProps {
  view?: QuoteView
  // If you aren't rendering inside of a QuoteContextProvider, you can provide
  // the necessary props here to render without one
  quote?: QuoteDocument
  tableView?: 'area' | 'substrate'
  online?: boolean
  wisetackPromo?: WisetackPromoResponse
  inPresentation?: boolean

  onClick?: (event: React.MouseEvent) => any
  onClickCapture?: (event: React.MouseEvent) => any
}

type QuotePropsWithInjections = QuoteProps & WithStyles<typeof styles>

/**
 * Renders a quote
 *
 * It will use the quote from an existing QuoteContextProvider if there is one, but if not, it'll provide its
 * own using the `quote` prop
 */
function Quote(props: QuotePropsWithInjections) {
  const { classes, view, quote, tableView, onClick, onClickCapture, inPresentation, online = true } = props

  const content = (
    <Paper
      classes={{ root: [classes.paper, 'Quote-paper'].join(' ') }}
      onClick={onClick}
      onClickCapture={onClickCapture}
    >
      <div className={classes.content}>
        {view === 'quote' && <QuoteView inPresentation={inPresentation} online={online} />}
        {view === 'work-order' && <WorkOrderView />}
        {view === 'product-order-form' && <ProductOrderForm />}
        {view === 'archived-items' && <ArchivedItemsView />}
        {view === 'notes' && <NotesView />}
      </div>
    </Paper>
  )

  if (quote) {
    return (
      <QuoteContextProvider quote={quote} tableView={tableView}>
        {content}
      </QuoteContextProvider>
    )
  } else {
    return <>{content}</>
  }
}

Quote.defaultProps = {
  view: 'quote' as QuoteView
}

export default withStyles(styles)(Quote)

import type { PresentationOption, OptionsDocument } from 'paintscout'
import { getObjectLabels } from '../../../util/get-object-labels'
import type { QuoteView } from '@ui/react-quote'

export function getDefaultPresentation(args: {
  options: OptionsDocument
  view?: QuoteView
  invoice?: boolean
}): PresentationOption {
  const { options, view, invoice } = args
  const objectLabels = getObjectLabels({ options })

  if (view === 'work-order') {
    return getDefaultCrewPresentation()
  } else if (view === 'product-order-form') {
    return getDefaultProductOrderPresentation()
  } else if (invoice) {
    return getDefaultInvoicePresentation()
  } else {
    return getDefaultPresentation()
  }

  function getDefaultPresentation() {
    const defaultHomePage = {
      key: 'home-page',
      title: objectLabels.quote.value,
      label: objectLabels.quote.value,
      type: 'default',
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      },
      sections: [
        {
          key: 'introduction-section',
          type: 'introduction',
          label: 'Introduction',
          title: '',
          active: true,
          advanced: {
            useForNav: false,
            allowCustom: true
          }
        },
        {
          key: 'quote-section',
          type: 'quote',
          label: objectLabels.quote.value,
          title: objectLabels.quote.value,
          active: true,
          advanced: {
            useForNav: true,
            allowCustom: false
          },
          fields: {},
          background: {
            style: 'contained',
            color: '#fff'
          }
        },
        {
          key: 'pictures-section',
          type: 'pictures',
          label: 'Pictures',
          title: 'Pictures',
          active: true,
          advanced: {
            useForNav: false,
            allowCustom: false
          },
          fields: {},
          background: {
            style: 'contained',
            color: '#fff'
          }
        },
        {
          key: 'terms-section',
          type: 'terms',
          label: 'Contract Terms',
          title: 'Terms',
          active: true,
          advanced: {
            useForNav: true,
            allowCustom: false
          },
          fields: {},
          background: {
            style: 'contained',
            color: '#fff'
          }
        }
      ]
    }

    const defaultAccent = '#000000'
    const defaultButton = '#000000'

    return {
      key: 'default',
      label: 'Default',
      quoteTypes: ['all'],
      active: true,
      advanced: {
        autoOpenNav: false,
        infiniteScroll: false,
        backgroundColor: '#FFFFFF',
        accentColor: defaultAccent,
        textColor: '#000000',
        buttonColor: defaultButton,
        fontType: 'sans-serif',
        partnerContent: {}
      },
      pages: [defaultHomePage]
    }
  }

  function getDefaultCrewPresentation() {
    return {
      key: 'default',
      label: 'Default',
      quoteTypes: ['all'],
      active: true,
      pages: [
        {
          key: 'home-page',
          title: objectLabels.workOrder.value,
          label: objectLabels.workOrder.value,
          type: 'default',
          advanced: {
            useForNav: true,
            allowCustom: false,
            active: true
          },
          sections: [
            {
              key: 'work-order-section',
              type: 'work-order',
              label: objectLabels.workOrder.value,
              title: objectLabels.workOrder.value,
              advanced: {
                useForNav: true,
                allowCustom: false,
                active: true
              },
              fields: {},
              background: {
                style: 'contained',
                color: '#fff'
              }
            }
          ]
        }
      ]
    }
  }

  function getDefaultInvoicePresentation() {
    return {
      key: 'default',
      label: 'Default',
      quoteTypes: ['all'],
      active: true,
      pages: [
        {
          key: 'home-page',
          title: 'Invoice',
          label: 'Invoice',
          type: 'default',
          advanced: {
            useForNav: true,
            allowCustom: false,
            active: true
          },
          sections: [
            {
              key: 'quote-section',
              type: 'quote',
              label: 'Invoice',
              title: 'Invoice',
              advanced: {
                useForNav: true,
                allowCustom: false,
                active: true
              },
              fields: {},
              background: {
                style: 'contained',
                color: '#fff'
              }
            },

            {
              key: 'pictures-section',
              type: 'pictures',
              label: 'Pictures',
              title: 'Pictures',
              active: true,
              advanced: {
                useForNav: false,
                allowCustom: false
              },
              fields: {},
              background: {
                style: 'contained',
                color: '#fff'
              }
            },
            {
              key: 'terms-section',
              type: 'terms',
              label: 'Payment Terms',
              title: 'Terms',
              advanced: {
                useForNav: true,
                allowCustom: false,
                active: true
              },
              fields: {},
              background: {
                style: 'contained',
                color: '#fff'
              }
            }
          ]
        }
      ]
    }
  }

  function getDefaultProductOrderPresentation() {
    return {
      key: 'default-product-order',
      label: 'Default',
      quoteTypes: ['all'],
      active: true,
      pages: [
        {
          key: 'home-page',
          title: 'Product Order From',
          label: 'Product Order From',
          type: 'default',
          advanced: {
            useForNav: true,
            allowCustom: false,
            active: true
          },
          sections: [
            {
              key: 'product-order-form-section',
              type: 'product-order-form',
              label: 'Product Order Form',
              title: 'Product Order Form',
              advanced: {
                useForNav: true,
                allowCustom: false,
                active: true
              },
              fields: {},
              background: {
                style: 'contained',
                color: '#fff'
              }
            }
          ]
        }
      ]
    }
  }
}

import React from 'react'
import type { LinkProps as MuiLinkProps } from '@material-ui/core/Link'
import MuiLink from '@material-ui/core/Link'
import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'

export type LinkProps = MuiLinkProps & ReactRouterLinkProps
/**
 * A styled Link for react-router
 */
function Link({ ...props }: LinkProps) {
  return <MuiLink component={ReactRouterLink as any} underline="none" {...props} />
}

export default Link

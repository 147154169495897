import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { OverridableComponent } from '@material-ui/core/OverridableComponent'
import type { TypographyTypeMap as MuiTypographyTypemap } from '@material-ui/core/Typography'
import MuiTypography from '@material-ui/core/Typography'
import classnames from 'classnames'
import NumberFormat from '@ui/core/NumberFormat'

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    error: {
      color: theme.palette.error.main
    }
  }
})

export interface TypographyTypeMap<P = unknown, D extends React.ElementType = 'span'>
  extends MuiTypographyTypemap<P, D> {
  props: MuiTypographyTypemap<P, D>['props'] & {
    format?: string
    value?: number
    showUnits?: boolean
    showZeros?: boolean
  }
}

/**
 * Extension of Material-UI's Typography component to add formatting helpers
 *
 */
const Typography: OverridableComponent<TypographyTypeMap> = (props: any) => {
  const classes = useStyles(props)
  const { format, showUnits, showZeros, ...typographyProps } = props
  const value = props.value ?? 0
  const { ...typographyClasses } = classes
  const className = classnames({
    [classes.root]: !!classes.root,
    [props.className]: !!props.className,
    [classes.error]: props.color === 'error'
  })

  return (
    <MuiTypography
      {...typographyProps}
      classes={typographyClasses}
      className={className}
      color={props.color === 'error' ? 'inherit' : props.color} // keep material-ui prop validation happy
    >
      <NumberFormat format={format} showUnits={showUnits} showZeros={showZeros} value={value}>
        {props.children}
      </NumberFormat>
    </MuiTypography>
  )
}

export default Typography

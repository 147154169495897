import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import classnames from 'classnames'
import React from 'react'
import type { IconButtonProps } from '../IconButton'
import IconButton from '../IconButton'
import type { StyleClasses } from '../theme'

export interface ActionButtonProps extends IconButtonProps {
  classes?: StyleClasses<typeof useStyles>
  className?: string
  actions: Action[]
  disabled?: boolean
  onActionClick: (event: React.MouseEvent<HTMLElement>, actionKey: string, actionPayload?: any) => void
  PopupMenu: React.ComponentType<any>
  MenuItem: React.ComponentType<any>
}

export interface Action {
  key: string

  /**
   * Text of the MenuItem
   */
  label: string

  payload?: any

  /**
   * Icon for the MenuItem
   */
  icon?: React.ComponentType<SvgIconProps>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {},
  menuItem: {},
  divider: {
    padding: 0,
    height: 0,
    minHeight: 0,
    paddingBottom: theme.spacing(),
    marginBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  }
}))

function ActionButton({
  disabled,
  actions,
  className,
  onActionClick,
  PopupMenu,
  MenuItem,
  ...props
}: ActionButtonProps) {
  const classes = useStyles(props)
  const { classes: _classProps, ...baseIconProps } = props

  return (
    <PopupMenu
      className={classnames(classes.root, className)}
      component={
        <IconButton
          className={classes.button}
          color="inherit"
          disabled={disabled}
          disableRipple={true}
          onClick={(ev) => ev.stopPropagation()}
          {...baseIconProps}
          id="action-button-icon"
        >
          <MoreHorizIcon />
        </IconButton>
      }
    >
      {actions &&
        actions.map((action, index) => {
          if (action.key === 'divider') {
            return <MenuItem key={`${action}-${index}`} disabled classes={{ root: classes.divider }} />
          }

          return (
            <MenuItem
              className={classes.menuItem}
              key={action.key}
              icon={action.icon}
              onClick={(ev) => {
                onActionClick(ev, action.key, action.payload)
              }}
            >
              {action.label}
            </MenuItem>
          )
        })}
    </PopupMenu>
  )
}

export default ActionButton

import React, { Suspense } from 'react'
import type { WithStyles } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core'
import type { SelectProps } from '../Select'
import Select from '../Select'

import Typography from '../Typography'

import MenuItem from '../MenuItem'

import WebfontLoader from '@dr-kobros/react-webfont-loader'
// const WebfontLoader = React.lazy(() => import('@dr-kobros/react-webfont-loader'))

import { getFontOptions } from './getFontOptions'

import values from 'lodash/values'
import sortBy from 'lodash/sortBy'

const styles = createStyles({
  root: {}
})

export interface FontSelectProps
  extends WithStyles<typeof styles>,
    Omit<SelectProps, 'onChange' | 'classes' | 'value'> {
  value?: string
  onChange?: (value: string) => void
}

export interface FontOption {
  label: string
  value: string
  style: any
}

const options = getFontOptions()

const sortedOptions = sortBy(values(options), 'label')

const config = {
  google: {
    families: sortedOptions.map((option) => option.value)
  }
}

function RateTypeSelect({ classes, value, onChange, ...baseSelectProps }: FontSelectProps) {
  return (
    <Suspense fallback={null}>
      <WebfontLoader config={config}>
        <div className={classes.root}>
          <Select
            {...baseSelectProps}
            fullWidth={true}
            name={'value'}
            value={value}
            shrinkLabel={true}
            variant={'outlined'}
            onChange={handleChange}
          >
            {sortedOptions.map((item: FontOption) => {
              if (!sortedOptions.length) {
                return null
              }

              return (
                <MenuItem key={item.value} value={item.value}>
                  <Typography style={item.style}>{item.label}</Typography>
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </WebfontLoader>
    </Suspense>
  )

  function handleChange(ev: React.ChangeEvent<any>) {
    onChange(ev.target.value)
  }
}

export default withStyles(styles)(RateTypeSelect)

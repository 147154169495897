import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields

  if (!fields) {
    return true
  }

  if (!section.title && !fields.subtitle && !fields.backgroundImage && !fields.overlayImage) {
    return true
  }

  return false
}

import type { QuoteArea } from 'paintscout'
import { parseNumber } from '../util/parse-number'
export function calculateLnft(area: QuoteArea) {
  const length = parseNumber(area.dimensions && area.dimensions.length ? area.dimensions.length : 0)

  if (area.area_type && area.area_type.value === 'surface') {
    return length
  } else {
    const width = parseNumber(area.dimensions && area.dimensions.width ? area.dimensions.width : 0)
    return (length + width) * 2
  }
}

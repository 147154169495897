import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import ErrorIcon from '@material-ui/icons/Error'
import type { QuoteDocument } from 'paintscout'
import { AcceptQuoteDialog, DeclineQuoteDialog, useQuote } from '@ui/react-quote'
import { AlertDialog, useClientOptions, useDialogs, useUser } from '@ui/paintscout'
import {
  getObjectLabels,
  getQuoteOptions,
  hasIntegrationInterface,
  isSignatureRequired
} from '@paintscout/util/builder'
import { getOperationName, gql } from 'apollo-boost'
import { useSnackbar } from 'notistack'
import { VIEW_QUOTE } from '../graphql/queries/VIEW_QUOTE'
import CollectPaymentDialog from '@ui/react-quote/src/dialogs/CollectPaymentDialog'
import getDepositAmount from '../../../../packages/@paintscout/util/src/util/getDepositAmount/getDepositAmount'
import { DepositPromptDialog } from '@ui/react-quote/src/dialogs'
import { isEmailValid } from '@paintscout/util/util'

export const ACCEPT_QUOTE_QUERY = gql`
  mutation acceptQuote($quote: JSON!, $signature: String) {
    acceptQuote(quote: $quote, signature: $signature) {
      quote
    }
  }
`

export const DECLINE_QUOTE_QUERY = gql`
  mutation declineQuote($rev: String!, $reason: String!) {
    declineQuote(rev: $rev, reason: $reason) {
      quote
    }
  }
`

export const ACCEPT_ADDITIONAL_WORK_QUERY = gql`
  mutation acceptAdditionalWork($rev: String!, $signature: String) {
    acceptAdditionalWork(rev: $rev, signature: $signature) {
      quote
    }
  }
`

export function useQuoteResponse() {
  const { options } = useClientOptions()
  const { quote } = useQuote()
  const { preferences } = useUser()

  function shouldRequestDeposit(quote: QuoteDocument) {
    if (!quote) {
      return false
    }
    const { allowPaymentsOnQuotes, depositAmount } = getQuoteOptions({ quote, options })
    const hasPayments = hasIntegrationInterface({ preferences, options, integration: 'stripe' })
    return (
      hasPayments &&
      allowPaymentsOnQuotes &&
      !!depositAmount?.value &&
      !quote?.is_invoice &&
      quote?.totals.amount_paid === 0 &&
      quote?.totals.balance_due > 0
    )
  }
  const hasValidEmail = !quote?.contact || (quote?.contact?.email && isEmailValid(quote?.contact?.email))

  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissAllDialogs, dismissDialog } = useDialogs()

  const objectLabels = getObjectLabels({ options })

  const [acceptQuoteMutation, acceptQuoteResponse] = useMutation(ACCEPT_QUOTE_QUERY)

  const [declineQuoteMutation, declineQuoteResponse] = useMutation(DECLINE_QUOTE_QUERY)

  const [acceptAdditionalWorkMutation, acceptAdditionalWorkResponse] = useMutation(ACCEPT_ADDITIONAL_WORK_QUERY)

  const acceptQuote = async ({ quote }: { quote: QuoteDocument }) => {
    const requireSignatureSent = isSignatureRequired({ options, quote, path: 'requireSignatureSent' })
    async function accept(signature?: string, quoteWithPayment?: QuoteDocument) {
      try {
        const res = await acceptQuoteMutation({
          variables: {
            quote: quoteWithPayment || quote,
            signature
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(VIEW_QUOTE)]
        })
        const { quote: updatedQuote } = res.data.acceptQuote

        enqueueSnackbar(`${objectLabels.quote.value} Accepted`, {
          variant: 'success'
        })
        dismissDialog()
        return updatedQuote
      } catch (error) {
        console.error(error)
        if (error.message?.includes('Rev Mismatch')) {
          updateWarning()
        } else {
          dismissAllDialogs()
          enqueueSnackbar(`An error occurred. Please try again, or contact your estimator.`, {
            variant: 'error'
          })
        }
      }
    }

    async function promptDeposit() {
      openDialog(DepositPromptDialog, {
        quote,
        options,
        onCancel: dismissAllDialogs,
        onPayDeposit: async () => {
          await payDeposit()
        }
      })
    }

    async function payDeposit() {
      return new Promise((resolve) => {
        openDialog(CollectPaymentDialog, {
          quote,
          options,
          amount: getDepositAmount({ quote, options }),
          source: 'customer-view',
          isDeposit: true,
          onConfirm: async (updatedQuote?: QuoteDocument) => {
            window.location.reload()
            resolve(updatedQuote)
          },
          onCancel: () => {
            dismissAllDialogs()
            resolve(null)
          }
        })
      })
    }

    if (!hasValidEmail && shouldRequestDeposit(quote)) {
      enqueueSnackbar('Something went wrong. Please contact your estimator', { variant: 'error' })
    } else {
      if (requireSignatureSent) {
        return new Promise((resolve) => {
          openDialog(AcceptQuoteDialog, {
            quote,
            options,
            onConfirm: async (ev: any, signature: string) => {
              await accept(signature).then((acceptedQuote) => {
                if (!acceptedQuote) {
                  return
                }
                if (shouldRequestDeposit(acceptedQuote)) {
                  promptDeposit().then(() => {
                    resolve(acceptedQuote)
                  })
                } else {
                  resolve(acceptedQuote)
                }
              })
            },
            onCancel: () => {
              dismissDialog()
            }
          })
        })
      } else {
        return new Promise(async (resolve) => {
          await accept().then((acceptedQuote) => {
            if (!acceptedQuote) {
              return
            }
            if (shouldRequestDeposit(acceptedQuote)) {
              promptDeposit().then(() => {
                resolve(acceptedQuote)
              })
            } else {
              resolve(acceptedQuote)
            }
          })
        })
      }
    }
  }

  const declineQuote = async ({ quote }: { quote: QuoteDocument }) => {
    return new Promise((resolve) => {
      openDialog(DeclineQuoteDialog, {
        quote,
        options,
        onConfirm: async (ev: any, data: string) => {
          try {
            const res = await declineQuoteMutation({
              variables: {
                rev: quote._rev,
                reason: data
              },
              awaitRefetchQueries: true,
              refetchQueries: [getOperationName(VIEW_QUOTE)]
            })

            const { quote: updatedQuote } = res.data.declineQuote

            enqueueSnackbar(`${objectLabels.quote.value} Declined`, {
              variant: 'success'
            })
            dismissDialog()
            return resolve(updatedQuote)
          } catch (e) {
            console.error(e)
            const error = e
            if (error.message?.includes('Rev Mismatch')) {
              updateWarning()
            } else {
              enqueueSnackbar(`An error occurred. Please try again, or contact your estimator.`, {
                variant: 'error'
              })
            }
          }
          return resolve(null)
        },
        onCancel: () => {
          dismissDialog()
          return resolve(null)
        }
      })
    })
  }

  const acceptAdditionalWork = async ({ quote }: { quote: QuoteDocument }) => {
    const requireSignatureAdditional = isSignatureRequired({ options, quote, path: 'requireSignatureAdditionalWork' })
    async function acceptAdditional(signature?: string) {
      try {
        const res = await acceptAdditionalWorkMutation({
          variables: {
            rev: quote._rev,
            signature
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(VIEW_QUOTE)]
        })

        const { quote: updatedQuote } = res.data.acceptAdditionalWork

        enqueueSnackbar(`${objectLabels.additionalWork.value} Accepted`, {
          variant: 'success'
        })
        dismissDialog()
        return updatedQuote
      } catch (e) {
        console.error(e)
        const error = e
        if (error.message?.includes('Rev Mismatch')) {
          updateWarning()
        } else {
          enqueueSnackbar(`An error occurred. Please try again, or contact your estimator.`, {
            variant: 'error'
          })
        }
      }
    }

    if (requireSignatureAdditional) {
      return new Promise((resolve) => {
        openDialog(AcceptQuoteDialog, {
          quote,
          options,
          onConfirm: async (ev: any, data: string) => {
            const quote = await acceptAdditional(data)
            resolve(quote)
          },
          onCancel: () => {
            dismissDialog()
          }
        })
      })
    } else {
      return acceptAdditional()
    }
  }

  return {
    acceptQuote,
    declineQuote,
    acceptAdditionalWork,
    responding: acceptQuoteResponse.loading || declineQuoteResponse.loading || acceptAdditionalWorkResponse.loading
  }

  function updateWarning() {
    openDialog(AlertDialog, {
      title: `${objectLabels.quote.value} Has Been Updated`,
      icon: <ErrorIcon />,
      color: 'warning',
      message: `The ${objectLabels.quote.value} was updated by the Estimator while you were viewing it. It may have been for internal purposes, but we suggest you look over it once more first.`,
      onConfirm: () => {
        dismissAllDialogs()
        window.location.reload()
      }
    })
  }
}

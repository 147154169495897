import React, { useContext, useMemo, useState } from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { useClientOptions, useUser, Collapse, Hidden } from '@ui/paintscout'
import type { RenderableItem } from '@paintscout/util/builder'
import { getItems, getQuoteOptions, getFeatures, getObjectLabels, isSignatureRequired } from '@paintscout/util/builder'
import get from 'lodash/get'
// import CopyIcon from '@material-ui/icons/FileCopy'
import AddBoxIcon from '@material-ui/icons/AddBox'
import ArchiveIcon from '@material-ui/icons/Archive'
import ConvertIcon from '@material-ui/icons/SwapHoriz'
import ListIcon from '@material-ui/icons/List'
import ErrorIcon from '@material-ui/icons/Error'
import { Typography } from '@ui/paintscout'
import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { ItemTableCell, ItemColumn } from '../../../../ItemTable'
import { QuoteContext } from '../../../../context/QuoteContext'
import ActionColumn from '../../../../ItemTable/columns/ActionColumn'

import { Grid } from '@ui/paintscout'
import { Button } from '@ui/paintscout'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    tableHead: {
      background: theme.palette.grey[200]
    },
    headerCell: {
      color: theme.palette.grey[800]
    },
    heading: {
      marginBottom: theme.spacing(1.5)
    },
    approveButton: {
      color: theme.palette.warning.main
    }
  })
}

export interface PendingTableProps extends WithStyles<typeof styles> {
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  onItemAction?: ItemTableProps['onItemAction']
  collapsed?: boolean
}

function PendingTable({ classes, collapsed, onItemAction, ...itemTableProps }: PendingTableProps) {
  const quoteContext = useContext(QuoteContext)
  const { quote, tableView, isEditable, isNoteEditable, onAccept, onReorder, onAddItem, canAddAdditionalWork } =
    quoteContext
  const { options } = useClientOptions()
  const { isAdmin, isCrew, isViewer } = useUser()
  const features = getFeatures({ options })
  const canConvertToLineItem = get(features, 'quotes.convertToLineItem')
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
  const { addIndex, hideAllPrices } = getQuoteOptions({ quote, options })
  const [responding, setResponding] = useState(false)
  const signatureRequired = isSignatureRequired({ options, quote, path: 'requireSignatureAdditionalWork' })

  const items: RenderableItem[] = useMemo(
    () =>
      getItems({
        quote,
        section: 'pending',
        view: tableView,
        consumer: 'customer',
        options,
        overrideOptions: { showPrices: true }
      }),
    [quote, tableView, options]
  )

  if (items.length === 0) {
    return null
  }

  if (collapsed && !isEditable) {
    return null
  }

  function handleReorder({ items, addIndex }) {
    onReorder?.({ items, section: 'pending', view: tableView, addIndex })
  }

  /*
    We're going to not show the add button on Additional Work... So that people are forced to
    first make options, and then turn them into Additional Work (with approval). Not sure if this
    will stick...
    showAddButton={hasAdditionalWork && this.props.showAdd}
     */

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems={'center'} className={classes.heading}>
        <Grid item>
          <Typography variant={'h3'}>Pending {objectLabels.additionalWork.plural}</Typography>
        </Grid>
        <Grid item>
          <Button
            id="allow-propagation"
            onClick={async () => {
              if (!signatureRequired) {
                setResponding(true)
              }
              await onAccept?.(true, quote)
              setResponding(false)
            }}
            loading={responding}
            variant={'text'}
            icon={ErrorIcon}
            classes={{ root: classes.approveButton }}
          >
            {objectLabels.option.value} Approval Required
          </Button>
        </Grid>
      </Grid>

      <Collapse show={!collapsed}>
        <ItemTable
          classes={{
            tableHead: classes.tableHead
          }}
          items={items}
          showAddButton={isAdmin && isEditable && (!quote.is_invoice || !quote.parent_quote)}
          addButtonLabel={'Add Area or Line Item'}
          checkboxes={!!(isEditable || isCrew || isViewer)}
          addIndex={addIndex.additional}
          onReorder={handleReorder}
          onItemClick={
            (!quote.is_invoice || isAdmin) && (isEditable || isNoteEditable)
              ? (ev, item) => onItemAction('edit', 'pending', item as RenderableItem)
              : null
          }
          onAddClick={
            canAddAdditionalWork ? (ev, insertAfterKey, type) => onAddItem('pending', insertAfterKey, type) : null
          }
          renderHeadColumns={() => (
            <>
              <ItemTableCell className={classes.headerCell}>Item</ItemTableCell>
              <ItemTableCell className={classes.headerCell} align="right">
                {!hideAllPrices && (
                  <Hidden smDown implementation="css">
                    {objectLabels.currency.symbol}
                  </Hidden>
                )}
              </ItemTableCell>

              {/* action cell */}
              {(isEditable || isCrew || isViewer) && <ItemTableCell className={classes.headerCell} />}
            </>
          )}
          renderRowColumns={(item: RenderableItem) => {
            const actions = []
            {
              /*if (isAdmin) {
              actions.push({
                key: 'edit',
                label: 'Edit',
                icon: EditIcon
              })
            }*/
            }

            if (!quote.is_invoice && !isCrew && !isViewer) {
              actions.push({
                key: 'move-to-bid',
                label: `Move to ${objectLabels.quote.value}`,
                icon: AddBoxIcon
              })
            }
            if (quote.is_invoice || isCrew || isViewer || isAdmin) {
              actions.push({
                key: 'make-option',
                label: `Make ${objectLabels.option.value}`,
                icon: AddBoxIcon
              })
            }
            if (isAdmin || isCrew || isViewer) {
              actions.push({
                key: 'archive',
                label: 'Archive',
                icon: ArchiveIcon
              })
            }

            if (item.type === 'group' && isAdmin) {
              actions.push({
                key: 'ungroup',
                label: 'Ungroup',
                icon: ListIcon
              })
            }

            if (canConvertToLineItem && item.type !== 'line_item') {
              actions.push({
                key: 'convert',
                label: 'Convert to Line Item',
                icon: ConvertIcon
              })
            }

            return (
              <>
                <ItemTableCell colSpan={2}>
                  <ItemColumn
                    item={item}
                    isEditable={isEditable || isCrew || isViewer}
                    showAmount={!hideAllPrices}
                    consumer="customer"
                    section={'pending'}
                  />
                </ItemTableCell>
                {(isEditable || isCrew || isViewer) && (
                  <ItemTableCell cancelDrag isControl>
                    <ActionColumn
                      onActionClick={(ev, action) => onItemAction(action, 'pending', item)}
                      actions={actions}
                    />
                  </ItemTableCell>
                )}
              </>
            )
          }}
          {...itemTableProps}
        />
      </Collapse>
    </div>
  )
}

export default withStyles(styles)(PendingTable)

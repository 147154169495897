import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import React from 'react'
import type { PresentationSectionProps } from '../../../..'
import ContentBlock from '../_components/ContentBlock'
import PageHeader from '../_components/PageHeader'

const useStyles = makeStyles<Theme, ColorHelpProps>(
  (theme) => ({
    root: {
      background: theme.palette.common.white,
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
      overflow: 'hidden'
    }
  }),
  { name: 'ColorHelp' }
)

export interface ColorHelpProps {
  classes?: StyleClasses<typeof useStyles>
}

function ColorHelp(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { quote } = props

  return (
    <div className={classes.root}>
      <PageHeader
        title={
          <>
            Get Help
            <br />
            With Color
          </>
        }
        ctaLabel="Order Samples"
        ctaLink="https://samples.sherwin-williams.com/view/top-50-colors?_gl=1*ipru9e*_gcl_aw*R0NMLjE3MDk3Mzg0MzAuQ2p3S0NBaUFyZmF1QmhBcEVpd0Flb0I3cUFQSkozVXp1SjF4NlpDNFk1XzBTekJKMTNXcnl4Yy1PM3lMcGE2UXhOSXBmbG1JRl9YRHl4b0M5YXdRQXZEX0J3RQ..*_gcl_au*MTg3MzYxNTQwNy4xNzA1NTEwMjA2*_ga*MTkxMDU0MDU2MS4xNzAxNzIwOTM1*_ga_BFTFS1H2E0*MTcwOTgzMDQ2Ni45NC4xLjE3MDk4MzA1NDEuNTMuMC4w&_ga=2.44348844.941519321.1709664242-1910540561.1701720935&_gac=1.223506281.1709738716.CjwKCAiArfauBhApEiwAeoB7qAPJJ3UzuJ1x6ZC4Y5_0SzBJ13Wryxc-O3yLpa6QxNIpflmIF_XDyxoC9awQAvD_BwE"
        backgroundImage={
          'https://res.cloudinary.com/taptapquote/image/upload/v1709849416/paintscout/partners/sw/images/header-background-img.png'
        }
        headerBackgroundColor="#1B6374"
      />

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp?.uploadPhotos && (
        <ContentBlock
          img={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849415/paintscout/partners/sw/images/upload-your-photos.png'
          }
          preTitle="EXPERIENCE YOUR COLORS"
          title={
            <>
              Upload Your
              <br />
              Photos
            </>
          }
          description={
            <>
              See our colors in your space -- upload
              <br />
              a photo of your own space and add
              <br />
              any color!
            </>
          }
          linkHref="https://www.sherwin-williams.com/visualizer#/active/scenes"
          linkLabel="Upload Your Photo"
          styles={{ padding: '80px 40px 40px' }}
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp
        ?.scheduleFreeConsultation && (
        <ContentBlock
          imgFirst
          img={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849413/paintscout/partners/sw/images/schedule-free-consultation.png'
          }
          title={
            <>
              Schedule a free
              <br />
              30 min Color
              <br />
              Consultation
            </>
          }
          description={
            <>
              Get expert color advice with our in-
              <br />
              store experts.
            </>
          }
          linkHref="https://www.sherwin-williams.com/en-us/virtual-color-consultation"
          linkLabel="Book Now"
          styles={{ padding: '40px' }}
        />
      )}

      {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp.matchAPhoto && (
        <ContentBlock
          img={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849412/paintscout/partners/sw/images/match-a-photo.png'
          }
          title={<>Match a Photo</>}
          description={
            <>
              Capture colors from any photo and match
              <br />
              them to actual Sherwin-Williams paint colors.
            </>
          }
          linkHref="https://www.sherwin-williams.com/visualizer#/active/colors"
          linkLabel="Learn more"
          styles={{ padding: '40px' }}
        />
      )}

      {/* {quote.presentationTemplate?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp.newApp && (
        <ContentBlock
          imgFirst
          img={
            'https://res.cloudinary.com/taptapquote/image/upload/v1709849411/paintscout/partners/sw/images/color-expert-app.png'
          }
          preTitle={'NEW APP!'}
          title={
            <>
              Try Our New
              <br />
              Color Expert App
            </>
          }
          description={
            <>
              Sherwin-Williams - Color ideas
              <br />
              tailored to your space.
            </>
          }
          // linkHref="https://www.google.ca"
          // linkLabel="Download Now"
          styles={{ padding: '40px' }}
        />
      )} */}
    </div>
  )
}

export default ColorHelp

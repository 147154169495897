import getFileKey from './getFileKey'
import getFileDimensions from './getFileDimensions'
import getPageCount from './getPageCount'
import getUploadData from './getUploadData'
import compressFile from './compressFile'
import generateVideoThumbnail from './generateVideoThumbnail'
import getRequiredTask from './getRequiredTask'
import { RESIZED_FILE_TYPES } from './getFileDimensions'
import getUploadUrl from './getUploadUrl'
import getMp4ThumbnailKey from './getMp4ThumbnailKey'
import dataUriToBlob from './dataUriToBlob'
import getInitialImageWidth from './getInitialImageWidth'

import type { S3UrlComponents } from './getFileDimensions'
import type { CompressFileOptions } from './compressFile'

export type { CompressFileOptions, S3UrlComponents }

const PERMITTED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/svg+xml',
  // 'image/webp',
  'application/pdf',
  'video/*'
]

export interface S3FileUploadData {
  size: number
  src: string
  s3PublicKey: string
  bucket: string
  format: string
  type: string
  pages?: number
  width?: number
  height?: number
}

export {
  PERMITTED_FILE_TYPES,
  RESIZED_FILE_TYPES,
  compressFile,
  getFileKey,
  getUploadData,
  getPageCount,
  getFileDimensions,
  dataUriToBlob,
  getMp4ThumbnailKey,
  getUploadUrl,
  getRequiredTask,
  generateVideoThumbnail,
  getInitialImageWidth
}

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import type { DialogContentProps as MuiDialogContentProps } from '@material-ui/core/DialogContent'
import MuiDialogContent from '@material-ui/core/DialogContent'

export interface DialogContentProps extends MuiDialogContentProps {
  className?: string
}

const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(function DialogContent(props, ref) {
  return (
    <MuiDialogContent {...props} ref={ref}>
      {props.children}
    </MuiDialogContent>
  )
})

export default withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    ...theme.typography.body1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3)
    },
    '& #menu-bar': {
      top: -theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        top: -theme.spacing()
      }
    }
  }
}))(DialogContent)

import type { QuoteDocument, QuoteTotals } from 'paintscout'
import cloneDeep from 'lodash/cloneDeep'
import { getAreaDimensions, getAreaSubstrateDimensions } from '../totals/sub-total'
import { addDimensions } from '../totals/add-dimensions'
import { add } from '../util'
import { getQuoteOptions } from '../../builder/quote/util/get-options'

/**
 * Add the group child totals & dimensions to the quote totals
 */
export function addChildTotals({
  quote,
  key: childKey,
  currentTotals
}: {
  quote: QuoteDocument
  key: string
  currentTotals: QuoteTotals
}): QuoteTotals {
  const { useAllAreasForTotalDimensions } = getQuoteOptions({ quote })
  const updatedTotal = cloneDeep(currentTotals)

  // If substrate, add totals to quote type group
  if (quote?.substrates[childKey]) {
    const substrate = quote?.substrates[childKey]

    let quoteType
    if (substrate.int_ext && substrate.int_ext.value) {
      quoteType = substrate.int_ext.value
    } else if (quote.int_ext && quote.int_ext.value) {
      quoteType = quote.int_ext.value
    }

    if (!quoteType || quoteType === 'all') {
      quoteType = 'int'
    }

    const hours = substrate?.grouped_totals?.hours ?? 0
    const prep = substrate?.grouped_totals?.prep ?? 0
    const price = substrate?.grouped_totals?.price ?? 0

    updatedTotal.groups[quoteType] = add(
      updatedTotal?.groups[quoteType] || {
        hours: 0,
        prep: 0,
        price: 0
      },
      {
        hours,
        prep,
        price
      },
      quote
    )
  } else if (quote?.areas[childKey]) {
    // If area, add to total dimensions
    const area = quote.areas[childKey]
    if (useAllAreasForTotalDimensions) {
      updatedTotal.dimensions = addDimensions({ total: updatedTotal.dimensions, area: getAreaDimensions(area) })
    } else {
      updatedTotal.dimensions = addDimensions({
        total: updatedTotal.dimensions,
        area: getAreaSubstrateDimensions(area)
      })
    }
  }

  return updatedTotal
}

import React, { useContext, useRef, useMemo } from 'react'
import type { QuoteDocument } from 'paintscout'
import type { DialogProps } from '@ui/paintscout'
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogStackContext } from '@ui/paintscout'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { Button, Editor, ConfirmationDialog, OverridableTextArea, useClientOptions } from '@ui/paintscout'
import { OverridableInputField, Grid } from '@ui/paintscout'
import { getDetails, setDetails } from '@paintscout/util/builder'
import type { QuoteDetails } from '@paintscout/util/builder'
import Done from '@material-ui/icons/Done'
import { useQuote } from '../../context'
import { isLooselyEqual } from '@paintscout/util'

export interface EditCrewNoteDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  quote: QuoteDocument
  onConfirm: (quote: QuoteDocument) => void
  onCancel: () => void
}

const styles = (_theme: Theme) => {
  return createStyles({
    root: {},
    dialogContent: {}
  })
}

function EditCrewNoteDialog(props: EditCrewNoteDialogProps) {
  const { quote, loading, onConfirm, onCancel, classes, ...otherProps } = props
  const { openDialog, dismissDialog, dismissAllDialogs } = useContext(DialogStackContext)
  const { isEditable } = useQuote()
  const { options } = useClientOptions()

  const { totalHours, crewNote, productDescription, details } = useMemo(() => {
    const details = getDetails({ quote, options })
    return {
      details,
      totalHours: details.totals.hours,
      crewNote: details.notes?.internal,
      productDescription: details.notes?.products
    }
  }, [])

  const crewNoteRef = useRef(null)
  const productDescriptionRef = useRef(null)
  const totalHoursRef = useRef(null)

  const leftButton = (
    <Button disabled={loading} onClick={handleCancel} variant={'text'}>
      Cancel
    </Button>
  )

  return (
    <Dialog onClose={handleCancel} fullWidth={true} maxWidth={'md'} {...otherProps}>
      <DialogTitle loading={loading}>{'Edit Work Order'}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OverridableInputField
              label="Total Hours"
              value={totalHours}
              format={'hours'}
              resetBlank={true}
              autoSelect={true}
              ref={totalHoursRef}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <Editor fullWidth={true} content={crewNote} label={'Crew Note'} ref={crewNoteRef} />
          </Grid>
          <Grid item xs={12}>
            <OverridableTextArea
              allowFormatting
              disabled={!isEditable}
              label={'Product Description'}
              value={productDescription}
              ref={productDescriptionRef}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button disabled={loading} onClick={handleConfirm} variant={'contained'} icon={Done}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleConfirm(_ev: React.MouseEvent) {
    // Only update if we have changes
    if (isDirty()) {
      onConfirm(
        setDetails({
          quote,
          options,
          details: getUpdatedDetails()
        })
      )
    } else {
      onCancel()
    }
  }

  function handleCancel(_ev: React.MouseEvent) {
    if (!isDirty()) {
      return onCancel()
    }

    openDialog(ConfirmationDialog, {
      message: 'Any unsaved changes will be lost.',
      onConfirm: (_event: React.MouseEvent<HTMLElement>) => {
        onCancel()
        dismissAllDialogs()
      },
      onCancel: (_event: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function isDirty() {
    return !isLooselyEqual(getUpdatedDetails(), details)
  }

  function getUpdatedDetails(): QuoteDetails {
    const internal = crewNoteRef.current?.getHTML()
    let products = productDescriptionRef.current?.getValue()
    const hours = totalHoursRef.current?.getValue()

    // If our 'custom' is blank but our default is also empty,
    //set it to truly empty default so updates happen automaticaly
    if ((products.custom === '<p></p>' && products.default === '') || products.default === products.custom) {
      // Need to make sure the fix doesnt make it dirty, so reset to what it was
      if (
        details.notes.products.default === '' &&
        !details.notes.products.custom &&
        !details.notes.products.useCustom
      ) {
        delete products.custom
        delete products.useCustom
      } else {
        products = {
          ...products,
          custom: '',
          useCustom: false
        }
      }
    }

    return {
      ...details,
      notes: {
        ...details.notes,
        internal,
        products
      },
      totals: {
        ...details.totals,
        hours
      }
    }
  }
}

export default withStyles(styles)(EditCrewNoteDialog)

import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'
import classnames from 'classnames'
import { useBreakpoint } from '@ui/core'

import Typography from '../Typography'

import Switch from '../Switch'

import Spinner from '../Spinner'
import Tooltip from '../Tooltip'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: (props: FormSectionTitleProps) => {
        if (props.variant === 'subtitle1') {
          return theme.spacing(1, 0)
        }
        if (props.variant === 'h3') {
          return theme.spacing(2, 0)
        }
      },
      marginBottom: (props: FormSectionTitleProps) => {
        if (props.variant === 'h3') {
          return theme.spacing(2)
        } else if (props.variant === 'h2') {
          return theme.spacing(2)
        } else if (props.variant === 'subtitle1' && !props.showDivider) {
          return -theme.spacing()
        } else {
          return theme.spacing()
        }
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginBottom: (props: FormSectionTitleProps) => {
          if (props.variant === 'h3') {
            return theme.spacing(1.25)
          }
        }
      }
    },

    leftContent: {
      display: 'flex',
      flexDirection: 'column'
    },
    firstRow: {
      display: 'flex',
      flexDirection: 'row'
    },
    rightContent: {
      marginLeft: theme.spacing()
    },
    toggle: {
      '@media print': {
        display: 'none'
      },
      marginLeft: (props: FormSectionTitleProps) => (props.leftToggle ? 0 : undefined)
    },
    title: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: theme.spacing(),
      marginBottom: theme.spacing(0.5),
      alignItems: 'center',
      color: (props: FormSectionTitleProps) => {
        if (props.variant === 'subtitle1') {
          return theme.palette.grey[400]
        }
      }
    },
    pointer: {
      cursor: 'pointer'
    },
    subheaderDivider: {
      width: '99%',
      marginBottom: theme.spacing()
    },
    required: {
      '& > span': {
        color: theme.palette.error.main
      }
    }
  })

type DivProps = JSX.IntrinsicElements['div']
export interface FormSectionTitleProps extends Omit<DivProps, 'title'> {
  title?: string | React.ReactNode
  subTitle?: React.ReactNode
  showToggle?: boolean
  leftToggle?: boolean
  loading?: boolean
  toggleTestId?: string
  toggleValue?: boolean
  onToggle?: (ev: React.MouseEvent, checked?: boolean) => void
  rightContent?: React.ReactNode
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'subtitle1'
  toolTip?: string | React.ReactNode
  interactiveToolTip?: boolean
  showDivider?: boolean
  required?: boolean
}

type FormSectionTitlePropsWithInjections = FormSectionTitleProps & WithStyles<typeof styles>

/**
 * Renders a div with a Divider above it
 */
const FormSectionTitle = (props: FormSectionTitlePropsWithInjections) => {
  const {
    title,
    subTitle,
    showToggle,
    leftToggle,
    loading,
    toggleTestId,
    onToggle,
    toggleValue,
    rightContent,
    classes,
    style,
    toolTip,
    interactiveToolTip,
    showDivider,
    required
  } = props
  const variant = props.variant ? props.variant : 'h3'
  const isSmallScreen = useBreakpoint((breakpoints) => breakpoints.down('md'))

  return (
    <>
      <div className={classes.root}>
        <div className={classes.leftContent}>
          <div className={classes.firstRow}>
            {title && (
              <>
                <Typography
                  variant={variant}
                  color="inherit"
                  gutterBottom={false}
                  onClick={(ev) => {
                    if (showToggle && onToggle) {
                      onToggle(ev, !toggleValue)
                    }
                  }}
                  style={style}
                  classes={{
                    root: classnames({
                      [classes.title]: true,
                      [classes.pointer]: showToggle,
                      [classes.required]: required
                    })
                  }}
                >
                  {showToggle && leftToggle && (
                    <Switch checked={toggleValue} classes={{ root: classes.toggle }} data-testid={toggleTestId} />
                  )}
                  {title}
                  {required && <span style={{ color: 'red' }}>*</span>}
                  {showToggle && !leftToggle && (
                    <Switch checked={toggleValue} classes={{ root: classes.toggle }} data-testid={toggleTestId} />
                  )}
                  {toolTip && <Tooltip interactive={interactiveToolTip} content={toolTip} />}
                </Typography>
                {loading && <Spinner size={15} />}
              </>
            )}
          </div>

          {subTitle && (
            <Typography variant={isSmallScreen ? 'subtitle2' : 'subtitle1'} gutterBottom={false}>
              {subTitle}
            </Typography>
          )}
        </div>
        {rightContent && <div className={classes.rightContent}>{rightContent}</div>}
      </div>
      {showDivider && <Divider className={classes.subheaderDivider} />}
    </>
  )
}

export default withStyles(styles)(FormSectionTitle)

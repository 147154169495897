import { makeStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, Spinner } from '@ui/paintscout'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: '1/1',
    '& iframe': {
      border: 'none',
      width: '100%',
      height: '100%'
    }
  }
})

export interface InlineWisetackLinkDialog extends DialogProps {
  onConfirm: () => void
  link?: string
  createLink: () => Promise<string>
}

const CopyWisetackLinkDialog = (props: InlineWisetackLinkDialog) => {
  const classes = useStyles()
  const { onConfirm, createLink } = props
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState(props.link)

  useEffect(() => {
    const getLink = async () => {
      setLoading(true)
      await createLink().then((createdLink) => {
        setLoading(false)
        setLink(createdLink)
      })
    }
    if (!link) {
      getLink()
    }
  }, [link])

  const handleDone = () => {
    if (loading) {
      onConfirm()
    } else {
      window.location.reload()
    }
  }

  return (
    <Dialog {...props}>
      <div className={classes.root}>
        {loading && <Spinner />}
        {!loading && !!link && <iframe src={link} />}
      </div>
      <DialogActions>
        <Button variant="text" onClick={handleDone}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CopyWisetackLinkDialog

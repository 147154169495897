/**
 * Trims an object of all hard-coded false values
 * @arg obj The object to trim
 * @arg excludeKeys An array of keys to exclude from the trim
 */
export function removeFalseValues<T>(obj: Record<string, any>, excludeKeys: string[] = []): T {
  const newObj = {}
  for (const key in obj) {
    if (excludeKeys.includes(key)) {
      newObj[key] = obj[key]
    } else if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      const nestedExcludeKeys = excludeKeys
        .filter((excludeKey) => excludeKey.startsWith(`${key}.`))
        .map((excludeKey) => excludeKey.replace(`${key}.`, ''))

      newObj[key] = removeFalseValues(obj[key], nestedExcludeKeys)
    } else if (obj[key] !== false) {
      newObj[key] = obj[key]
    }
  }
  return newObj as T
}

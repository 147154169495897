import { makeStyles } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import React from 'react'
import Button from '../Button'
import type { InputProps } from '../Input'
import Input from '../Input'
import type { WithUseStyles } from '../styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  inputWrapper: {
    flex: 1
  },
  inputRoot: {
    border: 'none',
    overflowY: 'auto',
    height: 'auto'
  },
  focused: {
    boxShadow: 'none'
  },
  button: {
    marginRight: theme.spacing(),
    width: 32,
    height: 30,
    minWidth: 0
  },
  sendIcon: {
    width: 16,
    height: 16,
    marginTop: -2
  }
}))

export interface MessageInputProps extends WithUseStyles<typeof useStyles>, Omit<InputProps, 'classes'> {
  onSend?: () => any
}

export default function MessageInput({ onSend, ...props }: MessageInputProps) {
  const classes = useStyles({})
  const isTouchScreenDevice = navigator.maxTouchPoints > 0

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // send when enter is hit without shift on desktop, enter sould always make a new line on mobile.
    if (e.key === 'Enter' && !e.shiftKey && !isTouchScreenDevice) {
      e.preventDefault()
      onSend()
    }
  }

  return (
    <div className={classes.root}>
      <Input
        classes={{
          wrapper: classes.inputWrapper,
          root: classes.inputRoot,
          focused: classes.focused
        }}
        placeholder="Send a message..."
        fullWidth
        multiline
        maxRows={4}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <Button className={classes.button} disabled={!props.value} onClick={() => onSend && onSend()}>
        <SendIcon className={classes.sendIcon} />
      </Button>
    </div>
  )
}

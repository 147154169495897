import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: process.env.SENTRY_CUSTOMER_VIEW_DSN,
  release: `${process.env.VERSION}`,
  environment: process.env.DEPLOYMENT_STAGE,
  ignoreErrors: [
    /^typeError: failed to fetch$/i,
    /^typeError: cancelled$/i,
    /Cannot resolve a Slate point from DOM point/i,
    /newestWorker is null/i,
    /Invariant Violation: 8/i,
    /ceCurrentVideo.currentTime/i,
    /undefined is not an object .* evaluating .*length/i,
    /network/i,
    // this error gets thrown when device is offline, and is expected.
    /Failed to update a ServiceWorker for scope(.*)An unknown error occurred when fetching the script/i,
    /Failed to update a ServiceWorker for scope(.*) with script (.*): An unknown error occurred when fetching the script/i,
    /evaluating 'this.g.src'/i
  ],
  beforeSend(event, _) {
    if (!event.exception) {
      return event
    }

    // Catch Non-Error exceptions and grab __serialized__ info and send as error instead, null if none present
    const isNonErrorException = (event.exception?.values?.[0]?.value ?? '').startsWith('Non-Error exception captured')
    if (isNonErrorException) {
      if (!event.extra.__serialized__) {
        return null
      }
      let realErrMsg = (event.extra.__serialized__ as any)?.error
        ? (event.extra.__serialized__ as any)?.error.message
        : null
      realErrMsg = realErrMsg || (event.extra.__serialized__ as any)?.message
      event.exception.values[0].value = realErrMsg
      event.message = realErrMsg
    }
    return event
  }
})

export { Sentry }

import type { PouchDocument, Auth0User } from 'paintscout'
import { assignUser } from '../assigned-users'
import { removeAuth0Prefix } from '../../../users'

export function setOwner<T extends PouchDocument>({ doc, user }: { doc: T; user: Auth0User | null }) {
  return {
    ...(user
      ? assignUser({
          doc,
          userId: user.user_id,
          userName: `${user.user_metadata.firstName ?? ''} ${user.user_metadata.lastName ?? ''}`.trim(),
          removeUserId: doc.owner?.id
        })
      : doc),
    owner: user
      ? {
          id: removeAuth0Prefix(user.user_id),
          email: user.email,
          firstName: user.user_metadata.firstName,
          lastName: user.user_metadata.lastName,
          phoneNumber: user.user_metadata.phoneNumber,
          phoneExtension: user.user_metadata.phoneExtension,
          title: user.user_metadata.title
        }
      : null
  } as T
}

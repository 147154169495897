import { Badge, makeStyles } from '@material-ui/core'
import type { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip'
import { default as MuiTooltip } from '@material-ui/core/Tooltip'
import type { Theme } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import type { StyleClasses } from '@ui/core/theme'
import React, { useEffect, useState } from 'react'
import Typography from '../Typography'

const useStyles = makeStyles<Theme, TooltipProps>(
  (theme) => ({
    root: {},
    icon: {
      width: theme.typography.pxToRem(15),
      marginLeft: theme.typography.pxToRem(15),
      color: theme.palette.primary.main
    },
    popper: {
      opacity: 1
    },
    htmlTooltip: {
      backgroundColor: '#fff',
      opacity: 1,
      color: 'rgba(0, 0, 0, 1)',
      maxWidth: 350,
      border: '1px solid #dadde9',
      padding: 10,
      '& b': {
        fontWeight: theme.typography.fontWeightMedium
      },
      '& em': {
        fontStyle: 'italic'
      }
    },
    badgeRoot: {},
    badge: (props) => ({
      top: props?.badgeTop || '50%',
      right: props?.badgeRight || -5,
      marginTop: -1
    })
  }),
  { name: 'Tooltip' }
)

export interface TooltipProps extends Omit<MuiTooltipProps, 'title' | 'content' | 'children'> {
  children?: React.ReactElement<any, any>
  classes?: StyleClasses<typeof useStyles>
  title?: string
  content?: string | React.ReactNode
  component?: React.ReactNode
  timeout?: number
  autoOpen?: boolean
  icon?: React.ReactNode
  hideIcon?: boolean
  interactive?: boolean
  badgeTop?: string
  badgeRight?: string
}

function Tooltip(props: TooltipProps) {
  const classes = useStyles(props)
  const { children, component, placement, title, content, hideIcon, autoOpen, interactive } = props
  const [openTooltip, setOpenTooltip] = useState<boolean>(false)

  useEffect(() => {
    if (autoOpen) {
      setTimeout(
        () => {
          setOpenTooltip(true)
          setTimeout(() => {
            setOpenTooltip(false)
          }, 5000)
        },
        props.timeout ? props.timeout : 5000
      )
    }
  }, [])

  const handleMouseEnter = () => {
    setOpenTooltip(true)
  }

  const handleMouseOut = () => {
    setOpenTooltip(false)
  }

  const tooltipContent = (
    <>
      {title && <Typography variant={'h4'}>{title}</Typography>}
      {content && <Typography variant={'body2'}>{content}</Typography>}
    </>
  )

  const icon = props.icon ? props.icon : hideIcon ? null : <HelpIcon className={classes.icon} />

  return (
    <>
      {autoOpen ? (
        <div onMouseEnter={handleMouseEnter.bind(this)} onMouseLeave={interactive ? null : handleMouseOut.bind(this)}>
          <MuiTooltip
            title={tooltipContent}
            open={openTooltip}
            placement={placement}
            classes={{ tooltip: classes.htmlTooltip, popper: classes.popper }}
          >
            {component && !children ? (
              <Badge
                badgeContent={icon}
                onClick={() => {
                  setOpenTooltip((toolTipValue) => !toolTipValue)
                }}
                classes={{ root: classes.badgeRoot, badge: classes.badge }}
              >
                {component}
              </Badge>
            ) : (
              <Badge badgeContent={icon} classes={{ root: classes.badgeRoot, badge: classes.badge }}>
                {children}
              </Badge>
            )}
          </MuiTooltip>
        </div>
      ) : (
        <MuiTooltip
          title={tooltipContent}
          placement={placement}
          classes={{ tooltip: classes.htmlTooltip, popper: classes.popper }}
          interactive={interactive}
        >
          {component && !children ? (
            <>{component}</>
          ) : (
            <Badge badgeContent={icon} classes={{ root: classes.badgeRoot, badge: classes.badge }}>
              <div>{children}</div>
            </Badge>
          )}
        </MuiTooltip>
      )}
    </>
  )
}

export default Tooltip

import { isHtml } from '../isHtml'
import striptags from 'striptags'

export interface MakeHtmlOptions {
  wrap?: string[]
  replaceBreaks?: boolean
}

export function makeHtml(input: string, options?: MakeHtmlOptions): string {
  if (!input) {
    return ''
  }
  const wrapTags = options?.wrap ?? ['p']
  wrapTags.reverse()

  if (!striptags(input).trim()) {
    return ''
  }

  if (isHtml(input)) {
    return input.trim()
  } else {
    const replaced = options?.replaceBreaks ? input.trim().replace(/(\r\n|\n|\r)/gm, '<br />') : input.trim()
    return wrap(replaced, wrapTags.reverse())
  }
}

function wrap(input: string, tags?: string[]): string {
  if (!tags || tags.length === 0) {
    return input
  }

  return `<${tags[0]}>${wrap(input, tags.slice(1))}</${tags[0]}>`
}

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { EditItemFilesDialog } from '../../../dialogs'

import { connect, Field } from 'formik'

import type { QuoteFile } from 'paintscout'

import { Button, Grid, FormikInputField, FormikDropdownSelect, FormikCheckbox, useDialogs } from '@ui/paintscout'

const columnOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 }
]

const GalleryForm = ({ formik, ..._props }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { openDialog, dismissDialog } = useDialogs()
  const { section } = formik.values
  const files = (section?.fields?.files ?? []) as QuoteFile[]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Field component={FormikInputField} label={'Title'} fullWidth={true} name={'section.title'} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="flex-end" justifyContent="space-between" spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  component={FormikDropdownSelect}
                  options={columnOptions}
                  name={'section.fields.columns'}
                  fullWidth={true}
                  label="Columns"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field component={FormikCheckbox} label="Border around images" name="section.fields.showBorder" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <Button onClick={handleEditGallery}>Add/Remove Images</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  function handleEditGallery(ev: React.MouseEvent, index?: number) {
    openDialog(EditItemFilesDialog, {
      files,
      index,
      onDelete: (key: string) => {
        formik.setValues({
          section: {
            ...section,
            fields: {
              ...section.fields,
              files: (section?.fields?.files ?? []).filter((file) => file?.key !== key)
            }
          }
        })
      },
      onConfirm: (newFiles: QuoteFile[], _deletedFiles?: QuoteFile[]) => {
        formik.setValues({
          section: {
            ...section,
            fields: {
              ...section.fields,
              files: newFiles
            }
          }
        })
        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(GalleryForm)

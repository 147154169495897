import React from 'react'
import type { CheckboxProps } from '../Checkbox'
import Checkbox from '../Checkbox'
import { ConfirmationDialog } from '../dialogs'
import { useDialogs } from '../DialogStack'

export interface ConfirmationCheckboxProps extends Omit<CheckboxProps, 'classes'> {
  dialogTitle: string
  dialogMessage: string
  direction: 'dangerousToUncheck' | 'dangerousToCheck' | 'both'
  customOnChange: (ev: React.ChangeEvent<HTMLInputElement>, value: boolean) => void
}

function ConfirmationCheckbox({
  dialogTitle,
  dialogMessage,
  customOnChange = () => {},
  direction,
  ...props
}: ConfirmationCheckboxProps) {
  const { openDialog, dismissDialog } = useDialogs()

  function handleConfirm(ev: React.ChangeEvent<HTMLInputElement>, value: boolean) {
    ev.persist()
    // value is what the onChange boolean is going to, not coming from
    if ((direction === 'dangerousToUncheck' && value) || (direction === 'dangerousToCheck' && !value)) {
      return customOnChange(ev, value)
    }
    openDialog(ConfirmationDialog, {
      title: dialogTitle,
      message: dialogMessage,
      onConfirm: () => {
        customOnChange(ev, value)
        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  return (
    <Checkbox
      onChange={(ev: React.ChangeEvent<HTMLInputElement>, value: boolean) => handleConfirm(ev, value)}
      {...props}
    />
  )
}
export default ConfirmationCheckbox

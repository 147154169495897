import React from 'react'
import * as ReactDOM from 'react-dom'
import Snackbar from '@material-ui/core/Snackbar'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import HorizontalScroller from '../HorizontalScroller'
import type { BulkActionButtonProps } from '../BulkActionButton'
import BulkActionButton from '../BulkActionButton'
import { Slide } from '@material-ui/core'
import classNames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      bottom: 0
    },
    belowBackdrop: {
      zIndex: 1300
    },
    snackbar: {
      height: theme.typography.pxToRem(60),
      width: '100vw',
      background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
      color: theme.palette.primary.contrastText
    },
    content: {
      margin: '0 auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'row' as const,
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '80vw',
      [theme.breakpoints.down('md')]: {
        width: '100vw'
      }
    },
    selectedLabelWrapper: {
      flexGrow: 1,
      width: theme.typography.pxToRem(175),
      display: 'flex',
      justifyContent: 'flex-start',
      '@media (max-width: 599px)': {
        display: 'none'
      }
    },
    selectedLabel: {
      ...theme.typography.body1,
      color: 'white',
      margin: `0 ${theme.spacing()}`,
      padding: `0 ${theme.spacing()}`
    },
    horizontalScroller: {
      flexGrow: 2,
      overflowX: 'hidden'
    },
    scrollButton: {
      color: theme.palette.primary.contrastText
    },
    horizontalScrollerContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row' as const,
      alignItems: 'center',
      // justifyContent as 'center' will cause the scroller to not show all elements on small screens
      // so using margin to center works when we use a div inside of HoriztonalScroller
      margin: '0 auto'
    },
    deselectWrapper: {
      flexGrow: 1,
      width: theme.typography.pxToRem(175),
      display: 'flex',
      justifyContent: 'flex-end',
      '@media (max-width: 599px)': {
        display: 'none'
      }
    }
  })

export interface BulkActionBarProps extends WithStyles<typeof styles> {
  itemName: string
  pluralItemName?: string // override the default determined pluralItemName
  selectedItems?: any[]
  onDeselectAll?: () => void
  children?: Array<React.ReactElement<BulkActionButtonProps>> | React.ReactElement<BulkActionButtonProps>
  aboveBackdrop?: boolean
}

class BulkActionBar extends React.Component<BulkActionBarProps> {
  /**
   * A div is appended to the body for the Snackbar to render inside
   */
  public rootDiv: HTMLDivElement

  constructor(props: BulkActionBarProps) {
    super(props)
    this.rootDiv = document.createElement('div')
  }

  public componentDidMount() {
    document.getElementsByTagName('body')[0].appendChild(this.rootDiv)
  }

  public componentWillUnmount() {
    document.getElementsByTagName('body')[0].removeChild(this.rootDiv)
  }

  public render() {
    const { classes, selectedItems, itemName, pluralItemName, onDeselectAll, aboveBackdrop } = this.props

    const actionButtons = React.Children.map(
      this.props.children,
      (child: React.ReactElement<BulkActionButtonProps>) =>
        child &&
        React.cloneElement(child, {
          onClick: this.onActionButtonClick.bind(this, child)
        })
    )

    // ensure the intercom button appears behind the BulkActionBar
    const openIntercom = document.querySelector<HTMLElement>('#intercom-container > div > iframe')
    const closedIntercom = document.querySelector<HTMLElement>('body > div.intercom-lightweight-app')
    if (openIntercom) {
      openIntercom.style.zIndex = '1299'
    }
    if (closedIntercom) {
      closedIntercom.style.zIndex = '1299'
    }

    return ReactDOM.createPortal(
      <Snackbar
        classes={{
          root: classNames({
            [classes.root]: true,
            [classes.belowBackdrop]: !aboveBackdrop
          })
        }}
        open={selectedItems && selectedItems.length > 0}
        TransitionComponent={Slide}
      >
        <div className={classes.snackbar}>
          <div className={classes.content}>
            <div className={classes.selectedLabelWrapper}>
              <div className={classes.selectedLabel}>
                {this.getSelectedLabel(selectedItems, itemName, pluralItemName)}
              </div>
            </div>
            <HorizontalScroller classes={{ root: classes.horizontalScroller, scrollButton: classes.scrollButton }}>
              <div className={classes.horizontalScrollerContent}>{actionButtons}</div>
            </HorizontalScroller>
            <div className={classes.deselectWrapper}>
              <BulkActionButton onClick={onDeselectAll}>Deselect All</BulkActionButton>
            </div>
          </div>
        </div>
      </Snackbar>,
      this.rootDiv
    )
  }

  private onActionButtonClick = (child: React.ReactElement<BulkActionButtonProps>) => {
    if (child && child.props && child.props.onClick) {
      child.props.onClick(this.props.selectedItems)
    }
  }

  private getSelectedLabel(selectedItems: any[], itemName: string, pluralItemName?: string) {
    const numSelected = selectedItems ? selectedItems.length : 0
    let plural = pluralItemName

    if (!pluralItemName) {
      plural = itemName.toLowerCase().substring(itemName.length) !== 's' ? `${itemName}s` : `${itemName}'`
    }

    return `${numSelected} ${numSelected === 1 ? itemName : plural} selected`
  }
}

export default withStyles(styles)(BulkActionBar)

import type { QuoteTotals } from 'paintscout'

export * from './calculate-after-tax'
export * from './calculate-amount-paid'
export * from './calculate-balance-due'
export * from './sub-total'
export * from './total-adjustment'
export * from './quote-totals'

export const DEFAULT_TOTALS: QuoteTotals = {
  groups: {},
  additionalWork: {
    hours: 0,
    prep: 0,
    price: 0,
    materials: 0
  },
  pending: {
    hours: 0,
    prep: 0,
    price: 0,
    materials: 0
  },
  addedOptions: {
    hours: 0,
    prep: 0,
    price: 0,
    materials: 0
  },
  groupedItems: {
    hours: 0,
    prep: 0,
    price: 0,
    materials: 0
  },
  line_items: {
    hours: 0,
    prep: 0,
    price: 0,
    materials: 0
  },
  dimensions: {
    lnft: 0,
    sqft: 0,
    sqft_walls: 0,
    sqft_ceiling: 0,
    sqft_floor: 0
  },
  after_adjustment: 0,
  after_discount: 0,
  after_tax: 0,
  amount_paid: 0,
  balance_due: 0,
  hours: 0,
  prep: 0,
  price: 0,
  total_discount: 0,
  total_tax: 0,
  materials: 0,
  service_fee_hidden: 0
}

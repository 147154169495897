import { makeStyles } from '@material-ui/core/styles'
import { getPresentationContentStyles } from '../styles'
import type { Theme } from '@material-ui/core'
import type { EditorProps } from './Editor'

export const useEditorStyles = makeStyles<Theme, EditorProps>((theme) => {
  return {
    editorContent: (props) => ({
      cursor: props.disabled ? 'auto' : 'text'
    }),
    root: (props) => ({
      ...(props.label && { marginTop: theme.spacing(1) }),
      ...getPresentationContentStyles(theme, { noMargins: ['li'] }),
      position: 'relative',
      width: '100%',
      border: `1px solid ${theme.palette.grey[500]}`,
      display: 'flex',
      flexDirection: 'column',
      '& input': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        '&:focus-visible': {
          outline: 'none !important'
        }
      },
      minHeight: 120,
      ...(props.singleLine && {
        minHeight: 0
      }),
      '&:focus-within': {
        outline: `1px solid ${!props.disabled ? theme.palette.primary.dark : theme.palette.grey[500]}`,
        borderColor: (props: EditorProps) => (!props.disabled ? theme.palette.primary.dark : theme.palette.grey[500]),
        '& #menu-bar': {
          position: 'sticky',
          zIndex: 2
        }
      },

      '& .ProseMirror': {
        opacity: (props: EditorProps) => (props.disabled ? 0.5 : 1),
        outline: 'none',
        padding: theme.size['2'],
        fontSize: theme.typography.fontSize,
        display: 'block',
        flexGrow: 1,
        maxWidth: '100%',
        overflowWrap: 'break-word',
        '&:hover': {
          cusrsor: 'text'
        },
        '& .ProseMirror-trailingBreak': {
          display: 'none'
        }
      },
      '& .ProseMirror p': {
        whiteSpace: 'break-spaces'
      },
      '& .template-tag': {
        paddingLeft: theme.size[1],
        paddingRight: theme.size[1],
        paddingTop: theme.size['0.5'],
        paddingBottom: theme.size['0.5'],
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: theme.palette.grey[300],
        borderRadius: theme.borderRadius.md
      },
      // this and all the flexGrow stuff is so the ProseMirror (clickable/editable area) is the full height of root (adjustable by props)
      // hacky solution as there is an aria inserted div by tiptap that needs to be set to flex/flexGrow 1 but can't be targeted
      '& div': {
        display: 'flex'
        // flexGrow: 1
      }
    }),
    noDisplay: {
      '&$noDisplay': {
        display: 'none'
      }
    }
  }
})

import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { TableCellProps } from '@ui/paintscout'
import { TableCell, TableHeadContext } from '@ui/paintscout'
import classnames from 'classnames'

export interface ItemTableCellProps extends TableCellProps {
  /**
   * If true, will disable drag & drop for this column
   */
  cancelDrag?: boolean
  className?: string
}

const useStyles = makeStyles<Theme, ItemTableCellProps & { isHead: boolean }>(
  (theme) => ({
    root: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
      verticalAlign: ({ valign, isHead }) => (valign ? valign : isHead ? 'middle' : 'top')
    }
  }),
  {
    name: 'ItemTableCell'
  }
)

export default function ItemTableCell({ cancelDrag, ...props }: ItemTableCellProps) {
  const { isHead } = useContext(TableHeadContext)
  const classes = useStyles({ ...props, isHead })

  return (
    <TableCell
      {...props}
      classes={classes}
      component={isHead ? 'th' : 'td'}
      className={classnames(props.className, {
        'no-drag': !!cancelDrag
      })}
    />
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import Button from '@ui/paintscout/src/Button'

const useStyles = makeStyles<Theme, SWLinkProps>(
  () => ({
    root: {
      fontFamily: 'CustomFont-SW',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '1.5px',

      display: 'inline-flex',
      padding: '14px 24px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      borderRadius: '2px'
    },
    dark: {
      background: '#2F2F30',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#454545'
      }
    },
    light: {
      background: '#FFFFFF',
      color: '#2F2F30',
      '&:hover': {
        backgroundColor: '#e3e3e3'
      }
    },
    social: {
      borderRadius: '2px',
      border: '2.5px solid var(--Light-FG-on-Surface, #2F2F30)',
      background: '#FFFFFF',
      color: '#2F2F30',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      }
    }
  }),
  { name: 'Button' }
)

export interface SWLinkProps {
  href?: string
  label?: string
  theme?: 'dark' | 'light' | 'social'
  style?: React.CSSProperties
  className?: string
}

function Link(props: SWLinkProps) {
  const { style, href, label, className, theme } = props
  const classes = useStyles(props)

  return (
    <a className={className} href={href} target="_blank" rel="noreferrer">
      <Button
        classes={{
          ...classes,
          root: `${classes.root} ${classes[theme]}`
        }}
        style={style}
      >
        {label}
      </Button>
    </a>
  )
}

export default Link

import type { EventType, EventParams } from 'paintscout'
import { useMutation } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'

import { gql } from 'apollo-boost'

export const PROCESS_EVENT = gql`
  mutation processEvent($type: String!, $provider: String, $async: Boolean, $params: JSON) {
    processEvent(type: $type, provider: $provider, async: $async, params: $params) {
      provider
      result
      error
    }
  }
`

export interface ProcessEventResponse {
  processEvent: {
    provider: string
    error: string
    result: any
  }
}

export function useProcessEvent() {
  const [processEventMutation] = useMutation<ProcessEventResponse>(PROCESS_EVENT, {
    fetchPolicy: 'no-cache'
  })

  return {
    processEvent: async ({
      type,
      provider,
      async,
      params
    }: {
      type: EventType
      provider: string
      async?: boolean
      params: EventParams
    }) => {
      const { data, errors } = await processEventMutation({
        variables: {
          type,
          provider,
          async,
          params
        }
      })

      if (errors) {
        console.log(errors)
        Sentry.captureException(new Error('processEvent returned an error'))
      }

      const errorMessage =
        typeof data?.processEvent?.error === 'string'
          ? data?.processEvent?.error
          : (data?.processEvent?.error as any)?.message

      return data?.processEvent
        ? {
            ...data.processEvent,
            error: errorMessage
          }
        : null
    }
  }
}

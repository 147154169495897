import React from 'react'
import Button from '../Button'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { QuoteItemSection } from '@paintscout/util/builder'
import AddIcon from '@material-ui/icons/AddCircle'
import RemoveIcon from '@material-ui/icons/RemoveCircle'

const useStyles = makeStyles<Theme, { padTop?: boolean }>((_theme: Theme) => ({
  root: {
    // silly hack - we need padding 0 but the button's edges get cut off if we do
    padding: '0px 2px 2px 2px',
    margin: ({ padTop }) => `${padTop ? 4 : 0}px -2px 0px -2px !important`,
    height: 'fit-content'
  }
}))

export interface OptionsApprovalButtonProps {
  section: QuoteItemSection
  padTop?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default function OptionsApprovalButton({ onClick, section, padTop }: OptionsApprovalButtonProps) {
  const classes = useStyles({ padTop })

  return (
    <Button
      className={classes.root}
      variant="hoverUnderline"
      size="small"
      icon={section === 'added-options' ? RemoveIcon : AddIcon}
      onClick={onClick}
    >
      {section === 'added-options' ? 'Remove' : 'Add Option'}
    </Button>
  )
}

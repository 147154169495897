import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { Grid, Typography } from '@ui/paintscout'
import type { ColorTileProps } from '../ColorTile'
import ColorTile from '../ColorTile'

const useStyles = makeStyles<Theme, ContentFeatureProps>(
  (theme) => ({
    root: {
      padding: '40px',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column-reverse'
      }
    },
    content: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      [theme.breakpoints.down('lg')]: {
        alignItems: 'center'
      },
      [theme.breakpoints.up('xl')]: {
        marginBottom: '100px'
      }
    },
    cta: {
      marginTop: '10px',
      width: '75%',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center'
      }
    },
    colorTiles: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    title: {
      fontFamily: 'CustomFont-SW',
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '-1.5px',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        padding: '0 18px'
      }
    },
    description: {
      fontFamily: 'CustomFont-SW',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.5px'
    }
  }),
  { name: 'ContentFeature' }
)

export interface ContentFeatureProps {
  title: React.ReactNode
  description: React.ReactNode
  cta: React.ReactNode
  colorTileA: ColorTileProps
  colorTileB: ColorTileProps
  image: string
  reverseOrder?: boolean
}

function ContentFeature(props: ContentFeatureProps) {
  const classes = useStyles(props)

  const { title, description, cta, colorTileA, colorTileB, image, reverseOrder } = props

  return (
    <Grid
      container
      className={classes.root}
      spacing={2}
      direction={reverseOrder ? 'row-reverse' : 'row'}
      justifyContent="space-between"
    >
      <Grid item container lg={5} className={classes.content} direction="column">
        <Grid item>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        </Grid>
        <Grid item container className={classes.cta} spacing={1}>
          {cta}
        </Grid>
      </Grid>
      <Grid item container lg={2} className={classes.colorTiles} spacing={1}>
        <Grid item>
          <ColorTile
            color={colorTileA.color}
            colorCode={colorTileA.colorCode}
            colorName={colorTileA.colorName}
            darkText={colorTileA.darkText}
          />
        </Grid>
        <Grid item>
          <ColorTile
            color={colorTileB.color}
            colorCode={colorTileB.colorCode}
            colorName={colorTileB.colorName}
            darkText={colorTileB.darkText}
          />
        </Grid>
      </Grid>
      <Grid item lg={5}>
        <img src={image} style={{ maxWidth: '100%' }} />
      </Grid>
    </Grid>
  )
}

export default ContentFeature

import type { OptionsDocument } from 'paintscout'
import type { QuoteTag } from '..'

export function getQuoteTags(args: { options: OptionsDocument }): QuoteTag[] {
  const { options } = args
  const { quoteTags } = options.options

  if (!quoteTags) {
    return []
  }

  return quoteTags.order
    .map((orderItem) => {
      const item = quoteTags.values[orderItem]
      if (!item) {
        return null
      }
      return {
        name: orderItem,
        label: item.label,
        color: item.color
      }
    })
    .filter((item) => item)
}

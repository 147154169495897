import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { AlertDialogActions } from '@ui/paintscout'
import { Alert, AlertDialog, Button, LearnMoreLink, Typography, useClientOptions, useUser } from '@ui/paintscout'
import type { ReactNode } from 'react'
import React, { useContext, useState } from 'react'
import { formatCurrency, getObjectLabels } from '@paintscout/util/builder'
import { Grid, useDialogs } from '@ui/core'
import { QuoteContext } from '../../context'
import type { LoanApplicationStatus, SignupStatus } from '..'
import { WisetackStatus } from '..'
import { useWisetack } from '..'
import WisetackLearnMore from '../WisetackLearnMore'
import startCase from 'lodash/startCase'

const useStyles = makeStyles((theme: Theme) => ({
  dialogLogo: {
    width: theme.typography.pxToRem(120),
    objectFit: 'contain',
    margin: theme.spacing(1, 0)
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  root: {
    background: theme.palette.grey['100'],
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '@media print': {
      display: 'none'
    }
  },
  logo: {
    margin: theme.spacing(1, 0),
    width: theme.typography.pxToRem(120),
    objectFit: 'contain'
  },
  content: {
    paddingRight: theme.spacing(1)
  },
  learnMore: {
    padding: 0
  },
  alerts: {
    marginTop: theme.spacing(3)
  },
  applicationButton: {
    padding: 0
  }
}))

export default function WisetackApplication({
  inPresentation,
  inCustomerView
}: {
  inPresentation?: boolean
  inCustomerView?: boolean
}) {
  const classes = useStyles()
  const { options } = useClientOptions()
  const { user, preferences } = useUser()
  const { quote, save: saveQuote, wisetackPromo } = useContext(QuoteContext)
  const { openDialog, dismissDialog } = useDialogs()
  const objectLabels = getObjectLabels({ options, quote, invoice: quote.is_invoice })

  const contact = quote.contact

  const {
    hasWisetack,
    merchantSignupDate,
    showOnOldDocuments,
    maximumLoanAmount,
    handleTextLoanApplication,
    handleGetLoanApplication,
    handleSignup
  } = useWisetack({
    user,
    options,
    preferences
  })

  // Don't show wisetack stuff on estimates/invoices made before the merchant signed up unless overridden\
  // Or if something went wrong getting the promo
  if (
    !wisetackPromo ||
    !merchantSignupDate ||
    (merchantSignupDate.isAfter(quote?.dates?.created) && !showOnOldDocuments)
  ) {
    return null
  }

  const getContactName = () => {
    if (contact?.first_name && contact?.last_name) {
      return startCase(`${contact.first_name} ${contact.last_name}`)
    } else if (contact?.first_name) {
      return startCase(contact?.first_name)
    } else {
      return 'Your Customer'
    }
  }

  const handleLearnMoreMerchant = (actions?: AlertDialogActions) => {
    openDialog(
      AlertDialog,
      {
        title: '',
        message: <WisetackLearnMore wisetackPromo={wisetackPromo} isCustomerView={inCustomerView} />,
        onConfirm: dismissDialog,
        actions: actions?.length ? actions : null,
        icon: (
          <img
            className={classes.dialogLogo}
            src={
              'https://res.cloudinary.com/taptapquote/image/upload/v1663601481/paintscout/providers/wisetack-alternate.png'
            }
            alt={'Wisetack'}
          />
        )
      },
      {
        replace: true
      }
    )
  }

  const handleLoanMerchant = async () => {
    if (!!saveQuote && !quote._rev && !!quote.contact) {
      await saveQuote(quote).then(handleTextLoanApplication)
    } else {
      handleTextLoanApplication(quote)
    }
  }

  const handleLearnMoreCustomer = () => {
    window.open('https://www.wisetack.com/consumers', '_blank')
  }

  const handleLoanCustomer = async () => {
    if (!inCustomerView) {
      return
    }
    handleLearnMoreMerchant([
      {
        left: true,
        variant: 'text',
        label: 'cancel',
        onClick: dismissDialog
      },
      {
        label: getButtonLabel(),
        onClick: () => {
          handleGetLoanApplication(quote)
        }
      }
    ])
  }

  const getButtonLabel = () => {
    if (!inPresentation) {
      return 'Send Application'
    }

    if (loanStatus === undefined) {
      return 'See options'
    }

    if (
      loanStatus === 'SETTLED' ||
      (approvedAmount && approvedAmount > quote?.totals?.balance_due && payouts?.length < 2)
    ) {
      return 'Apply Again'
    }

    if (loanStatus === 'LOAN TERMS ACCEPTED') {
      return 'Release Funds'
    }

    return 'Continue Application'
  }

  const getAlertContent = () => {
    const wisetackPayments = quote?.payments?.filter((payment) => payment.type === 'Wisetack')
    const amountFinanced = wisetackPayments?.reduce((acc, payment) => acc + payment.amount, 0) || 0
    const totalFinanceAmount = Math.min(
      parseFloat((quote.totals.balance_due + amountFinanced).toFixed(2)),
      maximumLoanAmount
    )

    const alerts = []

    if (totalFinanceAmount > maximumLoanAmount) {
      if (inPresentation) {
        alerts.push(
          `The total amount financed cannot exceed ${formatCurrency({ options, value: maximumLoanAmount })}. You will have to cover the remainder with another payment method.`
        )
      } else {
        alerts.push(
          `${getContactName()}'s total amount financed cannot exceed ${formatCurrency({ options, value: maximumLoanAmount })}. They will have to cover the remainder with another payment method.`
        )
      }
    }
    if (approvedAmount && approvedAmount > totalFinanceAmount) {
      if (inPresentation) {
        alerts.push(
          `Your financing application was previously approved for more than the current balance due on the ${objectLabels.quote.value.toLowerCase()}. Please apply again to finance this purchase.`
        )
      } else {
        alerts.push(
          `${getContactName()}'s financing application was previously approved for more than the current balance due on the ${objectLabels.quote.value.toLowerCase()}. They will need to apply for financing for the new balance due amount on the ${objectLabels.quote.value.toLowerCase()}.`
        )
      }
    }
    if (approvedAmount && approvedAmount < totalFinanceAmount) {
      if (inPresentation) {
        alerts.push(
          `Your approved financing amount is less than the balance due. You'll need to pay the difference with another payment method.`
        )
      } else {
        alerts.push(
          `${getContactName()}'s approved financing amount is less than the balance due. They'll need to pay the difference with another payment method.`
        )
      }
    } else if (maximumAmount && maximumAmount < totalFinanceAmount) {
      if (inPresentation) {
        alerts.push(
          `Your maximum qualified amount is less than the balance due. You'll need to pay the difference with another payment method.`
        )
      } else {
        alerts.push(
          `${getContactName()}'s maximum qualified amount is less than the balance due. They'll need to pay the difference with another payment method.`
        )
      }
    }
    return alerts
  }

  const loanStatus = contact?.wisetackLoanApplication?.changedStatus as LoanApplicationStatus | undefined
  const maximumAmount = contact?.wisetackLoanApplication?.maximumAmount
  const approvedAmount = contact?.wisetackLoanApplication?.approvedLoanAmount
  const payouts = contact?.wisetackLoanApplication?.payouts

  const merchantTitle = 'Win more jobs by offering low monthly payments'
  const customerTitle = 'Pay over time with Wisetack'

  const getBody = () => {
    const contactName = getContactName()
    const merchantBody = `Send ${getContactName()} a financing application to see how much they qualify for.`
    if (!hasWisetack) {
      return merchantBody + ' Applications take less than 5 minutes to complete.'
    }
    if (inPresentation) {
      if (approvedAmount) {
        return `You have locked in financing for ${formatCurrency({
          options,
          value: approvedAmount
        })}!`
      }
      if (maximumAmount) {
        return `You are qualified for up to ${formatCurrency({
          options,
          value: maximumAmount
        })}.`
      }
      return 'See your options in under 60 seconds with no impact to your credit score'
    }
    if (approvedAmount) {
      return `${contactName} has locked in financing for ${formatCurrency({
        options,
        value: approvedAmount
      })}!`
    }
    if (approvedAmount) {
      return `${contactName} has locked in financing worth ${formatCurrency({
        options,
        value: approvedAmount
      })}!`
    }
    if (maximumAmount) {
      return `${contactName} is qualified for up to ${formatCurrency({
        options,
        value: maximumAmount
      })}.`
    }
    return merchantBody
  }

  //signup
  if (!hasWisetack && !inPresentation) {
    const signupStatus = options.options.wisetackSignup?.status as SignupStatus | undefined

    if (signupStatus === 'APPLICATION_DECLINED') {
      return null
    }

    const getSignupButtonLabel = () => {
      if (!signupStatus) {
        return 'Sign Up Now'
      }
      switch (signupStatus) {
        case 'PENDING':
        case 'INITIATOR_ADDED':
        case 'BUSINESS_ADDED':
        case 'OWNERS_ADDED':
        case 'EXECUTIVE_ADDED':
          return 'Complete Application'
        default:
          startCase(signupStatus.replace('_', ' ').toLowerCase())
      }
    }

    return (
      <BaseApplication
        handleApply={() => handleSignup(options)}
        hideButton={signupStatus === 'APPLICATION_APPROVED'}
        buttonLabel={getSignupButtonLabel()}
        Status={signupStatus ? <WisetackStatus hideIcon variant="signup" status={signupStatus} /> : null}
        title={merchantTitle}
        body={getBody()}
      />
    )
  }

  // merchant financing
  if (hasWisetack && !inPresentation) {
    if (!quote?.totals?.balance_due || quote.totals.balance_due < 500) {
      return null
    }
    return (
      <BaseApplication
        handleApply={handleLoanMerchant}
        handleLearnMore={handleLearnMoreMerchant}
        hideButton={loanStatus === 'LOAN CONFIRMED'}
        buttonLabel={getButtonLabel()}
        title={merchantTitle}
        body={getBody()}
        Status={
          loanStatus ? (
            <WisetackStatus hideIcon variant="loanApplication" status={loanStatus} amount={approvedAmount} />
          ) : null
        }
        alertContent={getAlertContent()}
      />
    )
  }

  // customer financing
  if (hasWisetack && inPresentation) {
    if (!quote?.totals?.balance_due || quote.totals.balance_due < 500) {
      return null
    }
    if (!quote?.contact) {
      return null
    }

    return (
      <BaseApplication
        handleApply={handleLoanCustomer}
        hideButton={loanStatus === 'LOAN CONFIRMED'}
        buttonLabel={getButtonLabel()}
        title={customerTitle}
        body={getBody()}
        Status={loanStatus ? <WisetackStatus hideIcon variant="loanApplication" status={loanStatus} /> : null}
        alertContent={getAlertContent()}
        handleLearnMore={handleLearnMoreCustomer}
      />
    )
  }

  // customer view, wisetack is not set up
  return null
}

export interface WisetackApplicationProps {
  title: string
  body?: string
  alertContent?: string[]
  buttonLabel: string
  hideButton?: boolean
  Status?: ReactNode
  handleApply: () => Promise<void>
  handleLearnMore?: () => void
}

export function BaseApplication({
  title,
  body,
  buttonLabel,
  alertContent,
  Status,
  hideButton,
  handleApply,
  handleLearnMore
}: WisetackApplicationProps) {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  async function onApply() {
    setLoading(true)
    await handleApply()
    setLoading(false)
  }

  return (
    <Grid container className={classes.root} justifyContent="space-between" alignItems="center">
      <Grid item>
        <img
          className={classes.logo}
          alt="Wisetack"
          src={'https://res.cloudinary.com/taptapquote/image/upload/v1663599658/paintscout/providers/wisetack.png'}
        />
      </Grid>
      <Grid item>{Status}</Grid>
      <Grid item xs={12}>
        <Typography variant="h3">
          <strong>{title}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        {!!body && (
          <Typography variant="body2">
            <strong className={classes.content}>{body}</strong>
            {!!handleLearnMore && (
              <LearnMoreLink id="allow-propagation" onClick={handleLearnMore} classes={{ root: classes.learnMore }} />
            )}
          </Typography>
        )}
      </Grid>
      {!hideButton && (
        <Grid item>
          <Button className={classes.applicationButton} loading={loading} onClick={onApply} variant="underline">
            {buttonLabel}
          </Button>
        </Grid>
      )}

      {alertContent?.length > 0 && (
        <div className={classes.alerts}>
          {alertContent.map((alert, index) => (
            <Grid key={index} item xs={12}>
              <Alert severity="warning">{alert}</Alert>
            </Grid>
          ))}
        </div>
      )}
    </Grid>
  )
}

import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4)
  }
}))

export interface IdleTimeoutProps {
  classes?: StyleClasses<typeof useStyles>
  onTimeout: () => void
  onResume: () => void
}

function IdleTimeout({ onTimeout, onResume, ...props }: IdleTimeoutProps) {
  const classes = useStyles(props)
  const [idleModal, setIdleModal] = useState(false)
  const idleTimeout = 1000 * 60 * 1 * 20 //20 mins
  // const idleTimeout = (1000 * 10 * 1)   //10 seconds
  let idleEvent

  // Add any other events listeners here
  const events = ['mousemove', 'click', 'keypress']

  // Reset the idle timer if the user triggers any of the events listed above
  const sessionTimeout = () => {
    if (idleEvent) clearTimeout(idleEvent)
    idleEvent = setTimeout(() => {
      setIdleModal(true)
      onTimeout()
    }, idleTimeout)
  }

  // Refresh page and start heartbeat, close dialog
  const extendSession = () => {
    onResume()
    setIdleModal(false)
    window.location.reload()
  }

  // Add and remove listeners
  useEffect(() => {
    for (const e in events) {
      window.addEventListener(events[e], sessionTimeout)
    }

    return () => {
      for (const e in events) {
        window.removeEventListener(events[e], sessionTimeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog open={idleModal}>
      <DialogTitle>Are you still there?</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1">To continue your session, please click the button below.</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} onClick={extendSession}>
          Keep viewing
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default IdleTimeout

import type { Context } from '../template'

/**
 * Shallow merges an array of Contexts
 *
 */
export function combineContexts(...contexts: Context[]): Context {
  return contexts.reduce((combinedContext, context) => {
    return {
      ...combinedContext,
      ...context
    }
  }, {})
}

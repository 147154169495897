import uuid from 'uuid/v4'
import type {
  Activity,
  MessageActivity,
  SendMessageMutation,
  ActivityQuote,
  ActivityContact,
  Owner,
  QuoteContact,
  Quote
} from '../generated/types'
import type { QuoteDocument } from 'paintscout'

/**
 * Sanitizes the message activity so it can be used as an optimisticResponse for sendMessage
 */
export function sendMessageOptimisticResponse(
  activity: MessageActivity,
  quote: QuoteDocument
): SendMessageMutation['sendMessage'] {
  const { message } = activity.details
  const baseActivity = createOptimisticQuoteActivity(activity)
  const baseQuote = createOptimisticQuote(quote)

  return {
    activity: {
      ...baseActivity,
      __typename: 'MessageActivity',
      details: {
        __typename: 'MessageActivityDetails',
        ...baseActivity.details,
        message: {
          __typename: 'ActivityMessage',
          deleted: null,
          ...message,
          from: {
            __typename: 'ActivityMessageFrom',
            ...message.from
          }
        }
      }
    },
    quote: {
      ...baseQuote
    }
  }
}

export function createOptimisticQuote(quote: QuoteDocument): Quote {
  const contact: QuoteContact = quote.contact
    ? {
        ...quote.contact,
        __typename: 'QuoteContact'
      }
    : null
  const secondaryContact: QuoteContact = quote.secondaryContact
    ? {
        ...quote.secondaryContact,
        __typename: 'QuoteContact'
      }
    : null
  return {
    _id: `__optimistic__${uuid()}`,
    ...quote,
    __typename: 'Quote',
    contact,
    secondaryContact
  }
}

export function createOptimisticQuoteActivity(activity: Activity): Activity {
  const owner: Owner = activity.details.owner
    ? {
        __typename: 'Owner',
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        title: null,
        ...activity.details.owner
      }
    : null

  const contact: ActivityContact = activity.details.contact
    ? {
        __typename: 'ActivityContact',
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        secondaryFirstName: null,
        secondaryLastName: null,
        ...activity.details.contact
      }
    : null

  const quote: ActivityQuote = activity.details.quote
    ? {
        __typename: 'ActivityQuote',
        id: null,
        number: null,
        status: null,
        total: null,
        error: null,
        ...activity.details.quote,
        int_ext: {
          __typename: 'QuoteType',
          value: null,
          label: null,
          ...activity.details.quote?.int_ext
        }
      }
    : null

  const invoice: ActivityQuote = activity.details.invoice
    ? {
        __typename: 'ActivityQuote',
        id: null,
        number: null,
        status: null,
        total: null,
        error: null,
        ...activity.details.invoice,
        int_ext: {
          __typename: 'QuoteType',
          value: null,
          label: null,
          ...activity.details.invoice?.int_ext
        }
      }
    : null

  return {
    _id: `__optimistic__${uuid()}`,
    ...activity,
    details: {
      url: null as string,
      updated: null as number,
      ...activity.details,
      owner,
      contact,
      quote,
      invoice
    }
  }
}

import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import type { Editor } from '@tiptap/react'

import { Menu, ListItemText, Tooltip } from '@material-ui/core'
import Typography from '../Typography'
import MenuItem from '../MenuItem'
import { TextSelection } from 'prosemirror-state'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import CheckIcon from '@material-ui/icons/Check'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatColorTextIcon from '@material-ui/icons/FormatColorText'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import HighlightIcon from '@material-ui/icons/BorderColor'
import TemplateIcon from '@material-ui/icons/More'

import type { EditorProps } from './Editor'
import { InlineColorPicker } from '../ColorPicker'
import { useClientOptions } from '../ClientOptionsProvider'
import { getFeature } from '@paintscout/util/builder'

export interface TipTapToolbarProps extends EditorProps {
  editor: Editor
}

const useStyles = makeStyles<Theme>((theme) => {
  return {
    tippyMenu: (props: TipTapToolbarProps) => ({
      pointerEvents: props.disabled ? 'none' : 'auto',
      opacity: props.disabled ? 0.5 : 1.0,
      // backgroundColor: theme.palette.grey[300],
      backgroundColor: theme.palette.common.white,
      padding: 0,
      // borderRadius: theme.borderRadius.sm,
      overflow: 'hidden',
      display: 'flex',
      maxWidth: 180,
      flexWrap: 'wrap',
      '& button': {
        background: 'none',
        border: `none`,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        minWidth: theme.typography.pxToRem(30),
        display: 'flex',
        maxHeight: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0.5),
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[700],
        cursor: 'pointer',
        '& svg': {
          fontSize: '1rem'
        },
        '&:hover': {
          color: theme.palette.grey[900]
        },
        '&.is-active': {
          backgroundColor: theme.palette.grey[300]
        },
        '&.bold': {
          fontWeight: theme.typography.fontWeightBold
        },
        '&.italic': {
          fontWeight: theme.typography.fontWeightBold,
          fontStyle: 'italic'
        },
        '&.underline': {
          fontWeight: theme.typography.fontWeightBold,
          textDecoration: 'underline'
        }
      }
    }),
    tippyMenuBar: {
      position: 'sticky',
      zIndex: 2,
      '&$tippyMenuBar': {
        maxWidth: '100%',
        // maxHeight: theme.spacing(4),
        width: '100%',
        alignSelf: 'flex-start',
        boxShadow: theme.shadows[1]
      },
      '& $linkInput': {
        width: 'auto'
      }
    },
    linkInput: {
      width: '100%',
      border: 'none'
    },
    linkInputWrapper: {
      '&$linkInputWrapper': {
        display: 'flex',
        alignContent: 'flex-start',
        flexGrow: 0
      }
    }
  }
})

export function TipTapToolbar(props: TipTapToolbarProps) {
  const { editor, onReset, templateContext, disabled } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const showColorButtons = getFeature({ options, path: 'editorOptions.textColor' })

  const defaultToolbar = {
    headings: false,
    bold: true,
    italic: true,
    underline: true,
    color: true,
    highlight: true,
    align: false,
    lists: true,
    link: true,
    template: null
  }
  const toolbar = {
    ...defaultToolbar,
    ...props.toolbar
  }

  const tags = toolbar?.template?.tags || []
  const hrefInput = useRef(null)

  const [tagMenuAnchorEl, setTagMenuAnchorEl] = useState(null)

  const isActive = (...params) => {
    if (disabled) {
      return false
    }
    // eslint-disable-next-line prefer-spread
    return editor.isActive.apply(editor, params)
  }

  // Maintain current text align status to pass in on font change
  const alignmentOptions = {
    left: isActive({ textAlign: 'left' }),
    center: isActive({ textAlign: 'center' }),
    right: isActive({ textAlign: 'right' }),
    justify: isActive({ textAlign: 'justify' })
  }
  const currentTextAlign = Object.keys(alignmentOptions).filter((key) => alignmentOptions[key] === true)[0]
  const blockBlur = useRef<boolean>(false)
  const handleLinkSet = () => {
    // Check href sources to get most relevant
    let href = hrefInput.current?.value || editor?.getAttributes('link')?.href || 'https://example.com'
    if (!/^https?:\/\//i.test(href)) {
      href = 'https://' + href
    }

    // Set our link, update our cursor state appropriately and focus
    editor
      .chain()
      .focus()
      .setLink({ href: href })
      .command(({ tr, dispatch, state }) => {
        const { to } = state.selection
        if (to < tr.doc.content.size - 1) {
          // If there are characters after the selection, just move the cursor one space ahead
          const newSelection = TextSelection.create(tr.doc, to + 1)
          return dispatch(tr.setSelection(newSelection))
        } else {
          // If there are no characters after the selection, insert a space and move the cursor
          // escaping the hyperlink tags so we can keep typing
          dispatch(tr.insertText(' ', to, to + 1))
          const newSelection = TextSelection.create(tr.doc, to + 1)
          return dispatch(tr.setSelection(newSelection))
        }
      })
      .focus()
      .run()
    // Reset our hrefInput so we grey out href again
    hrefInput.current.value = ''
  }

  return (
    <div id={'menu-bar'} className={classnames([classes.tippyMenu, classes.tippyMenuBar, 'MenuBar'])}>
      {toolbar.bold && (
        <button
          type="button"
          onClick={(ev) => {
            ev.preventDefault()
            editor.chain().focus().toggleBold().run()
          }}
          className={isActive('bold') ? 'is-active bold' : 'bold'}
        >
          B
        </button>
      )}
      {toolbar.italic && (
        <button
          type="button"
          onClick={(ev) => {
            ev.preventDefault()
            editor.chain().focus().toggleItalic().run()
          }}
          className={isActive('italic') ? 'is-active italic' : 'italic'}
        >
          I
        </button>
      )}
      {toolbar.underline && (
        <button
          type="button"
          onClick={(ev) => {
            ev.preventDefault()
            editor.chain().focus().toggleUnderline().run()
          }}
          className={isActive('underline') ? 'is-active underline' : 'underline'}
        >
          U
        </button>
      )}
      {toolbar?.color && showColorButtons && (
        <InlineColorPicker
          swatchIcon={
            <button type="button">
              <FormatColorTextIcon style={{ color: editor.getAttributes('textStyle').color }} />
            </button>
          }
          swatchOnly
          value={editor.getAttributes('textStyle').color}
          onChange={(color: string) => {
            editor.chain().setColor(color).run()
          }}
        />
      )}
      {toolbar?.highlight && showColorButtons && (
        <InlineColorPicker
          swatchIcon={
            <button type="button">
              <HighlightIcon style={{ color: editor.getAttributes('highlight').color }} />
            </button>
          }
          swatchOnly
          value={editor.getAttributes('highlight').color}
          onChange={(color: string) => {
            editor.chain().setHighlight({ color }).run()
          }}
        />
      )}
      {toolbar.headings && (
        <>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().toggleHeading({ level: 1 }).setTextAlign(currentTextAlign).run()
            }}
            className={isActive('heading', { level: 1 }) ? 'is-active' : ''}
          >
            H1
          </button>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().toggleHeading({ level: 2 }).setTextAlign(currentTextAlign).run()
            }}
            className={isActive('heading', { level: 2 }) ? 'is-active' : ''}
          >
            H2
          </button>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().toggleHeading({ level: 3 }).setTextAlign(currentTextAlign).run()
            }}
            className={isActive('heading', { level: 3 }) ? 'is-active' : ''}
          >
            H3
          </button>
        </>
      )}
      {toolbar.align && (
        <>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().setTextAlign('left').run()
            }}
            className={isActive({ textAlign: 'left' }) ? 'is-active' : ''}
          >
            <FormatAlignLeftIcon />
          </button>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().setTextAlign('center').run()
            }}
            className={isActive({ textAlign: 'center' }) ? 'is-active' : ''}
          >
            <FormatAlignCenterIcon />
          </button>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().setTextAlign('right').run()
            }}
            className={isActive({ textAlign: 'right' }) ? 'is-active' : ''}
          >
            <FormatAlignRightIcon />
          </button>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().setTextAlign('justify').run()
            }}
            className={isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
          >
            <FormatAlignJustifyIcon />
          </button>
        </>
      )}
      {toolbar.lists && (
        <>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().toggleBulletList().run()
            }}
            className={isActive('bulletList') ? 'is-active' : ''}
          >
            <FormatListBulletedIcon />
          </button>
          <button
            type="button"
            onClick={(ev) => {
              ev.preventDefault()
              editor.chain().focus().toggleOrderedList().run()
            }}
            className={isActive('orderedList') ? 'is-active' : ''}
          >
            <FormatListNumberedIcon />
          </button>
        </>
      )}
      {toolbar.link && (
        <button
          type="button"
          onClick={(ev) => {
            ev.preventDefault()
            isActive('link')
              ? editor.chain().focus().unsetLink().run()
              : editor.chain().focus().toggleLink({ href: 'https://example.com' }).run()
          }}
          className={isActive('link') ? 'is-active' : ''}
        >
          {isActive('link') ? <LinkOffIcon /> : <LinkIcon />}
        </button>
      )}
      <div className={classes.linkInputWrapper}>
        {isActive('link') && (
          <>
            <input
              ref={hrefInput}
              placeholder={editor.getAttributes('link').href}
              className={classes.linkInput}
              type={'text'}
              onFocus={() => {
                if (!hrefInput?.current?.value) hrefInput.current.value = editor.getAttributes('link').href
              }}
              onBlur={() => {
                if (!blockBlur.current) {
                  handleLinkSet()
                } else {
                  blockBlur.current = false
                }
              }}
            />
            <button
              type="button"
              className={'set-button'}
              onMouseDown={(ev) => {
                ev.preventDefault()
              }}
              onClick={(ev) => {
                ev.preventDefault()
                blockBlur.current = true
                handleLinkSet()
              }}
            >
              <CheckIcon />
            </button>
          </>
        )}
      </div>
      {toolbar?.template?.tags && (
        <button
          type="button"
          onMouseDown={(ev) => {
            ev.preventDefault()
            setTagMenuAnchorEl(ev.currentTarget)
          }}
          className={isActive('orderedList') ? 'is-active' : ''}
        >
          <Tooltip title={'Insert Tag'}>
            <TemplateIcon />
          </Tooltip>
        </button>
      )}
      <Menu
        open={!!tagMenuAnchorEl}
        anchorEl={tagMenuAnchorEl}
        style={{ top: tagMenuAnchorEl?.offsetHeight }}
        onClose={() => setTagMenuAnchorEl(false)}
        // yes, we need all 3 props to maintain focus on the editor when this is opened...
        autoFocus={false}
        disableAutoFocus
        disableAutoFocusItem
        disableEnforceFocus
      >
        {tags.map((tag) => (
          <MenuItem
            key={tag.key}
            onMouseDown={(ev) => {
              ev.preventDefault()
              setTagMenuAnchorEl(false)

              // const range = editor.state.selection.ranges[0].$from.pos

              editor.chain().focus().insertContent(`${templateContext[tag.key]}`).run()
            }}
          >
            <ListItemText primary={tag.label} secondary={!tag.hideValue && (templateContext[tag.key] as string)} />
          </MenuItem>
        ))}
      </Menu>
      {!!onReset && (
        <button
          type="button"
          onMouseDown={(ev) => {
            ev.preventDefault()
            onReset()
          }}
        >
          <Tooltip title={'Reset to default (generated) value'}>
            <Typography variant="overline">{'Reset'}</Typography>
          </Tooltip>
        </button>
      )}
    </div>
  )
}

import type { OptionsDocument, FeatureOptions, UserPreferences } from 'paintscout'
const deepmerge = require('deepmerge')

import { getDefaultFeatures } from './get-default-features'

export function getFeatures(args?: { options?: OptionsDocument; preferences?: UserPreferences }): FeatureOptions {
  const defaultFeatures = getDefaultFeatures()
  const companyFeatures = args?.options?.options?.features ?? {}
  const preferenceFeatures = args?.preferences?.features ?? {}

  return deepmerge.all([defaultFeatures, companyFeatures, preferenceFeatures])
}

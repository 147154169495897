import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { formatCurrency } from '@paintscout/util/builder'
import { useClientOptions } from '../ClientOptionsProvider'
import type { Activity, PaymentActivityDetails, PaymentRequestedActivityDetails } from '@paintscout/api'
import { useIsMobile } from '@ui/core'
import startCase from 'lodash/startCase'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    marginTop: 2
  }
}))

interface QuoteActivitySubtitleProps {
  activity: Activity
  showDocTypeDetails: boolean
}

/**
 * The common subtitle for Quote activities
 */
export default function QuoteActivitySubtitle(props: QuoteActivitySubtitleProps) {
  const classes = useStyles({})
  const mobile = useIsMobile({ xs: true })
  const { options } = useClientOptions()
  const { activity, showDocTypeDetails } = props
  const quote = activity.details?.quote || activity.details?.invoice
  const contact = activity.details?.contact

  const { provider } = activity.details as PaymentActivityDetails
  const { method } = activity.details as PaymentActivityDetails
  const { deposit } = activity.details as PaymentActivityDetails

  let total: string | number
  if (activity.details?.activityType === 'payment') {
    total = (activity.details as PaymentActivityDetails)?.amountPaid
  } else if (activity.details?.activityType === 'payment-requested') {
    total = (activity.details as PaymentRequestedActivityDetails)?.amountRequested
  } else {
    total = activity.details?.quote?.total ?? activity.details?.invoice?.total ?? undefined
  }

  const showTotal = total !== undefined && (!!activity.details?.invoice || activity.details?.activityType !== 'created')

  const owner = activity.details?.owner
  const showEstimator = showDocTypeDetails && activity.details.activityType === 'accepted' && owner

  const subtitle = [
    showDocTypeDetails && quote.number && `#${quote.number}`,
    showEstimator && `${owner.firstName} ${owner.lastName}`,
    showDocTypeDetails && contact && `${contact.firstName} ${contact.lastName}`,
    showTotal && formatCurrency({ options, value: total as number }),
    !!provider && startCase(provider),
    !provider && !!method && startCase(method),
    !!deposit && 'Deposit'
  ]
    .filter(Boolean)
    .join(' · ')

  return (
    <Typography variant={mobile ? 'body1' : 'subtitle2'} component="span" className={classes.root}>
      {subtitle}
    </Typography>
  )
}

import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FieldArray, FastField } from 'formik'
import { Button, Grid, FieldGroup, FormikHtmlEditor, FormikInputField, FormikUploadImage } from '@ui/paintscout'
import { MoveRemoveButtons } from '../../util'

const DocumentsForm = ({ formik }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Field component={FormikInputField} fullWidth={true} name="section.title" label={'Title'} />
        </Grid>

        <Grid item md={12}>
          <FieldGroup label={'Documents'}>
            <FieldArray
              name="section.fields.rows"
              render={(arrayHelpers) => (
                <Grid container spacing={3}>
                  {(section?.fields?.rows ?? []).map((row, index) => (
                    <Grid item md={12} key={index}>
                      <DocumentsRow section={section} index={index} arrayHelpers={arrayHelpers} />
                    </Grid>
                  ))}

                  <Grid item md={12}>
                    <Button
                      onClick={() => {
                        arrayHelpers.push({ image: null, title: '', content: '' })
                      }}
                      variant={'outlined'}
                      fullWidth={true}
                    >
                      Add Document
                    </Button>
                  </Grid>
                </Grid>
              )}
            />
          </FieldGroup>
        </Grid>
      </Grid>
    </>
  )
}

function DocumentsRow({ index, section, arrayHelpers }) {
  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <Field
          component={FormikUploadImage}
          label={'PDF'}
          name={`section.fields.rows.${index}.image`}
          maxWidth={360}
          maxHeight={360}
          buttonHeight={100}
          quality={1}
        />
      </Grid>
      <Grid item md={8}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Field
              component={FormikInputField}
              label={'Title'}
              name={`section.fields.rows.${index}.title`}
              fullWidth={true}
            />
          </Grid>
          <Grid item md={12}>
            <FastField
              component={FormikHtmlEditor}
              label={'Description'}
              name={`section.fields.rows.${index}.content`}
              fullWidth={true}
              debounce={true}
              toolbar={{
                headings: true,
                align: true
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {section?.fields?.rows.length > 1 && (
        <MoveRemoveButtons arrayHelpers={arrayHelpers} index={index} section={section} />
      )}
    </Grid>
  )
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(DocumentsForm)

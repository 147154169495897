import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import { Grid } from '@ui/paintscout'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { useQuote } from '../../../context'
import { getObjectLabels } from '@paintscout/util/builder'
import { useClientOptions } from '@ui/paintscout'
import NumberFormat from 'react-number-format'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& img': {
        maxWidth: '100%'
      }
    },

    subtitle: {
      borderTop: `${theme.typography.pxToRem(2)} solid ${theme.palette.grey.A100}`,
      paddingTop: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      color: theme.palette.grey.A200
    },

    caption: {
      whiteSpace: 'nowrap'
    },
    signatureWrapper: {
      minHeight: theme.typography.pxToRem(100),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-start'
    }
  })
}

export interface QuoteSignCardProps extends WithStyles<typeof styles> {
  dense?: boolean
}

function QuoteSignCard({ classes, dense, ..._props }: QuoteSignCardProps) {
  const { options } = useClientOptions()

  const { quote } = useQuote()

  const dateFormat = options?.options?.dateFormat?.momentValue
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
  if (quote.is_invoice) {
    // Hide only if we dont require signature on additional work and there is no additional/pending work
    return null
  }

  const signatureWidth = dense ? 12 : 8
  const dateWidth = 4

  const spacing = dense ? 0 : 3

  return (
    <Grid container spacing={spacing} classes={{ container: classes.root }}>
      <Grid item>
        <Grid container={true} spacing={spacing} alignItems={'flex-end'} justifyContent="flex-end">
          {!dense && (
            <>
              <Grid item={true} xs={signatureWidth} sm={signatureWidth} md={signatureWidth}>
                <div className={classes.signatureWrapper}>
                  {quote?.signatures?.estimator && <img alt={'Estimator Signature'} src={quote.signatures.estimator} />}
                </div>
                <Typography className={classes.subtitle} variant="overline" component="div">
                  Estimator Signature
                </Typography>
              </Grid>
              <Grid item={true} xs={dateWidth} sm={dateWidth} md={dateWidth}>
                {quote?.dates?.client?.accepted && (
                  <Typography variant="body1">{moment(quote.dates.client.accepted).format(dateFormat)}</Typography>
                )}
                <Typography className={classes.subtitle} variant="overline" component="div">
                  Date
                </Typography>
              </Grid>
            </>
          )}
          <Grid item={true} xs={signatureWidth} sm={signatureWidth} md={signatureWidth}>
            <div className={classes.signatureWrapper}>
              {quote?.signatures?.customer && <img alt={'Customer Signature'} src={quote.signatures.customer} />}
            </div>
            <Typography className={classes.subtitle} variant="overline" component="div">
              {!dense && 'Customer Signature'}
              {dense && `Signed: ${moment(quote.dates.client.accepted).format(dateFormat)}`}
            </Typography>
          </Grid>
          {!dense && (
            <Grid item={true} xs={dateWidth} sm={dateWidth} md={dateWidth}>
              {quote?.dates?.client?.accepted && (
                <Typography variant="body1">{moment(quote.dates.client.accepted).format(dateFormat)}</Typography>
              )}
              <Typography className={classes.subtitle} variant="overline" component="div">
                Date
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      {!dense && (
        <Grid item>
          <Typography component={'div'} variant="body1" classes={{ root: classes.caption }}>
            {generateCaption()}
          </Typography>
          <Typography component={'div'} variant="body1" classes={{ root: classes.caption }}>
            {(quote.totals.price !== 0 || quote.totals.after_tax !== 0) && (
              <>
                {' Total value: '}
                <NumberFormat
                  value={quote.totals.after_tax}
                  displayType={'text'}
                  prefix={`${objectLabels.currency.symbol}`}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </>
            )}
          </Typography>
        </Grid>
      )}
    </Grid>
  )

  function generateCaption() {
    let components = []
    if (quote.number) {
      components.push(`${objectLabels.quote.value} #${quote.number}`)
    }

    const nameComponents = [quote?.contact?.first_name, quote?.contact?.last_name].filter((item) => item)
    if (nameComponents.length) {
      components = [...components, 'for', ...nameComponents].filter((item) => item)
    }

    return components.join(' ')
  }
}

export default withStyles(styles)(QuoteSignCard)

import { IntrospectionFragmentMatcher } from 'apollo-boost'
import { fragmentTypes } from '@paintscout/api'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { parseUrl } from 'src/util/parseUrl'
import isArray from 'lodash/isArray'
import history from 'shared/util/routing/history'
import { createHttpLink } from 'apollo-link-http'

const fetcher = (...args: any[]) => {
  // @ts-ignore
  return window.fetch(...args)
}

const httpLink = createHttpLink({
  uri: `${process.env.PS_GRAPHQL_URL}`,
  fetch: fetcher
})

const cvLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  let url = parseUrl(history.location.search)?.url
  if (isArray(url)) {
    url = url.join(',')
  }
  // eslint-disable-next-line
  url = url.replace(/[\?\%\&]/gi, '')
  return {
    headers: {
      ...headers,
      'cv-url': url,
      'x-app-version': `${process.env.VERSION}-${process.env.COMMIT}`
    }
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([cvLink, httpLink]),
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: fragmentTypes
    })
  })
})

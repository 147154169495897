import type { Theme } from '@material-ui/core'
import { makeStyles, Typography } from '@material-ui/core'
import { Collapse, FormSectionTitle, Table, TableBody, TableCell, useClientOptions } from '@ui/paintscout'
import { getQuoteOptions, setQuoteOptions } from '@paintscout/util/builder'
import { getItemProducts, getProductTotals } from '@paintscout/util/util'
import React, { useContext, useState } from 'react'
import { QuoteHeader } from '../..'
import { QuoteContext, useQuote } from '../../..'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  headerWrapper: {},
  htmlContent: {
    ...theme.typography.body1
  },
  hideContent: {
    display: 'none'
  },
  card: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: 'none',
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  header: {
    background: theme.palette.grey[300],
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  value: {
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightMedium
  },
  rows: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  dimensions: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1)
  }
}))

const ProductOrderForm = () => {
  const classes = useStyles()
  const { options } = useClientOptions()
  const { quote, isEditable } = useQuote()
  const quoteOptions = getQuoteOptions({ quote, options })
  const itemProducts = getItemProducts(quote, options)
  const productTotals = getProductTotals(quote)
  const { updateQuote } = useContext(QuoteContext)
  const { showProductOrderAllProducts, showProductOrderBreakdown } = quoteOptions

  const [showAllProducts, setShowAllProducts] = useState(showProductOrderAllProducts)
  const [showBreakdown, setShowBreakdown] = useState(showProductOrderBreakdown)

  const handleToggle = (name: 'showProductOrderAllProducts' | 'showProductOrderBreakdown', checked: boolean) => {
    if (name === 'showProductOrderAllProducts') {
      setShowAllProducts(checked)
    } else {
      setShowBreakdown(checked)
    }
    quoteOptions[name] = !quoteOptions[name]
    const updatedQuote = setQuoteOptions({ quoteOptions, quote })
    updateQuote({ quote: updatedQuote })
  }

  return (
    <div className={classes.root} id={'product-order-form'} data-testid="product-order-form">
      <div className={classes.headerWrapper}>
        <QuoteHeader isEditable={isEditable} variant={'product-order-form'} />
      </div>
      <div className={!isEditable && !showAllProducts ? classes.hideContent : null}>
        <FormSectionTitle
          title="All Products"
          showToggle={isEditable}
          toggleValue={showAllProducts}
          onToggle={(_, checked) => handleToggle('showProductOrderAllProducts', checked)}
        />
        <Collapse show={showAllProducts}>
          <div className={classes.card}>
            <Typography className={classes.header} variant="h4">
              Products
            </Typography>
            <Table>
              {productTotals.map((product) => (
                <CardRow
                  key={product.label}
                  label={product.label}
                  value={`${product.quantity} ${product.unitLabel ?? 'gal'}`}
                />
              ))}
            </Table>
          </div>
        </Collapse>
      </div>
      <div className={!isEditable && !showBreakdown ? classes.hideContent : null}>
        <FormSectionTitle
          title="Product Breakdown"
          showToggle={isEditable}
          toggleValue={showBreakdown}
          onToggle={(_, checked) => handleToggle('showProductOrderBreakdown', checked)}
        />
        <Collapse show={showBreakdown}>
          {itemProducts.map((card, i) => {
            return (
              <div key={`${card.label}-${i}`} className={classes.card}>
                <Typography className={classes.header} variant="h4">
                  {card.label}
                  {card.dimensions ? <span className={classes.dimensions}>({card.dimensions})</span> : null}
                </Typography>
                <Table>
                  <TableBody>
                    {card.products.map((product) => (
                      <CardRow
                        key={product.label}
                        label={`${product.label} ${product.color ? '- ' + product.color : ''}`}
                        value={`${product.volume} ${product.unitLabel}`}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            )
          })}
        </Collapse>
      </div>
    </div>
  )
}

interface CardProps {
  label: string
  value: string
}

const CardRow = ({ label, value }: CardProps) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.rows, 'product-row')}>
      <TableCell>
        <Typography key={label} variant="body1">
          {label}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography className={classes.value} variant={'body1'}>
          {value}
        </Typography>
      </TableCell>
    </div>
  )
}

export default ProductOrderForm

import type { PresentationIsEmptyProps } from '../../'

import type { QuoteItemSection } from '@paintscout/util/builder'
import { getQuoteOptions, hasFiles } from '@paintscout/util/builder'

export default function isEmpty({ quote, options }: PresentationIsEmptyProps) {
  const { showQuoteFiles } = getQuoteOptions({
    quote,
    options
  })

  if (!showQuoteFiles) {
    return true
  }

  const consumer = 'customer'
  const sections = ['bid', 'additional', 'added-options', 'pending', 'options'] as QuoteItemSection[]

  if (!hasFiles({ quote, options, sections, consumer })) {
    return true
  }

  return false
}

import type { TotalDimensions, AreaDimensions } from 'paintscout'
export function addDimensions(args: {
  total: TotalDimensions
  area: Omit<AreaDimensions, 'length' | 'width' | 'height'>
}): TotalDimensions {
  const total = args.total ?? {
    lnft: 0,
    sqft: 0,
    sqft_ceiling: 0,
    sqft_floor: 0,
    sqft_walls: 0
  }

  const area = args.area

  return {
    lnft: Number(total.lnft) + Number(area.lnft),
    sqft: Number(total.sqft) + Number(area.sqft),
    sqft_walls: Number(total.sqft_walls) + Number(area.sqft_walls),
    sqft_floor: Number(total.sqft_floor) + Number(area.sqft_floor),
    sqft_ceiling: Number(total.sqft_ceiling) + Number(area.sqft_ceiling)
  }
}

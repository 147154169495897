import type { OverridableComponent } from '@material-ui/core/OverridableComponent'
import type {
  TableRowProps as MuiTableRowProps,
  TableRowTypeMap as MuiTableRowTypeMap
} from '@material-ui/core/TableRow'
import MuiTableRow from '@material-ui/core/TableRow'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { TableHeadContext } from './TableHead'
import type { StyleClasses } from '../styles'

export interface TableRowProps extends MuiTableRowProps {
  classes?: StyleClasses<typeof useStyles>
  noBorder?: boolean
}

interface TableRowTypeMap<P = unknown, D extends React.ElementType = 'tr'> extends MuiTableRowTypeMap<P, D> {
  props: MuiTableRowTypeMap<P, D>['props'] & {
    noBorder?: boolean
  }
}

const useStyles = makeStyles<Theme, TableRowProps & { isHead: boolean }>((theme: Theme) => ({
  root: {
    boxSizing: 'border-box',
    cursor: ({ onClick, isHead }) => (onClick && !isHead ? 'pointer' : 'inherit'),
    borderBottom: ({ noBorder }) => (noBorder ? 'none' : `1px solid ${theme.palette.grey[300]}`)
  }
}))

const TableRow: OverridableComponent<TableRowTypeMap> = ({ children, noBorder, ...props }: TableRowProps) => {
  const { isHead } = useContext(TableHeadContext)
  const classes = useStyles({ ...props, isHead, noBorder })

  return (
    <MuiTableRow component="div" {...props} classes={classes}>
      {children}
    </MuiTableRow>
  )
}

export default TableRow

import type { UpdateableProduct } from '../../../area-substrates'
import type { QuoteProduct } from 'paintscout'
import { uuid } from '../../../../util'

/**
 * Convert a QuoteProduct to an UpdateableProduct.
 */
export function convertQuoteProduct({ product }: { product: QuoteProduct }): UpdateableProduct {
  return {
    active: true,
    color: product?.product?.color ?? '',
    key: product?.product?.name ?? uuid(),
    label: product?.product?.label ?? '',
    quantity: product.volume_override_value ? product.volume_override_value : product.volume,
    quoteTypes: product?.quoteTypes ?? ['all'],
    volume: {
      custom: product?.volume_override_value ?? 0,
      useCustom: Boolean(product.volume_override_value && product.volume_override_value !== 0),
      default: product?.volume ?? 0
    },
    price: {
      custom: product?.price_override_value ?? 0,
      useCustom: Boolean(product.price_override_value && product.price_override_value !== 0),
      default: product?.price ?? 0
    },
    totalPrice: {
      custom: product?.totalPrice_override_value ?? 0,
      useCustom: Boolean(product.totalPrice_override_value && product.totalPrice_override_value !== 0),
      default: product?.totalPrice ?? 0
    },
    quantityCoverage: {
      custom: product?.quantity_coverage_rate_override_value ?? 0,
      useCustom: Boolean(
        product.quantity_coverage_rate_override_value && product.quantity_coverage_rate_override_value !== 0
      ),
      default: product?.quantity_coverage_rate_value ?? 0
    },
    lnftCoverage: {
      custom: product?.lnft_coverage_rate_override_value ?? 0,
      useCustom: Boolean(product.lnft_coverage_rate_override_value && product.lnft_coverage_rate_override_value !== 0),
      default: product?.lnft_coverage_rate_value ?? 0
    },
    coverage: {
      custom: product?.coverage_rate_override_value ?? 0,
      useCustom: Boolean(product.coverage_rate_override_value && product.coverage_rate_override_value !== 0),
      default: product?.coverage_rate_value ?? 0
    }
  }
}

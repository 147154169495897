import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'

import { Grid } from '@ui/paintscout'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {},
  content: {},
  hr: {
    border: 'none'
  }
}))

export default function Divider(props: PresentationSectionProps) {
  const { section, WrapperComponent } = props

  const classes = useStyles(props)

  const textColor = section?.background?.textColor ?? '#000'
  const style = {
    borderTop: `1px solid ${textColor}`
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <hr className={classes.hr} style={style} />
        </Grid>
      </Grid>
    </WrapperComponent>
  )
}

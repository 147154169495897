export function isHtmlEmpty(text: string): boolean {
  if (!text) {
    return true
  }

  if (text.replace('<p></p>', '').trim() === '') {
    return true
  }

  return false
}

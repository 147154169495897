import React, { useContext } from 'react'
import type { QuoteFile } from 'paintscout'

import { FormikContext } from 'formik'

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'

import { Grid, Typography, useDialogs } from '@ui/paintscout'

import { FilesLightboxDialog } from '../../../dialogs'

import FilesGrid from '../../../FilesGrid'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {},
  content: {},
  filePreview: {
    '& img': {
      aspectRatio: '4/3',
      objectFit: 'cover'
    }
  }
}))

export default function Gallery(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { openDialog, dismissDialog } = useDialogs()
  const { section, WrapperComponent } = props
  // this bit of magic allows this component to be used inside or outside of formik without conditional hooks or throwing errors/warnings
  const formikContext = useContext(FormikContext)

  const { fields, title } = section
  const { files, columns, showBorder } = fields

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3} justifyContent={'center'} alignItems={'flex-start'}>
        {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        <Grid item xs={12} classes={{ root: classes.filePreview }}>
          <FilesGrid
            editable={props.editFromDialog}
            onReorder={handleReorder}
            justify="space-around"
            files={files}
            columns={columns}
            showBorder={showBorder}
            onFileClick={handleFileClick}
          />
        </Grid>
      </Grid>
    </WrapperComponent>
  )

  function handleReorder(reorderedFiles: QuoteFile[]) {
    const { setValues } = formikContext
    setValues({
      section: {
        ...section,
        fields: {
          ...section.fields,
          files: reorderedFiles
        }
      }
    })
  }

  function handleFileClick(index: number) {
    openDialog(FilesLightboxDialog, {
      files,
      index,
      onClose: dismissDialog
    })
  }
}

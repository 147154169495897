/**
 *
 * @param html html string to clean
 * @returns html string cleaned of inner whitespace
 */
export const trimHtmlInnerWhitespace = (html: string): string => {
  return cleanHtmlPreservingWhitespace(html)
}

function cleanHtmlPreservingWhitespace(htmlContent) {
  // Match tags other than <strong> and <p> with their content, custom checks on those
  // u, em and strong always embedded in p so we want to preserve the spaces around them
  htmlContent = htmlContent.replace(
    /<(?!\/?(strong|p|u|em)\b)([^>]+)>\s+|\s+<\/(?!strong|p|u|em)([^>]+)>/g,
    (match, group1, group2) => {
      if (group2) {
        return `</${group2}>`
      } else {
        return `<${group1}>`
      }
    }
  )

  // Match <strong> tags with their content
  htmlContent = htmlContent.replace(/<strong>(.*?)<\/strong>/gs, (match, strongContent) => {
    // Preserve the spaces within the <strong> tag
    return `<strong>${strongContent}</strong>`
  })

  // Match <p> tags with their content
  htmlContent = htmlContent.replace(/<p>(.*?)<\/p>/gs, (match, pContent) => {
    // Trim the whitespace within the <p> tag
    const trimmedPContent = pContent.trim()
    return `<p>${trimmedPContent}</p>`
  })

  return htmlContent
}

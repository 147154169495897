import type { AreaSubstrate, OverridableValue } from 'paintscout'

export default function convertHours(areaSubstrate: AreaSubstrate): OverridableValue {
  const hours = {
    useCustom: !!areaSubstrate.override_hours,
    custom: Number(
      areaSubstrate.override_hours && typeof areaSubstrate.override_hours_value !== 'undefined'
        ? areaSubstrate.override_hours_value
        : areaSubstrate.hours
    ),
    default: Number(
      typeof areaSubstrate.default_hours !== 'undefined' ? areaSubstrate.default_hours : areaSubstrate.hours
    )
  }
  return hours
}

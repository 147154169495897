import type { Theme } from '@material-ui/core'
import { makeStyles, TableSortLabel } from '@material-ui/core'
import type { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell'
import MuiTableCell from '@material-ui/core/TableCell'
import classNames from 'classnames'
import React, { useContext } from 'react'
import Tooltip from '../Tooltip'
import { TableHeadContext } from './TableHead'

export interface TableCellProps extends Omit<MuiTableCellProps, 'component'> {
  component?: string | React.ComponentType
  /**
   * Adjusts the width of the cell to a standard size for cells that contain
   * buttons/checkboxes/etc
   */
  isControl?: boolean
  sortable?: boolean

  /**
   * Fades the text if it is not the active sort
   */
  fadeWhenInactive?: boolean
  nowrap?: boolean

  tooltip?: string
}

const useStyles = makeStyles<Theme, TableCellProps & { isHead?: boolean }>(
  (theme: Theme) => ({
    root: {
      ...theme.typography.body1,
      whiteSpace: ({ nowrap }) => (nowrap ? 'nowrap' : 'normal'),
      fontWeight: ({ isHead }) => (isHead ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular),
      padding: ({ padding, isHead }) => {
        if (!padding || padding === 'default') {
          if (isHead) {
            return `${theme.spacing(2)}px ${theme.spacing()}px`
          }

          return theme.spacing()
        }

        return undefined // use material-ui default
      },
      width: ({ isControl }) => (isControl ? 24 : 'auto'),
      borderBottom: 'none',
      userSelect: 'none',
      background: 'none',

      // fade cell text if header
      color: ({ sortDirection, fadeWhenInactive }) => {
        const active = !!sortDirection

        if (fadeWhenInactive && !active) {
          return theme.palette.grey.A100
        }

        return 'inherit'
      },

      '&:first-child': {
        paddingLeft: theme.spacing(2)
      },
      '&:last-child': {
        paddingRight: theme.spacing(2)
      }
    },
    sortLabel: {
      '&:hover,&:focus,&$sortActive': {
        color: '#fff',
        '& $sortIcon': {
          color: '#fff !important', // !important because i can't figure out the specificity for this when active
          // width: theme.typography.pxToRem(12),
          height: theme.typography.pxToRem(12)
        }
      },
      position: 'relative'
    },
    reverseSortLabel: {
      flexDirection: 'row-reverse'
    },
    sortIcon: {
      transition: 'none',
      color: '#fff'
    },
    sortActive: {
      color: '#fff'
    },
    tooltipIcon: {
      color: theme.palette.common.white
    }
  }),
  {
    name: 'TableCell'
  }
)

function TableCell(props: TableCellProps) {
  const { isControl, sortable, fadeWhenInactive, tooltip, ...rest } = props
  const { isHead } = useContext(TableHeadContext)
  const classes = useStyles({ ...props, isHead, isControl, fadeWhenInactive })
  const align = rest.align ? rest.align : 'left'

  const active = !!props.sortDirection

  let content =
    isHead && sortable ? (
      <TableSortLabel
        active={active}
        classes={{
          root: classNames({ [classes.sortLabel]: true, [classes.reverseSortLabel]: align === 'right' }),
          icon: classes.sortIcon,
          active: classes.sortActive
        }}
        direction={props.sortDirection as 'asc' | 'desc'}
      >
        {props.children}
      </TableSortLabel>
    ) : (
      props.children
    )

  if (tooltip) {
    content = (
      <Tooltip content={tooltip} hideIcon={true} placement="top-end" classes={{ icon: classes.tooltipIcon }}>
        <>{content}</>
      </Tooltip>
    )
  }

  return (
    <MuiTableCell component={'div' as any} {...rest} align={align} classes={{ root: classes.root }}>
      {content}
    </MuiTableCell>
  )
}

export default TableCell

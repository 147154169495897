// Convert the rate type to be updateable
export default function convertRateType(rateType: string): string {
  switch (rateType) {
    case 'sqft_walls':
      return 'sqftWalls'
    case 'sqft_ceiling':
      return 'sqftCeiling'
    case 'sqft_floor':
      return 'sqftFloor'
    default:
      return rateType
  }
}

import type { QuoteDocument, PresentationEstimator } from 'paintscout'
import React from 'react'
import type { QuoteContextValue } from './QuoteContext'
import { QuoteContext } from './QuoteContext'

export interface QuoteContextProviderProps {
  quote: QuoteDocument
  children?: React.ReactNode
  tableView?: 'area' | 'substrate'
  presentationPage?: string
  sidebarPage?: string
  isCustomerView?: boolean
  isPresentation?: boolean

  onAccept?: (pending?: boolean) => any
  refetchQuote?: () => any

  estimator?: PresentationEstimator

  // for EditableQuoteContextProvider to use
  contextValue?: QuoteContextValue
}

class QuoteContextProvider extends React.Component<QuoteContextProviderProps> {
  public render() {
    const {
      children,
      quote,
      presentationPage,
      sidebarPage,
      estimator,
      onAccept,
      refetchQuote,
      isCustomerView,
      isPresentation
    } = this.props

    // const childrenFn = children as (context: QuoteContextValue) => React.ReactNode

    let tableView: 'area' | 'substrate'
    if (this.props.tableView) {
      tableView = this.props.tableView
    } else if (quote && quote.defaultView) {
      tableView = quote.defaultView as 'area' | 'substrate'
    } else {
      tableView = 'area'
    }

    const value: QuoteContextValue = {
      quote,
      tableView,
      sidebarPage,
      estimator,
      onAccept,
      refetchQuote,
      isCustomerView,
      isPresentation,
      isEditable: false,
      ...this.props.contextValue,
      presentationPage
    }

    return (
      <QuoteContext.Provider value={value}>
        {children}
        {/* {typeof children === 'function' ? childrenFn(value) : children} */}
      </QuoteContext.Provider>
    )
  }
}

export default QuoteContextProvider

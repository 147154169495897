import React from 'react'
import type { RenderableItem } from '@paintscout/util/builder'
import { getFileKeysForItem } from '@paintscout/util/builder'
import type { Theme } from '@material-ui/core'
import { makeStyles, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import PhotoIcon from '@material-ui/icons/Photo'
import classnames from 'classnames'
import { HtmlContent } from '@ui/paintscout'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium
    },
    itemIcons: {
      display: 'flex'
    },
    itemIcon: {
      color: theme.palette.grey[500],
      fontSize: '1.5em',

      marginLeft: theme.spacing() / 2
    }
  }),
  { name: 'NameColumn' }
)

export default function NameColumn({ item, isEditable }: { item: RenderableItem; isEditable: boolean }) {
  const classes = useStyles({})

  if (!item) {
    return
  }

  const name = typeof item.name === 'string' ? item.name : item.name?.useCustom ? item.name?.custom : item.name?.default
  const description = item.description?.useCustom ? item.description?.custom : item.description?.default
  const fileKeys = getFileKeysForItem({ item })

  const icons = (
    <div className={classes.itemIcons}>
      {fileKeys.length > 0 && (
        <Tooltip title={'This item has media attached'} placement={'bottom'}>
          <PhotoIcon className={classes.itemIcon} />
        </Tooltip>
      )}
      {item?.type === 'group' && (
        <Tooltip title={'This item is a group'} placement={'bottom'}>
          <ListIcon className={classes.itemIcon} />
        </Tooltip>
      )}
      {item?.description?.useCustom && (
        <Tooltip title={"This item's description has been customized"}>
          <EditIcon className={classes.itemIcon} />
        </Tooltip>
      )}
    </div>
  )

  return (
    <div className={classnames(classes.root, { [classes.bold]: !!name })}>
      <HtmlContent content={name ? name : description} />
      {isEditable && icons}
    </div>
  )
}

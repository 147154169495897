import type { ApolloClient as ApolloOfflineClient } from '@wora/apollo-offline'
import type ApolloClient from 'apollo-client'
import LogRocket from 'logrocket'

export function removeStoredMutationsById(client: ApolloClient<any>, id: string, options: { before?: number } = {}) {
  // useApolloClient returns normal ApolloClient, but it'll actually be ApolloOfflineClient
  const _client = client as ApolloOfflineClient

  const storedMutationIds = _client
    .getStoreOffline()
    .getListMutation()
    .filter((mutation) => {
      const mutationId = mutation?.request?.payload?.context?.__mutation_id__
      if (!mutationId) {
        LogRocket.info('Bad mutation:', JSON.stringify(mutation))
        return false
      }
      const isMatchingId = mutationId === id

      // only removes mutations before the passed in options.before (if provided)
      const isBefore = !options.before || mutation.fetchTime < options.before

      return isMatchingId && isBefore
    })
    .map((mutation) => mutation.id)

  storedMutationIds.forEach((mutationId) => {
    _client.getStoreOffline().remove(mutationId)
  })
}

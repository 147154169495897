import type { QuoteDocument, AreaSubstrate, OptionsDocument } from 'paintscout'
import { getAreaSubstrateKey } from '../../../area-substrates/get-area-substrate-key'
import type { UpdateableAreaSubstrate } from '../../../index'
import { isInSubstrateGroup } from '../../is-in-substrate-group'
import { isInAreaGroup } from '../../is-in-area-group'
import { getItemSection } from '../../../util/get-item-section'
import { getArea } from '../../../areas/get-area'
import {
  convertAreaSubstrateProducts,
  convertDimensions,
  convertHours,
  convertMaterials,
  convertPrep,
  convertPrice,
  convertRate,
  convertRateValue
} from '../util'
import { getDimensions } from '../../../items/get-items/get-crew-items-by-substrate/util'

interface toUpdateableAreaSubstrateProps {
  areaSubstrate: AreaSubstrate
  areaKey: string
  options: OptionsDocument
  substrateKey: string
  quote: QuoteDocument
}

/**
 * Convert an AreaSubstrate to an UpdateableAreaSubstrate to be edited.
 */
export default function toUpdateableAreaSubstrate({
  areaSubstrate,
  areaKey,
  options,
  substrateKey,
  quote
}: toUpdateableAreaSubstrateProps): UpdateableAreaSubstrate {
  const area = getArea({ quote, options, key: areaKey })
  const quoteSection = getItemSection(areaSubstrate)
  const price = convertPrice(areaSubstrate)
  const products = convertAreaSubstrateProducts(areaSubstrate)
  const materials = convertMaterials(products)
  const prep = convertPrep(areaSubstrate)
  const { length, width, height } = convertDimensions({ areaSubstrate, area })
  const areaSubstrateKey = getAreaSubstrateKey(quote, areaSubstrate)

  const inSubstrateGroup = isInSubstrateGroup({
    quote,
    areaSubstrateKey,
    rateKey: areaSubstrate.key,
    section: quoteSection
  })
  const inAreaGroup = !!isInAreaGroup({ quote, areaKey })
  const hours = convertHours(areaSubstrate)
  const rate = convertRate(areaSubstrate)
  const rateValue = convertRateValue(areaSubstrate)

  const updateableAreaSubstrate: UpdateableAreaSubstrate = {
    key: substrateKey,
    type: 'areaSubstrate' as const,
    name: areaSubstrate.rate.label,
    section: areaSubstrate.rate.section,
    clientLabel: areaSubstrate.rate.client_label,

    workOrderOnly: areaSubstrate.workOrderOnly,

    showPrice: areaSubstrate.showPrice,
    showProduct: areaSubstrate.showProduct,
    showCoats: areaSubstrate.showCoats,
    showPrep: areaSubstrate.showPrep,
    hideQuantity: areaSubstrate.hideQuantity,
    hideQuantityOnWorkOrder: areaSubstrate.hideQuantityOnWorkOrder,
    excludeDiscount: areaSubstrate.excludeDiscount,
    excludeTax: areaSubstrate.excludeTax,

    inSubstrateGroup: !!inSubstrateGroup,
    inAreaGroup: !!inAreaGroup,

    hourlyRate: areaSubstrate.hourlyRate,
    overrideHourlyRate: areaSubstrate.overrideHourlyRate,
    useInches: areaSubstrate.useInches,

    rate,

    products,

    prep,

    notes: areaSubstrate.notes,

    hours,

    price,

    materials,

    length,
    height,
    width,

    showOverrideDimensions: areaSubstrate.show_override_dimensions,

    rateValue,
    use_total: areaSubstrate.use_total,
    quantity: Number(areaSubstrate.quantity),
    coats: Number(areaSubstrate.coats),
    quoteSection
  }

  const dimensions = getDimensions({
    rateType: areaSubstrate.rate.rate_type,
    areaType: area.areaType,
    item: updateableAreaSubstrate,
    options,
    area: quote.areas[areaKey]
  })

  updateableAreaSubstrate.dimensionOverride = {
    default: dimensions.value,
    custom: areaSubstrate.override_dimensions_value ?? dimensions.value,
    useCustom: areaSubstrate.override_dimensions ?? false
  }

  return updateableAreaSubstrate
}

import React from 'react'

import Visibility from '@material-ui/icons/Visibility'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import classnames from 'classnames'
import WarningIcon from '@material-ui/icons/Warning'

import type { WithClientOptions } from '@ui/paintscout'
import { Select, MenuItem, Tooltip, withClientOptions } from '@ui/paintscout'

import find from 'lodash/find'
import { getObjectLabels } from '@paintscout/util/builder'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.grey.A400,
      '& $disabled': {
        color: theme.palette.primary.main
      },
      '@media print': {
        display: 'none'
      }
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    menuItemText: {
      marginTop: 2
    },
    selectedItem: {
      '& svg': {
        marginRight: '0.5em',

        fontSize: '1.5em'
      },
      textTransform: 'capitalize',
      ...theme.typography.overline
    },
    disabled: {
      color: theme.palette.grey[500]
    },
    select: {
      ...theme.typography.overline,
      color: theme.palette.grey[500],
      '&:focus': {
        background: 'none'
      }
    },
    hiddenOnSmall: {
      '@media (max-width: 350px)': {
        display: 'none'
      }
    }
  })

export interface AreaSubstrateViewProps extends WithStyles<typeof styles>, WithClientOptions {
  view?: string
  disabled?: boolean
  onChange?: (event: any, view: string) => void
  options?: Array<{ value: string; label: string }>
  hideOnSmall?: boolean
}

class AreaSubstrateView extends React.PureComponent<AreaSubstrateViewProps> {
  public render() {
    const { view, disabled, clientOptions, classes, hideOnSmall } = this.props
    const objectLabels = getObjectLabels({ options: clientOptions.options })
    const viewOptions = this.props.options
      ? this.props.options
      : [
          {
            label: 'Area View',
            value: 'area'
          },
          {
            label: `${objectLabels.substrate.value} View`,
            value: 'substrate'
          }
        ]

    const selectedOption = find(viewOptions, { value: view })
    const selectedLabel = selectedOption && selectedOption.label ? selectedOption.label : viewOptions[0].value
    const smallHiddenStyle = hideOnSmall ? classes.hiddenOnSmall : null

    return (
      <>
        {disabled ? (
          <Tooltip
            content={`${objectLabels.substrate.value} views are disabled when an area total has been overridden.`}
            placement="top"
            icon={<></>}
          >
            <div className={classnames(classes.menuItem, classes.selectedItem, classes.disabled)}>
              <WarningIcon />
              <span className={classes.menuItemText}>{selectedLabel}</span>
            </div>
          </Tooltip>
        ) : (
          <Select
            data-testid="area-substrate-view"
            classes={{ root: classes.root, select: classes.select }}
            value={view}
            onChange={this.handleChange}
            renderValue={() => (
              <div className={classnames(classes.menuItem, classes.selectedItem)}>
                <Visibility />
                <span className={classnames(smallHiddenStyle, classes.menuItemText)}>{selectedLabel}</span>
              </div>
            )}
          >
            {viewOptions.map((option) => {
              return (
                <MenuItem key={option.value} classes={{ root: classes.menuItem }} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            })}
          </Select>
        )}
      </>
    )
  }

  public handleChange = (event: any) => {
    const { value: view } = event.target

    if (this.props.onChange) {
      this.props.onChange(event, view)
    }
  }
}

export default withStyles(styles)(withClientOptions(AreaSubstrateView))

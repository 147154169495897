import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Typography from '../Typography'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      '& li': {
        textAlign: 'left'
      }
    },
    event: {
      color: theme.palette.text.secondary
    }
  }),
  {
    name: 'ActivityEvents'
  }
)

export default function ActivityEvents({ events = [] }: { events: string[] }) {
  const classes = useStyles({})

  if (events.length === 0) {
    return null
  }

  return (
    <ul className={classes.root}>
      {events.map((event, i) => (
        <li key={i}>
          <Typography variant="subtitle2" className={classes.event}>
            {event}
          </Typography>
        </li>
      ))}
    </ul>
  )
}

/**
 * @module builder
 */
import type { QuoteDocument } from 'paintscout'

export function setDate(args: { quote: QuoteDocument; date: number }): QuoteDocument {
  const { quote, date } = args

  return {
    ...quote,
    quote_date: date
  }
}

import React, { useEffect } from 'react'
import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { DropdownSelectOption, MultiSelectProps, WithClientOptions, WithUser } from '@ui/paintscout'
import { DropdownSelect, withClientOptions, withUser } from '@ui/paintscout'
import { getFeature } from '@paintscout/util/builder'
import { isEmailValid } from '@paintscout/util/util'

const styles = () => createStyles({})

export interface AddressBookSelectProps
  extends Omit<MultiSelectProps, 'classes' | 'options' | 'variant'>,
    WithClientOptions,
    WithUser,
    WithStyles<typeof styles> {
  bouncedValues?: DropdownSelectOption[]
  options?: DropdownSelectOption[]
}

const AddressBookSelect: React.FC<AddressBookSelectProps> = (props) => {
  const { options = [], clientOptions, user, bouncedValues, onChange, ...selectProps } = props
  const [initialOptions, setInitialOptions] = React.useState<DropdownSelectOption[]>(props.value)

  useEffect(() => {
    if (bouncedValues) onChange(bouncedValues)
  }, [bouncedValues, onChange])

  const companyAddresses = clientOptions.options.options.emails.addressBook
  const personalAddresses = user.preferences.addressBook
  const hasAddressBook = getFeature({ options: clientOptions.options, path: 'quotes.emails.hasAddressBook' })

  const groupedOptions: DropdownSelectOption[] = [
    ...options,
    ...initialOptions,
    hasAddressBook
      ? {
          label: 'Personal Address Book',
          options: personalAddresses.map((address) => ({ value: address, label: address }))
        }
      : null,
    hasAddressBook
      ? {
          label: 'Company Address Book',
          options: companyAddresses.map((address) => ({ value: address, label: address }))
        }
      : null
  ].filter(Boolean) as DropdownSelectOption[]

  const handleToChange = (values: DropdownSelectOption[]) => {
    if (onChange) {
      onChange(values)
    }
  }

  const invalidEmailMessage = (value: string) => {
    if (!isEmailValid(value)) {
      return 'Please enter a valid email address'
    }
  }

  return (
    <DropdownSelect
      {...selectProps}
      variant="multi"
      options={groupedOptions}
      noOptionsMessage={hasAddressBook ? 'Address Book and related emails will show here' : undefined}
      onChange={handleToChange}
      onCreateOption={(value) => {
        setInitialOptions([...initialOptions, { value, label: value }])
        handleToChange([...props.value, { value, label: value }])
      }}
      validateCreatedOption={invalidEmailMessage}
      createLabel={(inputValue) => `Use "${inputValue.trim()}"`}
    />
  )
}

export default withStyles(styles)(withClientOptions(withUser(AddressBookSelect)))

import type { OptionsDocument, QuoteDocument, QuoteTotals } from 'paintscout'
import { sumGroupChildren } from './sum-group-children'
import cloneDeep from 'lodash/cloneDeep'

/**
 *  Calculate totals for all groups on quote
 */
export function groups(quote: QuoteDocument, options: OptionsDocument): QuoteTotals {
  if (!quote.order || !quote.groups) {
    return quote.totals
  }

  const currentTotals = cloneDeep(quote.totals)
  const updatedTotals = quote.order.area.reduce((total, item) => {
    if (item._deleted || item.type !== 'group') {
      return total
    }
    const group = quote.groups[item.key]

    if (!group || group._deleted || !['bid', 'additional', 'pending', 'added-options'].includes(group.section)) {
      return total
    }
    let updatedTotal = cloneDeep(total)
    // Get totals for all children of this group
    updatedTotal = sumGroupChildren({ group, quote, options, currentTotals: updatedTotal })

    return updatedTotal
  }, currentTotals)

  return updatedTotals
}

import React from 'react'

import type { ButtonProps } from '@ui/paintscout'
import { Button } from '@ui/paintscout'
import AddIcon from '@material-ui/icons/Add'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

export interface AddItemButtonProps extends Omit<ButtonProps, 'classes'> {}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    '@media print': {
      display: 'none !important'
    }
  },
  icon: {
    fontSize: '1.0em',
    marginRight: theme.spacing(),
    alignSelf: 'center'
  }
}))

export default function AddItemButton({ children, ...props }: AddItemButtonProps) {
  const classes = useStyles(props)

  return (
    <Button fullWidth variant={'outlined'} size={'medium'} color={'default'} disableRipple {...props} classes={classes}>
      <AddIcon className={classes.icon} />
      {children}
    </Button>
  )
}

import qs from 'query-string'

export function parseUrl(queryString) {
  let parsed: any
  parsed = qs.parse(queryString) as {
    u: string
    e: string
    s: string
    r: string
    p?: string
    pdf: string
    source: string
  }

  if (!parsed.u) {
    const sanitizedQueryString = queryString.replace(/amp%3B/gi, '&').replace(/&&/gi, '&')

    parsed = qs.parse(sanitizedQueryString) as {
      u: string
      e: string
      s: string
      p?: string
      r: string
      pdf: string
      source: string
    }
  }

  const { u: url, s: silent, e: email, r: revision, p: paymentId, pdf, source } = parsed

  return {
    url,
    silent,
    email,
    revision,
    pdf,
    source,
    paymentId
  }
}

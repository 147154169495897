import React, { useContext } from 'react'
import type { Activity } from '@paintscout/api'

interface ActivityTimelineContextValue {
  activities: Activity[]
}

export const ActivityTimelineContext = React.createContext<ActivityTimelineContextValue>({
  activities: []
})

export const useActivityTimeline = () => useContext(ActivityTimelineContext)

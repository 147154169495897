import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TabScrollButton from '../TabScrollButton'
import classnames from 'classnames'
import * as scroll from 'scroll'
import type { WithUseStyles } from '../styles'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row' as const,
    justifyContent: 'space-between'
  },
  scrollableContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row' as const,
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  scrollButton: {}
})

export interface HorizontalScrollerProps extends WithUseStyles<typeof useStyles> {
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
}

export default function HorizontalScroller({ className, children, ...props }: HorizontalScrollerProps) {
  const classes = useStyles(props)
  const ref = useRef<HTMLDivElement>(null)
  const [showScrollButtons, setShowScrollButtons] = useState(false)

  const handleScrollClick = (direction: 'left' | 'right') => {
    const nextScroll = ref.current.scrollLeft + ref.current.clientWidth * (direction === 'right' ? 1 : -1)

    scroll.left(ref.current, nextScroll)
  }

  useEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setShowScrollButtons(true)
    } else {
      setShowScrollButtons(false)
    }
  }, [children])

  return (
    <div className={classnames('horizontal-scroller', classes.root, className)} {...props}>
      {showScrollButtons && (
        <TabScrollButton className={classes.scrollButton} direction="left" onClick={() => handleScrollClick('left')} />
      )}
      <div ref={ref} className={classes.scrollableContent}>
        {children}
      </div>
      {showScrollButtons && (
        <TabScrollButton
          className={classes.scrollButton}
          direction="right"
          onClick={() => handleScrollClick('right')}
        />
      )}
    </div>
  )
}

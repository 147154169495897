import type { PouchDocument, QuoteDocument } from 'paintscout'
import type { Contact } from '@paintscout/api'
import { removeAuth0Prefix } from '../../../users/removeAuth0Prefix/removeAuth0Prefix'

/**
 * Check if a user is assigned to the document
 *
 */
export const isUserAssigned = (
  doc: PouchDocument | Partial<QuoteDocument> | Partial<Contact>,
  userId: string
): boolean => {
  if (!doc?.assignedUsers) {
    return false
  }

  if (!doc?.assignedUsers[removeAuth0Prefix(userId)]) {
    return false
  }

  if (!doc?.assignedUsers[removeAuth0Prefix(userId)].assigned) {
    return false
  }

  return true
}

import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { WithClientOptions } from '@ui/paintscout'
import { FormSectionTitle, withClientOptions } from '@ui/paintscout'
import type { QuoteItemSection, RenderableItem } from '@paintscout/util/builder'
import { hasCustomTotal } from '@paintscout/util/util/hasCustomTotal'
import React from 'react'
import AreaSubstrateView from '../../../AreaSubstrateView'
import type { WithQuoteContext } from '../../../context/withQuoteContext'
import { withQuoteContext } from '../../../context/withQuoteContext'
import BulkActions from '../../BulkActions'
import ArchivedTable from './ArchivedTable'

const styles = (_theme: Theme) =>
  createStyles({
    root: {},
    headerWrapper: {},
    archivedTableWrapper: {}
  })

export interface ArchivedItemsViewProps extends WithStyles<typeof styles>, WithQuoteContext, WithClientOptions {}

export interface ArchivedItemsViewState {
  selectedItems: {
    bid: RenderableItem[]
    options: RenderableItem[]
    additional: RenderableItem[]
    pending: RenderableItem[]
    archived: RenderableItem[]
    'added-options': RenderableItem[]
  }
}

class ArchivedItemsView extends React.Component<ArchivedItemsViewProps, ArchivedItemsViewState> {
  public state: ArchivedItemsViewState = {
    selectedItems: {
      bid: [],
      options: [],
      additional: [],
      pending: [],
      archived: [],
      'added-options': []
    }
  }

  public render() {
    const { classes, quoteContext } = this.props
    const { options } = this.props.clientOptions
    const { isEditable, tableView, quote, onItemAction } = quoteContext

    const disableSubstrateView = hasCustomTotal(quote.areas, true)
    const view = disableSubstrateView ? 'area' : tableView
    return (
      <>
        <div className={classes.root}>
          <div className={classes.archivedTableWrapper}>
            <FormSectionTitle
              title={'Archived'}
              variant="h2"
              subTitle={'These items will not show anywhere except here.'}
              rightContent={
                isEditable && (
                  <AreaSubstrateView
                    hideOnSmall={true}
                    view={view}
                    onChange={this.handleTableViewChange}
                    disabled={disableSubstrateView}
                  />
                )
              }
            />
            <ArchivedTable
              selectedItems={this.getSelectedItems({ section: 'archived' })}
              onItemSelection={this.handleItemSelection({ section: 'archived' })}
              disableSubstrateView={disableSubstrateView}
              onItemAction={(action, section, item) => this.handleItemAction(action, section, item, onItemAction)}
            />
          </div>
        </div>
        {isEditable && (
          <BulkActions
            options={options}
            quote={quote}
            tableView={view}
            getSelectedItems={this.getSelectedItems}
            getSelectedItemsByType={this.getSelectedItemsByType}
            onBulkActionClick={this.handleBulkActionClick}
            onDeselectAll={this.handleDeselectAll}
          />
        )}
      </>
    )
  }

  public getSelectedItems = (args?: { section?: QuoteItemSection }) => {
    const section = args && args.section ? args.section : null
    const { selectedItems } = this.state
    const { bid, options, additional, pending, archived } = selectedItems

    if (section) {
      return selectedItems[section]
    }

    return [...bid, ...options, ...additional, ...pending, ...archived, ...selectedItems['added-options']]
  }

  /**
   * Gets the selected items by type
   */
  public getSelectedItemsByType = (type: string) => {
    const { selectedItems } = this.state
    const { bid, options, additional, pending, archived } = selectedItems
    const items = [...bid, ...options, ...additional, ...pending, ...archived]

    return items.filter((item) => item.type === type)
  }

  public handleItemAction(action: string, section: QuoteItemSection, item: RenderableItem, onItemAction: any) {
    const shouldDeselect = [
      'make-option',
      'archive',
      'unarchive',
      'move-to-bid',
      'move-to-additional',
      'move-to-pending',
      'delete'
    ]
    if (shouldDeselect.includes(action)) {
      this.handleDeselectItem({ section, key: item.key })
    }
    onItemAction(action, section, [item.key])
  }

  public handleDeselectItem = (args: { section: QuoteItemSection | 'all'; key?: string }) => {
    const { section, key } = args
    if (section === 'all') {
      this.handleDeselectAll()
    } else {
      this.setState({
        selectedItems: {
          ...this.state.selectedItems,
          [section]: this.state.selectedItems[section].filter((items) => items.key !== key)
        }
      })
    }
  }

  public handleBulkActionClick = (action: string) => (selectedItems: RenderableItem[]) => {
    this.props.quoteContext.onItemAction(
      action,
      'archived',
      selectedItems.map((item) => item.key)
    )
    this.setState({
      selectedItems: {
        bid: [],
        options: [],
        additional: [],
        pending: [],
        archived: [],
        'added-options': []
      }
    })
  }

  public handleItemSelection =
    (args: { section: 'bid' | 'options' | 'additional' | 'pending' | 'archived' }) =>
    (event: React.ChangeEvent<HTMLElement>, selectedItems: RenderableItem[]) => {
      this.setState({
        selectedItems: {
          ...this.state.selectedItems,
          [args.section]: selectedItems
        }
      })
    }

  public handleDeselectAll = () => {
    this.setState({
      selectedItems: {
        bid: [],
        options: [],
        additional: [],
        pending: [],
        archived: [],
        'added-options': []
      }
    })
  }

  public handleTableViewChange = (event: any, view: string) => {
    if (this.props.quoteContext.onTableViewChange) {
      this.props.quoteContext.onTableViewChange(view)
    }
  }
}

export default withStyles(styles)(withQuoteContext(withClientOptions(ArchivedItemsView)))

import type { OverridableValue } from 'paintscout'
import type { UpdateableProduct } from '../..'

export default function convertMaterials(products: UpdateableProduct[]): OverridableValue {
  const materials = products.reduce(
    (acc, product) => {
      return {
        ...acc,
        useCustom: acc.useCustom || product.totalPrice.useCustom,
        custom: acc.custom + (product.totalPrice.useCustom ? product.totalPrice.custom : product.totalPrice.default),
        default: acc.default + product.totalPrice.default
      }
    },
    { useCustom: false, custom: 0, default: 0 }
  )

  return materials
}

import React from 'react'
import type { SnackbarProviderProps } from 'notistack'
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core'

export type { SnackbarProviderProps } from 'notistack'

const useStyles = makeStyles((_theme) => ({
  root: {
    fontSize: '1rem'
  }
}))
const SnackbarProvider = (props: SnackbarProviderProps) => {
  const classes = useStyles()

  return <NotistackSnackbarProvider className={classes.root} maxSnack={1} {...props} />
}

export default SnackbarProvider

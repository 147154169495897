/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { QuoteItemConsumer } from '../../../index'
import { convertDiscounts, convertGroups, convertTaxes } from './util'

export interface RenderableDiscount {
  key: string
  negative: boolean
  description: string
  amount: number
}

export interface RenderableTax {
  key: string
  description: string
  amount: number
}

export interface RenderableTotalGroup {
  key: string
  description: string
  value: number
}

export interface RenderableTotals {
  groups: RenderableTotalGroup[]
  subTotal: number
  customSubTotal: boolean
  additionalFields?: { [key: string]: number }
  discounts: RenderableDiscount[]
  afterDiscount: number
  taxes: RenderableTax[]
  afterTax: number
  amountPaid: number
  balanceDue: number
  additionalWork: number
  addedOptions?: number
  show: boolean
  showBalanceDue: boolean
  dimensions: { lnft: number; sqft: number; sqftWalls: number; sqftCeiling: number; sqftFloor: number }
  serviceFees?: {
    hiddenFees?: number
    visibleFees?: number
    totalFees?: number
    surcharge?: number
  }
}

/**
 * Get Bid, Options, or Archived totals for a quote.
 * @param  {GetTotals}    args
 * @return {RenderableTotals}
 */
export function getTotals(args: {
  quote: QuoteDocument
  options: OptionsDocument
  consumer: QuoteItemConsumer
}): RenderableTotals {
  const { quote, options, consumer } = args

  const discounts: RenderableDiscount[] = convertDiscounts(quote, consumer)
  const groups: RenderableTotalGroup[] = consumer === 'crew' ? convertGroups(quote, options) : []

  let customSubTotal = false

  let price = quote.totals.price
  if (typeof quote.totals.after_adjustment !== 'undefined' && quote.totals.after_adjustment !== quote.totals.price) {
    price = quote.totals.after_adjustment
    if (consumer === 'customer') {
      customSubTotal = true
    }
  }

  let hours = quote.totals.hours + quote.totals.prep
  if (quote.totals.override_hours) {
    hours = quote.totals.override_hours_value
    if (consumer === 'crew') {
      customSubTotal = true
    }
  }

  const dimensions = {
    lnft: quote.totals?.dimensions?.lnft ?? 0,
    sqft: quote.totals?.dimensions?.sqft ?? 0,
    sqftWalls: quote.totals?.dimensions?.sqft_walls ?? 0,
    sqftFloor: quote.totals?.dimensions?.sqft_floor ?? 0,
    sqftCeiling: quote.totals?.dimensions?.sqft_ceiling ?? 0
  }

  const subTotal = consumer === 'customer' ? price : hours

  const totals: any = {
    groups,
    subTotal,
    customSubTotal,
    discounts,
    dimensions
  }

  if (consumer === 'customer') {
    if (
      quote.totals.after_tax === 0 &&
      (quote.totals?.total_adjustment ?? 0) === 0 &&
      (quote.totals?.total_discount ?? 0) === 0 &&
      (quote.totals?.additionalWork?.materials ?? 0) === 0 &&
      (quote.totals?.additionalWork?.price ?? 0) === 0
    ) {
      return {} as RenderableTotals
    }
    totals.afterTax = quote.totals.after_tax
    totals.taxes = convertTaxes(quote)
    totals.afterDiscount = quote.totals.after_discount
    totals.balanceDue = quote.totals.balance_due
    totals.amountPaid = quote.totals.amount_paid

    const additionalWorkPrice = quote?.totals?.additionalWork?.price ?? 0
    const additionalWorkMaterials = quote?.totals?.additionalWork?.materials ?? 0
    const additionalWork = additionalWorkPrice + additionalWorkMaterials
    totals.additionalWork = additionalWork

    const addedOptionsPrice = quote?.totals?.addedOptions?.price ?? 0
    const addedOptionsMaterials = quote?.totals?.addedOptions?.materials ?? 0
    const addedOptions = addedOptionsPrice + addedOptionsMaterials
    totals.addedOptions = addedOptions

    totals.serviceFees = {
      visibleFee: quote.totals?.service_fee_visible ?? 0,
      hiddenFee: quote.totals?.service_fee_hidden ?? 0,
      surcharge: quote.totals?.surcharge ?? 0
    }

    totals.showBalanceDue = quote?.totals?.showBalanceDue ?? true
    totals.show = quote?.totals?.show_total ?? true
  } else {
    const paintingHours = quote.totals.hours
    const prepHours = quote.totals.prep
    totals.additionalFields = {
      paintingHours,
      prepHours
    }
    totals.taxes = []
    totals.balanceDue = null
    totals.amountPaid = null
    totals.showBalanceDue = quote?.totals?.showBalanceDue ?? true
    totals.show = quote?.totals?.show_total ?? true
  }

  return totals
}

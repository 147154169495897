import React, { useState } from 'react'
import type { DialogProps } from '@ui/paintscout'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogAfterContent,
  Button,
  SignatureCanvas,
  InputField,
  HtmlContent,
  getPresentationContentStyles
} from '@ui/paintscout'

import type { StyleClasses } from '@ui/core/theme'
import type { Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { getObjectLabels } from '@paintscout/util/builder'
import { isEmpty } from '@paintscout/util/util'
import { QuoteContextProvider } from '../../context'

import Done from '@material-ui/icons/Done'
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import { PresentationNavTotal } from '../../presentation/PresentationNav'
import { useIsMobile } from '@ui/core'

export interface AcceptQuoteDialogProps extends Omit<DialogProps, 'loading'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  options: OptionsDocument
  quote?: QuoteDocument

  title?: string
  showTotal?: boolean
  allowNote?: boolean
  additionalAccept?: boolean
  onConfirm?: (event: React.MouseEvent<HTMLElement>, data: string, note?: string) => void | Promise<void>
  onCancel?: (event: React.MouseEvent<HTMLElement>) => void
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    dialogContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      overflowX: 'hidden'
    },
    acceptButton: {
      marginLeft: theme.spacing(1)
    },
    disclaimer: {
      flexGrow: 0
    },
    htmlContent: {
      ...getPresentationContentStyles(theme)
    }
  })
}

function AcceptQuoteDialog({
  options,
  quote,
  showTotal: _showTotal,
  additionalAccept,
  title,
  allowNote,
  onConfirm,
  onCancel,
  classes,
  ...dialogProps
}: AcceptQuoteDialogProps) {
  const [error, setError] = useState<string>('')
  const [signatureData, setSignature] = useState<string>('')
  const [note, setNote] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const objectLabels = getObjectLabels({ options })
  const [disableAccept, setDisableAccept] = useState<boolean>(false)
  const [disableSignature, setDisableSignature] = useState<boolean>(false)
  const isMobile = useIsMobile({ xs: true })

  const additional =
    (quote.is_invoice || quote.status.value === 'accepted' || additionalAccept) &&
    !!((quote?.totals?.pending?.materials ?? 0) + (quote?.totals?.pending?.price ?? 0))

  const getSanitizedTitle = () => {
    if (title) {
      return title
    } else if (isMobile) {
      return `Accept`
    } else if (additional) {
      return `Accept ${objectLabels.additionalWork.value}`
    } else {
      return `Accept ${objectLabels.quote.value}`
    }
  }

  const sanitizedTitle = getSanitizedTitle()

  let acceptDisclaimer =
    additional && typeof options.options.acceptAdditionalDisclaimer !== 'undefined'
      ? options.options.acceptAdditionalDisclaimer
      : options.options.acceptDisclaimer
  if (isEmpty(acceptDisclaimer)) {
    acceptDisclaimer = null
  }

  async function handleConfirm(event: any) {
    setDisableSignature(true)
    if (!signatureData && !allowNote) {
      setError(`Please sign above before accepting.`)
      setDisableSignature(false)
      return
    } else if (!signatureData && !note) {
      setError(`Please sign or add a note above before accepting.`)
      setDisableSignature(false)
      return
    }

    setLoading(true)
    await onConfirm(event, signatureData, note)
    setLoading(false)
    setDisableSignature(false)
  }

  function handleSignatureChange(event: any, data: string) {
    setSignature(data)
    setError(null)
  }

  function handleSignatureStart(value: boolean) {
    setDisableAccept(value)
  }

  function handleNoteChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setNote(value)
    setError(null)
  }

  const leftButton = (
    <Button disabled={loading} onClick={onCancel} variant={'text'}>
      Cancel
    </Button>
  )

  return (
    <QuoteContextProvider quote={quote}>
      <Dialog {...dialogProps} className={classes.root}>
        <DialogTitle loading={loading} rightContent={<PresentationNavTotal size={'small'} />}>
          {sanitizedTitle}
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <SignatureCanvas
            onSignStart={handleSignatureStart}
            disabled={disableSignature}
            onChange={handleSignatureChange}
          />
          {allowNote && (
            <InputField
              fullWidth={true}
              label={'Note'}
              sublabel={'Additional Instructions or Information about these items.'}
              multiline={true}
              onChange={handleNoteChange}
              value={note}
            />
          )}
        </DialogContent>
        {acceptDisclaimer && !error && (
          <DialogAfterContent classes={{ root: classes.disclaimer }}>
            <HtmlContent content={acceptDisclaimer} className={classes.htmlContent} />
          </DialogAfterContent>
        )}
        {error && <DialogAfterContent type={'error'}>{error}</DialogAfterContent>}
        <DialogActions showBorder={!acceptDisclaimer} leftButton={leftButton}>
          <Button
            loading={loading}
            classes={{ root: classes.acceptButton }}
            onClick={handleConfirm}
            variant={'contained'}
            icon={Done}
            disabled={disableAccept}
          >
            {sanitizedTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </QuoteContextProvider>
  )
}

export default withStyles(styles)(AcceptQuoteDialog)

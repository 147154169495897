import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import InputLabel from '../InputLabel'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    labelWrapper: {
      marginBottom: theme.typography.pxToRem(5)
    },
    wrapper: {
      border: `1px solid ${theme.palette.common.black}`,
      padding: theme.spacing(2)
    }
  })

export interface FieldGroupProps extends WithStyles<typeof styles> {
  label?: string
  children: React.ReactNode
}

function FieldGroup({ label, classes, children }: FieldGroupProps) {
  return (
    <div className={classes.root}>
      {label && (
        <div className={classes.labelWrapper}>
          <InputLabel shrink={true}>{label}</InputLabel>
        </div>
      )}
      <div className={classes.wrapper}>{children}</div>
    </div>
  )
}

export default withStyles(styles)(FieldGroup)

import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type {
  Activity,
  MessageActivity as MessageActivityInterface,
  MessageActivityDetails,
  User
} from '@paintscout/api'
import React, { useMemo } from 'react'
import { useActivityTimeline } from '../../ActivityTimeline/ActivityTimelineContext'
import Highlight from '../../Highlight'
import Typography from '../../Typography'
import { useUser } from '../../UserProvider'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityContent from '../ActivityContent'
import ActivityFooter from '../ActivityFooter'
import ActivityTitle from '../ActivityTitle'
import HtmlContent from '../../HtmlContent'
import ActivityActions, { ActivityAction } from '../ActivityActions'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDialogs } from '../../DialogStack'
import { ConfirmationDialog } from '../../dialogs'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
const linkify = require('linkifyjs/html')

const useStyles = makeStyles<Theme, { isOwnMessage: boolean; deleted?: boolean; isQuote?: boolean }>((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: ({ isQuote }) => (isQuote ? '230px' : 'inherit'),
    overflowWrap: 'break-word',
    [theme.breakpoints.up('xl')]: {
      maxWidth: ({ isQuote }) => (isQuote ? '240px' : 'inherit')
    }
  },
  bubble: {
    background: ({ isOwnMessage, deleted }) => (isOwnMessage && !deleted ? theme.palette.primary.light : null),
    color: ({ isOwnMessage, deleted }) => (isOwnMessage && !deleted ? theme.palette.primary.contrastText : null),
    '& a': {
      color: 'inherit'
    }
  },
  message: {
    // whiteSpace: 'pre-wrap'
  }
}))

export default function MessageActivity(props: ActivityTypeComponentProps<MessageActivityDetails>) {
  const { user } = useUser()
  const { activity, showDocTypeDetails, showContent, actions, onAction, isQuote } = props
  const { message } = activity.details
  const { from, readAt, text, deleted } = message

  const { activities } = useActivityTimeline()
  const { openDialog, dismissDialog } = useDialogs()

  const isOwnMessage = checkIsOwnMessage(activity, user)
  const classes = useStyles({ isOwnMessage, deleted, isQuote })

  // we only want to show the "READ" status if it's the latest read message
  const isLatestReadMessage = useMemo(() => {
    if (!readAt) {
      return false
    }

    return activities.reduce((isLatest, otherActivity) => {
      if (
        isMessageActivity(otherActivity) &&
        checkIsOwnMessage(otherActivity, user) &&
        otherActivity.details.message.readAt &&
        activity.details.timestamp < otherActivity.details.timestamp
      ) {
        return false
      }

      return isLatest
    }, true)
  }, [activities])

  if (deleted) {
    return (
      <ActivityContent
        classes={{
          root: classes.content,
          bubble: classes.bubble
        }}
      >
        <div className={classes.message}>
          <em>This message has been deleted</em>
        </div>
      </ActivityContent>
    )
  }

  return (
    <>
      <ActivityTitle
        rightContent={
          actions &&
          isOwnMessage && (
            <ActivityActions>
              <ActivityAction
                onClick={() => {
                  openDialog(ConfirmationDialog, {
                    title: 'Are you sure?',
                    message: 'This will permanently delete the message from the chat',
                    onConfirm: () => {
                      onAction('delete', activity)
                      dismissDialog()
                    },
                    onCancel: () => dismissDialog()
                  })
                }}
                icon={DeleteIcon}
              >
                Delete
              </ActivityAction>
            </ActivityActions>
          )
        }
      >
        {showDocTypeDetails ? (
          <>
            <Highlight>{from.name}</Highlight> sent a <Highlight>message</Highlight>
          </>
        ) : (
          <>
            <Highlight>{isOwnMessage ? 'You' : from.name}</Highlight> said:
          </>
        )}
      </ActivityTitle>
      {showContent && (
        <ActivityContent
          classes={{
            root: classes.content,
            bubble: classes.bubble
          }}
        >
          <div className={classes.message}>
            <HtmlContent content={linkify(text)} />
          </div>
        </ActivityContent>
      )}
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      <ActivityFooter activity={activity} timestamp={activity.details.timestamp}>
        {isOwnMessage && readAt && isLatestReadMessage && showContent && (
          <Typography variant="overline">Read</Typography>
        )}
      </ActivityFooter>
    </>
  )
}

function checkIsOwnMessage(activity: Activity, user?: User) {
  if (isMessageActivity(activity)) {
    // if there's no user context, then it's being viewed as the customer
    const userIsCustomer = !user

    return (
      (activity.details.message.from.isCustomer && userIsCustomer) ||
      user?.user_id === activity.details.message.from.userId
    )
  }

  return false
}

function isMessageActivity(activity: Activity): activity is MessageActivityInterface {
  return activity.details.activityType === 'message'
}

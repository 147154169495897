import React from 'react'

import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import ActivityFooter from '../ActivityFooter'
import ActivityTitle from '../ActivityTitle'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'

import capitalize from 'lodash/capitalize'
import ActivityOwner from '../ActivityOwner'
import QuoteLabel from '../QuoteLabel'

/**
 * For simple activities that only require a "Quote was X" title
 */
export default function BasicQuoteActivity(props: ActivityTypeComponentProps) {
  const { activity, showDocTypeDetails } = props
  const action = activity.details.activityType

  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            <QuoteLabel activity={activity} /> was <Highlight>{action}</Highlight>
          </>
        ) : (
          <>
            <Highlight>{capitalize(action)}</Highlight>
          </>
        )}
        <ActivityOwner activity={activity} />
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

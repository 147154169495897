import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import moment from 'moment-timezone'
import type { ActivityProps } from '../Activity/Activity'
import Activity from '../Activity/Activity'
import Skeleton from '@ui/core/Skeleton'
import Grid from '../Grid'
import type { WithUseStyles } from '../styles'
import type { Activity as ActivityInterface } from '@paintscout/api'
import { ActivityTimelineContext } from './ActivityTimelineContext'
import { motion } from 'framer-motion'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden'
  },
  border: {
    position: 'relative',
    '&:after': {
      zIndex: 1,
      display: 'block',
      content: "''",
      borderLeftStyle: 'solid',
      borderLeftWidth: 1,
      borderColor: theme.palette.grey[300],
      position: 'absolute',
      left: 17,
      top: 25,
      bottom: -20
    }
  },

  heading: {
    ...theme.typography.overline,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    color: theme.palette.grey[500],
    position: 'relative'
  },
  shimRow: {
    display: 'block',
    width: '100%',
    borderRadius: '5px',
    overflow: 'hidden',
    margin: '10px 0'
  },
  shim: {
    display: 'block',
    color: 'initial',
    height: 40,
    width: '100%',
    margin: 'auto 0'
  },

  activity: {
    alignItems: 'center',
    '&:last-of-type': {
      paddingBottom: theme.spacing(2)
    }
  },
  day: {}
}))

export interface ActivityTimelineProps extends WithUseStyles<typeof useStyles> {
  activities: ActivityInterface[]
  ascending?: boolean
  loading?: boolean
  shimRowAmount?: number
  actions?: boolean
  onAction?: (action: string, activity: ActivityInterface) => any

  /**
   * If true, render with quote sidebar style adjustments
   */
  isQuote?: boolean

  /**
   * Show the additional content for specific activities (events, email content, etc)
   */
  showContent?: boolean

  /**
   * Includes information about the doc type in each activity.
   * Set this to true if you're showing activities from different docs (such as the dashboard)
   *
   * Example descriptions:
   *
   * true - "Quote was Edited by Bob Smith"
   * false - "Edited by Bob Smith"
   */
  showDocTypeDetails?: boolean

  /**
   * Change the component that renders the Activity
   */
  ActivityComponent?: React.ComponentType<ActivityProps>
}

function ActivityTimeline(props: ActivityTimelineProps) {
  const {
    activities,
    loading,
    shimRowAmount,
    showContent,
    showDocTypeDetails,
    ActivityComponent,
    ascending,
    onAction,
    actions,
    isQuote
  } = props
  const classes = useStyles(props)
  const sorted = useMemo(
    () =>
      // [...] to prevent mutation
      [...activities].sort((a, b) => {
        const direction = ascending ? -1 : 1

        return a.details?.timestamp > b.details?.timestamp ? -1 * direction : 1 * direction
      }),
    [activities]
  )

  const groupedByDay = useMemo(() => {
    return sorted.reduce((byDay: Record<string, ActivityInterface[]>, activity) => {
      const day = moment(activity.details?.timestamp)
      let key = ''

      if (day.startOf('day').isSame(moment().startOf('day'))) {
        key = 'Today'
      } else if (day.startOf('day').isSame(moment().startOf('day').subtract(1, 'day'))) {
        key = 'Yesterday'
      } else {
        key = day.startOf('day').format('MMM Do, YYYY')
      }

      return {
        ...byDay,
        [key]: [...(byDay[key] ?? []), activity]
      }
    }, {})
  }, [activities, ascending])

  const dayKeys = useMemo(
    () =>
      Object.keys(groupedByDay).sort((a, b) => {
        const direction = ascending ? -1 : 1

        if (a === 'Today') {
          return -1 * direction
        }

        if (a === 'Yesterday') {
          return b === 'Today' ? 1 * direction : -1 * direction
        }

        return moment(a, 'MMM Do, YYYY').isAfter(moment(b, 'MMM Do, YYYY')) ? -1 * direction : 1 * direction
      }),
    [groupedByDay, ascending]
  )

  const prevNewestActivity = usePrevNewestActivity(sorted, ascending)

  const value = useMemo(
    () => ({
      activities
    }),
    [activities]
  )

  return (
    <ActivityTimelineContext.Provider value={value}>
      <div className={classes.root}>
        {loading ? (
          Array.from({ length: shimRowAmount }).map((_, index) => (
            <motion.div
              key={index}
              className={classes.shimRow}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.2
                }
              }}
            >
              <Skeleton height="55px" width="100%" />
            </motion.div>
          ))
        ) : (
          <Grid container spacing={2}>
            {dayKeys.map((day: keyof typeof groupedByDay) => (
              <Grid key={day} item xs={12} className={classes.day}>
                <Typography variant="h6" component="h1" className={classes.heading}>
                  {day}
                </Typography>
                <Grid container spacing={1}>
                  {groupedByDay[day].map((activity, index) => {
                    const animate =
                      prevNewestActivity && activity?.details?.timestamp > prevNewestActivity?.details?.timestamp

                    return (
                      <Grid
                        key={activity._id}
                        item
                        xs={12}
                        className={classnames(
                          classes.activity,
                          index === groupedByDay[day].length - 1 ? undefined : classes.border
                        )}
                      >
                        <ActivityComponent
                          activity={activity}
                          showContent={showContent}
                          showDocTypeDetails={showDocTypeDetails}
                          animate={animate}
                          actions={actions}
                          onAction={onAction}
                          isQuote={isQuote}
                          // there are performance issues when ascending and loading more messages (because of reverse-direction scroll)
                          // layout={ascending ? null : 'position'}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </ActivityTimelineContext.Provider>
  )
}

/**
 * Returns the previous 'newest' activity so we know which activities should be animated
 */
function usePrevNewestActivity(sortedActivities: ActivityInterface[], ascending: boolean) {
  const [prevNewestActivity, setPrevNewestActivity] = useState(
    sortedActivities.length > 0 && sortedActivities[ascending ? sortedActivities.length - 1 : 0]
  )

  useEffect(() => {
    setPrevNewestActivity(sortedActivities.length > 0 && sortedActivities[ascending ? sortedActivities.length - 1 : 0])
  }, [sortedActivities])

  return prevNewestActivity
}

ActivityTimeline.defaultProps = {
  activities: [],
  shimRowAmount: 10,
  ActivityComponent: Activity,
  actions: true
}

export default ActivityTimeline

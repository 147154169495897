import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field } from 'formik'

import { Grid, Typography, FormikInputField, FormikEmbedMedia } from '@ui/paintscout'

const ImageForm = (_props: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>This section will show a single image or video.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field component={FormikInputField} label={'Title'} name={'section.title'} fullWidth={true} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Field
            name="section.fields.image"
            component={FormikEmbedMedia}
            maxWidth={2000}
            maxHeight={1600}
            buttonHeight={100}
            quality={1}
          />
        </Grid>
        <Grid item xs={12}>
          <Field component={FormikInputField} label={'Link'} name={'section.fields.link'} fullWidth={true} />
        </Grid>
      </Grid>
    </div>
  )
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(ImageForm)

import type { Group, QuoteDocument } from 'paintscout'
import type { QuoteItemSection } from '..'

/**
 * Check if a substrate is in a group, return group if found
 * @returns Group if found, null if not
 */
export function isInSubstrateGroup({
  quote,
  areaSubstrateKey,
  rateKey,
  section,
  groupKey
}: {
  quote: QuoteDocument
  areaSubstrateKey: string
  rateKey: string
  section: QuoteItemSection
  groupKey?: string
}): Group {
  if (!quote || !rateKey) {
    return null
  }

  if (quote.version < 5) {
    for (const groupKey in quote.groups || []) {
      const group = quote.groups[groupKey]
      if (!!group && group.children.includes(areaSubstrateKey) && group.section === section) {
        return group
      }
    }
  } else {
    if (groupKey) {
      const group = quote?.groups[groupKey]
      if (!!group && group.substrateChildren?.includes(rateKey) && group.section === section) {
        return group
      }
    } else {
      for (const groupKey in quote.groups || []) {
        const group = quote.groups[groupKey]
        if (!!group && group.substrateChildren?.includes(rateKey) && group.section === section) {
          return group
        }
      }
    }
  }

  return null
}

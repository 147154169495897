import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { QuoteItemSection } from '../..'
import { getAreaSubstrates } from '../../area-substrates'

export function isSplitArea(args: {
  quote: QuoteDocument
  options: OptionsDocument
  areaKey: string
  section?: QuoteItemSection
  showGroups?: boolean
}) {
  const { quote, options, areaKey, section, showGroups } = args
  const bidItems = getAreaSubstrates({ quote, options, section: 'bid', areaKey, showGroups })
  const optionItems = getAreaSubstrates({ quote, options, section: 'options', areaKey, showGroups })
  const pendingItems = getAreaSubstrates({ quote, options, section: 'pending', areaKey, showGroups })
  const additionalItems = getAreaSubstrates({ quote, options, section: 'additional', areaKey, showGroups })

  // Account for zero bid items and items in other sections
  if (section === 'bid' && !bidItems?.length) {
    return [optionItems?.length > 0, pendingItems?.length > 0, additionalItems?.length > 0].filter(Boolean).length >= 1
  }

  return (
    [bidItems?.length > 0, optionItems?.length > 0, pendingItems?.length > 0, additionalItems?.length > 0].filter(
      Boolean
    ).length > 1
  )
}

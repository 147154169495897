import type { PresentationIsEmptyProps } from '../../'
export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields
  if (!fields) {
    return true
  }

  if (!fields.rows || fields.rows.lenth === 0) {
    return true
  }

  return fields.rows.reduce((acc, row) => {
    const hasContent = row?.content && row.content.replace('<p></p>', '').trim()
    const emptyRow = !hasContent && !row?.title && !row?.image

    return acc && emptyRow
  }, true)
}

import type { PouchDocument } from 'paintscout'
import { removeAuth0Prefix } from '../../../users'

export function assignUser<T extends PouchDocument>({
  doc,
  userId,
  userName,
  removeUserId
}: {
  doc: T
  userId: string
  userName?: string
  removeUserId?: string
}) {
  const assignedUsers = { ...(doc.assignedUsers || {}) }

  const updatedAssignedUsers = {
    ...assignedUsers,
    [removeAuth0Prefix(userId)]: {
      assigned: true,
      name: userName
    }
  }

  if (removeUserId) {
    delete updatedAssignedUsers[removeUserId]
  }

  return {
    ...(doc as any),
    assignedUsers: updatedAssignedUsers
  } as T
}

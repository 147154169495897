import React, { useState, useEffect } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '../theme'
import { makeStyles, Typography } from '@material-ui/core'
import { useFileDropArea } from '../files'
import type { QuoteFile } from 'paintscout'
import { useIsMobile } from '../hooks'

const useStyles = makeStyles<Theme, SignatureCanvasProps>(
  (theme) => ({
    root: {
      display: 'inline-block',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 450
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 40px)'
      }
    },
    canvas: {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    sigWrapper: {
      position: 'relative',
      width: 450,
      height: 150,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 400
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 40px)'
      }
    },
    canvasWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center'
    },
    buttonRow: {
      marginTop: theme.spacing(2),
      width: '100%',
      display: 'flex'
    },
    caption: {
      pointerEvents: 'none',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)'
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    sigButton: {
      padding: 0,
      margin: 0
    },
    sigButtonText: {
      textTransform: 'none'
    }
  }),
  { name: 'SignatureCanvas' }
)

export interface SignatureCanvasProps {
  Button: React.ComponentType<any>
  classes?: StyleClasses<typeof useStyles>
  disabled?: boolean
  caption?: string
  onChange?: (event: any, data?: string) => void
  onSignStart?: (value: boolean) => void
  onUploadError?: (type?: string) => void
  value?: string
  allowUpload?: boolean
  newSignatureButtons?: boolean
}

const PERMITTED_SIGNATURE_FILES = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/webp']

function SignatureCanvas(props: SignatureCanvasProps) {
  const classes = useStyles(props)
  const { caption, disabled, value, onChange, onSignStart, Button, onUploadError, allowUpload, newSignatureButtons } =
    props
  const isMobile = useIsMobile({ xs: true })

  const canvasRef = React.createRef<HTMLElement>() as any
  const [empty, setEmpty] = useState(!value)
  const [initialValue, setInitialValue] = useState(value)

  useEffect(() => {
    if (disabled) canvasRef.current.off()
    else canvasRef.current.on()
  }, [disabled])

  const clear = () => {
    canvasRef.current.clear()
    setEmpty(true)
    setInitialValue(null)
    handleOnEnd(null)
  }

  const handleOnEnd = (event: any) => {
    if (canvasRef.current.isEmpty()) {
      setEmpty(true)
      onChange(event, '')
    } else if (onChange) {
      onChange(event, canvasRef.current.getCanvas().toDataURL())
    }
    setTimeout(() => {
      if (onSignStart) onSignStart(false)
    }, 250)
  }

  const handleOnBegin = (_event: any) => {
    if (onSignStart) onSignStart(true)
    if (!disabled) {
      setEmpty(false)
      setInitialValue(null)
    }
  }

  function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      const reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  const { open: openDropArea, getInputProps } = useFileDropArea({
    maxHeight: 300,
    maxWidth: 300,
    onUpload: async (files: QuoteFile[]) => {
      if (files[0]) {
        if (PERMITTED_SIGNATURE_FILES.find((fileType) => fileType.includes(files[0].format))) {
          toDataURL(files[0].src, (data: string) => {
            setInitialValue(data)
            setEmpty(false)
            onChange(null, data)
          })
        } else if (onUploadError) {
          onUploadError(files[0]?.format ?? '')
        }
      } else if (onUploadError) {
        onUploadError()
      }
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.canvasWrapper}>
        <div className={classes.sigWrapper}>
          <ReactSignatureCanvas
            canvasProps={{ className: classes.canvas }}
            ref={canvasRef}
            onEnd={handleOnEnd}
            onBegin={handleOnBegin}
            clearOnResize={!isMobile}
          />
        </div>
        {empty && !initialValue && !disabled && <div className={classes.caption}>{caption ?? 'Sign Here'}</div>}
        {!empty && initialValue && <img className={classes.image} src={initialValue} />}
      </div>
      <div className={classes.buttonRow} style={{ justifyContent: allowUpload ? 'space-between' : 'flex-start' }}>
        {newSignatureButtons ? (
          <>
            {allowUpload && (
              <Button className={classes.sigButton} variant="text" onClick={openDropArea}>
                <input {...getInputProps()} accept={PERMITTED_SIGNATURE_FILES.join(',')} />
                <Typography className={classes.sigButtonText} variant="subtitle1">
                  Upload Signature
                </Typography>
              </Button>
            )}
            <Button
              disabled={disabled || empty}
              variant={'text'}
              className={classes.sigButton}
              edge={'start'}
              onClick={clear}
            >
              <Typography className={classes.sigButtonText} variant="subtitle1">
                Clear
              </Typography>
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={disabled || empty}
              variant={'text'}
              className={classes.sigButton}
              edge={'start'}
              onClick={clear}
            >
              Clear Signature
            </Button>
            {allowUpload && (
              <Button variant="text" color="primary" prominence={2} onClick={openDropArea}>
                <input {...getInputProps()} accept={PERMITTED_SIGNATURE_FILES.join(',')} />
                Upload
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default SignatureCanvas

import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import ActivityTitle from '../ActivityTitle'
import ActivityFooter from '../ActivityFooter'
import ActivityContent from '../ActivityContent'
import Highlight from '../../Highlight'
import type { ActivityTypeComponentProps } from '../Activity'
import QuoteActivitySubtitle from '../QuoteActivitySubtitle'
import Typography from '../../Typography'
import QuoteLabel from '../QuoteLabel'
import type { DeclinedActivityDetails } from '@paintscout/api'

const useStyles = makeStyles<Theme, ActivityTypeComponentProps<DeclinedActivityDetails>>((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  reason: {
    color: theme.palette.text.secondary
  }
}))

// the 2nd group will be the reason between quotation marks
const declinedEventRegex = /(Declined for the following reason:\n\n ")(.*)(")/

export default function QuoteDeclinedActivity(props: ActivityTypeComponentProps<DeclinedActivityDetails>) {
  const { activity, showDocTypeDetails } = props
  const classes = useStyles(props)

  const { events } = activity.details

  const declinedEvent = events?.[0]

  // get reason from details, otherwise get 2nd group from regex
  const reason = activity.details.reason ?? declinedEvent?.match(declinedEventRegex)?.[2] ?? ''

  return (
    <>
      <ActivityTitle>
        {showDocTypeDetails ? (
          <>
            <QuoteLabel activity={activity} /> was <Highlight>declined</Highlight>
          </>
        ) : (
          <>
            <Highlight>Declined</Highlight>
          </>
        )}
      </ActivityTitle>
      <QuoteActivitySubtitle activity={activity} showDocTypeDetails={showDocTypeDetails} />
      {reason && (
        <ActivityContent className={classes.content}>
          <Typography variant="subtitle2" className={classes.reason}>
            {`"${reason}"`}
          </Typography>
        </ActivityContent>
      )}
      <ActivityFooter timestamp={activity.details.timestamp}></ActivityFooter>
    </>
  )
}

import React from 'react'
import type { PresentationHelpProps } from '../../'

import { Alert } from '@ui/paintscout'

export default function Help(_: PresentationHelpProps) {
  return (
    <Alert
      severity="info"
      content="Use this section to add any important information you want your customers to see. It will appear with one title and two columns. These columns can either be text or images/videos."
    />
  )
}

import type { QuoteDocument, QuoteProduct } from 'paintscout'
import { CORRECTIVE_CONSTANT } from '../../constants'
import { parseNumber } from '../util/parse-number'

export function productVolume(
  quote: QuoteDocument,
  products: QuoteProduct[],
  rateType: string,
  dimension: number,
  coats?: string | number,
  quantity?: string | number,
  roundPrices?: 'none' | 'up' | 'round',
  roundProducts?: 'none' | 'up' | 'round'
) {
  for (const product of products) {
    if (product && typeof product !== 'string') {
      let coverageRateValue = 0
      if (rateType === 'quantity') {
        coverageRateValue = parseNumber(
          product.quantity_coverage_rate_override
            ? product.quantity_coverage_rate_override_value
            : product.quantity_coverage_rate_value
        )
      } else if (rateType === 'lnft') {
        coverageRateValue = parseNumber(
          product.lnft_coverage_rate_override
            ? product.lnft_coverage_rate_override_value
            : product.lnft_coverage_rate_value
        )
      } else if (product.coverage_rate_value || product.coverage_rate_override) {
        coverageRateValue = parseNumber(
          product.coverage_rate_override ? product.coverage_rate_override_value ?? 0 : product.coverage_rate_value
        )
      }

      const q = parseNumber(quantity, 1)
      const c = parseNumber(coats, 1)

      if (!coverageRateValue) {
        product.volume = 0
      } else if (rateType === 'quantity') {
        let volume = coverageRateValue * q * c
        if (roundProducts === 'up') volume = Math.ceil(volume)
        else if (roundProducts === 'round') volume = Math.round(volume)
        product.volume = volume
      } else {
        if (quote.version >= 7 && !dimension && dimension !== 0) {
          return
        } else if (!dimension) {
          return
        }

        let volume = (dimension / coverageRateValue) * q * c
        if (roundProducts === 'up') volume = Math.ceil(volume)
        else if (roundProducts === 'round') volume = Math.round(volume)
        product.volume = volume
      }

      const volume = product.volume_override ? product.volume_override_value : product.volume

      const productPrice = product.price_override ? product.price_override_value : product.price

      if (productPrice) {
        let totalPrice = volume * productPrice
        if (roundPrices === 'round') totalPrice = Math.round(totalPrice)
        else if (roundPrices === 'up') totalPrice = Math.ceil(totalPrice)
        product.totalPrice = quote.version >= 6 ? Math.floor(totalPrice * 100 + CORRECTIVE_CONSTANT) / 100 : totalPrice
      } else {
        product.totalPrice = 0
      }
    }
  }
}

import type { QuoteDocument } from 'paintscout'

export function sanitizeSubstrates(initialQuote: QuoteDocument) {
  const quote = {
    ...initialQuote
  }

  try {
    Object.keys(quote.substrates).forEach((substrateKey) => {
      const substrate = quote.substrates[substrateKey]

      if (!substrate) {
        return
      }

      // If anything has been customized or has a total, leave it alone.

      if (
        substrate?.section ||
        (substrate.area_string && substrate.area_string.use_custom) ||
        (substrate.product_string && substrate.product_string.use_custom) ||
        (substrate.report_text && substrate.report_text.use_custom) ||
        (substrate.clientLabel && substrate.clientLabel.useCustom)
      ) {
        return
      }

      const { totals, grouped_totals, hidden_totals } = substrate
      if (totals || grouped_totals || hidden_totals) {
        return
      }

      if (
        totals?.hours ||
        totals?.price ||
        totals?.prep ||
        grouped_totals?.hours ||
        grouped_totals?.price ||
        grouped_totals?.prep ||
        hidden_totals?.hours ||
        hidden_totals?.prep ||
        hidden_totals?.price
      ) {
        return
      }

      delete quote.substrates[substrateKey]
    })
    quote.order.substrate = quote.order.substrate.filter((orderItem) => {
      return orderItem.type !== 'substrate' || quote.substrates[orderItem.key]
    })
    return quote
  } catch (err) {
    return quote
  }
}

import React from 'react'
import { Tab } from '@material-ui/core'
import type { TabProps as MuiTabProps } from '@material-ui/core/Tab'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      ...theme.typography.overline,
      marginRight: theme.spacing(3),
      color: theme.palette.grey.A400,

      paddingLeft: 0,
      paddingRight: 0,

      minWidth: '0',
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1
      }
    },
    wrapper: {
      minWidth: '0'
    },
    selected: {
      color: theme.palette.primary.main
    }
  })
}

export interface TabProps extends WithStyles<typeof styles>, Omit<MuiTabProps, 'classes'> {
  children?: React.ReactNode
}

const CustomizedTab = (props: TabProps) => (
  <Tab disableRipple {...props}>
    {props.children}
  </Tab>
)

export default withStyles(styles)(CustomizedTab)

import { type Theme, makeStyles } from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { type DialogProps, DialogActions, Button, Dialog, DialogContent, Grid, Typography } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  successCheckmark: {
    color: theme.palette.success.main,
    width: '3em',
    height: '3em'
  }
}))

interface DepositPromptDialgProps extends DialogProps {
  quote: QuoteDocument
  options: OptionsDocument
  onCancel: () => void
  onPayDeposit: () => Promise<void>
}

export default function DepositPromptDialog({
  quote: _quote,
  options,
  onCancel,
  onPayDeposit,
  ...dialogProps
}: DepositPromptDialgProps) {
  const classes = useStyles()
  const { quote: quoteLabel } = getObjectLabels({ options })
  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <Grid item>
            <CheckCircle className={classes.successCheckmark} />
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="h2">
              {quoteLabel.value} Accepted!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={onPayDeposit}>Pay Deposit Now</Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          skip
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useClientOptions } from '@ui/paintscout'
import LogRocketImport from 'logrocket'
import { useEffect } from 'react'

import setupLogRocketReact from 'logrocket-react'
import moment from 'moment-timezone'

import * as Sentry from '@sentry/react'
import { useView } from 'src/components/ViewProvider'

let isInitialized = false

export default function CustomerViewLogRocket(): JSX.Element {
  const { options } = useClientOptions()
  const { companyId, url } = useView()

  useEffect(() => {
    if (
      process.env.LOGROCKET_CUSTOMER_VIEW_ID &&
      options.options.features?.logRocket?.enabled &&
      options.options?.features?.logRocket?.customerView
    ) {
      const release = `${process.env.VERSION}-${process.env.COMMIT}`
      // we can only run LogRocket.init once
      if (!isInitialized) {
        LogRocketImport.init(process.env.LOGROCKET_CUSTOMER_VIEW_ID, {
          release,
          shouldCaptureIP: true
        })
        setupLogRocketReact(LogRocketImport)
        isInitialized = true
      }

      const iPadOS = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
      const standalone = 'standalone' in window.navigator && (window.navigator as any).standalone
      const lastPurge = JSON.parse(window.localStorage.getItem('lastPurge')) ?? 0

      LogRocketImport.identify(companyId, {
        company: options.options.companyName.value,
        url,
        companyId,
        iPadOS,
        standalone,
        lastPurge: lastPurge ? moment(lastPurge).tz('America/Edmonton').format('MM/DD/YYYY @ hh:mma') : 'never'
      })

      console.log(release) //
      LogRocketImport.info(release)

      LogRocketImport.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL)
        })
      })
    }
  }, [options, companyId, url])

  return null
}

/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument, QuoteOptions } from 'paintscout'

import { getOverrideableOption } from './get-overrideable-option'

import get from 'lodash/get'
import getE2eInjection from './get-e2e-injection'

export function getQuoteOptions(args: {
  options?: OptionsDocument
  quote?: QuoteDocument
  override?: { [key: string]: any }
}): QuoteOptions {
  const { options, override, quote } = args

  const defaultOptions: any = {
    showPrep: true,

    showQuantities: true,
    groupHours: false,
    separateThousands: true,
    showPrices: true,
    hideAllPrices: false,
    stackItems: true,
    showDetailedBreakdown: false,
    showBalanceDue: false,
    showEstimatorSignature: true,
    requireSignatureSent: true,
    requireSignatureOnSpot: true,

    includeMaterialCost: false,

    showSignatures: true,

    showQuoteOptions: true,
    showQuoteFiles: true,
    showQuotePayments: false,
    allowQuotePayments: false,

    allowQuoteAdditionalWork: false,

    showWorkOrderAreas: true,
    showWorkOrderAreaOptions: true,
    showWorkOrderSubstrates: false,
    showWorkOrderSubstrateOptions: false,
    showWorkOrderAreaValues: false,
    showWorkOrderAreaOptionValues: true,
    showWorkOrderSubstrateValues: true,
    showWorkOrderSubstrateOptionValues: true,
    showWorkOrderViewDescriptions: false,
    showWorkOrderFiles: false,
    showWorkOrderProducts: true,
    showWorkOrderNotes: true,

    showProductOrderAllProducts: true,
    showProductOrderBreakdown: true,

    showWorkOrderClientNotes: false,
    hideWorkOrderClientContactInfo: false,

    hideWorkOrderVolumes: false,
    hideWorkOrderMaterialCost: false,

    workOrderOptionsConsumer: 'customer' as const,

    allowShowCustomizedTotalHours: false,
    allowShowCustomizedTotalPrice: false,
    useAllAreasForTotalDimensions: false,

    quickEditQuote: false,

    showWorkOrderItemTotals: false,

    alwaysShowJobAddress: false,

    afterDiscountSalesRate: false,

    sendQuoteByTextDefault: false,

    roundHours: 'half',
    roundQuantityHours: false,
    roundPrices: 'none',
    roundProducts: 'none',
    legacyRoundQuantityHours: null,

    noDefaultAreaDimensions: false,

    showTopSignature: false,

    stackSubstrates: false,
    splitAreaPrice: false,

    splitSubtotal: false,

    serviceFees: {
      hiddenValue: 0,
      visibleValue: 0,
      allow_custom: false
    },

    depositAmount: {
      type: 'percentage',
      value: 0
    },

    wisetackDepositAmount: {
      type: 'percentage',
      value: 0
    },

    surcharge: {
      enabled: false
    },

    allowPaymentsOnInvoices: true,

    allowPaymentsOnQuotes: true,

    depositAmountBeforeTax: false
  }

  return {
    ...Object.keys(defaultOptions).reduce((acc: any, key: string) => {
      acc[key] = getOverrideableOption({
        options,
        quote,
        override,
        selector: `options.quotes.${key}`,
        quoteSelector: `options.${key}`,
        overrideSelector: key,
        defaultValue: defaultOptions[key] as boolean
      })

      return acc
    }, {}),
    addIndex: {
      bid: get(quote, 'options.addIndex.bid', -1),
      options: get(quote, 'options.addIndex.options', -1)
    },
    customerViewExpiry: {
      ...get(quote, 'options.customerViewExpiry', {})
    },
    customerInvoiceExpiry: {
      ...get(quote, 'options.customerInvoiceExpiry', {})
    },
    workOrderOptionsConsumer: get(quote, 'options.workOrderOptionsConsumer', 'customer'),
    individualProductRounding: get(quote, 'options.individualProductRounding', false),
    ...getE2eInjection()
  }
}

import * as React from 'react'
import type { ClientOptionsValue } from './ClientOptionsContext'
import ClientOptionsContext from './ClientOptionsContext'

export interface WithClientOptions {
  clientOptions: ClientOptionsValue
}

/**
 * Provides the component with a ClientOptionsContext prop as the value from ClientOptionsContextProvider
 */
const withClientOptions = <T extends WithClientOptions>(Component: React.ComponentType<T>) => {
  function WithClientOptions(props: Omit<T, keyof WithClientOptions>) {
    return (
      <ClientOptionsContext.Consumer>
        {(context: ClientOptionsValue) => <Component {...(props as any)} clientOptions={context} />}
      </ClientOptionsContext.Consumer>
    )
  }

  WithClientOptions.displayname = `WithClientOptions(${Component.displayName ?? 'Component'})`

  return WithClientOptions
}

export default withClientOptions

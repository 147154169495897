/**
 * @module builder
 */
import type { LineItem, OptionsDocument, QuoteDocument } from 'paintscout'

import type { UpdateableLineItem, UpdateableProduct, QuoteItemSection } from '../../index'
import { moveTo } from '../../util'
import { calculateQuote } from '../../util/calculate/calculate-quote'
import { uuid } from '../../../util'

import findIndex from 'lodash/findIndex'

export function updateLineItem(args: {
  quote: QuoteDocument
  item: UpdateableLineItem
  options: OptionsDocument
  calculate?: boolean
  insertAfterKey?: string
  itemSection?: QuoteItemSection
}): QuoteDocument {
  const { quote, options, item, calculate, insertAfterKey, itemSection } = args

  const key = item?.key ?? uuid()
  const section = item?.section ?? itemSection ?? 'bid'
  const isNewItem = quote.lineItems[key] ? false : true

  let originalItem = isNewItem ? { ...createBaseLineItem(key), section } : quote.lineItems[key]
  originalItem = moveTo(originalItem, section)
  const newItem = mergeLineItem({ key, originalItem, updateableLineItem: item })

  if (item.quoteFiles) {
    quote.files = { ...quote.files, ...item.quoteFiles }
  }

  const areaOrder = [...quote.order.area]
  const substrateOrder = [...quote.order.substrate]

  if (isNewItem) {
    const orderItem = {
      key,
      type: 'line_item'
    }

    if (insertAfterKey) {
      const index = findIndex(areaOrder, { key: insertAfterKey })
      areaOrder.splice(index + 1, 0, orderItem)
      substrateOrder.splice(index + 1, 0, orderItem)
    } else {
      areaOrder.push(orderItem)
      substrateOrder.push(orderItem)
    }
  }

  const updatedQuote = {
    ...quote,
    lineItems: {
      ...quote.lineItems,
      [key]: newItem
    },
    order: {
      ...quote.order,
      area: areaOrder,
      substrate: substrateOrder
    }
  }

  return calculate === false && !['hourlyRate', 'quantity'].includes(item.calculateBy)
    ? updatedQuote
    : calculateQuote({ quote: updatedQuote, options })
}

export function createBaseLineItem(key: string): LineItem {
  return {
    key,
    show: true,
    show_price: true,
    show_crew: true,
    use_total: true,
    show_total: true,
    name: '',
    price: 0,
    hours: 0,
    customHourlyRate: false,
    hourlyRate: 0,
    description: '',
    crew_note: '',
    client_note: '',
    calculateBy: 'none',
    materials: [] as UpdateableProduct[],
    totals: {
      materials: 0,
      afterMaterials: 0
    },
    files: []
  }
}

export function mergeLineItem({
  key,
  originalItem,
  updateableLineItem
}: {
  key: string
  originalItem: LineItem
  updateableLineItem: UpdateableLineItem
}): LineItem {
  return {
    ...originalItem,
    key,
    optionKey: updateableLineItem?.optionKey ?? '',
    name: updateableLineItem?.label ?? '',
    description: updateableLineItem?.description,
    crew_note: updateableLineItem.crewNote,
    client_note: updateableLineItem.clientNote,
    hours:
      typeof updateableLineItem.hours === 'string' ? parseFloat(updateableLineItem.hours) : updateableLineItem.hours,
    price:
      typeof updateableLineItem.price === 'string' ? parseFloat(updateableLineItem.price) : updateableLineItem.price,
    calculate: updateableLineItem.calculate,
    calculateBy: updateableLineItem.calculateBy,
    quantity: updateableLineItem.quantity,
    hoursPerUnit: updateableLineItem.hoursPerUnit,
    pricePerUnit: updateableLineItem.pricePerUnit,
    show_hours_for_work_order: updateableLineItem.showHoursForWorkOrder,
    images: (updateableLineItem as any).images, // legacy
    files: updateableLineItem.files,
    source: updateableLineItem.source,
    customHourlyRate: updateableLineItem.customHourlyRate,
    hideOnWorkOrder: updateableLineItem.hideOnWorkOrder,
    hourlyRate:
      typeof updateableLineItem.hourlyRate === 'string'
        ? parseFloat(updateableLineItem.hourlyRate)
        : updateableLineItem.hourlyRate,
    materials: updateableLineItem.materials ? [...updateableLineItem.materials] : [],
    quoteType: updateableLineItem.quoteType,
    taxRate: updateableLineItem.taxRate,
    showPrice: updateableLineItem.showPrice,
    hideQuantity: updateableLineItem.hideQuantity
  }
}

import React, { useState } from 'react'

import type { MenuProps } from '@material-ui/core/Menu'
import Menu from '@material-ui/core/Menu'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'
import type { MenuItemProps } from '../MenuItem'
import classnames from 'classnames'
import type { StyleClasses } from '../theme'

export interface PopupMenuProps extends Omit<MenuProps, 'open'> {
  id?: string
  className?: string
  disabled?: boolean
  component: React.ReactElement<any>
  children: React.ReactElement<MenuItemProps> | Array<React.ReactElement<MenuItemProps>>
  getContentAnchorEl?: any
  orientation?: 'vertical' | 'horizontal'
  classes?: StyleClasses<typeof useStyles>
}

const useStyles = makeStyles<Theme, PopupMenuProps>(
  (_theme: Theme) => ({
    root: (props) => ({
      ...(props.orientation === 'horizontal' && {})
    }),
    menu: (props) => ({
      ...(props.orientation === 'horizontal' && {
        display: 'flex',
        flexDirection: 'row'
      })
    }),
    list: (props) => ({
      ...(props.orientation === 'horizontal' && {
        display: 'flex',
        padding: 0
      })
    }),
    divider: {}
  }),
  { name: 'CorePopupMenu' }
)

function PopupMenu(props: PopupMenuProps) {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles(props)

  const handleToggle = (event: React.SyntheticEvent) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleClose = (ev: any) => {
    ev.stopPropagation()
    setAnchorEl(null)
  }

  const popupButton = (component: any) => {
    if (!component) return null
    else
      return React.cloneElement(props.component, {
        'aria-haspopup': true,
        'aria-owns': anchorEl ? props.id : null,
        onClick: (ev: React.SyntheticEvent) => {
          if (!props.disabled && !props.component.props.disabled) {
            handleToggle(ev)
          }

          if (props.component.props.onClick) {
            props.component.props.onClick(ev)
          }
        }
      })
  }

  return (
    <div className={classnames(classes.root, props.className)}>
      {popupButton(props.component)}
      <Menu
        onClick={(ev) => {
          handleToggle(ev)
          ev.stopPropagation()
        }}
        id={props.id}
        anchorEl={anchorEl}
        getContentAnchorEl={props.getContentAnchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        {...props}
        classes={{ paper: classes.menu, list: classes.list }}
      >
        {props.children}
      </Menu>
    </div>
  )
}

export default PopupMenu
